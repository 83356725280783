import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CalendarEvent from "../classes/CalendarEvent";
import TextView from "./TextView";

export interface EventViewProps extends BaseUIProps {
  key?: string;
  event: CalendarEvent;
}

class _EventViewState extends ObservableComponent<EventViewProps> {
  static defaultProps = { event: null };
  public constructor(props: EventViewProps) {
    super(props);

    this.initState();
  }
  public get event(): CalendarEvent {
    return this.props.event;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("event", this.props.event);

    this.on(
      [
        "event",
        "event.backgroundColor",
        "event.endTime",
        "event.startTime",
        "event.title",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: EventViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.event !== this.props.event) {
      this.updateObservable("event", prevProps.event, this.props.event);

      this.fire("event", this);
    }
  }
  public render(): ReactNode {
    return ui.Container({
      decoration: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff000000), width: 1 }),
        color: ui.HexColor.fromHexStr(this.event.backgroundColor),
        borderRadius: ui.BorderRadius.circular(3.0),
      }),
      child: ui.Wrap({
        spacing: 5,
        children: [
          TextView({
            data: this.event.title,
            style: new ui.TextStyle({
              fontWeight: ui.FontWeight.bold,
              color: new ui.Color(0xffffffff),
            }),
            className: "x42",
            key: "0",
          }),
          TextView({
            data:
              this.event.startTime.toString() +
              " - " +
              this.event.endTime.toString(),
            style: new ui.TextStyle({ color: new ui.Color(0xffffffff) }),
            className: "x3f0",
            key: "1",
          }),
        ],
      }),
      className: ui.join(this.props.className, "EventView xec8"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function EventView(props: EventViewProps) {
  return React.createElement(_EventViewState, {
    ..._EventViewState.defaultProps,
    ...props,
  });
}
