import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import Range from "../classes/Range";
import CalendarHelper from "../classes/CalendarHelper";
import ListWrapper from "../utils/ListWrapper";
import D3EDate from "../classes/D3EDate";
import RoundedDateView from "./RoundedDateView";
import TextView from "./TextView";
import CalenderUtils from "../classes/CalenderUtils";
import CollectionUtils from "../utils/CollectionUtils";

type _MonthYearViewOnDateSelected = (date: D3EDate) => void;

export interface MonthYearViewProps extends BaseUIProps {
  key?: string;
  date?: D3EDate;
  helper: CalendarHelper;
  categories: Array<string>;
  weekNameBgColor?: ui.Color;
  currentDateColor?: ui.Color;
  _categoriesHash?: number;
  onDateSelected?: _MonthYearViewOnDateSelected;
}

class _MonthYearViewState extends ObservableComponent<MonthYearViewProps> {
  static defaultProps = {
    date: null,
    helper: null,
    weekNameBgColor: null,
    currentDateColor: null,
    categories: [],
    onDateSelected: null,
  };
  selectedDate: D3EDate = null;
  listOfDates: Array<D3EDate> = ListWrapper.widget(this, "listOfDates");
  weekNames: Array<string> = ListWrapper.widget(this, "weekNames");
  monthName: string = "";
  currentDate: D3EDate = null;
  public constructor(props: MonthYearViewProps) {
    super(props);

    this.initState();
  }
  public get date(): D3EDate {
    return this.props.date;
  }
  public get helper(): CalendarHelper {
    return this.props.helper;
  }
  public get categories(): Array<string> {
    return this.props.categories;
  }
  public get weekNameBgColor(): ui.Color {
    return this.props.weekNameBgColor;
  }
  public get currentDateColor(): ui.Color {
    return this.props.currentDateColor;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.categories, "categories");

    this.on(["date"], this.computeSelectedDate);

    this.computeSelectedDate();

    this.on(["selectedDate"], this.computeListOfDates);

    this.computeListOfDates();

    this.computeWeekNames();

    this.on(["selectedDate"], this.computeMonthName);

    this.computeMonthName();

    this.computeCurrentDate();

    this.on(
      [
        "categories",
        "currentDate",
        "currentDateColor",
        "date",
        "helper",
        "listOfDates",
        "monthName",
        "selectedDate",
        "weekNameBgColor",
        "weekNames",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: MonthYearViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.date !== this.props.date) {
      this.fire("date", this);
    }

    if (prevProps.helper !== this.props.helper) {
      this.fire("helper", this);
    }

    if (prevProps.categories !== this.props.categories) {
      this.fire("categories", this);
    }

    if (prevProps.weekNameBgColor !== this.props.weekNameBgColor) {
      this.fire("weekNameBgColor", this);
    }

    if (prevProps.currentDateColor !== this.props.currentDateColor) {
      this.fire("currentDateColor", this);
    }
  }
  public setSelectedDate(val: D3EDate): void {
    let isValChanged: boolean = this.selectedDate !== val;

    if (!isValChanged) {
      return;
    }

    this.selectedDate = val;

    this.fire("selectedDate", this);
  }
  public computeSelectedDate = (): void => {
    try {
      this.setSelectedDate(this.date !== null ? this.date : D3EDate.now());
    } catch (exception) {
      console.log(
        " exception in computeSelectedDate : " + exception.toString()
      );

      this.setSelectedDate(null);
    }
  };
  public setListOfDates(val: Array<D3EDate>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.listOfDates,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.listOfDates.clear();

    this.listOfDates.addAll(val);

    this.fire("listOfDates", this);
  }
  public addToListOfDates(val: D3EDate, index: number = -1): void {
    if (index === -1) {
      if (!this.listOfDates.contains(val)) this.listOfDates.add(val);
    } else {
      this.listOfDates.remove(this.listOfDates.elementAt(index));

      this.listOfDates.add(val);
    }

    this.fire("listOfDates", this, val, true);
  }
  public removeFromListOfDates(val: D3EDate): void {
    this.listOfDates.remove(val);

    this.fire("listOfDates", this, val, false);
  }
  public computeListOfDates = (): void => {
    try {
      this.setListOfDates(
        Array.from(CalenderUtils.prepareCalenderData(this.selectedDate))
      );
    } catch (exception) {
      console.log(" exception in computeListOfDates : " + exception.toString());

      this.setListOfDates([]);
    }
  };
  public setWeekNames(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.weekNames,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.weekNames.clear();

    this.weekNames.addAll(val);

    this.fire("weekNames", this);
  }
  public addToWeekNames(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.weekNames.contains(val)) this.weekNames.add(val);
    } else {
      this.weekNames.remove(this.weekNames.elementAt(index));

      this.weekNames.add(val);
    }

    this.fire("weekNames", this, val, true);
  }
  public removeFromWeekNames(val: string): void {
    this.weekNames.remove(val);

    this.fire("weekNames", this, val, false);
  }
  public computeWeekNames = (): void => {
    try {
      this.setWeekNames(
        Array.from(["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"])
      );
    } catch (exception) {
      console.log(" exception in computeWeekNames : " + exception.toString());

      this.setWeekNames([]);
    }
  };
  public setMonthName(val: string): void {
    let isValChanged: boolean = this.monthName !== val;

    if (!isValChanged) {
      return;
    }

    this.monthName = val;

    this.fire("monthName", this);
  }
  public computeMonthName = (): void => {
    try {
      this.setMonthName(CalenderUtils.getMonthName(this.selectedDate.month));
    } catch (exception) {
      console.log(" exception in computeMonthName : " + exception.toString());

      this.setMonthName("");
    }
  };
  public setCurrentDate(val: D3EDate): void {
    let isValChanged: boolean = this.currentDate !== val;

    if (!isValChanged) {
      return;
    }

    this.currentDate = val;

    this.fire("currentDate", this);
  }
  public computeCurrentDate = (): void => {
    try {
      this.setCurrentDate(D3EDate.now());
    } catch (exception) {
      console.log(" exception in computeCurrentDate : " + exception.toString());

      this.setCurrentDate(null);
    }
  };
  public render(): ReactNode {
    return ui.Column({
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.start,
          children: [
            TextView({
              data: this.monthName,
              style: new ui.TextStyle({
                fontSize: 15,
                fontWeight: ui.FontWeight.w500,
              }),
              className: "x7fc",
              key: "0",
            }),
          ],
          className: "x8d5 hc h",
          key: "0",
        }),
        ui.Table({
          border: new ui.TableBorder({
            horizontalInside: new ui.BorderSide({
              color: new ui.Color(0xffced4da),
              width: 1.0,
              style: ui.BorderStyle.solid,
            }),
            verticalInside: new ui.BorderSide({
              color: new ui.Color(0xffced4da),
              width: 1.0,
              style: ui.BorderStyle.solid,
            }),
            bottom: new ui.BorderSide({
              color: new ui.Color(0xffced4da),
              width: 1.0,
              style: ui.BorderStyle.solid,
            }),
            top: new ui.BorderSide({
              color: new ui.Color(0xffced4da),
              width: 1.0,
              style: ui.BorderStyle.solid,
            }),
            right: new ui.BorderSide({
              color: new ui.Color(0xffced4da),
              width: 1.0,
              style: ui.BorderStyle.solid,
            }),
            left: new ui.BorderSide({
              color: new ui.Color(0xffced4da),
              width: 1.0,
              style: ui.BorderStyle.solid,
            }),
          }),
          children: [
            ui.TableRow({
              children: [
                this.weekNames.expand((forItem) => [
                  ui.TableCell({
                    child: ui.Container({
                      decoration: new ui.BoxDecoration({
                        color: this.weekNameBgColor,
                      }),
                      child: ui.Center({
                        child: TextView({
                          data: forItem,
                          style: new ui.TextStyle({
                            fontSize: 12,
                            color: new ui.Color(0xffffffff),
                          }),
                          className: "x749 hc",
                        }),
                        className: "hc",
                      }),
                      className: "x00c hc",
                    }),
                    className: "hc",
                    key: forItem?.toString(),
                  }),
                ]),
              ],
              className: "hc",
              key: "0",
            }),
            Range.to(6).expand((eachWeek) => [
              ui.TableRow({
                children: [
                  this.listOfDates
                    .sublist(eachWeek * 7, eachWeek * 7 + 7)
                    .expand((forItem2) => [
                      ui.TableCell({
                        child: RoundedDateView({
                          date: forItem2,
                          month: this.selectedDate.month,
                          items: this.helper.getEventsForDate(
                            this.date.toDateTime(),
                            this.categories
                          ),
                          className: "hc",
                        }),
                        className: "hc",
                        key: forItem2?.toString(),
                      }),
                    ]),
                ],
                className: "hc",
                key: eachWeek?.toString(),
              }),
            ]),
          ],
          className: "x5e4 hc h",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "MonthYearView x6aa hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public get onDateSelected(): _MonthYearViewOnDateSelected {
    return this.props.onDateSelected;
  }
}
export default function MonthYearView(props: MonthYearViewProps) {
  return React.createElement(
    _MonthYearViewState,
    { ..._MonthYearViewState.defaultProps, ...props },
    ListWrapper.fromInput<string>(props.categories, "categories")
  );
}
