import Uri from "../core/Uri";
import DFile from "./DFile";
import Env from "./Env";
import FileToUpload from "./FileToUpload";
import FileUploadResult from "./FileUploadResult";

export default class PlatformClient {
  static async upload(fileToUpload: FileToUpload): Promise<FileUploadResult> {
    return PlatformClient._doUpload(fileToUpload);
  }
  static async _doUpload(
    fileToUpload: FileToUpload
  ): Promise<FileUploadResult> {
    let formData = new FormData();
    formData.append("file", fileToUpload.file);
    let _baseUrl: string = Env.get().baseHttpUrl;
    let respose: Response = await fetch(_baseUrl + "/api/upload", {
      method: "POST",
      body: formData,
    });
    if (respose.status === 200) {
      let map = new Map<string, any>();
      let body = await respose.text();
      let obj = JSON.parse(body);
      map.set("name", obj.name);
      map.set("id", obj.id);
      map.set("size", obj.size);
      map.set("mimeType", obj.mimeType);
      return new FileUploadResult(DFile.fromJson(map), true, "");
    } else {
      let errors = respose.statusText;
      return new FileUploadResult(null, false, errors);
    }
  }
}
