import D3EDate from "../classes/D3EDate";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";
import Facility from "./Facility";

export default class RentalBookingsRequest extends DBObject {
  private static readonly _DATE: number = 0;
  private static readonly _FACILITY: number = 1;
  public id: number = 0;
  public otherMaster: DBObject;
  private _date: D3EDate = null;
  private _facility: Facility = null;
  public constructor(
    d3eParams?: Partial<{ date: D3EDate; facility: Facility }>
  ) {
    super();

    this.setDate(d3eParams?.date ?? null);

    this.setFacility(d3eParams?.facility ?? null);
  }
  public get d3eType(): string {
    return "RentalBookingsRequest";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isTransientModel(): boolean {
    return;
  }
  public get date(): D3EDate {
    return this._date;
  }
  public setDate(val: D3EDate): void {
    let isValChanged: boolean = this._date !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalBookingsRequest._DATE, this._date);

    this._date = val;

    this.fire("date", this);
  }
  public get facility(): Facility {
    return this._facility;
  }
  public setFacility(val: Facility): void {
    let isValChanged: boolean = this._facility !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalBookingsRequest._FACILITY, this._facility);

    this.updateObservable("facility", this._facility, val);

    this._facility = val;

    this.fire("facility", this);
  }
  public get(field: number): any {
    switch (field) {
      case RentalBookingsRequest._DATE: {
        return this._date;
      }

      case RentalBookingsRequest._FACILITY: {
        return this._facility;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): RentalBookingsRequest {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: RentalBookingsRequest = dbObj as RentalBookingsRequest;

    obj.id = this.id;

    obj.setDate(this._date);

    obj.setFacility(this._facility);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case RentalBookingsRequest._DATE: {
        this.setDate(value as D3EDate);
        break;
      }

      case RentalBookingsRequest._FACILITY: {
        this.setFacility(value as Facility);
        break;
      }
    }
  }
}
