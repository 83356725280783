import FacilityType from "./FacilityType";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class RentalFacilitiesRequest extends DBObject {
  private static readonly _CITY: number = 0;
  private static readonly _FACILITYTYPE: number = 1;
  private static readonly _OFFSET: number = 2;
  private static readonly _PAGESIZE: number = 3;
  private static readonly _STATE: number = 4;
  public id: number = 0;
  public otherMaster: DBObject;
  private _facilityType: FacilityType = null;
  private _state: string = "";
  private _city: string = "";
  private _pageSize: number = 0;
  private _offset: number = 0;
  public constructor(
    d3eParams?: Partial<{
      city: string;
      facilityType: FacilityType;
      offset: number;
      pageSize: number;
      state: string;
    }>
  ) {
    super();

    this.setCity(d3eParams?.city ?? "");

    this.setFacilityType(d3eParams?.facilityType ?? null);

    this.setOffset(d3eParams?.offset ?? 0);

    this.setPageSize(d3eParams?.pageSize ?? 0);

    this.setState(d3eParams?.state ?? "");
  }
  public get d3eType(): string {
    return "RentalFacilitiesRequest";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isTransientModel(): boolean {
    return;
  }
  public get facilityType(): FacilityType {
    return this._facilityType;
  }
  public setFacilityType(val: FacilityType): void {
    let isValChanged: boolean = this._facilityType !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      RentalFacilitiesRequest._FACILITYTYPE,
      this._facilityType
    );

    this.updateObservable("facilityType", this._facilityType, val);

    this._facilityType = val;

    this.fire("facilityType", this);
  }
  public get state(): string {
    return this._state;
  }
  public setState(val: string): void {
    let isValChanged: boolean = this._state !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalFacilitiesRequest._STATE, this._state);

    this._state = val;

    this.fire("state", this);
  }
  public get city(): string {
    return this._city;
  }
  public setCity(val: string): void {
    let isValChanged: boolean = this._city !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalFacilitiesRequest._CITY, this._city);

    this._city = val;

    this.fire("city", this);
  }
  public get pageSize(): number {
    return this._pageSize;
  }
  public setPageSize(val: number): void {
    let isValChanged: boolean = this._pageSize !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalFacilitiesRequest._PAGESIZE, this._pageSize);

    this._pageSize = val;

    this.fire("pageSize", this);
  }
  public get offset(): number {
    return this._offset;
  }
  public setOffset(val: number): void {
    let isValChanged: boolean = this._offset !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalFacilitiesRequest._OFFSET, this._offset);

    this._offset = val;

    this.fire("offset", this);
  }
  public get(field: number): any {
    switch (field) {
      case RentalFacilitiesRequest._FACILITYTYPE: {
        return this._facilityType;
      }

      case RentalFacilitiesRequest._STATE: {
        return this._state;
      }

      case RentalFacilitiesRequest._CITY: {
        return this._city;
      }

      case RentalFacilitiesRequest._PAGESIZE: {
        return this._pageSize;
      }

      case RentalFacilitiesRequest._OFFSET: {
        return this._offset;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): RentalFacilitiesRequest {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: RentalFacilitiesRequest = dbObj as RentalFacilitiesRequest;

    obj.id = this.id;

    obj.setFacilityType(this._facilityType);

    obj.setState(this._state);

    obj.setCity(this._city);

    obj.setPageSize(this._pageSize);

    obj.setOffset(this._offset);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case RentalFacilitiesRequest._FACILITYTYPE: {
        this.setFacilityType(value as FacilityType);
        break;
      }

      case RentalFacilitiesRequest._STATE: {
        this.setState(value as string);
        break;
      }

      case RentalFacilitiesRequest._CITY: {
        this.setCity(value as string);
        break;
      }

      case RentalFacilitiesRequest._PAGESIZE: {
        this.setPageSize(value as number);
        break;
      }

      case RentalFacilitiesRequest._OFFSET: {
        this.setOffset(value as number);
        break;
      }
    }
  }
}
