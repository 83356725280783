import School from "./School";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class Equipment extends DBObject {
  private static readonly _NAME: number = 0;
  private static readonly _RATEPERHOUR: number = 1;
  private static readonly _SCHOOL: number = 2;
  public id: number = 0;
  public otherMaster: DBObject;
  private _name: string = "";
  private _ratePerHour: number = 0.0;
  private _school: School = null;
  public constructor(
    d3eParams?: Partial<{ name: string; ratePerHour: number; school: School }>
  ) {
    super();

    this.setName(d3eParams?.name ?? "");

    this.setRatePerHour(d3eParams?.ratePerHour ?? 0.0);

    this.setSchool(d3eParams?.school ?? null);
  }
  public get d3eType(): string {
    return "Equipment";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get name(): string {
    return this._name;
  }
  public setName(val: string): void {
    let isValChanged: boolean = this._name !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Equipment._NAME, this._name);

    this._name = val;

    this.fire("name", this);
  }
  public get ratePerHour(): number {
    return this._ratePerHour;
  }
  public setRatePerHour(val: number): void {
    let isValChanged: boolean = this._ratePerHour !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Equipment._RATEPERHOUR, this._ratePerHour);

    this._ratePerHour = val;

    this.fire("ratePerHour", this);
  }
  public get school(): School {
    return this._school;
  }
  public setSchool(val: School): void {
    let isValChanged: boolean = this._school !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Equipment._SCHOOL, this._school);

    this.updateObservable("school", this._school, val);

    this._school = val;

    this.fire("school", this);
  }
  public get(field: number): any {
    switch (field) {
      case Equipment._NAME: {
        return this._name;
      }

      case Equipment._RATEPERHOUR: {
        return this._ratePerHour;
      }

      case Equipment._SCHOOL: {
        return this._school;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): Equipment {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: Equipment = dbObj as Equipment;

    obj.id = this.id;

    obj.setName(this._name);

    obj.setRatePerHour(this._ratePerHour);

    obj.setSchool(this._school);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case Equipment._NAME: {
        this.setName(value as string);
        break;
      }

      case Equipment._RATEPERHOUR: {
        this.setRatePerHour(value as number);
        break;
      }

      case Equipment._SCHOOL: {
        this.setSchool(value as School);
        break;
      }
    }
  }
}
