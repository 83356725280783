import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import School from "../models/School";
import MaterialIcons from "../icons/MaterialIcons";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

export interface SchoolMapPinProps extends BaseUIProps {
  key?: string;
  school: School;
}

class _SchoolMapPinState extends ObservableComponent<SchoolMapPinProps> {
  static defaultProps = { school: null };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SchoolMapPinProps) {
    super(props);

    this.initState();
  }
  public get school(): School {
    return this.props.school;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("school", this.props.school);
  }
  public componentDidUpdate(prevProps: SchoolMapPinProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.school !== this.props.school) {
      this.updateObservable("school", prevProps.school, this.props.school);

      this.fire("school", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return IconView({
      icon: MaterialIcons.location_pin,
      color: cStyle.c16,
      className: ui.join(this.props.className, "SchoolMapPin xa95 hc"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function SchoolMapPin(props: SchoolMapPinProps) {
  return React.createElement(_SchoolMapPinState, {
    ..._SchoolMapPinState.defaultProps,
    ...props,
  });
}
