import { ReactNode } from "react";
import DBObject from "./DBObject";
import * as __d3ett from "../rocket/D3ETemplateTypes";
import Result from "../classes/Result";
import { RentalDocumentPageProps } from "../components/RentalDocumentPage";
import { MainPageProps } from "../components/MainPage";
import { RentalPaymentHistoryPageProps } from "../components/RentalPaymentHistoryPage";
import { PaymentMethodsPageProps } from "../components/PaymentMethodsPage";
import { RentalHomePageProps } from "../components/RentalHomePage";
import { RentalForgotPasswordPageProps } from "../components/RentalForgotPasswordPage";
import { RentelSignupPageProps } from "../components/RentelSignupPage";
import { RequestOverViewPageProps } from "../components/RequestOverViewPage";
import { RentalChangePasswordPageProps } from "../components/RentalChangePasswordPage";
import { RentalDashboardPageProps } from "../components/RentalDashboardPage";
import { MainHomePageProps } from "../components/MainHomePage";
import { SearchPageProps } from "../components/SearchPage";
import { BookingPageProps } from "../components/BookingPage";
import { EventCalendarPageProps } from "../components/EventCalendarPage";
import { RentalReservationPageProps } from "../components/RentalReservationPage";
import { RentalLoginPageProps } from "../components/RentalLoginPage";
import { RentalCurrentInvoicePageProps } from "../components/RentalCurrentInvoicePage";
import { RentalInsurancePageProps } from "../components/RentalInsurancePage";

export default class GlobalFunctions {
  public static save: (obj: DBObject) => Promise<Result<any>>;
  public static delete: (obj: DBObject) => Promise<Result<any>>;
  public static objectSave: (obj: DBObject, create: boolean) => void;
  public static fieldChanged: (
    obj: DBObject,
    field: number,
    oldValue: any
  ) => void;
  public static objectDelete: (obj: DBObject) => void;
  public static typeInt: (obj: string) => number;
  public static types: Array<__d3ett.D3ETemplateType>;
  public static BookingPage: (props: BookingPageProps) => ReactNode;
  public static EventCalendarPage: (props: EventCalendarPageProps) => ReactNode;
  public static MainHomePage: (props: MainHomePageProps) => ReactNode;
  public static MainPage: (props: MainPageProps) => ReactNode;
  public static PaymentMethodsPage: (
    props: PaymentMethodsPageProps
  ) => ReactNode;
  public static RentalChangePasswordPage: (
    props: RentalChangePasswordPageProps
  ) => ReactNode;
  public static RentalCurrentInvoicePage: (
    props: RentalCurrentInvoicePageProps
  ) => ReactNode;
  public static RentalDashboardPage: (
    props: RentalDashboardPageProps
  ) => ReactNode;
  public static RentalDocumentPage: (
    props: RentalDocumentPageProps
  ) => ReactNode;
  public static RentalForgotPasswordPage: (
    props: RentalForgotPasswordPageProps
  ) => ReactNode;
  public static RentalHomePage: (props: RentalHomePageProps) => ReactNode;
  public static RentalInsurancePage: (
    props: RentalInsurancePageProps
  ) => ReactNode;
  public static RentalLoginPage: (props: RentalLoginPageProps) => ReactNode;
  public static RentalPaymentHistoryPage: (
    props: RentalPaymentHistoryPageProps
  ) => ReactNode;
  public static RentalReservationPage: (
    props: RentalReservationPageProps
  ) => ReactNode;
  public static RentelSignupPage: (props: RentelSignupPageProps) => ReactNode;
  public static RequestOverViewPage: (
    props: RequestOverViewPageProps
  ) => ReactNode;
  public static SearchPage: (props: SearchPageProps) => ReactNode;
}
