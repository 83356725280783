import School from "./School";
import Renter from "./Renter";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class PendingRentalRequestsRequest extends DBObject {
  private static readonly _RENTER: number = 0;
  private static readonly _SCHOOL: number = 1;
  public id: number = 0;
  public otherMaster: DBObject;
  private _school: School = null;
  private _renter: Renter = null;
  public constructor(d3eParams?: Partial<{ renter: Renter; school: School }>) {
    super();

    this.setRenter(d3eParams?.renter ?? null);

    this.setSchool(d3eParams?.school ?? null);
  }
  public get d3eType(): string {
    return "PendingRentalRequestsRequest";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isTransientModel(): boolean {
    return;
  }
  public get school(): School {
    return this._school;
  }
  public setSchool(val: School): void {
    let isValChanged: boolean = this._school !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(PendingRentalRequestsRequest._SCHOOL, this._school);

    this.updateObservable("school", this._school, val);

    this._school = val;

    this.fire("school", this);
  }
  public get renter(): Renter {
    return this._renter;
  }
  public setRenter(val: Renter): void {
    let isValChanged: boolean = this._renter !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(PendingRentalRequestsRequest._RENTER, this._renter);

    this.updateObservable("renter", this._renter, val);

    this._renter = val;

    this.fire("renter", this);
  }
  public get(field: number): any {
    switch (field) {
      case PendingRentalRequestsRequest._SCHOOL: {
        return this._school;
      }

      case PendingRentalRequestsRequest._RENTER: {
        return this._renter;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): PendingRentalRequestsRequest {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: PendingRentalRequestsRequest =
      dbObj as PendingRentalRequestsRequest;

    obj.id = this.id;

    obj.setSchool(this._school);

    obj.setRenter(this._renter);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case PendingRentalRequestsRequest._SCHOOL: {
        this.setSchool(value as School);
        break;
      }

      case PendingRentalRequestsRequest._RENTER: {
        this.setRenter(value as Renter);
        break;
      }
    }
  }
}
