import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import D3EDate from "../classes/D3EDate";
import RentalRequest from "../models/RentalRequest";
import PageNavigator from "../classes/PageNavigator";
import Renter from "../models/Renter";
import RequestStatus from "../classes/RequestStatus";
import Query from "../classes/Query";
import DateTimeView from "./DateTimeView";
import CellButton from "./CellButton";
import ListWrapper from "../utils/ListWrapper";
import RentalRequestsRequest from "../models/RentalRequestsRequest";
import TextView from "./TextView";
import TimeView from "./TimeView";
import CollectionUtils from "../utils/CollectionUtils";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _ViewButtonOnPressed = (d3eState: _ItemState) => void;

export interface RentalUpcomingEventViewProps extends BaseUIProps {
  key?: string;
  renter: Renter;
}
/// To store state data for RentalUpcomingEventView
class RentalUpcomingEventViewRefs {
  public itemState: Map<RentalRequest, _ItemState> = new Map();
  public forItem(item: RentalRequest): _ItemState {
    let res = this.itemState.get(item);

    if (res == null) {
      res = new _ItemState(this, item);

      this.itemState.set(item, res);
    }

    return res;
  }
}

interface ViewButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _ItemState;
  _onViewHandler?: _ViewButtonOnPressed;
  item: RentalRequest;
}

class ViewButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ViewButtonWithState extends ObservableComponent<ViewButtonWithStateProps> {
  viewButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ViewButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get item(): RentalRequest {
    return this.props.item;
  }
  public get viewButton(): ViewButtonState {
    return this.props.d3eState.viewButton;
  }
  public get d3eState(): _ItemState {
    return this.props.d3eState;
  }
  public get _onViewHandler(): _ViewButtonOnPressed {
    return this.props._onViewHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("viewButton", null, this.viewButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("item", this.props.item);

    this.on(
      [
        "item",
        "item.requestId",
        "viewButton",
        "viewButton.",
        "viewButton.hover",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ViewButtonWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.item !== this.props.item) {
      this.updateObservable("item", prevProps.item, this.props.item);

      this.fire("item", this);
    }
  }
  public viewButtonOnEnter(event): void {
    return this.viewButton.setHover(true);
  }
  public viewButtonOnExit(event): void {
    return this.viewButton.setHover(false);
  }
  public dispose(): void {
    this.viewButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return CellButton({
      padding: this.viewButton.hover
        ? cStyle.tCellButtonLinkCellButtonPaddingOnHover
        : cStyle.tCellButtonLinkCellButtonPaddingOn,
      disable: this.viewButton.disable,
      onPressed: () => {
        this._onViewHandler(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({
        data: this.item.requestId,
        style: new ui.TextStyle({ fontSize: 16, color: cStyle.c20 }),
        className: "xd2a hc",
      }),
      onEnter: (event) => {
        this.viewButtonOnEnter(event);
      },
      onExit: (event) => {
        this.viewButtonOnExit(event);
      },
      className: "x2a46 hc",
    });
  }
}
function ViewButtonWithState(props: ViewButtonWithStateProps) {
  return React.createElement(_ViewButtonWithState, props);
}

class _ItemState {
  parent: RentalUpcomingEventViewRefs;
  item: RentalRequest;
  viewButton: ViewButtonState = new ViewButtonState();
  public constructor(parent, item) {
    this.parent = parent;

    this.item = item;
  }
}

class _RentalUpcomingEventViewState extends ObservableComponent<RentalUpcomingEventViewProps> {
  static defaultProps = { renter: null };
  d3eState: RentalUpcomingEventViewRefs = new RentalUpcomingEventViewRefs();
  upcomingEventList: Array<RentalRequest> = ListWrapper.widget(
    this,
    "upcomingEventList"
  );
  request: RentalRequest = null;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RentalUpcomingEventViewProps) {
    super(props);

    this.initState();
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("renter", this.props.renter);

    this.on(["renter"], this.computeUpcomingEventList);

    this.computeUpcomingEventList();

    this.on(
      [
        "upcomingEventList",
        "upcomingEventList.endTime",
        "upcomingEventList.eventDate",
        "upcomingEventList.eventName",
        "upcomingEventList.facility",
        "upcomingEventList.facility.name",
        "upcomingEventList.startTime",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RentalUpcomingEventViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public setUpcomingEventList(val: Array<RentalRequest>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.upcomingEventList,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("upcomingEventList", this.upcomingEventList, val);

    this.upcomingEventList.clear();

    this.upcomingEventList.addAll(val);

    this.fire("upcomingEventList", this);
  }
  public addToUpcomingEventList(val: RentalRequest, index: number = -1): void {
    if (index === -1) {
      if (!this.upcomingEventList.contains(val))
        this.upcomingEventList.add(val);
    } else {
      this.upcomingEventList.remove(this.upcomingEventList.elementAt(index));

      this.upcomingEventList.add(val);
    }

    this.fire("upcomingEventList", this, val, true);

    this.updateObservable("upcomingEventList", null, val);
  }
  public removeFromUpcomingEventList(val: RentalRequest): void {
    this.upcomingEventList.remove(val);

    this.fire("upcomingEventList", this, val, false);

    this.removeObservable("upcomingEventList", val);
  }
  public computeUpcomingEventList = async (): Promise<void> => {
    try {
      this.setUpcomingEventList(
        Array.from(
          (
            await Query.get().getRentalRequests(
              UsageConstants.QUERY_GETRENTALREQUESTS_RENTALUPCOMINGEVENTVIEW_PROPERTIES_UPCOMINGEVENTLIST_COMPUTATION,
              new RentalRequestsRequest({
                applyStatus: true,
                status: RequestStatus.Approved,
                fromDate: D3EDate.now(),
                renter: this.renter,
                pageSize: 100,
                offset: 0,
              })
            )
          )?.items ?? []
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeUpcomingEventList : " + exception.toString()
      );

      this.setUpcomingEventList([]);
    }
  };
  public setRequest(val: RentalRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          children: [
            TextView({
              data: "Upcoming Events",
              style: new ui.TextStyle({ fontWeight: ui.FontWeight.w600 }),
              className: "x76b6",
              key: "0",
            }),
          ],
          className: "x4dc7 hc h",
          key: "0",
        }),
        this.upcomingEventList.isNotEmpty
          ? ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.Table({
                  defaultColumnWidth: new ui.FlexColumnWidth(1),
                  columnWidths: { 0: new ui.FlexColumnWidth(1) },
                  children: [
                    ui.TableRow({
                      children: [
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: "Res. ID",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "xf4a0 hc",
                          }),
                          className: "hc",
                          key: "0",
                        }),
                        ui.TableCell({
                          child: TextView({
                            data: "Event Name",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "xca5 hc",
                          }),
                          className: "hc",
                          key: "1",
                        }),
                        ui.TableCell({
                          child: TextView({
                            data: "Facility Name",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "xc9a hc",
                          }),
                          className: "hc",
                          key: "2",
                        }),
                        ui.TableCell({
                          child: TextView({
                            data: "Event Date",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "xd4d hc",
                          }),
                          className: "hc",
                          key: "3",
                        }),
                        ui.TableCell({
                          child: TextView({
                            data: "Duration",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "x8ae hc",
                          }),
                          className: "hc",
                          key: "4",
                        }),
                      ],
                      className: "x2f40 hc",
                      key: "0",
                    }),
                    this.upcomingEventList.expand((item) => [
                      ui.TableRow({
                        children: [
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: ViewButtonWithState({
                              d3eState: this.d3eState.forItem(item),
                              _onViewHandler: this.onViewHandler,
                              item: item,
                            }),
                            className: "hc",
                            key: "0",
                          }),
                          ui.TableCell({
                            child: TextView({
                              data: item.eventName,
                              softWrap: true,
                              style: new ui.TextStyle({
                                fontFamily:
                                  cStyle.tTextViewSubHeaderFontFamilyOn,
                                color: cStyle.tTextViewSubHeaderColorOn,
                              }),
                              className: "x4da hc",
                            }),
                            className: "hc",
                            key: "1",
                          }),
                          ui.TableCell({
                            child: TextView({
                              data: item.facility.name,
                              style: new ui.TextStyle({
                                fontFamily:
                                  cStyle.tTextViewSubHeaderFontFamilyOn,
                                color: cStyle.tTextViewSubHeaderColorOn,
                              }),
                              className: "x9cf hc",
                            }),
                            className: "hc",
                            key: "2",
                          }),
                          ui.TableCell({
                            child: DateTimeView({
                              value: item.eventDate.toDateTime(),
                              format: "MMM dd, yyyy",
                              className: "hc",
                            }),
                            className: "hc",
                            key: "3",
                          }),
                          ui.TableCell({
                            child: ui.Wrap({
                              spacing: 5,
                              children: [
                                TimeView({
                                  value: item.startTime,
                                  format: "hh:mm a",
                                  className: "hc",
                                  key: "0",
                                }),
                                TextView({
                                  data: "-",
                                  className: "hc",
                                  key: "1",
                                }),
                                TimeView({
                                  value: item.endTime,
                                  format: "hh:mm a",
                                  className: "hc",
                                  key: "2",
                                }),
                              ],
                              className: "hc",
                            }),
                            className: "hc",
                            key: "4",
                          }),
                        ],
                        className: "xc9a1 hc",
                        key: item?.ident,
                      }),
                    ]),
                  ],
                  className: "xa440 hc h",
                  key: "0",
                }),
              ],
              className: "x323 hc h",
            })
          : ui.Column({
              children: [
                TextView({
                  data: "No Upcoming Events",
                  className: "hc",
                  key: "0",
                }),
              ],
              className: "hc",
            }),
      ],
      className: ui.join(
        this.props.className,
        "RentalUpcomingEventView x6cd hc h"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onViewHandler = (d3eState: _ItemState): void => {
    // TODO: add logic to navigate to the Request overview page

    this.setRequest(d3eState.item);

    this.navigator.pushRequestOverViewPage({
      admin: null,
      renter: this.renter,
      requestObj: this.request,
      target: "rRouter",
      replace: false,
    });
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function RentalUpcomingEventView(
  props: RentalUpcomingEventViewProps
) {
  return React.createElement(_RentalUpcomingEventViewState, {
    ..._RentalUpcomingEventViewState.defaultProps,
    ...props,
  });
}
