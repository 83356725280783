import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import TextView from "./TextView";
import PageNavigator from "../classes/PageNavigator";
import Renter from "../models/Renter";
import { BuildContext } from "../classes/BuildContext";

type _OnProfilePressedOnPressed = (d3eState: RentalHeaderViewRefs) => void;

export interface RentalHeaderViewProps extends BaseUIProps {
  key?: string;
  renter: Renter;
}
/// To store state data for RentalHeaderView
class RentalHeaderViewRefs {
  public onProfilePressed: OnProfilePressedState = new OnProfilePressedState();
}

interface OnProfilePressedWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalHeaderViewRefs;
  _onProfilePressedHandler?: _OnProfilePressedOnPressed;
  renter: Renter;
}

class OnProfilePressedState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _OnProfilePressedWithState extends ObservableComponent<OnProfilePressedWithStateProps> {
  onProfilePressedFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: OnProfilePressedWithStateProps) {
    super(props);

    this.initState();
  }
  public get onProfilePressed(): OnProfilePressedState {
    return this.props.d3eState.onProfilePressed;
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public get d3eState(): RentalHeaderViewRefs {
    return this.props.d3eState;
  }
  public get _onProfilePressedHandler(): _OnProfilePressedOnPressed {
    return this.props._onProfilePressedHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("onProfilePressed", null, this.onProfilePressed);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("renter", this.props.renter);

    this.on(["onProfilePressed", "renter", "renter.fullName"], this.rebuild);
  }
  public componentDidUpdate(prevProps: OnProfilePressedWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.onProfilePressed.disable,
      onPressed: () => {
        this._onProfilePressedHandler(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({
        data: "Hi " + this.renter.fullName,
        style: new ui.TextStyle({ color: cStyle.c2, fontSize: 18 }),
        className: "x7fc1",
      }),
    });
  }
}
function OnProfilePressedWithState(props: OnProfilePressedWithStateProps) {
  return React.createElement(_OnProfilePressedWithState, props);
}

class _RentalHeaderViewState extends ObservableComponent<RentalHeaderViewProps> {
  static defaultProps = { renter: null };
  d3eState: RentalHeaderViewRefs = new RentalHeaderViewRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RentalHeaderViewProps) {
    super(props);

    this.initState();
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("renter", this.props.renter);

    this.on(["renter"], this.rebuild);
  }
  public componentDidUpdate(prevProps: RentalHeaderViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Row({
      mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
      children: [
        ui.Row({
          children: [
            ui.AssetImage({
              path: "images/Logo123.png",
              height: 45,
              width: 100,
              className: "x6ce hc vc",
              key: "0",
            }),
          ],
          key: "0",
        }),
        this.renter !== null
          ? OnProfilePressedWithState({
              d3eState: this.d3eState,
              _onProfilePressedHandler: this.onProfilePressedHandler,
              renter: this.renter,
            })
          : [],
      ],
      className: ui.join(this.props.className, "RentalHeaderView xd8b hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onProfilePressedHandler = (d3eState: RentalHeaderViewRefs): void => {
    this.navigator.pushRentalHomePage({ renter: this.renter, replace: true });
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get onProfilePressed() {
    return this.d3eState.onProfilePressed;
  }
}
export default function RentalHeaderView(props: RentalHeaderViewProps) {
  return React.createElement(_RentalHeaderViewState, {
    ..._RentalHeaderViewState.defaultProps,
    ...props,
  });
}
