import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import RentalRequest from "../models/RentalRequest";
import Message from "../models/Message";
import Query from "../classes/Query";
import MessageDispatch from "../rocket/MessageDispatch";
import MessageListRequest from "../models/MessageListRequest";
import DateTimeView from "./DateTimeView";
import ListWrapper from "../utils/ListWrapper";
import CheckboxWithText from "./CheckboxWithText";
import TextView from "./TextView";
import LightDivider from "./LightDivider";
import CollectionUtils from "../utils/CollectionUtils";
import MessageType from "../classes/MessageType";
import MessageList from "../classes/MessageList";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

export interface CommentHistoryViewProps extends BaseUIProps {
  key?: string;
  rentalRequest: RentalRequest;
}

class _CommentHistoryViewState extends ObservableComponent<CommentHistoryViewProps> {
  static defaultProps = { rentalRequest: null };
  query: MessageList = null;
  messages: Array<Message> = ListWrapper.widget(this, "messages");
  request: MessageListRequest = null;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CommentHistoryViewProps) {
    super(props);

    this.initState();
  }
  public get rentalRequest(): RentalRequest {
    return this.props.rentalRequest;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("rentalRequest", this.props.rentalRequest);

    this.on(
      [
        "request",
        "request.ascending",
        "request.needComment",
        "request.needHistory",
        "request.orderBy",
        "request.request",
      ],
      this.computeQuery
    );

    this.computeQuery();

    this.on(["query", "query.items"], this.computeMessages);

    this.computeMessages();

    this.on(
      [
        "messages",
        "messages.admin",
        "messages.admin.fullName",
        "messages.createdDate",
        "messages.message",
        "messages.renter",
        "messages.renter.fullName",
        "messages.type",
        "request",
        "request.ascending",
        "request.needComment",
        "request.needHistory",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: CommentHistoryViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.rentalRequest !== this.props.rentalRequest) {
      this.updateObservable(
        "rentalRequest",
        prevProps.rentalRequest,
        this.props.rentalRequest
      );

      this.fire("rentalRequest", this);
    }
  }
  public setQuery(val: MessageList): void {
    let isValChanged: boolean = this.query !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("query", this.query, val);

    MessageDispatch.get().dispose(this.query);

    this.query = val;

    this.fire("query", this);
  }
  public computeQuery = async (): Promise<void> => {
    try {
      this.setQuery(
        await Query.get().getMessageList(
          UsageConstants.QUERY_GETMESSAGELIST_COMMENTHISTORYVIEW_PROPERTIES_QUERY_COMPUTATION,
          this.request,
          { "synchronize": true }
        )
      );
    } catch (exception) {
      console.log(" exception in computeQuery : " + exception.toString());

      this.setQuery(null);
    }
  };
  public setMessages(val: Array<Message>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.messages, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("messages", this.messages, val);

    this.messages.clear();

    this.messages.addAll(val);

    this.fire("messages", this);
  }
  public addToMessages(val: Message, index: number = -1): void {
    if (index === -1) {
      if (!this.messages.contains(val)) this.messages.add(val);
    } else {
      this.messages.remove(this.messages.elementAt(index));

      this.messages.add(val);
    }

    this.fire("messages", this, val, true);

    this.updateObservable("messages", null, val);
  }
  public removeFromMessages(val: Message): void {
    this.messages.remove(val);

    this.fire("messages", this, val, false);

    this.removeObservable("messages", val);
  }
  public computeMessages = (): void => {
    try {
      this.setMessages(Array.from(this.query !== null ? this.query.items : []));
    } catch (exception) {
      console.log(" exception in computeMessages : " + exception.toString());

      this.setMessages([]);
    }
  };
  public setRequest(val: MessageListRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      mainAxisAlignment: ui.MainAxisAlignment.start,
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.start,
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                CheckboxWithText({
                  value: this.request.ascending,
                  title: "Newest First",
                  activeColor: cStyle.c19,
                  onChanged: (value) => {
                    this.onSortCheckBoxChanged(value);
                  },
                  key: "0",
                }),
              ],
              key: "0",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.end,
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                CheckboxWithText({
                  value: this.request.needHistory,
                  title: "History",
                  activeColor: cStyle.c19,
                  onChanged: (value) => {
                    this.onHistoryCheckBoxChanged(value);
                  },
                  key: "0",
                }),
                CheckboxWithText({
                  value: this.request.needComment,
                  title: "Comments",
                  activeColor: cStyle.c19,
                  onChanged: (value) => {
                    this.onCommentCheckBoxChanged(value);
                  },
                  key: "1",
                }),
              ],
              key: "1",
            }),
          ],
          className: "x4ad hc h",
          key: "0",
        }),
        LightDivider({
          dividerColor: cStyle.c14,
          className: "xf64 hc h",
          key: "1",
        }),
        ui.Column({
          mainAxisAlignment: ui.MainAxisAlignment.start,
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            this.messages.expand((commentItem) => [
              ui.Column({
                mainAxisAlignment: ui.MainAxisAlignment.start,
                crossAxisAlignment: ui.CrossAxisAlignment.start,
                children: [
                  ui.Wrap({
                    spacing: 5,
                    children: [
                      DateTimeView({
                        value: commentItem.createdDate,
                        format: "dd/MM/yyyy HH:mm a",
                        className: "hc",
                        key: "0",
                      }),
                      TextView({
                        data:
                          commentItem.admin !== null
                            ? commentItem.admin.fullName
                            : commentItem.renter.fullName,
                        className: "hc",
                        key: "1",
                      }),
                      TextView({
                        data:
                          commentItem.type === MessageType.Comment
                            ? "Comment :" + commentItem.message
                            : commentItem.message,
                        states: ui.joinStates(
                          {
                            "data-c0": commentItem.type === MessageType.History,
                          },
                          {}
                        ),
                        style: new ui.TextStyle({
                          fontWeight:
                            commentItem.type === MessageType.History
                              ? ui.FontWeight.bold
                              : null,
                        }),
                        className: "x16e hc",
                        key: "2",
                      }),
                    ],
                    className: "hc",
                    key: "0",
                  }),
                  LightDivider({
                    dividerColor: new ui.Color(0xffdcdcdc),
                    className: "xbf hc h",
                    key: "1",
                  }),
                ],
                className: "xcb hc h",
                key: commentItem?.ident,
              }),
            ]),
          ],
          className: "x891 hc vc h",
          key: "2",
        }),
      ],
      className: ui.join(
        this.props.className,
        "CommentHistoryView xc40 hc vc h v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setRequest(
      new MessageListRequest({
        needHistory: true,
        needComment: true,
        request: this.rentalRequest,
        ascending: false,
        orderBy: "createdDate",
      })
    );
  };
  public onHistoryCheckBoxChanged = (value: boolean): void => {
    this.request.setNeedHistory(value);

    this.setRequest(this.request.deepClone());
  };
  public onCommentCheckBoxChanged = (value: boolean): void => {
    this.request.setNeedComment(value);

    this.setRequest(this.request.deepClone());
  };
  public onSortCheckBoxChanged = (value: boolean): void => {
    this.request.setAscending(value);

    this.setRequest(this.request.deepClone());
  };
  public dispose(): void {
    MessageDispatch.get().dispose(this.query);

    super.dispose();
  }
}
export default function CommentHistoryView(props: CommentHistoryViewProps) {
  return React.createElement(_CommentHistoryViewState, {
    ..._CommentHistoryViewState.defaultProps,
    ...props,
  });
}
