import Time from "./Time";
import RentalRequest from "../models/RentalRequest";
import FacilityRate from "../models/FacilityRate";
import Facility from "../models/Facility";

export default class RentalRequestUtils {
  public constructor() {}
  public static getTopPriceRate(facility: Facility): FacilityRate {
    facility.prices.sort((a, b) => b.price.compareTo(a.price));

    return facility.prices.first;
  }
  public static getBlockedStartSlots(
    requests: Array<RentalRequest>
  ): Array<Time> {
    let orderByStartTime: Array<RentalRequest> = Array.from(requests);

    orderByStartTime.sort((a, b) =>
      a.startTime.toMillOfDay().compareTo(b.startTime.toMillOfDay())
    );

    let times: Array<Time> = new Array();

    times.add(Time.of(0, 0, 0, 0));

    times.add(Time.of(6, 30, 0, 0));

    orderByStartTime.forEach((item) => {
      times.add(item.startTime);

      times.add(item.endTime.plusMinutes(-30));
    });

    times.add(Time.of(22, 0, 0, 0));

    times.add(Time.of(23, 50, 0, 0));

    return times;
  }
  public static getBlockedEndSlots(
    requests: Array<RentalRequest>,
    startTime: Time
  ): Array<Time> {
    let orderByStartTime: Array<RentalRequest> = Array.from(requests);

    orderByStartTime.sort((a, b) =>
      a.startTime.toMillOfDay().compareTo(b.startTime.toMillOfDay())
    );

    let times: Array<Time> = new Array();

    times.add(Time.of(0, 0, 0, 0));

    if (startTime === null) {
      startTime = Time.of(0, 0, 0, 0);
    }

    let next: Time = startTime.plusMinutes(30);

    times.add(next);

    for (let i: number = 0; i < orderByStartTime.length; i++) {
      let r: RentalRequest = orderByStartTime[i];

      if (r.endTime.isBefore(next)) {
        continue;
      }

      times.add(r.startTime);

      times.add(Time.of(23, 50, 0, 0));

      return times;
    }

    times.add(Time.of(22, 0, 0, 0));

    times.add(Time.of(23, 50, 0, 0));

    return times;
  }
}
