import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ListWrapper from "../utils/ListWrapper";
import GoogleMap from "./GoogleMap";
import Facility from "../models/Facility";
import FacilityMapPin from "./FacilityMapPin";
import GoogleMapMarker from "./GoogleMapMarker";

export interface FacilitiesMapViewProps extends BaseUIProps {
  key?: string;
  facilities: Array<Facility>;
  _facilitiesHash?: number;
}

class _FacilitiesMapViewState extends ObservableComponent<FacilitiesMapViewProps> {
  static defaultProps = { facilities: [] };
  public constructor(props: FacilitiesMapViewProps) {
    super(props);

    this.initState();
  }
  public get facilities(): Array<Facility> {
    return this.props.facilities;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.facilities, "facilities");

    this.updateSyncCollProperty("facilities", this.props.facilities);

    this.on(
      [
        "facilities",
        "facilities.school",
        "facilities.school.latitude",
        "facilities.school.longitude",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: FacilitiesMapViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.facilities !== this.props.facilities) {
      this.updateObservableColl(
        "facilities",
        prevProps.facilities,
        this.props.facilities
      );

      this.fire("facilities", this);
    }
  }
  public render(): ReactNode {
    return GoogleMap({
      centerLat: this.facilities.isEmpty
        ? null
        : Number.parseFloat(this.facilities.first.school.latitude),
      centerLng: this.facilities.isEmpty
        ? null
        : Number.parseFloat(this.facilities.first.school.longitude),
      children: [
        this.facilities.expand((facility) => [
          GoogleMapMarker({
            lat: Number.parseFloat(facility.school.latitude),
            lng: Number.parseFloat(facility.school.longitude),
            child: FacilityMapPin({ facility: facility, className: "hc" }),
            className: "hc",
            key: facility?.ident,
          }),
        ]),
      ],
      className: ui.join(this.props.className, "FacilitiesMapView hc"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function FacilitiesMapView(props: FacilitiesMapViewProps) {
  return React.createElement(
    _FacilitiesMapViewState,
    { ..._FacilitiesMapViewState.defaultProps, ...props },
    ListWrapper.fromInput<Facility>(props.facilities, "facilities")
  );
}
