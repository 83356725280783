import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import CalendarHelper from "../classes/CalendarHelper";
import PopupTargetController from "./PopupTargetController";
import DayView from "./DayView";
import CalenderView from "./CalenderView";
import CalendarViewType from "../classes/CalendarViewType";
import D3EDate from "../classes/D3EDate";
import Popup from "./Popup";
import MaterialIcons from "../icons/MaterialIcons";
import MonthView from "./MonthView";
import CalenderUtils from "../classes/CalenderUtils";
import DateView from "./DateView";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import YearView from "./YearView";
import DateTime from "../core/DateTime";
import CalendarEvent from "../classes/CalendarEvent";
import TextView from "./TextView";
import EventCategorySelectionView from "./EventCategorySelectionView";
import CollectionUtils from "../utils/CollectionUtils";
import IconView from "./IconView";
import WeekView from "./WeekView";
import DropDown from "./DropDown";
import { BuildContext } from "../classes/BuildContext";

type _EventCalenderViewBuilder<CalendarEvent, CalendarViewType> = (
  context: any,
  event: CalendarEvent,
  viewType: CalendarViewType
) => ReactNode;

type _PreviousYearOnPressed = (d3eState: EventCalenderViewRefs) => void;

type _NextYearOnPressed = (d3eState: EventCalenderViewRefs) => void;

type _PreviousMonthOnPressed = (d3eState: EventCalenderViewRefs) => void;

type _NextMonthOnPressed = (d3eState: EventCalenderViewRefs) => void;

type _PreviousWeekOnPressed = (d3eState: EventCalenderViewRefs) => void;

type _NextWeekOnPressed = (d3eState: EventCalenderViewRefs) => void;

type _SelectedDateViewOnPressed = (d3eState: EventCalenderViewRefs) => void;

type _Id2OnTap = (d3eState: EventCalenderViewRefs) => void;

export interface EventCalenderViewProps extends BaseUIProps {
  key?: string;
  inputDate?: D3EDate;
  events?: Array<CalendarEvent>;
  viewType?: CalendarViewType;
  yearWeekBgColor?: ui.Color;
  yearCurrentDateColor?: ui.Color;
  disable?: boolean;
  _eventsHash?: number;
  builder?: _EventCalenderViewBuilder<CalendarEvent, CalendarViewType>;
}
/// To store state data for EventCalenderView
class EventCalenderViewRefs {
  public id2: Id2State = new Id2State();
  public nextMonth: NextMonthState = new NextMonthState();
  public nextWeek: NextWeekState = new NextWeekState();
  public nextYear: NextYearState = new NextYearState();
  public previousMonth: PreviousMonthState = new PreviousMonthState();
  public previousWeek: PreviousWeekState = new PreviousWeekState();
  public previousYear: PreviousYearState = new PreviousYearState();
  public selectedDateView: SelectedDateViewState = new SelectedDateViewState();
  viewTypeDropDownFocusNode: ui.FocusNode = new ui.FocusNode();
}

interface Id2WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: EventCalenderViewRefs;
  _onShowDropDown?: _Id2OnTap;
}

class Id2State extends ObjectObservable {
  private _focus: boolean = false;
  public get focus(): boolean {
    return this._focus;
  }
  public setFocus(val: boolean) {
    let isValChanged: boolean = this._focus !== val;

    if (!isValChanged) {
      return;
    }

    this._focus = val;

    this.fire("focus", this);
  }
}

class _Id2WithState extends ObservableComponent<Id2WithStateProps> {
  public constructor(props: Id2WithStateProps) {
    super(props);

    this.initState();
  }
  public get id2(): Id2State {
    return this.props.d3eState.id2;
  }
  public get d3eState(): EventCalenderViewRefs {
    return this.props.d3eState;
  }
  public get _onShowDropDown(): _Id2OnTap {
    return this.props._onShowDropDown;
  }
  public initState() {
    super.initState();

    this.updateObservable("id2", null, this.id2);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["id2", "id2.focus"], this.rebuild);
  }
  public id2OnFocusChange(val): void {
    return this.id2.setFocus(val);
  }
  public dispose(): void {
    this.id2.setFocus(false);

    super.dispose();
  }
  public render(): ReactNode {
    return ui.Focus({
      focusNode: new ui.FocusNode(),
      child: ui.Container({
        child: ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          crossAxisAlignment: ui.CrossAxisAlignment.center,
          children: [
            TextView({
              data: "Filter By Category",
              overflow: ui.TextOverflow.ellipsis,
              style: new ui.TextStyle({
                fontFamily: "Open Sans",
                fontSize: 14,
              }),
              className: "xcdf hc vc h",
              key: "0",
            }),
            IconView({
              icon: MaterialIcons.arrow_drop_down,
              className: "vc",
              key: "1",
            }),
          ],
          className: "x4d hc vc h",
        }),
        onTap: (e) => {
          e.stopPropagation();

          this._onShowDropDown(this.d3eState);
        },
        className: "x03c hc vc",
      }),
      onFocusChange: (val) => {
        this.id2OnFocusChange(val);
      },
    });
  }
}
function Id2WithState(props: Id2WithStateProps) {
  return React.createElement(_Id2WithState, props);
}

interface SelectedDateViewWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: EventCalenderViewRefs;
  _onDateChange?: _SelectedDateViewOnPressed;
  selectedDateViewPopupTargetController: PopupTargetController;
  selectedDate: D3EDate;
}

class SelectedDateViewState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _SelectedDateViewWithState extends ObservableComponent<SelectedDateViewWithStateProps> {
  selectedDateViewFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SelectedDateViewWithStateProps) {
    super(props);

    this.initState();
  }
  public get selectedDate(): D3EDate {
    return this.props.selectedDate;
  }
  public get selectedDateView(): SelectedDateViewState {
    return this.props.d3eState.selectedDateView;
  }
  public get d3eState(): EventCalenderViewRefs {
    return this.props.d3eState;
  }
  public get _onDateChange(): _SelectedDateViewOnPressed {
    return this.props._onDateChange;
  }
  public get selectedDateViewPopupTargetController(): PopupTargetController {
    return this.props.selectedDateViewPopupTargetController;
  }
  public initState() {
    super.initState();

    this.updateObservable("selectedDateView", null, this.selectedDateView);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "selectedDate",
        "selectedDateView",
        "selectedDateView.",
        "selectedDateView.hover",
      ],
      this.rebuild
    );
  }
  public selectedDateViewOnEnter(event): void {
    return this.selectedDateView.setHover(true);
  }
  public selectedDateViewOnExit(event): void {
    return this.selectedDateView.setHover(false);
  }
  public dispose(): void {
    this.selectedDateView.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(10.0, 0.0, 0.0, 0.0, new Map()),
      child: Button({
        padding: cStyle.tButtonCalendarButtonPaddingOn,
        decoration: this.selectedDateView.hover
          ? cStyle.tButtonCalendarButtonDecorationOnHover
          : cStyle.tButtonCalendarButtonDecorationOn,
        disable: this.selectedDateView.disable,
        onPressed: () => {
          this._onDateChange(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: DateView({ value: this.selectedDate }),
        d3eRef: this.selectedDateViewPopupTargetController.handleRef,
        onEnter: (event) => {
          this.selectedDateViewOnEnter(event);
        },
        onExit: (event) => {
          this.selectedDateViewOnExit(event);
        },
      }),
      className: "x98",
    });
  }
}
function SelectedDateViewWithState(props: SelectedDateViewWithStateProps) {
  return React.createElement(_SelectedDateViewWithState, props);
}

interface NextWeekWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: EventCalenderViewRefs;
  _onPressedNextWeek?: _NextWeekOnPressed;
}

class NextWeekState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _NextWeekWithState extends ObservableComponent<NextWeekWithStateProps> {
  nextWeekFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: NextWeekWithStateProps) {
    super(props);

    this.initState();
  }
  public get nextWeek(): NextWeekState {
    return this.props.d3eState.nextWeek;
  }
  public get d3eState(): EventCalenderViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedNextWeek(): _NextWeekOnPressed {
    return this.props._onPressedNextWeek;
  }
  public initState() {
    super.initState();

    this.updateObservable("nextWeek", null, this.nextWeek);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["nextWeek", "nextWeek.", "nextWeek.hover"], this.rebuild);
  }
  public nextWeekOnEnter(event): void {
    return this.nextWeek.setHover(true);
  }
  public nextWeekOnExit(event): void {
    return this.nextWeek.setHover(false);
  }
  public dispose(): void {
    this.nextWeek.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      padding: cStyle.tButtonCalendarButtonPaddingOn,
      decoration: this.nextWeek.hover
        ? cStyle.tButtonCalendarButtonDecorationOnHover
        : cStyle.tButtonCalendarButtonDecorationOn,
      disable: this.nextWeek.disable,
      onPressed: () => {
        this._onPressedNextWeek(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: IconView({
        icon: MaterialIcons.arrow_forward,
        size: 13,
        className: "x1a3",
      }),
      onEnter: (event) => {
        this.nextWeekOnEnter(event);
      },
      onExit: (event) => {
        this.nextWeekOnExit(event);
      },
      className: "x33",
    });
  }
}
function NextWeekWithState(props: NextWeekWithStateProps) {
  return React.createElement(_NextWeekWithState, props);
}

interface PreviousWeekWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: EventCalenderViewRefs;
  _onPressedPreviousWeek?: _PreviousWeekOnPressed;
}

class PreviousWeekState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _PreviousWeekWithState extends ObservableComponent<PreviousWeekWithStateProps> {
  previousWeekFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: PreviousWeekWithStateProps) {
    super(props);

    this.initState();
  }
  public get previousWeek(): PreviousWeekState {
    return this.props.d3eState.previousWeek;
  }
  public get d3eState(): EventCalenderViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedPreviousWeek(): _PreviousWeekOnPressed {
    return this.props._onPressedPreviousWeek;
  }
  public initState() {
    super.initState();

    this.updateObservable("previousWeek", null, this.previousWeek);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["previousWeek", "previousWeek.", "previousWeek.hover"],
      this.rebuild
    );
  }
  public previousWeekOnEnter(event): void {
    return this.previousWeek.setHover(true);
  }
  public previousWeekOnExit(event): void {
    return this.previousWeek.setHover(false);
  }
  public dispose(): void {
    this.previousWeek.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      padding: cStyle.tButtonCalendarButtonPaddingOn,
      decoration: this.previousWeek.hover
        ? cStyle.tButtonCalendarButtonDecorationOnHover
        : cStyle.tButtonCalendarButtonDecorationOn,
      disable: this.previousWeek.disable,
      onPressed: () => {
        this._onPressedPreviousWeek(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: IconView({
        icon: MaterialIcons.arrow_back,
        size: 13,
        className: "xce8",
      }),
      onEnter: (event) => {
        this.previousWeekOnEnter(event);
      },
      onExit: (event) => {
        this.previousWeekOnExit(event);
      },
      className: "xe2",
    });
  }
}
function PreviousWeekWithState(props: PreviousWeekWithStateProps) {
  return React.createElement(_PreviousWeekWithState, props);
}

interface NextMonthWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: EventCalenderViewRefs;
  _onPressedNextMonth?: _NextMonthOnPressed;
}

class NextMonthState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _NextMonthWithState extends ObservableComponent<NextMonthWithStateProps> {
  nextMonthFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: NextMonthWithStateProps) {
    super(props);

    this.initState();
  }
  public get nextMonth(): NextMonthState {
    return this.props.d3eState.nextMonth;
  }
  public get d3eState(): EventCalenderViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedNextMonth(): _NextMonthOnPressed {
    return this.props._onPressedNextMonth;
  }
  public initState() {
    super.initState();

    this.updateObservable("nextMonth", null, this.nextMonth);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["nextMonth", "nextMonth.", "nextMonth.hover"], this.rebuild);
  }
  public nextMonthOnEnter(event): void {
    return this.nextMonth.setHover(true);
  }
  public nextMonthOnExit(event): void {
    return this.nextMonth.setHover(false);
  }
  public dispose(): void {
    this.nextMonth.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      padding: cStyle.tButtonCalendarButtonPaddingOn,
      decoration: this.nextMonth.hover
        ? cStyle.tButtonCalendarButtonDecorationOnHover
        : cStyle.tButtonCalendarButtonDecorationOn,
      disable: this.nextMonth.disable,
      onPressed: () => {
        this._onPressedNextMonth(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: IconView({
        icon: MaterialIcons.arrow_forward,
        size: 13,
        className: "x77",
      }),
      onEnter: (event) => {
        this.nextMonthOnEnter(event);
      },
      onExit: (event) => {
        this.nextMonthOnExit(event);
      },
      className: "x26",
    });
  }
}
function NextMonthWithState(props: NextMonthWithStateProps) {
  return React.createElement(_NextMonthWithState, props);
}

interface PreviousMonthWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: EventCalenderViewRefs;
  _onPressedPreviousMonth?: _PreviousMonthOnPressed;
}

class PreviousMonthState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _PreviousMonthWithState extends ObservableComponent<PreviousMonthWithStateProps> {
  previousMonthFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: PreviousMonthWithStateProps) {
    super(props);

    this.initState();
  }
  public get previousMonth(): PreviousMonthState {
    return this.props.d3eState.previousMonth;
  }
  public get d3eState(): EventCalenderViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedPreviousMonth(): _PreviousMonthOnPressed {
    return this.props._onPressedPreviousMonth;
  }
  public initState() {
    super.initState();

    this.updateObservable("previousMonth", null, this.previousMonth);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["previousMonth", "previousMonth.", "previousMonth.hover"],
      this.rebuild
    );
  }
  public previousMonthOnEnter(event): void {
    return this.previousMonth.setHover(true);
  }
  public previousMonthOnExit(event): void {
    return this.previousMonth.setHover(false);
  }
  public dispose(): void {
    this.previousMonth.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      padding: cStyle.tButtonCalendarButtonPaddingOn,
      decoration: this.previousMonth.hover
        ? cStyle.tButtonCalendarButtonDecorationOnHover
        : cStyle.tButtonCalendarButtonDecorationOn,
      disable: this.previousMonth.disable,
      onPressed: () => {
        this._onPressedPreviousMonth(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: IconView({
        icon: MaterialIcons.arrow_back,
        size: 13,
        className: "xd4a",
      }),
      onEnter: (event) => {
        this.previousMonthOnEnter(event);
      },
      onExit: (event) => {
        this.previousMonthOnExit(event);
      },
      className: "x164",
    });
  }
}
function PreviousMonthWithState(props: PreviousMonthWithStateProps) {
  return React.createElement(_PreviousMonthWithState, props);
}

interface NextYearWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: EventCalenderViewRefs;
  _onPressedNextYear?: _NextYearOnPressed;
}

class NextYearState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _NextYearWithState extends ObservableComponent<NextYearWithStateProps> {
  nextYearFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: NextYearWithStateProps) {
    super(props);

    this.initState();
  }
  public get nextYear(): NextYearState {
    return this.props.d3eState.nextYear;
  }
  public get d3eState(): EventCalenderViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedNextYear(): _NextYearOnPressed {
    return this.props._onPressedNextYear;
  }
  public initState() {
    super.initState();

    this.updateObservable("nextYear", null, this.nextYear);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["nextYear", "nextYear.", "nextYear.hover"], this.rebuild);
  }
  public nextYearOnEnter(event): void {
    return this.nextYear.setHover(true);
  }
  public nextYearOnExit(event): void {
    return this.nextYear.setHover(false);
  }
  public dispose(): void {
    this.nextYear.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      padding: cStyle.tButtonCalendarButtonPaddingOn,
      decoration: this.nextYear.hover
        ? cStyle.tButtonCalendarButtonDecorationOnHover
        : cStyle.tButtonCalendarButtonDecorationOn,
      disable: this.nextYear.disable,
      onPressed: () => {
        this._onPressedNextYear(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: IconView({
        icon: MaterialIcons.arrow_forward,
        size: 13,
        className: "xf0",
      }),
      onEnter: (event) => {
        this.nextYearOnEnter(event);
      },
      onExit: (event) => {
        this.nextYearOnExit(event);
      },
      className: "x52f",
    });
  }
}
function NextYearWithState(props: NextYearWithStateProps) {
  return React.createElement(_NextYearWithState, props);
}

interface PreviousYearWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: EventCalenderViewRefs;
  _onPressedPreviousYear?: _PreviousYearOnPressed;
}

class PreviousYearState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _PreviousYearWithState extends ObservableComponent<PreviousYearWithStateProps> {
  previousYearFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: PreviousYearWithStateProps) {
    super(props);

    this.initState();
  }
  public get previousYear(): PreviousYearState {
    return this.props.d3eState.previousYear;
  }
  public get d3eState(): EventCalenderViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedPreviousYear(): _PreviousYearOnPressed {
    return this.props._onPressedPreviousYear;
  }
  public initState() {
    super.initState();

    this.updateObservable("previousYear", null, this.previousYear);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["previousYear", "previousYear.", "previousYear.hover"],
      this.rebuild
    );
  }
  public previousYearOnEnter(event): void {
    return this.previousYear.setHover(true);
  }
  public previousYearOnExit(event): void {
    return this.previousYear.setHover(false);
  }
  public dispose(): void {
    this.previousYear.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      padding: cStyle.tButtonCalendarButtonPaddingOn,
      decoration: this.previousYear.hover
        ? cStyle.tButtonCalendarButtonDecorationOnHover
        : cStyle.tButtonCalendarButtonDecorationOn,
      disable: this.previousYear.disable,
      onPressed: () => {
        this._onPressedPreviousYear(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: IconView({
        icon: MaterialIcons.arrow_back,
        size: 13,
        className: "xde",
      }),
      onEnter: (event) => {
        this.previousYearOnEnter(event);
      },
      onExit: (event) => {
        this.previousYearOnExit(event);
      },
      className: "x681",
    });
  }
}
function PreviousYearWithState(props: PreviousYearWithStateProps) {
  return React.createElement(_PreviousYearWithState, props);
}

class _EventCalenderViewState extends ObservableComponent<EventCalenderViewProps> {
  static defaultProps = {
    inputDate: null,
    viewType: CalendarViewType.Month,
    yearWeekBgColor: null,
    yearCurrentDateColor: null,
    disable: false,
    events: [],
  };
  d3eState: EventCalenderViewRefs = new EventCalenderViewRefs();
  selectionType: CalendarViewType = CalendarViewType.Month;
  selectedDate: D3EDate = null;
  weekDayDates: Array<DateTime> = ListWrapper.widget(this, "weekDayDates");
  helper: CalendarHelper = null;
  eventCategories: Array<string> = ListWrapper.widget(this, "eventCategories");
  show: boolean = false;
  filterEventCategories: Array<string> = ListWrapper.widget(
    this,
    "filterEventCategories"
  );
  dateSelectorPopup: Popup;
  itemsPopupPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public selectedDateViewPopupTargetController: PopupTargetController =
    new PopupTargetController();
  public id3PopupTargetController: PopupTargetController =
    new PopupTargetController();
  public constructor(props: EventCalenderViewProps) {
    super(props);

    this.initState();
  }
  public get inputDate(): D3EDate {
    return this.props.inputDate;
  }
  public get events(): Array<CalendarEvent> {
    return this.props.events;
  }
  public get viewType(): CalendarViewType {
    return this.props.viewType;
  }
  public get yearWeekBgColor(): ui.Color {
    return this.props.yearWeekBgColor;
  }
  public get yearCurrentDateColor(): ui.Color {
    return this.props.yearCurrentDateColor;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.subscribeToList(this.events, "events");

    this.updateSyncCollProperty("events", this.props.events);

    this.on(["selectedDate"], this.computeWeekDayDates);

    this.computeWeekDayDates();

    this.on(["events"], this.computeHelper);

    this.computeHelper();

    this.on(["events", "events.category"], this.computeEventCategories);

    this.computeEventCategories();

    this.on(
      [
        "filterEventCategories",
        "helper",
        "selectedDate",
        "selectionType",
        "weekDayDates",
        "yearCurrentDateColor",
        "yearWeekBgColor",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: EventCalenderViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.inputDate !== this.props.inputDate) {
      this.fire("inputDate", this);
    }

    if (prevProps.events !== this.props.events) {
      this.updateObservableColl("events", prevProps.events, this.props.events);

      this.fire("events", this);
    }

    if (prevProps.viewType !== this.props.viewType) {
      this.fire("viewType", this);
    }

    if (prevProps.yearWeekBgColor !== this.props.yearWeekBgColor) {
      this.fire("yearWeekBgColor", this);
    }

    if (prevProps.yearCurrentDateColor !== this.props.yearCurrentDateColor) {
      this.fire("yearCurrentDateColor", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }
  }
  public setSelectionType(val: CalendarViewType): void {
    let isValChanged: boolean = this.selectionType !== val;

    if (!isValChanged) {
      return;
    }

    this.selectionType = val;

    this.fire("selectionType", this);
  }
  public setSelectedDate(val: D3EDate): void {
    let isValChanged: boolean = this.selectedDate !== val;

    if (!isValChanged) {
      return;
    }

    this.selectedDate = val;

    this.fire("selectedDate", this);
  }
  public setWeekDayDates(val: Array<DateTime>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.weekDayDates,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.weekDayDates.clear();

    this.weekDayDates.addAll(val);

    this.fire("weekDayDates", this);
  }
  public addToWeekDayDates(val: DateTime, index: number = -1): void {
    if (index === -1) {
      if (!this.weekDayDates.contains(val)) this.weekDayDates.add(val);
    } else {
      this.weekDayDates.remove(this.weekDayDates.elementAt(index));

      this.weekDayDates.add(val);
    }

    this.fire("weekDayDates", this, val, true);
  }
  public removeFromWeekDayDates(val: DateTime): void {
    this.weekDayDates.remove(val);

    this.fire("weekDayDates", this, val, false);
  }
  public computeWeekDayDates = (): void => {
    try {
      this.setWeekDayDates(
        Array.from(
          this.selectedDate !== null
            ? CalenderUtils.getWeekDayDates(this.selectedDate.toDateTime())
            : []
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeWeekDayDates : " + exception.toString()
      );

      this.setWeekDayDates([]);
    }
  };
  public setHelper(val: CalendarHelper): void {
    let isValChanged: boolean = this.helper !== val;

    if (!isValChanged) {
      return;
    }

    this.helper = val;

    this.fire("helper", this);
  }
  public computeHelper = (): void => {
    try {
      this.setHelper(new CalendarHelper(this.events));
    } catch (exception) {
      console.log(" exception in computeHelper : " + exception.toString());

      this.setHelper(null);
    }
  };
  public setEventCategories(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.eventCategories,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.eventCategories.clear();

    this.eventCategories.addAll(val);

    this.fire("eventCategories", this);
  }
  public addToEventCategories(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.eventCategories.contains(val)) this.eventCategories.add(val);
    } else {
      this.eventCategories.remove(this.eventCategories.elementAt(index));

      this.eventCategories.add(val);
    }

    this.fire("eventCategories", this, val, true);
  }
  public removeFromEventCategories(val: string): void {
    this.eventCategories.remove(val);

    this.fire("eventCategories", this, val, false);
  }
  public computeEventCategories = (): void => {
    try {
      this.setEventCategories(
        Array.from(
          this.events
            .map((e) => e.category)
            .toSet()
            .toList()
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeEventCategories : " + exception.toString()
      );

      this.setEventCategories([]);
    }
  };
  public setShow(val: boolean): void {
    let isValChanged: boolean = this.show !== val;

    if (!isValChanged) {
      return;
    }

    this.show = val;

    this.fire("show", this);
  }
  public setFilterEventCategories(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.filterEventCategories,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.filterEventCategories.clear();

    this.filterEventCategories.addAll(val);

    this.fire("filterEventCategories", this);
  }
  public addToFilterEventCategories(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.filterEventCategories.contains(val))
        this.filterEventCategories.add(val);
    } else {
      this.filterEventCategories.remove(
        this.filterEventCategories.elementAt(index)
      );

      this.filterEventCategories.add(val);
    }

    this.fire("filterEventCategories", this, val, true);
  }
  public removeFromFilterEventCategories(val: string): void {
    this.filterEventCategories.remove(val);

    this.fire("filterEventCategories", this, val, false);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            ui.Row({
              children: [
                this.selectionType === CalendarViewType.Year
                  ? ui.Row({
                      children: [
                        PreviousYearWithState({
                          d3eState: this.d3eState,
                          _onPressedPreviousYear: this.onPressedPreviousYear,
                          key: "0",
                        }),
                        NextYearWithState({
                          d3eState: this.d3eState,
                          _onPressedNextYear: this.onPressedNextYear,
                          key: "1",
                        }),
                      ],
                    })
                  : this.selectionType === CalendarViewType.Month
                  ? ui.Row({
                      children: [
                        PreviousMonthWithState({
                          d3eState: this.d3eState,
                          _onPressedPreviousMonth: this.onPressedPreviousMonth,
                          key: "0",
                        }),
                        NextMonthWithState({
                          d3eState: this.d3eState,
                          _onPressedNextMonth: this.onPressedNextMonth,
                          key: "1",
                        }),
                      ],
                    })
                  : this.selectionType === CalendarViewType.Week
                  ? ui.Row({
                      children: [
                        PreviousWeekWithState({
                          d3eState: this.d3eState,
                          _onPressedPreviousWeek: this.onPressedPreviousWeek,
                          key: "0",
                        }),
                        NextWeekWithState({
                          d3eState: this.d3eState,
                          _onPressedNextWeek: this.onPressedNextWeek,
                          key: "1",
                        }),
                      ],
                    })
                  : [],
                SelectedDateViewWithState({
                  d3eState: this.d3eState,
                  _onDateChange: this.onDateChange,
                  selectedDate: this.selectedDate,
                  selectedDateViewPopupTargetController:
                    this.selectedDateViewPopupTargetController,
                  key: "1",
                }),
              ],
              key: "0",
            }),
            ui.Container({
              child:
                this.selectionType === CalendarViewType.Year
                  ? ui.Wrap({
                      spacing: 5,
                      crossAxisAlignment: ui.WrapCrossAlignment.center,
                      children: [
                        TextView({
                          data: this.selectedDate.year.toString(),
                          style: new ui.TextStyle({
                            fontSize: 16,
                            color: new ui.Color(0xff464646),
                            fontWeight: ui.FontWeight.bold,
                          }),
                          className: "xb7ec",
                          key: "0",
                        }),
                      ],
                    })
                  : this.selectionType === CalendarViewType.Month
                  ? ui.Wrap({
                      spacing: 5,
                      crossAxisAlignment: ui.WrapCrossAlignment.center,
                      children: [
                        TextView({
                          data:
                            CalenderUtils.getMonthName(
                              this.selectedDate.month
                            ).toUpperCase() +
                            " " +
                            this.selectedDate.year.toString(),
                          style: new ui.TextStyle({
                            fontSize: 16,
                            color: new ui.Color(0xff464646),
                            fontWeight: ui.FontWeight.bold,
                          }),
                          className: "x89e",
                          key: "0",
                        }),
                      ],
                    })
                  : this.selectionType === CalendarViewType.Week
                  ? ui.Wrap({
                      spacing: 5,
                      crossAxisAlignment: ui.WrapCrossAlignment.center,
                      children: [
                        TextView({
                          data:
                            CalenderUtils.getMonthName(
                              this.weekDayDates.first.month
                            ) +
                            " " +
                            this.weekDayDates.first.day.toString() +
                            " - " +
                            CalenderUtils.getMonthName(
                              this.weekDayDates.last.month
                            ) +
                            " " +
                            this.weekDayDates.last.day.toString(),
                          style: new ui.TextStyle({
                            fontSize: 16,
                            color: new ui.Color(0xff464646),
                            fontWeight: ui.FontWeight.bold,
                          }),
                          className: "x3f8",
                          key: "0",
                        }),
                      ],
                    })
                  : [],
              key: "1",
            }),
            ui.Container({
              height: 40,
              width: 180,
              child: DropDown<CalendarViewType>({
                value: this.selectionType,
                items: CalendarViewType.values,
                onHoverColor: new ui.Color(0xffced4da),
                dropDownDecoration: new ui.BoxDecoration({
                  color: new ui.Color(0xffffffff),
                  border: ui.Border.all({
                    color: new ui.Color(0xffced4da),
                    width: 1.0,
                  }),
                  boxShadow: [
                    new ui.BoxShadow({
                      color: new ui.Color(0xffccc8c8),
                      blurRadius: 3,
                      spreadRadius: 1,
                      offset: ui.OffsetExt.getOffset({ dx: 2, dy: 2 }),
                    }),
                  ],
                }),
                onChanged: (value) => {
                  this.onVewTypeChange(value, this.d3eState);
                },
                onFocusChange: (val) => {},
                builder: (context, item) => {
                  return TextView({
                    data: item.name,
                    style: new ui.TextStyle({ fontSize: 13 }),
                    className: "x5a",
                  });
                },
                focusNode: this.d3eState.viewTypeDropDownFocusNode,
              }),
              key: "2",
              className: "xdc3 hc vc",
            }),
            ui.Container({
              child: Id2WithState({
                d3eState: this.d3eState,
                _onShowDropDown: this.onShowDropDown,
              }),
              d3eRef: this.id3PopupTargetController.handleRef,
              key: "3",
            }),
          ],
          className: "x2b hc h",
          key: "0",
        }),
        ui.Container({
          child:
            this.selectionType === CalendarViewType.Month
              ? MonthView({
                  date: this.selectedDate,
                  helper: this.helper,
                  categories: this.filterEventCategories,
                  key:
                    this.filterEventCategories.toString() +
                    this.selectedDate.toString(),
                  builder: (context, event, viewType) => {
                    return this.props.builder(this.context, event, viewType);
                  },
                  className: "xe4 hc vc h v",
                })
              : this.selectionType === CalendarViewType.Year
              ? YearView({
                  input: this.selectedDate,
                  helper: this.helper,
                  yearWeekBgColor:
                    this.yearWeekBgColor !== null
                      ? this.yearWeekBgColor
                      : ui.HexColor.fromHexStr("ff465af7"),
                  currentDateColor:
                    this.yearCurrentDateColor !== null
                      ? this.yearCurrentDateColor
                      : ui.HexColor.fromHexStr("fffaf2cc"),
                  categories: this.filterEventCategories,
                  onDateSelected: (date) => {
                    this.onDateSelected(date, this.d3eState);
                  },
                  className: "xfea hc vc h v",
                })
              : this.selectionType === CalendarViewType.Day
              ? DayView({
                  day: this.selectedDate,
                  helper: this.helper,
                  categories: this.filterEventCategories,
                  builder: (context, event, viewType) => {
                    return this.props.builder(this.context, event, viewType);
                  },
                  className: "x853 hc vc h v",
                })
              : this.selectionType === CalendarViewType.Week
              ? WeekView({
                  helper: this.helper,
                  date: this.selectedDate,
                  categories: this.filterEventCategories,
                  builder: (context, event, viewType) => {
                    return this.props.builder(this.context, event, viewType);
                  },
                  className: "x313 hc vc h v",
                })
              : [],
          className: "xe5 hc vc h v",
          key: "1",
        }),
      ],
      className: ui.join(
        this.props.className,
        "EventCalenderView xf7 hc vc h v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setSelectedDate(this.inputDate);

    this.setSelectionType(this.viewType);
  };
  public onVewTypeChange = (
    value: CalendarViewType,
    d3eState: EventCalenderViewRefs
  ): void => {
    this.setSelectionType(value);
  };
  public onDateChange = (d3eState: EventCalenderViewRefs): void => {
    this.showDateSelector({ autoClose: true });
  };
  public onDateSelectionFromPicker = (date: D3EDate): void => {
    this.setSelectedDate(date);

    this.hideDateSelector();
  };
  public onDateSelected = (
    date: D3EDate,
    d3eState: EventCalenderViewRefs
  ): void => {
    this.setSelectedDate(date);
  };
  public onPressedPreviousYear = (d3eState: EventCalenderViewRefs): void => {
    this.setSelectedDate(
      D3EDate.of(
        this.selectedDate.year - 1,
        this.selectedDate.month,
        this.selectedDate.day
      )
    );
  };
  public onPressedNextYear = (d3eState: EventCalenderViewRefs): void => {
    this.setSelectedDate(
      D3EDate.of(
        this.selectedDate.year + 1,
        this.selectedDate.month,
        this.selectedDate.day
      )
    );
  };
  public onPressedPreviousMonth = (d3eState: EventCalenderViewRefs): void => {
    this.setSelectedDate(this.selectedDate.plusMonths(-1));
  };
  public onPressedNextMonth = (d3eState: EventCalenderViewRefs): void => {
    this.setSelectedDate(this.selectedDate.plusMonths(1));
  };
  public onPressedPreviousWeek = (d3eState: EventCalenderViewRefs): void => {
    // TODO
  };
  public onPressedNextWeek = (d3eState: EventCalenderViewRefs): void => {
    // TODO
  };
  public onShowDropDown = (d3eState: EventCalenderViewRefs): void => {
    if (this.show) {
      this.hideItemsPopup();

      this.setShow(false);

      return;
    }

    if (this.eventCategories.isNotEmpty && !this.disable) {
      this.showItemsPopup({ autoClose: true });

      this.setShow(true);
    }
  };
  public onCategorySelected = (selectedItems: Array<string>): void => {
    this.setFilterEventCategories(selectedItems);
  };
  public dispose(): void {
    this.dateSelectorPopup?.dispose();

    this.itemsPopupPopup?.dispose();

    super.dispose();
  }
  public showDateSelector(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.dateSelectorPopup?.dispose();

    let target: ui.Rect = this.selectedDateViewPopupTargetController.getTarget(
      this.context
    );

    this.dateSelectorPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Bottom,
      child: ui.Container({
        constraints: new ui.BoxConstraints({ maxWidth: 400, maxHeight: 400 }),
        decoration: new ui.BoxDecoration({
          border: ui.Border.all({
            color: new ui.Color(0xffe0e0e0),
            width: 1.0,
          }),
          borderRadius: ui.BorderRadius.circular(3.0),
          boxShadow: [
            new ui.BoxShadow({
              color: new ui.Color(0xffccc8c8),
              blurRadius: 3,
              spreadRadius: 1,
              offset: ui.OffsetExt.getOffset({ dx: 2, dy: 2 }),
            }),
          ],
        }),
        margin: ui.EdgeInsets.fromLTRB(0.0, 10.0, 0.0, 0.0, new Map()),
        child: CalenderView({
          onDateSelected: (date) => {
            this.onDateSelectionFromPicker(date);
          },
        }),
        className: "xdf hc vc",
      }),
      target: target,
    });

    this.dateSelectorPopup.showPopup(this.context);
  }
  public showItemsPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.itemsPopupPopup?.dispose();

    let target: ui.Rect = this.id3PopupTargetController.getTarget(this.context);

    this.itemsPopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Bottom,
      child: ui.Container({
        constraints: new ui.BoxConstraints({ maxHeight: 250 }),
        width: 200,
        child: EventCategorySelectionView({
          items: this.eventCategories,
          onFilterChanged: (selectedItems) => {
            this.onCategorySelected(selectedItems);
          },
        }),
        className: "xc0 hc vc",
      }),
      target: target,
    });

    this.itemsPopupPopup.showPopup(this.context);
  }
  public hideDateSelector(): void {
    this.dateSelectorPopup?.dispose();
  }
  public hideItemsPopup(): void {
    this.itemsPopupPopup?.dispose();
  }
  public get id2() {
    return this.d3eState.id2;
  }
  public get nextMonth() {
    return this.d3eState.nextMonth;
  }
  public get nextWeek() {
    return this.d3eState.nextWeek;
  }
  public get nextYear() {
    return this.d3eState.nextYear;
  }
  public get previousMonth() {
    return this.d3eState.previousMonth;
  }
  public get previousWeek() {
    return this.d3eState.previousWeek;
  }
  public get previousYear() {
    return this.d3eState.previousYear;
  }
  public get selectedDateView() {
    return this.d3eState.selectedDateView;
  }
}
export default function EventCalenderView(props: EventCalenderViewProps) {
  return React.createElement(
    _EventCalenderViewState,
    { ..._EventCalenderViewState.defaultProps, ...props },
    ListWrapper.fromInput<CalendarEvent>(props.events, "events")
  );
}
