import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import PopupTargetController from "./PopupTargetController";
import ListWrapper from "../utils/ListWrapper";
import DropDownPopup from "./DropDownPopup";
import Popup from "./Popup";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import IconView from "./IconView";

type _DropDownOnChanged<T> = (value: T) => void;

type _DropDownBuilder<T> = (context: any, item: T) => ReactNode;

type _Id2OnTap<T> = (d3eState: DropDownRefs<T>) => void;

type _Id2OnKey<T> = (
  focusNode: ui.FocusNode,
  event: ui.RawKeyEvent,
  d3eState: DropDownRefs<T>
) => ui.KeyEventResult;

type _DropDownFocusChange<T> = (val: boolean) => void;

export interface DropDownProps<T> extends BaseUIProps {
  key?: string;
  placeHolder?: string;
  items: Array<T>;
  value?: T;
  onHoverColor?: ui.Color;
  dropDownDecoration?: ui.BoxDecoration;
  padding?: ui.EdgeInsets;
  placeHolderColor?: ui.Color;
  disable?: boolean;
  viewDecoration?: ui.BoxDecoration;
  maxHeight?: number;
  focusedColor?: ui.Color;
  focusNode?: ui.FocusNode;
  _itemsHash?: number;
  onChanged?: _DropDownOnChanged<T>;
  builder?: _DropDownBuilder<T>;
  _onFocusChange?: _DropDownFocusChange<T>;
}
/// To store state data for DropDown
class DropDownRefs<T> {
  focusNode: ui.FocusNode = new ui.FocusNode();
  public id2: Id2State<T> = new Id2State();
}

interface Id2WithStateProps<T> extends BaseUIProps {
  key?: string;
  d3eState: DropDownRefs<T>;
  builder: _DropDownBuilder<T>;
  _onKey?: _Id2OnKey<T>;
  _onShowDropDown?: _Id2OnTap<T>;
  _onFocusChange: _DropDownFocusChange<T>;
  disable: boolean;
  dropDownDecoration: ui.BoxDecoration;
  focusedColor: ui.Color;
  isExists: boolean;
  items: Array<T>;
  padding: ui.EdgeInsets;
  placeHolder: string;
  placeHolderColor: ui.Color;
  value: T;
}

class Id2State<T> extends ObjectObservable {
  private _focus: boolean = false;
  public get focus(): boolean {
    return this._focus;
  }
  public setFocus(val: boolean) {
    let isValChanged: boolean = this._focus !== val;

    if (!isValChanged) {
      return;
    }

    this._focus = val;

    this.fire("focus", this);
  }
}

class _Id2WithState<T> extends ObservableComponent<Id2WithStateProps<T>> {
  public constructor(props: Id2WithStateProps<T>) {
    super(props);

    this.initState();
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public get dropDownDecoration(): ui.BoxDecoration {
    return this.props.dropDownDecoration;
  }
  public get focusedColor(): ui.Color {
    return this.props.focusedColor;
  }
  public get id2(): Id2State<T> {
    return this.props.d3eState.id2;
  }
  public get isExists(): boolean {
    return this.props.isExists;
  }
  public get items(): Array<T> {
    return this.props.items;
  }
  public get padding(): ui.EdgeInsets {
    return this.props.padding;
  }
  public get placeHolder(): string {
    return this.props.placeHolder;
  }
  public get placeHolderColor(): ui.Color {
    return this.props.placeHolderColor;
  }
  public get value(): T {
    return this.props.value;
  }
  public get d3eState(): DropDownRefs<T> {
    return this.props.d3eState;
  }
  public get builder(): _DropDownBuilder<T> {
    return this.props.builder;
  }
  public get _onKey(): _Id2OnKey<T> {
    return this.props._onKey;
  }
  public get _onShowDropDown(): _Id2OnTap<T> {
    return this.props._onShowDropDown;
  }
  public get _onFocusChange(): _DropDownFocusChange<T> {
    return this.props._onFocusChange;
  }
  public initState() {
    super.initState();

    this.updateObservable("id2", null, this.id2);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "disable",
        "dropDownDecoration",
        "focusedColor",
        "id2",
        "id2.focus",
        "isExists",
        "items",
        "padding",
        "placeHolder",
        "placeHolderColor",
        "value",
      ],
      this.rebuild
    );
  }
  public id2OnFocusChange(val): void {
    return this.id2.setFocus(val);
  }
  public dispose(): void {
    this.id2.setFocus(false);

    super.dispose();
  }
  public render(): ReactNode {
    return ui.Focus({
      focusNode: this.props.d3eState.focusNode ?? new ui.FocusNode(),
      child: ui.Container({
        decoration:
          this.dropDownDecoration !== null
            ? this.dropDownDecoration
            : new ui.BoxDecoration({
                border: new ui.Border({
                  top: new ui.BorderSide({
                    color:
                      this.id2.focus && this.focusedColor !== null
                        ? this.focusedColor
                        : this.id2.focus
                        ? ui.HexColor.fromHexInt(0x42000000)
                        : ui.HexColor.fromHexInt(0xffa9a9a9),
                    width: 1.0,
                    style: ui.BorderStyle.solid,
                  }),
                  left: new ui.BorderSide({
                    color:
                      this.id2.focus && this.focusedColor !== null
                        ? this.focusedColor
                        : this.id2.focus
                        ? ui.HexColor.fromHexInt(0x42000000)
                        : ui.HexColor.fromHexInt(0xffa9a9a9),
                    width: 1.0,
                    style: ui.BorderStyle.solid,
                  }),
                  right: new ui.BorderSide({
                    color:
                      this.id2.focus && this.focusedColor !== null
                        ? this.focusedColor
                        : this.id2.focus
                        ? ui.HexColor.fromHexInt(0x42000000)
                        : ui.HexColor.fromHexInt(0xffa9a9a9),
                    width: 1.0,
                    style: ui.BorderStyle.solid,
                  }),
                  bottom: new ui.BorderSide({
                    color:
                      this.id2.focus && this.focusedColor !== null
                        ? this.focusedColor
                        : this.id2.focus
                        ? ui.HexColor.fromHexInt(0x42000000)
                        : ui.HexColor.fromHexInt(0xffa9a9a9),
                    width: 1.0,
                    style: ui.BorderStyle.solid,
                  }),
                }),
              }),
        padding:
          this.padding !== null
            ? this.padding
            : ui.EdgeInsetsExt.only({
                left: 4.0,
                right: 0.0,
                top: 0.0,
                bottom: 0.0,
              }),
        foregroundDecoration:
          this.items.isEmpty || this.disable
            ? new ui.BoxDecoration({ color: new ui.Color(0x61dddddd) })
            : null,
        key: this.placeHolder,
        child: ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          crossAxisAlignment: ui.CrossAxisAlignment.center,
          children: [
            this.isExists
              ? ui.Container({
                  padding:
                    this.padding !== null
                      ? this.padding
                      : ui.EdgeInsetsExt.only({
                          left: 4.0,
                          right: 0.0,
                          top: 0.0,
                          bottom: 0.0,
                        }),
                  child: this.props.builder(this.context, this.value),
                  className: "x157 hc h",
                })
              : ui.Container({
                  padding:
                    this.padding !== null
                      ? this.padding
                      : ui.EdgeInsetsExt.only({
                          left: 4.0,
                          right: 0.0,
                          top: 0.0,
                          bottom: 0.0,
                        }),
                  child: TextView({
                    data: this.placeHolder !== null ? this.placeHolder : "",
                    overflow: ui.TextOverflow.ellipsis,
                    style: new ui.TextStyle({
                      color:
                        this.placeHolderColor !== null
                          ? this.placeHolderColor
                          : ui.HexColor.fromHexInt(0xffa9a9a9),
                    }),
                  }),
                  className: "x34 hc h",
                }),
            IconView({
              icon: MaterialIcons.expand_more,
              color:
                this.placeHolderColor !== null
                  ? this.placeHolderColor
                  : ui.HexColor.fromHexInt(0xffa9a9a9),
              key: "1",
            }),
          ],
          className: "x2c h",
        }),
        onFocusKey: (focusNode, event) => {
          return this._onKey(focusNode, event, this.d3eState);
        },
        onTap: (e) => {
          e.stopPropagation();

          this._onShowDropDown(this.d3eState);
        },
        className: "xfd3 h",
      }),
      onFocusKey: (focusNode, event) => {
        return this._onKey(focusNode, event, this.d3eState);
      },
      onFocusChange: (val) => {
        this.id2OnFocusChange(val);

        this.props.onFocusChange(val);
      },
    });
  }
}
function Id2WithState<T>(props: Id2WithStateProps<T>) {
  return React.createElement(_Id2WithState<T>, props);
}

class _DropDownState<T> extends ObservableComponent<DropDownProps<T>> {
  static defaultProps = {
    placeHolder: "",
    value: null,
    onHoverColor: null,
    dropDownDecoration: null,
    padding: null,
    placeHolderColor: null,
    disable: false,
    viewDecoration: null,
    maxHeight: 250.0,
    focusedColor: null,
    focusNode: null,
    items: [],
    onChanged: null,
  };
  d3eState: DropDownRefs<T> = new DropDownRefs();
  popUpWidth: number = 150.0;
  isExists: boolean = false;
  itemsShown: boolean = false;
  popupPopup: Popup;
  public id3PopupTargetController: PopupTargetController =
    new PopupTargetController();
  public constructor(props: DropDownProps<T>) {
    super(props);

    this.initState();
  }
  public get placeHolder(): string {
    return this.props.placeHolder;
  }
  public get items(): Array<T> {
    return this.props.items;
  }
  public get value(): T {
    return this.props.value;
  }
  public get onHoverColor(): ui.Color {
    return this.props.onHoverColor;
  }
  public get dropDownDecoration(): ui.BoxDecoration {
    return this.props.dropDownDecoration;
  }
  public get padding(): ui.EdgeInsets {
    return this.props.padding;
  }
  public get placeHolderColor(): ui.Color {
    return this.props.placeHolderColor;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public get viewDecoration(): ui.BoxDecoration {
    return this.props.viewDecoration;
  }
  public get maxHeight(): number {
    return this.props.maxHeight;
  }
  public get focusedColor(): ui.Color {
    return this.props.focusedColor;
  }
  public get focusNode(): ui.FocusNode {
    return this.props.focusNode;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.subscribeToList(this.items, "items");

    this.on(["items", "value"], this.computeIsExists);

    this.computeIsExists();

    this.on(
      [
        "disable",
        "dropDownDecoration",
        "focusedColor",
        "isExists",
        "items",
        "padding",
        "placeHolder",
        "placeHolderColor",
        "value",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: DropDownProps<T>): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.placeHolder !== this.props.placeHolder) {
      this.fire("placeHolder", this);
    }

    if (prevProps.items !== this.props.items) {
      this.fire("items", this);
    }

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.onHoverColor !== this.props.onHoverColor) {
      this.fire("onHoverColor", this);
    }

    if (prevProps.dropDownDecoration !== this.props.dropDownDecoration) {
      this.fire("dropDownDecoration", this);
    }

    if (prevProps.padding !== this.props.padding) {
      this.fire("padding", this);
    }

    if (prevProps.placeHolderColor !== this.props.placeHolderColor) {
      this.fire("placeHolderColor", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }

    if (prevProps.viewDecoration !== this.props.viewDecoration) {
      this.fire("viewDecoration", this);
    }

    if (prevProps.maxHeight !== this.props.maxHeight) {
      this.fire("maxHeight", this);
    }

    if (prevProps.focusedColor !== this.props.focusedColor) {
      this.fire("focusedColor", this);
    }

    if (prevProps.focusNode !== this.props.focusNode) {
      this.fire("focusNode", this);
    }
  }
  public setPopUpWidth(val: number): void {
    let isValChanged: boolean = this.popUpWidth !== val;

    if (!isValChanged) {
      return;
    }

    this.popUpWidth = val;

    this.fire("popUpWidth", this);
  }
  public setIsExists(val: boolean): void {
    let isValChanged: boolean = this.isExists !== val;

    if (!isValChanged) {
      return;
    }

    this.isExists = val;

    this.fire("isExists", this);
  }
  public computeIsExists = (): void => {
    try {
      this.setIsExists(this.items.any((element) => element === this.value));
    } catch (exception) {
      console.log(" exception in computeIsExists : " + exception.toString());

      this.setIsExists(false);
    }
  };
  public setItemsShown(val: boolean): void {
    let isValChanged: boolean = this.itemsShown !== val;

    if (!isValChanged) {
      return;
    }

    this.itemsShown = val;

    this.fire("itemsShown", this);
  }
  public render(): ReactNode {
    return ui.Container({
      child: Id2WithState({
        d3eState: this.d3eState,
        builder: this.props.builder,
        _onKey: this.onKey,
        _onShowDropDown: this.onShowDropDown,
        disable: this.disable,
        dropDownDecoration: this.dropDownDecoration,
        focusedColor: this.focusedColor,
        isExists: this.isExists,
        items: this.items,
        padding: this.padding,
        placeHolder: this.placeHolder,
        placeHolderColor: this.placeHolderColor,
        value: this.value,
        _onFocusChange: this.onFocusChange,
      }),
      d3eRef: ui.LayoutAware((bounds, globalPos) => {
        this.onDropDownBoundsChanges(bounds, globalPos, this.d3eState);
      }, this.id3PopupTargetController.handleRef),
      className: ui.join(this.props.className, "DropDown x47 h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onShowDropDown = (d3eState: DropDownRefs<T>): void => {
    if (this.items.isNotEmpty && !this.disable) {
      if (!this.itemsShown) {
        this.showPopup({ autoClose: true });
      } else {
        this.hidePopup();
      }

      this.setItemsShown(!this.itemsShown);
    }
  };
  public onCloseDropDown = (value: any): void => {
    this.hidePopup();

    this.setItemsShown(false);

    this.onChanged(value);
  };
  public onDropDownBoundsChanges = (
    bounds: ui.Rect,
    globalPos: ui.Offset,
    d3eState: DropDownRefs<T>
  ): void => {
    this.setPopUpWidth(bounds.width);
  };
  public onKey = (
    focusNode: ui.FocusNode,
    event: ui.RawKeyEvent,
    d3eState: DropDownRefs<T>
  ): ui.KeyEventResult => {
    if (event instanceof ui.RawKeyDownEvent && !this.disable) {
      if (
        event.logicalKey === ui.LogicalKeyboardKey.enter ||
        event.logicalKey === ui.LogicalKeyboardKey.space ||
        event.logicalKey === ui.LogicalKeyboardKey.numpadEnter
      ) {
        if (this.items.isNotEmpty && !this.disable) {
          this.setItemsShown(true);

          this.showPopup({ autoClose: true });
        }

        return ui.KeyEventResult.handled;
      }

      if (event instanceof ui.RawKeyDownEvent) {
        if (event.logicalKey === ui.LogicalKeyboardKey.arrowDown) {
          this.onDownHandler();
        } else if (event.logicalKey === ui.LogicalKeyboardKey.arrowUp) {
          this.onUpHandler();
        } else if (
          event.logicalKey === ui.LogicalKeyboardKey.escape ||
          event.logicalKey === ui.LogicalKeyboardKey.tab
        ) {
          this.hidePopup();

          this.setItemsShown(false);
        }
      }
    }

    return ui.KeyEventResult.ignored;
  };
  public onDownHandler = (): void => {
    if (this.items.isNotEmpty && !this.disable) {
      let idx: number = this.items.indexOf(
        this.value !== null ? this.value : this.items.first
      );

      let nextValue: number = idx + 1;

      if (nextValue >= this.items.length) {
        return;
      }

      this.onChanged(this.items.elementAt(nextValue));
    }
  };
  public onUpHandler = (): void => {
    if (this.items.isNotEmpty && !this.disable) {
      let idx: number = this.items.indexOf(
        this.value !== null ? this.value : this.items.first
      );

      let nextValue: number = idx - 1;

      if (nextValue < 0) {
        return;
      }

      this.onChanged(this.items.elementAt(nextValue));
    }
  };
  public onDropDownAutoClose = (): void => {
    this.setItemsShown(false);
  };
  public onInit = (): void => {
    if (false) {
      let focusNode111: ui.FocusNode = this.focusNode;
    }
  };
  public get onChanged(): _DropDownOnChanged<T> {
    return this.props.onChanged;
  }
  public dispose(): void {
    this.popupPopup?.dispose();

    super.dispose();
  }
  public showPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.popupPopup?.dispose();

    let target: ui.Rect = this.id3PopupTargetController.getTarget(this.context);

    this.popupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Bottom,
      child: ui.Container({
        constraints: new ui.BoxConstraints({ maxHeight: this.maxHeight }),
        decoration:
          this.viewDecoration !== null
            ? this.viewDecoration
            : new ui.BoxDecoration({
                color: ui.HexColor.fromHexInt(0xffffffff),
              }),
        width: this.popUpWidth,
        child: DropDownPopup({
          hoverColor: this.onHoverColor,
          items: this.items,
          currentItem: this.value,
          onChanged: (value) => {
            this.onCloseDropDown(value);
          },
          builder: this.props.builder,
        }),
        className: "hc vc",
      }),
      target: target,
      onClose: this.onDropDownAutoClose,
    });

    this.popupPopup.showPopup(this.context);
  }
  public hidePopup(): void {
    this.popupPopup?.dispose();
  }
  public get onFocusChange(): _DropDownFocusChange<T> {
    return this.props.onFocusChange;
  }
  public get id2() {
    return this.d3eState.id2;
  }
}
export default function DropDown<T>(props: DropDownProps<T>) {
  return React.createElement(
    _DropDownState<T>,
    { ..._DropDownState.defaultProps, ...props },
    ListWrapper.fromInput<T>(props.items, "items")
  );
}
