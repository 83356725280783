import * as __d3ett from "./D3ETemplateTypes";
import GlobalFunctions from "../utils/GlobalFunctions";
import CalendarViewType from "../classes/CalendarViewType";
import ResultStatus from "../classes/ResultStatus";
import RentalRequestChanges from "../models/RentalRequestChanges";
import RentalFacilities from "../classes/RentalFacilities";
import RentalRequest from "../models/RentalRequest";
import Renter from "../models/Renter";
import Admin from "../models/Admin";
import EventListRequest from "../models/EventListRequest";
import RentalBookingsRequest from "../models/RentalBookingsRequest";
import RequestDocument from "../models/RequestDocument";
import GradientType from "../classes/GradientType";
import FacilitiesListRequest from "../models/FacilitiesListRequest";
import RequestStatusUpdate from "../models/RequestStatusUpdate";
import RentalBookings from "../classes/RentalBookings";
import MessageList from "../classes/MessageList";
import OneTimePassword from "../models/OneTimePassword";
import Equipment from "../models/Equipment";
import RequestDocuments from "../classes/RequestDocuments";
import RequestInsurance from "../models/RequestInsurance";
import PendingRentalRequests from "../classes/PendingRentalRequests";
import FacilityTypeListRequest from "../models/FacilityTypeListRequest";
import RequestStatus from "../classes/RequestStatus";
import PaymentHistory from "../classes/PaymentHistory";
import SchoolsListRequest from "../models/SchoolsListRequest";
import PendingPaymentsRequest from "../models/PendingPaymentsRequest";
import RentalRequestsRequest from "../models/RentalRequestsRequest";
import RentalRequests from "../classes/RentalRequests";
import PaymentReceipt from "../models/PaymentReceipt";
import CalendarEvent from "../classes/CalendarEvent";
import PaymentHistoryRequest from "../models/PaymentHistoryRequest";
import GSDRMSFieldType from "../classes/GSDRMSFieldType";
import FacilitiesList from "../classes/FacilitiesList";
import FacilityType from "../models/FacilityType";
import RequestInsurancesRequest from "../models/RequestInsurancesRequest";
import RequestInsurances from "../classes/RequestInsurances";
import School from "../models/School";
import SchoolType from "../models/SchoolType";
import SchoolsList from "../classes/SchoolsList";
import LoginResult from "../classes/LoginResult";
import Facility from "../models/Facility";
import ConnectionStatus from "../classes/ConnectionStatus";
import FacilityUsageCategory from "../models/FacilityUsageCategory";
import FacilityTypeList from "../classes/FacilityTypeList";
import ChangePasswordRequest from "../models/ChangePasswordRequest";
import PendingRentalRequestsRequest from "../models/PendingRentalRequestsRequest";
import Message from "../models/Message";
import FacilityRate from "../models/FacilityRate";
import PendingPayments from "../classes/PendingPayments";
import AdminRole from "../classes/AdminRole";
import Service from "../models/Service";
import MessageListRequest from "../models/MessageListRequest";
import EventList from "../classes/EventList";
import SignUpRequest from "../models/SignUpRequest";
import RequestDocumentsRequest from "../models/RequestDocumentsRequest";
import ImageFrom from "../classes/ImageFrom";
import RentalFacilitiesRequest from "../models/RentalFacilitiesRequest";
import IconType from "../classes/IconType";
import MessageType from "../classes/MessageType";

export const ADMIN: number = 0;

export const ADMINROLE: number = 1;

export const BASEUSER: number = 2;

export const BLOB: number = 3;

export const BOOLEAN: number = 4;

export const CALENDAREVENT: number = 5;

export const CALENDARVIEWTYPE: number = 6;

export const CHANGEPASSWORDREQUEST: number = 7;

export const CONNECTIONSTATUS: number = 8;

export const DFILE: number = 9;

export const DATE: number = 10;

export const DATETIME: number = 11;

export const DOUBLE: number = 12;

export const DURATION: number = 13;

export const EQUIPMENT: number = 14;

export const EVENTLIST: number = 15;

export const EVENTLISTREQUEST: number = 16;

export const FACILITIESLIST: number = 17;

export const FACILITIESLISTREQUEST: number = 18;

export const FACILITY: number = 19;

export const FACILITYRATE: number = 20;

export const FACILITYTYPE: number = 21;

export const FACILITYTYPELIST: number = 22;

export const FACILITYTYPELISTREQUEST: number = 23;

export const FACILITYUSAGECATEGORY: number = 24;

export const GSDRMSFIELDTYPE: number = 25;

export const GRADIENTTYPE: number = 26;

export const ICONTYPE: number = 27;

export const IMAGEFROM: number = 28;

export const INTEGER: number = 29;

export const LOGINRESULT: number = 30;

export const MESSAGE: number = 31;

export const MESSAGELIST: number = 32;

export const MESSAGELISTREQUEST: number = 33;

export const MESSAGETYPE: number = 34;

export const ONETIMEPASSWORD: number = 35;

export const PAYMENTHISTORY: number = 36;

export const PAYMENTHISTORYREQUEST: number = 37;

export const PAYMENTRECEIPT: number = 38;

export const PENDINGPAYMENTS: number = 39;

export const PENDINGPAYMENTSREQUEST: number = 40;

export const PENDINGRENTALREQUESTS: number = 41;

export const PENDINGRENTALREQUESTSREQUEST: number = 42;

export const RENTALBOOKINGS: number = 43;

export const RENTALBOOKINGSREQUEST: number = 44;

export const RENTALFACILITIES: number = 45;

export const RENTALFACILITIESREQUEST: number = 46;

export const RENTALREQUEST: number = 47;

export const RENTALREQUESTCHANGES: number = 48;

export const RENTALREQUESTS: number = 49;

export const RENTALREQUESTSREQUEST: number = 50;

export const RENTER: number = 51;

export const REQUESTDOCUMENT: number = 52;

export const REQUESTDOCUMENTS: number = 53;

export const REQUESTDOCUMENTSREQUEST: number = 54;

export const REQUESTINSURANCE: number = 55;

export const REQUESTINSURANCES: number = 56;

export const REQUESTINSURANCESREQUEST: number = 57;

export const REQUESTSTATUS: number = 58;

export const REQUESTSTATUSUPDATE: number = 59;

export const RESULTSTATUS: number = 60;

export const SCHOOL: number = 61;

export const SCHOOLTYPE: number = 62;

export const SCHOOLSLIST: number = 63;

export const SCHOOLSLISTREQUEST: number = 64;

export const SERVICE: number = 65;

export const SIGNUPREQUEST: number = 66;

export const STRING: number = 67;

export const TIME: number = 68;

export const TYPE: number = 69;

export const VOID: number = 70;

export class D3ETemplate {
  public static HASH: string = "4887c19ef3c15e34738e5d4bdddab1af";
  private static _usages: Array<__d3ett.D3EUsage> = [
    new __d3ett.D3EUsage(
      "Query_currentUser_MainHomePage_eventHandlers_onInit_block",
      [
        new __d3ett.D3ETypeUsage(ADMIN, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
        ]),
        new __d3ett.D3ETypeUsage(RENTER, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
        new __d3ett.D3ETypeUsage(BASEUSER, []),
      ],
      "551ec70d53fb54ab6ea3fd11049760ea"
    ),
    new __d3ett.D3EUsage(
      "Query_currentUser_MainPage_eventHandlers_onInit_block",
      [
        new __d3ett.D3ETypeUsage(ADMIN, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
        ]),
        new __d3ett.D3ETypeUsage(RENTER, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
        new __d3ett.D3ETypeUsage(BASEUSER, []),
      ],
      "551ec70d53fb54ab6ea3fd11049760ea"
    ),
    new __d3ett.D3EUsage(
      "Query_getEventList_EventCalendarPage_properties_rentals_computation",
      [
        new __d3ett.D3ETypeUsage(EVENTLIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(RENTALREQUEST, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(EQUIPMENT, []),
              ]),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
              new __d3ett.D3EFieldUsage(7, [
                new __d3ett.D3ETypeUsage(FACILITY, []),
              ]),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
              new __d3ett.D3EFieldUsage(12, [
                new __d3ett.D3ETypeUsage(SCHOOL, []),
              ]),
              new __d3ett.D3EFieldUsage(13, [
                new __d3ett.D3ETypeUsage(SERVICE, []),
              ]),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, []),
              new __d3ett.D3EFieldUsage(16, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "fd938c3f2b962ec1fd851e03f456f450"
    ),
    new __d3ett.D3EUsage(
      "Query_getFacilitiesList_MainPage_properties_facilities_computation",
      [
        new __d3ett.D3ETypeUsage(FACILITIESLIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(FACILITY, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(EQUIPMENT, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, [
                    new __d3ett.D3ETypeUsage(SCHOOL, []),
                  ]),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, [
                new __d3ett.D3ETypeUsage(FACILITYRATE, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, []),
                  ]),
                  new __d3ett.D3EFieldUsage(1, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(SCHOOL, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, [
                    new __d3ett.D3ETypeUsage(SCHOOLTYPE, []),
                  ]),
                  new __d3ett.D3EFieldUsage(7, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(5, [
                new __d3ett.D3ETypeUsage(SERVICE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(FACILITYTYPE, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(DFILE, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "2a72efef3f4d1d068de7a31d557cc0ff"
    ),
    new __d3ett.D3EUsage(
      "Query_getFacilityById_BookingPage_facility_fetchData",
      [
        new __d3ett.D3ETypeUsage(FACILITY, [
          new __d3ett.D3EFieldUsage(0, [
            new __d3ett.D3ETypeUsage(EQUIPMENT, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, [
                new __d3ett.D3ETypeUsage(SCHOOL, []),
              ]),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(DFILE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, [
            new __d3ett.D3ETypeUsage(FACILITYRATE, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(4, [
            new __d3ett.D3ETypeUsage(SCHOOL, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(SCHOOLTYPE, []),
              ]),
              new __d3ett.D3EFieldUsage(7, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(5, [
            new __d3ett.D3ETypeUsage(SERVICE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(6, [
            new __d3ett.D3ETypeUsage(FACILITYTYPE, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
          ]),
        ]),
      ],
      "d5751e19748c8529079db9d871243495"
    ),
    new __d3ett.D3EUsage(
      "Query_getFacilityTypeList_MainHomePage_properties_facilityTypes_computation",
      [
        new __d3ett.D3ETypeUsage(FACILITYTYPELIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(FACILITYTYPE, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "18157e297dd2213f63c3ecaa18079c39"
    ),
    new __d3ett.D3EUsage(
      "Query_getFacilityTypeList_MainPage_properties_facilityTypes_computation",
      [
        new __d3ett.D3ETypeUsage(FACILITYTYPELIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(FACILITYTYPE, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "18157e297dd2213f63c3ecaa18079c39"
    ),
    new __d3ett.D3EUsage(
      "Query_getFacilityTypeList_RentalSearchRequestView_properties_facilityTypes_computation",
      [
        new __d3ett.D3ETypeUsage(FACILITYTYPELIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(FACILITYTYPE, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "18157e297dd2213f63c3ecaa18079c39"
    ),
    new __d3ett.D3EUsage(
      "Query_getMessageList_CommentHistoryView_properties_query_computation",
      [
        new __d3ett.D3ETypeUsage(MESSAGELIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(MESSAGE, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(ADMIN, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(RENTALREQUEST, []),
              ]),
              new __d3ett.D3EFieldUsage(5, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "2b18ceb00fe54e71b2e8a2ad3466da6d"
    ),
    new __d3ett.D3EUsage(
      "Query_getPaymentHistory_PaymentDetailsCardView_properties_query_computation",
      [
        new __d3ett.D3ETypeUsage(PAYMENTHISTORY, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(PAYMENTRECEIPT, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(3, [
                new __d3ett.D3ETypeUsage(RENTALREQUEST, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, []),
                  ]),
                  new __d3ett.D3EFieldUsage(1, [
                    new __d3ett.D3ETypeUsage(RENTER, []),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, [
                    new __d3ett.D3ETypeUsage(EQUIPMENT, []),
                  ]),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                  new __d3ett.D3EFieldUsage(7, [
                    new __d3ett.D3ETypeUsage(FACILITY, []),
                  ]),
                  new __d3ett.D3EFieldUsage(8, []),
                  new __d3ett.D3EFieldUsage(9, []),
                  new __d3ett.D3EFieldUsage(10, []),
                  new __d3ett.D3EFieldUsage(11, []),
                  new __d3ett.D3EFieldUsage(12, [
                    new __d3ett.D3ETypeUsage(SCHOOL, []),
                  ]),
                  new __d3ett.D3EFieldUsage(13, [
                    new __d3ett.D3ETypeUsage(SERVICE, []),
                  ]),
                  new __d3ett.D3EFieldUsage(14, []),
                  new __d3ett.D3EFieldUsage(15, []),
                  new __d3ett.D3EFieldUsage(16, []),
                ]),
              ]),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "de28b0c9f8fbaa7ac35a56e496d8b4f5"
    ),
    new __d3ett.D3EUsage(
      "Query_getPaymentHistory_RentalPaymentHistoryPage_properties_query_computation",
      [
        new __d3ett.D3ETypeUsage(PAYMENTHISTORY, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(PAYMENTRECEIPT, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(3, [
                new __d3ett.D3ETypeUsage(RENTALREQUEST, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, []),
                  ]),
                  new __d3ett.D3EFieldUsage(1, [
                    new __d3ett.D3ETypeUsage(RENTER, []),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, [
                    new __d3ett.D3ETypeUsage(EQUIPMENT, []),
                  ]),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                  new __d3ett.D3EFieldUsage(7, [
                    new __d3ett.D3ETypeUsage(FACILITY, []),
                  ]),
                  new __d3ett.D3EFieldUsage(8, []),
                  new __d3ett.D3EFieldUsage(9, []),
                  new __d3ett.D3EFieldUsage(10, []),
                  new __d3ett.D3EFieldUsage(11, []),
                  new __d3ett.D3EFieldUsage(12, [
                    new __d3ett.D3ETypeUsage(SCHOOL, []),
                  ]),
                  new __d3ett.D3EFieldUsage(13, [
                    new __d3ett.D3ETypeUsage(SERVICE, []),
                  ]),
                  new __d3ett.D3EFieldUsage(14, []),
                  new __d3ett.D3EFieldUsage(15, []),
                  new __d3ett.D3EFieldUsage(16, []),
                ]),
              ]),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "de28b0c9f8fbaa7ac35a56e496d8b4f5"
    ),
    new __d3ett.D3EUsage(
      "Query_getPendingPayments_RentalCurrentInvoicePage_properties_query_computation",
      [
        new __d3ett.D3ETypeUsage(PENDINGPAYMENTS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(RENTALREQUEST, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(EQUIPMENT, []),
              ]),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
              new __d3ett.D3EFieldUsage(7, [
                new __d3ett.D3ETypeUsage(FACILITY, []),
              ]),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
              new __d3ett.D3EFieldUsage(12, [
                new __d3ett.D3ETypeUsage(SCHOOL, []),
              ]),
              new __d3ett.D3EFieldUsage(13, [
                new __d3ett.D3ETypeUsage(SERVICE, []),
              ]),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, []),
              new __d3ett.D3EFieldUsage(16, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "b8779b9c5f6c32ff0ff6c2a03a6dd4ef"
    ),
    new __d3ett.D3EUsage(
      "Query_getPendingPayments_RentalPaymentsView_properties_query_computation",
      [
        new __d3ett.D3ETypeUsage(PENDINGPAYMENTS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(RENTALREQUEST, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(EQUIPMENT, []),
              ]),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
              new __d3ett.D3EFieldUsage(7, [
                new __d3ett.D3ETypeUsage(FACILITY, []),
              ]),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
              new __d3ett.D3EFieldUsage(12, [
                new __d3ett.D3ETypeUsage(SCHOOL, []),
              ]),
              new __d3ett.D3EFieldUsage(13, [
                new __d3ett.D3ETypeUsage(SERVICE, []),
              ]),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, []),
              new __d3ett.D3EFieldUsage(16, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "b8779b9c5f6c32ff0ff6c2a03a6dd4ef"
    ),
    new __d3ett.D3EUsage(
      "Query_getPendingRentalRequests_RentalPendingRequestsView_properties_submititedList_computation",
      [
        new __d3ett.D3ETypeUsage(PENDINGRENTALREQUESTS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(RENTALREQUEST, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(EQUIPMENT, []),
              ]),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
              new __d3ett.D3EFieldUsage(7, [
                new __d3ett.D3ETypeUsage(FACILITY, []),
              ]),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
              new __d3ett.D3EFieldUsage(12, [
                new __d3ett.D3ETypeUsage(SCHOOL, []),
              ]),
              new __d3ett.D3EFieldUsage(13, [
                new __d3ett.D3ETypeUsage(SERVICE, []),
              ]),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, []),
              new __d3ett.D3EFieldUsage(16, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "2d87c5e66c3f74865345c83e9a70f115"
    ),
    new __d3ett.D3EUsage(
      "Query_getRentalBookings_BookingPage_properties_allBookings_computation",
      [
        new __d3ett.D3ETypeUsage(RENTALBOOKINGS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(RENTALREQUEST, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(EQUIPMENT, []),
              ]),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
              new __d3ett.D3EFieldUsage(7, [
                new __d3ett.D3ETypeUsage(FACILITY, []),
              ]),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
              new __d3ett.D3EFieldUsage(12, [
                new __d3ett.D3ETypeUsage(SCHOOL, []),
              ]),
              new __d3ett.D3EFieldUsage(13, [
                new __d3ett.D3ETypeUsage(SERVICE, []),
              ]),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, []),
              new __d3ett.D3EFieldUsage(16, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "3e99eeccc7b8e72629c66e28f69c8ba8"
    ),
    new __d3ett.D3EUsage(
      "Query_getRentalFacilities_SearchPage_properties_facilitiesQuery_computation",
      [
        new __d3ett.D3ETypeUsage(RENTALFACILITIES, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(FACILITY, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(EQUIPMENT, []),
              ]),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, [
                new __d3ett.D3ETypeUsage(FACILITYRATE, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, []),
                  ]),
                  new __d3ett.D3EFieldUsage(1, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(SCHOOL, []),
              ]),
              new __d3ett.D3EFieldUsage(5, [
                new __d3ett.D3ETypeUsage(SERVICE, []),
              ]),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(FACILITYTYPE, []),
              ]),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "6f4d50d64ac00da11437e7580344faf2"
    ),
    new __d3ett.D3EUsage(
      "Query_getRentalRequestById_InvoiceView_request_fetchData",
      [
        new __d3ett.D3ETypeUsage(RENTALREQUEST, [
          new __d3ett.D3EFieldUsage(0, [
            new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(RENTER, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, []),
          new __d3ett.D3EFieldUsage(4, [
            new __d3ett.D3ETypeUsage(EQUIPMENT, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, [
                new __d3ett.D3ETypeUsage(SCHOOL, []),
              ]),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(5, []),
          new __d3ett.D3EFieldUsage(6, []),
          new __d3ett.D3EFieldUsage(7, [
            new __d3ett.D3ETypeUsage(FACILITY, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(EQUIPMENT, []),
              ]),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, [
                new __d3ett.D3ETypeUsage(FACILITYRATE, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(1, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(1, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(SCHOOL, []),
              ]),
              new __d3ett.D3EFieldUsage(5, [
                new __d3ett.D3ETypeUsage(SERVICE, []),
              ]),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(FACILITYTYPE, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(DFILE, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(8, []),
          new __d3ett.D3EFieldUsage(9, []),
          new __d3ett.D3EFieldUsage(10, []),
          new __d3ett.D3EFieldUsage(11, []),
          new __d3ett.D3EFieldUsage(12, [
            new __d3ett.D3ETypeUsage(SCHOOL, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(SCHOOLTYPE, []),
              ]),
              new __d3ett.D3EFieldUsage(7, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(13, [
            new __d3ett.D3ETypeUsage(SERVICE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(14, []),
          new __d3ett.D3EFieldUsage(15, []),
          new __d3ett.D3EFieldUsage(16, []),
        ]),
      ],
      "13fee0dd2d48d0d1961e1e95c8fc6198"
    ),
    new __d3ett.D3EUsage(
      "Query_getRentalRequestById_ReservationView_request_fetchData",
      [
        new __d3ett.D3ETypeUsage(RENTALREQUEST, [
          new __d3ett.D3EFieldUsage(0, [
            new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(RENTER, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, []),
          new __d3ett.D3EFieldUsage(4, [
            new __d3ett.D3ETypeUsage(EQUIPMENT, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, [
                new __d3ett.D3ETypeUsage(SCHOOL, []),
              ]),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(5, []),
          new __d3ett.D3EFieldUsage(6, []),
          new __d3ett.D3EFieldUsage(7, [
            new __d3ett.D3ETypeUsage(FACILITY, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(EQUIPMENT, []),
              ]),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, [
                new __d3ett.D3ETypeUsage(FACILITYRATE, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(1, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(1, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(SCHOOL, []),
              ]),
              new __d3ett.D3EFieldUsage(5, [
                new __d3ett.D3ETypeUsage(SERVICE, []),
              ]),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(FACILITYTYPE, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(DFILE, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(8, []),
          new __d3ett.D3EFieldUsage(9, []),
          new __d3ett.D3EFieldUsage(10, []),
          new __d3ett.D3EFieldUsage(11, []),
          new __d3ett.D3EFieldUsage(12, [
            new __d3ett.D3ETypeUsage(SCHOOL, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(SCHOOLTYPE, [
                  new __d3ett.D3EFieldUsage(0, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(7, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(13, [
            new __d3ett.D3ETypeUsage(SERVICE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(14, []),
          new __d3ett.D3EFieldUsage(15, []),
          new __d3ett.D3EFieldUsage(16, []),
        ]),
      ],
      "09dff60ab1a40fd29f55003839a4b6b9"
    ),
    new __d3ett.D3EUsage(
      "Query_getRentalRequests_RentalReservationPage_properties_rentalList_computation",
      [
        new __d3ett.D3ETypeUsage(RENTALREQUESTS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(RENTALREQUEST, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(EQUIPMENT, []),
              ]),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
              new __d3ett.D3EFieldUsage(7, [
                new __d3ett.D3ETypeUsage(FACILITY, []),
              ]),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
              new __d3ett.D3EFieldUsage(12, [
                new __d3ett.D3ETypeUsage(SCHOOL, []),
              ]),
              new __d3ett.D3EFieldUsage(13, [
                new __d3ett.D3ETypeUsage(SERVICE, []),
              ]),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, []),
              new __d3ett.D3EFieldUsage(16, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "233ababcda8bd635af165f98df7ee672"
    ),
    new __d3ett.D3EUsage(
      "Query_getRentalRequests_RentalUpcomingEventView_properties_upcomingEventList_computation",
      [
        new __d3ett.D3ETypeUsage(RENTALREQUESTS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(RENTALREQUEST, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(EQUIPMENT, []),
              ]),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
              new __d3ett.D3EFieldUsage(7, [
                new __d3ett.D3ETypeUsage(FACILITY, []),
              ]),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
              new __d3ett.D3EFieldUsage(12, [
                new __d3ett.D3ETypeUsage(SCHOOL, []),
              ]),
              new __d3ett.D3EFieldUsage(13, [
                new __d3ett.D3ETypeUsage(SERVICE, []),
              ]),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, []),
              new __d3ett.D3EFieldUsage(16, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "233ababcda8bd635af165f98df7ee672"
    ),
    new __d3ett.D3EUsage(
      "Query_getRenterById_Banner_renter_fetchData",
      [
        new __d3ett.D3ETypeUsage(RENTER, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
        new __d3ett.D3ETypeUsage(BASEUSER, []),
      ],
      "997908f7f2b68cf9f17836b4f427c687"
    ),
    new __d3ett.D3EUsage(
      "Query_getRequestDocuments_RentalDocumentPage_properties_query_computation",
      [
        new __d3ett.D3ETypeUsage(REQUESTDOCUMENTS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(REQUESTDOCUMENT, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, [
                new __d3ett.D3ETypeUsage(RENTALREQUEST, []),
              ]),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "776b63a2cee9fea1c7133d28620eab05"
    ),
    new __d3ett.D3EUsage(
      "Query_getRequestInsurances_RentalInsurancePage_properties_query_computation",
      [
        new __d3ett.D3ETypeUsage(REQUESTINSURANCES, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(REQUESTINSURANCE, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, [
                new __d3ett.D3ETypeUsage(RENTALREQUEST, []),
              ]),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "731ebd4c081918ab23e1dde294d58daf"
    ),
    new __d3ett.D3EUsage(
      "Query_getSchoolById_EventCalendarPage_school_fetchData",
      [
        new __d3ett.D3ETypeUsage(SCHOOL, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, []),
          new __d3ett.D3EFieldUsage(4, []),
          new __d3ett.D3EFieldUsage(5, []),
          new __d3ett.D3EFieldUsage(6, [
            new __d3ett.D3ETypeUsage(SCHOOLTYPE, [
              new __d3ett.D3EFieldUsage(0, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(7, []),
        ]),
      ],
      "695b7e9e663f7d54d983d690142d856e"
    ),
    new __d3ett.D3EUsage(
      "Query_getSchoolsList_MainHomePage_properties_schools_computation",
      [
        new __d3ett.D3ETypeUsage(SCHOOLSLIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(SCHOOL, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(SCHOOLTYPE, []),
              ]),
              new __d3ett.D3EFieldUsage(7, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "fcf2af1c230b6fb73977026e6baecc71"
    ),
    new __d3ett.D3EUsage(
      "Query_getSchoolsList_MainPage_properties_schools_computation",
      [
        new __d3ett.D3ETypeUsage(SCHOOLSLIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(SCHOOL, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(SCHOOLTYPE, [
                  new __d3ett.D3EFieldUsage(0, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(7, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "9a60bd7c62f64d7d6e4147234bda485a"
    ),
    new __d3ett.D3EUsage(
      "Query_getSchoolsList_RentalSearchRequestView_properties_schools_computation",
      [
        new __d3ett.D3ETypeUsage(SCHOOLSLIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(SCHOOL, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(SCHOOLTYPE, []),
              ]),
              new __d3ett.D3EFieldUsage(7, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "fcf2af1c230b6fb73977026e6baecc71"
    ),
    new __d3ett.D3EUsage(
      "Query_loadRenter_MainHomePage_eventHandlers_onInit_block",
      [
        new __d3ett.D3ETypeUsage(RENTER, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
        new __d3ett.D3ETypeUsage(BASEUSER, []),
      ],
      "997908f7f2b68cf9f17836b4f427c687"
    ),
    new __d3ett.D3EUsage(
      "Query_loadRenter_MainPage_eventHandlers_onInit_block",
      [
        new __d3ett.D3ETypeUsage(RENTER, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
        new __d3ett.D3ETypeUsage(BASEUSER, []),
      ],
      "997908f7f2b68cf9f17836b4f427c687"
    ),
    new __d3ett.D3EUsage(
      "Query_loginRenterUserWithEmailAndPassword_RentalLoginPage_eventHandlers_authenticatingUser_block",
      [
        new __d3ett.D3ETypeUsage(LOGINRESULT, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, [
            new __d3ett.D3ETypeUsage(ADMIN, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
            ]),
            new __d3ett.D3ETypeUsage(RENTER, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
        ]),
      ],
      "190eab28d44486997221e322294f57c1"
    ),
    new __d3ett.D3EUsage(
      "Query_loginRenterUserWithEmailAndPassword_RentelSignupPage_eventHandlers_doAuthenticate_block",
      [
        new __d3ett.D3ETypeUsage(LOGINRESULT, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, [
            new __d3ett.D3ETypeUsage(ADMIN, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
            ]),
            new __d3ett.D3ETypeUsage(RENTER, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
        ]),
      ],
      "190eab28d44486997221e322294f57c1"
    ),
    new __d3ett.D3EUsage(
      "Query_loginWithOTP_RentalForgotPasswordPage_eventHandlers_verifyOTP_block",
      [
        new __d3ett.D3ETypeUsage(LOGINRESULT, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, [
            new __d3ett.D3ETypeUsage(ADMIN, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
            ]),
            new __d3ett.D3ETypeUsage(RENTER, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
        ]),
      ],
      "190eab28d44486997221e322294f57c1"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onMessageListChange_CommentHistoryView_query_synchronise",
      [
        new __d3ett.D3ETypeUsage(MESSAGELIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(MESSAGE, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(ADMIN, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(RENTALREQUEST, []),
              ]),
              new __d3ett.D3EFieldUsage(5, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "2b18ceb00fe54e71b2e8a2ad3466da6d"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onPaymentHistoryChange_PaymentDetailsCardView_query_synchronise",
      [
        new __d3ett.D3ETypeUsage(PAYMENTHISTORY, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(PAYMENTRECEIPT, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(3, [
                new __d3ett.D3ETypeUsage(RENTALREQUEST, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, []),
                  ]),
                  new __d3ett.D3EFieldUsage(1, [
                    new __d3ett.D3ETypeUsage(RENTER, []),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, [
                    new __d3ett.D3ETypeUsage(EQUIPMENT, []),
                  ]),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                  new __d3ett.D3EFieldUsage(7, [
                    new __d3ett.D3ETypeUsage(FACILITY, []),
                  ]),
                  new __d3ett.D3EFieldUsage(8, []),
                  new __d3ett.D3EFieldUsage(9, []),
                  new __d3ett.D3EFieldUsage(10, []),
                  new __d3ett.D3EFieldUsage(11, []),
                  new __d3ett.D3EFieldUsage(12, [
                    new __d3ett.D3ETypeUsage(SCHOOL, []),
                  ]),
                  new __d3ett.D3EFieldUsage(13, [
                    new __d3ett.D3ETypeUsage(SERVICE, []),
                  ]),
                  new __d3ett.D3EFieldUsage(14, []),
                  new __d3ett.D3EFieldUsage(15, []),
                  new __d3ett.D3EFieldUsage(16, []),
                ]),
              ]),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "de28b0c9f8fbaa7ac35a56e496d8b4f5"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onPaymentHistoryChange_RentalPaymentHistoryPage_query_synchronise",
      [
        new __d3ett.D3ETypeUsage(PAYMENTHISTORY, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(PAYMENTRECEIPT, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(3, [
                new __d3ett.D3ETypeUsage(RENTALREQUEST, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, []),
                  ]),
                  new __d3ett.D3EFieldUsage(1, [
                    new __d3ett.D3ETypeUsage(RENTER, []),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, [
                    new __d3ett.D3ETypeUsage(EQUIPMENT, []),
                  ]),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                  new __d3ett.D3EFieldUsage(7, [
                    new __d3ett.D3ETypeUsage(FACILITY, []),
                  ]),
                  new __d3ett.D3EFieldUsage(8, []),
                  new __d3ett.D3EFieldUsage(9, []),
                  new __d3ett.D3EFieldUsage(10, []),
                  new __d3ett.D3EFieldUsage(11, []),
                  new __d3ett.D3EFieldUsage(12, [
                    new __d3ett.D3ETypeUsage(SCHOOL, []),
                  ]),
                  new __d3ett.D3EFieldUsage(13, [
                    new __d3ett.D3ETypeUsage(SERVICE, []),
                  ]),
                  new __d3ett.D3EFieldUsage(14, []),
                  new __d3ett.D3EFieldUsage(15, []),
                  new __d3ett.D3EFieldUsage(16, []),
                ]),
              ]),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "de28b0c9f8fbaa7ac35a56e496d8b4f5"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onPendingPaymentsChange_RentalCurrentInvoicePage_query_synchronise",
      [
        new __d3ett.D3ETypeUsage(PENDINGPAYMENTS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(RENTALREQUEST, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(EQUIPMENT, []),
              ]),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
              new __d3ett.D3EFieldUsage(7, [
                new __d3ett.D3ETypeUsage(FACILITY, []),
              ]),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
              new __d3ett.D3EFieldUsage(12, [
                new __d3ett.D3ETypeUsage(SCHOOL, []),
              ]),
              new __d3ett.D3EFieldUsage(13, [
                new __d3ett.D3ETypeUsage(SERVICE, []),
              ]),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, []),
              new __d3ett.D3EFieldUsage(16, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "b8779b9c5f6c32ff0ff6c2a03a6dd4ef"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onPendingPaymentsChange_RentalPaymentsView_query_synchronise",
      [
        new __d3ett.D3ETypeUsage(PENDINGPAYMENTS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(RENTALREQUEST, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(FACILITYUSAGECATEGORY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(RENTER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(EQUIPMENT, []),
              ]),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
              new __d3ett.D3EFieldUsage(7, [
                new __d3ett.D3ETypeUsage(FACILITY, []),
              ]),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
              new __d3ett.D3EFieldUsage(12, [
                new __d3ett.D3ETypeUsage(SCHOOL, []),
              ]),
              new __d3ett.D3EFieldUsage(13, [
                new __d3ett.D3ETypeUsage(SERVICE, []),
              ]),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, []),
              new __d3ett.D3EFieldUsage(16, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "b8779b9c5f6c32ff0ff6c2a03a6dd4ef"
    ),
  ];
  private static _types: Array<__d3ett.D3ETemplateType> = [
    new __d3ett.D3ETemplateType(
      "Admin",
      "cbfba9ab1d5dcb990ad1445d3fbdf245",
      [
        new __d3ett.D3ETemplateField(
          "fullName",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "role",
          ADMINROLE,
          __d3ett.D3EFieldType.Enum,
          {}
        ),
      ],
      {
        "parent": 2,
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new Admin(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "AdminRole",
      "6d8310bee59df775520ae1de957a1d49",
      [
        new __d3ett.D3ETemplateField("School", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Admin", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Support", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "BaseUser",
      "5116c054754a25edc5a13eddbd47e85a",
      [],
      { "abstract": true, "refType": __d3ett.D3ERefType.Model }
    ),
    new __d3ett.D3ETemplateType(
      "Blob",
      "e8016c85ada38bdc5fac616ec1318047",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "Boolean",
      "27226c864bac7454a8504f8edb15d95b",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "CalendarEvent",
      "4dfea3b716e486cc50407e27dd4c13b5",
      [
        new __d3ett.D3ETemplateField(
          "allDay",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean
        ),
        new __d3ett.D3ETemplateField(
          "backgroundColor",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "category",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField("date", DATE, __d3ett.D3EFieldType.Date),
        new __d3ett.D3ETemplateField(
          "description",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "endTime",
          DATETIME,
          __d3ett.D3EFieldType.DateTime
        ),
        new __d3ett.D3ETemplateField(
          "startTime",
          DATETIME,
          __d3ett.D3EFieldType.DateTime
        ),
        new __d3ett.D3ETemplateField(
          "title",
          STRING,
          __d3ett.D3EFieldType.String
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new CalendarEvent(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "CalendarViewType",
      "e6cf075321a6d83271d39e7089ec10e2",
      [
        new __d3ett.D3ETemplateField("Month", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Week", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Year", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Day", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "ChangePasswordRequest",
      "7bd88617205355840f720948d2b93298",
      [
        new __d3ett.D3ETemplateField(
          "newPassword",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new ChangePasswordRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "ConnectionStatus",
      "0d5c2bfbc6b6e414981c0c67321165d5",
      [
        new __d3ett.D3ETemplateField(
          "Connecting",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField("Connected", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField(
          "ConnectionBusy",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField(
          "ConnectionNormal",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField(
          "ConnectionFailed",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField(
          "RestoreFailed",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField(
          "AuthFailed",
          0,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "DFile",
      "71a781845a8ebe8adf67352a573af199",
      [
        new __d3ett.D3ETemplateField("id", STRING, __d3ett.D3EFieldType.String),
        new __d3ett.D3ETemplateField(
          "name",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "size",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "mimeType",
          STRING,
          __d3ett.D3EFieldType.String
        ),
      ],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "Date",
      "44749712dbec183e983dcd78a7736c41",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "DateTime",
      "8cf10d2341ed01492506085688270c1e",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "Double",
      "d909d38d705ce75386dd86e611a82f5b",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "Duration",
      "e02d2ae03de9d493df2b6b2d2813d302",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "Equipment",
      "d40d22145e935e0ec9c696323b17a6b9",
      [
        new __d3ett.D3ETemplateField(
          "name",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "ratePerHour",
          DOUBLE,
          __d3ett.D3EFieldType.Double,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "school",
          SCHOOL,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      { "refType": __d3ett.D3ERefType.Model, "creator": () => new Equipment() }
    ),
    new __d3ett.D3ETemplateType(
      "EventList",
      "a15bc8a5bf5b854440a0e95d730060b9",
      [
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          RENTALREQUEST,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      { "refType": __d3ett.D3ERefType.Struct, "creator": () => new EventList() }
    ),
    new __d3ett.D3ETemplateType(
      "EventListRequest",
      "5eaabfdebf65ba8b3e87dda5b9bae8a5",
      [
        new __d3ett.D3ETemplateField(
          "renter",
          RENTER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "school",
          SCHOOL,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new EventListRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "FacilitiesList",
      "f53a58e7ff1d517a4347dd3c90287dc4",
      [
        new __d3ett.D3ETemplateField(
          "count",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          FACILITY,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new FacilitiesList(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "FacilitiesListRequest",
      "87ff95244991d9ba5e77fb4c5c99971d",
      [
        new __d3ett.D3ETemplateField(
          "ascending",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "offset",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "orderBy",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "pageSize",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "school",
          SCHOOL,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "type",
          FACILITYTYPE,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new FacilitiesListRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "Facility",
      "14f0b69bd6a508c086f21987b7856964",
      [
        new __d3ett.D3ETemplateField(
          "equipments",
          EQUIPMENT,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "images",
          DFILE,
          __d3ett.D3EFieldType.DFile,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "name",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "prices",
          FACILITYRATE,
          __d3ett.D3EFieldType.Ref,
          { "child": true, "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "school",
          SCHOOL,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "services",
          SERVICE,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "type",
          FACILITYTYPE,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      { "refType": __d3ett.D3ERefType.Model, "creator": () => new Facility() }
    ),
    new __d3ett.D3ETemplateType(
      "FacilityRate",
      "06fa156f28fdbab87df1cf7ae740f403",
      [
        new __d3ett.D3ETemplateField(
          "category",
          FACILITYUSAGECATEGORY,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "price",
          DOUBLE,
          __d3ett.D3EFieldType.Double,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new FacilityRate(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "FacilityType",
      "8f83b82c75a0d30ff7fe9f9130d7221d",
      [
        new __d3ett.D3ETemplateField(
          "image",
          DFILE,
          __d3ett.D3EFieldType.DFile,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "name",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "policies",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new FacilityType(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "FacilityTypeList",
      "bc99138f41dbc3dac1f4e0bc708e92a9",
      [
        new __d3ett.D3ETemplateField(
          "count",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          FACILITYTYPE,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new FacilityTypeList(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "FacilityTypeListRequest",
      "aea061a378b83a89a36c2a75e3b74063",
      [
        new __d3ett.D3ETemplateField(
          "ascending",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "offset",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "orderBy",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "pageSize",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new FacilityTypeListRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "FacilityUsageCategory",
      "8b3b8f98c77038d565202980e3ac80c7",
      [
        new __d3ett.D3ETemplateField(
          "description",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "name",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new FacilityUsageCategory(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "GSDRMSFieldType",
      "d8f6467efe153256f917796a8ad2b989",
      [
        new __d3ett.D3ETemplateField("Rectangle", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Circle", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Underline", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("None", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "GradientType",
      "dfc80f7e140e2dac94987925914062a8",
      [
        new __d3ett.D3ETemplateField("Color", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField(
          "LinearGradient",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField(
          "SweepGradient",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField(
          "RadialGradient",
          0,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "IconType",
      "4529ef025543fdd73b83290b6c7b541f",
      [
        new __d3ett.D3ETemplateField("SVG", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Font", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "ImageFrom",
      "7462b5b42617aa6ada4b99b737535133",
      [
        new __d3ett.D3ETemplateField("Network", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Asset", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Project", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "Integer",
      "a0faef0851b4294c06f2b94bb1cb2044",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "LoginResult",
      "43b15c92fa28924318ec2dd9b20d65d3",
      [
        new __d3ett.D3ETemplateField(
          "failureMessage",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "success",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean
        ),
        new __d3ett.D3ETemplateField(
          "token",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "userObject",
          BASEUSER,
          __d3ett.D3EFieldType.Ref
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new LoginResult(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "Message",
      "2b839f271eb67312a516a0b06c955a26",
      [
        new __d3ett.D3ETemplateField(
          "admin",
          ADMIN,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "createdDate",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "message",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "renter",
          RENTER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "request",
          RENTALREQUEST,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "type",
          MESSAGETYPE,
          __d3ett.D3EFieldType.Enum,
          {}
        ),
      ],
      { "refType": __d3ett.D3ERefType.Model, "creator": () => new Message() }
    ),
    new __d3ett.D3ETemplateType(
      "MessageList",
      "76276640067663d1aaa05f0386a07162",
      [
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          MESSAGE,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new MessageList(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "MessageListRequest",
      "00277bd0dc422150cdf5f5b7aff863e0",
      [
        new __d3ett.D3ETemplateField(
          "ascending",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "needComment",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "needHistory",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "orderBy",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "request",
          RENTALREQUEST,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new MessageListRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "MessageType",
      "66d40c16413440122768463c4eccaf66",
      [
        new __d3ett.D3ETemplateField("History", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Comment", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "OneTimePassword",
      "51ff4b4311b22fe068e4103b21e0b0b8",
      [
        new __d3ett.D3ETemplateField(
          "input",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "inputType",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "token",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "userType",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new OneTimePassword(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "PaymentHistory",
      "d698143f145be71821c0ef5d9258ab1c",
      [
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          PAYMENTRECEIPT,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new PaymentHistory(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "PaymentHistoryRequest",
      "b4d07bb399f3edd54beab21638dc34ee",
      [
        new __d3ett.D3ETemplateField(
          "renter",
          RENTER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "request",
          RENTALREQUEST,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new PaymentHistoryRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "PaymentReceipt",
      "2154b31fd2b85d67ad3f5d54d37d3da5",
      [
        new __d3ett.D3ETemplateField(
          "amount",
          DOUBLE,
          __d3ett.D3EFieldType.Double,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "createdDate",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "renter",
          RENTER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "request",
          RENTALREQUEST,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new PaymentReceipt(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "PendingPayments",
      "c60e085237ab36af5750236f96f6db08",
      [
        new __d3ett.D3ETemplateField(
          "count",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          RENTALREQUEST,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new PendingPayments(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "PendingPaymentsRequest",
      "3e58ff0485e814e3ec40f7a9611eaac3",
      [
        new __d3ett.D3ETemplateField(
          "offset",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "pageSize",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "renter",
          RENTER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "school",
          SCHOOL,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new PendingPaymentsRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "PendingRentalRequests",
      "26ea12255e318eac344ae9ab1730062c",
      [
        new __d3ett.D3ETemplateField(
          "count",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          RENTALREQUEST,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new PendingRentalRequests(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "PendingRentalRequestsRequest",
      "aad7ef43a22f72b91ed23486e07cc9cf",
      [
        new __d3ett.D3ETemplateField(
          "renter",
          RENTER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "school",
          SCHOOL,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new PendingRentalRequestsRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RentalBookings",
      "2092334e7c71ecb891d1fcdeee85a9bd",
      [
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          RENTALREQUEST,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new RentalBookings(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RentalBookingsRequest",
      "9b6e137a58d74d4f641ec16e32d38468",
      [
        new __d3ett.D3ETemplateField(
          "date",
          DATE,
          __d3ett.D3EFieldType.Date,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "facility",
          FACILITY,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new RentalBookingsRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RentalFacilities",
      "b84f4cc57dc238c05f97132bdcecffc1",
      [
        new __d3ett.D3ETemplateField(
          "count",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          FACILITY,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new RentalFacilities(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RentalFacilitiesRequest",
      "4f3896f908670d45bdf063792603a166",
      [
        new __d3ett.D3ETemplateField(
          "city",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "facilityType",
          FACILITYTYPE,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "offset",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "pageSize",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "state",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new RentalFacilitiesRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RentalRequest",
      "eb9a6bbbc1d9de2f04d8e348b8b19432",
      [
        new __d3ett.D3ETemplateField(
          "category",
          FACILITYUSAGECATEGORY,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "createdBy",
          RENTER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "createdDate",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "endTime",
          TIME,
          __d3ett.D3EFieldType.Time,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "equipments",
          EQUIPMENT,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "eventDate",
          DATE,
          __d3ett.D3EFieldType.Date,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "eventName",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "facility",
          FACILITY,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "paid",
          DOUBLE,
          __d3ett.D3EFieldType.Double,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "price",
          DOUBLE,
          __d3ett.D3EFieldType.Double,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "requestChanges",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "requestId",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "school",
          SCHOOL,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "services",
          SERVICE,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "startTime",
          TIME,
          __d3ett.D3EFieldType.Time,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "status",
          REQUESTSTATUS,
          __d3ett.D3EFieldType.Enum,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "total",
          DOUBLE,
          __d3ett.D3EFieldType.Double,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new RentalRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RentalRequestChanges",
      "7691c5f28fd9a0fa6b3dfb52da204cce",
      [
        new __d3ett.D3ETemplateField(
          "changes",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "request",
          RENTALREQUEST,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new RentalRequestChanges(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RentalRequests",
      "ab407e7f5428dd8045292495304b4eff",
      [
        new __d3ett.D3ETemplateField(
          "count",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          RENTALREQUEST,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new RentalRequests(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RentalRequestsRequest",
      "f542ff7b4dd83dad60dce8f43e3e71e6",
      [
        new __d3ett.D3ETemplateField(
          "applyStatus",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "fromDate",
          DATE,
          __d3ett.D3EFieldType.Date,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "offset",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "pageSize",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "renter",
          RENTER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "school",
          SCHOOL,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "status",
          REQUESTSTATUS,
          __d3ett.D3EFieldType.Enum,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "toDate",
          DATE,
          __d3ett.D3EFieldType.Date,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new RentalRequestsRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "Renter",
      "f72134eaf24ef5452d419b66141e43dd",
      [
        new __d3ett.D3ETemplateField(
          "active",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "email",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "fullName",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      {
        "parent": 2,
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new Renter(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RequestDocument",
      "6a9d1d654c14791ff700634d9d657b36",
      [
        new __d3ett.D3ETemplateField(
          "attachment",
          DFILE,
          __d3ett.D3EFieldType.DFile,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "createdBy",
          RENTER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "createdDate",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "request",
          RENTALREQUEST,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new RequestDocument(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RequestDocuments",
      "31e332013a107499af950248cb5350e0",
      [
        new __d3ett.D3ETemplateField(
          "count",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          REQUESTDOCUMENT,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new RequestDocuments(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RequestDocumentsRequest",
      "5b7410cbf5607ff554a47e4e2af3cae8",
      [
        new __d3ett.D3ETemplateField(
          "ascending",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "offset",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "orderBy",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "pageSize",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "renter",
          RENTER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new RequestDocumentsRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RequestInsurance",
      "f163a74f293ce8e6a283b90c958f32de",
      [
        new __d3ett.D3ETemplateField(
          "attachment",
          DFILE,
          __d3ett.D3EFieldType.DFile,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "createdBy",
          RENTER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "createdDate",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "request",
          RENTALREQUEST,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new RequestInsurance(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RequestInsurances",
      "19af972ebf35e257abb99dc9496f25d0",
      [
        new __d3ett.D3ETemplateField(
          "count",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          REQUESTINSURANCE,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new RequestInsurances(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RequestInsurancesRequest",
      "1ac1782962996269e9d2d102472fc555",
      [
        new __d3ett.D3ETemplateField(
          "ascending",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "offset",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "orderBy",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "pageSize",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "renter",
          RENTER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new RequestInsurancesRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RequestStatus",
      "6ef40ba4519118b191f79453b816d933",
      [
        new __d3ett.D3ETemplateField("Submitted", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField(
          "SchoolRejected",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField(
          "SchoolApproved",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField("Rejected", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Approved", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "RequestStatusUpdate",
      "ad1d704a6d33d24ae208acec4e6e4cf0",
      [
        new __d3ett.D3ETemplateField(
          "rentalRequest",
          RENTALREQUEST,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "status",
          REQUESTSTATUS,
          __d3ett.D3EFieldType.Enum,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new RequestStatusUpdate(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "ResultStatus",
      "b3e4a20b998b614cb3f7bd669946ae7f",
      [
        new __d3ett.D3ETemplateField("Success", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Errors", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "School",
      "285dd70cab6541da190b1bc00b9db79e",
      [
        new __d3ett.D3ETemplateField(
          "address",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "city",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "latitude",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "longitude",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "name",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "pincode",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "schoolType",
          SCHOOLTYPE,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "state",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      { "refType": __d3ett.D3ERefType.Model, "creator": () => new School() }
    ),
    new __d3ett.D3ETemplateType(
      "SchoolType",
      "c3e8647916e2738a81c462d86555688d",
      [
        new __d3ett.D3ETemplateField(
          "name",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      { "refType": __d3ett.D3ERefType.Model, "creator": () => new SchoolType() }
    ),
    new __d3ett.D3ETemplateType(
      "SchoolsList",
      "ce23fce6b04622c9e896cedb35520315",
      [
        new __d3ett.D3ETemplateField(
          "count",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          SCHOOL,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new SchoolsList(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "SchoolsListRequest",
      "c26760c5a71a16df24f99d8fa3ab2437",
      [
        new __d3ett.D3ETemplateField(
          "ascending",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "offset",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "orderBy",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "pageSize",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new SchoolsListRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "Service",
      "8d1f7586fec51238a92b4accfaed2572",
      [
        new __d3ett.D3ETemplateField(
          "name",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "ratePerHour",
          DOUBLE,
          __d3ett.D3EFieldType.Double,
          {}
        ),
      ],
      { "refType": __d3ett.D3ERefType.Model, "creator": () => new Service() }
    ),
    new __d3ett.D3ETemplateType(
      "SignUpRequest",
      "4bab8fc5e32da335e6a91c15ef5fdb8c",
      [
        new __d3ett.D3ETemplateField(
          "email",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "fullName",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "password",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new SignUpRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "String",
      "27118326006d3829667a400ad23d5d98",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "Time",
      "a76d4ef5f3f6a672bbfab2865563e530",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "Type",
      "a1fa27779242b4902f7ae3bdd5c6d508",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "void",
      "cab8111fd0b710a336c898e539090e34",
      [],
      {}
    ),
  ];
  private static readonly _typeMap: Map<string, number> = Map.fromIterables(
    D3ETemplate._types.map((x) => x.name),
    Array.generate(D3ETemplate._types.length, (index) => index)
  );
  public static allFields(type: number): Array<number> {
    return Array.generate(
      D3ETemplate._types[type].fields.length,
      (index) => index
    );
  }
  public static get types(): Array<__d3ett.D3ETemplateType> {
    return D3ETemplate._types;
  }
  public static get usages(): Array<__d3ett.D3EUsage> {
    return D3ETemplate._usages;
  }
  public static typeString(val: number): string {
    return D3ETemplate._types[val].name;
  }
  public static typeInt(val: string): number {
    return D3ETemplate._typeMap.get(val);
  }
  public static _getField(type: number, val: number): __d3ett.D3ETemplateField {
    let _type: __d3ett.D3ETemplateType = D3ETemplate._types[type];

    /*
_type will have fields with index starting from _type.parentFields.
Anything less needs to be looked up in _type.parent.
*/

    if (val < _type.parentFields) {
      return D3ETemplate._getField(_type.parent, val);
    }

    /*
The field cannot be in _type's child, so subtract _type.parentField from val, and use that as index.
*/

    let adjustedIndex: number = val - _type.parentFields;

    return _type.fields[adjustedIndex];
  }
  public static fieldString(type: number, val: number): string {
    return D3ETemplate._getField(type, val).name;
  }
  public static fieldType(type: number, val: number): number {
    return D3ETemplate._getField(type, val).type;
  }
  public static isChild(type: number, val: number): boolean {
    return D3ETemplate._getField(type, val).child;
  }
  public static fieldInt(type: number, val: string): number {
    let _type: __d3ett.D3ETemplateType = D3ETemplate._types[type];

    if (_type.fieldMap.containsKey(val)) {
      return _type.fieldMap[val];
    }

    if (_type.parent != null) {
      return D3ETemplate.fieldInt(_type.parent, val);
    }

    return null;
  }
  public static isEmbedded(type: number): boolean {
    return D3ETemplate._types[type].embedded;
  }
  public static isAbstract(type: number): boolean {
    return D3ETemplate._types[type].abstract;
  }
  public static hasParent(type: number): boolean {
    return D3ETemplate._types[type].parent != null;
  }
  public static parent(type: number): number {
    return D3ETemplate._types[type].parent;
  }
  public static getEnumField<T>(type: number, field: number): T {
    switch (type) {
      case ADMINROLE: {
        return AdminRole.values[field] as unknown as T;
      }

      case CALENDARVIEWTYPE: {
        return CalendarViewType.values[field] as unknown as T;
      }

      case CONNECTIONSTATUS: {
        return ConnectionStatus.values[field] as unknown as T;
      }

      case GSDRMSFIELDTYPE: {
        return GSDRMSFieldType.values[field] as unknown as T;
      }

      case GRADIENTTYPE: {
        return GradientType.values[field] as unknown as T;
      }

      case ICONTYPE: {
        return IconType.values[field] as unknown as T;
      }

      case IMAGEFROM: {
        return ImageFrom.values[field] as unknown as T;
      }

      case MESSAGETYPE: {
        return MessageType.values[field] as unknown as T;
      }

      case REQUESTSTATUS: {
        return RequestStatus.values[field] as unknown as T;
      }

      case RESULTSTATUS: {
        return ResultStatus.values[field] as unknown as T;
      }
    }
  }
}

export class UsageConstants {
  public static readonly QUERY_CURRENTUSER_MAINHOMEPAGE_EVENTHANDLERS_ONINIT_BLOCK: number = 0;
  public static readonly QUERY_CURRENTUSER_MAINPAGE_EVENTHANDLERS_ONINIT_BLOCK: number = 1;
  public static readonly QUERY_GETEVENTLIST_EVENTCALENDARPAGE_PROPERTIES_RENTALS_COMPUTATION: number = 2;
  public static readonly QUERY_GETFACILITIESLIST_MAINPAGE_PROPERTIES_FACILITIES_COMPUTATION: number = 3;
  public static readonly QUERY_GETFACILITYBYID_BOOKINGPAGE_FACILITY_FETCHDATA: number = 4;
  public static readonly QUERY_GETFACILITYTYPELIST_MAINHOMEPAGE_PROPERTIES_FACILITYTYPES_COMPUTATION: number = 5;
  public static readonly QUERY_GETFACILITYTYPELIST_MAINPAGE_PROPERTIES_FACILITYTYPES_COMPUTATION: number = 6;
  public static readonly QUERY_GETFACILITYTYPELIST_RENTALSEARCHREQUESTVIEW_PROPERTIES_FACILITYTYPES_COMPUTATION: number = 7;
  public static readonly QUERY_GETMESSAGELIST_COMMENTHISTORYVIEW_PROPERTIES_QUERY_COMPUTATION: number = 8;
  public static readonly QUERY_GETPAYMENTHISTORY_PAYMENTDETAILSCARDVIEW_PROPERTIES_QUERY_COMPUTATION: number = 9;
  public static readonly QUERY_GETPAYMENTHISTORY_RENTALPAYMENTHISTORYPAGE_PROPERTIES_QUERY_COMPUTATION: number = 10;
  public static readonly QUERY_GETPENDINGPAYMENTS_RENTALCURRENTINVOICEPAGE_PROPERTIES_QUERY_COMPUTATION: number = 11;
  public static readonly QUERY_GETPENDINGPAYMENTS_RENTALPAYMENTSVIEW_PROPERTIES_QUERY_COMPUTATION: number = 12;
  public static readonly QUERY_GETPENDINGRENTALREQUESTS_RENTALPENDINGREQUESTSVIEW_PROPERTIES_SUBMITITEDLIST_COMPUTATION: number = 13;
  public static readonly QUERY_GETRENTALBOOKINGS_BOOKINGPAGE_PROPERTIES_ALLBOOKINGS_COMPUTATION: number = 14;
  public static readonly QUERY_GETRENTALFACILITIES_SEARCHPAGE_PROPERTIES_FACILITIESQUERY_COMPUTATION: number = 15;
  public static readonly QUERY_GETRENTALREQUESTBYID_INVOICEVIEW_REQUEST_FETCHDATA: number = 16;
  public static readonly QUERY_GETRENTALREQUESTBYID_RESERVATIONVIEW_REQUEST_FETCHDATA: number = 17;
  public static readonly QUERY_GETRENTALREQUESTS_RENTALRESERVATIONPAGE_PROPERTIES_RENTALLIST_COMPUTATION: number = 18;
  public static readonly QUERY_GETRENTALREQUESTS_RENTALUPCOMINGEVENTVIEW_PROPERTIES_UPCOMINGEVENTLIST_COMPUTATION: number = 19;
  public static readonly QUERY_GETRENTERBYID_BANNER_RENTER_FETCHDATA: number = 20;
  public static readonly QUERY_GETREQUESTDOCUMENTS_RENTALDOCUMENTPAGE_PROPERTIES_QUERY_COMPUTATION: number = 21;
  public static readonly QUERY_GETREQUESTINSURANCES_RENTALINSURANCEPAGE_PROPERTIES_QUERY_COMPUTATION: number = 22;
  public static readonly QUERY_GETSCHOOLBYID_EVENTCALENDARPAGE_SCHOOL_FETCHDATA: number = 23;
  public static readonly QUERY_GETSCHOOLSLIST_MAINHOMEPAGE_PROPERTIES_SCHOOLS_COMPUTATION: number = 24;
  public static readonly QUERY_GETSCHOOLSLIST_MAINPAGE_PROPERTIES_SCHOOLS_COMPUTATION: number = 25;
  public static readonly QUERY_GETSCHOOLSLIST_RENTALSEARCHREQUESTVIEW_PROPERTIES_SCHOOLS_COMPUTATION: number = 26;
  public static readonly QUERY_LOADRENTER_MAINHOMEPAGE_EVENTHANDLERS_ONINIT_BLOCK: number = 27;
  public static readonly QUERY_LOADRENTER_MAINPAGE_EVENTHANDLERS_ONINIT_BLOCK: number = 28;
  public static readonly QUERY_LOGINRENTERUSERWITHEMAILANDPASSWORD_RENTALLOGINPAGE_EVENTHANDLERS_AUTHENTICATINGUSER_BLOCK: number = 29;
  public static readonly QUERY_LOGINRENTERUSERWITHEMAILANDPASSWORD_RENTELSIGNUPPAGE_EVENTHANDLERS_DOAUTHENTICATE_BLOCK: number = 30;
  public static readonly QUERY_LOGINWITHOTP_RENTALFORGOTPASSWORDPAGE_EVENTHANDLERS_VERIFYOTP_BLOCK: number = 31;
  public static readonly SUBSCRIPTION_ONMESSAGELISTCHANGE_COMMENTHISTORYVIEW_QUERY_SYNCHRONISE: number = 32;
  public static readonly SUBSCRIPTION_ONPAYMENTHISTORYCHANGE_PAYMENTDETAILSCARDVIEW_QUERY_SYNCHRONISE: number = 33;
  public static readonly SUBSCRIPTION_ONPAYMENTHISTORYCHANGE_RENTALPAYMENTHISTORYPAGE_QUERY_SYNCHRONISE: number = 34;
  public static readonly SUBSCRIPTION_ONPENDINGPAYMENTSCHANGE_RENTALCURRENTINVOICEPAGE_QUERY_SYNCHRONISE: number = 35;
  public static readonly SUBSCRIPTION_ONPENDINGPAYMENTSCHANGE_RENTALPAYMENTSVIEW_QUERY_SYNCHRONISE: number = 36;
}

export class ChannelConstants {
  public static readonly TOTAL_CHANNEL_COUNT: number = 0;
  public static readonly channels: Array<__d3ett.D3ETemplateClass> = [];
}

export class RPCConstants {
  public static readonly UniqueChecker: number = 0;
  public static readonly UniqueCheckerCheckTokenUniqueInOneTimePassword: number = 0;
  public static readonly UniqueCheckerCheckRequestIdUniqueInRentalRequest: number = 1;
  public static readonly UniqueCheckerCheckEmailUniqueInRenter: number = 2;
  public static readonly UNIQUECHECKER_PROCEDURE_COUNT: number = 3;
  public static readonly FileService: number = 1;
  public static readonly FILESERVICE_PROCEDURE_COUNT: number = 0;
  public static readonly PaymentService: number = 2;
  public static readonly PaymentServiceCreateSessionLink: number = 0;
  public static readonly PAYMENTSERVICE_PROCEDURE_COUNT: number = 1;
  public static readonly TOTAL_RPC_CLASS_COUNT: number = 3;
  public static readonly classes: Array<__d3ett.D3ETemplateClass> = [
    new __d3ett.D3ETemplateClass(
      "UniqueChecker",
      "0be7e2a7da1517b5e1387b2c1afd7eb5",
      [
        new __d3ett.D3ETemplateMethodWithReturn(
          "checkTokenUniqueInOneTimePassword",
          [
            new __d3ett.D3ETemplateParam(
              D3ETemplate.typeInt("OneTimePassword"),
              {}
            ),
            new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {}),
          ],
          D3ETemplate.typeInt("Boolean"),
          {}
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "checkRequestIdUniqueInRentalRequest",
          [
            new __d3ett.D3ETemplateParam(
              D3ETemplate.typeInt("RentalRequest"),
              {}
            ),
            new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {}),
          ],
          D3ETemplate.typeInt("Boolean"),
          {}
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "checkEmailUniqueInRenter",
          [
            new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("Renter"), {}),
            new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {}),
          ],
          D3ETemplate.typeInt("Boolean"),
          {}
        ),
      ]
    ),
    new __d3ett.D3ETemplateClass(
      "FileService",
      "e256e0e9ad423eb20c85f506f4580ce8",
      []
    ),
    new __d3ett.D3ETemplateClass(
      "PaymentService",
      "51b18c05a1644fc29c4d6e251858f782",
      [
        new __d3ett.D3ETemplateMethodWithReturn(
          "createSessionLink",
          [
            new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {}),
            new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {}),
            new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("Double"), {}),
          ],
          D3ETemplate.typeInt("String"),
          {}
        ),
      ]
    ),
  ];
}
GlobalFunctions.typeInt = D3ETemplate.typeInt;

GlobalFunctions.types = D3ETemplate.types;
