import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import DateTimeView from "./DateTimeView";
import CellButton from "./CellButton";
import ListWrapper from "../utils/ListWrapper";
import RentalRequest from "../models/RentalRequest";
import RentalRequestsRequest from "../models/RentalRequestsRequest";
import TextView from "./TextView";
import PageNavigator from "../classes/PageNavigator";
import Renter from "../models/Renter";
import RequestStatus from "../classes/RequestStatus";
import CollectionUtils from "../utils/CollectionUtils";
import Query from "../classes/Query";
import SearchComponent from "./SearchComponent";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _ViewButtonOnPressed = (d3eState: _ItemState) => void;

export interface RentalReservationPageProps extends BaseUIProps {
  key?: string;
  renter: Renter;
}
/// To store state data for RentalReservationPage
class RentalReservationPageRefs {
  public itemState: Map<RentalRequest, _ItemState> = new Map();
  public forItem(item: RentalRequest): _ItemState {
    let res = this.itemState.get(item);

    if (res == null) {
      res = new _ItemState(this, item);

      this.itemState.set(item, res);
    }

    return res;
  }
}

interface ViewButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _ItemState;
  _onViewHandler?: _ViewButtonOnPressed;
  item: RentalRequest;
}

class ViewButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ViewButtonWithState extends ObservableComponent<ViewButtonWithStateProps> {
  viewButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ViewButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get item(): RentalRequest {
    return this.props.item;
  }
  public get viewButton(): ViewButtonState {
    return this.props.d3eState.viewButton;
  }
  public get d3eState(): _ItemState {
    return this.props.d3eState;
  }
  public get _onViewHandler(): _ViewButtonOnPressed {
    return this.props._onViewHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("viewButton", null, this.viewButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("item", this.props.item);

    this.on(
      [
        "item",
        "item.requestId",
        "viewButton",
        "viewButton.",
        "viewButton.hover",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ViewButtonWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.item !== this.props.item) {
      this.updateObservable("item", prevProps.item, this.props.item);

      this.fire("item", this);
    }
  }
  public viewButtonOnEnter(event): void {
    return this.viewButton.setHover(true);
  }
  public viewButtonOnExit(event): void {
    return this.viewButton.setHover(false);
  }
  public dispose(): void {
    this.viewButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return CellButton({
      padding: this.viewButton.hover
        ? cStyle.tCellButtonLinkCellButtonPaddingOnHover
        : cStyle.tCellButtonLinkCellButtonPaddingOn,
      disable: this.viewButton.disable,
      onPressed: () => {
        this._onViewHandler(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({
        data: this.item.requestId,
        style: new ui.TextStyle({ fontSize: 16, color: cStyle.c20 }),
        className: "xdf7 hc",
      }),
      onEnter: (event) => {
        this.viewButtonOnEnter(event);
      },
      onExit: (event) => {
        this.viewButtonOnExit(event);
      },
      className: "xf4b hc",
    });
  }
}
function ViewButtonWithState(props: ViewButtonWithStateProps) {
  return React.createElement(_ViewButtonWithState, props);
}

class _ItemState {
  parent: RentalReservationPageRefs;
  item: RentalRequest;
  viewButton: ViewButtonState = new ViewButtonState();
  public constructor(parent, item) {
    this.parent = parent;

    this.item = item;
  }
}

class _RentalReservationPageState extends ObservableComponent<RentalReservationPageProps> {
  static defaultProps = { renter: null };
  d3eState: RentalReservationPageRefs = new RentalReservationPageRefs();
  rentalList: Array<RentalRequest> = ListWrapper.widget(this, "rentalList");
  filteredList: Array<RentalRequest> = ListWrapper.widget(this, "filteredList");
  searchText: string = "";
  request: RentalRequest = null;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RentalReservationPageProps) {
    super(props);

    this.initState();
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("renter", this.props.renter);

    this.on(["renter"], this.computeRentalList);

    this.computeRentalList();

    this.on(
      [
        "rentalList",
        "rentalList.facility",
        "rentalList.facility.name",
        "searchText",
      ],
      this.computeFilteredList
    );

    this.computeFilteredList();

    this.on(
      [
        "filteredList",
        "filteredList.createdBy",
        "filteredList.createdBy.fullName",
        "filteredList.createdDate",
        "filteredList.eventDate",
        "filteredList.eventName",
        "filteredList.facility",
        "filteredList.facility.name",
        "filteredList.paid",
        "filteredList.status",
        "filteredList.total",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RentalReservationPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public setRentalList(val: Array<RentalRequest>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.rentalList,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("rentalList", this.rentalList, val);

    this.rentalList.clear();

    this.rentalList.addAll(val);

    this.fire("rentalList", this);
  }
  public addToRentalList(val: RentalRequest, index: number = -1): void {
    if (index === -1) {
      if (!this.rentalList.contains(val)) this.rentalList.add(val);
    } else {
      this.rentalList.remove(this.rentalList.elementAt(index));

      this.rentalList.add(val);
    }

    this.fire("rentalList", this, val, true);

    this.updateObservable("rentalList", null, val);
  }
  public removeFromRentalList(val: RentalRequest): void {
    this.rentalList.remove(val);

    this.fire("rentalList", this, val, false);

    this.removeObservable("rentalList", val);
  }
  public computeRentalList = async (): Promise<void> => {
    try {
      this.setRentalList(
        Array.from(
          (
            await Query.get().getRentalRequests(
              UsageConstants.QUERY_GETRENTALREQUESTS_RENTALRESERVATIONPAGE_PROPERTIES_RENTALLIST_COMPUTATION,
              new RentalRequestsRequest({
                renter: this.renter,
                applyStatus: false,
                pageSize: 100,
                offset: 0,
              })
            )
          )?.items ?? []
        )
      );
    } catch (exception) {
      console.log(" exception in computeRentalList : " + exception.toString());

      this.setRentalList([]);
    }
  };
  public setFilteredList(val: Array<RentalRequest>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.filteredList,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("filteredList", this.filteredList, val);

    this.filteredList.clear();

    this.filteredList.addAll(val);

    this.fire("filteredList", this);
  }
  public addToFilteredList(val: RentalRequest, index: number = -1): void {
    if (index === -1) {
      if (!this.filteredList.contains(val)) this.filteredList.add(val);
    } else {
      this.filteredList.remove(this.filteredList.elementAt(index));

      this.filteredList.add(val);
    }

    this.fire("filteredList", this, val, true);

    this.updateObservable("filteredList", null, val);
  }
  public removeFromFilteredList(val: RentalRequest): void {
    this.filteredList.remove(val);

    this.fire("filteredList", this, val, false);

    this.removeObservable("filteredList", val);
  }
  public computeFilteredList = (): void => {
    try {
      this.setFilteredList(
        Array.from(
          this.rentalList
            .where((t) =>
              t.facility.name
                .toLowerCase()
                .contains(this.searchText.toLowerCase())
            )
            .toList()
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeFilteredList : " + exception.toString()
      );

      this.setFilteredList([]);
    }
  };
  public setSearchText(val: string): void {
    let isValChanged: boolean = this.searchText !== val;

    if (!isValChanged) {
      return;
    }

    this.searchText = val;

    this.fire("searchText", this);
  }
  public setRequest(val: RentalRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        TextView({
          data: "Reservations",
          style: new ui.TextStyle({
            fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
            fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
          }),
          className: "hc",
          key: "0",
        }),
        ui.Container({
          height: 40,
          margin: ui.EdgeInsets.fromLTRB(0.0, 25.0, 0.0, 0.0, new Map()),
          width: 350,
          child: SearchComponent({
            placeholder: "Search...",
            onChanged: (value) => {
              this.searchComponent2Handler(value, this.d3eState);
            },
          }),
          key: "1",
          className: "x0df hc vc",
        }),
        ui.Table({
          defaultColumnWidth: new ui.FlexColumnWidth(1),
          columnWidths: { 0: new ui.FlexColumnWidth(1) },
          children: [
            ui.TableRow({
              children: [
                ui.TableCell({
                  verticalAlignment: ui.TableCellVerticalAlignment.middle,
                  child: TextView({
                    data: "Submit Date",
                    style: new ui.TextStyle({
                      fontFamily: cStyle.tTextViewTableHeaderFontFamilyOn,
                      color: cStyle.tTextViewTableHeaderColorOn,
                      fontWeight: cStyle.tTextViewTableHeaderFontWeightOn,
                    }),
                    className: "hc",
                  }),
                  className: "hc",
                  key: "0",
                }),
                ui.TableCell({
                  verticalAlignment: ui.TableCellVerticalAlignment.middle,
                  child: TextView({
                    data: "Res. ID",
                    style: new ui.TextStyle({
                      fontFamily: cStyle.tTextViewTableHeaderFontFamilyOn,
                      color: cStyle.tTextViewTableHeaderColorOn,
                      fontWeight: cStyle.tTextViewTableHeaderFontWeightOn,
                    }),
                    className: "hc",
                  }),
                  className: "hc",
                  key: "1",
                }),
                ui.TableCell({
                  verticalAlignment: ui.TableCellVerticalAlignment.middle,
                  child: TextView({
                    data: "Event Name",
                    style: new ui.TextStyle({
                      fontFamily: cStyle.tTextViewTableHeaderFontFamilyOn,
                      color: cStyle.tTextViewTableHeaderColorOn,
                      fontWeight: cStyle.tTextViewTableHeaderFontWeightOn,
                    }),
                    className: "hc",
                  }),
                  className: "hc",
                  key: "2",
                }),
                ui.TableCell({
                  verticalAlignment: ui.TableCellVerticalAlignment.middle,
                  child: TextView({
                    data: "Event Date",
                    style: new ui.TextStyle({
                      fontFamily: cStyle.tTextViewTableHeaderFontFamilyOn,
                      color: cStyle.tTextViewTableHeaderColorOn,
                      fontWeight: cStyle.tTextViewTableHeaderFontWeightOn,
                    }),
                    className: "hc",
                  }),
                  className: "hc",
                  key: "3",
                }),
                ui.TableCell({
                  verticalAlignment: ui.TableCellVerticalAlignment.middle,
                  child: TextView({
                    data: "Facility",
                    style: new ui.TextStyle({
                      fontFamily: cStyle.tTextViewTableHeaderFontFamilyOn,
                      color: cStyle.tTextViewTableHeaderColorOn,
                      fontWeight: cStyle.tTextViewTableHeaderFontWeightOn,
                    }),
                    className: "hc",
                  }),
                  className: "hc",
                  key: "4",
                }),
                ui.TableCell({
                  verticalAlignment: ui.TableCellVerticalAlignment.middle,
                  child: TextView({
                    data: "Status",
                    style: new ui.TextStyle({
                      fontFamily: cStyle.tTextViewTableHeaderFontFamilyOn,
                      color: cStyle.tTextViewTableHeaderColorOn,
                      fontWeight: cStyle.tTextViewTableHeaderFontWeightOn,
                    }),
                    className: "hc",
                  }),
                  className: "hc",
                  key: "5",
                }),
                ui.TableCell({
                  verticalAlignment: ui.TableCellVerticalAlignment.middle,
                  child: TextView({
                    data: "User",
                    style: new ui.TextStyle({
                      fontFamily: cStyle.tTextViewTableHeaderFontFamilyOn,
                      color: cStyle.tTextViewTableHeaderColorOn,
                      fontWeight: cStyle.tTextViewTableHeaderFontWeightOn,
                    }),
                    className: "hc",
                  }),
                  className: "hc",
                  key: "6",
                }),
                ui.TableCell({
                  verticalAlignment: ui.TableCellVerticalAlignment.middle,
                  child: TextView({
                    data: "Total",
                    style: new ui.TextStyle({
                      fontFamily: cStyle.tTextViewTableHeaderFontFamilyOn,
                      color: cStyle.tTextViewTableHeaderColorOn,
                      fontWeight: cStyle.tTextViewTableHeaderFontWeightOn,
                    }),
                    className: "hc",
                  }),
                  className: "hc",
                  key: "7",
                }),
                ui.TableCell({
                  verticalAlignment: ui.TableCellVerticalAlignment.middle,
                  child: TextView({
                    data: "Due",
                    style: new ui.TextStyle({
                      fontFamily: cStyle.tTextViewTableHeaderFontFamilyOn,
                      color: cStyle.tTextViewTableHeaderColorOn,
                      fontWeight: cStyle.tTextViewTableHeaderFontWeightOn,
                    }),
                    className: "hc",
                  }),
                  className: "hc",
                  key: "8",
                }),
              ],
              className: "x07b hc",
              key: "0",
            }),
            this.filteredList.expand((item) => [
              ui.TableRow({
                children: [
                  ui.TableCell({
                    verticalAlignment: ui.TableCellVerticalAlignment.middle,
                    child: DateTimeView({
                      value: item.createdDate,
                      format: "dd/MM/yyyy hh:mm a",
                      className: "hc",
                    }),
                    className: "hc",
                    key: "0",
                  }),
                  ui.TableCell({
                    verticalAlignment: ui.TableCellVerticalAlignment.middle,
                    child: ViewButtonWithState({
                      d3eState: this.d3eState.forItem(item),
                      _onViewHandler: this.onViewHandler,
                      item: item,
                    }),
                    className: "hc",
                    key: "1",
                  }),
                  ui.TableCell({
                    verticalAlignment: ui.TableCellVerticalAlignment.middle,
                    child: TextView({
                      data: item.eventName,
                      softWrap: true,
                      style: new ui.TextStyle({
                        fontFamily: cStyle.tTextViewTableCellTextFontFamilyOn,
                        color: cStyle.tTextViewTableCellTextColorOn,
                        fontSize: cStyle.tTextViewTableCellTextFontSizeOn,
                      }),
                      className: "hc",
                    }),
                    className: "hc",
                    key: "2",
                  }),
                  ui.TableCell({
                    verticalAlignment: ui.TableCellVerticalAlignment.middle,
                    child: DateTimeView({
                      value: item.eventDate.toDateTime(),
                      format: "dd/MM/yyyy",
                      className: "hc",
                    }),
                    className: "hc",
                    key: "3",
                  }),
                  ui.TableCell({
                    verticalAlignment: ui.TableCellVerticalAlignment.middle,
                    child: TextView({
                      data: item.facility.name,
                      style: new ui.TextStyle({
                        fontFamily: cStyle.tTextViewTableCellTextFontFamilyOn,
                        color: cStyle.tTextViewTableCellTextColorOn,
                        fontSize: cStyle.tTextViewTableCellTextFontSizeOn,
                      }),
                      className: "hc",
                    }),
                    className: "hc",
                    key: "4",
                  }),
                  ui.TableCell({
                    verticalAlignment: ui.TableCellVerticalAlignment.middle,
                    child: TextView({
                      data: item.status.name,
                      states: ui.joinStates(
                        {
                          "data-c0": item.status === RequestStatus.Submitted,
                          "data-c1":
                            item.status === RequestStatus.Approved ||
                            item.status === RequestStatus.SchoolApproved,
                          "data-c2":
                            item.status === RequestStatus.Rejected ||
                            item.status === RequestStatus.SchoolRejected,
                        },
                        {}
                      ),
                      style: new ui.TextStyle({
                        fontFamily: cStyle.tTextViewTableCellTextFontFamilyOn,
                        color:
                          item.status === RequestStatus.Submitted
                            ? new ui.Color(0xffdec150)
                            : item.status === RequestStatus.Approved ||
                              item.status === RequestStatus.SchoolApproved
                            ? new ui.Color(0xff008000)
                            : item.status === RequestStatus.Rejected ||
                              item.status === RequestStatus.SchoolRejected
                            ? new ui.Color(0xfff71306)
                            : cStyle.tTextViewTableCellTextColorOn,
                        fontSize: cStyle.tTextViewTableCellTextFontSizeOn,
                      }),
                      className: "x8f7 hc",
                    }),
                    className: "hc",
                    key: "5",
                  }),
                  ui.TableCell({
                    verticalAlignment: ui.TableCellVerticalAlignment.middle,
                    child: TextView({
                      data: item.createdBy.fullName,
                      style: new ui.TextStyle({
                        fontFamily: cStyle.tTextViewTableCellTextFontFamilyOn,
                        color: cStyle.tTextViewTableCellTextColorOn,
                        fontSize: cStyle.tTextViewTableCellTextFontSizeOn,
                      }),
                      className: "hc",
                    }),
                    className: "hc",
                    key: "6",
                  }),
                  ui.TableCell({
                    verticalAlignment: ui.TableCellVerticalAlignment.middle,
                    child: TextView({
                      data: "$" + item.total.toString(),
                      style: new ui.TextStyle({
                        fontFamily: cStyle.tTextViewTableCellTextFontFamilyOn,
                        color: cStyle.tTextViewTableCellTextColorOn,
                        fontSize: cStyle.tTextViewTableCellTextFontSizeOn,
                      }),
                      className: "hc",
                    }),
                    className: "hc",
                    key: "7",
                  }),
                  ui.TableCell({
                    verticalAlignment: ui.TableCellVerticalAlignment.middle,
                    child: TextView({
                      data: "$" + (item.total - item.paid).toString(),
                      style: new ui.TextStyle({
                        fontFamily: cStyle.tTextViewTableCellTextFontFamilyOn,
                        color: cStyle.tTextViewTableCellTextColorOn,
                        fontSize: cStyle.tTextViewTableCellTextFontSizeOn,
                      }),
                      className: "hc",
                    }),
                    className: "hc",
                    key: "8",
                  }),
                ],
                className: "x29f hc",
                key: item?.ident,
              }),
            ]),
          ],
          className: "x405 hc h",
          key: "2",
        }),
      ],
      className: ui.join(
        this.props.className,
        "RentalReservationPage xa1 hc vc"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onViewHandler = (d3eState: _ItemState): void => {
    this.setRequest(d3eState.item);

    this.navigator.pushRequestOverViewPage({
      admin: null,
      renter: this.renter,
      requestObj: this.request,
      target: "rRouter",
      replace: false,
    });
  };
  public searchComponent2Handler = (
    value: string,
    d3eState: RentalReservationPageRefs
  ): void => {
    this.setSearchText(value);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function RentalReservationPage(
  props: RentalReservationPageProps
) {
  return React.createElement(_RentalReservationPageState, {
    ..._RentalReservationPageState.defaultProps,
    ...props,
  });
}
