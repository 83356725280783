import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ToolTipWrapper from "./ToolTipWrapper";
import IconView from "./IconView";

type _RoundedIconButtonOnPressed = () => void;

export interface RoundedIconButtonProps extends BaseUIProps {
  key?: string;
  icon: ui.IconData;
  tooltip?: string;
  disableMode?: boolean;
  bgColor?: ui.Color;
  color?: ui.Color;
  hoverColor?: ui.Color;
  disabledColor?: ui.Color;
  size?: number;
  onPressed: _RoundedIconButtonOnPressed;
}

class _RoundedIconButtonState extends ObservableComponent<RoundedIconButtonProps> {
  static defaultProps = {
    icon: null,
    tooltip: "",
    disableMode: false,
    bgColor: null,
    color: null,
    hoverColor: null,
    disabledColor: null,
    size: 0.0,
    onPressed: null,
  };
  mousePointerOn: boolean = false;
  public constructor(props: RoundedIconButtonProps) {
    super(props);

    this.initState();
  }
  public get icon(): ui.IconData {
    return this.props.icon;
  }
  public get tooltip(): string {
    return this.props.tooltip;
  }
  public get disableMode(): boolean {
    return this.props.disableMode;
  }
  public get bgColor(): ui.Color {
    return this.props.bgColor;
  }
  public get color(): ui.Color {
    return this.props.color;
  }
  public get hoverColor(): ui.Color {
    return this.props.hoverColor;
  }
  public get disabledColor(): ui.Color {
    return this.props.disabledColor;
  }
  public get size(): number {
    return this.props.size;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "bgColor",
        "color",
        "disableMode",
        "disabledColor",
        "hoverColor",
        "icon",
        "mousePointerOn",
        "size",
        "tooltip",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RoundedIconButtonProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.icon !== this.props.icon) {
      this.fire("icon", this);
    }

    if (prevProps.tooltip !== this.props.tooltip) {
      this.fire("tooltip", this);
    }

    if (prevProps.disableMode !== this.props.disableMode) {
      this.fire("disableMode", this);
    }

    if (prevProps.bgColor !== this.props.bgColor) {
      this.fire("bgColor", this);
    }

    if (prevProps.color !== this.props.color) {
      this.fire("color", this);
    }

    if (prevProps.hoverColor !== this.props.hoverColor) {
      this.fire("hoverColor", this);
    }

    if (prevProps.disabledColor !== this.props.disabledColor) {
      this.fire("disabledColor", this);
    }

    if (prevProps.size !== this.props.size) {
      this.fire("size", this);
    }
  }
  public setMousePointerOn(val: boolean): void {
    let isValChanged: boolean = this.mousePointerOn !== val;

    if (!isValChanged) {
      return;
    }

    this.mousePointerOn = val;

    this.fire("mousePointerOn", this);
  }
  public render(): ReactNode {
    return ui.Container({
      alignment: ui.Alignment.center,
      child: ToolTipWrapper({
        message: this.tooltip,
        child: ui.Container({
          decoration: new ui.BoxDecoration({
            color: this.bgColor,
            shape: ui.BoxShape.circle,
          }),
          child: IconView({
            icon: this.icon,
            color:
              this.disableMode && this.disabledColor !== null
                ? this.disabledColor
                : this.mousePointerOn && this.hoverColor !== null
                ? this.hoverColor
                : this.color,
            size: this.size !== 0.0 ? this.size : 24.0,
            states: ui.joinStates(
              { "data-c0": this.disableMode && this.disabledColor === null },
              {}
            ),
            onEnter: (event) => {
              this.onEnterHandler(event);
            },
            onExit: (event) => {
              this.onExitHandler(event);
            },
            onTap: (e) => {
              e.stopPropagation();

              this.onPressedHandler();
            },
          }),
          onFocusKey: (focusNode, event) => {
            return this.onKey(focusNode, event);
          },
          className: "x515",
        }),
        className: "x454e",
      }),
      className: ui.join(this.props.className, "RoundedIconButton x11f"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onEnterHandler = (event: ui.PointerEnterEvent): void => {
    this.setMousePointerOn(true);
  };
  public onExitHandler = (event: ui.PointerExitEvent): void => {
    this.setMousePointerOn(false);
  };
  public onPressedHandler = (): void => {
    if (!this.disableMode && this.onPressed !== null) {
      this.onPressed();
    }
  };
  public onKey = (
    focusNode: ui.FocusNode,
    event: ui.RawKeyEvent
  ): ui.KeyEventResult => {
    if (event instanceof ui.RawKeyDownEvent && !this.disableMode) {
      if (
        event.logicalKey === ui.LogicalKeyboardKey.enter ||
        event.logicalKey === ui.LogicalKeyboardKey.space ||
        event.logicalKey === ui.LogicalKeyboardKey.numpadEnter
      ) {
        if (this.onPressed !== null) {
          this.onPressed();
        }

        return ui.KeyEventResult.handled;
      }
    }

    return ui.KeyEventResult.ignored;
  };
  public get onPressed(): _RoundedIconButtonOnPressed {
    return this.props.onPressed;
  }
}
export default function RoundedIconButton(props: RoundedIconButtonProps) {
  return React.createElement(_RoundedIconButtonState, {
    ..._RoundedIconButtonState.defaultProps,
    ...props,
  });
}
