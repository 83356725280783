import React from "react";
import { BlendMode } from "../classes/BlendMode";
import { BoxFit } from "../classes/BoxFit";
import { Color } from "../classes/Color";
import { FilterQuality } from "../classes/FilterQuality";
import { ImageFrom } from "../classes/ImageFrom";
import { ImageRepeat } from "../classes/ImageRepeat";
import { BaseComponentUtil } from "./BaseComponent";
import BaseUIProps, { processBaseUIProps } from "./BaseUIProps";

interface ImageViewProps extends BaseUIProps {
  imageType: ImageFrom;
  imageUrl: string;
  placeHolderUrl?: string;
  color?: Color;
  colorBlendMode?: BlendMode;
  fit?: BoxFit;
  repeat?: ImageRepeat; // default norepeat,
  filterQuality?: FilterQuality; // default low
}

class _ImageView extends React.Component<ImageViewProps, {}> {
  static defaultProps = {
    repeat: ImageRepeat.noRepeat,
    filterQuality: FilterQuality.low,
  };

  constructor(props: ImageViewProps) {
    super(props);
  }

  populateStyles(style: any) {
    BaseComponentUtil.applyBaseComponentProps(style, this.props);

    switch (this.props.fit) {
      case BoxFit.fill:
        Object.assign(style, {
          objectFit: "fill",
        });
        break;
      case BoxFit.contain:
        Object.assign(style, {
          objectFit: "contain",
        });
        break;

      case BoxFit.cover:
        Object.assign(style, {
          objectFit: "cover",
        });
        break;

      case BoxFit.none:
        Object.assign(style, {
          objectFit: "none",
        });
        break;
      case BoxFit.scaleDown:
        Object.assign(style, {
          objectFit: "scale-down",
        });
        break;
      case BoxFit.fitWidth:
        Object.assign(style, {
          objectFit: "scale-down",
          width: "100%",
          height: "auto",
        });
        break;
      case BoxFit.fitHeight:
        Object.assign(style, {
          objectFit: "scale-down",
          height: "100%",
          width: "auto",
        });
        break;
    }
    // TODO: repeat can only be done for background image in CSS. How do we do this?
    // TODO: Filter quality actually controls the quality of the image shown. Does Flutter actually do image processing?
  }

  render() {
    let style = {};
    this.populateStyles(style);
    return React.createElement("img", {
      src: this.props.imageUrl,
      placeholder: this.props.placeHolderUrl,
      class: this.props.className ? this.props.className : "",
      style,
      ...processBaseUIProps(this.props),
    });
  }
}

export default function ImageView(props: ImageViewProps) {
  return React.createElement(_ImageView, {
    ..._ImageView.defaultProps,
    ...props,
  });
}
