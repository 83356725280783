import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import PageRouter from "./PageRouter";
import RentalFooterView from "./RentalFooterView";
import PageNavigator from "../classes/PageNavigator";
import Duration from "../core/Duration";
import Timer from "../utils/Timer";
import RentalSideMenuView from "./RentalSideMenuView";
import Renter from "../models/Renter";
import RentalHeaderView from "./RentalHeaderView";
import { BuildContext } from "../classes/BuildContext";

export interface RentalHomePageProps extends BaseUIProps {
  key?: string;
  renter: Renter;
}

class _RentalHomePageState extends ObservableComponent<RentalHomePageProps> {
  static defaultProps = { renter: null };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RentalHomePageProps) {
    super(props);

    this.initState();
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("renter", this.props.renter);

    this.on(["renter"], this.rebuild);
  }
  public componentDidUpdate(prevProps: RentalHomePageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        RentalHeaderView({
          renter: this.renter,
          className: "xfec hc h",
          key: "0",
        }),
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.start,
          children: [
            ui.Container({
              width: 255,
              child: RentalSideMenuView({
                renter: this.renter,
                onSelectedMenu: (value) => {
                  this.rentalSideMenuViewHandler(value);
                },
              }),
              key: "0",
              className: "x130 hc vc v",
            }),
            ui.Column({
              children: [
                ui.Container({
                  child: PageRouter({
                    target: "rRouter",
                    className: "x115 hc vc h v",
                  }),
                  className: "xa513 hc vc h v",
                  key: "0",
                }),
                RentalFooterView({ className: "x9fb hc h", key: "1" }),
              ],
              className: "x2d3 hc vc h v",
              key: "1",
            }),
          ],
          className: "xa49 hc vc h v",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "RentalHomePage x717 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    new Timer(new Duration({ seconds: 1 }), () => {
      this.navigator.pushRentalDashboardPage({
        renter: this.renter,
        target: "rRouter",
        replace: true,
      });
    });
  };
  public rentalSideMenuViewHandler = (value: string): void => {
    /*
Your code here. 
*/

    switch (value) {
      case "Dashboard": {
        this.navigator.pushRentalDashboardPage({
          target: "rRouter",
          replace: false,
          renter: this.renter,
        });
        break;
      }

      case "Reservations": {
        this.navigator.pushRentalReservationPage({
          target: "rRouter",
          replace: false,
          renter: this.renter,
        });
        break;
      }

      case "Document": {
        this.navigator.pushRentalDocumentPage({
          target: "rRouter",
          replace: false,
          renter: this.renter,
        });
        break;
      }

      case "Insurance": {
        this.navigator.pushRentalInsurancePage({
          target: "rRouter",
          replace: false,
          renter: this.renter,
        });
        break;
      }

      case "Payment Method": {
        this.navigator.pushPaymentMethodsPage({
          target: "rRouter",
          replace: false,
          renter: this.renter,
        });
        break;
      }

      case "CurrentInvoice": {
        this.navigator.pushRentalCurrentInvoicePage({
          target: "rRouter",
          replace: false,
          renter: this.renter,
        });
        break;
      }

      case "Payment History": {
        this.navigator.pushRentalPaymentHistoryPage({
          target: "rRouter",
          replace: false,
          renter: this.renter,
        });
        break;
      }

      case "Calendar": {
        this.navigator.pushEventCalendarPage({
          school: null,
          renter: this.renter,
          target: "rRouter",
          replace: false,
        });
        break;
      }
      default: {
      }
    }
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function RentalHomePage(props: RentalHomePageProps) {
  return React.createElement(_RentalHomePageState, {
    ..._RentalHomePageState.defaultProps,
    ...props,
  });
}
