import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import DateTimeView from "./DateTimeView";
import ListWrapper from "../utils/ListWrapper";
import PaymentHistory from "../classes/PaymentHistory";
import RentalRequest from "../models/RentalRequest";
import PaymentReceipt from "../models/PaymentReceipt";
import TextView from "./TextView";
import PaymentHistoryRequest from "../models/PaymentHistoryRequest";
import LightDivider from "./LightDivider";
import CollectionUtils from "../utils/CollectionUtils";
import MessageDispatch from "../rocket/MessageDispatch";
import Query from "../classes/Query";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

export interface PaymentDetailsCardViewProps extends BaseUIProps {
  key?: string;
  requestObj: RentalRequest;
}

class _PaymentDetailsCardViewState extends ObservableComponent<PaymentDetailsCardViewProps> {
  static defaultProps = { requestObj: null };
  query: PaymentHistory = null;
  payments: Array<PaymentReceipt> = ListWrapper.widget(this, "payments");
  totalPaid: number = 0.0;
  request: PaymentHistoryRequest = null;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: PaymentDetailsCardViewProps) {
    super(props);

    this.initState();
  }
  public get requestObj(): RentalRequest {
    return this.props.requestObj;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("requestObj", this.props.requestObj);

    this.on(
      ["request", "request.renter", "request.request"],
      this.computeQuery
    );

    this.computeQuery();

    this.on(["query", "query.items"], this.computePayments);

    this.computePayments();

    this.on(["payments", "payments.amount"], this.computeTotalPaid);

    this.computeTotalPaid();

    this.on(
      [
        "payments",
        "payments.amount",
        "payments.createdDate",
        "requestObj",
        "requestObj.total",
        "totalPaid",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: PaymentDetailsCardViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.requestObj !== this.props.requestObj) {
      this.updateObservable(
        "requestObj",
        prevProps.requestObj,
        this.props.requestObj
      );

      this.fire("requestObj", this);
    }
  }
  public setQuery(val: PaymentHistory): void {
    let isValChanged: boolean = this.query !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("query", this.query, val);

    MessageDispatch.get().dispose(this.query);

    this.query = val;

    this.fire("query", this);
  }
  public computeQuery = async (): Promise<void> => {
    try {
      this.setQuery(
        await Query.get().getPaymentHistory(
          UsageConstants.QUERY_GETPAYMENTHISTORY_PAYMENTDETAILSCARDVIEW_PROPERTIES_QUERY_COMPUTATION,
          this.request,
          { "synchronize": true }
        )
      );
    } catch (exception) {
      console.log(" exception in computeQuery : " + exception.toString());

      this.setQuery(null);
    }
  };
  public setPayments(val: Array<PaymentReceipt>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.payments, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("payments", this.payments, val);

    this.payments.clear();

    this.payments.addAll(val);

    this.fire("payments", this);
  }
  public addToPayments(val: PaymentReceipt, index: number = -1): void {
    if (index === -1) {
      if (!this.payments.contains(val)) this.payments.add(val);
    } else {
      this.payments.remove(this.payments.elementAt(index));

      this.payments.add(val);
    }

    this.fire("payments", this, val, true);

    this.updateObservable("payments", null, val);
  }
  public removeFromPayments(val: PaymentReceipt): void {
    this.payments.remove(val);

    this.fire("payments", this, val, false);

    this.removeObservable("payments", val);
  }
  public computePayments = (): void => {
    try {
      this.setPayments(Array.from(this.query !== null ? this.query.items : []));
    } catch (exception) {
      console.log(" exception in computePayments : " + exception.toString());

      this.setPayments([]);
    }
  };
  public setTotalPaid(val: number): void {
    let isValChanged: boolean = this.totalPaid !== val;

    if (!isValChanged) {
      return;
    }

    this.totalPaid = val;

    this.fire("totalPaid", this);
  }
  public computeTotalPaid = (): void => {
    try {
      this.setTotalPaid(this.payments.fold(0.0, (p, e) => p + e.amount));
    } catch (exception) {
      console.log(" exception in computeTotalPaid : " + exception.toString());

      this.setTotalPaid(0.0);
    }
  };
  public setRequest(val: PaymentHistoryRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      mainAxisAlignment: ui.MainAxisAlignment.start,
      children: [
        ui.Row({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          mainAxisAlignment: ui.MainAxisAlignment.start,
          children: [
            ui.Wrap({
              spacing: 10,
              crossAxisAlignment: ui.WrapCrossAlignment.center,
              children: [
                TextView({
                  data: "Total: ",
                  style: new ui.TextStyle({
                    fontWeight: ui.FontWeight.bold,
                    color: cStyle.c19,
                  }),
                  className: "x56d",
                  key: "0",
                }),
                TextView({
                  data: "$" + this.requestObj.total.toString(),
                  style: new ui.TextStyle({
                    fontWeight: ui.FontWeight.bold,
                    color: cStyle.c19,
                  }),
                  className: "x147",
                  key: "1",
                }),
              ],
              key: "0",
            }),
          ],
          className: "x193 hc h",
          key: "0",
        }),
        this.payments.isNotEmpty
          ? ui.Column({
              mainAxisAlignment: ui.MainAxisAlignment.start,
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                this.payments.expand((item) => [
                  ui.Row({
                    mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                    crossAxisAlignment: ui.CrossAxisAlignment.start,
                    children: [
                      ui.Container({
                        margin: ui.EdgeInsets.symmetric({
                          horizontal: 20.0,
                          vertical: 0.0,
                          transitions: new Map(),
                        }),
                        child: DateTimeView({
                          value: item.createdDate,
                          format: "dd/MM/yyyy hh:mm a",
                        }),
                        key: "0",
                        className: "xe97",
                      }),
                      TextView({
                        data: "$" + item.amount.toString(),
                        style: new ui.TextStyle({
                          color: new ui.Color(0xff008000),
                        }),
                        className: "x50b0",
                        key: "1",
                      }),
                    ],
                    className: "xdb8 hc h",
                    key: item?.ident,
                  }),
                ]),
                LightDivider({
                  margin: ui.EdgeInsets.symmetric({
                    horizontal: 20.0,
                    vertical: 10.0,
                    transitions: new Map(),
                  }),
                  dividerColor: cStyle.c14,
                  className: "x553 hc h",
                  key: "1",
                }),
                ui.Row({
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  mainAxisAlignment: ui.MainAxisAlignment.end,
                  children: [
                    TextView({
                      data: "Total Paid: ",
                      style: new ui.TextStyle({
                        fontWeight: ui.FontWeight.bold,
                      }),
                      className: "x9f8",
                      key: "0",
                    }),
                    TextView({
                      data: "$" + this.totalPaid.toString(),
                      style: new ui.TextStyle({
                        fontWeight: ui.FontWeight.bold,
                      }),
                      className: "xb50",
                      key: "1",
                    }),
                  ],
                  className: "x9ce hc h",
                  key: "2",
                }),
                ui.Row({
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  mainAxisAlignment: ui.MainAxisAlignment.start,
                  children: [
                    ui.Wrap({
                      spacing: 10,
                      children: [
                        TextView({
                          data: "Balance: ",
                          style: new ui.TextStyle({
                            fontWeight: ui.FontWeight.bold,
                            color: new ui.Color(0xffe71306),
                          }),
                          className: "x834",
                          key: "0",
                        }),
                        TextView({
                          data:
                            "$" +
                            (this.requestObj.total - this.totalPaid).toString(),
                          style: new ui.TextStyle({
                            color: new ui.Color(0xffe71306),
                            fontWeight: ui.FontWeight.bold,
                          }),
                          className: "xdba",
                          key: "1",
                        }),
                      ],
                      key: "0",
                    }),
                  ],
                  className: "x632 hc h",
                  key: "3",
                }),
              ],
              className: "x630 hc vc h",
            })
          : [],
      ],
      className: ui.join(
        this.props.className,
        "PaymentDetailsCardView x9c4 hc vc h v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setRequest(new PaymentHistoryRequest({ request: this.requestObj }));
  };
  public dispose(): void {
    MessageDispatch.get().dispose(this.query);

    super.dispose();
  }
}
export default function PaymentDetailsCardView(
  props: PaymentDetailsCardViewProps
) {
  return React.createElement(_PaymentDetailsCardViewState, {
    ..._PaymentDetailsCardViewState.defaultProps,
    ...props,
  });
}
