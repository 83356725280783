import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import FileUploadResult from "../classes/FileUploadResult";
import ResultStatus from "../classes/ResultStatus";
import SuccessMessage from "../classes/SuccessMessage";
import FileToUpload from "../classes/FileToUpload";
import EventBus from "../utils/EventBus";
import RequestInsurance from "../models/RequestInsurance";
import PageNavigator from "../classes/PageNavigator";
import Result from "../classes/Result";
import PopupWrapperView from "./PopupWrapperView";
import Button from "./Button";
import DBSaveStatus from "../utils/DBSaveStatus";
import ListWrapper from "../utils/ListWrapper";
import PlatformClient from "../classes/PlatformClient";
import CheckServerErrors from "./CheckServerErrors";
import Browser from "../classes/Browser";
import TextView from "./TextView";
import FailureMessage from "../classes/FailureMessage";
import CollectionUtils from "../utils/CollectionUtils";
import { BuildContext } from "../classes/BuildContext";

type _UploadButtonOnPressed = (d3eState: AddorEditInsuranceViewRefs) => void;

type _CancelButtonOnPressed = (d3eState: AddorEditInsuranceViewRefs) => void;

type _SaveButtonOnPressed = (d3eState: AddorEditInsuranceViewRefs) => void;

export interface AddorEditInsuranceViewProps extends BaseUIProps {
  key?: string;
  obj: RequestInsurance;
}
/// To store state data for AddorEditInsuranceView
class AddorEditInsuranceViewRefs {
  public cancelButton: CancelButtonState = new CancelButtonState();
  public saveButton: SaveButtonState = new SaveButtonState();
  public uploadButton: UploadButtonState = new UploadButtonState();
}

interface SaveButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: AddorEditInsuranceViewRefs;
  _onPressedSaveButtonHandler?: _SaveButtonOnPressed;
}

class SaveButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _SaveButtonWithState extends ObservableComponent<SaveButtonWithStateProps> {
  saveButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SaveButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get saveButton(): SaveButtonState {
    return this.props.d3eState.saveButton;
  }
  public get d3eState(): AddorEditInsuranceViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedSaveButtonHandler(): _SaveButtonOnPressed {
    return this.props._onPressedSaveButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("saveButton", null, this.saveButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["saveButton", "saveButton.", "saveButton.hover"], this.rebuild);
  }
  public saveButtonOnEnter(event): void {
    return this.saveButton.setHover(true);
  }
  public saveButtonOnExit(event): void {
    return this.saveButton.setHover(false);
  }
  public dispose(): void {
    this.saveButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: 100,
      child: Button({
        padding: this.saveButton.hover
          ? cStyle.tButtonPrimaryNewPaddingOnHover
          : cStyle.tButtonPrimaryNewPaddingOn,
        decoration: this.saveButton.hover
          ? cStyle.tButtonPrimaryNewDecorationOnHover
          : cStyle.tButtonPrimaryNewDecorationOn,
        disable: this.saveButton.disable,
        onPressed: () => {
          this._onPressedSaveButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Save" }),
        onEnter: (event) => {
          this.saveButtonOnEnter(event);
        },
        onExit: (event) => {
          this.saveButtonOnExit(event);
        },
      }),
      className: "x7c hc",
    });
  }
}
function SaveButtonWithState(props: SaveButtonWithStateProps) {
  return React.createElement(_SaveButtonWithState, props);
}

interface CancelButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: AddorEditInsuranceViewRefs;
  _onPressedCancelButtonHandler?: _CancelButtonOnPressed;
}

class CancelButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _CancelButtonWithState extends ObservableComponent<CancelButtonWithStateProps> {
  cancelButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CancelButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get cancelButton(): CancelButtonState {
    return this.props.d3eState.cancelButton;
  }
  public get d3eState(): AddorEditInsuranceViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedCancelButtonHandler(): _CancelButtonOnPressed {
    return this.props._onPressedCancelButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("cancelButton", null, this.cancelButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["cancelButton", "cancelButton.", "cancelButton.hover"],
      this.rebuild
    );
  }
  public cancelButtonOnEnter(event): void {
    return this.cancelButton.setHover(true);
  }
  public cancelButtonOnExit(event): void {
    return this.cancelButton.setHover(false);
  }
  public dispose(): void {
    this.cancelButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 10.0, 0.0, new Map()),
      width: 100,
      child: Button({
        padding: this.cancelButton.hover
          ? cStyle.tButtonSecondaryNewPaddingOnHover
          : cStyle.tButtonSecondaryNewPaddingOn,
        decoration: this.cancelButton.hover
          ? cStyle.tButtonSecondaryNewDecorationOnHover
          : cStyle.tButtonSecondaryNewDecorationOn,
        disable: this.cancelButton.disable,
        onPressed: () => {
          this._onPressedCancelButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Cancel" }),
        onEnter: (event) => {
          this.cancelButtonOnEnter(event);
        },
        onExit: (event) => {
          this.cancelButtonOnExit(event);
        },
      }),
      className: "x05 hc",
    });
  }
}
function CancelButtonWithState(props: CancelButtonWithStateProps) {
  return React.createElement(_CancelButtonWithState, props);
}

interface UploadButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: AddorEditInsuranceViewRefs;
  _onPressedPhotographButtonHandler?: _UploadButtonOnPressed;
}

class UploadButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _UploadButtonWithState extends ObservableComponent<UploadButtonWithStateProps> {
  uploadButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: UploadButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get uploadButton(): UploadButtonState {
    return this.props.d3eState.uploadButton;
  }
  public get d3eState(): AddorEditInsuranceViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedPhotographButtonHandler(): _UploadButtonOnPressed {
    return this.props._onPressedPhotographButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("uploadButton", null, this.uploadButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["uploadButton", "uploadButton.", "uploadButton.hover"],
      this.rebuild
    );
  }
  public uploadButtonOnEnter(event): void {
    return this.uploadButton.setHover(true);
  }
  public uploadButtonOnExit(event): void {
    return this.uploadButton.setHover(false);
  }
  public dispose(): void {
    this.uploadButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.zero,
      child: Button({
        padding: ui.EdgeInsets.all(10.0, new Map()),
        decoration: this.uploadButton.hover
          ? cStyle.tButtonPrimaryNewDecorationOnHover
          : cStyle.tButtonPrimaryNewDecorationOn,
        disable: this.uploadButton.disable,
        onPressed: () => {
          this._onPressedPhotographButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({
          data: "Upload Document",
          style: new ui.TextStyle({
            color: new ui.Color(0xffffffff),
            fontFamily: "Open Sans",
            fontSize: 16,
          }),
          className: "x5c hc",
        }),
        onEnter: (event) => {
          this.uploadButtonOnEnter(event);
        },
        onExit: (event) => {
          this.uploadButtonOnExit(event);
        },
      }),
      className: "hc",
    });
  }
}
function UploadButtonWithState(props: UploadButtonWithStateProps) {
  return React.createElement(_UploadButtonWithState, props);
}

class _AddorEditInsuranceViewState extends ObservableComponent<AddorEditInsuranceViewProps> {
  static defaultProps = { obj: null };
  d3eState: AddorEditInsuranceViewRefs = new AddorEditInsuranceViewRefs();
  errors: Array<string> = ListWrapper.widget(this, "errors");
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: AddorEditInsuranceViewProps) {
    super(props);

    this.initState();
  }
  public get obj(): RequestInsurance {
    return this.props.obj;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("obj", this.props.obj);

    this.on(["errors", "obj", "obj.attachment"], this.rebuild);
  }
  public componentDidUpdate(prevProps: AddorEditInsuranceViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.obj !== this.props.obj) {
      this.updateObservable("obj", prevProps.obj, this.props.obj);

      this.fire("obj", this);
    }
  }
  public setErrors(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.errors, val);

    if (!isValChanged) {
      return;
    }

    this.errors.clear();

    this.errors.addAll(val);

    this.fire("errors", this);
  }
  public addToErrors(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.errors.contains(val)) this.errors.add(val);
    } else {
      this.errors.remove(this.errors.elementAt(index));

      this.errors.add(val);
    }

    this.fire("errors", this, val, true);
  }
  public removeFromErrors(val: string): void {
    this.errors.remove(val);

    this.fire("errors", this, val, false);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return PopupWrapperView({
      title:
        this.obj.saveStatus !== DBSaveStatus.New
          ? "Edit Insurance"
          : "Add Insurance",
      content: ui.Column({
        crossAxisAlignment: ui.CrossAxisAlignment.start,
        children: [
          UploadButtonWithState({
            d3eState: this.d3eState,
            _onPressedPhotographButtonHandler:
              this.onPressedPhotographButtonHandler,
            key: "0",
          }),
          this.obj.attachment !== null
            ? ui.Column({
                crossAxisAlignment: ui.CrossAxisAlignment.start,
                children: [
                  TextView({
                    data: this.obj.attachment.name,
                    softWrap: true,
                    className: "hc",
                    key: "0",
                  }),
                ],
                className: "hc",
              })
            : [],
        ],
        className: "hc",
      }),
      buttons: [
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          mainAxisAlignment: ui.MainAxisAlignment.start,
          children: [
            this.errors.isNotEmpty
              ? CheckServerErrors({ errors: this.errors })
              : [],
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.start,
              children: [
                CancelButtonWithState({
                  d3eState: this.d3eState,
                  _onPressedCancelButtonHandler:
                    this.onPressedCancelButtonHandler,
                  key: "0",
                }),
                SaveButtonWithState({
                  d3eState: this.d3eState,
                  _onPressedSaveButtonHandler: this.onPressedSaveButtonHandler,
                  key: "1",
                }),
              ],
              key: "1",
            }),
          ],
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "AddorEditInsuranceView hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressedPhotographButtonHandler = async (
    d3eState: AddorEditInsuranceViewRefs
  ): Promise<void> => {
    let fileToUpload: FileToUpload = await Browser.selectFile([], () => {});

    if (fileToUpload !== null) {
      let res: FileUploadResult = await PlatformClient.upload(fileToUpload);

      if (res.success) {
        this.obj.setAttachment(res.file);
      }
    }
  };
  public onPressedCancelButtonHandler = (
    d3eState: AddorEditInsuranceViewRefs
  ): void => {
    this.navigator.close();
  };
  public onPressedSaveButtonHandler = async (
    d3eState: AddorEditInsuranceViewRefs
  ): Promise<void> => {
    let res: Result<RequestInsurance> = await this.obj.save();

    if (res.status === ResultStatus.Success) {
      EventBus.get().fire(
        new SuccessMessage({ message: "Successfully Saved" })
      );

      this.navigator.close();
    } else {
      EventBus.get().fire(new FailureMessage({ message: "Failed to Save" }));

      this.setErrors(res.errors);
    }
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get cancelButton() {
    return this.d3eState.cancelButton;
  }
  public get saveButton() {
    return this.d3eState.saveButton;
  }
  public get uploadButton() {
    return this.d3eState.uploadButton;
  }
}
export default function AddorEditInsuranceView(
  props: AddorEditInsuranceViewProps
) {
  return React.createElement(_AddorEditInsuranceViewState, {
    ..._AddorEditInsuranceViewState.defaultProps,
    ...props,
  });
}
