import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import FacilityType from "../models/FacilityType";
import ListWrapper from "../utils/ListWrapper";
import TextView from "./TextView";
import Facility from "../models/Facility";
import CollectionUtils from "../utils/CollectionUtils";
import { BuildContext } from "../classes/BuildContext";

export interface GSDRMSCardViewProps extends BaseUIProps {
  key?: string;
  facilityType: FacilityType;
  facility: Array<Facility>;
  _facilityHash?: number;
}

class _GSDRMSCardViewState extends ObservableComponent<GSDRMSCardViewProps> {
  static defaultProps = { facilityType: null, facility: [] };
  facilityCount: Array<Facility> = ListWrapper.widget(this, "facilityCount");
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: GSDRMSCardViewProps) {
    super(props);

    this.initState();
  }
  public get facilityType(): FacilityType {
    return this.props.facilityType;
  }
  public get facility(): Array<Facility> {
    return this.props.facility;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("facilityType", this.props.facilityType);

    this.on(
      ["facility", "facility.type", "facilityType"],
      this.computeFacilityCount
    );

    this.computeFacilityCount();

    this.subscribeToList(this.facility, "facility");

    this.updateSyncCollProperty("facility", this.props.facility);

    this.on(
      [
        "facilityCount",
        "facilityType",
        "facilityType.image",
        "facilityType.name",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: GSDRMSCardViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.facilityType !== this.props.facilityType) {
      this.updateObservable(
        "facilityType",
        prevProps.facilityType,
        this.props.facilityType
      );

      this.fire("facilityType", this);
    }

    if (prevProps.facility !== this.props.facility) {
      this.updateObservableColl(
        "facility",
        prevProps.facility,
        this.props.facility
      );

      this.fire("facility", this);
    }
  }
  public setFacilityCount(val: Array<Facility>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.facilityCount,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("facilityCount", this.facilityCount, val);

    this.facilityCount.clear();

    this.facilityCount.addAll(val);

    this.fire("facilityCount", this);
  }
  public addToFacilityCount(val: Facility, index: number = -1): void {
    if (index === -1) {
      if (!this.facilityCount.contains(val)) this.facilityCount.add(val);
    } else {
      this.facilityCount.remove(this.facilityCount.elementAt(index));

      this.facilityCount.add(val);
    }

    this.fire("facilityCount", this, val, true);

    this.updateObservable("facilityCount", null, val);
  }
  public removeFromFacilityCount(val: Facility): void {
    this.facilityCount.remove(val);

    this.fire("facilityCount", this, val, false);

    this.removeObservable("facilityCount", val);
  }
  public computeFacilityCount = (): void => {
    try {
      this.setFacilityCount(
        Array.from(
          this.facility.where((e) => e.type === this.facilityType).toList()
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeFacilityCount : " + exception.toString()
      );

      this.setFacilityCount([]);
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.NetworkImage({
          url: this.facilityType.image?.downloadUrl ?? "",
          height: 380,
          width: 430,
          fit: ui.BoxFit.fill,
          className: "xfde hc vc",
          key: "0",
        }),
        ui.Wrap({
          crossAxisAlignment: ui.WrapCrossAlignment.center,
          children: [
            TextView({
              data: this.facilityType.name,
              style: new ui.TextStyle({
                fontSize: 26,
                fontWeight: ui.FontWeight.w700,
              }),
              className: "x482 hc",
              key: "0",
            }),
            TextView({
              data:
                "(" + this.facilityCount.length.toString() + ")" + "Properties",
              style: new ui.TextStyle({
                fontSize: 14,
                fontWeight: ui.FontWeight.w600,
              }),
              className: "x8ef hc",
              key: "1",
            }),
          ],
          className: "x9d3 hc",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "GSDRMSCardView x5f5 hc"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function GSDRMSCardView(props: GSDRMSCardViewProps) {
  return React.createElement(
    _GSDRMSCardViewState,
    { ..._GSDRMSCardViewState.defaultProps, ...props },
    ListWrapper.fromInput<Facility>(props.facility, "facility")
  );
}
