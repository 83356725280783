import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import FeaturesView from "./FeaturesView";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";

export interface FeaturesProps extends BaseUIProps {
  key?: string;
}

class _FeaturesState extends ObservableComponent<FeaturesProps> {
  public constructor(props: FeaturesProps) {
    super(props);

    this.initState();
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {}
  public render(): ReactNode {
    return ui.Column({
      children: [
        TextView({
          data: "Some premium features",
          style: new ui.TextStyle({
            fontSize: 35,
            fontWeight: ui.FontWeight.w600,
          }),
          className: "x7bf hc",
          key: "0",
        }),
        ui.Column({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                ui.Container({
                  margin: ui.EdgeInsets.fromLTRB(
                    0.0,
                    0.0,
                    20.0,
                    0.0,
                    new Map()
                  ),
                  child: FeaturesView({
                    icon: MaterialIcons.calendar_today,
                    title: "One calendar",
                    description:
                      "Schedule facility use and manage requests from the community all in one place.",
                  }),
                  key: "0",
                  className: "xbb",
                }),
                ui.Container({
                  margin: ui.EdgeInsets.zero,
                  child: FeaturesView({
                    icon: MaterialIcons.image,
                    title: "Showcase your facilities",
                    description:
                      "Display your facilities with custom rental websites, including state of the art drone and 360 degree photos.",
                  }),
                  key: "1",
                }),
              ],
              className: "xc68 hc h",
              key: "0",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                ui.Container({
                  margin: ui.EdgeInsets.fromLTRB(
                    0.0,
                    0.0,
                    20.0,
                    0.0,
                    new Map()
                  ),
                  child: FeaturesView({
                    icon: MaterialIcons.payments,
                    title: "Payments and insurance",
                    description:
                      "Our team handles insurance confirmation, payment processing and refunds.",
                  }),
                  key: "0",
                  className: "xc1f",
                }),
                FeaturesView({
                  icon: MaterialIcons.access_time,
                  title: "24/7 Customer support",
                  description:
                    "Our support team answers questions from community requesters 24 hours a day via phone, email, or live chat.",
                  key: "1",
                }),
              ],
              className: "xf2 hc h",
              key: "1",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                ui.Container({
                  margin: ui.EdgeInsets.fromLTRB(
                    0.0,
                    0.0,
                    20.0,
                    0.0,
                    new Map()
                  ),
                  child: FeaturesView({
                    icon: MaterialIcons.calendar_month,
                    title: "Schedule work and maintenance",
                    description:
                      "Schedule utilities, security, custodial, and more, in your fully integrated calendar platform.",
                  }),
                  key: "0",
                  className: "x90d",
                }),
                FeaturesView({
                  icon: MaterialIcons.handshake,
                  title: "Easy onboarding",
                  description:
                    "We handle account configuration, photos, current schedule ingestion, training, and more, at no cost.",
                  key: "1",
                }),
              ],
              className: "x066 hc h",
              key: "2",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                ui.Container({
                  margin: ui.EdgeInsets.fromLTRB(
                    0.0,
                    0.0,
                    20.0,
                    0.0,
                    new Map()
                  ),
                  child: FeaturesView({
                    icon: MaterialIcons.insert_chart_outlined,
                    title: "Business insights",
                    description:
                      "Update your policies and procedures with true usage and costs data.",
                  }),
                  key: "0",
                  className: "x50b",
                }),
                FeaturesView({
                  icon: MaterialIcons.thumb_up_alt,
                  title: "No direct cost",
                  description:
                    "Our services are fully sustained through service fees on external rental amounts with no hidden costs.",
                  key: "1",
                }),
              ],
              className: "xd6 hc h",
              key: "3",
            }),
          ],
          className: "x9c hc h",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "Features x2e hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function Features(props: FeaturesProps) {
  return React.createElement(_FeaturesState, props);
}
