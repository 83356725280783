import DFile from "./DFile";
import FileToUpload from "./FileToUpload";

export default class Browser {
  static async selectFile(
    extns: Array<string>,
    onCancel: () => void
  ): Promise<FileToUpload> {
    let contentType: string;
    if (extns != null) {
      extns = extns
        .map((e) => {
          if (!e.startsWith(".")) {
            e = "." + e;
          }
          return e;
        })
        .toList();
      contentType = extns.join(",");
    }
    let file = await Browser.loadFile(contentType, false);
    if (file == null) {
      return null;
    } else {
      return new FileToUpload(file);
    }
  }
  static async loadFile(contentType: string, multiple: boolean): Promise<File> {
    return new Promise<File>((resolve) => {
      let input = document.createElement("input");
      input.type = "file";
      input.multiple = multiple;
      input.accept = contentType;
      input.onchange = (_) => {
        let files = Array.from(input.files);
        if (multiple) resolve(files);
        else resolve(files[0]);
      };
      input.click();
    });
  }
  static download(_file: DFile) {
    let url: string = _file.downloadUrl + "?&originalName=" + _file.name;
    // eslint-disable-next-line no-restricted-globals
    open(url);
  }
  static sendMailTo(toEmail: string, subject?: string, body?: string): void {
    let email: string = toEmail;
    if (subject != null) {
      email += "?subject=" + encodeURIComponent(subject);
    }
    if (body != null) {
      if (subject == null) {
        email += "?";
      } else {
        email += "&";
      }
      email += "body=" + encodeURIComponent(body);
    }
    window.location.href = "mailto:" + email;
  }
  static open(url: string): void {
    window.open(url, "_blank");
  }
  static print(): void {
    window.print();
  }
}
