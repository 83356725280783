export default class MessageType {
  public static readonly History = new MessageType("History", "History", 0);
  public static readonly Comment = new MessageType("Comment", "Comment", 1);
  private _ident: string;
  private _name: string;
  private _index: number;
  public constructor(_ident: string, _name: string, _index: number) {
    this._ident = _ident;

    this._name = _name;

    this._index = _index;
  }
  public static get values(): MessageType[] {
    return [MessageType.History, MessageType.Comment];
  }
  public get ident(): string {
    return this._ident;
  }
  public get name(): string {
    return this._name;
  }
  public get index(): number {
    return this._index;
  }
  public toString(): string {
    return this._name;
  }
}
