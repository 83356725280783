import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CalendarViewType from "../classes/CalendarViewType";
import D3EDisposable from "../rocket/D3EDisposable";
import D3EDate from "../classes/D3EDate";
import RentalRequest from "../models/RentalRequest";
import School from "../models/School";
import Renter from "../models/Renter";
import EventListRequest from "../models/EventListRequest";
import Query from "../classes/Query";
import MessageDispatch from "../rocket/MessageDispatch";
import ListWrapper from "../utils/ListWrapper";
import CalendarEvent from "../classes/CalendarEvent";
import EventView from "./EventView";
import EventCalenderView from "./EventCalenderView";
import CalendarEventUtils from "../classes/CalendarEventUtils";
import CollectionUtils from "../utils/CollectionUtils";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

export interface EventCalendarPageProps extends BaseUIProps {
  key?: string;
  school: School;
  renter: Renter;
}

class _EventCalendarPageState extends ObservableComponent<EventCalendarPageProps> {
  static defaultProps = { school: null, renter: null };
  rentals: Array<RentalRequest> = ListWrapper.widget(this, "rentals");
  request: EventListRequest = null;
  schoolEvents: Array<CalendarEvent> = ListWrapper.widget(this, "schoolEvents");
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public schoolDisposable: D3EDisposable;
  public constructor(props: EventCalendarPageProps) {
    super(props);

    this.initState();
  }
  public get school(): School {
    return this.props.school;
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.runFetchDataQueryForSchool();

    this.schoolDisposable = MessageDispatch.get().syncObject(
      this.props.school,
      UsageConstants.QUERY_GETSCHOOLBYID_EVENTCALENDARPAGE_SCHOOL_FETCHDATA
    );

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("school", this.props.school);

    this.updateSyncProperty("renter", this.props.renter);

    this.on(
      ["request", "request.renter", "request.school"],
      this.computeRentals
    );

    this.computeRentals();

    this.on(
      [
        "rentals",
        "rentals.endTime",
        "rentals.eventDate",
        "rentals.eventName",
        "rentals.startTime",
      ],
      this.computeSchoolEvents
    );

    this.computeSchoolEvents();

    this.on(["schoolEvents"], this.rebuild);
  }
  public componentDidUpdate(prevProps: EventCalendarPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.school !== this.props.school) {
      this.updateObservable("school", prevProps.school, this.props.school);

      this.runFetchDataQueryForSchool();

      this.fire("school", this);
    }

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public runFetchDataQueryForSchool = (): void => {
    if (this.school == null) {
      return;
    }

    Query.get().getSchoolById(
      UsageConstants.QUERY_GETSCHOOLBYID_EVENTCALENDARPAGE_SCHOOL_FETCHDATA,
      this.school.id
    );
  };
  public setRentals(val: Array<RentalRequest>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.rentals, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("rentals", this.rentals, val);

    this.rentals.clear();

    this.rentals.addAll(val);

    this.fire("rentals", this);
  }
  public addToRentals(val: RentalRequest, index: number = -1): void {
    if (index === -1) {
      if (!this.rentals.contains(val)) this.rentals.add(val);
    } else {
      this.rentals.remove(this.rentals.elementAt(index));

      this.rentals.add(val);
    }

    this.fire("rentals", this, val, true);

    this.updateObservable("rentals", null, val);
  }
  public removeFromRentals(val: RentalRequest): void {
    this.rentals.remove(val);

    this.fire("rentals", this, val, false);

    this.removeObservable("rentals", val);
  }
  public computeRentals = async (): Promise<void> => {
    try {
      this.setRentals(
        Array.from(
          (
            await Query.get().getEventList(
              UsageConstants.QUERY_GETEVENTLIST_EVENTCALENDARPAGE_PROPERTIES_RENTALS_COMPUTATION,
              this.request
            )
          )?.items ?? []
        )
      );
    } catch (exception) {
      console.log(" exception in computeRentals : " + exception.toString());

      this.setRentals([]);
    }
  };
  public setRequest(val: EventListRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public setSchoolEvents(val: Array<CalendarEvent>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.schoolEvents,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("schoolEvents", this.schoolEvents, val);

    this.schoolEvents.clear();

    this.schoolEvents.addAll(val);

    this.fire("schoolEvents", this);
  }
  public addToSchoolEvents(val: CalendarEvent, index: number = -1): void {
    if (index === -1) {
      if (!this.schoolEvents.contains(val)) this.schoolEvents.add(val);
    } else {
      this.schoolEvents.remove(this.schoolEvents.elementAt(index));

      this.schoolEvents.add(val);
    }

    this.fire("schoolEvents", this, val, true);

    this.updateObservable("schoolEvents", null, val);
  }
  public removeFromSchoolEvents(val: CalendarEvent): void {
    this.schoolEvents.remove(val);

    this.fire("schoolEvents", this, val, false);

    this.removeObservable("schoolEvents", val);
  }
  public computeSchoolEvents = (): void => {
    try {
      this.setSchoolEvents(
        Array.from(CalendarEventUtils.getEventList(this.rentals))
      );
    } catch (exception) {
      console.log(
        " exception in computeSchoolEvents : " + exception.toString()
      );

      this.setSchoolEvents([]);
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Container({
          expand: true,
          padding: ui.EdgeInsets.fromLTRB(20.0, 10.0, 0.0, 10.0, new Map()),
          child: EventCalenderView({
            inputDate: D3EDate.now(),
            events: this.schoolEvents,
            builder: (context, event, viewType) => {
              return EventView({ event: event });
            },
          }),
          key: "0",
          className: "x37 hc vc h v",
        }),
      ],
      className: ui.join(this.props.className, "EventCalendarPage x07c hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setRequest(
      new EventListRequest({ school: this.school, renter: this.renter })
    );
  };
  public dispose(): void {
    this.schoolDisposable?.dispose();

    super.dispose();
  }
}
export default function EventCalendarPage(props: EventCalendarPageProps) {
  return React.createElement(_EventCalendarPageState, {
    ..._EventCalendarPageState.defaultProps,
    ...props,
  });
}
