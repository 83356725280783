import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import RequestInsurancesRequest from "../models/RequestInsurancesRequest";
import ResultStatus from "../classes/ResultStatus";
import DeleteWarningView from "./DeleteWarningView";
import SuccessMessage from "../classes/SuccessMessage";
import RequestInsurances from "../classes/RequestInsurances";
import Popup from "./Popup";
import EventBus from "../utils/EventBus";
import MaterialIcons from "../icons/MaterialIcons";
import RequestInsurance from "../models/RequestInsurance";
import Renter from "../models/Renter";
import Result from "../classes/Result";
import PaginationView from "./PaginationView";
import Query from "../classes/Query";
import SearchComponent from "./SearchComponent";
import DateTimeView from "./DateTimeView";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import TextView from "./TextView";
import FailureMessage from "../classes/FailureMessage";
import Checkbox from "./Checkbox";
import CollectionUtils from "../utils/CollectionUtils";
import IconView from "./IconView";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _DeleteButtonOnPressed = (d3eState: RentalInsurancePageRefs) => void;

export interface RentalInsurancePageProps extends BaseUIProps {
  key?: string;
  renter: Renter;
}
/// To store state data for RentalInsurancePage
class RentalInsurancePageRefs {
  public deleteButton: DeleteButtonState = new DeleteButtonState();
}

interface DeleteButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalInsurancePageRefs;
  _deleteButtonHandler?: _DeleteButtonOnPressed;
}

class DeleteButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _DeleteButtonWithState extends ObservableComponent<DeleteButtonWithStateProps> {
  deleteButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: DeleteButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get deleteButton(): DeleteButtonState {
    return this.props.d3eState.deleteButton;
  }
  public get d3eState(): RentalInsurancePageRefs {
    return this.props.d3eState;
  }
  public get _deleteButtonHandler(): _DeleteButtonOnPressed {
    return this.props._deleteButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("deleteButton", null, this.deleteButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["deleteButton"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      constraints: new ui.BoxConstraints({ minWidth: 80 }),
      child: Button({
        decoration: new ui.BoxDecoration({
          color: cStyle.c2,
          border: ui.Border.all({ color: new ui.Color(0xff0000), width: 0.0 }),
          borderRadius: ui.BorderRadius.circular(3.0),
        }),
        padding: ui.EdgeInsets.symmetric({
          horizontal: 12.0,
          vertical: 7.0,
          transitions: new Map(),
        }),
        disable: this.deleteButton.disable,
        onPressed: () => {
          this._deleteButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          children: [
            IconView({ icon: MaterialIcons.delete, key: "0" }),
            TextView({ data: "Delete Selected", key: "1" }),
          ],
        }),
      }),
      className: "xb9e",
    });
  }
}
function DeleteButtonWithState(props: DeleteButtonWithStateProps) {
  return React.createElement(_DeleteButtonWithState, props);
}

class _RentalInsurancePageState extends ObservableComponent<RentalInsurancePageProps> {
  static defaultProps = { renter: null };
  d3eState: RentalInsurancePageRefs = new RentalInsurancePageRefs();
  query: RequestInsurances = null;
  request: RequestInsurancesRequest = null;
  insurancesList: Array<RequestInsurance> = ListWrapper.widget(
    this,
    "insurancesList"
  );
  selectedItems: Array<RequestInsurance> = ListWrapper.widget(
    this,
    "selectedItems"
  );
  filteredList: Array<RequestInsurance> = ListWrapper.widget(
    this,
    "filteredList"
  );
  searchText: string = "";
  selectAll: boolean = false;
  deletePopupViewPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RentalInsurancePageProps) {
    super(props);

    this.initState();
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("renter", this.props.renter);

    this.on(
      [
        "request",
        "request.ascending",
        "request.offset",
        "request.orderBy",
        "request.pageSize",
        "request.renter",
      ],
      this.computeQuery
    );

    this.computeQuery();

    this.on(["query", "query.items"], this.computeInsurancesList);

    this.computeInsurancesList();

    this.on(
      [
        "insurancesList",
        "insurancesList.createdBy",
        "insurancesList.createdBy.fullName",
        "searchText",
      ],
      this.computeFilteredList
    );

    this.computeFilteredList();

    this.on(
      [
        "filteredList",
        "insurancesList",
        "insurancesList.attachment",
        "insurancesList.createdBy",
        "insurancesList.createdBy.fullName",
        "insurancesList.createdDate",
        "insurancesList.request",
        "insurancesList.request.eventDate",
        "insurancesList.request.eventName",
        "query",
        "query.count",
        "request",
        "request.offset",
        "request.pageSize",
        "selectAll",
        "selectedItems",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RentalInsurancePageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public setQuery(val: RequestInsurances): void {
    let isValChanged: boolean = this.query !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("query", this.query, val);

    this.query = val;

    this.fire("query", this);
  }
  public computeQuery = async (): Promise<void> => {
    try {
      this.setQuery(
        await Query.get().getRequestInsurances(
          UsageConstants.QUERY_GETREQUESTINSURANCES_RENTALINSURANCEPAGE_PROPERTIES_QUERY_COMPUTATION,
          this.request
        )
      );
    } catch (exception) {
      console.log(" exception in computeQuery : " + exception.toString());

      this.setQuery(null);
    }
  };
  public setRequest(val: RequestInsurancesRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public setInsurancesList(val: Array<RequestInsurance>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.insurancesList,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("insurancesList", this.insurancesList, val);

    this.insurancesList.clear();

    this.insurancesList.addAll(val);

    this.fire("insurancesList", this);
  }
  public addToInsurancesList(val: RequestInsurance, index: number = -1): void {
    if (index === -1) {
      if (!this.insurancesList.contains(val)) this.insurancesList.add(val);
    } else {
      this.insurancesList.remove(this.insurancesList.elementAt(index));

      this.insurancesList.add(val);
    }

    this.fire("insurancesList", this, val, true);

    this.updateObservable("insurancesList", null, val);
  }
  public removeFromInsurancesList(val: RequestInsurance): void {
    this.insurancesList.remove(val);

    this.fire("insurancesList", this, val, false);

    this.removeObservable("insurancesList", val);
  }
  public computeInsurancesList = (): void => {
    try {
      this.setInsurancesList(Array.from(this.query?.items ?? []));
    } catch (exception) {
      console.log(
        " exception in computeInsurancesList : " + exception.toString()
      );

      this.setInsurancesList([]);
    }
  };
  public setSelectedItems(val: Array<RequestInsurance>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.selectedItems,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("selectedItems", this.selectedItems, val);

    this.selectedItems.clear();

    this.selectedItems.addAll(val);

    this.fire("selectedItems", this);
  }
  public addToSelectedItems(val: RequestInsurance, index: number = -1): void {
    if (index === -1) {
      if (!this.selectedItems.contains(val)) this.selectedItems.add(val);
    } else {
      this.selectedItems.remove(this.selectedItems.elementAt(index));

      this.selectedItems.add(val);
    }

    this.fire("selectedItems", this, val, true);

    this.updateObservable("selectedItems", null, val);
  }
  public removeFromSelectedItems(val: RequestInsurance): void {
    this.selectedItems.remove(val);

    this.fire("selectedItems", this, val, false);

    this.removeObservable("selectedItems", val);
  }
  public setFilteredList(val: Array<RequestInsurance>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.filteredList,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("filteredList", this.filteredList, val);

    this.filteredList.clear();

    this.filteredList.addAll(val);

    this.fire("filteredList", this);
  }
  public addToFilteredList(val: RequestInsurance, index: number = -1): void {
    if (index === -1) {
      if (!this.filteredList.contains(val)) this.filteredList.add(val);
    } else {
      this.filteredList.remove(this.filteredList.elementAt(index));

      this.filteredList.add(val);
    }

    this.fire("filteredList", this, val, true);

    this.updateObservable("filteredList", null, val);
  }
  public removeFromFilteredList(val: RequestInsurance): void {
    this.filteredList.remove(val);

    this.fire("filteredList", this, val, false);

    this.removeObservable("filteredList", val);
  }
  public computeFilteredList = (): void => {
    try {
      this.setFilteredList(
        Array.from(
          this.insurancesList
            .where((t) =>
              t.createdBy.fullName
                .toLowerCase()
                .contains(this.searchText.toLowerCase())
            )
            .toList()
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeFilteredList : " + exception.toString()
      );

      this.setFilteredList([]);
    }
  };
  public setSearchText(val: string): void {
    let isValChanged: boolean = this.searchText !== val;

    if (!isValChanged) {
      return;
    }

    this.searchText = val;

    this.fire("searchText", this);
  }
  public setSelectAll(val: boolean): void {
    let isValChanged: boolean = this.selectAll !== val;

    if (!isValChanged) {
      return;
    }

    this.selectAll = val;

    this.fire("selectAll", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        TextView({
          data: "Insurance",
          style: new ui.TextStyle({
            fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
            fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
          }),
          className: "hc",
          key: "0",
        }),
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            ui.Row({
              children: [
                ui.Container({
                  height: 40,
                  margin: ui.EdgeInsets.fromLTRB(
                    0.0,
                    25.0,
                    0.0,
                    0.0,
                    new Map()
                  ),
                  width: 350,
                  child: SearchComponent({
                    placeholder: "Search...",
                    onChanged: (value) => {
                      this.onChangedSearchComponentHandler(
                        value,
                        this.d3eState
                      );
                    },
                  }),
                  key: "0",
                  className: "x881 hc vc",
                }),
              ],
              key: "0",
            }),
          ],
          className: "xb8d hc h",
          key: "1",
        }),
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.end,
          children: [
            ui.Row({
              children: [
                this.selectedItems.isNotEmpty
                  ? TextView({
                      data:
                        "(" +
                        this.selectedItems.length.toString() +
                        ")" +
                        "Types selected",
                      style: new ui.TextStyle({
                        fontSize: 14,
                        fontFamily: "Open Sans",
                      }),
                      className: "xabe",
                    })
                  : TextView({
                      data:
                        "Total" +
                        " " +
                        "(" +
                        this.filteredList.length.toString() +
                        ")",
                      style: new ui.TextStyle({
                        fontSize: 14,
                        fontFamily: "Open Sans",
                      }),
                      className: "xabe6",
                    }),
                this.selectedItems.isNotEmpty
                  ? DeleteButtonWithState({
                      d3eState: this.d3eState,
                      _deleteButtonHandler: this.deleteButtonHandler,
                    })
                  : [],
              ],
              className: "x172 hc h",
              key: "0",
            }),
            ui.Column({
              children: [
                ui.Table({
                  defaultColumnWidth: new ui.FlexColumnWidth(1),
                  columnWidths: { 0: new ui.FlexColumnWidth(1) },
                  children: [
                    ui.TableRow({
                      children: [
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: Checkbox({
                            value: this.selectAll,
                            onChanged: (val) => {
                              this.onChangedCheckbox2Handler(
                                val,
                                this.d3eState
                              );
                            },
                            className: "hc",
                          }),
                          className: "hc",
                          key: "0",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: "Created By",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewTableHeaderFontFamilyOn,
                              color: cStyle.tTextViewTableHeaderColorOn,
                              fontWeight:
                                cStyle.tTextViewTableHeaderFontWeightOn,
                            }),
                            className: "hc",
                          }),
                          className: "hc",
                          key: "1",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: "Event Name",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewTableHeaderFontFamilyOn,
                              color: cStyle.tTextViewTableHeaderColorOn,
                              fontWeight:
                                cStyle.tTextViewTableHeaderFontWeightOn,
                            }),
                            className: "hc",
                          }),
                          className: "hc",
                          key: "2",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: "Event Date",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewTableHeaderFontFamilyOn,
                              color: cStyle.tTextViewTableHeaderColorOn,
                              fontWeight:
                                cStyle.tTextViewTableHeaderFontWeightOn,
                            }),
                            className: "hc",
                          }),
                          className: "hc",
                          key: "3",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: "File Name",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewTableHeaderFontFamilyOn,
                              color: cStyle.tTextViewTableHeaderColorOn,
                              fontWeight:
                                cStyle.tTextViewTableHeaderFontWeightOn,
                            }),
                            className: "hc",
                          }),
                          className: "hc",
                          key: "4",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: "Created Date",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewTableHeaderFontFamilyOn,
                              color: cStyle.tTextViewTableHeaderColorOn,
                              fontWeight:
                                cStyle.tTextViewTableHeaderFontWeightOn,
                            }),
                            className: "hc",
                          }),
                          className: "hc",
                          key: "5",
                        }),
                      ],
                      className: "x325 hc",
                      key: "0",
                    }),
                    this.insurancesList.expand((item) => [
                      ui.TableRow({
                        children: [
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: Checkbox({
                              value: this.selectedItems.contains(item),
                              onChanged: (val) => {
                                this.onChangedCheckboxHandler(
                                  val,
                                  item,
                                  this.d3eState
                                );
                              },
                              className: "hc",
                            }),
                            className: "hc",
                            key: "0",
                          }),
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: TextView({
                              data: item.createdBy.fullName,
                              style: new ui.TextStyle({
                                fontFamily:
                                  cStyle.tTextViewTableCellTextFontFamilyOn,
                                color: cStyle.tTextViewTableCellTextColorOn,
                                fontSize:
                                  cStyle.tTextViewTableCellTextFontSizeOn,
                              }),
                              className: "hc",
                            }),
                            className: "hc",
                            key: "1",
                          }),
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: TextView({
                              data: item.request.eventName,
                              softWrap: true,
                              style: new ui.TextStyle({
                                fontFamily:
                                  cStyle.tTextViewTableCellTextFontFamilyOn,
                                color: cStyle.tTextViewTableCellTextColorOn,
                                fontSize:
                                  cStyle.tTextViewTableCellTextFontSizeOn,
                              }),
                              className: "hc",
                            }),
                            className: "hc",
                            key: "2",
                          }),
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: DateTimeView({
                              value: item.request.eventDate.toDateTime(),
                              className: "xf5e3 hc",
                            }),
                            className: "hc",
                            key: "3",
                          }),
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: TextView({
                              data: item.attachment.name,
                              style: new ui.TextStyle({
                                fontFamily:
                                  cStyle.tTextViewTableCellTextFontFamilyOn,
                                color: cStyle.tTextViewTableCellTextColorOn,
                                fontSize:
                                  cStyle.tTextViewTableCellTextFontSizeOn,
                              }),
                              className: "hc",
                            }),
                            className: "hc",
                            key: "4",
                          }),
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: DateTimeView({
                              value: item.createdDate,
                              className: "x1e1 hc",
                            }),
                            className: "hc",
                            key: "5",
                          }),
                        ],
                        className: "x764 hc",
                        key: item?.ident,
                      }),
                    ]),
                  ],
                  className: "xf9a hc h",
                  key: "0",
                }),
              ],
              className: "x51f hc h",
              key: "1",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.end,
              children: [
                ui.Container({
                  padding: ui.EdgeInsets.fromLTRB(
                    0.0,
                    0.0,
                    0.0,
                    20.0,
                    new Map()
                  ),
                  child: PaginationView({
                    pageSize: this.request.pageSize,
                    offset: this.request.offset,
                    total: this.query !== null ? this.query.count : 0,
                    onChange: (pageSize, offset) => {
                      this.onPaginationViewChanged(
                        pageSize,
                        offset,
                        this.d3eState
                      );
                    },
                  }),
                  key: "0",
                  className: "xd215",
                }),
              ],
              className: "x54c hc h",
              key: "2",
            }),
          ],
          className: "x5b7 hc h",
          key: "2",
        }),
      ],
      className: ui.join(
        this.props.className,
        "RentalInsurancePage x3a5 hc vc"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setRequest(
      new RequestInsurancesRequest({
        pageSize: 100,
        offset: 0,
        renter: this.renter,
        orderBy: "",
        ascending: true,
      })
    );
  };
  public onChangedCheckbox2Handler = (
    val: boolean,
    d3eState: RentalInsurancePageRefs
  ): void => {
    // POS: 150.00, Y = 80.00

    if (val) {
      // POS: 250.00, Y = 30.00

      this.setSelectedItems(this.filteredList);
    } else {
      // POS: 250.00, Y = 130.00

      this.setSelectedItems([]);
    }

    // POS: 350.00, Y = 80.00

    this.setSelectAll(val);
  };
  public onChangedCheckboxHandler = (
    val: boolean,
    item: RequestInsurance,
    d3eState: RentalInsurancePageRefs
  ): void => {
    if (val) {
      this.selectedItems.add(item);
    } else {
      this.selectedItems.remove(item);
    }
  };
  public onDeleteWarningViewHanlder = async (): Promise<void> => {
    for (let ri of this.selectedItems) {
      let res: Result<RequestInsurance> = await ri.delete();

      if (res.status === ResultStatus.Success) {
        EventBus.get().fire(
          new SuccessMessage({ message: "Successfully Deleted" })
        );
      } else {
        EventBus.get().fire(
          new FailureMessage({
            message: "Can not deleted selected Insurance  as it is being used",
          })
        );

        break;
      }
    }

    this.setSelectedItems([]);

    this.setSelectAll(false);
  };
  public deleteButtonHandler = (d3eState: RentalInsurancePageRefs): void => {
    this.showDeletePopupView();
  };
  public onPaginationViewChanged = (
    pageSize: number,
    offset: number,
    d3eState: RentalInsurancePageRefs
  ): void => {
    this.request.setPageSize(pageSize);

    this.request.setOffset(offset);

    this.setRequest(this.request.deepClone());
  };
  public onChangedSearchComponentHandler = (
    value: string,
    d3eState: RentalInsurancePageRefs
  ): void => {
    this.setSearchText(value);
  };
  public dispose(): void {
    this.deletePopupViewPopup?.dispose();

    super.dispose();
  }
  public showDeletePopupView(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.deletePopupViewPopup?.dispose();

    this.deletePopupViewPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        width: 600,
        child: DeleteWarningView({
          onDelete: () => {
            this.onDeleteWarningViewHanlder();
          },
        }),
        className: "x883 hc vc",
      }),
    });

    this.deletePopupViewPopup.showPopup(this.context);
  }
  public hideDeletePopupView(): void {
    this.deletePopupViewPopup?.dispose();
  }
  public get deleteButton() {
    return this.d3eState.deleteButton;
  }
}
export default function RentalInsurancePage(props: RentalInsurancePageProps) {
  return React.createElement(_RentalInsurancePageState, {
    ..._RentalInsurancePageState.defaultProps,
    ...props,
  });
}
