import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";
import RentalRequest from "./RentalRequest";

export default class MessageListRequest extends DBObject {
  private static readonly _ASCENDING: number = 0;
  private static readonly _NEEDCOMMENT: number = 1;
  private static readonly _NEEDHISTORY: number = 2;
  private static readonly _ORDERBY: number = 3;
  private static readonly _REQUEST: number = 4;
  public id: number = 0;
  public otherMaster: DBObject;
  private _request: RentalRequest = null;
  private _needHistory: boolean = false;
  private _needComment: boolean = false;
  private _orderBy: string = "";
  private _ascending: boolean = false;
  public constructor(
    d3eParams?: Partial<{
      ascending: boolean;
      needComment: boolean;
      needHistory: boolean;
      orderBy: string;
      request: RentalRequest;
    }>
  ) {
    super();

    this.setAscending(d3eParams?.ascending ?? false);

    this.setNeedComment(d3eParams?.needComment ?? false);

    this.setNeedHistory(d3eParams?.needHistory ?? false);

    this.setOrderBy(d3eParams?.orderBy ?? "");

    this.setRequest(d3eParams?.request ?? null);
  }
  public get d3eType(): string {
    return "MessageListRequest";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isTransientModel(): boolean {
    return;
  }
  public get request(): RentalRequest {
    return this._request;
  }
  public setRequest(val: RentalRequest): void {
    let isValChanged: boolean = this._request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MessageListRequest._REQUEST, this._request);

    this.updateObservable("request", this._request, val);

    this._request = val;

    this.fire("request", this);
  }
  public get needHistory(): boolean {
    return this._needHistory;
  }
  public setNeedHistory(val: boolean): void {
    let isValChanged: boolean = this._needHistory !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MessageListRequest._NEEDHISTORY, this._needHistory);

    this._needHistory = val;

    this.fire("needHistory", this);
  }
  public get needComment(): boolean {
    return this._needComment;
  }
  public setNeedComment(val: boolean): void {
    let isValChanged: boolean = this._needComment !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MessageListRequest._NEEDCOMMENT, this._needComment);

    this._needComment = val;

    this.fire("needComment", this);
  }
  public get orderBy(): string {
    return this._orderBy;
  }
  public setOrderBy(val: string): void {
    let isValChanged: boolean = this._orderBy !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MessageListRequest._ORDERBY, this._orderBy);

    this._orderBy = val;

    this.fire("orderBy", this);
  }
  public get ascending(): boolean {
    return this._ascending;
  }
  public setAscending(val: boolean): void {
    let isValChanged: boolean = this._ascending !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MessageListRequest._ASCENDING, this._ascending);

    this._ascending = val;

    this.fire("ascending", this);
  }
  public get(field: number): any {
    switch (field) {
      case MessageListRequest._REQUEST: {
        return this._request;
      }

      case MessageListRequest._NEEDHISTORY: {
        return this._needHistory;
      }

      case MessageListRequest._NEEDCOMMENT: {
        return this._needComment;
      }

      case MessageListRequest._ORDERBY: {
        return this._orderBy;
      }

      case MessageListRequest._ASCENDING: {
        return this._ascending;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): MessageListRequest {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: MessageListRequest = dbObj as MessageListRequest;

    obj.id = this.id;

    obj.setRequest(this._request);

    obj.setNeedHistory(this._needHistory);

    obj.setNeedComment(this._needComment);

    obj.setOrderBy(this._orderBy);

    obj.setAscending(this._ascending);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case MessageListRequest._REQUEST: {
        this.setRequest(value as RentalRequest);
        break;
      }

      case MessageListRequest._NEEDHISTORY: {
        this.setNeedHistory(value as boolean);
        break;
      }

      case MessageListRequest._NEEDCOMMENT: {
        this.setNeedComment(value as boolean);
        break;
      }

      case MessageListRequest._ORDERBY: {
        this.setOrderBy(value as string);
        break;
      }

      case MessageListRequest._ASCENDING: {
        this.setAscending(value as boolean);
        break;
      }
    }
  }
}
