import * as ui from "..";
import Timer from "../../utils/Timer";
import Duration from "../../core/Duration";

type LayoutCallback = (bounds: ui.Rect, globalPos: ui.Offset) => void;

function prepareBox(e: HTMLElement): ui.RenderBox {
  if (!e) {
    return null;
  }
  let ref = e;
  let rect = e.getBoundingClientRect();
  return new ui.RenderBox({
    size: new ui.Size({ width: rect.width, height: rect.height }),
    position: new ui.Offset({ dx: rect.left, dy: rect.top }),
  });
}
export default function LayoutAware(
  onBoundsChange: LayoutCallback,
  d3eRef?: (e: HTMLElement) => void
) {
  let bounds: ui.Rect;
  return (e: HTMLElement) => {
    if (d3eRef) {
      d3eRef(e);
    }
    if (onBoundsChange != null) {
      let _timer = new Timer(new Duration({ milliseconds: 20 }), () => {
        if (_timer == null) {
          return;
        }
        let box: ui.RenderBox = prepareBox(e);
        if (box == null) {
          return;
        }
        let size = box.size;
        let rect = ui.Rect.fromLTWH(0, 0, size.width, size.height);
        if (!bounds?.isSame(rect)) {
          bounds = rect;
          onBoundsChange(rect, ui.Offset.zero);
        }
      });
    }
  };
}
