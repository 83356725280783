import BaseUser from "./BaseUser";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class Renter extends BaseUser {
  private static readonly _ACTIVE: number = 0;
  private static readonly _EMAIL: number = 1;
  private static readonly _FULLNAME: number = 2;
  private _fullName: string = "";
  private _email: string = "";
  private _active: boolean = false;
  public constructor(
    d3eParams?: Partial<{ active: boolean; email: string; fullName: string }>
  ) {
    super();

    this.setActive(d3eParams?.active ?? false);

    this.setEmail(d3eParams?.email ?? "");

    this.setFullName(d3eParams?.fullName ?? "");
  }
  public get d3eType(): string {
    return "Renter";
  }
  public get fullName(): string {
    return this._fullName;
  }
  public setFullName(val: string): void {
    let isValChanged: boolean = this._fullName !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Renter._FULLNAME, this._fullName);

    this._fullName = val;

    this.fire("fullName", this);
  }
  public get email(): string {
    return this._email;
  }
  public setEmail(val: string): void {
    let isValChanged: boolean = this._email !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Renter._EMAIL, this._email);

    this._email = val;

    this.fire("email", this);
  }
  public get active(): boolean {
    return this._active;
  }
  public setActive(val: boolean): void {
    let isValChanged: boolean = this._active !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Renter._ACTIVE, this._active);

    this._active = val;

    this.fire("active", this);
  }
  public get(field: number): any {
    switch (field) {
      case Renter._FULLNAME: {
        return this._fullName;
      }

      case Renter._EMAIL: {
        return this._email;
      }

      case Renter._ACTIVE: {
        return this._active;
      }
      default: {
        return super.get(field);
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): Renter {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {
    super.collectChildValues(ctx);
  }
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    super.deepCloneIntoObj(dbObj, ctx);

    let obj: Renter = dbObj as Renter;

    obj.setFullName(this._fullName);

    obj.setEmail(this._email);

    obj.setActive(this._active);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case Renter._FULLNAME: {
        this.setFullName(value as string);
        break;
      }

      case Renter._EMAIL: {
        this.setEmail(value as string);
        break;
      }

      case Renter._ACTIVE: {
        this.setActive(value as boolean);
        break;
      }
      default: {
        super.set(field, value);
      }
    }
  }
}
