import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ListWrapper from "../utils/ListWrapper";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

export interface CheckServerErrorsProps extends BaseUIProps {
  key?: string;
  errors: Array<string>;
  _errorsHash?: number;
}

class _CheckServerErrorsState extends ObservableComponent<CheckServerErrorsProps> {
  static defaultProps = { errors: [] };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CheckServerErrorsProps) {
    super(props);

    this.initState();
  }
  public get errors(): Array<string> {
    return this.props.errors;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.errors, "errors");

    this.on(["errors"], this.rebuild);
  }
  public componentDidUpdate(prevProps: CheckServerErrorsProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.errors !== this.props.errors) {
      this.fire("errors", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        this.errors.expand((error) => [
          TextView({
            data: error,
            style: new ui.TextStyle({
              fontFamily: cStyle.tTextViewErrorLabelFontFamilyOn,
              color: cStyle.tTextViewErrorLabelColorOn,
              fontSize: cStyle.tTextViewErrorLabelFontSizeOn,
            }),
            key: error?.toString(),
          }),
        ]),
      ],
      className: ui.join(this.props.className, "CheckServerErrors"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function CheckServerErrors(props: CheckServerErrorsProps) {
  return React.createElement(
    _CheckServerErrorsState,
    { ..._CheckServerErrorsState.defaultProps, ...props },
    ListWrapper.fromInput<string>(props.errors, "errors")
  );
}
