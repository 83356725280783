export default class RequestStatus {
  public static readonly Submitted = new RequestStatus(
    "Submitted",
    "Submitted",
    0
  );
  public static readonly SchoolRejected = new RequestStatus(
    "SchoolRejected",
    "SchoolRejected",
    1
  );
  public static readonly SchoolApproved = new RequestStatus(
    "SchoolApproved",
    "SchoolApproved",
    2
  );
  public static readonly Rejected = new RequestStatus(
    "Rejected",
    "Rejected",
    3
  );
  public static readonly Approved = new RequestStatus(
    "Approved",
    "Approved",
    4
  );
  private _ident: string;
  private _name: string;
  private _index: number;
  public constructor(_ident: string, _name: string, _index: number) {
    this._ident = _ident;

    this._name = _name;

    this._index = _index;
  }
  public static get values(): RequestStatus[] {
    return [
      RequestStatus.Submitted,
      RequestStatus.SchoolRejected,
      RequestStatus.SchoolApproved,
      RequestStatus.Rejected,
      RequestStatus.Approved,
    ];
  }
  public get ident(): string {
    return this._ident;
  }
  public get name(): string {
    return this._name;
  }
  public get index(): number {
    return this._index;
  }
  public toString(): string {
    return this._name;
  }
}
