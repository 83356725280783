import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import TextView from "./TextView";
import PageNavigator from "../classes/PageNavigator";
import PopupWrapperView from "./PopupWrapperView";
import { BuildContext } from "../classes/BuildContext";

type _DeleteWarningViewOnDelete = () => void;

type _NoButtonOnPressed = (d3eState: DeleteWarningViewRefs) => void;

type _YesButtonOnPressed = (d3eState: DeleteWarningViewRefs) => void;

export interface DeleteWarningViewProps extends BaseUIProps {
  key?: string;
  onDelete: _DeleteWarningViewOnDelete;
}
/// To store state data for DeleteWarningView
class DeleteWarningViewRefs {
  public noButton: NoButtonState = new NoButtonState();
  public yesButton: YesButtonState = new YesButtonState();
}

interface YesButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: DeleteWarningViewRefs;
  _yesButtonHandler?: _YesButtonOnPressed;
}

class YesButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _YesButtonWithState extends ObservableComponent<YesButtonWithStateProps> {
  yesButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: YesButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get yesButton(): YesButtonState {
    return this.props.d3eState.yesButton;
  }
  public get d3eState(): DeleteWarningViewRefs {
    return this.props.d3eState;
  }
  public get _yesButtonHandler(): _YesButtonOnPressed {
    return this.props._yesButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("yesButton", null, this.yesButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["yesButton", "yesButton.", "yesButton.hover"], this.rebuild);
  }
  public yesButtonOnEnter(event): void {
    return this.yesButton.setHover(true);
  }
  public yesButtonOnExit(event): void {
    return this.yesButton.setHover(false);
  }
  public dispose(): void {
    this.yesButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: 100,
      child: Button({
        padding: this.yesButton.hover
          ? cStyle.tButtonPrimaryNewPaddingOnHover
          : cStyle.tButtonPrimaryNewPaddingOn,
        decoration: this.yesButton.hover
          ? cStyle.tButtonPrimaryNewDecorationOnHover
          : cStyle.tButtonPrimaryNewDecorationOn,
        disable: this.yesButton.disable,
        onPressed: () => {
          this._yesButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Yes" }),
        onEnter: (event) => {
          this.yesButtonOnEnter(event);
        },
        onExit: (event) => {
          this.yesButtonOnExit(event);
        },
      }),
      className: "xad hc",
    });
  }
}
function YesButtonWithState(props: YesButtonWithStateProps) {
  return React.createElement(_YesButtonWithState, props);
}

interface NoButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: DeleteWarningViewRefs;
  _noButtonHandler?: _NoButtonOnPressed;
}

class NoButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _NoButtonWithState extends ObservableComponent<NoButtonWithStateProps> {
  noButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: NoButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get noButton(): NoButtonState {
    return this.props.d3eState.noButton;
  }
  public get d3eState(): DeleteWarningViewRefs {
    return this.props.d3eState;
  }
  public get _noButtonHandler(): _NoButtonOnPressed {
    return this.props._noButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("noButton", null, this.noButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["noButton", "noButton.", "noButton.hover"], this.rebuild);
  }
  public noButtonOnEnter(event): void {
    return this.noButton.setHover(true);
  }
  public noButtonOnExit(event): void {
    return this.noButton.setHover(false);
  }
  public dispose(): void {
    this.noButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 10.0, 0.0, new Map()),
      width: 100,
      child: Button({
        padding: this.noButton.hover
          ? cStyle.tButtonSecondaryNewPaddingOnHover
          : cStyle.tButtonSecondaryNewPaddingOn,
        decoration: this.noButton.hover
          ? cStyle.tButtonSecondaryNewDecorationOnHover
          : cStyle.tButtonSecondaryNewDecorationOn,
        disable: this.noButton.disable,
        onPressed: () => {
          this._noButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "No" }),
        onEnter: (event) => {
          this.noButtonOnEnter(event);
        },
        onExit: (event) => {
          this.noButtonOnExit(event);
        },
      }),
      className: "x10 hc",
    });
  }
}
function NoButtonWithState(props: NoButtonWithStateProps) {
  return React.createElement(_NoButtonWithState, props);
}

class _DeleteWarningViewState extends ObservableComponent<DeleteWarningViewProps> {
  d3eState: DeleteWarningViewRefs = new DeleteWarningViewRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: DeleteWarningViewProps) {
    super(props);

    this.initState();
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {}
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return PopupWrapperView({
      title: "Confirm Delete",
      content: TextView({
        data: "You are about to delete data permanently. Are you sure you want to continue?",
        softWrap: true,
        style: new ui.TextStyle({ fontSize: 16 }),
        className: "x08f hc",
      }),
      buttons: [
        NoButtonWithState({
          d3eState: this.d3eState,
          _noButtonHandler: this.noButtonHandler,
          key: "0",
        }),
        YesButtonWithState({
          d3eState: this.d3eState,
          _yesButtonHandler: this.yesButtonHandler,
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "DeleteWarningView hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public noButtonHandler = (d3eState: DeleteWarningViewRefs): void => {
    this.navigator.close();
  };
  public yesButtonHandler = (d3eState: DeleteWarningViewRefs): void => {
    if (this.onDelete !== null) {
      this.onDelete();

      this.navigator.close();
    }
  };
  public get onDelete(): _DeleteWarningViewOnDelete {
    return this.props.onDelete;
  }
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get noButton() {
    return this.d3eState.noButton;
  }
  public get yesButton() {
    return this.d3eState.yesButton;
  }
}
export default function DeleteWarningView(props: DeleteWarningViewProps) {
  return React.createElement(_DeleteWarningViewState, props);
}
