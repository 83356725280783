import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import TextView from "./TextView";
import FacilityRate from "../models/FacilityRate";
import Facility from "../models/Facility";
import LightDivider from "./LightDivider";
import { BuildContext } from "../classes/BuildContext";

type _CategoiresListOnSelectItem = (value: FacilityRate) => void;

type _CategoiresListItemRefOnPressed = (d3eState: _ItemState) => void;

export interface CategoiresListProps extends BaseUIProps {
  key?: string;
  facility: Facility;
  onSelectItem?: _CategoiresListOnSelectItem;
}
/// To store state data for CategoiresList
class CategoiresListRefs {
  public itemState: Map<FacilityRate, _ItemState> = new Map();
  public forItem(item: FacilityRate): _ItemState {
    let res = this.itemState.get(item);

    if (res == null) {
      res = new _ItemState(this, item);

      this.itemState.set(item, res);
    }

    return res;
  }
}

interface CategoiresListItemRefWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _ItemState;
  _onTapCategoiresListItemRef?: _CategoiresListItemRefOnPressed;
  item: FacilityRate;
}

class CategoiresListItemRefState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _CategoiresListItemRefWithState extends ObservableComponent<CategoiresListItemRefWithStateProps> {
  categoiresListItemRefFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CategoiresListItemRefWithStateProps) {
    super(props);

    this.initState();
  }
  public get categoiresListItemRef(): CategoiresListItemRefState {
    return this.props.d3eState.categoiresListItemRef;
  }
  public get item(): FacilityRate {
    return this.props.item;
  }
  public get d3eState(): _ItemState {
    return this.props.d3eState;
  }
  public get _onTapCategoiresListItemRef(): _CategoiresListItemRefOnPressed {
    return this.props._onTapCategoiresListItemRef;
  }
  public initState() {
    super.initState();

    this.updateObservable(
      "categoiresListItemRef",
      null,
      this.categoiresListItemRef
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("item", this.props.item);

    this.on(
      [
        "categoiresListItemRef",
        "item",
        "item.category",
        "item.category.description",
        "item.category.name",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(
    prevProps: CategoiresListItemRefWithStateProps
  ): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.item !== this.props.item) {
      this.updateObservable("item", prevProps.item, this.props.item);

      this.fire("item", this);
    }
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.categoiresListItemRef.disable,
      onPressed: () => {
        this._onTapCategoiresListItemRef(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: ui.Column({
        crossAxisAlignment: ui.CrossAxisAlignment.start,
        children: [
          TextView({
            data: this.item.category.name,
            style: new ui.TextStyle({
              fontSize: cStyle.tTextViewHeadlineFiveFontSizeOn,
              fontWeight: cStyle.tTextViewHeadlineFiveFontWeightOn,
            }),
            className: "hc",
            key: "0",
          }),
          TextView({
            data: this.item.category.description,
            softWrap: true,
            style: new ui.TextStyle({ fontSize: 12 }),
            className: "x70 hc",
            key: "1",
          }),
        ],
        className: "hc",
      }),
      className: "hc",
    });
  }
}
function CategoiresListItemRefWithState(
  props: CategoiresListItemRefWithStateProps
) {
  return React.createElement(_CategoiresListItemRefWithState, props);
}

class _ItemState {
  parent: CategoiresListRefs;
  item: FacilityRate;
  categoiresListItemRef: CategoiresListItemRefState =
    new CategoiresListItemRefState();
  public constructor(parent, item) {
    this.parent = parent;

    this.item = item;
  }
}

class _CategoiresListState extends ObservableComponent<CategoiresListProps> {
  static defaultProps = { facility: null, onSelectItem: null };
  d3eState: CategoiresListRefs = new CategoiresListRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CategoiresListProps) {
    super(props);

    this.initState();
  }
  public get facility(): Facility {
    return this.props.facility;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("facility", this.props.facility);

    this.on(["facility", "facility.prices"], this.rebuild);
  }
  public componentDidUpdate(prevProps: CategoiresListProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.facility !== this.props.facility) {
      this.updateObservable(
        "facility",
        prevProps.facility,
        this.props.facility
      );

      this.fire("facility", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      mainAxisSize: ui.MainAxisSize.min,
      children: [
        this.facility.prices.expand((item) => [
          ui.Column({
            crossAxisAlignment: ui.CrossAxisAlignment.start,
            children: [
              CategoiresListItemRefWithState({
                d3eState: this.d3eState.forItem(item),
                _onTapCategoiresListItemRef: this.onTapCategoiresListItemRef,
                item: item,
                key: "0",
              }),
              LightDivider({
                dividerColor: cStyle.c14,
                className: "xc10 hc h",
                key: "1",
              }),
            ],
            className: "x439 hc h",
            key: item?.ident,
          }),
        ]),
      ],
      className: ui.join(this.props.className, "CategoiresList xb1 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onTapCategoiresListItemRef = (d3eState: _ItemState): void => {
    if (this.onSelectItem !== null) {
      this.onSelectItem(d3eState.item);
    }
  };
  public get onSelectItem(): _CategoiresListOnSelectItem {
    return this.props.onSelectItem;
  }
}
export default function CategoiresList(props: CategoiresListProps) {
  return React.createElement(_CategoiresListState, {
    ..._CategoiresListState.defaultProps,
    ...props,
  });
}
