import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import FacilityType from "../models/FacilityType";
import Loader from "./Loader";
import School from "../models/School";
import FooterView from "./FooterView";
import MaterialIcons from "../icons/MaterialIcons";
import PageNavigator from "../classes/PageNavigator";
import HeaderView from "./HeaderView";
import Renter from "../models/Renter";
import FacilityTypeListRequest from "../models/FacilityTypeListRequest";
import FeaturesCardView from "./FeaturesCardView";
import Query from "../classes/Query";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import SchoolsListRequest from "../models/SchoolsListRequest";
import TextView from "./TextView";
import FacilityRentCardView from "./FacilityRentCardView";
import RentalSearchableView from "./RentalSearchableView";
import BaseUser from "../models/BaseUser";
import CollectionUtils from "../utils/CollectionUtils";
import SchoolsMapView from "./SchoolsMapView";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _Button2OnPressed = (d3eState: MainHomePageRefs) => void;

type _Button3OnPressed = (d3eState: MainHomePageRefs) => void;

type _SearchButtonOnPressed = (d3eState: MainHomePageRefs) => void;

type _RentalSearchableViewOnChanged = (
  text: FacilityType,
  d3eState: MainHomePageRefs
) => void;

type _RentalSearchableView2OnChanged = (
  text: string,
  d3eState: MainHomePageRefs
) => void;

export interface MainHomePageProps extends BaseUIProps {
  key?: string;
}
/// To store state data for MainHomePage
class MainHomePageRefs {
  public button2: Button2State = new Button2State();
  public button3: Button3State = new Button3State();
  public searchButton: SearchButtonState = new SearchButtonState();
}

interface SearchButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: MainHomePageRefs;
  _onPressedSearchButtonHandler?: _SearchButtonOnPressed;
}

class SearchButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _SearchButtonWithState extends ObservableComponent<SearchButtonWithStateProps> {
  searchButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SearchButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get searchButton(): SearchButtonState {
    return this.props.d3eState.searchButton;
  }
  public get d3eState(): MainHomePageRefs {
    return this.props.d3eState;
  }
  public get _onPressedSearchButtonHandler(): _SearchButtonOnPressed {
    return this.props._onPressedSearchButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("searchButton", null, this.searchButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["searchButton"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 20.0, 0.0, 0.0, new Map()),
      width: 415,
      child: Button({
        padding: cStyle.tButtonPrimaryButtonPaddingOn,
        decoration: cStyle.tButtonPrimaryButtonDecorationOn,
        disable: this.searchButton.disable,
        onPressed: () => {
          this._onPressedSearchButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Search", className: "hc" }),
      }),
      className: "xeaf hc",
    });
  }
}
function SearchButtonWithState(props: SearchButtonWithStateProps) {
  return React.createElement(_SearchButtonWithState, props);
}

interface Button3WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: MainHomePageRefs;
  _onAttendingADemo?: _Button3OnPressed;
}

class Button3State extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _Button3WithState extends ObservableComponent<Button3WithStateProps> {
  button3FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Button3WithStateProps) {
    super(props);

    this.initState();
  }
  public get button3(): Button3State {
    return this.props.d3eState.button3;
  }
  public get d3eState(): MainHomePageRefs {
    return this.props.d3eState;
  }
  public get _onAttendingADemo(): _Button3OnPressed {
    return this.props._onAttendingADemo;
  }
  public initState() {
    super.initState();

    this.updateObservable("button3", null, this.button3);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button3"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      padding: cStyle.tButtonPrimaryButtonPaddingOn,
      decoration: cStyle.tButtonPrimaryButtonDecorationOn,
      disable: this.button3.disable,
      onPressed: () => {
        this._onAttendingADemo(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({ data: "Attend a Demo" }),
      className: "x4b52",
    });
  }
}
function Button3WithState(props: Button3WithStateProps) {
  return React.createElement(_Button3WithState, props);
}

interface Button2WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: MainHomePageRefs;
  _onScheduleADemo?: _Button2OnPressed;
}

class Button2State extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _Button2WithState extends ObservableComponent<Button2WithStateProps> {
  button2FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Button2WithStateProps) {
    super(props);

    this.initState();
  }
  public get button2(): Button2State {
    return this.props.d3eState.button2;
  }
  public get d3eState(): MainHomePageRefs {
    return this.props.d3eState;
  }
  public get _onScheduleADemo(): _Button2OnPressed {
    return this.props._onScheduleADemo;
  }
  public initState() {
    super.initState();

    this.updateObservable("button2", null, this.button2);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button2"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 20.0, 0.0, new Map()),
      child: Button({
        padding: cStyle.tButtonDemoButtonPaddingOn,
        decoration: cStyle.tButtonDemoButtonDecorationOn,
        disable: this.button2.disable,
        onPressed: () => {
          this._onScheduleADemo(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Schedule a Demo" }),
      }),
      className: "x71",
    });
  }
}
function Button2WithState(props: Button2WithStateProps) {
  return React.createElement(_Button2WithState, props);
}

class _MainHomePageState extends ObservableComponent<MainHomePageProps> {
  d3eState: MainHomePageRefs = new MainHomePageRefs();
  facilityType: FacilityType = null;
  location: string = "";
  facilityTypes: Array<FacilityType> = ListWrapper.widget(
    this,
    "facilityTypes"
  );
  schools: Array<School> = ListWrapper.widget(this, "schools");
  locations: Array<string> = ListWrapper.widget(this, "locations");
  userLoaded: boolean = false;
  renter: Renter = null;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: MainHomePageProps) {
    super(props);

    this.initState();
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.computeFacilityTypes();

    this.computeSchools();

    this.on(
      ["schools", "schools.city", "schools.state"],
      this.computeLocations
    );

    this.computeLocations();

    this.on(
      [
        "facilityType",
        "facilityTypes",
        "location",
        "locations",
        "renter",
        "schools",
        "userLoaded",
      ],
      this.rebuild
    );
  }
  public setFacilityType(val: FacilityType): void {
    let isValChanged: boolean = this.facilityType !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("facilityType", this.facilityType, val);

    this.facilityType = val;

    this.fire("facilityType", this);
  }
  public setLocation(val: string): void {
    let isValChanged: boolean = this.location !== val;

    if (!isValChanged) {
      return;
    }

    this.location = val;

    this.fire("location", this);
  }
  public setFacilityTypes(val: Array<FacilityType>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.facilityTypes,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("facilityTypes", this.facilityTypes, val);

    this.facilityTypes.clear();

    this.facilityTypes.addAll(val);

    this.fire("facilityTypes", this);
  }
  public addToFacilityTypes(val: FacilityType, index: number = -1): void {
    if (index === -1) {
      if (!this.facilityTypes.contains(val)) this.facilityTypes.add(val);
    } else {
      this.facilityTypes.remove(this.facilityTypes.elementAt(index));

      this.facilityTypes.add(val);
    }

    this.fire("facilityTypes", this, val, true);

    this.updateObservable("facilityTypes", null, val);
  }
  public removeFromFacilityTypes(val: FacilityType): void {
    this.facilityTypes.remove(val);

    this.fire("facilityTypes", this, val, false);

    this.removeObservable("facilityTypes", val);
  }
  public computeFacilityTypes = async (): Promise<void> => {
    try {
      this.setFacilityTypes(
        Array.from(
          (
            await Query.get().getFacilityTypeList(
              UsageConstants.QUERY_GETFACILITYTYPELIST_MAINHOMEPAGE_PROPERTIES_FACILITYTYPES_COMPUTATION,
              new FacilityTypeListRequest({ pageSize: 100, offset: 0 })
            )
          )?.items ?? []
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeFacilityTypes : " + exception.toString()
      );

      this.setFacilityTypes([]);
    }
  };
  public setSchools(val: Array<School>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.schools, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("schools", this.schools, val);

    this.schools.clear();

    this.schools.addAll(val);

    this.fire("schools", this);
  }
  public addToSchools(val: School, index: number = -1): void {
    if (index === -1) {
      if (!this.schools.contains(val)) this.schools.add(val);
    } else {
      this.schools.remove(this.schools.elementAt(index));

      this.schools.add(val);
    }

    this.fire("schools", this, val, true);

    this.updateObservable("schools", null, val);
  }
  public removeFromSchools(val: School): void {
    this.schools.remove(val);

    this.fire("schools", this, val, false);

    this.removeObservable("schools", val);
  }
  public computeSchools = async (): Promise<void> => {
    try {
      this.setSchools(
        Array.from(
          (
            await Query.get().getSchoolsList(
              UsageConstants.QUERY_GETSCHOOLSLIST_MAINHOMEPAGE_PROPERTIES_SCHOOLS_COMPUTATION,
              new SchoolsListRequest({ pageSize: 1000, offset: 0 })
            )
          ).items
        )
      );
    } catch (exception) {
      console.log(" exception in computeSchools : " + exception.toString());

      this.setSchools([]);
    }
  };
  public setLocations(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.locations,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.locations.clear();

    this.locations.addAll(val);

    this.fire("locations", this);
  }
  public addToLocations(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.locations.contains(val)) this.locations.add(val);
    } else {
      this.locations.remove(this.locations.elementAt(index));

      this.locations.add(val);
    }

    this.fire("locations", this, val, true);
  }
  public removeFromLocations(val: string): void {
    this.locations.remove(val);

    this.fire("locations", this, val, false);
  }
  public computeLocations = (): void => {
    try {
      this.setLocations(
        Array.from(
          this.schools
            .map((school) => school.city + ", " + school.state)
            .toSet()
            .toList()
        )
      );
    } catch (exception) {
      console.log(" exception in computeLocations : " + exception.toString());

      this.setLocations([]);
    }
  };
  public setUserLoaded(val: boolean): void {
    let isValChanged: boolean = this.userLoaded !== val;

    if (!isValChanged) {
      return;
    }

    this.userLoaded = val;

    this.fire("userLoaded", this);
  }
  public setRenter(val: Renter): void {
    let isValChanged: boolean = this.renter !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("renter", this.renter, val);

    this.renter = val;

    this.fire("renter", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return this.userLoaded
      ? ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            HeaderView({
              renter: this.renter,
              className: "x693 hc h",
              key: "0",
            }),
            ui.Column({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  children: [
                    ui.Column({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        TextView({
                          data: "We bring communities &\nspaces together.",
                          softWrap: true,
                          style: new ui.TextStyle({
                            color: cStyle.c2,
                            fontSize:
                              cStyle.tTextViewDisplayHeadingThreeFontSizeOn,
                            fontWeight: cStyle.tTextViewHeadlineOneFontWeightOn,
                          }),
                          className: "x894 hc",
                          key: "0",
                        }),
                        TextView({
                          data: "FaciliRes helps community members search and\nrequest public event spaces in their local area.",
                          style: new ui.TextStyle({
                            color: cStyle.c2,
                            height: 2,
                            fontSize: cStyle.tTextViewHeadlineThreeFontSizeOn,
                            fontWeight:
                              cStyle.tTextViewHeadlineThreeFontWeightOn,
                          }),
                          className: "x311 hc",
                          key: "1",
                        }),
                        ui.Row({
                          children: [
                            Button2WithState({
                              d3eState: this.d3eState,
                              _onScheduleADemo: this.onScheduleADemo,
                              key: "0",
                            }),
                            Button3WithState({
                              d3eState: this.d3eState,
                              _onAttendingADemo: this.onAttendingADemo,
                              key: "1",
                            }),
                          ],
                          className: "xfb hc h",
                          key: "2",
                        }),
                      ],
                      className: "xd5c hc",
                      key: "0",
                    }),
                    ui.Column({
                      children: [
                        TextView({
                          data: "Search for a Auditoriums, gymnasiums, rooms, playing Fields, pools and GTI-Hospital Room",
                          softWrap: true,
                          style: new ui.TextStyle({
                            height: 1.6,
                            fontSize: cStyle.tTextViewHeadlineThreeFontSizeOn,
                            fontWeight:
                              cStyle.tTextViewHeadlineThreeFontWeightOn,
                          }),
                          className: "x2d6 hc",
                          key: "0",
                        }),
                        ui.Container({
                          padding: ui.EdgeInsets.all(5.0, new Map()),
                          width: 415,
                          child: RentalSearchableView<FacilityType>({
                            name: "Facility Name",
                            placeHolder: "E.g Auditoriums",
                            activeColor: cStyle.c20,
                            inActiveColor: cStyle.c36,
                            value: this.facilityType,
                            items: this.facilityTypes,
                            onChanged: (text) => {
                              this.rentalSearchableViewonChanged(
                                text,
                                this.d3eState
                              );
                            },
                          }),
                          key: "1",
                          className: "x25b hc",
                        }),
                        ui.Container({
                          padding: ui.EdgeInsets.all(5.0, new Map()),
                          width: 415,
                          child: RentalSearchableView<string>({
                            name: "Facility Location",
                            placeHolder: "Enter Location",
                            inActiveColor: cStyle.c36,
                            activeColor: cStyle.c20,
                            value: this.location,
                            items: this.locations,
                            onChanged: (text) => {
                              this.rentalSearchableView2onChanged(
                                text,
                                this.d3eState
                              );
                            },
                          }),
                          key: "2",
                          className: "x65 hc",
                        }),
                        SearchButtonWithState({
                          d3eState: this.d3eState,
                          _onPressedSearchButtonHandler:
                            this.onPressedSearchButtonHandler,
                          key: "3",
                        }),
                      ],
                      className: "x9b5 hc",
                      key: "1",
                    }),
                  ],
                  className: "xb83 hc h",
                  key: "0",
                }),
              ],
              className: "x92 hc h",
              key: "1",
            }),
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                TextView({
                  data: "Facilities for rent",
                  style: new ui.TextStyle({
                    fontSize: cStyle.tTextViewHeadlineOneFontSizeOn,
                    fontWeight: cStyle.tTextViewHeadlineOneFontWeightOn,
                  }),
                  className: "x9ad hc",
                  key: "0",
                }),
                TextView({
                  data: "We feature a variety of auditoriums, gyms, classrooms, and other venue options available for community use.",
                  style: new ui.TextStyle({
                    fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                    fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
                  }),
                  className: "x1de hc",
                  key: "1",
                }),
                ui.Wrap({
                  children: [
                    this.facilityTypes.expand((facilityTypeItem) => [
                      FacilityRentCardView({
                        facilityType: facilityTypeItem,
                        onTapFacilityType: (value) => {
                          this.onTapFacilityTypeHandler(
                            value,
                            facilityTypeItem,
                            this.d3eState
                          );
                        },
                        className: "hc",
                        key: facilityTypeItem?.ident,
                      }),
                    ]),
                  ],
                  className: "hc",
                  key: "2",
                }),
              ],
              className: "xd26 hc",
              key: "2",
            }),
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.Container({
                  height: 500,
                  margin: ui.EdgeInsets.fromLTRB(
                    0.0,
                    0.0,
                    0.0,
                    25.0,
                    new Map()
                  ),
                  width: Number.POSITIVE_INFINITY,
                  child: SchoolsMapView({ schools: this.schools }),
                  key: "0",
                  className: "xf62 hc vc",
                }),
              ],
              className: "x93 hc",
              key: "3",
            }),
            ui.Column({
              children: [
                TextView({
                  data: "Some premium features",
                  style: new ui.TextStyle({
                    fontSize: cStyle.tTextViewHeadlineOneFontSizeOn,
                    fontWeight: cStyle.tTextViewHeadlineOneFontWeightOn,
                  }),
                  className: "x019 hc",
                  key: "0",
                }),
                ui.Column({
                  children: [
                    ui.Row({
                      mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                      children: [
                        ui.Container({
                          decoration: new ui.BoxDecoration({
                            border: new ui.Border({
                              top: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                              left: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                              right: new ui.BorderSide({
                                color: cStyle.c5,
                                width: 1.0,
                                style: ui.BorderStyle.solid,
                              }),
                              bottom: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                            }),
                          }),
                          child: FeaturesCardView({
                            icon: MaterialIcons.calendar_month,
                            headerText: "One calendar",
                            subText:
                              "Schedule facility use and manage \nrequests from the community all in one place.",
                          }),
                          key: "0",
                          className: "xdc2",
                        }),
                        ui.Container({
                          decoration: new ui.BoxDecoration({
                            border: new ui.Border({
                              top: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                              left: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                              right: new ui.BorderSide({
                                color: cStyle.c5,
                                width: 1.0,
                                style: ui.BorderStyle.solid,
                              }),
                              bottom: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                            }),
                          }),
                          child: FeaturesCardView({
                            icon: MaterialIcons.calendar_month,
                            headerText: "Showcase your facilities",
                            subText:
                              "Schedule facility use and manage \nrequests from the community all in one place.",
                          }),
                          key: "1",
                          className: "x506",
                        }),
                        ui.Container({
                          decoration: new ui.BoxDecoration({
                            border: new ui.Border({
                              top: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                              left: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                              right: new ui.BorderSide({
                                color: cStyle.c5,
                                width: 1.0,
                                style: ui.BorderStyle.solid,
                              }),
                              bottom: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                            }),
                          }),
                          child: FeaturesCardView({
                            icon: MaterialIcons.local_atm,
                            headerText: "Payments and insurance",
                            subText:
                              "Schedule facility use and manage \nrequests from the community all in one place.",
                          }),
                          key: "2",
                          className: "xf5e",
                        }),
                        ui.Container({
                          decoration: new ui.BoxDecoration({
                            border: ui.Border.all({
                              color: new ui.Color(0xff0000),
                              width: 0.0,
                            }),
                          }),
                          child: FeaturesCardView({
                            icon: MaterialIcons.support_agent,
                            headerText: "24/7 Customer support",
                            subText:
                              "Schedule facility use and manage \nrequests from the community all in one place.",
                          }),
                          key: "3",
                          className: "xec2",
                        }),
                      ],
                      className: "x463 hc h",
                      key: "0",
                    }),
                    ui.Row({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      className: "x8f2 hc h",
                      key: "1",
                    }),
                    ui.Row({
                      mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        ui.Container({
                          decoration: new ui.BoxDecoration({
                            border: new ui.Border({
                              top: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                              left: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                              right: new ui.BorderSide({
                                color: cStyle.c5,
                                width: 1.0,
                                style: ui.BorderStyle.solid,
                              }),
                              bottom: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                            }),
                          }),
                          child: FeaturesCardView({
                            icon: MaterialIcons.perm_contact_calendar,
                            headerText: "Schedule work and maintenance",
                            subText:
                              "Schedule facility use and manage \nrequests from the community all in one place.",
                          }),
                          key: "0",
                          className: "x285",
                        }),
                        ui.Container({
                          decoration: new ui.BoxDecoration({
                            border: new ui.Border({
                              top: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                              left: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                              right: new ui.BorderSide({
                                color: cStyle.c5,
                                width: 1.0,
                                style: ui.BorderStyle.solid,
                              }),
                              bottom: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                            }),
                          }),
                          child: FeaturesCardView({
                            icon: MaterialIcons.handshake,
                            headerText: "Easy onboarding",
                            subText:
                              "Schedule facility use and manage \nrequests from the community all in one place.",
                          }),
                          key: "1",
                          className: "x177",
                        }),
                        ui.Container({
                          decoration: new ui.BoxDecoration({
                            border: new ui.Border({
                              top: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                              left: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                              right: new ui.BorderSide({
                                color: cStyle.c5,
                                width: 1.0,
                                style: ui.BorderStyle.solid,
                              }),
                              bottom: new ui.BorderSide({
                                color: new ui.Color(0xff000000),
                                width: 1.0,
                                style: ui.BorderStyle.none,
                              }),
                            }),
                          }),
                          child: FeaturesCardView({
                            icon: MaterialIcons.insert_chart_outlined,
                            headerText: "Business insights",
                            subText:
                              "Schedule facility use and manage \nrequests from the community all in one place.",
                          }),
                          key: "2",
                          className: "x1cfb",
                        }),
                        ui.Container({
                          decoration: new ui.BoxDecoration({
                            border: ui.Border.all({
                              color: new ui.Color(0xff0000),
                              width: 0.0,
                            }),
                          }),
                          child: FeaturesCardView({
                            icon: MaterialIcons.thumb_up,
                            headerText: "No direct cost",
                            subText:
                              "Schedule facility use and manage \nrequests from the community all in one place.",
                          }),
                          key: "3",
                          className: "x005",
                        }),
                      ],
                      className: "xc61 hc h",
                      key: "2",
                    }),
                  ],
                  className: "xbf7 hc h",
                  key: "1",
                }),
              ],
              className: "x32a hc h",
              key: "4",
            }),
            FooterView({ className: "x541 hc h", key: "5" }),
          ],
          className: ui.join(this.props.className, "MainHomePage x1a8 hc vc"),
          ...copyBaseUIProps(this.props),
        })
      : ui.Column({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                Loader({ key: "0" }),
                TextView({
                  data: "Loading App......",
                  className: "xe9f",
                  key: "1",
                }),
              ],
              className: "xdd hc h",
              key: "0",
            }),
          ],
          className: ui.join(this.props.className, "MainHomePage hc vc"),
          ...copyBaseUIProps(this.props),
        });
  }
  public onInit = async (): Promise<void> => {
    let baseUser: BaseUser = await Query.get().currentUser();

    if (baseUser !== null) {
      //  It must be EndUser as we dont have other user types now

      let user: Renter = await Query.get().getRenterById(
        UsageConstants.QUERY_LOADRENTER_MAINHOMEPAGE_EVENTHANDLERS_ONINIT_BLOCK,
        (baseUser as Renter).id
      );

      if (user !== null && user.active) {
        this.setRenter(user);
      }
    }

    this.setUserLoaded(true);
  };
  public onAttendingADemo = (d3eState: MainHomePageRefs): void => {};
  public onScheduleADemo = (d3eState: MainHomePageRefs): void => {};
  public onPressedSearchButtonHandler = (d3eState: MainHomePageRefs): void => {
    this.navigator.pushSearchPage({
      facilityType: this.facilityType,
      location: this.location,
      facilityTypes: this.facilityTypes,
      locations: this.locations,
      renter: this.renter,
    });
  };
  public onTapFacilityTypeHandler = (
    value: FacilityType,
    facilityTypeItem: FacilityType,
    d3eState: MainHomePageRefs
  ): void => {
    this.navigator.pushSearchPage({
      facilityType: value,
      location: this.location,
      facilityTypes: this.facilityTypes,
      locations: this.locations,
      renter: null,
    });
  };
  public rentalSearchableViewonChanged = (
    val: FacilityType,
    d3eState: MainHomePageRefs
  ): void => {
    this.setFacilityType(val);
  };
  public rentalSearchableView2onChanged = (
    val: string,
    d3eState: MainHomePageRefs
  ): void => {
    this.setLocation(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get button2() {
    return this.d3eState.button2;
  }
  public get button3() {
    return this.d3eState.button3;
  }
  public get searchButton() {
    return this.d3eState.searchButton;
  }
}
export default function MainHomePage(props: MainHomePageProps) {
  return React.createElement(_MainHomePageState, props);
}
