import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import RentalUpcomingEventView from "./RentalUpcomingEventView";
import RentalPendingRequestsView from "./RentalPendingRequestsView";
import RentalPaymentsView from "./RentalPaymentsView";
import Renter from "../models/Renter";
import RentalSearchRequestView from "./RentalSearchRequestView";

export interface RentalDashboardPageProps extends BaseUIProps {
  key?: string;
  renter: Renter;
}

class _RentalDashboardPageState extends ObservableComponent<RentalDashboardPageProps> {
  static defaultProps = { renter: null };
  public constructor(props: RentalDashboardPageProps) {
    super(props);

    this.initState();
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("renter", this.props.renter);

    this.on(["renter"], this.rebuild);
  }
  public componentDidUpdate(prevProps: RentalDashboardPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public render(): ReactNode {
    return ui.Column({
      children: [
        ui.Wrap({
          runSpacing: 10,
          spacing: 10,
          children: [
            ui.Container({
              constraints: new ui.BoxConstraints({
                maxWidth: 800,
                maxHeight: 300,
              }),
              child: RentalPaymentsView({ renter: this.renter }),
              key: "0",
              className: "x37cd hc vc",
            }),
            ui.Container({
              constraints: new ui.BoxConstraints({
                maxWidth: 800,
                maxHeight: 300,
              }),
              child: RentalUpcomingEventView({ renter: this.renter }),
              key: "1",
              className: "x648 hc vc",
            }),
            ui.Container({
              constraints: new ui.BoxConstraints({
                maxWidth: 800,
                maxHeight: 300,
              }),
              child: RentalPendingRequestsView({ renter: this.renter }),
              key: "2",
              className: "xebb hc vc",
            }),
            ui.Container({
              constraints: new ui.BoxConstraints({
                maxWidth: 800,
                maxHeight: 300,
              }),
              child: RentalSearchRequestView({ renter: this.renter }),
              key: "3",
              className: "x9d6 hc vc",
            }),
          ],
          className: "hc",
          key: "0",
        }),
      ],
      className: ui.join(
        this.props.className,
        "RentalDashboardPage x2f4 hc vc"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function RentalDashboardPage(props: RentalDashboardPageProps) {
  return React.createElement(_RentalDashboardPageState, {
    ..._RentalDashboardPageState.defaultProps,
    ...props,
  });
}
