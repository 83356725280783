import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ListWrapper from "../utils/ListWrapper";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

type _LabelwithInputFieldOnChanged = (value: string) => void;

type _LabelwithInputFieldOnTap = () => void;

type _LabelwithInputFieldOnSubmitted = (value: string) => void;

export interface LabelwithInputFieldProps extends BaseUIProps {
  key?: string;
  name?: string;
  placeHolder?: string;
  isRequired?: boolean;
  value?: string;
  errors?: Array<string>;
  obscureText?: boolean;
  disable?: boolean;
  fieldColor?: ui.Color;
  dense?: boolean;
  _errorsHash?: number;
  onChanged?: _LabelwithInputFieldOnChanged;
  onTap?: _LabelwithInputFieldOnTap;
  onSubmitted?: _LabelwithInputFieldOnSubmitted;
}

class _LabelwithInputFieldState extends ObservableComponent<LabelwithInputFieldProps> {
  static defaultProps = {
    name: "",
    placeHolder: "",
    isRequired: false,
    value: "",
    obscureText: false,
    disable: false,
    fieldColor: null,
    dense: false,
    errors: [],
    onChanged: null,
    onTap: null,
    onSubmitted: null,
  };
  fieldController: ui.TextEditingController = new ui.TextEditingController();
  focusNode: ui.FocusNode = null;
  active: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LabelwithInputFieldProps) {
    super(props);

    this.initState();
  }
  public get name(): string {
    return this.props.name;
  }
  public get placeHolder(): string {
    return this.props.placeHolder;
  }
  public get isRequired(): boolean {
    return this.props.isRequired;
  }
  public get value(): string {
    return this.props.value;
  }
  public get errors(): Array<string> {
    return this.props.errors;
  }
  public get obscureText(): boolean {
    return this.props.obscureText;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public get fieldColor(): ui.Color {
    return this.props.fieldColor;
  }
  public get dense(): boolean {
    return this.props.dense;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.subscribeToList(this.errors, "errors");

    this.on(
      [
        "dense",
        "disable",
        "errors",
        "focusNode",
        "isRequired",
        "name",
        "obscureText",
        "placeHolder",
        "value",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: LabelwithInputFieldProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.name !== this.props.name) {
      this.fire("name", this);
    }

    if (prevProps.placeHolder !== this.props.placeHolder) {
      this.fire("placeHolder", this);
    }

    if (prevProps.isRequired !== this.props.isRequired) {
      this.fire("isRequired", this);
    }

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.errors !== this.props.errors) {
      this.fire("errors", this);
    }

    if (prevProps.obscureText !== this.props.obscureText) {
      this.fire("obscureText", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }

    if (prevProps.fieldColor !== this.props.fieldColor) {
      this.fire("fieldColor", this);
    }

    if (prevProps.dense !== this.props.dense) {
      this.fire("dense", this);
    }
  }
  public setFocusNode(val: ui.FocusNode): void {
    let isValChanged: boolean = this.focusNode !== val;

    if (!isValChanged) {
      return;
    }

    this.focusNode = val;

    this.fire("focusNode", this);
  }
  public setActive(val: boolean): void {
    let isValChanged: boolean = this.active !== val;

    if (!isValChanged) {
      return;
    }

    this.active = val;

    this.fire("active", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          children: [
            this.name !== null && this.name.isNotEmpty
              ? TextView({
                  data: this.name,
                  style: new ui.TextStyle({
                    fontFamily: "Open Sans",
                    fontSize: 14,
                  }),
                  className: "x293",
                })
              : [],
            this.isRequired
              ? TextView({
                  data: "*",
                  style: new ui.TextStyle({ color: cStyle.c8 }),
                  className: "xa609",
                })
              : [],
          ],
          className: "x01c hc h",
          key: "0",
        }),
        ui.InputField({
          value: this.value,
          inActiveColor: this.errors.isNotEmpty
            ? new ui.Color(0xffe71306)
            : new ui.Color(0xffdcdcdc),
          activeColor: this.errors.isNotEmpty
            ? new ui.Color(0xffe71306)
            : new ui.Color(0xff6c10ec),
          focusNode: this.focusNode,
          padding: ui.EdgeInsets.symmetric({
            horizontal: 10.0,
            vertical: 0.0,
            transitions: new Map(),
          }),
          placeHolder: this.placeHolder,
          obscureText: this.obscureText,
          disable: this.disable,
          backgroundColor: cStyle.c2,
          style: new ui.TextStyle({
            fontSize: 14,
            fontWeight: ui.FontWeight.w500,
            decorationStyle: ui.TextDecorationStyle.solid,
          }),
          cornerRadius: 3,
          dense: this.dense,
          controller: this.fieldController,
          onChanged: (text) => {
            this.onChangeText(text);
          },
          onSubmitted: (text) => {
            this.onSubmiitedTextHandler(text);
          },
          onTap: () => {
            this.onTapHandker();
          },
          onFocusChange: (val) => {},
          states: ui.joinStates({ "data-c0": this.errors.isNotEmpty }, {}),
          className: "x0d1 hc vc h",
          key: "1",
        }),
        this.errors.isNotEmpty
          ? ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                this.errors.expand((item) => [
                  TextView({
                    data: item,
                    style: new ui.TextStyle({
                      fontSize: 14,
                      fontFamily: cStyle.tTextViewErrorLabelFontFamilyOn,
                      color: cStyle.tTextViewErrorLabelColorOn,
                    }),
                    className: "xd15 hc",
                    key: item?.toString(),
                  }),
                ]),
              ],
              className: "hc",
            })
          : [],
      ],
      className: ui.join(this.props.className, "LabelwithInputField x6a5 hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setFocusNode(new ui.FocusNode());

    this.focusNode.addListener(this.onChangeFocus);
  };
  public onChangeFocus = (): void => {
    this.setActive(this.focusNode.hasFocus);

    if (this.active && this.onTap !== null) {
      this.onTap();
    }
  };
  public onChangeText = (text: string): void => {
    if (this.onChanged !== null) {
      this.onChanged(text);
    }
  };
  public onSubmiitedTextHandler = (text: string): void => {
    if (this.onSubmitted !== null) {
      this.onSubmitted(text);
    }
  };
  public onTapHandker = (): void => {
    if (this.onTap !== null) {
      this.onTap();
    }
  };
  public get onChanged(): _LabelwithInputFieldOnChanged {
    return this.props.onChanged;
  }
  public get onTap(): _LabelwithInputFieldOnTap {
    return this.props.onTap;
  }
  public get onSubmitted(): _LabelwithInputFieldOnSubmitted {
    return this.props.onSubmitted;
  }
}
export default function LabelwithInputField(props: LabelwithInputFieldProps) {
  return React.createElement(
    _LabelwithInputFieldState,
    { ..._LabelwithInputFieldState.defaultProps, ...props },
    ListWrapper.fromInput<string>(props.errors, "errors")
  );
}
