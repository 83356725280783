import { Component } from "react";
import PageNavigator from "./main/classes/PageNavigator";
import PageRouter from "./main/components/PageRouter";
import MainPage from "./main/components/MainPage";
import ThemeWrapper from "./main/components/ThemeWrapper";
import { StyleThemeData } from "./main/components/ThemeWrapper";
import { DefaultTextStyle, DefaultPopupOverlay } from "./main/native";

export default class MyApp extends Component<{}, { theme: StyleThemeData }> {
  public constructor(props: any) {
    super(props);

    StyleThemeData.current = StyleThemeData.createD3ELight();

    this.state = { theme: StyleThemeData.current };

    PageNavigator.themeUpdateListener = () => {
      this.setState({ theme: StyleThemeData.current });
    };
  }
  public render() {
    return ThemeWrapper({
      child: DefaultPopupOverlay({
        child: PageRouter({ target: "", component: MainPage({}), key: "0" }),
      }),
    });
  }
}
