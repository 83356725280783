import React, { ReactNode } from "react";
import { Axis } from "../classes/Axis";
import { Clip } from "../classes/Clip";
import { TextDirection } from "../classes/TextDirection";
import { VerticalDirection } from "../classes/VerticalDirection";
import { WrapAlignment } from "../classes/WrapAlignment";
import { WrapCrossAlignment } from "../classes/WrapCrossAlignment";
import BaseUIProps, { processBaseUIProps } from "./BaseUIProps";

interface WrapProps extends BaseUIProps {
  direction?: Axis;
  alignment?: WrapAlignment;
  spacing?: number;
  runAlignment?: WrapAlignment;
  runSpacing?: number;
  crossAxisAlignment?: WrapCrossAlignment;
  textDirection?: TextDirection;
  verticalDirection?: VerticalDirection;
  clipBehavior?: Clip;
  children?: Array<ReactNode>;
}

class _Wrap extends React.Component<WrapProps, {}> {
  static defaultProps = {
    direction: Axis.horizontal,
    alignment: WrapAlignment.start,
    spacing: 0,
    runAlignment: WrapAlignment.start,
    runSpacing: 0,
    crossAxisAlignment: WrapCrossAlignment.start,
    verticalDirection: VerticalDirection.down,
    clipBehavior: Clip.none,
  };
  render() {
    let classNames = [];
    let style: any = {};
    if (this.props.direction === Axis.vertical) {
      classNames.push("fd-col");
    }
    if (this.props.alignment) {
      // justifyContent
      switch (this.props.alignment) {
        case WrapAlignment.start:
          classNames.push("ma-s");
          break;
        case WrapAlignment.end:
          classNames.push("ma-e");
          break;
        case WrapAlignment.center:
          classNames.push("ma-c");
          break;
        case WrapAlignment.spaceBetween:
          classNames.push("ma-sb");
          break;
        case WrapAlignment.spaceAround:
          classNames.push("ma-sa");
          break;
        case WrapAlignment.spaceEvenly:
          classNames.push("ma-se");
          break;
      }
    }
    if (this.props.direction == Axis.horizontal) {
      if (this.props.spacing) {
        style.columnGap = this.props.spacing;
      }
      if (this.props.runSpacing) {
        style.rowGap = this.props.runSpacing;
      }
    } else {
      if (this.props.spacing) {
        style.rowGap = this.props.spacing;
      }
      if (this.props.runSpacing) {
        style.columnGap = this.props.runSpacing;
      }
    }
    if (this.props.runAlignment) {
      // alignItems
      switch (this.props.alignment) {
        case WrapAlignment.start:
          classNames.push("ra-s");
          break;
        case WrapAlignment.end:
          classNames.push("ra-e");
          break;
        case WrapAlignment.center:
          classNames.push("ra-c");
          break;
        case WrapAlignment.spaceBetween:
          break;
        case WrapAlignment.spaceAround:
          break;
        case WrapAlignment.spaceEvenly:
          break;
      }
    }
    if (this.props.crossAxisAlignment) {
      switch (this.props.crossAxisAlignment) {
        case WrapCrossAlignment.start:
          classNames.push("cr-s");
          break;
        case WrapCrossAlignment.end:
          classNames.push("cr-e");
          break;
        case WrapCrossAlignment.center:
          classNames.push("cr-c");
          break;
      }
    }
    if (this.props.textDirection) {
    }
    if (this.props.verticalDirection) {
    }
    if (this.props.clipBehavior) {
    }
    return React.createElement(
      "ui-wrap",
      {
        class:
          classNames.join(" ") +
          (this.props.className ? " " + this.props.className : ""),
        style: style,
        ...processBaseUIProps(this.props),
      },
      this.props.children
    );
  }
}

export default function Wrap(props: WrapProps) {
  return React.createElement(_Wrap, { ..._Wrap.defaultProps, ...props });
}
