import DateTime from "../core/DateTime";
import DBObject from "../utils/DBObject";
import D3EDate from "./D3EDate";

export default class CalendarEvent extends DBObject {
  private _id: number = DBObject.nextStructId();
  private static readonly _ALLDAY: number = 0;
  private static readonly _BACKGROUNDCOLOR: number = 1;
  private static readonly _CATEGORY: number = 2;
  private static readonly _DATE: number = 3;
  private static readonly _DESCRIPTION: number = 4;
  private static readonly _ENDTIME: number = 5;
  private static readonly _STARTTIME: number = 6;
  private static readonly _TITLE: number = 7;
  private _date: D3EDate = null;
  private _title: string = "";
  private _category: string = "";
  private _allDay: boolean = false;
  private _startTime: DateTime = null;
  private _endTime: DateTime = null;
  private _description: string = "";
  private _backgroundColor: string = "ffe91e63";
  public constructor(
    d3eParams?: Partial<{
      allDay: boolean;
      backgroundColor: string;
      category: string;
      date: D3EDate;
      description: string;
      endTime: DateTime;
      startTime: DateTime;
      title: string;
    }>
  ) {
    super();

    if (d3eParams?.allDay) {
      this.setAllDay(d3eParams?.allDay);
    }

    if (d3eParams?.backgroundColor) {
      this.setBackgroundColor(d3eParams?.backgroundColor);
    }

    if (d3eParams?.category) {
      this.setCategory(d3eParams?.category);
    }

    if (d3eParams?.date) {
      this.setDate(d3eParams?.date);
    }

    if (d3eParams?.description) {
      this.setDescription(d3eParams?.description);
    }

    if (d3eParams?.endTime) {
      this.setEndTime(d3eParams?.endTime);
    }

    if (d3eParams?.startTime) {
      this.setStartTime(d3eParams?.startTime);
    }

    if (d3eParams?.title) {
      this.setTitle(d3eParams?.title);
    }
  }
  public get id(): number {
    return this._id;
  }
  public set id(id: number) {
    this._id = id;
  }
  public get d3eType(): string {
    return "CalendarEvent";
  }
  public clear(): void {}
  public initListeners(): void {
    super.initListeners();
  }
  public get date(): D3EDate {
    return this._date;
  }
  public setDate(val: D3EDate): void {
    let isValChanged: boolean = this._date !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(CalendarEvent._DATE, this._date);

    this._date = val;

    this.fire("date", this);
  }
  public get title(): string {
    return this._title;
  }
  public setTitle(val: string): void {
    let isValChanged: boolean = this._title !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(CalendarEvent._TITLE, this._title);

    this._title = val;

    this.fire("title", this);
  }
  public get category(): string {
    return this._category;
  }
  public setCategory(val: string): void {
    let isValChanged: boolean = this._category !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(CalendarEvent._CATEGORY, this._category);

    this._category = val;

    this.fire("category", this);
  }
  public get allDay(): boolean {
    return this._allDay;
  }
  public setAllDay(val: boolean): void {
    let isValChanged: boolean = this._allDay !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(CalendarEvent._ALLDAY, this._allDay);

    this._allDay = val;

    this.fire("allDay", this);
  }
  public get startTime(): DateTime {
    return this._startTime;
  }
  public setStartTime(val: DateTime): void {
    let isValChanged: boolean = this._startTime !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(CalendarEvent._STARTTIME, this._startTime);

    this._startTime = val;

    this.fire("startTime", this);
  }
  public get endTime(): DateTime {
    return this._endTime;
  }
  public setEndTime(val: DateTime): void {
    let isValChanged: boolean = this._endTime !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(CalendarEvent._ENDTIME, this._endTime);

    this._endTime = val;

    this.fire("endTime", this);
  }
  public get description(): string {
    return this._description;
  }
  public setDescription(val: string): void {
    let isValChanged: boolean = this._description !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(CalendarEvent._DESCRIPTION, this._description);

    this._description = val;

    this.fire("description", this);
  }
  public get backgroundColor(): string {
    return this._backgroundColor;
  }
  public setBackgroundColor(val: string): void {
    let isValChanged: boolean = this._backgroundColor !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      CalendarEvent._BACKGROUNDCOLOR,
      this._backgroundColor
    );

    this._backgroundColor = val;

    this.fire("backgroundColor", this);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case CalendarEvent._DATE: {
        this.setDate(value as D3EDate);
        break;
      }

      case CalendarEvent._TITLE: {
        this.setTitle(value as string);
        break;
      }

      case CalendarEvent._CATEGORY: {
        this.setCategory(value as string);
        break;
      }

      case CalendarEvent._ALLDAY: {
        this.setAllDay(value as boolean);
        break;
      }

      case CalendarEvent._STARTTIME: {
        this.setStartTime(value as DateTime);
        break;
      }

      case CalendarEvent._ENDTIME: {
        this.setEndTime(value as DateTime);
        break;
      }

      case CalendarEvent._DESCRIPTION: {
        this.setDescription(value as string);
        break;
      }

      case CalendarEvent._BACKGROUNDCOLOR: {
        this.setBackgroundColor(value as string);
        break;
      }
    }
  }
  public get(field: number): any {
    switch (field) {
      case CalendarEvent._DATE: {
        return this._date;
      }

      case CalendarEvent._TITLE: {
        return this._title;
      }

      case CalendarEvent._CATEGORY: {
        return this._category;
      }

      case CalendarEvent._ALLDAY: {
        return this._allDay;
      }

      case CalendarEvent._STARTTIME: {
        return this._startTime;
      }

      case CalendarEvent._ENDTIME: {
        return this._endTime;
      }

      case CalendarEvent._DESCRIPTION: {
        return this._description;
      }

      case CalendarEvent._BACKGROUNDCOLOR: {
        return this._backgroundColor;
      }
      default: {
        return null;
      }
    }
  }
  public equals(other: any): boolean {
    return (
      this === other ||
      (other instanceof CalendarEvent &&
        this._date === other._date &&
        this._title === other._title &&
        this._category === other._category &&
        this._allDay === other._allDay &&
        this._startTime === other._startTime &&
        this._endTime === other._endTime &&
        this._description === other._description &&
        this._backgroundColor === other._backgroundColor)
    );
  }
  public get hashCode(): number {
    return (
      (this._date?.hashCode ?? 0) +
      (this._title?.hashCode ?? 0) +
      (this._category?.hashCode ?? 0) +
      (this._allDay?.hashCode ?? 0) +
      (this._startTime?.hashCode ?? 0) +
      (this._endTime?.hashCode ?? 0) +
      (this._description?.hashCode ?? 0) +
      (this._backgroundColor?.hashCode ?? 0)
    );
  }
}
