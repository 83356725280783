import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import PopupTargetController from "./PopupTargetController";
import Popup from "./Popup";
import Duration from "../core/Duration";
import Timer from "../utils/Timer";
import ToolTipMessage from "./ToolTipMessage";
import { StyleThemeData } from "./ThemeWrapper";

export interface ToolTipWrapperProps extends BaseUIProps {
  key?: string;
  message: string;
  backgroundColor?: ui.Color;
  textColor?: ui.Color;
  child?: ReactNode;
}

class _ToolTipWrapperState extends ObservableComponent<ToolTipWrapperProps> {
  static defaultProps = { message: "", backgroundColor: null, textColor: null };
  hasShow: boolean = true;
  backgroundColorValue: ui.Color = null;
  textColorValue: ui.Color = null;
  onCursorEnter: boolean = false;
  showTimer: Timer = null;
  hideTimer: Timer = null;
  toolTipMessagePopup: Popup;
  public idPopupTargetController: PopupTargetController =
    new PopupTargetController();
  public constructor(props: ToolTipWrapperProps) {
    super(props);

    this.initState();
  }
  public get message(): string {
    return this.props.message;
  }
  public get backgroundColor(): ui.Color {
    return this.props.backgroundColor;
  }
  public get textColor(): ui.Color {
    return this.props.textColor;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["backgroundColor"], this.computeBackgroundColorValue);

    this.computeBackgroundColorValue();

    this.on(["textColor"], this.computeTextColorValue);

    this.computeTextColorValue();
  }
  public componentDidUpdate(prevProps: ToolTipWrapperProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.message !== this.props.message) {
      this.fire("message", this);
    }

    if (prevProps.backgroundColor !== this.props.backgroundColor) {
      this.fire("backgroundColor", this);
    }

    if (prevProps.textColor !== this.props.textColor) {
      this.fire("textColor", this);
    }
  }
  public setHasShow(val: boolean): void {
    let isValChanged: boolean = this.hasShow !== val;

    if (!isValChanged) {
      return;
    }

    this.hasShow = val;

    this.fire("hasShow", this);
  }
  public setBackgroundColorValue(val: ui.Color): void {
    let isValChanged: boolean = this.backgroundColorValue !== val;

    if (!isValChanged) {
      return;
    }

    this.backgroundColorValue = val;

    this.fire("backgroundColorValue", this);
  }
  public computeBackgroundColorValue = (): void => {
    try {
      this.setBackgroundColorValue(
        this.backgroundColor !== null
          ? this.backgroundColor
          : ui.HexColor.fromHexInt(0xff64728c)
      );
    } catch (exception) {
      console.log(
        " exception in computeBackgroundColorValue : " + exception.toString()
      );

      this.setBackgroundColorValue(null);
    }
  };
  public setTextColorValue(val: ui.Color): void {
    let isValChanged: boolean = this.textColorValue !== val;

    if (!isValChanged) {
      return;
    }

    this.textColorValue = val;

    this.fire("textColorValue", this);
  }
  public computeTextColorValue = (): void => {
    try {
      this.setTextColorValue(
        this.textColor !== null
          ? this.textColor
          : ui.HexColor.fromHexInt(0xffffffff)
      );
    } catch (exception) {
      console.log(
        " exception in computeTextColorValue : " + exception.toString()
      );

      this.setTextColorValue(null);
    }
  };
  public setOnCursorEnter(val: boolean): void {
    let isValChanged: boolean = this.onCursorEnter !== val;

    if (!isValChanged) {
      return;
    }

    this.onCursorEnter = val;

    this.fire("onCursorEnter", this);
  }
  public setShowTimer(val: Timer): void {
    let isValChanged: boolean = this.showTimer !== val;

    if (!isValChanged) {
      return;
    }

    this.showTimer = val;

    this.fire("showTimer", this);
  }
  public setHideTimer(val: Timer): void {
    let isValChanged: boolean = this.hideTimer !== val;

    if (!isValChanged) {
      return;
    }

    this.hideTimer = val;

    this.fire("hideTimer", this);
  }
  public render(): ReactNode {
    return ui.Container({
      child: this.props.child,
      d3eRef: this.idPopupTargetController.handleRef,
      onTap: (e) => {
        e.stopPropagation();

        this.selectionHandler();
      },
      onEnter: (event) => {
        this.onEnterHandler(event);
      },
      onExit: (event) => {
        this.onExitHandler(event);
      },
      className: ui.join(this.props.className, "ToolTipWrapper"),
      ...copyBaseUIProps(this.props),
    });
  }
  public selectionHandler = (): void => {
    this.setHasShow(false);

    this.hideToolTipMessage();
  };
  public onEnterHandler = (event: ui.PointerEnterEvent): void => {
    this.setOnCursorEnter(true);

    this.setShowTimer(
      new Timer(new Duration({ milliseconds: 8 }), () => {
        if (
          this.onCursorEnter &&
          this.hasShow &&
          this.message !== null &&
          this.message.isNotEmpty
        ) {
          this.showToolTipMessage({ autoClose: true });
        }
      })
    );

    this.setHideTimer(
      new Timer(new Duration({ seconds: 6 }), () => {
        if (
          this.onCursorEnter &&
          this.hasShow &&
          this.message !== null &&
          this.message.isNotEmpty
        ) {
          this.hideToolTipMessage();
        }
      })
    );
  };
  public onExitHandler = (event: ui.PointerExitEvent): void => {
    this.setOnCursorEnter(false);

    this.hideToolTipMessage();

    this.setHasShow(true);
  };
  public onDispose = (): void => {
    if (this.showTimer !== null) {
      this.showTimer.cancel();
    }

    if (this.hideTimer !== null) {
      this.hideTimer.cancel();
    }
  };
  public get childPresent(): boolean {
    return this.props.child != null;
  }
  public get child(): ReactNode {
    return this.props.child;
  }
  public dispose(): void {
    this.toolTipMessagePopup?.dispose();

    super.dispose();

    this.onDispose();
  }
  public showToolTipMessage(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.toolTipMessagePopup?.dispose();

    /*
TODO ThemeWrapper.of(this.context);
*/

    let cStyle = StyleThemeData.current;

    let target: ui.Rect = this.idPopupTargetController.getTarget(this.context);

    this.toolTipMessagePopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Bottom,
      child: ui.Container({
        decoration: new ui.BoxDecoration({ color: new ui.Color(0xff64728c) }),
        padding: ui.EdgeInsets.symmetric({
          horizontal: 5.0,
          vertical: 0.0,
          transitions: new Map(),
        }),
        child: ToolTipMessage({ message: this.message, textColor: cStyle.c2 }),
        className: "xede hc vc",
      }),
      target: target,
    });

    this.toolTipMessagePopup.showPopup(this.context);
  }
  public hideToolTipMessage(): void {
    this.toolTipMessagePopup?.dispose();
  }
}
export default function ToolTipWrapper(props: ToolTipWrapperProps) {
  return React.createElement(_ToolTipWrapperState, {
    ..._ToolTipWrapperState.defaultProps,
    ...props,
  });
}
