import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import MaterialIcons from "../icons/MaterialIcons";
import IconView from "./IconView";

type _CheckboxOnChanged = (val: boolean) => void;

type _CheckBoxOnKey = (
  focusNode: ui.FocusNode,
  event: ui.RawKeyEvent,
  d3eState: CheckboxRefs
) => ui.KeyEventResult;

type _CheckBoxOnTap = (d3eState: CheckboxRefs) => void;

export interface CheckboxProps extends BaseUIProps {
  key?: string;
  value?: boolean;
  activeColor?: ui.Color;
  disable?: boolean;
  onChanged?: _CheckboxOnChanged;
}
/// To store state data for Checkbox
class CheckboxRefs {
  public checkBox: CheckBoxState = new CheckBoxState();
}

interface CheckBoxWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: CheckboxRefs;
  _onCangedPressed?: _CheckBoxOnTap;
  _onKey?: _CheckBoxOnKey;
  activeColor: ui.Color;
  disable: boolean;
  value: boolean;
}

class CheckBoxState extends ObjectObservable {
  private _focus: boolean = false;
  public get focus(): boolean {
    return this._focus;
  }
  public setFocus(val: boolean) {
    let isValChanged: boolean = this._focus !== val;

    if (!isValChanged) {
      return;
    }

    this._focus = val;

    this.fire("focus", this);
  }
}

class _CheckBoxWithState extends ObservableComponent<CheckBoxWithStateProps> {
  public constructor(props: CheckBoxWithStateProps) {
    super(props);

    this.initState();
  }
  public get activeColor(): ui.Color {
    return this.props.activeColor;
  }
  public get checkBox(): CheckBoxState {
    return this.props.d3eState.checkBox;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public get value(): boolean {
    return this.props.value;
  }
  public get d3eState(): CheckboxRefs {
    return this.props.d3eState;
  }
  public get _onCangedPressed(): _CheckBoxOnTap {
    return this.props._onCangedPressed;
  }
  public get _onKey(): _CheckBoxOnKey {
    return this.props._onKey;
  }
  public initState() {
    super.initState();

    this.updateObservable("checkBox", null, this.checkBox);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["activeColor", "checkBox", "checkBox.focus", "disable", "value"],
      this.rebuild
    );
  }
  public checkBoxOnFocusChange(val): void {
    return this.checkBox.setFocus(val);
  }
  public dispose(): void {
    this.checkBox.setFocus(false);

    super.dispose();
  }
  public render(): ReactNode {
    return ui.Focus({
      focusNode: new ui.FocusNode(),
      child: ui.Container({
        alignment: ui.Alignment.center,
        decoration: new ui.BoxDecoration({
          color: this.disable
            ? ui.HexColor.fromHexInt(0x61dddddd)
            : this.value && this.activeColor !== null
            ? this.activeColor
            : null,
          border: new ui.Border({
            top: new ui.BorderSide({
              color: this.checkBox.focus
                ? this.activeColor !== null
                  ? this.activeColor
                  : ui.HexColor.fromHexInt(0xffbfbfbf)
                : ui.HexColor.fromHexInt(0xffbfbfbf),
              width: 1.5,
              style: ui.BorderStyle.solid,
            }),
            left: new ui.BorderSide({
              color: this.checkBox.focus
                ? this.activeColor !== null
                  ? this.activeColor
                  : ui.HexColor.fromHexInt(0xffbfbfbf)
                : ui.HexColor.fromHexInt(0xffbfbfbf),
              width: 1.5,
              style: ui.BorderStyle.solid,
            }),
            right: new ui.BorderSide({
              color: this.checkBox.focus
                ? this.activeColor !== null
                  ? this.activeColor
                  : ui.HexColor.fromHexInt(0xffbfbfbf)
                : ui.HexColor.fromHexInt(0xffbfbfbf),
              width: 1.5,
              style: ui.BorderStyle.solid,
            }),
            bottom: new ui.BorderSide({
              color: this.checkBox.focus
                ? this.activeColor !== null
                  ? this.activeColor
                  : ui.HexColor.fromHexInt(0xffbfbfbf)
                : ui.HexColor.fromHexInt(0xffbfbfbf),
              width: 1.5,
              style: ui.BorderStyle.solid,
            }),
          }),
          borderRadius: ui.BorderRadiusExt.only({
            topLeft: ui.RadiusExt.elliptical({ x: 1.5, y: 1.5 }),
            topRight: ui.RadiusExt.elliptical({ x: 1.5, y: 1.5 }),
            bottomLeft: ui.RadiusExt.elliptical({ x: 1.5, y: 1.5 }),
            bottomRight: ui.RadiusExt.elliptical({ x: 1.5, y: 1.5 }),
          }),
        }),
        child: this.value
          ? IconView({
              icon: MaterialIcons.done,
              color:
                this.value && this.activeColor !== null
                  ? new ui.Color(0xffffffff)
                  : new ui.Color(0xff1b8cff),
              size: 16,
              states: ui.joinStates(
                { "data-c0": this.value && this.activeColor !== null },
                {}
              ),
              className: "x51 hc vc",
            })
          : ui.Container({ className: "hc vc" }),
        onTap: (e) => {
          e.stopPropagation();

          this._onCangedPressed(this.d3eState);
        },
        onFocusKey: (focusNode, event) => {
          return this._onKey(focusNode, event, this.d3eState);
        },
        className: "xa44 hc vc",
      }),
      onFocusKey: (focusNode, event) => {
        return this._onKey(focusNode, event, this.d3eState);
      },
      onFocusChange: (val) => {
        this.checkBoxOnFocusChange(val);
      },
    });
  }
}
function CheckBoxWithState(props: CheckBoxWithStateProps) {
  return React.createElement(_CheckBoxWithState, props);
}

class _CheckboxState extends ObservableComponent<CheckboxProps> {
  static defaultProps = {
    value: false,
    activeColor: null,
    disable: false,
    onChanged: null,
  };
  d3eState: CheckboxRefs = new CheckboxRefs();
  public constructor(props: CheckboxProps) {
    super(props);

    this.initState();
  }
  public get value(): boolean {
    return this.props.value;
  }
  public get activeColor(): ui.Color {
    return this.props.activeColor;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["activeColor", "disable", "value"], this.rebuild);
  }
  public componentDidUpdate(prevProps: CheckboxProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.activeColor !== this.props.activeColor) {
      this.fire("activeColor", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }
  }
  public render(): ReactNode {
    return ui.Container({
      alignment: ui.Alignment.center,
      child: CheckBoxWithState({
        d3eState: this.d3eState,
        _onCangedPressed: this.onCangedPressed,
        _onKey: this.onKey,
        activeColor: this.activeColor,
        disable: this.disable,
        value: this.value,
      }),
      className: ui.join(this.props.className, "Checkbox xea"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onCangedPressed = (d3eState: CheckboxRefs): void => {
    if (!this.disable) {
      this.onChanged(!this.value);
    }
  };
  public onKey = (
    focusNode: ui.FocusNode,
    event: ui.RawKeyEvent,
    d3eState: CheckboxRefs
  ): ui.KeyEventResult => {
    if (event instanceof ui.RawKeyDownEvent && !this.disable) {
      if (
        event.logicalKey === ui.LogicalKeyboardKey.enter ||
        event.logicalKey === ui.LogicalKeyboardKey.space ||
        event.logicalKey === ui.LogicalKeyboardKey.numpadEnter
      ) {
        if (!this.disable) {
          this.onChanged(!this.value);

          return ui.KeyEventResult.handled;
        }

        return ui.KeyEventResult.handled;
      }
    }

    return ui.KeyEventResult.ignored;
  };
  public get onChanged(): _CheckboxOnChanged {
    return this.props.onChanged;
  }
  public get checkBox() {
    return this.d3eState.checkBox;
  }
}
export default function Checkbox(props: CheckboxProps) {
  return React.createElement(_CheckboxState, {
    ..._CheckboxState.defaultProps,
    ...props,
  });
}
