import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class FacilityUsageCategory extends DBObject {
  private static readonly _DESCRIPTION: number = 0;
  private static readonly _NAME: number = 1;
  public id: number = 0;
  public otherMaster: DBObject;
  private _name: string = "";
  private _description: string = "";
  public constructor(
    d3eParams?: Partial<{ description: string; name: string }>
  ) {
    super();

    this.setDescription(d3eParams?.description ?? "");

    this.setName(d3eParams?.name ?? "");
  }
  public get d3eType(): string {
    return "FacilityUsageCategory";
  }
  public toString(): string {
    return this.name;
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get name(): string {
    return this._name;
  }
  public setName(val: string): void {
    let isValChanged: boolean = this._name !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(FacilityUsageCategory._NAME, this._name);

    this._name = val;

    this.fire("name", this);
  }
  public get description(): string {
    return this._description;
  }
  public setDescription(val: string): void {
    let isValChanged: boolean = this._description !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      FacilityUsageCategory._DESCRIPTION,
      this._description
    );

    this._description = val;

    this.fire("description", this);
  }
  public get(field: number): any {
    switch (field) {
      case FacilityUsageCategory._NAME: {
        return this._name;
      }

      case FacilityUsageCategory._DESCRIPTION: {
        return this._description;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): FacilityUsageCategory {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: FacilityUsageCategory = dbObj as FacilityUsageCategory;

    obj.id = this.id;

    obj.setName(this._name);

    obj.setDescription(this._description);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case FacilityUsageCategory._NAME: {
        this.setName(value as string);
        break;
      }

      case FacilityUsageCategory._DESCRIPTION: {
        this.setDescription(value as string);
        break;
      }
    }
  }
}
