import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ListWrapper from "../utils/ListWrapper";
import D3EDate from "../classes/D3EDate";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import Time from "../classes/Time";
import IconButton from "./IconButton";
import CollectionUtils from "../utils/CollectionUtils";
import DateFormat from "../classes/DateFormat";
import { BuildContext } from "../classes/BuildContext";

type _TimeSelectionViewOnSelectedTime = (time: Time) => void;

export interface TimeSelectionViewProps extends BaseUIProps {
  key?: string;
  date?: D3EDate;
  format?: string;
  blockedSlots?: Array<Time>;
  _blockedSlotsHash?: number;
  onSelectedTime?: _TimeSelectionViewOnSelectedTime;
}

class _TimeSelectionViewState extends ObservableComponent<TimeSelectionViewProps> {
  static defaultProps = {
    date: null,
    format: "hh:mm a",
    blockedSlots: [],
    onSelectedTime: null,
  };
  blockedTimes: Array<Time> = ListWrapper.widget(this, "blockedTimes");
  times: Array<Time> = ListWrapper.widget(this, "times");
  formatter: DateFormat = null;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: TimeSelectionViewProps) {
    super(props);

    this.initState();
  }
  public get date(): D3EDate {
    return this.props.date;
  }
  public get format(): string {
    return this.props.format;
  }
  public get blockedSlots(): Array<Time> {
    return this.props.blockedSlots;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.subscribeToList(this.blockedSlots, "blockedSlots");

    this.on(["format"], this.computeFormatter);

    this.computeFormatter();

    this.on(["blockedTimes", "date", "formatter", "times"], this.rebuild);
  }
  public componentDidUpdate(prevProps: TimeSelectionViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.date !== this.props.date) {
      this.fire("date", this);
    }

    if (prevProps.format !== this.props.format) {
      this.fire("format", this);
    }

    if (prevProps.blockedSlots !== this.props.blockedSlots) {
      this.fire("blockedSlots", this);
    }
  }
  public setBlockedTimes(val: Array<Time>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.blockedTimes,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.blockedTimes.clear();

    this.blockedTimes.addAll(val);

    this.fire("blockedTimes", this);
  }
  public addToBlockedTimes(val: Time, index: number = -1): void {
    if (index === -1) {
      if (!this.blockedTimes.contains(val)) this.blockedTimes.add(val);
    } else {
      this.blockedTimes.remove(this.blockedTimes.elementAt(index));

      this.blockedTimes.add(val);
    }

    this.fire("blockedTimes", this, val, true);
  }
  public removeFromBlockedTimes(val: Time): void {
    this.blockedTimes.remove(val);

    this.fire("blockedTimes", this, val, false);
  }
  public setTimes(val: Array<Time>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.times, val);

    if (!isValChanged) {
      return;
    }

    this.times.clear();

    this.times.addAll(val);

    this.fire("times", this);
  }
  public addToTimes(val: Time, index: number = -1): void {
    if (index === -1) {
      if (!this.times.contains(val)) this.times.add(val);
    } else {
      this.times.remove(this.times.elementAt(index));

      this.times.add(val);
    }

    this.fire("times", this, val, true);
  }
  public removeFromTimes(val: Time): void {
    this.times.remove(val);

    this.fire("times", this, val, false);
  }
  public setFormatter(val: DateFormat): void {
    let isValChanged: boolean = this.formatter !== val;

    if (!isValChanged) {
      return;
    }

    this.formatter = val;

    this.fire("formatter", this);
  }
  public computeFormatter = (): void => {
    try {
      this.setFormatter(new DateFormat(this.format));
    } catch (exception) {
      console.log(" exception in computeFormatter : " + exception.toString());

      this.setFormatter(null);
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      mainAxisSize: ui.MainAxisSize.min,
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [
            TextView({
              data: "Select Slot",
              style: new ui.TextStyle({ color: cStyle.c2 }),
              className: "x0be",
              key: "0",
            }),
          ],
          className: "xd57 hc h",
          key: "0",
        }),
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            IconButton({
              icon: MaterialIcons.arrow_back_ios,
              size: 20,
              onPressed: () => {
                this.previousButtonHandler();
              },
              key: "0",
            }),
            TextView({ data: this.date.toString(), key: "1" }),
            IconButton({
              icon: MaterialIcons.arrow_forward_ios,
              size: 20,
              onPressed: () => {
                this.nextButtonHandler();
              },
              key: "2",
            }),
          ],
          className: "x0f9 hc h",
          key: "1",
        }),
        ui.Wrap({
          children: [
            this.times.expand((slot) => [
              TextView({
                data: this.formatter.format(slot.toDateTime()),
                states: ui.joinStates(
                  { "data-c0": this.blockedTimes.contains(slot) },
                  {}
                ),
                style: new ui.TextStyle({ fontSize: 13 }),
                className: "xcb6 hc",
                key: slot?.toString(),
                onTap: (e) => {
                  e.stopPropagation();

                  this.onTapHandler(slot);
                },
              }),
            ]),
          ],
          className: "hc",
          key: "2",
        }),
      ],
      className: ui.join(this.props.className, "TimeSelectionView x94a7 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    let t: Time = Time.of(0, 0, 0, 0);

    let i: number = 0;

    let start: number =
      this.blockedSlots.length > i ? this.blockedSlots[i].toMillOfDay() : 0;

    i++;

    let end: number =
      this.blockedSlots.length > i ? this.blockedSlots[i].toMillOfDay() : -1;

    while (true) {
      let mt: number = t.toMillOfDay();

      if (mt >= start && mt <= end) {
        this.blockedTimes.add(t);
      }

      if (mt >= end) {
        i++;

        start =
          this.blockedSlots.length > i ? this.blockedSlots[i].toMillOfDay() : 0;

        i++;

        end =
          this.blockedSlots.length > i
            ? this.blockedSlots[i].toMillOfDay()
            : -1;
      }

      this.times.add(t);

      t = t.plusMinutes(30);

      if (t.hour === 0 && t.minutes === 0) {
        break;
      }
    }
  };
  public previousButtonHandler = (): void => {};
  public nextButtonHandler = (): void => {};
  public onTapHandler = (slot: Time): void => {
    if (this.onSelectedTime !== null) {
      if (!this.blockedTimes.contains(slot)) {
        this.onSelectedTime(slot);
      }
    }
  };
  public get onSelectedTime(): _TimeSelectionViewOnSelectedTime {
    return this.props.onSelectedTime;
  }
}
export default function TimeSelectionView(props: TimeSelectionViewProps) {
  return React.createElement(
    _TimeSelectionViewState,
    { ..._TimeSelectionViewState.defaultProps, ...props },
    ListWrapper.fromInput<Time>(props.blockedSlots, "blockedSlots")
  );
}
