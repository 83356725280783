import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ListWrapper from "../utils/ListWrapper";
import School from "../models/School";
import SchoolMapPin from "./SchoolMapPin";
import GoogleMap from "./GoogleMap";
import GoogleMapMarker from "./GoogleMapMarker";

export interface SchoolsMapViewProps extends BaseUIProps {
  key?: string;
  schools: Array<School>;
  _schoolsHash?: number;
}

class _SchoolsMapViewState extends ObservableComponent<SchoolsMapViewProps> {
  static defaultProps = { schools: [] };
  public constructor(props: SchoolsMapViewProps) {
    super(props);

    this.initState();
  }
  public get schools(): Array<School> {
    return this.props.schools;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.schools, "schools");

    this.updateSyncCollProperty("schools", this.props.schools);

    this.on(["schools", "schools.latitude", "schools.longitude"], this.rebuild);
  }
  public componentDidUpdate(prevProps: SchoolsMapViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.schools !== this.props.schools) {
      this.updateObservableColl(
        "schools",
        prevProps.schools,
        this.props.schools
      );

      this.fire("schools", this);
    }
  }
  public render(): ReactNode {
    return GoogleMap({
      children: [
        this.schools.expand((s) => [
          GoogleMapMarker({
            lat: Number.parseFloat(s.latitude),
            lng: Number.parseFloat(s.longitude),
            child: SchoolMapPin({ school: s, className: "hc" }),
            className: "hc",
            key: s?.ident,
          }),
        ]),
      ],
      className: ui.join(this.props.className, "SchoolsMapView hc"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function SchoolsMapView(props: SchoolsMapViewProps) {
  return React.createElement(
    _SchoolsMapViewState,
    { ..._SchoolsMapViewState.defaultProps, ...props },
    ListWrapper.fromInput<School>(props.schools, "schools")
  );
}
