import { KeyboardKey } from "./KeyboardKey";

export class LogicalKeyboardKey extends KeyboardKey {
  static enter: LogicalKeyboardKey;
  static space: LogicalKeyboardKey;
  static numpadEnter: LogicalKeyboardKey;
  static arrowDown: LogicalKeyboardKey;
  static arrowUp: LogicalKeyboardKey;
  static escape: LogicalKeyboardKey;
  static backspace: LogicalKeyboardKey;
  static tab: LogicalKeyboardKey;
  static minus: LogicalKeyboardKey;
  static equal: LogicalKeyboardKey;
  static delete: LogicalKeyboardKey;
  static end: LogicalKeyboardKey;
  static arrowRight: LogicalKeyboardKey;
  static arrowLeft: LogicalKeyboardKey;
  static controlLeft: LogicalKeyboardKey;
  static shiftLeft: LogicalKeyboardKey;
  static altLeft: LogicalKeyboardKey;
  static metaLeft: LogicalKeyboardKey;
  static controlRight: LogicalKeyboardKey;
  static shiftRight: LogicalKeyboardKey;
  static altRight: LogicalKeyboardKey;
  static metaRight: LogicalKeyboardKey;
  static save: LogicalKeyboardKey;
  static redo: LogicalKeyboardKey;
  static shift: LogicalKeyboardKey;
  static meta: LogicalKeyboardKey;
  static alt: LogicalKeyboardKey;
  static control: LogicalKeyboardKey;
  static zoomIn: LogicalKeyboardKey;
  static zoomOut: LogicalKeyboardKey;
  static zoomToggle: LogicalKeyboardKey;
  static close: LogicalKeyboardKey;
  static copy: LogicalKeyboardKey;
  static cut: LogicalKeyboardKey;
  static undo: LogicalKeyboardKey;
  static again: LogicalKeyboardKey;
  static select: LogicalKeyboardKey;
  static open: LogicalKeyboardKey;
  static home: LogicalKeyboardKey;
  static insert: LogicalKeyboardKey;
  static capsLock: LogicalKeyboardKey;
  static slash: LogicalKeyboardKey;
  static f1: LogicalKeyboardKey;
  static f2: LogicalKeyboardKey;
  static f3: LogicalKeyboardKey;
  static f4: LogicalKeyboardKey;
  static f5: LogicalKeyboardKey;
  static f6: LogicalKeyboardKey;
  static f7: LogicalKeyboardKey;
  static f8: LogicalKeyboardKey;
  static f9: LogicalKeyboardKey;
  static f10: LogicalKeyboardKey;
  static f11: LogicalKeyboardKey;
  static f12: LogicalKeyboardKey;
  static keyA: LogicalKeyboardKey;
  static keyB: LogicalKeyboardKey;
  static keyC: LogicalKeyboardKey;
  static keyD: LogicalKeyboardKey;
  static keyE: LogicalKeyboardKey;
  static keyF: LogicalKeyboardKey;
  static keyG: LogicalKeyboardKey;
  static keyH: LogicalKeyboardKey;
  static keyI: LogicalKeyboardKey;
  static keyJ: LogicalKeyboardKey;
  static keyK: LogicalKeyboardKey;
  static keyL: LogicalKeyboardKey;
  static keyM: LogicalKeyboardKey;
  static keyN: LogicalKeyboardKey;
  static keyO: LogicalKeyboardKey;
  static keyP: LogicalKeyboardKey;
  static keyQ: LogicalKeyboardKey;
  static keyR: LogicalKeyboardKey;
  static keyS: LogicalKeyboardKey;
  static keyT: LogicalKeyboardKey;
  static keyU: LogicalKeyboardKey;
  static keyV: LogicalKeyboardKey;
  static keyW: LogicalKeyboardKey;
  static keyX: LogicalKeyboardKey;
  static keyY: LogicalKeyboardKey;
  static keyZ: LogicalKeyboardKey;
  static digit1: LogicalKeyboardKey;
  static digit2: LogicalKeyboardKey;
  static digit3: LogicalKeyboardKey;
  static digit4: LogicalKeyboardKey;
  static digit5: LogicalKeyboardKey;
  static digit6: LogicalKeyboardKey;
  static digit7: LogicalKeyboardKey;
  static digit8: LogicalKeyboardKey;
  static digit9: LogicalKeyboardKey;
  static digit0: LogicalKeyboardKey;
  static pageUp: LogicalKeyboardKey;
  static pageDown: LogicalKeyboardKey;
  static bracketLeft: LogicalKeyboardKey;
  static bracketRight: LogicalKeyboardKey;
  static numpadAdd: LogicalKeyboardKey;
  static numpadSubtract: LogicalKeyboardKey;
  static quote: LogicalKeyboardKey;
  static comma: LogicalKeyboardKey;
  static period: LogicalKeyboardKey;
  static exclamation: LogicalKeyboardKey;
  static at: LogicalKeyboardKey;
  static numberSign: LogicalKeyboardKey;
  static dollar: LogicalKeyboardKey;
}
