import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ListWrapper from "../utils/ListWrapper";
import TextView from "./TextView";
import GSDRMSFieldType from "../classes/GSDRMSFieldType";
import { BuildContext } from "../classes/BuildContext";

type _LabelWithStringFieldOnChanged = (value: string) => void;

type _LabelWithStringFieldOnTap = () => void;

type _LabelWithStringFieldOnSubmitted = (value: string) => void;

type _LabelWithStringFieldOnEditingComplete = () => void;

export interface LabelWithStringFieldProps extends BaseUIProps {
  key?: string;
  name?: string;
  placeHolder?: string;
  isRequired?: boolean;
  value?: string;
  errors?: Array<string>;
  disable?: boolean;
  fieldType?: GSDRMSFieldType;
  activeColor?: ui.Color;
  inActiveColor?: ui.Color;
  fieldColor?: ui.Color;
  dense?: boolean;
  _errorsHash?: number;
  onChanged?: _LabelWithStringFieldOnChanged;
  onTap?: _LabelWithStringFieldOnTap;
  onSubmitted?: _LabelWithStringFieldOnSubmitted;
  onEditingComplete?: _LabelWithStringFieldOnEditingComplete;
}

class _LabelWithStringFieldState extends ObservableComponent<LabelWithStringFieldProps> {
  static defaultProps = {
    name: "",
    placeHolder: "",
    isRequired: false,
    value: "",
    disable: false,
    fieldType: GSDRMSFieldType.Rectangle,
    activeColor: null,
    inActiveColor: null,
    fieldColor: null,
    dense: false,
    errors: [],
    onChanged: null,
    onTap: null,
    onSubmitted: null,
    onEditingComplete: null,
  };
  fieldController: ui.TextEditingController = new ui.TextEditingController();
  focusNode: ui.FocusNode = null;
  active: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LabelWithStringFieldProps) {
    super(props);

    this.initState();
  }
  public get name(): string {
    return this.props.name;
  }
  public get placeHolder(): string {
    return this.props.placeHolder;
  }
  public get isRequired(): boolean {
    return this.props.isRequired;
  }
  public get value(): string {
    return this.props.value;
  }
  public get errors(): Array<string> {
    return this.props.errors;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public get fieldType(): GSDRMSFieldType {
    return this.props.fieldType;
  }
  public get activeColor(): ui.Color {
    return this.props.activeColor;
  }
  public get inActiveColor(): ui.Color {
    return this.props.inActiveColor;
  }
  public get fieldColor(): ui.Color {
    return this.props.fieldColor;
  }
  public get dense(): boolean {
    return this.props.dense;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.subscribeToList(this.errors, "errors");

    this.on(
      [
        "active",
        "activeColor",
        "dense",
        "disable",
        "errors",
        "fieldColor",
        "fieldType",
        "focusNode",
        "inActiveColor",
        "isRequired",
        "name",
        "placeHolder",
        "value",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: LabelWithStringFieldProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.name !== this.props.name) {
      this.fire("name", this);
    }

    if (prevProps.placeHolder !== this.props.placeHolder) {
      this.fire("placeHolder", this);
    }

    if (prevProps.isRequired !== this.props.isRequired) {
      this.fire("isRequired", this);
    }

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.errors !== this.props.errors) {
      this.fire("errors", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }

    if (prevProps.fieldType !== this.props.fieldType) {
      this.fire("fieldType", this);
    }

    if (prevProps.activeColor !== this.props.activeColor) {
      this.fire("activeColor", this);
    }

    if (prevProps.inActiveColor !== this.props.inActiveColor) {
      this.fire("inActiveColor", this);
    }

    if (prevProps.fieldColor !== this.props.fieldColor) {
      this.fire("fieldColor", this);
    }

    if (prevProps.dense !== this.props.dense) {
      this.fire("dense", this);
    }
  }
  public setFocusNode(val: ui.FocusNode): void {
    let isValChanged: boolean = this.focusNode !== val;

    if (!isValChanged) {
      return;
    }

    this.focusNode = val;

    this.fire("focusNode", this);
  }
  public setActive(val: boolean): void {
    let isValChanged: boolean = this.active !== val;

    if (!isValChanged) {
      return;
    }

    this.active = val;

    this.fire("active", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          children: [
            this.name !== null && this.name.isNotEmpty
              ? TextView({
                  data: this.name,
                  style: new ui.TextStyle({
                    fontFamily: "Open Sans",
                    fontSize: 14,
                  }),
                  className: "x1aa",
                })
              : [],
            this.isRequired
              ? TextView({
                  data: "*",
                  style: new ui.TextStyle({ color: new ui.Color(0xfff51f2a) }),
                  className: "xf58",
                })
              : [],
          ],
          className: "xe7 hc h",
          key: "0",
        }),
        ui.Container({
          decoration:
            this.fieldType === GSDRMSFieldType.Underline &&
            !this.active &&
            this.inActiveColor === null
              ? new ui.BoxDecoration({
                  border: new ui.Border({
                    bottom: new ui.BorderSide({
                      color: new ui.Color(0xffdcdcdc),
                      width: 1,
                      style: ui.BorderStyle.solid,
                    }),
                  }),
                })
              : this.fieldType === GSDRMSFieldType.Underline &&
                !this.active &&
                this.inActiveColor !== null
              ? new ui.BoxDecoration({
                  border: new ui.Border({
                    bottom: new ui.BorderSide({
                      color: this.inActiveColor,
                      width: 1,
                      style: ui.BorderStyle.solid,
                    }),
                  }),
                })
              : this.fieldType === GSDRMSFieldType.Underline &&
                this.active &&
                this.activeColor === null
              ? new ui.BoxDecoration({
                  border: new ui.Border({
                    bottom: new ui.BorderSide({
                      color: new ui.Color(0xffdcdcdc),
                      width: 1,
                      style: ui.BorderStyle.solid,
                    }),
                  }),
                })
              : this.fieldType === GSDRMSFieldType.Underline &&
                this.active &&
                this.activeColor !== null
              ? new ui.BoxDecoration({
                  border: new ui.Border({
                    bottom: new ui.BorderSide({
                      color: this.activeColor,
                      width: 1,
                      style: ui.BorderStyle.solid,
                    }),
                  }),
                })
              : null,
          child: ui.InputField({
            value: this.value,
            focusNode: this.focusNode,
            placeHolder: this.placeHolder,
            disable: this.disable,
            style: new ui.TextStyle({
              fontSize: 14,
              fontWeight: ui.FontWeight.w500,
              decorationStyle: ui.TextDecorationStyle.solid,
            }),
            inActiveColor:
              this.fieldType === GSDRMSFieldType.Circle &&
              !this.active &&
              this.inActiveColor !== null
                ? this.inActiveColor
                : this.fieldType === GSDRMSFieldType.Rectangle &&
                  !this.active &&
                  this.inActiveColor !== null
                ? this.inActiveColor
                : this.inActiveColor,
            activeColor:
              this.fieldType === GSDRMSFieldType.Circle &&
              this.active &&
              this.activeColor !== null
                ? this.activeColor
                : this.fieldType === GSDRMSFieldType.Rectangle &&
                  this.active &&
                  this.activeColor !== null
                ? this.activeColor
                : new ui.Color(0x0),
            backgroundColor:
              this.fieldColor === null
                ? new ui.Color(0xffffffff)
                : this.fieldColor,
            cornerRadius:
              this.fieldType === GSDRMSFieldType.Circle &&
              this.active &&
              this.activeColor === null
                ? 3
                : this.fieldType === GSDRMSFieldType.Circle &&
                  this.active &&
                  this.activeColor !== null
                ? 3
                : this.fieldType === GSDRMSFieldType.Circle &&
                  !this.active &&
                  this.inActiveColor === null
                ? 3
                : this.fieldType === GSDRMSFieldType.Circle &&
                  !this.active &&
                  this.inActiveColor !== null
                ? 3
                : 3,
            dense: this.dense,
            padding: ui.EdgeInsets.all(10.0, new Map()),
            controller: this.fieldController,
            onChanged: (text) => {
              this.onChangeText(text);
            },
            onEditingComplete: () => {
              this.onEditingTextHandler();
            },
            onSubmitted: (text) => {
              this.onSubmiitedTextHandler(text);
            },
            onFocusChange: (val) => {},
            states: ui.joinStates(
              {
                "data-c0":
                  this.fieldType === GSDRMSFieldType.Circle &&
                  this.active &&
                  this.activeColor === null,
                "data-c2":
                  this.fieldType === GSDRMSFieldType.Circle &&
                  !this.active &&
                  this.inActiveColor === null,
                "data-c4":
                  this.fieldType === GSDRMSFieldType.Underline &&
                  !this.active &&
                  this.inActiveColor === null,
                "data-c6":
                  this.fieldType === GSDRMSFieldType.Underline &&
                  this.active &&
                  this.activeColor === null,
                "data-c8":
                  this.fieldType === GSDRMSFieldType.Rectangle &&
                  this.active &&
                  this.activeColor === null,
                "data-c10":
                  this.fieldType === GSDRMSFieldType.Rectangle &&
                  !this.active &&
                  this.inActiveColor === null,
              },
              {}
            ),
          }),
          key: "1",
          className: "x574 hc h",
          states: ui.joinStates(
            {
              "data-c0":
                this.fieldType === GSDRMSFieldType.Circle &&
                this.active &&
                this.activeColor === null,
              "data-c2":
                this.fieldType === GSDRMSFieldType.Circle &&
                !this.active &&
                this.inActiveColor === null,
              "data-c4":
                this.fieldType === GSDRMSFieldType.Underline &&
                !this.active &&
                this.inActiveColor === null,
              "data-c6":
                this.fieldType === GSDRMSFieldType.Underline &&
                this.active &&
                this.activeColor === null,
              "data-c8":
                this.fieldType === GSDRMSFieldType.Rectangle &&
                this.active &&
                this.activeColor === null,
              "data-c10":
                this.fieldType === GSDRMSFieldType.Rectangle &&
                !this.active &&
                this.inActiveColor === null,
            },
            {}
          ),
        }),
        this.errors.isNotEmpty
          ? ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                this.errors.expand((item) => [
                  TextView({
                    data: item,
                    textAlign: cStyle.tTextViewErrorTextAlignOn,
                    style: new ui.TextStyle({
                      fontSize: 14,
                      color: cStyle.tTextViewErrorColorOn,
                    }),
                    className: "x961 hc",
                    key: item?.toString(),
                  }),
                ]),
              ],
              className: "hc",
            })
          : [],
      ],
      className: ui.join(
        this.props.className,
        "LabelWithStringField x3e3 hc h"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setFocusNode(new ui.FocusNode());

    this.focusNode.addListener(this.onChangeFocus);
  };
  public onChangeFocus = (): void => {
    this.setActive(this.focusNode.hasFocus);

    if (this.active && this.onTap !== null) {
      this.onTap();
    }
  };
  public onChangeText = (text: string): void => {
    if (this.onChanged !== null) {
      this.onChanged(text);
    }
  };
  public onSubmiitedTextHandler = (text: string): void => {
    if (this.onSubmitted !== null) {
      this.onSubmitted(text);
    }
  };
  public onEditingTextHandler = (): void => {
    if (this.onEditingComplete !== null) {
      this.onEditingComplete();
    }
  };
  public get onChanged(): _LabelWithStringFieldOnChanged {
    return this.props.onChanged;
  }
  public get onTap(): _LabelWithStringFieldOnTap {
    return this.props.onTap;
  }
  public get onSubmitted(): _LabelWithStringFieldOnSubmitted {
    return this.props.onSubmitted;
  }
  public get onEditingComplete(): _LabelWithStringFieldOnEditingComplete {
    return this.props.onEditingComplete;
  }
}
export default function LabelWithStringField(props: LabelWithStringFieldProps) {
  return React.createElement(
    _LabelWithStringFieldState,
    { ..._LabelWithStringFieldState.defaultProps, ...props },
    ListWrapper.fromInput<string>(props.errors, "errors")
  );
}
