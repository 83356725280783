import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ListWrapper from "../utils/ListWrapper";
import CheckboxWithText from "./CheckboxWithText";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

type _EventCategorySelectionViewOnFilterChanged = (
  selectedItems: Array<string>
) => void;

export interface EventCategorySelectionViewProps extends BaseUIProps {
  key?: string;
  items: Array<string>;
  selectedItems?: Array<string>;
  _itemsHash?: number;
  _selectedItemsHash?: number;
  onFilterChanged: _EventCategorySelectionViewOnFilterChanged;
}

class _EventCategorySelectionViewState extends ObservableComponent<EventCategorySelectionViewProps> {
  static defaultProps = { items: [], selectedItems: [], onFilterChanged: null };
  hackValue: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: EventCategorySelectionViewProps) {
    super(props);

    this.initState();
  }
  public get items(): Array<string> {
    return this.props.items;
  }
  public get selectedItems(): Array<string> {
    return this.props.selectedItems;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.items, "items");

    this.subscribeToList(this.selectedItems, "selectedItems");

    this.on(["hackValue", "items", "selectedItems"], this.rebuild);
  }
  public componentDidUpdate(prevProps: EventCategorySelectionViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.items !== this.props.items) {
      this.fire("items", this);
    }

    if (prevProps.selectedItems !== this.props.selectedItems) {
      this.fire("selectedItems", this);
    }
  }
  public setHackValue(val: boolean): void {
    let isValChanged: boolean = this.hackValue !== val;

    if (!isValChanged) {
      return;
    }

    this.hackValue = val;

    this.fire("hackValue", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      child: ui.Column({
        mainAxisAlignment: ui.MainAxisAlignment.start,
        crossAxisAlignment: ui.CrossAxisAlignment.start,
        children: [
          TextView({
            data: this.hackValue.toString(),
            states: ui.joinStates({ "data-visibility": false }, {}),
            className: "x03d hc",
            key: "0",
          }),
          this.items.expand((categoryItem) => [
            ui.Container({
              margin: ui.EdgeInsets.all(5.0, new Map()),
              child: CheckboxWithText({
                value: this.selectedItems.contains(categoryItem),
                title: categoryItem,
                onChanged: (value) => {
                  this.onCategoryItemChanged(value, categoryItem);
                },
                key: categoryItem.toString(),
              }),
              key: categoryItem?.toString(),
              className: "x322 hc h",
            }),
          ]),
        ],
        className: "x646 hc vc h v",
      }),
      className: ui.join(
        this.props.className,
        "EventCategorySelectionView xec4 hc vc"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onCategoryItemChanged = (
    value: boolean,
    categoryItem: string
  ): void => {
    if (value && !this.selectedItems.contains(categoryItem)) {
      this.selectedItems.add(categoryItem);

      this.onFilterChanged(this.selectedItems);
    } else {
      this.selectedItems.remove(categoryItem);

      this.onFilterChanged(this.selectedItems);
    }

    this.setHackValue(!this.hackValue);
  };
  public get onFilterChanged(): _EventCategorySelectionViewOnFilterChanged {
    return this.props.onFilterChanged;
  }
}
export default function EventCategorySelectionView(
  props: EventCategorySelectionViewProps
) {
  return React.createElement(
    _EventCategorySelectionViewState,
    { ..._EventCategorySelectionViewState.defaultProps, ...props },
    ListWrapper.fromInput<string>(props.items, "items"),
    ListWrapper.fromInput<string>(props.selectedItems, "selectedItems")
  );
}
