import { CrossAxisAlignment } from "./CrossAxisAlignment";
import { MainAxisAlignment } from "./MainAxisAlignment";

export default class CSSHelper {
  static handleMainAxisAlignment(maa?: MainAxisAlignment): string[] {
    let classNames = [];
    if (maa) {
      switch (maa) {
        case MainAxisAlignment.start:
          break;
        case MainAxisAlignment.end:
          classNames.push("ma-e");
          break;
        case MainAxisAlignment.center:
          classNames.push("ma-c");
          break;
        case MainAxisAlignment.spaceAround:
          classNames.push("ma-sa");
          break;
        case MainAxisAlignment.spaceBetween:
          classNames.push("ma-sb");
          break;
        case MainAxisAlignment.spaceEvenly:
          classNames.push("ma-se");
          break;
      }
    }
    return classNames;
  }

  static handleCrossAxisAlignment(caa?: CrossAxisAlignment): string[] {
    let classNames = [];
    if (caa) {
      switch (caa) {
        case CrossAxisAlignment.start:
          classNames.push("cr-s");
          break;
        case CrossAxisAlignment.end:
          classNames.push("cr-e");
          break;
        case CrossAxisAlignment.center:
          break;
        case CrossAxisAlignment.stretch:
          classNames.push("cr-st");
          break;
        case CrossAxisAlignment.baseline:
          classNames.push("cr-b");
          break;
      }
    }
    return classNames;
  }
}
