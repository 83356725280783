import * as ui from "../native";

export default class MaterialIcons {
  public static readonly icon_10k: ui.IconData = new ui.IconData(59729, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_10mp: ui.IconData = new ui.IconData(59730, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_11mp: ui.IconData = new ui.IconData(59731, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_123: ui.IconData = new ui.IconData(60301, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_12mp: ui.IconData = new ui.IconData(59732, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_13mp: ui.IconData = new ui.IconData(59733, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_14mp: ui.IconData = new ui.IconData(59734, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_15mp: ui.IconData = new ui.IconData(59735, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_16mp: ui.IconData = new ui.IconData(59736, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_17mp: ui.IconData = new ui.IconData(59737, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_18mp: ui.IconData = new ui.IconData(59738, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_19mp: ui.IconData = new ui.IconData(59739, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_1k: ui.IconData = new ui.IconData(59740, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_1k_plus: ui.IconData = new ui.IconData(59741, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_1x_mobiledata: ui.IconData = new ui.IconData(
    61389,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly icon_20mp: ui.IconData = new ui.IconData(59742, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_21mp: ui.IconData = new ui.IconData(59743, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_22mp: ui.IconData = new ui.IconData(59744, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_23mp: ui.IconData = new ui.IconData(59745, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_24mp: ui.IconData = new ui.IconData(59746, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_2k: ui.IconData = new ui.IconData(59747, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_2k_plus: ui.IconData = new ui.IconData(59748, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_2mp: ui.IconData = new ui.IconData(59749, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_30fps: ui.IconData = new ui.IconData(61390, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_30fps_select: ui.IconData = new ui.IconData(
    61391,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly icon_360: ui.IconData = new ui.IconData(58743, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_3d_rotation: ui.IconData = new ui.IconData(
    59469,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly icon_3g_mobiledata: ui.IconData = new ui.IconData(
    61392,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly icon_3k: ui.IconData = new ui.IconData(59750, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_3k_plus: ui.IconData = new ui.IconData(59751, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_3mp: ui.IconData = new ui.IconData(59752, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_3p: ui.IconData = new ui.IconData(61393, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_4g_mobiledata: ui.IconData = new ui.IconData(
    61394,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly icon_4g_plus_mobiledata: ui.IconData = new ui.IconData(
    61395,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly icon_4k: ui.IconData = new ui.IconData(57458, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_4k_plus: ui.IconData = new ui.IconData(59753, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_4mp: ui.IconData = new ui.IconData(59754, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_5g: ui.IconData = new ui.IconData(61240, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_5k: ui.IconData = new ui.IconData(59755, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_5k_plus: ui.IconData = new ui.IconData(59756, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_5mp: ui.IconData = new ui.IconData(59757, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_60fps: ui.IconData = new ui.IconData(61396, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_60fps_select: ui.IconData = new ui.IconData(
    61397,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly icon_6_ft_apart: ui.IconData = new ui.IconData(61982, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_6k: ui.IconData = new ui.IconData(59758, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_6k_plus: ui.IconData = new ui.IconData(59759, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_6mp: ui.IconData = new ui.IconData(59760, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_7k: ui.IconData = new ui.IconData(59761, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_7k_plus: ui.IconData = new ui.IconData(59762, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_7mp: ui.IconData = new ui.IconData(59763, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_8k: ui.IconData = new ui.IconData(59764, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_8k_plus: ui.IconData = new ui.IconData(59765, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_8mp: ui.IconData = new ui.IconData(59766, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_9k: ui.IconData = new ui.IconData(59767, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_9k_plus: ui.IconData = new ui.IconData(59768, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_9mp: ui.IconData = new ui.IconData(59769, {
    fontFamily: "MaterialIcons",
  });
  public static readonly abc: ui.IconData = new ui.IconData(60308, {
    fontFamily: "MaterialIcons",
  });
  public static readonly ac_unit: ui.IconData = new ui.IconData(60219, {
    fontFamily: "MaterialIcons",
  });
  public static readonly access_alarm: ui.IconData = new ui.IconData(57744, {
    fontFamily: "MaterialIcons",
  });
  public static readonly access_alarms: ui.IconData = new ui.IconData(57745, {
    fontFamily: "MaterialIcons",
  });
  public static readonly access_time: ui.IconData = new ui.IconData(57746, {
    fontFamily: "MaterialIcons",
  });
  public static readonly access_time_filled: ui.IconData = new ui.IconData(
    61398,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly accessibility: ui.IconData = new ui.IconData(59470, {
    fontFamily: "MaterialIcons",
  });
  public static readonly accessibility_new: ui.IconData = new ui.IconData(
    59692,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly accessible: ui.IconData = new ui.IconData(59668, {
    fontFamily: "MaterialIcons",
  });
  public static readonly accessible_forward: ui.IconData = new ui.IconData(
    59700,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly account_balance: ui.IconData = new ui.IconData(59471, {
    fontFamily: "MaterialIcons",
  });
  public static readonly account_balance_wallet: ui.IconData = new ui.IconData(
    59472,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly account_box: ui.IconData = new ui.IconData(59473, {
    fontFamily: "MaterialIcons",
  });
  public static readonly account_circle: ui.IconData = new ui.IconData(59475, {
    fontFamily: "MaterialIcons",
  });
  public static readonly account_tree: ui.IconData = new ui.IconData(59770, {
    fontFamily: "MaterialIcons",
  });
  public static readonly ad_units: ui.IconData = new ui.IconData(61241, {
    fontFamily: "MaterialIcons",
  });
  public static readonly adb: ui.IconData = new ui.IconData(58894, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add: ui.IconData = new ui.IconData(57669, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_a_photo: ui.IconData = new ui.IconData(58425, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_alarm: ui.IconData = new ui.IconData(57747, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_alert: ui.IconData = new ui.IconData(57347, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_box: ui.IconData = new ui.IconData(57670, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_business: ui.IconData = new ui.IconData(59177, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_call: ui.IconData = new ui.IconData(57576, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_card: ui.IconData = new ui.IconData(60294, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_chart: ui.IconData = new ui.IconData(59771, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_circle: ui.IconData = new ui.IconData(57671, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_circle_outline: ui.IconData = new ui.IconData(
    57672,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly add_comment: ui.IconData = new ui.IconData(57958, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_ic_call: ui.IconData = new ui.IconData(59772, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_link: ui.IconData = new ui.IconData(57720, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_location: ui.IconData = new ui.IconData(58727, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_location_alt: ui.IconData = new ui.IconData(
    61242,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly add_moderator: ui.IconData = new ui.IconData(59773, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_photo_alternate: ui.IconData = new ui.IconData(
    58430,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly add_reaction: ui.IconData = new ui.IconData(57811, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_road: ui.IconData = new ui.IconData(61243, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_shopping_cart: ui.IconData = new ui.IconData(
    59476,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly add_task: ui.IconData = new ui.IconData(62010, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_to_drive: ui.IconData = new ui.IconData(58972, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_to_home_screen: ui.IconData = new ui.IconData(
    57854,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly add_to_photos: ui.IconData = new ui.IconData(58269, {
    fontFamily: "MaterialIcons",
  });
  public static readonly add_to_queue: ui.IconData = new ui.IconData(57436, {
    fontFamily: "MaterialIcons",
  });
  public static readonly addchart: ui.IconData = new ui.IconData(61244, {
    fontFamily: "MaterialIcons",
  });
  public static readonly adf_scanner: ui.IconData = new ui.IconData(60122, {
    fontFamily: "MaterialIcons",
  });
  public static readonly adjust: ui.IconData = new ui.IconData(58270, {
    fontFamily: "MaterialIcons",
  });
  public static readonly admin_panel_settings: ui.IconData = new ui.IconData(
    61245,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly ads_click: ui.IconData = new ui.IconData(59234, {
    fontFamily: "MaterialIcons",
  });
  public static readonly agriculture: ui.IconData = new ui.IconData(60025, {
    fontFamily: "MaterialIcons",
  });
  public static readonly air: ui.IconData = new ui.IconData(61400, {
    fontFamily: "MaterialIcons",
  });
  public static readonly airline_seat_flat: ui.IconData = new ui.IconData(
    58928,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly airline_seat_flat_angled: ui.IconData =
    new ui.IconData(58929, { fontFamily: "MaterialIcons" });
  public static readonly airline_seat_individual_suite: ui.IconData =
    new ui.IconData(58930, { fontFamily: "MaterialIcons" });
  public static readonly airline_seat_legroom_extra: ui.IconData =
    new ui.IconData(58931, { fontFamily: "MaterialIcons" });
  public static readonly airline_seat_legroom_normal: ui.IconData =
    new ui.IconData(58932, { fontFamily: "MaterialIcons" });
  public static readonly airline_seat_legroom_reduced: ui.IconData =
    new ui.IconData(58933, { fontFamily: "MaterialIcons" });
  public static readonly airline_seat_recline_extra: ui.IconData =
    new ui.IconData(58934, { fontFamily: "MaterialIcons" });
  public static readonly airline_seat_recline_normal: ui.IconData =
    new ui.IconData(58935, { fontFamily: "MaterialIcons" });
  public static readonly airline_stops: ui.IconData = new ui.IconData(59344, {
    fontFamily: "MaterialIcons",
  });
  public static readonly airlines: ui.IconData = new ui.IconData(59338, {
    fontFamily: "MaterialIcons",
  });
  public static readonly airplane_ticket: ui.IconData = new ui.IconData(61401, {
    fontFamily: "MaterialIcons",
  });
  public static readonly airplanemode_active: ui.IconData = new ui.IconData(
    57749,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly airplanemode_inactive: ui.IconData = new ui.IconData(
    57748,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly airplay: ui.IconData = new ui.IconData(57429, {
    fontFamily: "MaterialIcons",
  });
  public static readonly airport_shuttle: ui.IconData = new ui.IconData(60220, {
    fontFamily: "MaterialIcons",
  });
  public static readonly alarm: ui.IconData = new ui.IconData(59477, {
    fontFamily: "MaterialIcons",
  });
  public static readonly alarm_add: ui.IconData = new ui.IconData(59478, {
    fontFamily: "MaterialIcons",
  });
  public static readonly alarm_off: ui.IconData = new ui.IconData(59479, {
    fontFamily: "MaterialIcons",
  });
  public static readonly alarm_on: ui.IconData = new ui.IconData(59480, {
    fontFamily: "MaterialIcons",
  });
  public static readonly album: ui.IconData = new ui.IconData(57369, {
    fontFamily: "MaterialIcons",
  });
  public static readonly align_horizontal_center: ui.IconData = new ui.IconData(
    57359,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly align_horizontal_left: ui.IconData = new ui.IconData(
    57357,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly align_horizontal_right: ui.IconData = new ui.IconData(
    57360,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly align_vertical_bottom: ui.IconData = new ui.IconData(
    57365,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly align_vertical_center: ui.IconData = new ui.IconData(
    57361,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly align_vertical_top: ui.IconData = new ui.IconData(
    57356,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly all_inbox: ui.IconData = new ui.IconData(59775, {
    fontFamily: "MaterialIcons",
  });
  public static readonly all_inclusive: ui.IconData = new ui.IconData(60221, {
    fontFamily: "MaterialIcons",
  });
  public static readonly all_out: ui.IconData = new ui.IconData(59659, {
    fontFamily: "MaterialIcons",
  });
  public static readonly alt_route: ui.IconData = new ui.IconData(61828, {
    fontFamily: "MaterialIcons",
  });
  public static readonly alternate_email: ui.IconData = new ui.IconData(57574, {
    fontFamily: "MaterialIcons",
  });
  public static readonly analytics: ui.IconData = new ui.IconData(61246, {
    fontFamily: "MaterialIcons",
  });
  public static readonly anchor: ui.IconData = new ui.IconData(61901, {
    fontFamily: "MaterialIcons",
  });
  public static readonly android: ui.IconData = new ui.IconData(59481, {
    fontFamily: "MaterialIcons",
  });
  public static readonly animation: ui.IconData = new ui.IconData(59164, {
    fontFamily: "MaterialIcons",
  });
  public static readonly announcement: ui.IconData = new ui.IconData(59482, {
    fontFamily: "MaterialIcons",
  });
  public static readonly aod: ui.IconData = new ui.IconData(61402, {
    fontFamily: "MaterialIcons",
  });
  public static readonly apartment: ui.IconData = new ui.IconData(59968, {
    fontFamily: "MaterialIcons",
  });
  public static readonly api: ui.IconData = new ui.IconData(61879, {
    fontFamily: "MaterialIcons",
  });
  public static readonly app_blocking: ui.IconData = new ui.IconData(61247, {
    fontFamily: "MaterialIcons",
  });
  public static readonly app_registration: ui.IconData = new ui.IconData(
    61248,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly app_settings_alt: ui.IconData = new ui.IconData(
    61249,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly app_shortcut: ui.IconData = new ui.IconData(60132, {
    fontFamily: "MaterialIcons",
  });
  public static readonly approval: ui.IconData = new ui.IconData(59778, {
    fontFamily: "MaterialIcons",
  });
  public static readonly apps: ui.IconData = new ui.IconData(58819, {
    fontFamily: "MaterialIcons",
  });
  public static readonly apps_outage: ui.IconData = new ui.IconData(59340, {
    fontFamily: "MaterialIcons",
  });
  public static readonly architecture: ui.IconData = new ui.IconData(59963, {
    fontFamily: "MaterialIcons",
  });
  public static readonly archive: ui.IconData = new ui.IconData(57673, {
    fontFamily: "MaterialIcons",
  });
  public static readonly area_chart: ui.IconData = new ui.IconData(59248, {
    fontFamily: "MaterialIcons",
  });
  public static readonly arrow_back: ui.IconData = new ui.IconData(58820, {
    fontFamily: "MaterialIcons",
  });
  public static readonly arrow_back_ios: ui.IconData = new ui.IconData(58848, {
    fontFamily: "MaterialIcons",
  });
  public static readonly arrow_back_ios_new: ui.IconData = new ui.IconData(
    58090,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly arrow_circle_down: ui.IconData = new ui.IconData(
    61825,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly arrow_circle_left: ui.IconData = new ui.IconData(
    60071,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly arrow_circle_right: ui.IconData = new ui.IconData(
    60074,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly arrow_circle_up: ui.IconData = new ui.IconData(61826, {
    fontFamily: "MaterialIcons",
  });
  public static readonly arrow_downward: ui.IconData = new ui.IconData(58843, {
    fontFamily: "MaterialIcons",
  });
  public static readonly arrow_drop_down: ui.IconData = new ui.IconData(58821, {
    fontFamily: "MaterialIcons",
  });
  public static readonly arrow_drop_down_circle: ui.IconData = new ui.IconData(
    58822,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly arrow_drop_up: ui.IconData = new ui.IconData(58823, {
    fontFamily: "MaterialIcons",
  });
  public static readonly arrow_forward: ui.IconData = new ui.IconData(58824, {
    fontFamily: "MaterialIcons",
  });
  public static readonly arrow_forward_ios: ui.IconData = new ui.IconData(
    58849,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly arrow_left: ui.IconData = new ui.IconData(58846, {
    fontFamily: "MaterialIcons",
  });
  public static readonly arrow_right: ui.IconData = new ui.IconData(58847, {
    fontFamily: "MaterialIcons",
  });
  public static readonly arrow_right_alt: ui.IconData = new ui.IconData(59713, {
    fontFamily: "MaterialIcons",
  });
  public static readonly arrow_upward: ui.IconData = new ui.IconData(58840, {
    fontFamily: "MaterialIcons",
  });
  public static readonly art_track: ui.IconData = new ui.IconData(57440, {
    fontFamily: "MaterialIcons",
  });
  public static readonly article: ui.IconData = new ui.IconData(61250, {
    fontFamily: "MaterialIcons",
  });
  public static readonly aspect_ratio: ui.IconData = new ui.IconData(59483, {
    fontFamily: "MaterialIcons",
  });
  public static readonly assessment: ui.IconData = new ui.IconData(59484, {
    fontFamily: "MaterialIcons",
  });
  public static readonly assignment: ui.IconData = new ui.IconData(59485, {
    fontFamily: "MaterialIcons",
  });
  public static readonly assignment_ind: ui.IconData = new ui.IconData(59486, {
    fontFamily: "MaterialIcons",
  });
  public static readonly assignment_late: ui.IconData = new ui.IconData(59487, {
    fontFamily: "MaterialIcons",
  });
  public static readonly assignment_return: ui.IconData = new ui.IconData(
    59488,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly assignment_returned: ui.IconData = new ui.IconData(
    59489,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly assignment_turned_in: ui.IconData = new ui.IconData(
    59490,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly assistant: ui.IconData = new ui.IconData(58271, {
    fontFamily: "MaterialIcons",
  });
  public static readonly assistant_direction: ui.IconData = new ui.IconData(
    59784,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly assistant_navigation: ui.IconData = new ui.IconData(
    59785,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly assistant_photo: ui.IconData = new ui.IconData(58272, {
    fontFamily: "MaterialIcons",
  });
  public static readonly assured_workload: ui.IconData = new ui.IconData(
    60271,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly atm: ui.IconData = new ui.IconData(58739, {
    fontFamily: "MaterialIcons",
  });
  public static readonly attach_email: ui.IconData = new ui.IconData(59998, {
    fontFamily: "MaterialIcons",
  });
  public static readonly attach_file: ui.IconData = new ui.IconData(57894, {
    fontFamily: "MaterialIcons",
  });
  public static readonly attach_money: ui.IconData = new ui.IconData(57895, {
    fontFamily: "MaterialIcons",
  });
  public static readonly attachment: ui.IconData = new ui.IconData(58044, {
    fontFamily: "MaterialIcons",
  });
  public static readonly attractions: ui.IconData = new ui.IconData(59986, {
    fontFamily: "MaterialIcons",
  });
  public static readonly attribution: ui.IconData = new ui.IconData(61403, {
    fontFamily: "MaterialIcons",
  });
  public static readonly audio_file: ui.IconData = new ui.IconData(60290, {
    fontFamily: "MaterialIcons",
  });
  public static readonly audiotrack: ui.IconData = new ui.IconData(58273, {
    fontFamily: "MaterialIcons",
  });
  public static readonly auto_awesome: ui.IconData = new ui.IconData(58975, {
    fontFamily: "MaterialIcons",
  });
  public static readonly auto_awesome_mosaic: ui.IconData = new ui.IconData(
    58976,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly auto_awesome_motion: ui.IconData = new ui.IconData(
    58977,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly auto_delete: ui.IconData = new ui.IconData(59980, {
    fontFamily: "MaterialIcons",
  });
  public static readonly auto_fix_high: ui.IconData = new ui.IconData(58979, {
    fontFamily: "MaterialIcons",
  });
  public static readonly auto_fix_normal: ui.IconData = new ui.IconData(58980, {
    fontFamily: "MaterialIcons",
  });
  public static readonly auto_fix_off: ui.IconData = new ui.IconData(58981, {
    fontFamily: "MaterialIcons",
  });
  public static readonly auto_graph: ui.IconData = new ui.IconData(58619, {
    fontFamily: "MaterialIcons",
  });
  public static readonly auto_stories: ui.IconData = new ui.IconData(58982, {
    fontFamily: "MaterialIcons",
  });
  public static readonly autofps_select: ui.IconData = new ui.IconData(61404, {
    fontFamily: "MaterialIcons",
  });
  public static readonly autorenew: ui.IconData = new ui.IconData(59491, {
    fontFamily: "MaterialIcons",
  });
  public static readonly av_timer: ui.IconData = new ui.IconData(57371, {
    fontFamily: "MaterialIcons",
  });
  public static readonly baby_changing_station: ui.IconData = new ui.IconData(
    61851,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly back_hand: ui.IconData = new ui.IconData(59236, {
    fontFamily: "MaterialIcons",
  });
  public static readonly backpack: ui.IconData = new ui.IconData(61852, {
    fontFamily: "MaterialIcons",
  });
  public static readonly backspace: ui.IconData = new ui.IconData(57674, {
    fontFamily: "MaterialIcons",
  });
  public static readonly backup: ui.IconData = new ui.IconData(59492, {
    fontFamily: "MaterialIcons",
  });
  public static readonly backup_table: ui.IconData = new ui.IconData(61251, {
    fontFamily: "MaterialIcons",
  });
  public static readonly badge: ui.IconData = new ui.IconData(60007, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bakery_dining: ui.IconData = new ui.IconData(59987, {
    fontFamily: "MaterialIcons",
  });
  public static readonly balance: ui.IconData = new ui.IconData(60150, {
    fontFamily: "MaterialIcons",
  });
  public static readonly balcony: ui.IconData = new ui.IconData(58767, {
    fontFamily: "MaterialIcons",
  });
  public static readonly ballot: ui.IconData = new ui.IconData(57714, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bar_chart: ui.IconData = new ui.IconData(57963, {
    fontFamily: "MaterialIcons",
  });
  public static readonly batch_prediction: ui.IconData = new ui.IconData(
    61685,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly bathroom: ui.IconData = new ui.IconData(61405, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bathtub: ui.IconData = new ui.IconData(59969, {
    fontFamily: "MaterialIcons",
  });
  public static readonly battery_0_bar: ui.IconData = new ui.IconData(60380, {
    fontFamily: "MaterialIcons",
  });
  public static readonly battery_1_bar: ui.IconData = new ui.IconData(60377, {
    fontFamily: "MaterialIcons",
  });
  public static readonly battery_2_bar: ui.IconData = new ui.IconData(60384, {
    fontFamily: "MaterialIcons",
  });
  public static readonly battery_3_bar: ui.IconData = new ui.IconData(60381, {
    fontFamily: "MaterialIcons",
  });
  public static readonly battery_4_bar: ui.IconData = new ui.IconData(60386, {
    fontFamily: "MaterialIcons",
  });
  public static readonly battery_5_bar: ui.IconData = new ui.IconData(60372, {
    fontFamily: "MaterialIcons",
  });
  public static readonly battery_6_bar: ui.IconData = new ui.IconData(60370, {
    fontFamily: "MaterialIcons",
  });
  public static readonly battery_alert: ui.IconData = new ui.IconData(57756, {
    fontFamily: "MaterialIcons",
  });
  public static readonly battery_charging_full: ui.IconData = new ui.IconData(
    57763,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly battery_full: ui.IconData = new ui.IconData(57764, {
    fontFamily: "MaterialIcons",
  });
  public static readonly battery_saver: ui.IconData = new ui.IconData(61406, {
    fontFamily: "MaterialIcons",
  });
  public static readonly battery_std: ui.IconData = new ui.IconData(57765, {
    fontFamily: "MaterialIcons",
  });
  public static readonly battery_unknown: ui.IconData = new ui.IconData(57766, {
    fontFamily: "MaterialIcons",
  });
  public static readonly beach_access: ui.IconData = new ui.IconData(60222, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bed: ui.IconData = new ui.IconData(61407, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bedroom_baby: ui.IconData = new ui.IconData(61408, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bedroom_child: ui.IconData = new ui.IconData(61409, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bedroom_parent: ui.IconData = new ui.IconData(61410, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bedtime: ui.IconData = new ui.IconData(61252, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bedtime_off: ui.IconData = new ui.IconData(60278, {
    fontFamily: "MaterialIcons",
  });
  public static readonly beenhere: ui.IconData = new ui.IconData(58669, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bento: ui.IconData = new ui.IconData(61940, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bike_scooter: ui.IconData = new ui.IconData(61253, {
    fontFamily: "MaterialIcons",
  });
  public static readonly biotech: ui.IconData = new ui.IconData(59962, {
    fontFamily: "MaterialIcons",
  });
  public static readonly blender: ui.IconData = new ui.IconData(61411, {
    fontFamily: "MaterialIcons",
  });
  public static readonly block: ui.IconData = new ui.IconData(57675, {
    fontFamily: "MaterialIcons",
  });
  public static readonly block_flipped: ui.IconData = new ui.IconData(61254, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bloodtype: ui.IconData = new ui.IconData(61412, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bluetooth: ui.IconData = new ui.IconData(57767, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bluetooth_audio: ui.IconData = new ui.IconData(58895, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bluetooth_connected: ui.IconData = new ui.IconData(
    57768,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly bluetooth_disabled: ui.IconData = new ui.IconData(
    57769,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly bluetooth_drive: ui.IconData = new ui.IconData(61413, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bluetooth_searching: ui.IconData = new ui.IconData(
    57770,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly blur_circular: ui.IconData = new ui.IconData(58274, {
    fontFamily: "MaterialIcons",
  });
  public static readonly blur_linear: ui.IconData = new ui.IconData(58275, {
    fontFamily: "MaterialIcons",
  });
  public static readonly blur_off: ui.IconData = new ui.IconData(58276, {
    fontFamily: "MaterialIcons",
  });
  public static readonly blur_on: ui.IconData = new ui.IconData(58277, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bolt: ui.IconData = new ui.IconData(59915, {
    fontFamily: "MaterialIcons",
  });
  public static readonly book: ui.IconData = new ui.IconData(59493, {
    fontFamily: "MaterialIcons",
  });
  public static readonly book_online: ui.IconData = new ui.IconData(61975, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bookmark: ui.IconData = new ui.IconData(59494, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bookmark_add: ui.IconData = new ui.IconData(58776, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bookmark_added: ui.IconData = new ui.IconData(58777, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bookmark_border: ui.IconData = new ui.IconData(59495, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bookmark_remove: ui.IconData = new ui.IconData(58778, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bookmarks: ui.IconData = new ui.IconData(59787, {
    fontFamily: "MaterialIcons",
  });
  public static readonly border_all: ui.IconData = new ui.IconData(57896, {
    fontFamily: "MaterialIcons",
  });
  public static readonly border_bottom: ui.IconData = new ui.IconData(57897, {
    fontFamily: "MaterialIcons",
  });
  public static readonly border_clear: ui.IconData = new ui.IconData(57898, {
    fontFamily: "MaterialIcons",
  });
  public static readonly border_color: ui.IconData = new ui.IconData(57899, {
    fontFamily: "MaterialIcons",
  });
  public static readonly border_horizontal: ui.IconData = new ui.IconData(
    57900,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly border_inner: ui.IconData = new ui.IconData(57901, {
    fontFamily: "MaterialIcons",
  });
  public static readonly border_left: ui.IconData = new ui.IconData(57902, {
    fontFamily: "MaterialIcons",
  });
  public static readonly border_outer: ui.IconData = new ui.IconData(57903, {
    fontFamily: "MaterialIcons",
  });
  public static readonly border_right: ui.IconData = new ui.IconData(57904, {
    fontFamily: "MaterialIcons",
  });
  public static readonly border_style: ui.IconData = new ui.IconData(57905, {
    fontFamily: "MaterialIcons",
  });
  public static readonly border_top: ui.IconData = new ui.IconData(57906, {
    fontFamily: "MaterialIcons",
  });
  public static readonly border_vertical: ui.IconData = new ui.IconData(57907, {
    fontFamily: "MaterialIcons",
  });
  public static readonly boy: ui.IconData = new ui.IconData(60263, {
    fontFamily: "MaterialIcons",
  });
  public static readonly branding_watermark: ui.IconData = new ui.IconData(
    57451,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly breakfast_dining: ui.IconData = new ui.IconData(
    59988,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly brightness_1: ui.IconData = new ui.IconData(58278, {
    fontFamily: "MaterialIcons",
  });
  public static readonly brightness_2: ui.IconData = new ui.IconData(58279, {
    fontFamily: "MaterialIcons",
  });
  public static readonly brightness_3: ui.IconData = new ui.IconData(58280, {
    fontFamily: "MaterialIcons",
  });
  public static readonly brightness_4: ui.IconData = new ui.IconData(58281, {
    fontFamily: "MaterialIcons",
  });
  public static readonly brightness_5: ui.IconData = new ui.IconData(58282, {
    fontFamily: "MaterialIcons",
  });
  public static readonly brightness_6: ui.IconData = new ui.IconData(58283, {
    fontFamily: "MaterialIcons",
  });
  public static readonly brightness_7: ui.IconData = new ui.IconData(58284, {
    fontFamily: "MaterialIcons",
  });
  public static readonly brightness_auto: ui.IconData = new ui.IconData(57771, {
    fontFamily: "MaterialIcons",
  });
  public static readonly brightness_high: ui.IconData = new ui.IconData(57772, {
    fontFamily: "MaterialIcons",
  });
  public static readonly brightness_low: ui.IconData = new ui.IconData(57773, {
    fontFamily: "MaterialIcons",
  });
  public static readonly brightness_medium: ui.IconData = new ui.IconData(
    57774,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly broken_image: ui.IconData = new ui.IconData(58285, {
    fontFamily: "MaterialIcons",
  });
  public static readonly browse_gallery: ui.IconData = new ui.IconData(60369, {
    fontFamily: "MaterialIcons",
  });
  public static readonly browser_not_supported: ui.IconData = new ui.IconData(
    61255,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly browser_updated: ui.IconData = new ui.IconData(59343, {
    fontFamily: "MaterialIcons",
  });
  public static readonly brunch_dining: ui.IconData = new ui.IconData(60019, {
    fontFamily: "MaterialIcons",
  });
  public static readonly brush: ui.IconData = new ui.IconData(58286, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bubble_chart: ui.IconData = new ui.IconData(59101, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bug_report: ui.IconData = new ui.IconData(59496, {
    fontFamily: "MaterialIcons",
  });
  public static readonly build: ui.IconData = new ui.IconData(59497, {
    fontFamily: "MaterialIcons",
  });
  public static readonly build_circle: ui.IconData = new ui.IconData(61256, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bungalow: ui.IconData = new ui.IconData(58769, {
    fontFamily: "MaterialIcons",
  });
  public static readonly burst_mode: ui.IconData = new ui.IconData(58428, {
    fontFamily: "MaterialIcons",
  });
  public static readonly bus_alert: ui.IconData = new ui.IconData(59791, {
    fontFamily: "MaterialIcons",
  });
  public static readonly business: ui.IconData = new ui.IconData(57519, {
    fontFamily: "MaterialIcons",
  });
  public static readonly business_center: ui.IconData = new ui.IconData(60223, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cabin: ui.IconData = new ui.IconData(58761, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cable: ui.IconData = new ui.IconData(61414, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cached: ui.IconData = new ui.IconData(59498, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cake: ui.IconData = new ui.IconData(59369, {
    fontFamily: "MaterialIcons",
  });
  public static readonly calculate: ui.IconData = new ui.IconData(59999, {
    fontFamily: "MaterialIcons",
  });
  public static readonly calendar_month: ui.IconData = new ui.IconData(60364, {
    fontFamily: "MaterialIcons",
  });
  public static readonly calendar_today: ui.IconData = new ui.IconData(59701, {
    fontFamily: "MaterialIcons",
  });
  public static readonly calendar_view_day: ui.IconData = new ui.IconData(
    59702,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly calendar_view_month: ui.IconData = new ui.IconData(
    61415,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly calendar_view_week: ui.IconData = new ui.IconData(
    61416,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly call: ui.IconData = new ui.IconData(57520, {
    fontFamily: "MaterialIcons",
  });
  public static readonly call_end: ui.IconData = new ui.IconData(57521, {
    fontFamily: "MaterialIcons",
  });
  public static readonly call_made: ui.IconData = new ui.IconData(57522, {
    fontFamily: "MaterialIcons",
  });
  public static readonly call_merge: ui.IconData = new ui.IconData(57523, {
    fontFamily: "MaterialIcons",
  });
  public static readonly call_missed: ui.IconData = new ui.IconData(57524, {
    fontFamily: "MaterialIcons",
  });
  public static readonly call_missed_outgoing: ui.IconData = new ui.IconData(
    57572,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly call_received: ui.IconData = new ui.IconData(57525, {
    fontFamily: "MaterialIcons",
  });
  public static readonly call_split: ui.IconData = new ui.IconData(57526, {
    fontFamily: "MaterialIcons",
  });
  public static readonly call_to_action: ui.IconData = new ui.IconData(57452, {
    fontFamily: "MaterialIcons",
  });
  public static readonly camera: ui.IconData = new ui.IconData(58287, {
    fontFamily: "MaterialIcons",
  });
  public static readonly camera_alt: ui.IconData = new ui.IconData(58288, {
    fontFamily: "MaterialIcons",
  });
  public static readonly camera_enhance: ui.IconData = new ui.IconData(59644, {
    fontFamily: "MaterialIcons",
  });
  public static readonly camera_front: ui.IconData = new ui.IconData(58289, {
    fontFamily: "MaterialIcons",
  });
  public static readonly camera_indoor: ui.IconData = new ui.IconData(61417, {
    fontFamily: "MaterialIcons",
  });
  public static readonly camera_outdoor: ui.IconData = new ui.IconData(61418, {
    fontFamily: "MaterialIcons",
  });
  public static readonly camera_rear: ui.IconData = new ui.IconData(58290, {
    fontFamily: "MaterialIcons",
  });
  public static readonly camera_roll: ui.IconData = new ui.IconData(58291, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cameraswitch: ui.IconData = new ui.IconData(61419, {
    fontFamily: "MaterialIcons",
  });
  public static readonly campaign: ui.IconData = new ui.IconData(61257, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cancel: ui.IconData = new ui.IconData(58825, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cancel_presentation: ui.IconData = new ui.IconData(
    57577,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly cancel_schedule_send: ui.IconData = new ui.IconData(
    59961,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly candlestick_chart: ui.IconData = new ui.IconData(
    60116,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly car_crash: ui.IconData = new ui.IconData(60402, {
    fontFamily: "MaterialIcons",
  });
  public static readonly car_rental: ui.IconData = new ui.IconData(59989, {
    fontFamily: "MaterialIcons",
  });
  public static readonly car_repair: ui.IconData = new ui.IconData(59990, {
    fontFamily: "MaterialIcons",
  });
  public static readonly card_giftcard: ui.IconData = new ui.IconData(59638, {
    fontFamily: "MaterialIcons",
  });
  public static readonly card_membership: ui.IconData = new ui.IconData(59639, {
    fontFamily: "MaterialIcons",
  });
  public static readonly card_travel: ui.IconData = new ui.IconData(59640, {
    fontFamily: "MaterialIcons",
  });
  public static readonly carpenter: ui.IconData = new ui.IconData(61944, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cases: ui.IconData = new ui.IconData(59794, {
    fontFamily: "MaterialIcons",
  });
  public static readonly casino: ui.IconData = new ui.IconData(60224, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cast: ui.IconData = new ui.IconData(58119, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cast_connected: ui.IconData = new ui.IconData(58120, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cast_for_education: ui.IconData = new ui.IconData(
    61420,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly castle: ui.IconData = new ui.IconData(60081, {
    fontFamily: "MaterialIcons",
  });
  public static readonly catching_pokemon: ui.IconData = new ui.IconData(
    58632,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly category: ui.IconData = new ui.IconData(58740, {
    fontFamily: "MaterialIcons",
  });
  public static readonly celebration: ui.IconData = new ui.IconData(60005, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cell_tower: ui.IconData = new ui.IconData(60346, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cell_wifi: ui.IconData = new ui.IconData(57580, {
    fontFamily: "MaterialIcons",
  });
  public static readonly center_focus_strong: ui.IconData = new ui.IconData(
    58292,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly center_focus_weak: ui.IconData = new ui.IconData(
    58293,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly chair: ui.IconData = new ui.IconData(61421, {
    fontFamily: "MaterialIcons",
  });
  public static readonly chair_alt: ui.IconData = new ui.IconData(61422, {
    fontFamily: "MaterialIcons",
  });
  public static readonly chalet: ui.IconData = new ui.IconData(58757, {
    fontFamily: "MaterialIcons",
  });
  public static readonly change_circle: ui.IconData = new ui.IconData(58087, {
    fontFamily: "MaterialIcons",
  });
  public static readonly change_history: ui.IconData = new ui.IconData(59499, {
    fontFamily: "MaterialIcons",
  });
  public static readonly charging_station: ui.IconData = new ui.IconData(
    61853,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly chat: ui.IconData = new ui.IconData(57527, {
    fontFamily: "MaterialIcons",
  });
  public static readonly chat_bubble: ui.IconData = new ui.IconData(57546, {
    fontFamily: "MaterialIcons",
  });
  public static readonly chat_bubble_outline: ui.IconData = new ui.IconData(
    57547,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly check: ui.IconData = new ui.IconData(58826, {
    fontFamily: "MaterialIcons",
  });
  public static readonly check_box: ui.IconData = new ui.IconData(59444, {
    fontFamily: "MaterialIcons",
  });
  public static readonly check_box_outline_blank: ui.IconData = new ui.IconData(
    59445,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly check_circle: ui.IconData = new ui.IconData(59500, {
    fontFamily: "MaterialIcons",
  });
  public static readonly check_circle_outline: ui.IconData = new ui.IconData(
    59693,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly checklist: ui.IconData = new ui.IconData(59057, {
    fontFamily: "MaterialIcons",
  });
  public static readonly checklist_rtl: ui.IconData = new ui.IconData(59059, {
    fontFamily: "MaterialIcons",
  });
  public static readonly checkroom: ui.IconData = new ui.IconData(61854, {
    fontFamily: "MaterialIcons",
  });
  public static readonly chevron_left: ui.IconData = new ui.IconData(58827, {
    fontFamily: "MaterialIcons",
  });
  public static readonly chevron_right: ui.IconData = new ui.IconData(58828, {
    fontFamily: "MaterialIcons",
  });
  public static readonly child_care: ui.IconData = new ui.IconData(60225, {
    fontFamily: "MaterialIcons",
  });
  public static readonly child_friendly: ui.IconData = new ui.IconData(60226, {
    fontFamily: "MaterialIcons",
  });
  public static readonly chrome_reader_mode: ui.IconData = new ui.IconData(
    59501,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly church: ui.IconData = new ui.IconData(60078, {
    fontFamily: "MaterialIcons",
  });
  public static readonly circle: ui.IconData = new ui.IconData(61258, {
    fontFamily: "MaterialIcons",
  });
  public static readonly circle_notifications: ui.IconData = new ui.IconData(
    59796,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly icon_class: ui.IconData = new ui.IconData(59502, {
    fontFamily: "MaterialIcons",
  });
  public static readonly clean_hands: ui.IconData = new ui.IconData(61983, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cleaning_services: ui.IconData = new ui.IconData(
    61695,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly clear: ui.IconData = new ui.IconData(57676, {
    fontFamily: "MaterialIcons",
  });
  public static readonly clear_all: ui.IconData = new ui.IconData(57528, {
    fontFamily: "MaterialIcons",
  });
  public static readonly close: ui.IconData = new ui.IconData(58829, {
    fontFamily: "MaterialIcons",
  });
  public static readonly close_fullscreen: ui.IconData = new ui.IconData(
    61903,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly closed_caption: ui.IconData = new ui.IconData(57372, {
    fontFamily: "MaterialIcons",
  });
  public static readonly closed_caption_disabled: ui.IconData = new ui.IconData(
    61916,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly closed_caption_off: ui.IconData = new ui.IconData(
    59798,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly cloud: ui.IconData = new ui.IconData(58045, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cloud_circle: ui.IconData = new ui.IconData(58046, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cloud_done: ui.IconData = new ui.IconData(58047, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cloud_download: ui.IconData = new ui.IconData(58048, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cloud_off: ui.IconData = new ui.IconData(58049, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cloud_queue: ui.IconData = new ui.IconData(58050, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cloud_sync: ui.IconData = new ui.IconData(60250, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cloud_upload: ui.IconData = new ui.IconData(58051, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cloudy_snowing: ui.IconData = new ui.IconData(59408, {
    fontFamily: "MaterialIcons",
  });
  public static readonly co2: ui.IconData = new ui.IconData(59312, {
    fontFamily: "MaterialIcons",
  });
  public static readonly co_present: ui.IconData = new ui.IconData(60144, {
    fontFamily: "MaterialIcons",
  });
  public static readonly code: ui.IconData = new ui.IconData(59503, {
    fontFamily: "MaterialIcons",
  });
  public static readonly code_off: ui.IconData = new ui.IconData(58611, {
    fontFamily: "MaterialIcons",
  });
  public static readonly coffee: ui.IconData = new ui.IconData(61423, {
    fontFamily: "MaterialIcons",
  });
  public static readonly coffee_maker: ui.IconData = new ui.IconData(61424, {
    fontFamily: "MaterialIcons",
  });
  public static readonly collections: ui.IconData = new ui.IconData(58294, {
    fontFamily: "MaterialIcons",
  });
  public static readonly collections_bookmark: ui.IconData = new ui.IconData(
    58417,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly color_lens: ui.IconData = new ui.IconData(58295, {
    fontFamily: "MaterialIcons",
  });
  public static readonly colorize: ui.IconData = new ui.IconData(58296, {
    fontFamily: "MaterialIcons",
  });
  public static readonly comment: ui.IconData = new ui.IconData(57529, {
    fontFamily: "MaterialIcons",
  });
  public static readonly comment_bank: ui.IconData = new ui.IconData(59982, {
    fontFamily: "MaterialIcons",
  });
  public static readonly comments_disabled: ui.IconData = new ui.IconData(
    59298,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly commit: ui.IconData = new ui.IconData(60149, {
    fontFamily: "MaterialIcons",
  });
  public static readonly commute: ui.IconData = new ui.IconData(59712, {
    fontFamily: "MaterialIcons",
  });
  public static readonly compare: ui.IconData = new ui.IconData(58297, {
    fontFamily: "MaterialIcons",
  });
  public static readonly compare_arrows: ui.IconData = new ui.IconData(59669, {
    fontFamily: "MaterialIcons",
  });
  public static readonly compass_calibration: ui.IconData = new ui.IconData(
    58748,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly compost: ui.IconData = new ui.IconData(59233, {
    fontFamily: "MaterialIcons",
  });
  public static readonly compress: ui.IconData = new ui.IconData(59725, {
    fontFamily: "MaterialIcons",
  });
  public static readonly computer: ui.IconData = new ui.IconData(58122, {
    fontFamily: "MaterialIcons",
  });
  public static readonly confirmation_number: ui.IconData = new ui.IconData(
    58936,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly connect_without_contact: ui.IconData = new ui.IconData(
    61987,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly connected_tv: ui.IconData = new ui.IconData(59800, {
    fontFamily: "MaterialIcons",
  });
  public static readonly connecting_airports: ui.IconData = new ui.IconData(
    59337,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly construction: ui.IconData = new ui.IconData(59964, {
    fontFamily: "MaterialIcons",
  });
  public static readonly contact_mail: ui.IconData = new ui.IconData(57552, {
    fontFamily: "MaterialIcons",
  });
  public static readonly contact_page: ui.IconData = new ui.IconData(61998, {
    fontFamily: "MaterialIcons",
  });
  public static readonly contact_phone: ui.IconData = new ui.IconData(57551, {
    fontFamily: "MaterialIcons",
  });
  public static readonly contact_support: ui.IconData = new ui.IconData(59724, {
    fontFamily: "MaterialIcons",
  });
  public static readonly contactless: ui.IconData = new ui.IconData(60017, {
    fontFamily: "MaterialIcons",
  });
  public static readonly contacts: ui.IconData = new ui.IconData(57530, {
    fontFamily: "MaterialIcons",
  });
  public static readonly content_copy: ui.IconData = new ui.IconData(57677, {
    fontFamily: "MaterialIcons",
  });
  public static readonly content_cut: ui.IconData = new ui.IconData(57678, {
    fontFamily: "MaterialIcons",
  });
  public static readonly content_paste: ui.IconData = new ui.IconData(57679, {
    fontFamily: "MaterialIcons",
  });
  public static readonly content_paste_go: ui.IconData = new ui.IconData(
    60046,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly content_paste_off: ui.IconData = new ui.IconData(
    58616,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly content_paste_search: ui.IconData = new ui.IconData(
    60059,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly contrast: ui.IconData = new ui.IconData(60215, {
    fontFamily: "MaterialIcons",
  });
  public static readonly control_camera: ui.IconData = new ui.IconData(57460, {
    fontFamily: "MaterialIcons",
  });
  public static readonly control_point: ui.IconData = new ui.IconData(58298, {
    fontFamily: "MaterialIcons",
  });
  public static readonly control_point_duplicate: ui.IconData = new ui.IconData(
    58299,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly cookie: ui.IconData = new ui.IconData(60076, {
    fontFamily: "MaterialIcons",
  });
  public static readonly copy_all: ui.IconData = new ui.IconData(58092, {
    fontFamily: "MaterialIcons",
  });
  public static readonly copyright: ui.IconData = new ui.IconData(59660, {
    fontFamily: "MaterialIcons",
  });
  public static readonly coronavirus: ui.IconData = new ui.IconData(61985, {
    fontFamily: "MaterialIcons",
  });
  public static readonly corporate_fare: ui.IconData = new ui.IconData(61904, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cottage: ui.IconData = new ui.IconData(58759, {
    fontFamily: "MaterialIcons",
  });
  public static readonly countertops: ui.IconData = new ui.IconData(61943, {
    fontFamily: "MaterialIcons",
  });
  public static readonly create: ui.IconData = new ui.IconData(57680, {
    fontFamily: "MaterialIcons",
  });
  public static readonly create_new_folder: ui.IconData = new ui.IconData(
    58060,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly credit_card: ui.IconData = new ui.IconData(59504, {
    fontFamily: "MaterialIcons",
  });
  public static readonly credit_card_off: ui.IconData = new ui.IconData(58612, {
    fontFamily: "MaterialIcons",
  });
  public static readonly credit_score: ui.IconData = new ui.IconData(61425, {
    fontFamily: "MaterialIcons",
  });
  public static readonly crib: ui.IconData = new ui.IconData(58760, {
    fontFamily: "MaterialIcons",
  });
  public static readonly crisis_alert: ui.IconData = new ui.IconData(60393, {
    fontFamily: "MaterialIcons",
  });
  public static readonly crop: ui.IconData = new ui.IconData(58302, {
    fontFamily: "MaterialIcons",
  });
  public static readonly crop_16_9: ui.IconData = new ui.IconData(58300, {
    fontFamily: "MaterialIcons",
  });
  public static readonly crop_3_2: ui.IconData = new ui.IconData(58301, {
    fontFamily: "MaterialIcons",
  });
  public static readonly crop_5_4: ui.IconData = new ui.IconData(58303, {
    fontFamily: "MaterialIcons",
  });
  public static readonly crop_7_5: ui.IconData = new ui.IconData(58304, {
    fontFamily: "MaterialIcons",
  });
  public static readonly crop_din: ui.IconData = new ui.IconData(58305, {
    fontFamily: "MaterialIcons",
  });
  public static readonly crop_free: ui.IconData = new ui.IconData(58306, {
    fontFamily: "MaterialIcons",
  });
  public static readonly crop_landscape: ui.IconData = new ui.IconData(58307, {
    fontFamily: "MaterialIcons",
  });
  public static readonly crop_original: ui.IconData = new ui.IconData(58308, {
    fontFamily: "MaterialIcons",
  });
  public static readonly crop_portrait: ui.IconData = new ui.IconData(58309, {
    fontFamily: "MaterialIcons",
  });
  public static readonly crop_rotate: ui.IconData = new ui.IconData(58423, {
    fontFamily: "MaterialIcons",
  });
  public static readonly crop_square: ui.IconData = new ui.IconData(58310, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cruelty_free: ui.IconData = new ui.IconData(59289, {
    fontFamily: "MaterialIcons",
  });
  public static readonly css: ui.IconData = new ui.IconData(60307, {
    fontFamily: "MaterialIcons",
  });
  public static readonly currency_bitcoin: ui.IconData = new ui.IconData(
    60357,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly currency_exchange: ui.IconData = new ui.IconData(
    60272,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly currency_franc: ui.IconData = new ui.IconData(60154, {
    fontFamily: "MaterialIcons",
  });
  public static readonly currency_lira: ui.IconData = new ui.IconData(60143, {
    fontFamily: "MaterialIcons",
  });
  public static readonly currency_pound: ui.IconData = new ui.IconData(60145, {
    fontFamily: "MaterialIcons",
  });
  public static readonly currency_ruble: ui.IconData = new ui.IconData(60140, {
    fontFamily: "MaterialIcons",
  });
  public static readonly currency_rupee: ui.IconData = new ui.IconData(60151, {
    fontFamily: "MaterialIcons",
  });
  public static readonly currency_yen: ui.IconData = new ui.IconData(60155, {
    fontFamily: "MaterialIcons",
  });
  public static readonly currency_yuan: ui.IconData = new ui.IconData(60153, {
    fontFamily: "MaterialIcons",
  });
  public static readonly cyclone: ui.IconData = new ui.IconData(60373, {
    fontFamily: "MaterialIcons",
  });
  public static readonly dangerous: ui.IconData = new ui.IconData(59802, {
    fontFamily: "MaterialIcons",
  });
  public static readonly dark_mode: ui.IconData = new ui.IconData(58652, {
    fontFamily: "MaterialIcons",
  });
  public static readonly dashboard: ui.IconData = new ui.IconData(59505, {
    fontFamily: "MaterialIcons",
  });
  public static readonly dashboard_customize: ui.IconData = new ui.IconData(
    59803,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly data_array: ui.IconData = new ui.IconData(60113, {
    fontFamily: "MaterialIcons",
  });
  public static readonly data_exploration: ui.IconData = new ui.IconData(
    59247,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly data_object: ui.IconData = new ui.IconData(60115, {
    fontFamily: "MaterialIcons",
  });
  public static readonly data_saver_off: ui.IconData = new ui.IconData(61426, {
    fontFamily: "MaterialIcons",
  });
  public static readonly data_saver_on: ui.IconData = new ui.IconData(61427, {
    fontFamily: "MaterialIcons",
  });
  public static readonly data_thresholding: ui.IconData = new ui.IconData(
    60319,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly data_usage: ui.IconData = new ui.IconData(57775, {
    fontFamily: "MaterialIcons",
  });
  public static readonly date_range: ui.IconData = new ui.IconData(59670, {
    fontFamily: "MaterialIcons",
  });
  public static readonly deblur: ui.IconData = new ui.IconData(60279, {
    fontFamily: "MaterialIcons",
  });
  public static readonly deck: ui.IconData = new ui.IconData(59970, {
    fontFamily: "MaterialIcons",
  });
  public static readonly dehaze: ui.IconData = new ui.IconData(58311, {
    fontFamily: "MaterialIcons",
  });
  public static readonly delete: ui.IconData = new ui.IconData(59506, {
    fontFamily: "MaterialIcons",
  });
  public static readonly delete_forever: ui.IconData = new ui.IconData(59691, {
    fontFamily: "MaterialIcons",
  });
  public static readonly delete_outline: ui.IconData = new ui.IconData(59694, {
    fontFamily: "MaterialIcons",
  });
  public static readonly delete_sweep: ui.IconData = new ui.IconData(57708, {
    fontFamily: "MaterialIcons",
  });
  public static readonly delivery_dining: ui.IconData = new ui.IconData(60018, {
    fontFamily: "MaterialIcons",
  });
  public static readonly density_large: ui.IconData = new ui.IconData(60329, {
    fontFamily: "MaterialIcons",
  });
  public static readonly density_medium: ui.IconData = new ui.IconData(60318, {
    fontFamily: "MaterialIcons",
  });
  public static readonly density_small: ui.IconData = new ui.IconData(60328, {
    fontFamily: "MaterialIcons",
  });
  public static readonly departure_board: ui.IconData = new ui.IconData(58742, {
    fontFamily: "MaterialIcons",
  });
  public static readonly description: ui.IconData = new ui.IconData(59507, {
    fontFamily: "MaterialIcons",
  });
  public static readonly deselect: ui.IconData = new ui.IconData(60342, {
    fontFamily: "MaterialIcons",
  });
  public static readonly design_services: ui.IconData = new ui.IconData(61706, {
    fontFamily: "MaterialIcons",
  });
  public static readonly desktop_access_disabled: ui.IconData = new ui.IconData(
    59805,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly desktop_mac: ui.IconData = new ui.IconData(58123, {
    fontFamily: "MaterialIcons",
  });
  public static readonly desktop_windows: ui.IconData = new ui.IconData(58124, {
    fontFamily: "MaterialIcons",
  });
  public static readonly details: ui.IconData = new ui.IconData(58312, {
    fontFamily: "MaterialIcons",
  });
  public static readonly developer_board: ui.IconData = new ui.IconData(58125, {
    fontFamily: "MaterialIcons",
  });
  public static readonly developer_board_off: ui.IconData = new ui.IconData(
    58623,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly developer_mode: ui.IconData = new ui.IconData(57776, {
    fontFamily: "MaterialIcons",
  });
  public static readonly device_hub: ui.IconData = new ui.IconData(58165, {
    fontFamily: "MaterialIcons",
  });
  public static readonly device_thermostat: ui.IconData = new ui.IconData(
    57855,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly device_unknown: ui.IconData = new ui.IconData(58169, {
    fontFamily: "MaterialIcons",
  });
  public static readonly devices: ui.IconData = new ui.IconData(57777, {
    fontFamily: "MaterialIcons",
  });
  public static readonly devices_fold: ui.IconData = new ui.IconData(60382, {
    fontFamily: "MaterialIcons",
  });
  public static readonly devices_other: ui.IconData = new ui.IconData(58167, {
    fontFamily: "MaterialIcons",
  });
  public static readonly dialer_sip: ui.IconData = new ui.IconData(57531, {
    fontFamily: "MaterialIcons",
  });
  public static readonly dialpad: ui.IconData = new ui.IconData(57532, {
    fontFamily: "MaterialIcons",
  });
  public static readonly diamond: ui.IconData = new ui.IconData(60117, {
    fontFamily: "MaterialIcons",
  });
  public static readonly difference: ui.IconData = new ui.IconData(60285, {
    fontFamily: "MaterialIcons",
  });
  public static readonly dining: ui.IconData = new ui.IconData(61428, {
    fontFamily: "MaterialIcons",
  });
  public static readonly dinner_dining: ui.IconData = new ui.IconData(59991, {
    fontFamily: "MaterialIcons",
  });
  public static readonly directions: ui.IconData = new ui.IconData(58670, {
    fontFamily: "MaterialIcons",
  });
  public static readonly directions_bike: ui.IconData = new ui.IconData(58671, {
    fontFamily: "MaterialIcons",
  });
  public static readonly directions_boat: ui.IconData = new ui.IconData(58674, {
    fontFamily: "MaterialIcons",
  });
  public static readonly directions_boat_filled: ui.IconData = new ui.IconData(
    61429,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly directions_bus: ui.IconData = new ui.IconData(58672, {
    fontFamily: "MaterialIcons",
  });
  public static readonly directions_bus_filled: ui.IconData = new ui.IconData(
    61430,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly directions_car: ui.IconData = new ui.IconData(58673, {
    fontFamily: "MaterialIcons",
  });
  public static readonly directions_car_filled: ui.IconData = new ui.IconData(
    61431,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly directions_off: ui.IconData = new ui.IconData(61711, {
    fontFamily: "MaterialIcons",
  });
  public static readonly directions_railway: ui.IconData = new ui.IconData(
    58676,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly directions_railway_filled: ui.IconData =
    new ui.IconData(61432, { fontFamily: "MaterialIcons" });
  public static readonly directions_run: ui.IconData = new ui.IconData(58726, {
    fontFamily: "MaterialIcons",
  });
  public static readonly directions_subway: ui.IconData = new ui.IconData(
    58675,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly directions_subway_filled: ui.IconData =
    new ui.IconData(61433, { fontFamily: "MaterialIcons" });
  public static readonly directions_transit: ui.IconData = new ui.IconData(
    58677,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly directions_transit_filled: ui.IconData =
    new ui.IconData(61434, { fontFamily: "MaterialIcons" });
  public static readonly directions_walk: ui.IconData = new ui.IconData(58678, {
    fontFamily: "MaterialIcons",
  });
  public static readonly dirty_lens: ui.IconData = new ui.IconData(61259, {
    fontFamily: "MaterialIcons",
  });
  public static readonly disabled_by_default: ui.IconData = new ui.IconData(
    62000,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly disabled_visible: ui.IconData = new ui.IconData(
    59246,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly disc_full: ui.IconData = new ui.IconData(58896, {
    fontFamily: "MaterialIcons",
  });
  public static readonly discount: ui.IconData = new ui.IconData(60361, {
    fontFamily: "MaterialIcons",
  });
  public static readonly display_settings: ui.IconData = new ui.IconData(
    60311,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly dns: ui.IconData = new ui.IconData(59509, {
    fontFamily: "MaterialIcons",
  });
  public static readonly do_disturb: ui.IconData = new ui.IconData(61580, {
    fontFamily: "MaterialIcons",
  });
  public static readonly do_disturb_alt: ui.IconData = new ui.IconData(61581, {
    fontFamily: "MaterialIcons",
  });
  public static readonly do_disturb_off: ui.IconData = new ui.IconData(61582, {
    fontFamily: "MaterialIcons",
  });
  public static readonly do_disturb_on: ui.IconData = new ui.IconData(61583, {
    fontFamily: "MaterialIcons",
  });
  public static readonly do_not_disturb: ui.IconData = new ui.IconData(58898, {
    fontFamily: "MaterialIcons",
  });
  public static readonly do_not_disturb_alt: ui.IconData = new ui.IconData(
    58897,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly do_not_disturb_off: ui.IconData = new ui.IconData(
    58947,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly do_not_disturb_on: ui.IconData = new ui.IconData(
    58948,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly do_not_disturb_on_total_silence: ui.IconData =
    new ui.IconData(61435, { fontFamily: "MaterialIcons" });
  public static readonly do_not_step: ui.IconData = new ui.IconData(61855, {
    fontFamily: "MaterialIcons",
  });
  public static readonly do_not_touch: ui.IconData = new ui.IconData(61872, {
    fontFamily: "MaterialIcons",
  });
  public static readonly dock: ui.IconData = new ui.IconData(58126, {
    fontFamily: "MaterialIcons",
  });
  public static readonly document_scanner: ui.IconData = new ui.IconData(
    58874,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly domain: ui.IconData = new ui.IconData(59374, {
    fontFamily: "MaterialIcons",
  });
  public static readonly domain_add: ui.IconData = new ui.IconData(60258, {
    fontFamily: "MaterialIcons",
  });
  public static readonly domain_disabled: ui.IconData = new ui.IconData(57583, {
    fontFamily: "MaterialIcons",
  });
  public static readonly domain_verification: ui.IconData = new ui.IconData(
    61260,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly done: ui.IconData = new ui.IconData(59510, {
    fontFamily: "MaterialIcons",
  });
  public static readonly done_all: ui.IconData = new ui.IconData(59511, {
    fontFamily: "MaterialIcons",
  });
  public static readonly done_outline: ui.IconData = new ui.IconData(59695, {
    fontFamily: "MaterialIcons",
  });
  public static readonly donut_large: ui.IconData = new ui.IconData(59671, {
    fontFamily: "MaterialIcons",
  });
  public static readonly donut_small: ui.IconData = new ui.IconData(59672, {
    fontFamily: "MaterialIcons",
  });
  public static readonly door_back: ui.IconData = new ui.IconData(61436, {
    fontFamily: "MaterialIcons",
  });
  public static readonly door_front: ui.IconData = new ui.IconData(61437, {
    fontFamily: "MaterialIcons",
  });
  public static readonly door_sliding: ui.IconData = new ui.IconData(61438, {
    fontFamily: "MaterialIcons",
  });
  public static readonly doorbell: ui.IconData = new ui.IconData(61439, {
    fontFamily: "MaterialIcons",
  });
  public static readonly double_arrow: ui.IconData = new ui.IconData(59984, {
    fontFamily: "MaterialIcons",
  });
  public static readonly downhill_skiing: ui.IconData = new ui.IconData(58633, {
    fontFamily: "MaterialIcons",
  });
  public static readonly download: ui.IconData = new ui.IconData(61584, {
    fontFamily: "MaterialIcons",
  });
  public static readonly download_done: ui.IconData = new ui.IconData(61585, {
    fontFamily: "MaterialIcons",
  });
  public static readonly download_for_offline: ui.IconData = new ui.IconData(
    61440,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly downloading: ui.IconData = new ui.IconData(61441, {
    fontFamily: "MaterialIcons",
  });
  public static readonly drafts: ui.IconData = new ui.IconData(57681, {
    fontFamily: "MaterialIcons",
  });
  public static readonly drag_handle: ui.IconData = new ui.IconData(57949, {
    fontFamily: "MaterialIcons",
  });
  public static readonly drag_indicator: ui.IconData = new ui.IconData(59717, {
    fontFamily: "MaterialIcons",
  });
  public static readonly draw: ui.IconData = new ui.IconData(59206, {
    fontFamily: "MaterialIcons",
  });
  public static readonly drive_eta: ui.IconData = new ui.IconData(58899, {
    fontFamily: "MaterialIcons",
  });
  public static readonly drive_file_move: ui.IconData = new ui.IconData(58997, {
    fontFamily: "MaterialIcons",
  });
  public static readonly drive_file_move_outline: ui.IconData = new ui.IconData(
    59809,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly drive_file_move_rtl: ui.IconData = new ui.IconData(
    59245,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly drive_file_rename_outline: ui.IconData =
    new ui.IconData(59810, { fontFamily: "MaterialIcons" });
  public static readonly drive_folder_upload: ui.IconData = new ui.IconData(
    59811,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly dry: ui.IconData = new ui.IconData(61875, {
    fontFamily: "MaterialIcons",
  });
  public static readonly dry_cleaning: ui.IconData = new ui.IconData(59992, {
    fontFamily: "MaterialIcons",
  });
  public static readonly duo: ui.IconData = new ui.IconData(59813, {
    fontFamily: "MaterialIcons",
  });
  public static readonly dvr: ui.IconData = new ui.IconData(57778, {
    fontFamily: "MaterialIcons",
  });
  public static readonly dynamic_feed: ui.IconData = new ui.IconData(59924, {
    fontFamily: "MaterialIcons",
  });
  public static readonly dynamic_form: ui.IconData = new ui.IconData(61887, {
    fontFamily: "MaterialIcons",
  });
  public static readonly e_mobiledata: ui.IconData = new ui.IconData(61442, {
    fontFamily: "MaterialIcons",
  });
  public static readonly earbuds: ui.IconData = new ui.IconData(61443, {
    fontFamily: "MaterialIcons",
  });
  public static readonly earbuds_battery: ui.IconData = new ui.IconData(61444, {
    fontFamily: "MaterialIcons",
  });
  public static readonly east: ui.IconData = new ui.IconData(61919, {
    fontFamily: "MaterialIcons",
  });
  public static readonly edgesensor_high: ui.IconData = new ui.IconData(61445, {
    fontFamily: "MaterialIcons",
  });
  public static readonly edgesensor_low: ui.IconData = new ui.IconData(61446, {
    fontFamily: "MaterialIcons",
  });
  public static readonly edit: ui.IconData = new ui.IconData(58313, {
    fontFamily: "MaterialIcons",
  });
  public static readonly edit_attributes: ui.IconData = new ui.IconData(58744, {
    fontFamily: "MaterialIcons",
  });
  public static readonly edit_calendar: ui.IconData = new ui.IconData(59202, {
    fontFamily: "MaterialIcons",
  });
  public static readonly edit_location: ui.IconData = new ui.IconData(58728, {
    fontFamily: "MaterialIcons",
  });
  public static readonly edit_location_alt: ui.IconData = new ui.IconData(
    57797,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly edit_note: ui.IconData = new ui.IconData(59205, {
    fontFamily: "MaterialIcons",
  });
  public static readonly edit_notifications: ui.IconData = new ui.IconData(
    58661,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly edit_off: ui.IconData = new ui.IconData(59728, {
    fontFamily: "MaterialIcons",
  });
  public static readonly edit_road: ui.IconData = new ui.IconData(61261, {
    fontFamily: "MaterialIcons",
  });
  public static readonly egg: ui.IconData = new ui.IconData(60108, {
    fontFamily: "MaterialIcons",
  });
  public static readonly egg_alt: ui.IconData = new ui.IconData(60104, {
    fontFamily: "MaterialIcons",
  });
  public static readonly eject: ui.IconData = new ui.IconData(59643, {
    fontFamily: "MaterialIcons",
  });
  public static readonly elderly: ui.IconData = new ui.IconData(61978, {
    fontFamily: "MaterialIcons",
  });
  public static readonly elderly_woman: ui.IconData = new ui.IconData(60265, {
    fontFamily: "MaterialIcons",
  });
  public static readonly electric_bike: ui.IconData = new ui.IconData(60187, {
    fontFamily: "MaterialIcons",
  });
  public static readonly electric_car: ui.IconData = new ui.IconData(60188, {
    fontFamily: "MaterialIcons",
  });
  public static readonly electric_moped: ui.IconData = new ui.IconData(60189, {
    fontFamily: "MaterialIcons",
  });
  public static readonly electric_rickshaw: ui.IconData = new ui.IconData(
    60190,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly electric_scooter: ui.IconData = new ui.IconData(
    60191,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly electrical_services: ui.IconData = new ui.IconData(
    61698,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly elevator: ui.IconData = new ui.IconData(61856, {
    fontFamily: "MaterialIcons",
  });
  public static readonly email: ui.IconData = new ui.IconData(57534, {
    fontFamily: "MaterialIcons",
  });
  public static readonly emergency: ui.IconData = new ui.IconData(57835, {
    fontFamily: "MaterialIcons",
  });
  public static readonly emergency_recording: ui.IconData = new ui.IconData(
    60404,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly emergency_share: ui.IconData = new ui.IconData(60406, {
    fontFamily: "MaterialIcons",
  });
  public static readonly emoji_emotions: ui.IconData = new ui.IconData(59938, {
    fontFamily: "MaterialIcons",
  });
  public static readonly emoji_events: ui.IconData = new ui.IconData(59939, {
    fontFamily: "MaterialIcons",
  });
  public static readonly emoji_food_beverage: ui.IconData = new ui.IconData(
    59931,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly emoji_nature: ui.IconData = new ui.IconData(59932, {
    fontFamily: "MaterialIcons",
  });
  public static readonly emoji_objects: ui.IconData = new ui.IconData(59940, {
    fontFamily: "MaterialIcons",
  });
  public static readonly emoji_people: ui.IconData = new ui.IconData(59933, {
    fontFamily: "MaterialIcons",
  });
  public static readonly emoji_symbols: ui.IconData = new ui.IconData(59934, {
    fontFamily: "MaterialIcons",
  });
  public static readonly emoji_transportation: ui.IconData = new ui.IconData(
    59935,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly engineering: ui.IconData = new ui.IconData(59965, {
    fontFamily: "MaterialIcons",
  });
  public static readonly enhanced_encryption: ui.IconData = new ui.IconData(
    58943,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly equalizer: ui.IconData = new ui.IconData(57373, {
    fontFamily: "MaterialIcons",
  });
  public static readonly error: ui.IconData = new ui.IconData(57344, {
    fontFamily: "MaterialIcons",
  });
  public static readonly error_outline: ui.IconData = new ui.IconData(57345, {
    fontFamily: "MaterialIcons",
  });
  public static readonly escalator: ui.IconData = new ui.IconData(61857, {
    fontFamily: "MaterialIcons",
  });
  public static readonly escalator_warning: ui.IconData = new ui.IconData(
    61868,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly euro: ui.IconData = new ui.IconData(59925, {
    fontFamily: "MaterialIcons",
  });
  public static readonly euro_symbol: ui.IconData = new ui.IconData(59686, {
    fontFamily: "MaterialIcons",
  });
  public static readonly ev_station: ui.IconData = new ui.IconData(58733, {
    fontFamily: "MaterialIcons",
  });
  public static readonly event: ui.IconData = new ui.IconData(59512, {
    fontFamily: "MaterialIcons",
  });
  public static readonly event_available: ui.IconData = new ui.IconData(58900, {
    fontFamily: "MaterialIcons",
  });
  public static readonly event_busy: ui.IconData = new ui.IconData(58901, {
    fontFamily: "MaterialIcons",
  });
  public static readonly event_note: ui.IconData = new ui.IconData(58902, {
    fontFamily: "MaterialIcons",
  });
  public static readonly event_repeat: ui.IconData = new ui.IconData(60283, {
    fontFamily: "MaterialIcons",
  });
  public static readonly event_seat: ui.IconData = new ui.IconData(59651, {
    fontFamily: "MaterialIcons",
  });
  public static readonly exit_to_app: ui.IconData = new ui.IconData(59513, {
    fontFamily: "MaterialIcons",
  });
  public static readonly expand: ui.IconData = new ui.IconData(59727, {
    fontFamily: "MaterialIcons",
  });
  public static readonly expand_circle_down: ui.IconData = new ui.IconData(
    59341,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly expand_less: ui.IconData = new ui.IconData(58830, {
    fontFamily: "MaterialIcons",
  });
  public static readonly expand_more: ui.IconData = new ui.IconData(58831, {
    fontFamily: "MaterialIcons",
  });
  public static readonly explicit: ui.IconData = new ui.IconData(57374, {
    fontFamily: "MaterialIcons",
  });
  public static readonly explore: ui.IconData = new ui.IconData(59514, {
    fontFamily: "MaterialIcons",
  });
  public static readonly explore_off: ui.IconData = new ui.IconData(59816, {
    fontFamily: "MaterialIcons",
  });
  public static readonly exposure: ui.IconData = new ui.IconData(58314, {
    fontFamily: "MaterialIcons",
  });
  public static readonly exposure_neg_1: ui.IconData = new ui.IconData(58315, {
    fontFamily: "MaterialIcons",
  });
  public static readonly exposure_neg_2: ui.IconData = new ui.IconData(58316, {
    fontFamily: "MaterialIcons",
  });
  public static readonly exposure_plus_1: ui.IconData = new ui.IconData(58317, {
    fontFamily: "MaterialIcons",
  });
  public static readonly exposure_plus_2: ui.IconData = new ui.IconData(58318, {
    fontFamily: "MaterialIcons",
  });
  public static readonly exposure_zero: ui.IconData = new ui.IconData(58319, {
    fontFamily: "MaterialIcons",
  });
  public static readonly extension: ui.IconData = new ui.IconData(59515, {
    fontFamily: "MaterialIcons",
  });
  public static readonly extension_off: ui.IconData = new ui.IconData(58613, {
    fontFamily: "MaterialIcons",
  });
  public static readonly face: ui.IconData = new ui.IconData(59516, {
    fontFamily: "MaterialIcons",
  });
  public static readonly face_retouching_natural: ui.IconData = new ui.IconData(
    61262,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly face_retouching_off: ui.IconData = new ui.IconData(
    61447,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly fact_check: ui.IconData = new ui.IconData(61637, {
    fontFamily: "MaterialIcons",
  });
  public static readonly factory: ui.IconData = new ui.IconData(60348, {
    fontFamily: "MaterialIcons",
  });
  public static readonly family_restroom: ui.IconData = new ui.IconData(61858, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fast_forward: ui.IconData = new ui.IconData(57375, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fast_rewind: ui.IconData = new ui.IconData(57376, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fastfood: ui.IconData = new ui.IconData(58746, {
    fontFamily: "MaterialIcons",
  });
  public static readonly favorite: ui.IconData = new ui.IconData(59517, {
    fontFamily: "MaterialIcons",
  });
  public static readonly favorite_border: ui.IconData = new ui.IconData(59518, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fax: ui.IconData = new ui.IconData(60120, {
    fontFamily: "MaterialIcons",
  });
  public static readonly featured_play_list: ui.IconData = new ui.IconData(
    57453,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly featured_video: ui.IconData = new ui.IconData(57454, {
    fontFamily: "MaterialIcons",
  });
  public static readonly feed: ui.IconData = new ui.IconData(61449, {
    fontFamily: "MaterialIcons",
  });
  public static readonly feedback: ui.IconData = new ui.IconData(59519, {
    fontFamily: "MaterialIcons",
  });
  public static readonly female: ui.IconData = new ui.IconData(58768, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fence: ui.IconData = new ui.IconData(61942, {
    fontFamily: "MaterialIcons",
  });
  public static readonly festival: ui.IconData = new ui.IconData(60008, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fiber_dvr: ui.IconData = new ui.IconData(57437, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fiber_manual_record: ui.IconData = new ui.IconData(
    57441,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly fiber_new: ui.IconData = new ui.IconData(57438, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fiber_pin: ui.IconData = new ui.IconData(57450, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fiber_smart_record: ui.IconData = new ui.IconData(
    57442,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly file_copy: ui.IconData = new ui.IconData(57715, {
    fontFamily: "MaterialIcons",
  });
  public static readonly file_download: ui.IconData = new ui.IconData(58052, {
    fontFamily: "MaterialIcons",
  });
  public static readonly file_download_done: ui.IconData = new ui.IconData(
    59818,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly file_download_off: ui.IconData = new ui.IconData(
    58622,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly file_open: ui.IconData = new ui.IconData(60147, {
    fontFamily: "MaterialIcons",
  });
  public static readonly file_present: ui.IconData = new ui.IconData(59918, {
    fontFamily: "MaterialIcons",
  });
  public static readonly file_upload: ui.IconData = new ui.IconData(58054, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter: ui.IconData = new ui.IconData(58323, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_1: ui.IconData = new ui.IconData(58320, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_2: ui.IconData = new ui.IconData(58321, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_3: ui.IconData = new ui.IconData(58322, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_4: ui.IconData = new ui.IconData(58324, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_5: ui.IconData = new ui.IconData(58325, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_6: ui.IconData = new ui.IconData(58326, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_7: ui.IconData = new ui.IconData(58327, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_8: ui.IconData = new ui.IconData(58328, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_9: ui.IconData = new ui.IconData(58329, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_9_plus: ui.IconData = new ui.IconData(58330, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_alt: ui.IconData = new ui.IconData(61263, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_alt_off: ui.IconData = new ui.IconData(60210, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_b_and_w: ui.IconData = new ui.IconData(58331, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_center_focus: ui.IconData = new ui.IconData(
    58332,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly filter_drama: ui.IconData = new ui.IconData(58333, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_frames: ui.IconData = new ui.IconData(58334, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_hdr: ui.IconData = new ui.IconData(58335, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_list: ui.IconData = new ui.IconData(57682, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_list_alt: ui.IconData = new ui.IconData(59726, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_list_off: ui.IconData = new ui.IconData(60247, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_none: ui.IconData = new ui.IconData(58336, {
    fontFamily: "MaterialIcons",
  });
  public static readonly filter_tilt_shift: ui.IconData = new ui.IconData(
    58338,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly filter_vintage: ui.IconData = new ui.IconData(58339, {
    fontFamily: "MaterialIcons",
  });
  public static readonly find_in_page: ui.IconData = new ui.IconData(59520, {
    fontFamily: "MaterialIcons",
  });
  public static readonly find_replace: ui.IconData = new ui.IconData(59521, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fingerprint: ui.IconData = new ui.IconData(59661, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fire_extinguisher: ui.IconData = new ui.IconData(
    61912,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly fireplace: ui.IconData = new ui.IconData(59971, {
    fontFamily: "MaterialIcons",
  });
  public static readonly first_page: ui.IconData = new ui.IconData(58844, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fit_screen: ui.IconData = new ui.IconData(59920, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fitbit: ui.IconData = new ui.IconData(59435, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fitness_center: ui.IconData = new ui.IconData(60227, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flag: ui.IconData = new ui.IconData(57683, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flag_circle: ui.IconData = new ui.IconData(60152, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flaky: ui.IconData = new ui.IconData(61264, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flare: ui.IconData = new ui.IconData(58340, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flash_auto: ui.IconData = new ui.IconData(58341, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flash_off: ui.IconData = new ui.IconData(58342, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flash_on: ui.IconData = new ui.IconData(58343, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flashlight_off: ui.IconData = new ui.IconData(61450, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flashlight_on: ui.IconData = new ui.IconData(61451, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flatware: ui.IconData = new ui.IconData(61452, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flight: ui.IconData = new ui.IconData(58681, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flight_class: ui.IconData = new ui.IconData(59339, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flight_land: ui.IconData = new ui.IconData(59652, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flight_takeoff: ui.IconData = new ui.IconData(59653, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flip: ui.IconData = new ui.IconData(58344, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flip_camera_android: ui.IconData = new ui.IconData(
    59959,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly flip_camera_ios: ui.IconData = new ui.IconData(59960, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flip_to_back: ui.IconData = new ui.IconData(59522, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flip_to_front: ui.IconData = new ui.IconData(59523, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flood: ui.IconData = new ui.IconData(60390, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flourescent: ui.IconData = new ui.IconData(61453, {
    fontFamily: "MaterialIcons",
  });
  public static readonly flutter_dash: ui.IconData = new ui.IconData(57355, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fmd_bad: ui.IconData = new ui.IconData(61454, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fmd_good: ui.IconData = new ui.IconData(61455, {
    fontFamily: "MaterialIcons",
  });
  public static readonly foggy: ui.IconData = new ui.IconData(59416, {
    fontFamily: "MaterialIcons",
  });
  public static readonly folder: ui.IconData = new ui.IconData(58055, {
    fontFamily: "MaterialIcons",
  });
  public static readonly folder_copy: ui.IconData = new ui.IconData(60349, {
    fontFamily: "MaterialIcons",
  });
  public static readonly folder_delete: ui.IconData = new ui.IconData(60212, {
    fontFamily: "MaterialIcons",
  });
  public static readonly folder_off: ui.IconData = new ui.IconData(60291, {
    fontFamily: "MaterialIcons",
  });
  public static readonly folder_open: ui.IconData = new ui.IconData(58056, {
    fontFamily: "MaterialIcons",
  });
  public static readonly folder_shared: ui.IconData = new ui.IconData(58057, {
    fontFamily: "MaterialIcons",
  });
  public static readonly folder_special: ui.IconData = new ui.IconData(58903, {
    fontFamily: "MaterialIcons",
  });
  public static readonly folder_zip: ui.IconData = new ui.IconData(60204, {
    fontFamily: "MaterialIcons",
  });
  public static readonly follow_the_signs: ui.IconData = new ui.IconData(
    61986,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly font_download: ui.IconData = new ui.IconData(57703, {
    fontFamily: "MaterialIcons",
  });
  public static readonly font_download_off: ui.IconData = new ui.IconData(
    58617,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly food_bank: ui.IconData = new ui.IconData(61938, {
    fontFamily: "MaterialIcons",
  });
  public static readonly forest: ui.IconData = new ui.IconData(60057, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fork_left: ui.IconData = new ui.IconData(60320, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fork_right: ui.IconData = new ui.IconData(60332, {
    fontFamily: "MaterialIcons",
  });
  public static readonly format_align_center: ui.IconData = new ui.IconData(
    57908,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly format_align_justify: ui.IconData = new ui.IconData(
    57909,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly format_align_left: ui.IconData = new ui.IconData(
    57910,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly format_align_right: ui.IconData = new ui.IconData(
    57911,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly format_bold: ui.IconData = new ui.IconData(57912, {
    fontFamily: "MaterialIcons",
  });
  public static readonly format_clear: ui.IconData = new ui.IconData(57913, {
    fontFamily: "MaterialIcons",
  });
  public static readonly format_color_fill: ui.IconData = new ui.IconData(
    57914,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly format_color_reset: ui.IconData = new ui.IconData(
    57915,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly format_color_text: ui.IconData = new ui.IconData(
    57916,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly format_indent_decrease: ui.IconData = new ui.IconData(
    57917,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly format_indent_increase: ui.IconData = new ui.IconData(
    57918,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly format_italic: ui.IconData = new ui.IconData(57919, {
    fontFamily: "MaterialIcons",
  });
  public static readonly format_line_spacing: ui.IconData = new ui.IconData(
    57920,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly format_list_bulleted: ui.IconData = new ui.IconData(
    57921,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly format_list_numbered: ui.IconData = new ui.IconData(
    57922,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly format_list_numbered_rtl: ui.IconData =
    new ui.IconData(57959, { fontFamily: "MaterialIcons" });
  public static readonly format_overline: ui.IconData = new ui.IconData(60261, {
    fontFamily: "MaterialIcons",
  });
  public static readonly format_paint: ui.IconData = new ui.IconData(57923, {
    fontFamily: "MaterialIcons",
  });
  public static readonly format_quote: ui.IconData = new ui.IconData(57924, {
    fontFamily: "MaterialIcons",
  });
  public static readonly format_shapes: ui.IconData = new ui.IconData(57950, {
    fontFamily: "MaterialIcons",
  });
  public static readonly format_size: ui.IconData = new ui.IconData(57925, {
    fontFamily: "MaterialIcons",
  });
  public static readonly format_strikethrough: ui.IconData = new ui.IconData(
    57926,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly format_textdirection_l_to_r: ui.IconData =
    new ui.IconData(57927, { fontFamily: "MaterialIcons" });
  public static readonly format_textdirection_r_to_l: ui.IconData =
    new ui.IconData(57928, { fontFamily: "MaterialIcons" });
  public static readonly format_underlined: ui.IconData = new ui.IconData(
    57929,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly fort: ui.IconData = new ui.IconData(60077, {
    fontFamily: "MaterialIcons",
  });
  public static readonly forum: ui.IconData = new ui.IconData(57535, {
    fontFamily: "MaterialIcons",
  });
  public static readonly forward: ui.IconData = new ui.IconData(57684, {
    fontFamily: "MaterialIcons",
  });
  public static readonly forward_10: ui.IconData = new ui.IconData(57430, {
    fontFamily: "MaterialIcons",
  });
  public static readonly forward_30: ui.IconData = new ui.IconData(57431, {
    fontFamily: "MaterialIcons",
  });
  public static readonly forward_5: ui.IconData = new ui.IconData(57432, {
    fontFamily: "MaterialIcons",
  });
  public static readonly forward_to_inbox: ui.IconData = new ui.IconData(
    61831,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly foundation: ui.IconData = new ui.IconData(61952, {
    fontFamily: "MaterialIcons",
  });
  public static readonly free_breakfast: ui.IconData = new ui.IconData(60228, {
    fontFamily: "MaterialIcons",
  });
  public static readonly free_cancellation: ui.IconData = new ui.IconData(
    59208,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly front_hand: ui.IconData = new ui.IconData(59241, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fullscreen: ui.IconData = new ui.IconData(58832, {
    fontFamily: "MaterialIcons",
  });
  public static readonly fullscreen_exit: ui.IconData = new ui.IconData(58833, {
    fontFamily: "MaterialIcons",
  });
  public static readonly functions: ui.IconData = new ui.IconData(57930, {
    fontFamily: "MaterialIcons",
  });
  public static readonly g_mobiledata: ui.IconData = new ui.IconData(61456, {
    fontFamily: "MaterialIcons",
  });
  public static readonly g_translate: ui.IconData = new ui.IconData(59687, {
    fontFamily: "MaterialIcons",
  });
  public static readonly gamepad: ui.IconData = new ui.IconData(58127, {
    fontFamily: "MaterialIcons",
  });
  public static readonly games: ui.IconData = new ui.IconData(57377, {
    fontFamily: "MaterialIcons",
  });
  public static readonly garage: ui.IconData = new ui.IconData(61457, {
    fontFamily: "MaterialIcons",
  });
  public static readonly gavel: ui.IconData = new ui.IconData(59662, {
    fontFamily: "MaterialIcons",
  });
  public static readonly generating_tokens: ui.IconData = new ui.IconData(
    59209,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly gesture: ui.IconData = new ui.IconData(57685, {
    fontFamily: "MaterialIcons",
  });
  public static readonly get_app: ui.IconData = new ui.IconData(59524, {
    fontFamily: "MaterialIcons",
  });
  public static readonly gif: ui.IconData = new ui.IconData(59656, {
    fontFamily: "MaterialIcons",
  });
  public static readonly gif_box: ui.IconData = new ui.IconData(59299, {
    fontFamily: "MaterialIcons",
  });
  public static readonly girl: ui.IconData = new ui.IconData(60264, {
    fontFamily: "MaterialIcons",
  });
  public static readonly gite: ui.IconData = new ui.IconData(58763, {
    fontFamily: "MaterialIcons",
  });
  public static readonly golf_course: ui.IconData = new ui.IconData(60229, {
    fontFamily: "MaterialIcons",
  });
  public static readonly gpp_bad: ui.IconData = new ui.IconData(61458, {
    fontFamily: "MaterialIcons",
  });
  public static readonly gpp_good: ui.IconData = new ui.IconData(61459, {
    fontFamily: "MaterialIcons",
  });
  public static readonly gpp_maybe: ui.IconData = new ui.IconData(61460, {
    fontFamily: "MaterialIcons",
  });
  public static readonly gps_fixed: ui.IconData = new ui.IconData(57779, {
    fontFamily: "MaterialIcons",
  });
  public static readonly gps_not_fixed: ui.IconData = new ui.IconData(57780, {
    fontFamily: "MaterialIcons",
  });
  public static readonly gps_off: ui.IconData = new ui.IconData(57781, {
    fontFamily: "MaterialIcons",
  });
  public static readonly grade: ui.IconData = new ui.IconData(59525, {
    fontFamily: "MaterialIcons",
  });
  public static readonly gradient: ui.IconData = new ui.IconData(58345, {
    fontFamily: "MaterialIcons",
  });
  public static readonly grading: ui.IconData = new ui.IconData(59983, {
    fontFamily: "MaterialIcons",
  });
  public static readonly grain: ui.IconData = new ui.IconData(58346, {
    fontFamily: "MaterialIcons",
  });
  public static readonly graphic_eq: ui.IconData = new ui.IconData(57784, {
    fontFamily: "MaterialIcons",
  });
  public static readonly grass: ui.IconData = new ui.IconData(61957, {
    fontFamily: "MaterialIcons",
  });
  public static readonly grid_3x3: ui.IconData = new ui.IconData(61461, {
    fontFamily: "MaterialIcons",
  });
  public static readonly grid_4x4: ui.IconData = new ui.IconData(61462, {
    fontFamily: "MaterialIcons",
  });
  public static readonly grid_goldenratio: ui.IconData = new ui.IconData(
    61463,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly grid_off: ui.IconData = new ui.IconData(58347, {
    fontFamily: "MaterialIcons",
  });
  public static readonly grid_on: ui.IconData = new ui.IconData(58348, {
    fontFamily: "MaterialIcons",
  });
  public static readonly grid_view: ui.IconData = new ui.IconData(59824, {
    fontFamily: "MaterialIcons",
  });
  public static readonly group: ui.IconData = new ui.IconData(59375, {
    fontFamily: "MaterialIcons",
  });
  public static readonly group_add: ui.IconData = new ui.IconData(59376, {
    fontFamily: "MaterialIcons",
  });
  public static readonly group_off: ui.IconData = new ui.IconData(59207, {
    fontFamily: "MaterialIcons",
  });
  public static readonly group_remove: ui.IconData = new ui.IconData(59309, {
    fontFamily: "MaterialIcons",
  });
  public static readonly group_work: ui.IconData = new ui.IconData(59526, {
    fontFamily: "MaterialIcons",
  });
  public static readonly groups: ui.IconData = new ui.IconData(62003, {
    fontFamily: "MaterialIcons",
  });
  public static readonly h_mobiledata: ui.IconData = new ui.IconData(61464, {
    fontFamily: "MaterialIcons",
  });
  public static readonly h_plus_mobiledata: ui.IconData = new ui.IconData(
    61465,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly hail: ui.IconData = new ui.IconData(59825, {
    fontFamily: "MaterialIcons",
  });
  public static readonly handshake: ui.IconData = new ui.IconData(60363, {
    fontFamily: "MaterialIcons",
  });
  public static readonly handyman: ui.IconData = new ui.IconData(61707, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hardware: ui.IconData = new ui.IconData(59993, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hd: ui.IconData = new ui.IconData(57426, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hdr_auto: ui.IconData = new ui.IconData(61466, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hdr_auto_select: ui.IconData = new ui.IconData(61467, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hdr_enhanced_select: ui.IconData = new ui.IconData(
    61265,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly hdr_off: ui.IconData = new ui.IconData(58349, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hdr_off_select: ui.IconData = new ui.IconData(61468, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hdr_on: ui.IconData = new ui.IconData(58350, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hdr_on_select: ui.IconData = new ui.IconData(61469, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hdr_plus: ui.IconData = new ui.IconData(61470, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hdr_strong: ui.IconData = new ui.IconData(58353, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hdr_weak: ui.IconData = new ui.IconData(58354, {
    fontFamily: "MaterialIcons",
  });
  public static readonly headphones: ui.IconData = new ui.IconData(61471, {
    fontFamily: "MaterialIcons",
  });
  public static readonly headphones_battery: ui.IconData = new ui.IconData(
    61472,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly headset: ui.IconData = new ui.IconData(58128, {
    fontFamily: "MaterialIcons",
  });
  public static readonly headset_mic: ui.IconData = new ui.IconData(58129, {
    fontFamily: "MaterialIcons",
  });
  public static readonly headset_off: ui.IconData = new ui.IconData(58170, {
    fontFamily: "MaterialIcons",
  });
  public static readonly healing: ui.IconData = new ui.IconData(58355, {
    fontFamily: "MaterialIcons",
  });
  public static readonly health_and_safety: ui.IconData = new ui.IconData(
    57813,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly hearing: ui.IconData = new ui.IconData(57379, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hearing_disabled: ui.IconData = new ui.IconData(
    61700,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly heart_broken: ui.IconData = new ui.IconData(60098, {
    fontFamily: "MaterialIcons",
  });
  public static readonly height: ui.IconData = new ui.IconData(59926, {
    fontFamily: "MaterialIcons",
  });
  public static readonly help: ui.IconData = new ui.IconData(59527, {
    fontFamily: "MaterialIcons",
  });
  public static readonly help_center: ui.IconData = new ui.IconData(61888, {
    fontFamily: "MaterialIcons",
  });
  public static readonly help_outline: ui.IconData = new ui.IconData(59645, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hevc: ui.IconData = new ui.IconData(61473, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hexagon: ui.IconData = new ui.IconData(60217, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hide_image: ui.IconData = new ui.IconData(61474, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hide_source: ui.IconData = new ui.IconData(61475, {
    fontFamily: "MaterialIcons",
  });
  public static readonly high_quality: ui.IconData = new ui.IconData(57380, {
    fontFamily: "MaterialIcons",
  });
  public static readonly highlight: ui.IconData = new ui.IconData(57951, {
    fontFamily: "MaterialIcons",
  });
  public static readonly highlight_alt: ui.IconData = new ui.IconData(61266, {
    fontFamily: "MaterialIcons",
  });
  public static readonly highlight_off: ui.IconData = new ui.IconData(59528, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hiking: ui.IconData = new ui.IconData(58634, {
    fontFamily: "MaterialIcons",
  });
  public static readonly history: ui.IconData = new ui.IconData(59529, {
    fontFamily: "MaterialIcons",
  });
  public static readonly history_edu: ui.IconData = new ui.IconData(59966, {
    fontFamily: "MaterialIcons",
  });
  public static readonly history_toggle_off: ui.IconData = new ui.IconData(
    61821,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly hive: ui.IconData = new ui.IconData(60070, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hls: ui.IconData = new ui.IconData(60298, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hls_off: ui.IconData = new ui.IconData(60300, {
    fontFamily: "MaterialIcons",
  });
  public static readonly holiday_village: ui.IconData = new ui.IconData(58762, {
    fontFamily: "MaterialIcons",
  });
  public static readonly home: ui.IconData = new ui.IconData(59530, {
    fontFamily: "MaterialIcons",
  });
  public static readonly home_filled: ui.IconData = new ui.IconData(59826, {
    fontFamily: "MaterialIcons",
  });
  public static readonly home_max: ui.IconData = new ui.IconData(61476, {
    fontFamily: "MaterialIcons",
  });
  public static readonly home_mini: ui.IconData = new ui.IconData(61477, {
    fontFamily: "MaterialIcons",
  });
  public static readonly home_repair_service: ui.IconData = new ui.IconData(
    61696,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly home_work: ui.IconData = new ui.IconData(59913, {
    fontFamily: "MaterialIcons",
  });
  public static readonly horizontal_distribute: ui.IconData = new ui.IconData(
    57364,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly horizontal_rule: ui.IconData = new ui.IconData(61704, {
    fontFamily: "MaterialIcons",
  });
  public static readonly horizontal_split: ui.IconData = new ui.IconData(
    59719,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly hot_tub: ui.IconData = new ui.IconData(60230, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hotel: ui.IconData = new ui.IconData(58682, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hotel_class: ui.IconData = new ui.IconData(59203, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hourglass_bottom: ui.IconData = new ui.IconData(
    59996,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly hourglass_disabled: ui.IconData = new ui.IconData(
    61267,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly hourglass_empty: ui.IconData = new ui.IconData(59531, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hourglass_full: ui.IconData = new ui.IconData(59532, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hourglass_top: ui.IconData = new ui.IconData(59995, {
    fontFamily: "MaterialIcons",
  });
  public static readonly house: ui.IconData = new ui.IconData(59972, {
    fontFamily: "MaterialIcons",
  });
  public static readonly house_siding: ui.IconData = new ui.IconData(61954, {
    fontFamily: "MaterialIcons",
  });
  public static readonly houseboat: ui.IconData = new ui.IconData(58756, {
    fontFamily: "MaterialIcons",
  });
  public static readonly how_to_reg: ui.IconData = new ui.IconData(57716, {
    fontFamily: "MaterialIcons",
  });
  public static readonly how_to_vote: ui.IconData = new ui.IconData(57717, {
    fontFamily: "MaterialIcons",
  });
  public static readonly html: ui.IconData = new ui.IconData(60286, {
    fontFamily: "MaterialIcons",
  });
  public static readonly http: ui.IconData = new ui.IconData(59650, {
    fontFamily: "MaterialIcons",
  });
  public static readonly https: ui.IconData = new ui.IconData(59533, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hub: ui.IconData = new ui.IconData(59892, {
    fontFamily: "MaterialIcons",
  });
  public static readonly hvac: ui.IconData = new ui.IconData(61710, {
    fontFamily: "MaterialIcons",
  });
  public static readonly ice_skating: ui.IconData = new ui.IconData(58635, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icecream: ui.IconData = new ui.IconData(60009, {
    fontFamily: "MaterialIcons",
  });
  public static readonly image: ui.IconData = new ui.IconData(58356, {
    fontFamily: "MaterialIcons",
  });
  public static readonly image_aspect_ratio: ui.IconData = new ui.IconData(
    58357,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly image_not_supported: ui.IconData = new ui.IconData(
    61718,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly image_search: ui.IconData = new ui.IconData(58431, {
    fontFamily: "MaterialIcons",
  });
  public static readonly imagesearch_roller: ui.IconData = new ui.IconData(
    59828,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly import_contacts: ui.IconData = new ui.IconData(57568, {
    fontFamily: "MaterialIcons",
  });
  public static readonly import_export: ui.IconData = new ui.IconData(57539, {
    fontFamily: "MaterialIcons",
  });
  public static readonly important_devices: ui.IconData = new ui.IconData(
    59666,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly inbox: ui.IconData = new ui.IconData(57686, {
    fontFamily: "MaterialIcons",
  });
  public static readonly incomplete_circle: ui.IconData = new ui.IconData(
    59291,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly indeterminate_check_box: ui.IconData = new ui.IconData(
    59657,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly info: ui.IconData = new ui.IconData(59534, {
    fontFamily: "MaterialIcons",
  });
  public static readonly info_outline: ui.IconData = new ui.IconData(59535, {
    fontFamily: "MaterialIcons",
  });
  public static readonly input: ui.IconData = new ui.IconData(59536, {
    fontFamily: "MaterialIcons",
  });
  public static readonly insert_chart: ui.IconData = new ui.IconData(57931, {
    fontFamily: "MaterialIcons",
  });
  public static readonly insert_chart_outlined: ui.IconData = new ui.IconData(
    57962,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly insert_comment: ui.IconData = new ui.IconData(57932, {
    fontFamily: "MaterialIcons",
  });
  public static readonly insert_drive_file: ui.IconData = new ui.IconData(
    57933,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly insert_emoticon: ui.IconData = new ui.IconData(57934, {
    fontFamily: "MaterialIcons",
  });
  public static readonly insert_invitation: ui.IconData = new ui.IconData(
    57935,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly insert_link: ui.IconData = new ui.IconData(57936, {
    fontFamily: "MaterialIcons",
  });
  public static readonly insert_page_break: ui.IconData = new ui.IconData(
    60106,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly insert_photo: ui.IconData = new ui.IconData(57937, {
    fontFamily: "MaterialIcons",
  });
  public static readonly insights: ui.IconData = new ui.IconData(61586, {
    fontFamily: "MaterialIcons",
  });
  public static readonly install_desktop: ui.IconData = new ui.IconData(60273, {
    fontFamily: "MaterialIcons",
  });
  public static readonly install_mobile: ui.IconData = new ui.IconData(60274, {
    fontFamily: "MaterialIcons",
  });
  public static readonly integration_instructions: ui.IconData =
    new ui.IconData(61268, { fontFamily: "MaterialIcons" });
  public static readonly interests: ui.IconData = new ui.IconData(59336, {
    fontFamily: "MaterialIcons",
  });
  public static readonly interpreter_mode: ui.IconData = new ui.IconData(
    59451,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly inventory: ui.IconData = new ui.IconData(57721, {
    fontFamily: "MaterialIcons",
  });
  public static readonly inventory_2: ui.IconData = new ui.IconData(57761, {
    fontFamily: "MaterialIcons",
  });
  public static readonly invert_colors: ui.IconData = new ui.IconData(59537, {
    fontFamily: "MaterialIcons",
  });
  public static readonly invert_colors_off: ui.IconData = new ui.IconData(
    57540,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly ios_share: ui.IconData = new ui.IconData(59064, {
    fontFamily: "MaterialIcons",
  });
  public static readonly iron: ui.IconData = new ui.IconData(58755, {
    fontFamily: "MaterialIcons",
  });
  public static readonly iso: ui.IconData = new ui.IconData(58358, {
    fontFamily: "MaterialIcons",
  });
  public static readonly javascript: ui.IconData = new ui.IconData(60284, {
    fontFamily: "MaterialIcons",
  });
  public static readonly join_full: ui.IconData = new ui.IconData(60139, {
    fontFamily: "MaterialIcons",
  });
  public static readonly join_inner: ui.IconData = new ui.IconData(60148, {
    fontFamily: "MaterialIcons",
  });
  public static readonly join_left: ui.IconData = new ui.IconData(60146, {
    fontFamily: "MaterialIcons",
  });
  public static readonly join_right: ui.IconData = new ui.IconData(60138, {
    fontFamily: "MaterialIcons",
  });
  public static readonly kayaking: ui.IconData = new ui.IconData(58636, {
    fontFamily: "MaterialIcons",
  });
  public static readonly kebab_dining: ui.IconData = new ui.IconData(59458, {
    fontFamily: "MaterialIcons",
  });
  public static readonly key: ui.IconData = new ui.IconData(59196, {
    fontFamily: "MaterialIcons",
  });
  public static readonly key_off: ui.IconData = new ui.IconData(60292, {
    fontFamily: "MaterialIcons",
  });
  public static readonly keyboard: ui.IconData = new ui.IconData(58130, {
    fontFamily: "MaterialIcons",
  });
  public static readonly keyboard_alt: ui.IconData = new ui.IconData(61480, {
    fontFamily: "MaterialIcons",
  });
  public static readonly keyboard_arrow_down: ui.IconData = new ui.IconData(
    58131,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly keyboard_arrow_left: ui.IconData = new ui.IconData(
    58132,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly keyboard_arrow_right: ui.IconData = new ui.IconData(
    58133,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly keyboard_arrow_up: ui.IconData = new ui.IconData(
    58134,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly keyboard_backspace: ui.IconData = new ui.IconData(
    58135,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly keyboard_capslock: ui.IconData = new ui.IconData(
    58136,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly keyboard_command_key: ui.IconData = new ui.IconData(
    60135,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly keyboard_control_key: ui.IconData = new ui.IconData(
    60134,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly keyboard_double_arrow_down: ui.IconData =
    new ui.IconData(60112, { fontFamily: "MaterialIcons" });
  public static readonly keyboard_double_arrow_left: ui.IconData =
    new ui.IconData(60099, { fontFamily: "MaterialIcons" });
  public static readonly keyboard_double_arrow_right: ui.IconData =
    new ui.IconData(60105, { fontFamily: "MaterialIcons" });
  public static readonly keyboard_double_arrow_up: ui.IconData =
    new ui.IconData(60111, { fontFamily: "MaterialIcons" });
  public static readonly keyboard_hide: ui.IconData = new ui.IconData(58138, {
    fontFamily: "MaterialIcons",
  });
  public static readonly keyboard_option_key: ui.IconData = new ui.IconData(
    60136,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly keyboard_return: ui.IconData = new ui.IconData(58139, {
    fontFamily: "MaterialIcons",
  });
  public static readonly keyboard_tab: ui.IconData = new ui.IconData(58140, {
    fontFamily: "MaterialIcons",
  });
  public static readonly keyboard_voice: ui.IconData = new ui.IconData(58141, {
    fontFamily: "MaterialIcons",
  });
  public static readonly king_bed: ui.IconData = new ui.IconData(59973, {
    fontFamily: "MaterialIcons",
  });
  public static readonly kitchen: ui.IconData = new ui.IconData(60231, {
    fontFamily: "MaterialIcons",
  });
  public static readonly kitesurfing: ui.IconData = new ui.IconData(58637, {
    fontFamily: "MaterialIcons",
  });
  public static readonly label: ui.IconData = new ui.IconData(59538, {
    fontFamily: "MaterialIcons",
  });
  public static readonly label_important: ui.IconData = new ui.IconData(59703, {
    fontFamily: "MaterialIcons",
  });
  public static readonly label_important_outline: ui.IconData = new ui.IconData(
    59720,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly label_off: ui.IconData = new ui.IconData(59830, {
    fontFamily: "MaterialIcons",
  });
  public static readonly lan: ui.IconData = new ui.IconData(60207, {
    fontFamily: "MaterialIcons",
  });
  public static readonly landscape: ui.IconData = new ui.IconData(58359, {
    fontFamily: "MaterialIcons",
  });
  public static readonly landslide: ui.IconData = new ui.IconData(60375, {
    fontFamily: "MaterialIcons",
  });
  public static readonly language: ui.IconData = new ui.IconData(59540, {
    fontFamily: "MaterialIcons",
  });
  public static readonly laptop: ui.IconData = new ui.IconData(58142, {
    fontFamily: "MaterialIcons",
  });
  public static readonly laptop_chromebook: ui.IconData = new ui.IconData(
    58143,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly laptop_mac: ui.IconData = new ui.IconData(58144, {
    fontFamily: "MaterialIcons",
  });
  public static readonly laptop_windows: ui.IconData = new ui.IconData(58145, {
    fontFamily: "MaterialIcons",
  });
  public static readonly last_page: ui.IconData = new ui.IconData(58845, {
    fontFamily: "MaterialIcons",
  });
  public static readonly launch: ui.IconData = new ui.IconData(59541, {
    fontFamily: "MaterialIcons",
  });
  public static readonly layers: ui.IconData = new ui.IconData(58683, {
    fontFamily: "MaterialIcons",
  });
  public static readonly layers_clear: ui.IconData = new ui.IconData(58684, {
    fontFamily: "MaterialIcons",
  });
  public static readonly leaderboard: ui.IconData = new ui.IconData(61964, {
    fontFamily: "MaterialIcons",
  });
  public static readonly leak_add: ui.IconData = new ui.IconData(58360, {
    fontFamily: "MaterialIcons",
  });
  public static readonly leak_remove: ui.IconData = new ui.IconData(58361, {
    fontFamily: "MaterialIcons",
  });
  public static readonly legend_toggle: ui.IconData = new ui.IconData(61723, {
    fontFamily: "MaterialIcons",
  });
  public static readonly lens: ui.IconData = new ui.IconData(58362, {
    fontFamily: "MaterialIcons",
  });
  public static readonly lens_blur: ui.IconData = new ui.IconData(61481, {
    fontFamily: "MaterialIcons",
  });
  public static readonly library_add: ui.IconData = new ui.IconData(57390, {
    fontFamily: "MaterialIcons",
  });
  public static readonly library_add_check: ui.IconData = new ui.IconData(
    59831,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly library_books: ui.IconData = new ui.IconData(57391, {
    fontFamily: "MaterialIcons",
  });
  public static readonly library_music: ui.IconData = new ui.IconData(57392, {
    fontFamily: "MaterialIcons",
  });
  public static readonly light: ui.IconData = new ui.IconData(61482, {
    fontFamily: "MaterialIcons",
  });
  public static readonly light_mode: ui.IconData = new ui.IconData(58648, {
    fontFamily: "MaterialIcons",
  });
  public static readonly lightbulb: ui.IconData = new ui.IconData(57584, {
    fontFamily: "MaterialIcons",
  });
  public static readonly lightbulb_circle: ui.IconData = new ui.IconData(
    60414,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly lightbulb_outline: ui.IconData = new ui.IconData(
    59663,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly line_axis: ui.IconData = new ui.IconData(60058, {
    fontFamily: "MaterialIcons",
  });
  public static readonly line_style: ui.IconData = new ui.IconData(59673, {
    fontFamily: "MaterialIcons",
  });
  public static readonly line_weight: ui.IconData = new ui.IconData(59674, {
    fontFamily: "MaterialIcons",
  });
  public static readonly linear_scale: ui.IconData = new ui.IconData(57952, {
    fontFamily: "MaterialIcons",
  });
  public static readonly link: ui.IconData = new ui.IconData(57687, {
    fontFamily: "MaterialIcons",
  });
  public static readonly link_off: ui.IconData = new ui.IconData(57711, {
    fontFamily: "MaterialIcons",
  });
  public static readonly linked_camera: ui.IconData = new ui.IconData(58424, {
    fontFamily: "MaterialIcons",
  });
  public static readonly liquor: ui.IconData = new ui.IconData(60000, {
    fontFamily: "MaterialIcons",
  });
  public static readonly list: ui.IconData = new ui.IconData(59542, {
    fontFamily: "MaterialIcons",
  });
  public static readonly list_alt: ui.IconData = new ui.IconData(57582, {
    fontFamily: "MaterialIcons",
  });
  public static readonly live_help: ui.IconData = new ui.IconData(57542, {
    fontFamily: "MaterialIcons",
  });
  public static readonly live_tv: ui.IconData = new ui.IconData(58937, {
    fontFamily: "MaterialIcons",
  });
  public static readonly living: ui.IconData = new ui.IconData(61483, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_activity: ui.IconData = new ui.IconData(58687, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_airport: ui.IconData = new ui.IconData(58685, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_atm: ui.IconData = new ui.IconData(58686, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_bar: ui.IconData = new ui.IconData(58688, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_cafe: ui.IconData = new ui.IconData(58689, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_car_wash: ui.IconData = new ui.IconData(58690, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_convenience_store: ui.IconData = new ui.IconData(
    58691,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly local_dining: ui.IconData = new ui.IconData(58710, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_drink: ui.IconData = new ui.IconData(58692, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_fire_department: ui.IconData = new ui.IconData(
    61269,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly local_florist: ui.IconData = new ui.IconData(58693, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_gas_station: ui.IconData = new ui.IconData(
    58694,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly local_grocery_store: ui.IconData = new ui.IconData(
    58695,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly local_hospital: ui.IconData = new ui.IconData(58696, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_hotel: ui.IconData = new ui.IconData(58697, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_laundry_service: ui.IconData = new ui.IconData(
    58698,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly local_library: ui.IconData = new ui.IconData(58699, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_mall: ui.IconData = new ui.IconData(58700, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_movies: ui.IconData = new ui.IconData(58701, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_offer: ui.IconData = new ui.IconData(58702, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_parking: ui.IconData = new ui.IconData(58703, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_pharmacy: ui.IconData = new ui.IconData(58704, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_phone: ui.IconData = new ui.IconData(58705, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_pizza: ui.IconData = new ui.IconData(58706, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_play: ui.IconData = new ui.IconData(58707, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_police: ui.IconData = new ui.IconData(61270, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_post_office: ui.IconData = new ui.IconData(
    58708,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly local_printshop: ui.IconData = new ui.IconData(58709, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_see: ui.IconData = new ui.IconData(58711, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_shipping: ui.IconData = new ui.IconData(58712, {
    fontFamily: "MaterialIcons",
  });
  public static readonly local_taxi: ui.IconData = new ui.IconData(58713, {
    fontFamily: "MaterialIcons",
  });
  public static readonly location_city: ui.IconData = new ui.IconData(59377, {
    fontFamily: "MaterialIcons",
  });
  public static readonly location_disabled: ui.IconData = new ui.IconData(
    57782,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly location_off: ui.IconData = new ui.IconData(57543, {
    fontFamily: "MaterialIcons",
  });
  public static readonly location_on: ui.IconData = new ui.IconData(57544, {
    fontFamily: "MaterialIcons",
  });
  public static readonly location_pin: ui.IconData = new ui.IconData(61915, {
    fontFamily: "MaterialIcons",
  });
  public static readonly location_searching: ui.IconData = new ui.IconData(
    57783,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly lock: ui.IconData = new ui.IconData(59543, {
    fontFamily: "MaterialIcons",
  });
  public static readonly lock_clock: ui.IconData = new ui.IconData(61271, {
    fontFamily: "MaterialIcons",
  });
  public static readonly lock_open: ui.IconData = new ui.IconData(59544, {
    fontFamily: "MaterialIcons",
  });
  public static readonly lock_outline: ui.IconData = new ui.IconData(59545, {
    fontFamily: "MaterialIcons",
  });
  public static readonly lock_reset: ui.IconData = new ui.IconData(60126, {
    fontFamily: "MaterialIcons",
  });
  public static readonly login: ui.IconData = new ui.IconData(60023, {
    fontFamily: "MaterialIcons",
  });
  public static readonly logo_dev: ui.IconData = new ui.IconData(60118, {
    fontFamily: "MaterialIcons",
  });
  public static readonly logout: ui.IconData = new ui.IconData(59834, {
    fontFamily: "MaterialIcons",
  });
  public static readonly looks: ui.IconData = new ui.IconData(58364, {
    fontFamily: "MaterialIcons",
  });
  public static readonly looks_3: ui.IconData = new ui.IconData(58363, {
    fontFamily: "MaterialIcons",
  });
  public static readonly looks_4: ui.IconData = new ui.IconData(58365, {
    fontFamily: "MaterialIcons",
  });
  public static readonly looks_5: ui.IconData = new ui.IconData(58366, {
    fontFamily: "MaterialIcons",
  });
  public static readonly looks_6: ui.IconData = new ui.IconData(58367, {
    fontFamily: "MaterialIcons",
  });
  public static readonly looks_one: ui.IconData = new ui.IconData(58368, {
    fontFamily: "MaterialIcons",
  });
  public static readonly looks_two: ui.IconData = new ui.IconData(58369, {
    fontFamily: "MaterialIcons",
  });
  public static readonly loop: ui.IconData = new ui.IconData(57384, {
    fontFamily: "MaterialIcons",
  });
  public static readonly loupe: ui.IconData = new ui.IconData(58370, {
    fontFamily: "MaterialIcons",
  });
  public static readonly low_priority: ui.IconData = new ui.IconData(57709, {
    fontFamily: "MaterialIcons",
  });
  public static readonly loyalty: ui.IconData = new ui.IconData(59546, {
    fontFamily: "MaterialIcons",
  });
  public static readonly lte_mobiledata: ui.IconData = new ui.IconData(61484, {
    fontFamily: "MaterialIcons",
  });
  public static readonly lte_plus_mobiledata: ui.IconData = new ui.IconData(
    61485,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly luggage: ui.IconData = new ui.IconData(62005, {
    fontFamily: "MaterialIcons",
  });
  public static readonly lunch_dining: ui.IconData = new ui.IconData(60001, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mail: ui.IconData = new ui.IconData(57688, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mail_outline: ui.IconData = new ui.IconData(57569, {
    fontFamily: "MaterialIcons",
  });
  public static readonly male: ui.IconData = new ui.IconData(58766, {
    fontFamily: "MaterialIcons",
  });
  public static readonly man: ui.IconData = new ui.IconData(58603, {
    fontFamily: "MaterialIcons",
  });
  public static readonly manage_accounts: ui.IconData = new ui.IconData(61486, {
    fontFamily: "MaterialIcons",
  });
  public static readonly manage_history: ui.IconData = new ui.IconData(60391, {
    fontFamily: "MaterialIcons",
  });
  public static readonly manage_search: ui.IconData = new ui.IconData(61487, {
    fontFamily: "MaterialIcons",
  });
  public static readonly map: ui.IconData = new ui.IconData(58715, {
    fontFamily: "MaterialIcons",
  });
  public static readonly maps_home_work: ui.IconData = new ui.IconData(61488, {
    fontFamily: "MaterialIcons",
  });
  public static readonly maps_ugc: ui.IconData = new ui.IconData(61272, {
    fontFamily: "MaterialIcons",
  });
  public static readonly margin: ui.IconData = new ui.IconData(59835, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mark_as_unread: ui.IconData = new ui.IconData(59836, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mark_chat_read: ui.IconData = new ui.IconData(61835, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mark_chat_unread: ui.IconData = new ui.IconData(
    61833,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly mark_email_read: ui.IconData = new ui.IconData(61836, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mark_email_unread: ui.IconData = new ui.IconData(
    61834,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly mark_unread_chat_alt: ui.IconData = new ui.IconData(
    60317,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly markunread: ui.IconData = new ui.IconData(57689, {
    fontFamily: "MaterialIcons",
  });
  public static readonly markunread_mailbox: ui.IconData = new ui.IconData(
    59547,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly masks: ui.IconData = new ui.IconData(61976, {
    fontFamily: "MaterialIcons",
  });
  public static readonly maximize: ui.IconData = new ui.IconData(59696, {
    fontFamily: "MaterialIcons",
  });
  public static readonly media_bluetooth_off: ui.IconData = new ui.IconData(
    61489,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly media_bluetooth_on: ui.IconData = new ui.IconData(
    61490,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly mediation: ui.IconData = new ui.IconData(61351, {
    fontFamily: "MaterialIcons",
  });
  public static readonly medical_information: ui.IconData = new ui.IconData(
    60397,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly medical_services: ui.IconData = new ui.IconData(
    61705,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly medication: ui.IconData = new ui.IconData(61491, {
    fontFamily: "MaterialIcons",
  });
  public static readonly medication_liquid: ui.IconData = new ui.IconData(
    60039,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly meeting_room: ui.IconData = new ui.IconData(60239, {
    fontFamily: "MaterialIcons",
  });
  public static readonly memory: ui.IconData = new ui.IconData(58146, {
    fontFamily: "MaterialIcons",
  });
  public static readonly menu: ui.IconData = new ui.IconData(58834, {
    fontFamily: "MaterialIcons",
  });
  public static readonly menu_book: ui.IconData = new ui.IconData(59929, {
    fontFamily: "MaterialIcons",
  });
  public static readonly menu_open: ui.IconData = new ui.IconData(59837, {
    fontFamily: "MaterialIcons",
  });
  public static readonly merge: ui.IconData = new ui.IconData(60312, {
    fontFamily: "MaterialIcons",
  });
  public static readonly merge_type: ui.IconData = new ui.IconData(57938, {
    fontFamily: "MaterialIcons",
  });
  public static readonly message: ui.IconData = new ui.IconData(57545, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mic: ui.IconData = new ui.IconData(57385, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mic_external_off: ui.IconData = new ui.IconData(
    61273,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly mic_external_on: ui.IconData = new ui.IconData(61274, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mic_none: ui.IconData = new ui.IconData(57386, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mic_off: ui.IconData = new ui.IconData(57387, {
    fontFamily: "MaterialIcons",
  });
  public static readonly microwave: ui.IconData = new ui.IconData(61956, {
    fontFamily: "MaterialIcons",
  });
  public static readonly military_tech: ui.IconData = new ui.IconData(59967, {
    fontFamily: "MaterialIcons",
  });
  public static readonly minimize: ui.IconData = new ui.IconData(59697, {
    fontFamily: "MaterialIcons",
  });
  public static readonly minor_crash: ui.IconData = new ui.IconData(60401, {
    fontFamily: "MaterialIcons",
  });
  public static readonly miscellaneous_services: ui.IconData = new ui.IconData(
    61708,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly missed_video_call: ui.IconData = new ui.IconData(
    57459,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly mms: ui.IconData = new ui.IconData(58904, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mobile_friendly: ui.IconData = new ui.IconData(57856, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mobile_off: ui.IconData = new ui.IconData(57857, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mobile_screen_share: ui.IconData = new ui.IconData(
    57575,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly mobiledata_off: ui.IconData = new ui.IconData(61492, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mode: ui.IconData = new ui.IconData(61591, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mode_comment: ui.IconData = new ui.IconData(57939, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mode_edit: ui.IconData = new ui.IconData(57940, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mode_edit_outline: ui.IconData = new ui.IconData(
    61493,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly mode_night: ui.IconData = new ui.IconData(61494, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mode_of_travel: ui.IconData = new ui.IconData(59342, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mode_standby: ui.IconData = new ui.IconData(61495, {
    fontFamily: "MaterialIcons",
  });
  public static readonly model_training: ui.IconData = new ui.IconData(61647, {
    fontFamily: "MaterialIcons",
  });
  public static readonly monetization_on: ui.IconData = new ui.IconData(57955, {
    fontFamily: "MaterialIcons",
  });
  public static readonly money: ui.IconData = new ui.IconData(58749, {
    fontFamily: "MaterialIcons",
  });
  public static readonly money_off: ui.IconData = new ui.IconData(57948, {
    fontFamily: "MaterialIcons",
  });
  public static readonly money_off_csred: ui.IconData = new ui.IconData(61496, {
    fontFamily: "MaterialIcons",
  });
  public static readonly monitor: ui.IconData = new ui.IconData(61275, {
    fontFamily: "MaterialIcons",
  });
  public static readonly monitor_heart: ui.IconData = new ui.IconData(60066, {
    fontFamily: "MaterialIcons",
  });
  public static readonly monitor_weight: ui.IconData = new ui.IconData(61497, {
    fontFamily: "MaterialIcons",
  });
  public static readonly monochrome_photos: ui.IconData = new ui.IconData(
    58371,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly mood: ui.IconData = new ui.IconData(59378, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mood_bad: ui.IconData = new ui.IconData(59379, {
    fontFamily: "MaterialIcons",
  });
  public static readonly moped: ui.IconData = new ui.IconData(60200, {
    fontFamily: "MaterialIcons",
  });
  public static readonly more: ui.IconData = new ui.IconData(58905, {
    fontFamily: "MaterialIcons",
  });
  public static readonly more_horiz: ui.IconData = new ui.IconData(58835, {
    fontFamily: "MaterialIcons",
  });
  public static readonly more_time: ui.IconData = new ui.IconData(59997, {
    fontFamily: "MaterialIcons",
  });
  public static readonly more_vert: ui.IconData = new ui.IconData(58836, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mosque: ui.IconData = new ui.IconData(60082, {
    fontFamily: "MaterialIcons",
  });
  public static readonly motion_photos_auto: ui.IconData = new ui.IconData(
    61498,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly motion_photos_off: ui.IconData = new ui.IconData(
    59840,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly motion_photos_on: ui.IconData = new ui.IconData(
    59841,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly motion_photos_pause: ui.IconData = new ui.IconData(
    61991,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly motion_photos_paused: ui.IconData = new ui.IconData(
    59842,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly mouse: ui.IconData = new ui.IconData(58147, {
    fontFamily: "MaterialIcons",
  });
  public static readonly move_down: ui.IconData = new ui.IconData(60257, {
    fontFamily: "MaterialIcons",
  });
  public static readonly move_to_inbox: ui.IconData = new ui.IconData(57704, {
    fontFamily: "MaterialIcons",
  });
  public static readonly move_up: ui.IconData = new ui.IconData(60260, {
    fontFamily: "MaterialIcons",
  });
  public static readonly movie: ui.IconData = new ui.IconData(57388, {
    fontFamily: "MaterialIcons",
  });
  public static readonly movie_creation: ui.IconData = new ui.IconData(58372, {
    fontFamily: "MaterialIcons",
  });
  public static readonly movie_filter: ui.IconData = new ui.IconData(58426, {
    fontFamily: "MaterialIcons",
  });
  public static readonly moving: ui.IconData = new ui.IconData(58625, {
    fontFamily: "MaterialIcons",
  });
  public static readonly mp: ui.IconData = new ui.IconData(59843, {
    fontFamily: "MaterialIcons",
  });
  public static readonly multiline_chart: ui.IconData = new ui.IconData(59103, {
    fontFamily: "MaterialIcons",
  });
  public static readonly multiple_stop: ui.IconData = new ui.IconData(61881, {
    fontFamily: "MaterialIcons",
  });
  public static readonly museum: ui.IconData = new ui.IconData(59958, {
    fontFamily: "MaterialIcons",
  });
  public static readonly music_note: ui.IconData = new ui.IconData(58373, {
    fontFamily: "MaterialIcons",
  });
  public static readonly music_off: ui.IconData = new ui.IconData(58432, {
    fontFamily: "MaterialIcons",
  });
  public static readonly music_video: ui.IconData = new ui.IconData(57443, {
    fontFamily: "MaterialIcons",
  });
  public static readonly my_location: ui.IconData = new ui.IconData(58716, {
    fontFamily: "MaterialIcons",
  });
  public static readonly nat: ui.IconData = new ui.IconData(61276, {
    fontFamily: "MaterialIcons",
  });
  public static readonly nature: ui.IconData = new ui.IconData(58374, {
    fontFamily: "MaterialIcons",
  });
  public static readonly nature_people: ui.IconData = new ui.IconData(58375, {
    fontFamily: "MaterialIcons",
  });
  public static readonly navigate_before: ui.IconData = new ui.IconData(58376, {
    fontFamily: "MaterialIcons",
  });
  public static readonly navigate_next: ui.IconData = new ui.IconData(58377, {
    fontFamily: "MaterialIcons",
  });
  public static readonly navigation: ui.IconData = new ui.IconData(58717, {
    fontFamily: "MaterialIcons",
  });
  public static readonly near_me: ui.IconData = new ui.IconData(58729, {
    fontFamily: "MaterialIcons",
  });
  public static readonly near_me_disabled: ui.IconData = new ui.IconData(
    61935,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly nearby_error: ui.IconData = new ui.IconData(61499, {
    fontFamily: "MaterialIcons",
  });
  public static readonly nearby_off: ui.IconData = new ui.IconData(61500, {
    fontFamily: "MaterialIcons",
  });
  public static readonly network_cell: ui.IconData = new ui.IconData(57785, {
    fontFamily: "MaterialIcons",
  });
  public static readonly network_check: ui.IconData = new ui.IconData(58944, {
    fontFamily: "MaterialIcons",
  });
  public static readonly network_locked: ui.IconData = new ui.IconData(58906, {
    fontFamily: "MaterialIcons",
  });
  public static readonly network_ping: ui.IconData = new ui.IconData(60362, {
    fontFamily: "MaterialIcons",
  });
  public static readonly network_wifi: ui.IconData = new ui.IconData(57786, {
    fontFamily: "MaterialIcons",
  });
  public static readonly network_wifi_1_bar: ui.IconData = new ui.IconData(
    60388,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly network_wifi_2_bar: ui.IconData = new ui.IconData(
    60374,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly network_wifi_3_bar: ui.IconData = new ui.IconData(
    60385,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly new_label: ui.IconData = new ui.IconData(58889, {
    fontFamily: "MaterialIcons",
  });
  public static readonly new_releases: ui.IconData = new ui.IconData(57393, {
    fontFamily: "MaterialIcons",
  });
  public static readonly newspaper: ui.IconData = new ui.IconData(60289, {
    fontFamily: "MaterialIcons",
  });
  public static readonly next_plan: ui.IconData = new ui.IconData(61277, {
    fontFamily: "MaterialIcons",
  });
  public static readonly next_week: ui.IconData = new ui.IconData(57706, {
    fontFamily: "MaterialIcons",
  });
  public static readonly nfc: ui.IconData = new ui.IconData(57787, {
    fontFamily: "MaterialIcons",
  });
  public static readonly night_shelter: ui.IconData = new ui.IconData(61937, {
    fontFamily: "MaterialIcons",
  });
  public static readonly nightlife: ui.IconData = new ui.IconData(60002, {
    fontFamily: "MaterialIcons",
  });
  public static readonly nightlight: ui.IconData = new ui.IconData(61501, {
    fontFamily: "MaterialIcons",
  });
  public static readonly nightlight_round: ui.IconData = new ui.IconData(
    61278,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly nights_stay: ui.IconData = new ui.IconData(59974, {
    fontFamily: "MaterialIcons",
  });
  public static readonly no_accounts: ui.IconData = new ui.IconData(61502, {
    fontFamily: "MaterialIcons",
  });
  public static readonly no_backpack: ui.IconData = new ui.IconData(62007, {
    fontFamily: "MaterialIcons",
  });
  public static readonly no_cell: ui.IconData = new ui.IconData(61860, {
    fontFamily: "MaterialIcons",
  });
  public static readonly no_crash: ui.IconData = new ui.IconData(60400, {
    fontFamily: "MaterialIcons",
  });
  public static readonly no_drinks: ui.IconData = new ui.IconData(61861, {
    fontFamily: "MaterialIcons",
  });
  public static readonly no_encryption: ui.IconData = new ui.IconData(58945, {
    fontFamily: "MaterialIcons",
  });
  public static readonly no_encryption_gmailerrorred: ui.IconData =
    new ui.IconData(61503, { fontFamily: "MaterialIcons" });
  public static readonly no_flash: ui.IconData = new ui.IconData(61862, {
    fontFamily: "MaterialIcons",
  });
  public static readonly no_food: ui.IconData = new ui.IconData(61863, {
    fontFamily: "MaterialIcons",
  });
  public static readonly no_luggage: ui.IconData = new ui.IconData(62011, {
    fontFamily: "MaterialIcons",
  });
  public static readonly no_meals: ui.IconData = new ui.IconData(61910, {
    fontFamily: "MaterialIcons",
  });
  public static readonly no_meals_ouline: ui.IconData = new ui.IconData(61993, {
    fontFamily: "MaterialIcons",
  });
  public static readonly no_meeting_room: ui.IconData = new ui.IconData(60238, {
    fontFamily: "MaterialIcons",
  });
  public static readonly no_photography: ui.IconData = new ui.IconData(61864, {
    fontFamily: "MaterialIcons",
  });
  public static readonly no_sim: ui.IconData = new ui.IconData(57548, {
    fontFamily: "MaterialIcons",
  });
  public static readonly no_stroller: ui.IconData = new ui.IconData(61871, {
    fontFamily: "MaterialIcons",
  });
  public static readonly no_transfer: ui.IconData = new ui.IconData(61909, {
    fontFamily: "MaterialIcons",
  });
  public static readonly noise_aware: ui.IconData = new ui.IconData(60396, {
    fontFamily: "MaterialIcons",
  });
  public static readonly noise_control_off: ui.IconData = new ui.IconData(
    60403,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly nordic_walking: ui.IconData = new ui.IconData(58638, {
    fontFamily: "MaterialIcons",
  });
  public static readonly north: ui.IconData = new ui.IconData(61920, {
    fontFamily: "MaterialIcons",
  });
  public static readonly north_east: ui.IconData = new ui.IconData(61921, {
    fontFamily: "MaterialIcons",
  });
  public static readonly north_west: ui.IconData = new ui.IconData(61922, {
    fontFamily: "MaterialIcons",
  });
  public static readonly not_accessible: ui.IconData = new ui.IconData(61694, {
    fontFamily: "MaterialIcons",
  });
  public static readonly not_interested: ui.IconData = new ui.IconData(57395, {
    fontFamily: "MaterialIcons",
  });
  public static readonly not_listed_location: ui.IconData = new ui.IconData(
    58741,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly not_started: ui.IconData = new ui.IconData(61649, {
    fontFamily: "MaterialIcons",
  });
  public static readonly note: ui.IconData = new ui.IconData(57455, {
    fontFamily: "MaterialIcons",
  });
  public static readonly note_add: ui.IconData = new ui.IconData(59548, {
    fontFamily: "MaterialIcons",
  });
  public static readonly note_alt: ui.IconData = new ui.IconData(61504, {
    fontFamily: "MaterialIcons",
  });
  public static readonly notes: ui.IconData = new ui.IconData(57964, {
    fontFamily: "MaterialIcons",
  });
  public static readonly notification_add: ui.IconData = new ui.IconData(
    58265,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly notification_important: ui.IconData = new ui.IconData(
    57348,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly notifications: ui.IconData = new ui.IconData(59380, {
    fontFamily: "MaterialIcons",
  });
  public static readonly notifications_active: ui.IconData = new ui.IconData(
    59383,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly notifications_none: ui.IconData = new ui.IconData(
    59381,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly notifications_off: ui.IconData = new ui.IconData(
    59382,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly notifications_paused: ui.IconData = new ui.IconData(
    59384,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly numbers: ui.IconData = new ui.IconData(60103, {
    fontFamily: "MaterialIcons",
  });
  public static readonly offline_bolt: ui.IconData = new ui.IconData(59698, {
    fontFamily: "MaterialIcons",
  });
  public static readonly offline_pin: ui.IconData = new ui.IconData(59658, {
    fontFamily: "MaterialIcons",
  });
  public static readonly offline_share: ui.IconData = new ui.IconData(59845, {
    fontFamily: "MaterialIcons",
  });
  public static readonly on_device_training: ui.IconData = new ui.IconData(
    60413,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly ondemand_video: ui.IconData = new ui.IconData(58938, {
    fontFamily: "MaterialIcons",
  });
  public static readonly online_prediction: ui.IconData = new ui.IconData(
    61675,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly opacity: ui.IconData = new ui.IconData(59676, {
    fontFamily: "MaterialIcons",
  });
  public static readonly open_in_browser: ui.IconData = new ui.IconData(59549, {
    fontFamily: "MaterialIcons",
  });
  public static readonly open_in_full: ui.IconData = new ui.IconData(61902, {
    fontFamily: "MaterialIcons",
  });
  public static readonly open_in_new: ui.IconData = new ui.IconData(59550, {
    fontFamily: "MaterialIcons",
  });
  public static readonly open_in_new_off: ui.IconData = new ui.IconData(58614, {
    fontFamily: "MaterialIcons",
  });
  public static readonly open_with: ui.IconData = new ui.IconData(59551, {
    fontFamily: "MaterialIcons",
  });
  public static readonly other_houses: ui.IconData = new ui.IconData(58764, {
    fontFamily: "MaterialIcons",
  });
  public static readonly outbound: ui.IconData = new ui.IconData(57802, {
    fontFamily: "MaterialIcons",
  });
  public static readonly outbox: ui.IconData = new ui.IconData(61279, {
    fontFamily: "MaterialIcons",
  });
  public static readonly outdoor_grill: ui.IconData = new ui.IconData(59975, {
    fontFamily: "MaterialIcons",
  });
  public static readonly outgoing_mail: ui.IconData = new ui.IconData(61650, {
    fontFamily: "MaterialIcons",
  });
  public static readonly outlet: ui.IconData = new ui.IconData(61908, {
    fontFamily: "MaterialIcons",
  });
  public static readonly outlined_flag: ui.IconData = new ui.IconData(57710, {
    fontFamily: "MaterialIcons",
  });
  public static readonly output: ui.IconData = new ui.IconData(60350, {
    fontFamily: "MaterialIcons",
  });
  public static readonly padding: ui.IconData = new ui.IconData(59848, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pages: ui.IconData = new ui.IconData(59385, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pageview: ui.IconData = new ui.IconData(59552, {
    fontFamily: "MaterialIcons",
  });
  public static readonly paid: ui.IconData = new ui.IconData(61505, {
    fontFamily: "MaterialIcons",
  });
  public static readonly palette: ui.IconData = new ui.IconData(58378, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pan_tool: ui.IconData = new ui.IconData(59685, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pan_tool_alt: ui.IconData = new ui.IconData(60345, {
    fontFamily: "MaterialIcons",
  });
  public static readonly panorama: ui.IconData = new ui.IconData(58379, {
    fontFamily: "MaterialIcons",
  });
  public static readonly panorama_fish_eye: ui.IconData = new ui.IconData(
    58380,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly panorama_horizontal: ui.IconData = new ui.IconData(
    58381,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly panorama_horizontal_select: ui.IconData =
    new ui.IconData(61280, { fontFamily: "MaterialIcons" });
  public static readonly panorama_photosphere: ui.IconData = new ui.IconData(
    59849,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly panorama_photosphere_select: ui.IconData =
    new ui.IconData(59850, { fontFamily: "MaterialIcons" });
  public static readonly panorama_vertical: ui.IconData = new ui.IconData(
    58382,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly panorama_vertical_select: ui.IconData =
    new ui.IconData(61281, { fontFamily: "MaterialIcons" });
  public static readonly panorama_wide_angle: ui.IconData = new ui.IconData(
    58383,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly panorama_wide_angle_select: ui.IconData =
    new ui.IconData(61282, { fontFamily: "MaterialIcons" });
  public static readonly paragliding: ui.IconData = new ui.IconData(58639, {
    fontFamily: "MaterialIcons",
  });
  public static readonly park: ui.IconData = new ui.IconData(60003, {
    fontFamily: "MaterialIcons",
  });
  public static readonly party_mode: ui.IconData = new ui.IconData(59386, {
    fontFamily: "MaterialIcons",
  });
  public static readonly password: ui.IconData = new ui.IconData(61506, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pattern: ui.IconData = new ui.IconData(61507, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pause: ui.IconData = new ui.IconData(57396, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pause_circle: ui.IconData = new ui.IconData(57762, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pause_circle_filled: ui.IconData = new ui.IconData(
    57397,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly pause_circle_outline: ui.IconData = new ui.IconData(
    57398,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly pause_presentation: ui.IconData = new ui.IconData(
    57578,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly payment: ui.IconData = new ui.IconData(59553, {
    fontFamily: "MaterialIcons",
  });
  public static readonly payments: ui.IconData = new ui.IconData(61283, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pedal_bike: ui.IconData = new ui.IconData(60201, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pending: ui.IconData = new ui.IconData(61284, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pending_actions: ui.IconData = new ui.IconData(61883, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pentagon: ui.IconData = new ui.IconData(60240, {
    fontFamily: "MaterialIcons",
  });
  public static readonly people: ui.IconData = new ui.IconData(59387, {
    fontFamily: "MaterialIcons",
  });
  public static readonly people_alt: ui.IconData = new ui.IconData(59937, {
    fontFamily: "MaterialIcons",
  });
  public static readonly people_outline: ui.IconData = new ui.IconData(59388, {
    fontFamily: "MaterialIcons",
  });
  public static readonly percent: ui.IconData = new ui.IconData(60248, {
    fontFamily: "MaterialIcons",
  });
  public static readonly perm_camera_mic: ui.IconData = new ui.IconData(59554, {
    fontFamily: "MaterialIcons",
  });
  public static readonly perm_contact_calendar: ui.IconData = new ui.IconData(
    59555,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly perm_data_setting: ui.IconData = new ui.IconData(
    59556,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly perm_device_information: ui.IconData = new ui.IconData(
    59557,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly perm_identity: ui.IconData = new ui.IconData(59558, {
    fontFamily: "MaterialIcons",
  });
  public static readonly perm_media: ui.IconData = new ui.IconData(59559, {
    fontFamily: "MaterialIcons",
  });
  public static readonly perm_phone_msg: ui.IconData = new ui.IconData(59560, {
    fontFamily: "MaterialIcons",
  });
  public static readonly perm_scan_wifi: ui.IconData = new ui.IconData(59561, {
    fontFamily: "MaterialIcons",
  });
  public static readonly person: ui.IconData = new ui.IconData(59389, {
    fontFamily: "MaterialIcons",
  });
  public static readonly person_add: ui.IconData = new ui.IconData(59390, {
    fontFamily: "MaterialIcons",
  });
  public static readonly person_add_alt: ui.IconData = new ui.IconData(59981, {
    fontFamily: "MaterialIcons",
  });
  public static readonly person_add_alt_1: ui.IconData = new ui.IconData(
    61285,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly person_add_disabled: ui.IconData = new ui.IconData(
    59851,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly person_off: ui.IconData = new ui.IconData(58640, {
    fontFamily: "MaterialIcons",
  });
  public static readonly person_outline: ui.IconData = new ui.IconData(59391, {
    fontFamily: "MaterialIcons",
  });
  public static readonly person_pin: ui.IconData = new ui.IconData(58714, {
    fontFamily: "MaterialIcons",
  });
  public static readonly person_pin_circle: ui.IconData = new ui.IconData(
    58730,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly person_remove: ui.IconData = new ui.IconData(61286, {
    fontFamily: "MaterialIcons",
  });
  public static readonly person_remove_alt_1: ui.IconData = new ui.IconData(
    61287,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly person_search: ui.IconData = new ui.IconData(61702, {
    fontFamily: "MaterialIcons",
  });
  public static readonly personal_injury: ui.IconData = new ui.IconData(59098, {
    fontFamily: "MaterialIcons",
  });
  public static readonly personal_video: ui.IconData = new ui.IconData(58939, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pest_control: ui.IconData = new ui.IconData(61690, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pest_control_rodent: ui.IconData = new ui.IconData(
    61693,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly pets: ui.IconData = new ui.IconData(59677, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phishing: ui.IconData = new ui.IconData(60119, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phone: ui.IconData = new ui.IconData(57549, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phone_android: ui.IconData = new ui.IconData(58148, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phone_bluetooth_speaker: ui.IconData = new ui.IconData(
    58907,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly phone_callback: ui.IconData = new ui.IconData(58953, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phone_disabled: ui.IconData = new ui.IconData(59852, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phone_enabled: ui.IconData = new ui.IconData(59853, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phone_forwarded: ui.IconData = new ui.IconData(58908, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phone_in_talk: ui.IconData = new ui.IconData(58909, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phone_iphone: ui.IconData = new ui.IconData(58149, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phone_locked: ui.IconData = new ui.IconData(58910, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phone_missed: ui.IconData = new ui.IconData(58911, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phone_paused: ui.IconData = new ui.IconData(58912, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phonelink: ui.IconData = new ui.IconData(58150, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phonelink_erase: ui.IconData = new ui.IconData(57563, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phonelink_lock: ui.IconData = new ui.IconData(57564, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phonelink_off: ui.IconData = new ui.IconData(58151, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phonelink_ring: ui.IconData = new ui.IconData(57565, {
    fontFamily: "MaterialIcons",
  });
  public static readonly phonelink_setup: ui.IconData = new ui.IconData(57566, {
    fontFamily: "MaterialIcons",
  });
  public static readonly photo: ui.IconData = new ui.IconData(58384, {
    fontFamily: "MaterialIcons",
  });
  public static readonly photo_album: ui.IconData = new ui.IconData(58385, {
    fontFamily: "MaterialIcons",
  });
  public static readonly photo_camera: ui.IconData = new ui.IconData(58386, {
    fontFamily: "MaterialIcons",
  });
  public static readonly photo_camera_back: ui.IconData = new ui.IconData(
    61288,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly photo_camera_front: ui.IconData = new ui.IconData(
    61289,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly photo_filter: ui.IconData = new ui.IconData(58427, {
    fontFamily: "MaterialIcons",
  });
  public static readonly photo_library: ui.IconData = new ui.IconData(58387, {
    fontFamily: "MaterialIcons",
  });
  public static readonly photo_size_select_actual: ui.IconData =
    new ui.IconData(58418, { fontFamily: "MaterialIcons" });
  public static readonly photo_size_select_large: ui.IconData = new ui.IconData(
    58419,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly photo_size_select_small: ui.IconData = new ui.IconData(
    58420,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly php: ui.IconData = new ui.IconData(60303, {
    fontFamily: "MaterialIcons",
  });
  public static readonly piano: ui.IconData = new ui.IconData(58657, {
    fontFamily: "MaterialIcons",
  });
  public static readonly piano_off: ui.IconData = new ui.IconData(58656, {
    fontFamily: "MaterialIcons",
  });
  public static readonly picture_as_pdf: ui.IconData = new ui.IconData(58389, {
    fontFamily: "MaterialIcons",
  });
  public static readonly picture_in_picture: ui.IconData = new ui.IconData(
    59562,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly picture_in_picture_alt: ui.IconData = new ui.IconData(
    59665,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly pie_chart: ui.IconData = new ui.IconData(59076, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pie_chart_outline: ui.IconData = new ui.IconData(
    61508,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly pie_chart_outlined: ui.IconData = new ui.IconData(
    59077,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly pin: ui.IconData = new ui.IconData(61509, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pin_drop: ui.IconData = new ui.IconData(58718, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pin_end: ui.IconData = new ui.IconData(59239, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pin_invoke: ui.IconData = new ui.IconData(59235, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pinch: ui.IconData = new ui.IconData(60216, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pivot_table_chart: ui.IconData = new ui.IconData(
    59854,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly pix: ui.IconData = new ui.IconData(60067, {
    fontFamily: "MaterialIcons",
  });
  public static readonly place: ui.IconData = new ui.IconData(58719, {
    fontFamily: "MaterialIcons",
  });
  public static readonly plagiarism: ui.IconData = new ui.IconData(59994, {
    fontFamily: "MaterialIcons",
  });
  public static readonly play_arrow: ui.IconData = new ui.IconData(57399, {
    fontFamily: "MaterialIcons",
  });
  public static readonly play_circle: ui.IconData = new ui.IconData(57796, {
    fontFamily: "MaterialIcons",
  });
  public static readonly play_circle_filled: ui.IconData = new ui.IconData(
    57400,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly play_circle_outline: ui.IconData = new ui.IconData(
    57401,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly play_disabled: ui.IconData = new ui.IconData(61290, {
    fontFamily: "MaterialIcons",
  });
  public static readonly play_for_work: ui.IconData = new ui.IconData(59654, {
    fontFamily: "MaterialIcons",
  });
  public static readonly play_lesson: ui.IconData = new ui.IconData(61511, {
    fontFamily: "MaterialIcons",
  });
  public static readonly playlist_add: ui.IconData = new ui.IconData(57403, {
    fontFamily: "MaterialIcons",
  });
  public static readonly playlist_add_check: ui.IconData = new ui.IconData(
    57445,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly playlist_add_check_circle: ui.IconData =
    new ui.IconData(59366, { fontFamily: "MaterialIcons" });
  public static readonly playlist_add_circle: ui.IconData = new ui.IconData(
    59365,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly playlist_play: ui.IconData = new ui.IconData(57439, {
    fontFamily: "MaterialIcons",
  });
  public static readonly playlist_remove: ui.IconData = new ui.IconData(60288, {
    fontFamily: "MaterialIcons",
  });
  public static readonly plumbing: ui.IconData = new ui.IconData(61703, {
    fontFamily: "MaterialIcons",
  });
  public static readonly plus_one: ui.IconData = new ui.IconData(59392, {
    fontFamily: "MaterialIcons",
  });
  public static readonly podcasts: ui.IconData = new ui.IconData(61512, {
    fontFamily: "MaterialIcons",
  });
  public static readonly point_of_sale: ui.IconData = new ui.IconData(61822, {
    fontFamily: "MaterialIcons",
  });
  public static readonly policy: ui.IconData = new ui.IconData(59927, {
    fontFamily: "MaterialIcons",
  });
  public static readonly poll: ui.IconData = new ui.IconData(59393, {
    fontFamily: "MaterialIcons",
  });
  public static readonly polyline: ui.IconData = new ui.IconData(60347, {
    fontFamily: "MaterialIcons",
  });
  public static readonly polymer: ui.IconData = new ui.IconData(59563, {
    fontFamily: "MaterialIcons",
  });
  public static readonly pool: ui.IconData = new ui.IconData(60232, {
    fontFamily: "MaterialIcons",
  });
  public static readonly portable_wifi_off: ui.IconData = new ui.IconData(
    57550,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly portrait: ui.IconData = new ui.IconData(58390, {
    fontFamily: "MaterialIcons",
  });
  public static readonly post_add: ui.IconData = new ui.IconData(59936, {
    fontFamily: "MaterialIcons",
  });
  public static readonly power: ui.IconData = new ui.IconData(58940, {
    fontFamily: "MaterialIcons",
  });
  public static readonly power_input: ui.IconData = new ui.IconData(58166, {
    fontFamily: "MaterialIcons",
  });
  public static readonly power_off: ui.IconData = new ui.IconData(58950, {
    fontFamily: "MaterialIcons",
  });
  public static readonly power_settings_new: ui.IconData = new ui.IconData(
    59564,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly precision_manufacturing: ui.IconData = new ui.IconData(
    61513,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly pregnant_woman: ui.IconData = new ui.IconData(59678, {
    fontFamily: "MaterialIcons",
  });
  public static readonly present_to_all: ui.IconData = new ui.IconData(57567, {
    fontFamily: "MaterialIcons",
  });
  public static readonly preview: ui.IconData = new ui.IconData(61893, {
    fontFamily: "MaterialIcons",
  });
  public static readonly price_change: ui.IconData = new ui.IconData(61514, {
    fontFamily: "MaterialIcons",
  });
  public static readonly price_check: ui.IconData = new ui.IconData(61515, {
    fontFamily: "MaterialIcons",
  });
  public static readonly print: ui.IconData = new ui.IconData(59565, {
    fontFamily: "MaterialIcons",
  });
  public static readonly print_disabled: ui.IconData = new ui.IconData(59855, {
    fontFamily: "MaterialIcons",
  });
  public static readonly priority_high: ui.IconData = new ui.IconData(58949, {
    fontFamily: "MaterialIcons",
  });
  public static readonly privacy_tip: ui.IconData = new ui.IconData(61660, {
    fontFamily: "MaterialIcons",
  });
  public static readonly private_connectivity: ui.IconData = new ui.IconData(
    59204,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly production_quantity_limits: ui.IconData =
    new ui.IconData(57809, { fontFamily: "MaterialIcons" });
  public static readonly psychology: ui.IconData = new ui.IconData(59978, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_public: ui.IconData = new ui.IconData(59403, {
    fontFamily: "MaterialIcons",
  });
  public static readonly public_off: ui.IconData = new ui.IconData(61898, {
    fontFamily: "MaterialIcons",
  });
  public static readonly publish: ui.IconData = new ui.IconData(57941, {
    fontFamily: "MaterialIcons",
  });
  public static readonly published_with_changes: ui.IconData = new ui.IconData(
    62002,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly punch_clock: ui.IconData = new ui.IconData(60072, {
    fontFamily: "MaterialIcons",
  });
  public static readonly push_pin: ui.IconData = new ui.IconData(61709, {
    fontFamily: "MaterialIcons",
  });
  public static readonly qr_code: ui.IconData = new ui.IconData(61291, {
    fontFamily: "MaterialIcons",
  });
  public static readonly qr_code_2: ui.IconData = new ui.IconData(57354, {
    fontFamily: "MaterialIcons",
  });
  public static readonly qr_code_scanner: ui.IconData = new ui.IconData(61958, {
    fontFamily: "MaterialIcons",
  });
  public static readonly query_builder: ui.IconData = new ui.IconData(59566, {
    fontFamily: "MaterialIcons",
  });
  public static readonly query_stats: ui.IconData = new ui.IconData(58620, {
    fontFamily: "MaterialIcons",
  });
  public static readonly question_answer: ui.IconData = new ui.IconData(59567, {
    fontFamily: "MaterialIcons",
  });
  public static readonly question_mark: ui.IconData = new ui.IconData(60299, {
    fontFamily: "MaterialIcons",
  });
  public static readonly queue: ui.IconData = new ui.IconData(57404, {
    fontFamily: "MaterialIcons",
  });
  public static readonly queue_music: ui.IconData = new ui.IconData(57405, {
    fontFamily: "MaterialIcons",
  });
  public static readonly queue_play_next: ui.IconData = new ui.IconData(57446, {
    fontFamily: "MaterialIcons",
  });
  public static readonly quickreply: ui.IconData = new ui.IconData(61292, {
    fontFamily: "MaterialIcons",
  });
  public static readonly quiz: ui.IconData = new ui.IconData(61516, {
    fontFamily: "MaterialIcons",
  });
  public static readonly r_mobiledata: ui.IconData = new ui.IconData(61517, {
    fontFamily: "MaterialIcons",
  });
  public static readonly radar: ui.IconData = new ui.IconData(61518, {
    fontFamily: "MaterialIcons",
  });
  public static readonly radio: ui.IconData = new ui.IconData(57406, {
    fontFamily: "MaterialIcons",
  });
  public static readonly radio_button_checked: ui.IconData = new ui.IconData(
    59447,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly radio_button_unchecked: ui.IconData = new ui.IconData(
    59446,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly railway_alert: ui.IconData = new ui.IconData(59857, {
    fontFamily: "MaterialIcons",
  });
  public static readonly ramen_dining: ui.IconData = new ui.IconData(60004, {
    fontFamily: "MaterialIcons",
  });
  public static readonly ramp_left: ui.IconData = new ui.IconData(60316, {
    fontFamily: "MaterialIcons",
  });
  public static readonly ramp_right: ui.IconData = new ui.IconData(60310, {
    fontFamily: "MaterialIcons",
  });
  public static readonly rate_review: ui.IconData = new ui.IconData(58720, {
    fontFamily: "MaterialIcons",
  });
  public static readonly raw_off: ui.IconData = new ui.IconData(61519, {
    fontFamily: "MaterialIcons",
  });
  public static readonly raw_on: ui.IconData = new ui.IconData(61520, {
    fontFamily: "MaterialIcons",
  });
  public static readonly read_more: ui.IconData = new ui.IconData(61293, {
    fontFamily: "MaterialIcons",
  });
  public static readonly real_estate_agent: ui.IconData = new ui.IconData(
    59194,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly receipt: ui.IconData = new ui.IconData(59568, {
    fontFamily: "MaterialIcons",
  });
  public static readonly receipt_long: ui.IconData = new ui.IconData(61294, {
    fontFamily: "MaterialIcons",
  });
  public static readonly recent_actors: ui.IconData = new ui.IconData(57407, {
    fontFamily: "MaterialIcons",
  });
  public static readonly recommend: ui.IconData = new ui.IconData(59858, {
    fontFamily: "MaterialIcons",
  });
  public static readonly record_voice_over: ui.IconData = new ui.IconData(
    59679,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly rectangle: ui.IconData = new ui.IconData(60244, {
    fontFamily: "MaterialIcons",
  });
  public static readonly recycling: ui.IconData = new ui.IconData(59232, {
    fontFamily: "MaterialIcons",
  });
  public static readonly redeem: ui.IconData = new ui.IconData(59569, {
    fontFamily: "MaterialIcons",
  });
  public static readonly redo: ui.IconData = new ui.IconData(57690, {
    fontFamily: "MaterialIcons",
  });
  public static readonly reduce_capacity: ui.IconData = new ui.IconData(61980, {
    fontFamily: "MaterialIcons",
  });
  public static readonly refresh: ui.IconData = new ui.IconData(58837, {
    fontFamily: "MaterialIcons",
  });
  public static readonly remember_me: ui.IconData = new ui.IconData(61521, {
    fontFamily: "MaterialIcons",
  });
  public static readonly remove: ui.IconData = new ui.IconData(57691, {
    fontFamily: "MaterialIcons",
  });
  public static readonly remove_circle: ui.IconData = new ui.IconData(57692, {
    fontFamily: "MaterialIcons",
  });
  public static readonly remove_circle_outline: ui.IconData = new ui.IconData(
    57693,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly remove_done: ui.IconData = new ui.IconData(59859, {
    fontFamily: "MaterialIcons",
  });
  public static readonly remove_from_queue: ui.IconData = new ui.IconData(
    57447,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly remove_moderator: ui.IconData = new ui.IconData(
    59860,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly remove_red_eye: ui.IconData = new ui.IconData(58391, {
    fontFamily: "MaterialIcons",
  });
  public static readonly remove_road: ui.IconData = new ui.IconData(60412, {
    fontFamily: "MaterialIcons",
  });
  public static readonly remove_shopping_cart: ui.IconData = new ui.IconData(
    59688,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly reorder: ui.IconData = new ui.IconData(59646, {
    fontFamily: "MaterialIcons",
  });
  public static readonly repeat: ui.IconData = new ui.IconData(57408, {
    fontFamily: "MaterialIcons",
  });
  public static readonly repeat_on: ui.IconData = new ui.IconData(59862, {
    fontFamily: "MaterialIcons",
  });
  public static readonly repeat_one: ui.IconData = new ui.IconData(57409, {
    fontFamily: "MaterialIcons",
  });
  public static readonly repeat_one_on: ui.IconData = new ui.IconData(59863, {
    fontFamily: "MaterialIcons",
  });
  public static readonly replay: ui.IconData = new ui.IconData(57410, {
    fontFamily: "MaterialIcons",
  });
  public static readonly replay_10: ui.IconData = new ui.IconData(57433, {
    fontFamily: "MaterialIcons",
  });
  public static readonly replay_30: ui.IconData = new ui.IconData(57434, {
    fontFamily: "MaterialIcons",
  });
  public static readonly replay_5: ui.IconData = new ui.IconData(57435, {
    fontFamily: "MaterialIcons",
  });
  public static readonly replay_circle_filled: ui.IconData = new ui.IconData(
    59864,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly reply: ui.IconData = new ui.IconData(57694, {
    fontFamily: "MaterialIcons",
  });
  public static readonly reply_all: ui.IconData = new ui.IconData(57695, {
    fontFamily: "MaterialIcons",
  });
  public static readonly report: ui.IconData = new ui.IconData(57696, {
    fontFamily: "MaterialIcons",
  });
  public static readonly report_gmailerrorred: ui.IconData = new ui.IconData(
    61522,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly report_off: ui.IconData = new ui.IconData(57712, {
    fontFamily: "MaterialIcons",
  });
  public static readonly report_problem: ui.IconData = new ui.IconData(59570, {
    fontFamily: "MaterialIcons",
  });
  public static readonly request_page: ui.IconData = new ui.IconData(61996, {
    fontFamily: "MaterialIcons",
  });
  public static readonly request_quote: ui.IconData = new ui.IconData(61878, {
    fontFamily: "MaterialIcons",
  });
  public static readonly reset_tv: ui.IconData = new ui.IconData(59865, {
    fontFamily: "MaterialIcons",
  });
  public static readonly restart_alt: ui.IconData = new ui.IconData(61523, {
    fontFamily: "MaterialIcons",
  });
  public static readonly restaurant: ui.IconData = new ui.IconData(58732, {
    fontFamily: "MaterialIcons",
  });
  public static readonly restaurant_menu: ui.IconData = new ui.IconData(58721, {
    fontFamily: "MaterialIcons",
  });
  public static readonly restore: ui.IconData = new ui.IconData(59571, {
    fontFamily: "MaterialIcons",
  });
  public static readonly restore_from_trash: ui.IconData = new ui.IconData(
    59704,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly restore_page: ui.IconData = new ui.IconData(59689, {
    fontFamily: "MaterialIcons",
  });
  public static readonly reviews: ui.IconData = new ui.IconData(61524, {
    fontFamily: "MaterialIcons",
  });
  public static readonly rice_bowl: ui.IconData = new ui.IconData(61941, {
    fontFamily: "MaterialIcons",
  });
  public static readonly ring_volume: ui.IconData = new ui.IconData(57553, {
    fontFamily: "MaterialIcons",
  });
  public static readonly rocket: ui.IconData = new ui.IconData(60325, {
    fontFamily: "MaterialIcons",
  });
  public static readonly rocket_launch: ui.IconData = new ui.IconData(60315, {
    fontFamily: "MaterialIcons",
  });
  public static readonly roller_skating: ui.IconData = new ui.IconData(60365, {
    fontFamily: "MaterialIcons",
  });
  public static readonly roofing: ui.IconData = new ui.IconData(61953, {
    fontFamily: "MaterialIcons",
  });
  public static readonly room: ui.IconData = new ui.IconData(59572, {
    fontFamily: "MaterialIcons",
  });
  public static readonly room_preferences: ui.IconData = new ui.IconData(
    61880,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly room_service: ui.IconData = new ui.IconData(60233, {
    fontFamily: "MaterialIcons",
  });
  public static readonly rotate_90_degrees_ccw: ui.IconData = new ui.IconData(
    58392,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly rotate_90_degrees_cw: ui.IconData = new ui.IconData(
    60075,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly rotate_left: ui.IconData = new ui.IconData(58393, {
    fontFamily: "MaterialIcons",
  });
  public static readonly rotate_right: ui.IconData = new ui.IconData(58394, {
    fontFamily: "MaterialIcons",
  });
  public static readonly roundabout_left: ui.IconData = new ui.IconData(60313, {
    fontFamily: "MaterialIcons",
  });
  public static readonly roundabout_right: ui.IconData = new ui.IconData(
    60323,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly rounded_corner: ui.IconData = new ui.IconData(59680, {
    fontFamily: "MaterialIcons",
  });
  public static readonly route: ui.IconData = new ui.IconData(60109, {
    fontFamily: "MaterialIcons",
  });
  public static readonly router: ui.IconData = new ui.IconData(58152, {
    fontFamily: "MaterialIcons",
  });
  public static readonly rowing: ui.IconData = new ui.IconData(59681, {
    fontFamily: "MaterialIcons",
  });
  public static readonly rss_feed: ui.IconData = new ui.IconData(57573, {
    fontFamily: "MaterialIcons",
  });
  public static readonly rsvp: ui.IconData = new ui.IconData(61525, {
    fontFamily: "MaterialIcons",
  });
  public static readonly rtt: ui.IconData = new ui.IconData(59821, {
    fontFamily: "MaterialIcons",
  });
  public static readonly rule: ui.IconData = new ui.IconData(61890, {
    fontFamily: "MaterialIcons",
  });
  public static readonly rule_folder: ui.IconData = new ui.IconData(61897, {
    fontFamily: "MaterialIcons",
  });
  public static readonly run_circle: ui.IconData = new ui.IconData(61295, {
    fontFamily: "MaterialIcons",
  });
  public static readonly running_with_errors: ui.IconData = new ui.IconData(
    58653,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly rv_hookup: ui.IconData = new ui.IconData(58946, {
    fontFamily: "MaterialIcons",
  });
  public static readonly safety_check: ui.IconData = new ui.IconData(60399, {
    fontFamily: "MaterialIcons",
  });
  public static readonly safety_divider: ui.IconData = new ui.IconData(57804, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sailing: ui.IconData = new ui.IconData(58626, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sanitizer: ui.IconData = new ui.IconData(61981, {
    fontFamily: "MaterialIcons",
  });
  public static readonly satellite: ui.IconData = new ui.IconData(58722, {
    fontFamily: "MaterialIcons",
  });
  public static readonly satellite_alt: ui.IconData = new ui.IconData(60218, {
    fontFamily: "MaterialIcons",
  });
  public static readonly save: ui.IconData = new ui.IconData(57697, {
    fontFamily: "MaterialIcons",
  });
  public static readonly save_alt: ui.IconData = new ui.IconData(57713, {
    fontFamily: "MaterialIcons",
  });
  public static readonly save_as: ui.IconData = new ui.IconData(60256, {
    fontFamily: "MaterialIcons",
  });
  public static readonly saved_search: ui.IconData = new ui.IconData(59921, {
    fontFamily: "MaterialIcons",
  });
  public static readonly savings: ui.IconData = new ui.IconData(58091, {
    fontFamily: "MaterialIcons",
  });
  public static readonly scale: ui.IconData = new ui.IconData(60255, {
    fontFamily: "MaterialIcons",
  });
  public static readonly scanner: ui.IconData = new ui.IconData(58153, {
    fontFamily: "MaterialIcons",
  });
  public static readonly scatter_plot: ui.IconData = new ui.IconData(57960, {
    fontFamily: "MaterialIcons",
  });
  public static readonly schedule: ui.IconData = new ui.IconData(59573, {
    fontFamily: "MaterialIcons",
  });
  public static readonly schedule_send: ui.IconData = new ui.IconData(59914, {
    fontFamily: "MaterialIcons",
  });
  public static readonly schema: ui.IconData = new ui.IconData(58621, {
    fontFamily: "MaterialIcons",
  });
  public static readonly school: ui.IconData = new ui.IconData(59404, {
    fontFamily: "MaterialIcons",
  });
  public static readonly science: ui.IconData = new ui.IconData(59979, {
    fontFamily: "MaterialIcons",
  });
  public static readonly score: ui.IconData = new ui.IconData(57961, {
    fontFamily: "MaterialIcons",
  });
  public static readonly scoreboard: ui.IconData = new ui.IconData(60368, {
    fontFamily: "MaterialIcons",
  });
  public static readonly screen_lock_landscape: ui.IconData = new ui.IconData(
    57790,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly screen_lock_portrait: ui.IconData = new ui.IconData(
    57791,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly screen_lock_rotation: ui.IconData = new ui.IconData(
    57792,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly screen_rotation: ui.IconData = new ui.IconData(57793, {
    fontFamily: "MaterialIcons",
  });
  public static readonly screen_rotation_alt: ui.IconData = new ui.IconData(
    60398,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly screen_search_desktop: ui.IconData = new ui.IconData(
    61296,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly screen_share: ui.IconData = new ui.IconData(57570, {
    fontFamily: "MaterialIcons",
  });
  public static readonly screenshot: ui.IconData = new ui.IconData(61526, {
    fontFamily: "MaterialIcons",
  });
  public static readonly scuba_diving: ui.IconData = new ui.IconData(60366, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sd: ui.IconData = new ui.IconData(59869, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sd_card: ui.IconData = new ui.IconData(58915, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sd_card_alert: ui.IconData = new ui.IconData(61527, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sd_storage: ui.IconData = new ui.IconData(57794, {
    fontFamily: "MaterialIcons",
  });
  public static readonly search: ui.IconData = new ui.IconData(59574, {
    fontFamily: "MaterialIcons",
  });
  public static readonly search_off: ui.IconData = new ui.IconData(60022, {
    fontFamily: "MaterialIcons",
  });
  public static readonly security: ui.IconData = new ui.IconData(58154, {
    fontFamily: "MaterialIcons",
  });
  public static readonly security_update: ui.IconData = new ui.IconData(61528, {
    fontFamily: "MaterialIcons",
  });
  public static readonly security_update_good: ui.IconData = new ui.IconData(
    61529,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly security_update_warning: ui.IconData = new ui.IconData(
    61530,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly segment: ui.IconData = new ui.IconData(59723, {
    fontFamily: "MaterialIcons",
  });
  public static readonly select_all: ui.IconData = new ui.IconData(57698, {
    fontFamily: "MaterialIcons",
  });
  public static readonly self_improvement: ui.IconData = new ui.IconData(
    60024,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly sell: ui.IconData = new ui.IconData(61531, {
    fontFamily: "MaterialIcons",
  });
  public static readonly send: ui.IconData = new ui.IconData(57699, {
    fontFamily: "MaterialIcons",
  });
  public static readonly send_and_archive: ui.IconData = new ui.IconData(
    59916,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly send_time_extension: ui.IconData = new ui.IconData(
    60123,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly send_to_mobile: ui.IconData = new ui.IconData(61532, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sensor_door: ui.IconData = new ui.IconData(61877, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sensor_window: ui.IconData = new ui.IconData(61876, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sensors: ui.IconData = new ui.IconData(58654, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sensors_off: ui.IconData = new ui.IconData(58655, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sentiment_dissatisfied: ui.IconData = new ui.IconData(
    59409,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly sentiment_neutral: ui.IconData = new ui.IconData(
    59410,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly sentiment_satisfied: ui.IconData = new ui.IconData(
    59411,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly sentiment_satisfied_alt: ui.IconData = new ui.IconData(
    57581,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly sentiment_very_dissatisfied: ui.IconData =
    new ui.IconData(59412, { fontFamily: "MaterialIcons" });
  public static readonly sentiment_very_satisfied: ui.IconData =
    new ui.IconData(59413, { fontFamily: "MaterialIcons" });
  public static readonly set_meal: ui.IconData = new ui.IconData(61930, {
    fontFamily: "MaterialIcons",
  });
  public static readonly settings: ui.IconData = new ui.IconData(59576, {
    fontFamily: "MaterialIcons",
  });
  public static readonly settings_accessibility: ui.IconData = new ui.IconData(
    61533,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly settings_applications: ui.IconData = new ui.IconData(
    59577,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly settings_backup_restore: ui.IconData = new ui.IconData(
    59578,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly settings_bluetooth: ui.IconData = new ui.IconData(
    59579,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly settings_brightness: ui.IconData = new ui.IconData(
    59581,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly settings_cell: ui.IconData = new ui.IconData(59580, {
    fontFamily: "MaterialIcons",
  });
  public static readonly settings_ethernet: ui.IconData = new ui.IconData(
    59582,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly settings_input_antenna: ui.IconData = new ui.IconData(
    59583,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly settings_input_component: ui.IconData =
    new ui.IconData(59584, { fontFamily: "MaterialIcons" });
  public static readonly settings_input_composite: ui.IconData =
    new ui.IconData(59585, { fontFamily: "MaterialIcons" });
  public static readonly settings_input_hdmi: ui.IconData = new ui.IconData(
    59586,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly settings_input_svideo: ui.IconData = new ui.IconData(
    59587,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly settings_overscan: ui.IconData = new ui.IconData(
    59588,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly settings_phone: ui.IconData = new ui.IconData(59589, {
    fontFamily: "MaterialIcons",
  });
  public static readonly settings_power: ui.IconData = new ui.IconData(59590, {
    fontFamily: "MaterialIcons",
  });
  public static readonly settings_remote: ui.IconData = new ui.IconData(59591, {
    fontFamily: "MaterialIcons",
  });
  public static readonly settings_suggest: ui.IconData = new ui.IconData(
    61534,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly settings_system_daydream: ui.IconData =
    new ui.IconData(57795, { fontFamily: "MaterialIcons" });
  public static readonly settings_voice: ui.IconData = new ui.IconData(59592, {
    fontFamily: "MaterialIcons",
  });
  public static readonly severe_cold: ui.IconData = new ui.IconData(60371, {
    fontFamily: "MaterialIcons",
  });
  public static readonly share: ui.IconData = new ui.IconData(59405, {
    fontFamily: "MaterialIcons",
  });
  public static readonly share_location: ui.IconData = new ui.IconData(61535, {
    fontFamily: "MaterialIcons",
  });
  public static readonly shield: ui.IconData = new ui.IconData(59872, {
    fontFamily: "MaterialIcons",
  });
  public static readonly shield_moon: ui.IconData = new ui.IconData(60073, {
    fontFamily: "MaterialIcons",
  });
  public static readonly shop: ui.IconData = new ui.IconData(59593, {
    fontFamily: "MaterialIcons",
  });
  public static readonly shop_2: ui.IconData = new ui.IconData(57758, {
    fontFamily: "MaterialIcons",
  });
  public static readonly shop_two: ui.IconData = new ui.IconData(59594, {
    fontFamily: "MaterialIcons",
  });
  public static readonly shopping_bag: ui.IconData = new ui.IconData(61900, {
    fontFamily: "MaterialIcons",
  });
  public static readonly shopping_basket: ui.IconData = new ui.IconData(59595, {
    fontFamily: "MaterialIcons",
  });
  public static readonly shopping_cart: ui.IconData = new ui.IconData(59596, {
    fontFamily: "MaterialIcons",
  });
  public static readonly shopping_cart_checkout: ui.IconData = new ui.IconData(
    60296,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly short_text: ui.IconData = new ui.IconData(57953, {
    fontFamily: "MaterialIcons",
  });
  public static readonly shortcut: ui.IconData = new ui.IconData(61536, {
    fontFamily: "MaterialIcons",
  });
  public static readonly show_chart: ui.IconData = new ui.IconData(59105, {
    fontFamily: "MaterialIcons",
  });
  public static readonly shower: ui.IconData = new ui.IconData(61537, {
    fontFamily: "MaterialIcons",
  });
  public static readonly shuffle: ui.IconData = new ui.IconData(57411, {
    fontFamily: "MaterialIcons",
  });
  public static readonly shuffle_on: ui.IconData = new ui.IconData(59873, {
    fontFamily: "MaterialIcons",
  });
  public static readonly shutter_speed: ui.IconData = new ui.IconData(58429, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sick: ui.IconData = new ui.IconData(61984, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sign_language: ui.IconData = new ui.IconData(60389, {
    fontFamily: "MaterialIcons",
  });
  public static readonly signal_cellular_0_bar: ui.IconData = new ui.IconData(
    61608,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly signal_cellular_4_bar: ui.IconData = new ui.IconData(
    57800,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly signal_cellular_alt: ui.IconData = new ui.IconData(
    57858,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly signal_cellular_alt_1_bar: ui.IconData =
    new ui.IconData(60383, { fontFamily: "MaterialIcons" });
  public static readonly signal_cellular_alt_2_bar: ui.IconData =
    new ui.IconData(60387, { fontFamily: "MaterialIcons" });
  public static readonly signal_cellular_connected_no_internet_0_bar: ui.IconData =
    new ui.IconData(61612, { fontFamily: "MaterialIcons" });
  public static readonly signal_cellular_connected_no_internet_4_bar: ui.IconData =
    new ui.IconData(57805, { fontFamily: "MaterialIcons" });
  public static readonly signal_cellular_no_sim: ui.IconData = new ui.IconData(
    57806,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly signal_cellular_nodata: ui.IconData = new ui.IconData(
    61538,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly signal_cellular_null: ui.IconData = new ui.IconData(
    57807,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly signal_cellular_off: ui.IconData = new ui.IconData(
    57808,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly signal_wifi_0_bar: ui.IconData = new ui.IconData(
    61616,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly signal_wifi_4_bar: ui.IconData = new ui.IconData(
    57816,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly signal_wifi_4_bar_lock: ui.IconData = new ui.IconData(
    57817,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly signal_wifi_bad: ui.IconData = new ui.IconData(61539, {
    fontFamily: "MaterialIcons",
  });
  public static readonly signal_wifi_connected_no_internet_4: ui.IconData =
    new ui.IconData(61540, { fontFamily: "MaterialIcons" });
  public static readonly signal_wifi_off: ui.IconData = new ui.IconData(57818, {
    fontFamily: "MaterialIcons",
  });
  public static readonly signal_wifi_statusbar_4_bar: ui.IconData =
    new ui.IconData(61541, { fontFamily: "MaterialIcons" });
  public static readonly signal_wifi_statusbar_connected_no_internet_4: ui.IconData =
    new ui.IconData(61542, { fontFamily: "MaterialIcons" });
  public static readonly signal_wifi_statusbar_null: ui.IconData =
    new ui.IconData(61543, { fontFamily: "MaterialIcons" });
  public static readonly signpost: ui.IconData = new ui.IconData(60305, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sim_card: ui.IconData = new ui.IconData(58155, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sim_card_alert: ui.IconData = new ui.IconData(58916, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sim_card_download: ui.IconData = new ui.IconData(
    61544,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly single_bed: ui.IconData = new ui.IconData(59976, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sip: ui.IconData = new ui.IconData(61545, {
    fontFamily: "MaterialIcons",
  });
  public static readonly skateboarding: ui.IconData = new ui.IconData(58641, {
    fontFamily: "MaterialIcons",
  });
  public static readonly skip_next: ui.IconData = new ui.IconData(57412, {
    fontFamily: "MaterialIcons",
  });
  public static readonly skip_previous: ui.IconData = new ui.IconData(57413, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sledding: ui.IconData = new ui.IconData(58642, {
    fontFamily: "MaterialIcons",
  });
  public static readonly slideshow: ui.IconData = new ui.IconData(58395, {
    fontFamily: "MaterialIcons",
  });
  public static readonly slow_motion_video: ui.IconData = new ui.IconData(
    57448,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly smart_button: ui.IconData = new ui.IconData(61889, {
    fontFamily: "MaterialIcons",
  });
  public static readonly smart_display: ui.IconData = new ui.IconData(61546, {
    fontFamily: "MaterialIcons",
  });
  public static readonly smart_screen: ui.IconData = new ui.IconData(61547, {
    fontFamily: "MaterialIcons",
  });
  public static readonly smart_toy: ui.IconData = new ui.IconData(61548, {
    fontFamily: "MaterialIcons",
  });
  public static readonly smartphone: ui.IconData = new ui.IconData(58156, {
    fontFamily: "MaterialIcons",
  });
  public static readonly smoke_free: ui.IconData = new ui.IconData(60234, {
    fontFamily: "MaterialIcons",
  });
  public static readonly smoking_rooms: ui.IconData = new ui.IconData(60235, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sms: ui.IconData = new ui.IconData(58917, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sms_failed: ui.IconData = new ui.IconData(58918, {
    fontFamily: "MaterialIcons",
  });
  public static readonly snippet_folder: ui.IconData = new ui.IconData(61895, {
    fontFamily: "MaterialIcons",
  });
  public static readonly snooze: ui.IconData = new ui.IconData(57414, {
    fontFamily: "MaterialIcons",
  });
  public static readonly snowboarding: ui.IconData = new ui.IconData(58643, {
    fontFamily: "MaterialIcons",
  });
  public static readonly snowing: ui.IconData = new ui.IconData(59407, {
    fontFamily: "MaterialIcons",
  });
  public static readonly snowmobile: ui.IconData = new ui.IconData(58627, {
    fontFamily: "MaterialIcons",
  });
  public static readonly snowshoeing: ui.IconData = new ui.IconData(58644, {
    fontFamily: "MaterialIcons",
  });
  public static readonly soap: ui.IconData = new ui.IconData(61874, {
    fontFamily: "MaterialIcons",
  });
  public static readonly social_distance: ui.IconData = new ui.IconData(57803, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sort: ui.IconData = new ui.IconData(57700, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sort_by_alpha: ui.IconData = new ui.IconData(57427, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sos: ui.IconData = new ui.IconData(60407, {
    fontFamily: "MaterialIcons",
  });
  public static readonly soup_kitchen: ui.IconData = new ui.IconData(59347, {
    fontFamily: "MaterialIcons",
  });
  public static readonly source: ui.IconData = new ui.IconData(61892, {
    fontFamily: "MaterialIcons",
  });
  public static readonly south: ui.IconData = new ui.IconData(61923, {
    fontFamily: "MaterialIcons",
  });
  public static readonly south_america: ui.IconData = new ui.IconData(59364, {
    fontFamily: "MaterialIcons",
  });
  public static readonly south_east: ui.IconData = new ui.IconData(61924, {
    fontFamily: "MaterialIcons",
  });
  public static readonly south_west: ui.IconData = new ui.IconData(61925, {
    fontFamily: "MaterialIcons",
  });
  public static readonly spa: ui.IconData = new ui.IconData(60236, {
    fontFamily: "MaterialIcons",
  });
  public static readonly space_bar: ui.IconData = new ui.IconData(57942, {
    fontFamily: "MaterialIcons",
  });
  public static readonly space_dashboard: ui.IconData = new ui.IconData(58987, {
    fontFamily: "MaterialIcons",
  });
  public static readonly spatial_audio: ui.IconData = new ui.IconData(60395, {
    fontFamily: "MaterialIcons",
  });
  public static readonly spatial_audio_off: ui.IconData = new ui.IconData(
    60392,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly spatial_tracking: ui.IconData = new ui.IconData(
    60394,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly speaker: ui.IconData = new ui.IconData(58157, {
    fontFamily: "MaterialIcons",
  });
  public static readonly speaker_group: ui.IconData = new ui.IconData(58158, {
    fontFamily: "MaterialIcons",
  });
  public static readonly speaker_notes: ui.IconData = new ui.IconData(59597, {
    fontFamily: "MaterialIcons",
  });
  public static readonly speaker_notes_off: ui.IconData = new ui.IconData(
    59690,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly speaker_phone: ui.IconData = new ui.IconData(57554, {
    fontFamily: "MaterialIcons",
  });
  public static readonly speed: ui.IconData = new ui.IconData(59876, {
    fontFamily: "MaterialIcons",
  });
  public static readonly spellcheck: ui.IconData = new ui.IconData(59598, {
    fontFamily: "MaterialIcons",
  });
  public static readonly splitscreen: ui.IconData = new ui.IconData(61549, {
    fontFamily: "MaterialIcons",
  });
  public static readonly spoke: ui.IconData = new ui.IconData(59815, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sports: ui.IconData = new ui.IconData(59952, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sports_bar: ui.IconData = new ui.IconData(61939, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sports_baseball: ui.IconData = new ui.IconData(59985, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sports_basketball: ui.IconData = new ui.IconData(
    59942,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly sports_cricket: ui.IconData = new ui.IconData(59943, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sports_esports: ui.IconData = new ui.IconData(59944, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sports_football: ui.IconData = new ui.IconData(59945, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sports_golf: ui.IconData = new ui.IconData(59946, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sports_gymnastics: ui.IconData = new ui.IconData(
    60356,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly sports_handball: ui.IconData = new ui.IconData(59955, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sports_hockey: ui.IconData = new ui.IconData(59947, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sports_kabaddi: ui.IconData = new ui.IconData(59956, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sports_martial_arts: ui.IconData = new ui.IconData(
    60137,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly sports_mma: ui.IconData = new ui.IconData(59948, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sports_motorsports: ui.IconData = new ui.IconData(
    59949,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly sports_rugby: ui.IconData = new ui.IconData(59950, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sports_score: ui.IconData = new ui.IconData(61550, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sports_soccer: ui.IconData = new ui.IconData(59951, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sports_tennis: ui.IconData = new ui.IconData(59954, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sports_volleyball: ui.IconData = new ui.IconData(
    59953,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly square: ui.IconData = new ui.IconData(60214, {
    fontFamily: "MaterialIcons",
  });
  public static readonly square_foot: ui.IconData = new ui.IconData(59977, {
    fontFamily: "MaterialIcons",
  });
  public static readonly ssid_chart: ui.IconData = new ui.IconData(60262, {
    fontFamily: "MaterialIcons",
  });
  public static readonly stacked_bar_chart: ui.IconData = new ui.IconData(
    59878,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly stacked_line_chart: ui.IconData = new ui.IconData(
    61995,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly stadium: ui.IconData = new ui.IconData(60304, {
    fontFamily: "MaterialIcons",
  });
  public static readonly stairs: ui.IconData = new ui.IconData(61865, {
    fontFamily: "MaterialIcons",
  });
  public static readonly star: ui.IconData = new ui.IconData(59448, {
    fontFamily: "MaterialIcons",
  });
  public static readonly star_border: ui.IconData = new ui.IconData(59450, {
    fontFamily: "MaterialIcons",
  });
  public static readonly star_border_purple500: ui.IconData = new ui.IconData(
    61593,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly star_half: ui.IconData = new ui.IconData(59449, {
    fontFamily: "MaterialIcons",
  });
  public static readonly star_outline: ui.IconData = new ui.IconData(61551, {
    fontFamily: "MaterialIcons",
  });
  public static readonly star_purple500: ui.IconData = new ui.IconData(61594, {
    fontFamily: "MaterialIcons",
  });
  public static readonly star_rate: ui.IconData = new ui.IconData(61676, {
    fontFamily: "MaterialIcons",
  });
  public static readonly stars: ui.IconData = new ui.IconData(59600, {
    fontFamily: "MaterialIcons",
  });
  public static readonly start: ui.IconData = new ui.IconData(57481, {
    fontFamily: "MaterialIcons",
  });
  public static readonly stay_current_landscape: ui.IconData = new ui.IconData(
    57555,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly stay_current_portrait: ui.IconData = new ui.IconData(
    57556,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly stay_primary_landscape: ui.IconData = new ui.IconData(
    57557,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly stay_primary_portrait: ui.IconData = new ui.IconData(
    57558,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly sticky_note_2: ui.IconData = new ui.IconData(61948, {
    fontFamily: "MaterialIcons",
  });
  public static readonly stop: ui.IconData = new ui.IconData(57415, {
    fontFamily: "MaterialIcons",
  });
  public static readonly stop_circle: ui.IconData = new ui.IconData(61297, {
    fontFamily: "MaterialIcons",
  });
  public static readonly stop_screen_share: ui.IconData = new ui.IconData(
    57571,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly storage: ui.IconData = new ui.IconData(57819, {
    fontFamily: "MaterialIcons",
  });
  public static readonly store: ui.IconData = new ui.IconData(59601, {
    fontFamily: "MaterialIcons",
  });
  public static readonly store_mall_directory: ui.IconData = new ui.IconData(
    58723,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly storefront: ui.IconData = new ui.IconData(59922, {
    fontFamily: "MaterialIcons",
  });
  public static readonly storm: ui.IconData = new ui.IconData(61552, {
    fontFamily: "MaterialIcons",
  });
  public static readonly straight: ui.IconData = new ui.IconData(60309, {
    fontFamily: "MaterialIcons",
  });
  public static readonly straighten: ui.IconData = new ui.IconData(58396, {
    fontFamily: "MaterialIcons",
  });
  public static readonly stream: ui.IconData = new ui.IconData(59881, {
    fontFamily: "MaterialIcons",
  });
  public static readonly streetview: ui.IconData = new ui.IconData(58734, {
    fontFamily: "MaterialIcons",
  });
  public static readonly strikethrough_s: ui.IconData = new ui.IconData(57943, {
    fontFamily: "MaterialIcons",
  });
  public static readonly stroller: ui.IconData = new ui.IconData(61870, {
    fontFamily: "MaterialIcons",
  });
  public static readonly style: ui.IconData = new ui.IconData(58397, {
    fontFamily: "MaterialIcons",
  });
  public static readonly subdirectory_arrow_left: ui.IconData = new ui.IconData(
    58841,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly subdirectory_arrow_right: ui.IconData =
    new ui.IconData(58842, { fontFamily: "MaterialIcons" });
  public static readonly subject: ui.IconData = new ui.IconData(59602, {
    fontFamily: "MaterialIcons",
  });
  public static readonly subscript: ui.IconData = new ui.IconData(61713, {
    fontFamily: "MaterialIcons",
  });
  public static readonly subscriptions: ui.IconData = new ui.IconData(57444, {
    fontFamily: "MaterialIcons",
  });
  public static readonly subtitles: ui.IconData = new ui.IconData(57416, {
    fontFamily: "MaterialIcons",
  });
  public static readonly subtitles_off: ui.IconData = new ui.IconData(61298, {
    fontFamily: "MaterialIcons",
  });
  public static readonly subway: ui.IconData = new ui.IconData(58735, {
    fontFamily: "MaterialIcons",
  });
  public static readonly summarize: ui.IconData = new ui.IconData(61553, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sunny: ui.IconData = new ui.IconData(59418, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sunny_snowing: ui.IconData = new ui.IconData(59417, {
    fontFamily: "MaterialIcons",
  });
  public static readonly superscript: ui.IconData = new ui.IconData(61714, {
    fontFamily: "MaterialIcons",
  });
  public static readonly supervised_user_circle: ui.IconData = new ui.IconData(
    59705,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly supervisor_account: ui.IconData = new ui.IconData(
    59603,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly support: ui.IconData = new ui.IconData(61299, {
    fontFamily: "MaterialIcons",
  });
  public static readonly support_agent: ui.IconData = new ui.IconData(61666, {
    fontFamily: "MaterialIcons",
  });
  public static readonly surfing: ui.IconData = new ui.IconData(58645, {
    fontFamily: "MaterialIcons",
  });
  public static readonly surround_sound: ui.IconData = new ui.IconData(57417, {
    fontFamily: "MaterialIcons",
  });
  public static readonly swap_calls: ui.IconData = new ui.IconData(57559, {
    fontFamily: "MaterialIcons",
  });
  public static readonly swap_horiz: ui.IconData = new ui.IconData(59604, {
    fontFamily: "MaterialIcons",
  });
  public static readonly swap_horizontal_circle: ui.IconData = new ui.IconData(
    59699,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly swap_vert: ui.IconData = new ui.IconData(59605, {
    fontFamily: "MaterialIcons",
  });
  public static readonly swap_vertical_circle: ui.IconData = new ui.IconData(
    59606,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly swipe: ui.IconData = new ui.IconData(59884, {
    fontFamily: "MaterialIcons",
  });
  public static readonly swipe_down: ui.IconData = new ui.IconData(60243, {
    fontFamily: "MaterialIcons",
  });
  public static readonly swipe_down_alt: ui.IconData = new ui.IconData(60208, {
    fontFamily: "MaterialIcons",
  });
  public static readonly swipe_left: ui.IconData = new ui.IconData(60249, {
    fontFamily: "MaterialIcons",
  });
  public static readonly swipe_left_alt: ui.IconData = new ui.IconData(60211, {
    fontFamily: "MaterialIcons",
  });
  public static readonly swipe_right: ui.IconData = new ui.IconData(60242, {
    fontFamily: "MaterialIcons",
  });
  public static readonly swipe_right_alt: ui.IconData = new ui.IconData(60246, {
    fontFamily: "MaterialIcons",
  });
  public static readonly swipe_up: ui.IconData = new ui.IconData(60206, {
    fontFamily: "MaterialIcons",
  });
  public static readonly swipe_up_alt: ui.IconData = new ui.IconData(60213, {
    fontFamily: "MaterialIcons",
  });
  public static readonly swipe_vertical: ui.IconData = new ui.IconData(60241, {
    fontFamily: "MaterialIcons",
  });
  public static readonly switch_access_shortcut: ui.IconData = new ui.IconData(
    59361,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly switch_access_shortcut_add: ui.IconData =
    new ui.IconData(59362, { fontFamily: "MaterialIcons" });
  public static readonly switch_account: ui.IconData = new ui.IconData(59885, {
    fontFamily: "MaterialIcons",
  });
  public static readonly switch_camera: ui.IconData = new ui.IconData(58398, {
    fontFamily: "MaterialIcons",
  });
  public static readonly switch_left: ui.IconData = new ui.IconData(61905, {
    fontFamily: "MaterialIcons",
  });
  public static readonly switch_right: ui.IconData = new ui.IconData(61906, {
    fontFamily: "MaterialIcons",
  });
  public static readonly switch_video: ui.IconData = new ui.IconData(58399, {
    fontFamily: "MaterialIcons",
  });
  public static readonly synagogue: ui.IconData = new ui.IconData(60080, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sync: ui.IconData = new ui.IconData(58919, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sync_alt: ui.IconData = new ui.IconData(59928, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sync_disabled: ui.IconData = new ui.IconData(58920, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sync_lock: ui.IconData = new ui.IconData(60142, {
    fontFamily: "MaterialIcons",
  });
  public static readonly sync_problem: ui.IconData = new ui.IconData(58921, {
    fontFamily: "MaterialIcons",
  });
  public static readonly system_security_update: ui.IconData = new ui.IconData(
    61554,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly system_security_update_good: ui.IconData =
    new ui.IconData(61555, { fontFamily: "MaterialIcons" });
  public static readonly system_security_update_warning: ui.IconData =
    new ui.IconData(61556, { fontFamily: "MaterialIcons" });
  public static readonly system_update: ui.IconData = new ui.IconData(58922, {
    fontFamily: "MaterialIcons",
  });
  public static readonly system_update_alt: ui.IconData = new ui.IconData(
    59607,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly tab: ui.IconData = new ui.IconData(59608, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tab_unselected: ui.IconData = new ui.IconData(59609, {
    fontFamily: "MaterialIcons",
  });
  public static readonly table_bar: ui.IconData = new ui.IconData(60114, {
    fontFamily: "MaterialIcons",
  });
  public static readonly table_chart: ui.IconData = new ui.IconData(57957, {
    fontFamily: "MaterialIcons",
  });
  public static readonly table_restaurant: ui.IconData = new ui.IconData(
    60102,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly table_rows: ui.IconData = new ui.IconData(61697, {
    fontFamily: "MaterialIcons",
  });
  public static readonly table_view: ui.IconData = new ui.IconData(61886, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tablet: ui.IconData = new ui.IconData(58159, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tablet_android: ui.IconData = new ui.IconData(58160, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tablet_mac: ui.IconData = new ui.IconData(58161, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tag: ui.IconData = new ui.IconData(59887, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tag_faces: ui.IconData = new ui.IconData(58400, {
    fontFamily: "MaterialIcons",
  });
  public static readonly takeout_dining: ui.IconData = new ui.IconData(60020, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tap_and_play: ui.IconData = new ui.IconData(58923, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tapas: ui.IconData = new ui.IconData(61929, {
    fontFamily: "MaterialIcons",
  });
  public static readonly task: ui.IconData = new ui.IconData(61557, {
    fontFamily: "MaterialIcons",
  });
  public static readonly task_alt: ui.IconData = new ui.IconData(58086, {
    fontFamily: "MaterialIcons",
  });
  public static readonly taxi_alert: ui.IconData = new ui.IconData(61300, {
    fontFamily: "MaterialIcons",
  });
  public static readonly temple_buddhist: ui.IconData = new ui.IconData(60083, {
    fontFamily: "MaterialIcons",
  });
  public static readonly temple_hindu: ui.IconData = new ui.IconData(60079, {
    fontFamily: "MaterialIcons",
  });
  public static readonly terminal: ui.IconData = new ui.IconData(60302, {
    fontFamily: "MaterialIcons",
  });
  public static readonly terrain: ui.IconData = new ui.IconData(58724, {
    fontFamily: "MaterialIcons",
  });
  public static readonly text_decrease: ui.IconData = new ui.IconData(60125, {
    fontFamily: "MaterialIcons",
  });
  public static readonly text_fields: ui.IconData = new ui.IconData(57954, {
    fontFamily: "MaterialIcons",
  });
  public static readonly text_format: ui.IconData = new ui.IconData(57701, {
    fontFamily: "MaterialIcons",
  });
  public static readonly text_increase: ui.IconData = new ui.IconData(60130, {
    fontFamily: "MaterialIcons",
  });
  public static readonly text_rotate_up: ui.IconData = new ui.IconData(59706, {
    fontFamily: "MaterialIcons",
  });
  public static readonly text_rotate_vertical: ui.IconData = new ui.IconData(
    59707,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly text_rotation_angledown: ui.IconData = new ui.IconData(
    59708,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly text_rotation_angleup: ui.IconData = new ui.IconData(
    59709,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly text_rotation_down: ui.IconData = new ui.IconData(
    59710,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly text_rotation_none: ui.IconData = new ui.IconData(
    59711,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly text_snippet: ui.IconData = new ui.IconData(61894, {
    fontFamily: "MaterialIcons",
  });
  public static readonly textsms: ui.IconData = new ui.IconData(57560, {
    fontFamily: "MaterialIcons",
  });
  public static readonly texture: ui.IconData = new ui.IconData(58401, {
    fontFamily: "MaterialIcons",
  });
  public static readonly theater_comedy: ui.IconData = new ui.IconData(60006, {
    fontFamily: "MaterialIcons",
  });
  public static readonly theaters: ui.IconData = new ui.IconData(59610, {
    fontFamily: "MaterialIcons",
  });
  public static readonly thermostat: ui.IconData = new ui.IconData(61558, {
    fontFamily: "MaterialIcons",
  });
  public static readonly thermostat_auto: ui.IconData = new ui.IconData(61559, {
    fontFamily: "MaterialIcons",
  });
  public static readonly thumb_down: ui.IconData = new ui.IconData(59611, {
    fontFamily: "MaterialIcons",
  });
  public static readonly thumb_down_alt: ui.IconData = new ui.IconData(59414, {
    fontFamily: "MaterialIcons",
  });
  public static readonly thumb_down_off_alt: ui.IconData = new ui.IconData(
    59890,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly thumb_up: ui.IconData = new ui.IconData(59612, {
    fontFamily: "MaterialIcons",
  });
  public static readonly thumb_up_alt: ui.IconData = new ui.IconData(59415, {
    fontFamily: "MaterialIcons",
  });
  public static readonly thumb_up_off_alt: ui.IconData = new ui.IconData(
    59891,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly thumbs_up_down: ui.IconData = new ui.IconData(59613, {
    fontFamily: "MaterialIcons",
  });
  public static readonly thunderstorm: ui.IconData = new ui.IconData(60379, {
    fontFamily: "MaterialIcons",
  });
  public static readonly time_to_leave: ui.IconData = new ui.IconData(58924, {
    fontFamily: "MaterialIcons",
  });
  public static readonly timelapse: ui.IconData = new ui.IconData(58402, {
    fontFamily: "MaterialIcons",
  });
  public static readonly timeline: ui.IconData = new ui.IconData(59682, {
    fontFamily: "MaterialIcons",
  });
  public static readonly timer: ui.IconData = new ui.IconData(58405, {
    fontFamily: "MaterialIcons",
  });
  public static readonly timer_10: ui.IconData = new ui.IconData(58403, {
    fontFamily: "MaterialIcons",
  });
  public static readonly timer_10_select: ui.IconData = new ui.IconData(61562, {
    fontFamily: "MaterialIcons",
  });
  public static readonly timer_3: ui.IconData = new ui.IconData(58404, {
    fontFamily: "MaterialIcons",
  });
  public static readonly timer_3_select: ui.IconData = new ui.IconData(61563, {
    fontFamily: "MaterialIcons",
  });
  public static readonly timer_off: ui.IconData = new ui.IconData(58406, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tips_and_updates: ui.IconData = new ui.IconData(
    59290,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly tire_repair: ui.IconData = new ui.IconData(60360, {
    fontFamily: "MaterialIcons",
  });
  public static readonly title: ui.IconData = new ui.IconData(57956, {
    fontFamily: "MaterialIcons",
  });
  public static readonly toc: ui.IconData = new ui.IconData(59614, {
    fontFamily: "MaterialIcons",
  });
  public static readonly today: ui.IconData = new ui.IconData(59615, {
    fontFamily: "MaterialIcons",
  });
  public static readonly toggle_off: ui.IconData = new ui.IconData(59893, {
    fontFamily: "MaterialIcons",
  });
  public static readonly toggle_on: ui.IconData = new ui.IconData(59894, {
    fontFamily: "MaterialIcons",
  });
  public static readonly token: ui.IconData = new ui.IconData(59941, {
    fontFamily: "MaterialIcons",
  });
  public static readonly toll: ui.IconData = new ui.IconData(59616, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tonality: ui.IconData = new ui.IconData(58407, {
    fontFamily: "MaterialIcons",
  });
  public static readonly topic: ui.IconData = new ui.IconData(61896, {
    fontFamily: "MaterialIcons",
  });
  public static readonly touch_app: ui.IconData = new ui.IconData(59667, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tour: ui.IconData = new ui.IconData(61301, {
    fontFamily: "MaterialIcons",
  });
  public static readonly toys: ui.IconData = new ui.IconData(58162, {
    fontFamily: "MaterialIcons",
  });
  public static readonly track_changes: ui.IconData = new ui.IconData(59617, {
    fontFamily: "MaterialIcons",
  });
  public static readonly traffic: ui.IconData = new ui.IconData(58725, {
    fontFamily: "MaterialIcons",
  });
  public static readonly train: ui.IconData = new ui.IconData(58736, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tram: ui.IconData = new ui.IconData(58737, {
    fontFamily: "MaterialIcons",
  });
  public static readonly transfer_within_a_station: ui.IconData =
    new ui.IconData(58738, { fontFamily: "MaterialIcons" });
  public static readonly transform: ui.IconData = new ui.IconData(58408, {
    fontFamily: "MaterialIcons",
  });
  public static readonly transgender: ui.IconData = new ui.IconData(58765, {
    fontFamily: "MaterialIcons",
  });
  public static readonly transit_enterexit: ui.IconData = new ui.IconData(
    58745,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly translate: ui.IconData = new ui.IconData(59618, {
    fontFamily: "MaterialIcons",
  });
  public static readonly travel_explore: ui.IconData = new ui.IconData(58075, {
    fontFamily: "MaterialIcons",
  });
  public static readonly trending_down: ui.IconData = new ui.IconData(59619, {
    fontFamily: "MaterialIcons",
  });
  public static readonly trending_flat: ui.IconData = new ui.IconData(59620, {
    fontFamily: "MaterialIcons",
  });
  public static readonly trending_up: ui.IconData = new ui.IconData(59621, {
    fontFamily: "MaterialIcons",
  });
  public static readonly trip_origin: ui.IconData = new ui.IconData(58747, {
    fontFamily: "MaterialIcons",
  });
  public static readonly icon_try: ui.IconData = new ui.IconData(61564, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tsunami: ui.IconData = new ui.IconData(60376, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tty: ui.IconData = new ui.IconData(61866, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tune: ui.IconData = new ui.IconData(58409, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tungsten: ui.IconData = new ui.IconData(61565, {
    fontFamily: "MaterialIcons",
  });
  public static readonly turn_left: ui.IconData = new ui.IconData(60326, {
    fontFamily: "MaterialIcons",
  });
  public static readonly turn_right: ui.IconData = new ui.IconData(60331, {
    fontFamily: "MaterialIcons",
  });
  public static readonly turn_sharp_left: ui.IconData = new ui.IconData(60327, {
    fontFamily: "MaterialIcons",
  });
  public static readonly turn_sharp_right: ui.IconData = new ui.IconData(
    60330,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly turn_slight_left: ui.IconData = new ui.IconData(
    60324,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly turn_slight_right: ui.IconData = new ui.IconData(
    60314,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly turned_in: ui.IconData = new ui.IconData(59622, {
    fontFamily: "MaterialIcons",
  });
  public static readonly turned_in_not: ui.IconData = new ui.IconData(59623, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tv: ui.IconData = new ui.IconData(58163, {
    fontFamily: "MaterialIcons",
  });
  public static readonly tv_off: ui.IconData = new ui.IconData(58951, {
    fontFamily: "MaterialIcons",
  });
  public static readonly two_wheeler: ui.IconData = new ui.IconData(59897, {
    fontFamily: "MaterialIcons",
  });
  public static readonly u_turn_left: ui.IconData = new ui.IconData(60321, {
    fontFamily: "MaterialIcons",
  });
  public static readonly u_turn_right: ui.IconData = new ui.IconData(60322, {
    fontFamily: "MaterialIcons",
  });
  public static readonly umbrella: ui.IconData = new ui.IconData(61869, {
    fontFamily: "MaterialIcons",
  });
  public static readonly unarchive: ui.IconData = new ui.IconData(57705, {
    fontFamily: "MaterialIcons",
  });
  public static readonly undo: ui.IconData = new ui.IconData(57702, {
    fontFamily: "MaterialIcons",
  });
  public static readonly unfold_less: ui.IconData = new ui.IconData(58838, {
    fontFamily: "MaterialIcons",
  });
  public static readonly unfold_more: ui.IconData = new ui.IconData(58839, {
    fontFamily: "MaterialIcons",
  });
  public static readonly unpublished: ui.IconData = new ui.IconData(62006, {
    fontFamily: "MaterialIcons",
  });
  public static readonly unsubscribe: ui.IconData = new ui.IconData(57579, {
    fontFamily: "MaterialIcons",
  });
  public static readonly upcoming: ui.IconData = new ui.IconData(61566, {
    fontFamily: "MaterialIcons",
  });
  public static readonly update: ui.IconData = new ui.IconData(59683, {
    fontFamily: "MaterialIcons",
  });
  public static readonly update_disabled: ui.IconData = new ui.IconData(57461, {
    fontFamily: "MaterialIcons",
  });
  public static readonly upgrade: ui.IconData = new ui.IconData(61691, {
    fontFamily: "MaterialIcons",
  });
  public static readonly upload: ui.IconData = new ui.IconData(61595, {
    fontFamily: "MaterialIcons",
  });
  public static readonly upload_file: ui.IconData = new ui.IconData(59900, {
    fontFamily: "MaterialIcons",
  });
  public static readonly usb: ui.IconData = new ui.IconData(57824, {
    fontFamily: "MaterialIcons",
  });
  public static readonly usb_off: ui.IconData = new ui.IconData(58618, {
    fontFamily: "MaterialIcons",
  });
  public static readonly vaccines: ui.IconData = new ui.IconData(57656, {
    fontFamily: "MaterialIcons",
  });
  public static readonly vape_free: ui.IconData = new ui.IconData(60358, {
    fontFamily: "MaterialIcons",
  });
  public static readonly vaping_rooms: ui.IconData = new ui.IconData(60367, {
    fontFamily: "MaterialIcons",
  });
  public static readonly verified: ui.IconData = new ui.IconData(61302, {
    fontFamily: "MaterialIcons",
  });
  public static readonly verified_user: ui.IconData = new ui.IconData(59624, {
    fontFamily: "MaterialIcons",
  });
  public static readonly vertical_align_bottom: ui.IconData = new ui.IconData(
    57944,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly vertical_align_center: ui.IconData = new ui.IconData(
    57945,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly vertical_align_top: ui.IconData = new ui.IconData(
    57946,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly vertical_distribute: ui.IconData = new ui.IconData(
    57462,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly vertical_split: ui.IconData = new ui.IconData(59721, {
    fontFamily: "MaterialIcons",
  });
  public static readonly vibration: ui.IconData = new ui.IconData(58925, {
    fontFamily: "MaterialIcons",
  });
  public static readonly video_call: ui.IconData = new ui.IconData(57456, {
    fontFamily: "MaterialIcons",
  });
  public static readonly video_camera_back: ui.IconData = new ui.IconData(
    61567,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly video_camera_front: ui.IconData = new ui.IconData(
    61568,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly video_file: ui.IconData = new ui.IconData(60295, {
    fontFamily: "MaterialIcons",
  });
  public static readonly video_label: ui.IconData = new ui.IconData(57457, {
    fontFamily: "MaterialIcons",
  });
  public static readonly video_library: ui.IconData = new ui.IconData(57418, {
    fontFamily: "MaterialIcons",
  });
  public static readonly video_settings: ui.IconData = new ui.IconData(60021, {
    fontFamily: "MaterialIcons",
  });
  public static readonly video_stable: ui.IconData = new ui.IconData(61569, {
    fontFamily: "MaterialIcons",
  });
  public static readonly videocam: ui.IconData = new ui.IconData(57419, {
    fontFamily: "MaterialIcons",
  });
  public static readonly videocam_off: ui.IconData = new ui.IconData(57420, {
    fontFamily: "MaterialIcons",
  });
  public static readonly videogame_asset: ui.IconData = new ui.IconData(58168, {
    fontFamily: "MaterialIcons",
  });
  public static readonly videogame_asset_off: ui.IconData = new ui.IconData(
    58624,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly view_agenda: ui.IconData = new ui.IconData(59625, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_array: ui.IconData = new ui.IconData(59626, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_carousel: ui.IconData = new ui.IconData(59627, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_column: ui.IconData = new ui.IconData(59628, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_comfy: ui.IconData = new ui.IconData(58410, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_comfy_alt: ui.IconData = new ui.IconData(60275, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_compact: ui.IconData = new ui.IconData(58411, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_compact_alt: ui.IconData = new ui.IconData(
    60276,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly view_cozy: ui.IconData = new ui.IconData(60277, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_day: ui.IconData = new ui.IconData(59629, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_headline: ui.IconData = new ui.IconData(59630, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_in_ar: ui.IconData = new ui.IconData(59902, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_kanban: ui.IconData = new ui.IconData(60287, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_list: ui.IconData = new ui.IconData(59631, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_module: ui.IconData = new ui.IconData(59632, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_quilt: ui.IconData = new ui.IconData(59633, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_sidebar: ui.IconData = new ui.IconData(61716, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_stream: ui.IconData = new ui.IconData(59634, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_timeline: ui.IconData = new ui.IconData(60293, {
    fontFamily: "MaterialIcons",
  });
  public static readonly view_week: ui.IconData = new ui.IconData(59635, {
    fontFamily: "MaterialIcons",
  });
  public static readonly vignette: ui.IconData = new ui.IconData(58421, {
    fontFamily: "MaterialIcons",
  });
  public static readonly villa: ui.IconData = new ui.IconData(58758, {
    fontFamily: "MaterialIcons",
  });
  public static readonly visibility: ui.IconData = new ui.IconData(59636, {
    fontFamily: "MaterialIcons",
  });
  public static readonly visibility_off: ui.IconData = new ui.IconData(59637, {
    fontFamily: "MaterialIcons",
  });
  public static readonly voice_chat: ui.IconData = new ui.IconData(58926, {
    fontFamily: "MaterialIcons",
  });
  public static readonly voice_over_off: ui.IconData = new ui.IconData(59722, {
    fontFamily: "MaterialIcons",
  });
  public static readonly voicemail: ui.IconData = new ui.IconData(57561, {
    fontFamily: "MaterialIcons",
  });
  public static readonly volcano: ui.IconData = new ui.IconData(60378, {
    fontFamily: "MaterialIcons",
  });
  public static readonly volume_down: ui.IconData = new ui.IconData(57421, {
    fontFamily: "MaterialIcons",
  });
  public static readonly volume_down_alt: ui.IconData = new ui.IconData(59292, {
    fontFamily: "MaterialIcons",
  });
  public static readonly volume_mute: ui.IconData = new ui.IconData(57422, {
    fontFamily: "MaterialIcons",
  });
  public static readonly volume_off: ui.IconData = new ui.IconData(57423, {
    fontFamily: "MaterialIcons",
  });
  public static readonly volume_up: ui.IconData = new ui.IconData(57424, {
    fontFamily: "MaterialIcons",
  });
  public static readonly volunteer_activism: ui.IconData = new ui.IconData(
    60016,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly vpn_key: ui.IconData = new ui.IconData(57562, {
    fontFamily: "MaterialIcons",
  });
  public static readonly vpn_key_off: ui.IconData = new ui.IconData(60282, {
    fontFamily: "MaterialIcons",
  });
  public static readonly vpn_lock: ui.IconData = new ui.IconData(58927, {
    fontFamily: "MaterialIcons",
  });
  public static readonly vrpano: ui.IconData = new ui.IconData(61570, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wallpaper: ui.IconData = new ui.IconData(57788, {
    fontFamily: "MaterialIcons",
  });
  public static readonly warehouse: ui.IconData = new ui.IconData(60344, {
    fontFamily: "MaterialIcons",
  });
  public static readonly warning: ui.IconData = new ui.IconData(57346, {
    fontFamily: "MaterialIcons",
  });
  public static readonly warning_amber: ui.IconData = new ui.IconData(61571, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wash: ui.IconData = new ui.IconData(61873, {
    fontFamily: "MaterialIcons",
  });
  public static readonly watch: ui.IconData = new ui.IconData(58164, {
    fontFamily: "MaterialIcons",
  });
  public static readonly watch_later: ui.IconData = new ui.IconData(59684, {
    fontFamily: "MaterialIcons",
  });
  public static readonly watch_off: ui.IconData = new ui.IconData(60131, {
    fontFamily: "MaterialIcons",
  });
  public static readonly water: ui.IconData = new ui.IconData(61572, {
    fontFamily: "MaterialIcons",
  });
  public static readonly water_damage: ui.IconData = new ui.IconData(61955, {
    fontFamily: "MaterialIcons",
  });
  public static readonly water_drop: ui.IconData = new ui.IconData(59288, {
    fontFamily: "MaterialIcons",
  });
  public static readonly waterfall_chart: ui.IconData = new ui.IconData(59904, {
    fontFamily: "MaterialIcons",
  });
  public static readonly waves: ui.IconData = new ui.IconData(57718, {
    fontFamily: "MaterialIcons",
  });
  public static readonly waving_hand: ui.IconData = new ui.IconData(59238, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wb_auto: ui.IconData = new ui.IconData(58412, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wb_cloudy: ui.IconData = new ui.IconData(58413, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wb_incandescent: ui.IconData = new ui.IconData(58414, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wb_iridescent: ui.IconData = new ui.IconData(58422, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wb_shade: ui.IconData = new ui.IconData(59905, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wb_sunny: ui.IconData = new ui.IconData(58416, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wb_twighlight: ui.IconData = new ui.IconData(59906, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wb_twilight: ui.IconData = new ui.IconData(57798, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wc: ui.IconData = new ui.IconData(58941, {
    fontFamily: "MaterialIcons",
  });
  public static readonly web: ui.IconData = new ui.IconData(57425, {
    fontFamily: "MaterialIcons",
  });
  public static readonly web_asset: ui.IconData = new ui.IconData(57449, {
    fontFamily: "MaterialIcons",
  });
  public static readonly web_asset_off: ui.IconData = new ui.IconData(58615, {
    fontFamily: "MaterialIcons",
  });
  public static readonly web_stories: ui.IconData = new ui.IconData(58773, {
    fontFamily: "MaterialIcons",
  });
  public static readonly webhook: ui.IconData = new ui.IconData(60306, {
    fontFamily: "MaterialIcons",
  });
  public static readonly weekend: ui.IconData = new ui.IconData(57707, {
    fontFamily: "MaterialIcons",
  });
  public static readonly west: ui.IconData = new ui.IconData(61926, {
    fontFamily: "MaterialIcons",
  });
  public static readonly whatsapp: ui.IconData = new ui.IconData(60060, {
    fontFamily: "MaterialIcons",
  });
  public static readonly whatshot: ui.IconData = new ui.IconData(59406, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wheelchair_pickup: ui.IconData = new ui.IconData(
    61867,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly where_to_vote: ui.IconData = new ui.IconData(57719, {
    fontFamily: "MaterialIcons",
  });
  public static readonly widgets: ui.IconData = new ui.IconData(57789, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wifi: ui.IconData = new ui.IconData(58942, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wifi_1_bar: ui.IconData = new ui.IconData(58570, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wifi_2_bar: ui.IconData = new ui.IconData(58585, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wifi_calling: ui.IconData = new ui.IconData(61303, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wifi_calling_3: ui.IconData = new ui.IconData(61573, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wifi_channel: ui.IconData = new ui.IconData(60266, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wifi_find: ui.IconData = new ui.IconData(60209, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wifi_lock: ui.IconData = new ui.IconData(57825, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wifi_off: ui.IconData = new ui.IconData(58952, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wifi_password: ui.IconData = new ui.IconData(60267, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wifi_protected_setup: ui.IconData = new ui.IconData(
    61692,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly wifi_tethering: ui.IconData = new ui.IconData(57826, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wifi_tethering_error: ui.IconData = new ui.IconData(
    60121,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly wifi_tethering_off: ui.IconData = new ui.IconData(
    61575,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly window: ui.IconData = new ui.IconData(61576, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wine_bar: ui.IconData = new ui.IconData(61928, {
    fontFamily: "MaterialIcons",
  });
  public static readonly woman: ui.IconData = new ui.IconData(57662, {
    fontFamily: "MaterialIcons",
  });
  public static readonly work: ui.IconData = new ui.IconData(59641, {
    fontFamily: "MaterialIcons",
  });
  public static readonly work_off: ui.IconData = new ui.IconData(59714, {
    fontFamily: "MaterialIcons",
  });
  public static readonly work_outline: ui.IconData = new ui.IconData(59715, {
    fontFamily: "MaterialIcons",
  });
  public static readonly workspace_premium: ui.IconData = new ui.IconData(
    59311,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly workspaces: ui.IconData = new ui.IconData(57760, {
    fontFamily: "MaterialIcons",
  });
  public static readonly workspaces_filled: ui.IconData = new ui.IconData(
    59917,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly workspaces_outline: ui.IconData = new ui.IconData(
    59919,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly wrap_text: ui.IconData = new ui.IconData(57947, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wrong_location: ui.IconData = new ui.IconData(61304, {
    fontFamily: "MaterialIcons",
  });
  public static readonly wysiwyg: ui.IconData = new ui.IconData(61891, {
    fontFamily: "MaterialIcons",
  });
  public static readonly yard: ui.IconData = new ui.IconData(61577, {
    fontFamily: "MaterialIcons",
  });
  public static readonly youtube_searched_for: ui.IconData = new ui.IconData(
    59642,
    { fontFamily: "MaterialIcons" }
  );
  public static readonly zoom_in: ui.IconData = new ui.IconData(59647, {
    fontFamily: "MaterialIcons",
  });
  public static readonly zoom_in_map: ui.IconData = new ui.IconData(60205, {
    fontFamily: "MaterialIcons",
  });
  public static readonly zoom_out: ui.IconData = new ui.IconData(59648, {
    fontFamily: "MaterialIcons",
  });
  public static readonly zoom_out_map: ui.IconData = new ui.IconData(58731, {
    fontFamily: "MaterialIcons",
  });
}
