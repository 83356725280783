import React from "react";
import Locale from "../classes/Locale";
import StrutStyle from "../classes/StrutStyle";
import { TextAlign } from "../classes/TextAlign";
import { TextDirection } from "../classes/TextDirection";
import { TextHeightBehavior } from "../classes/TextHeightBehavior";
import { TextOverflow } from "../classes/TextOverflow";
import TextStyle from "../classes/TextStyle";
import { TextWidthBasis } from "../classes/TextWidthBasis";
import BaseUIProps, { processBaseUIProps } from "./BaseUIProps";

interface TextProps extends BaseUIProps {
  data: string;
  style?: TextStyle;
  strutStyle?: StrutStyle;
  textAlign?: TextAlign;
  textDirection?: TextDirection;
  locale?: Locale;
  softWrap?: boolean;
  overflow?: TextOverflow;
  textScaleFactor?: number;
  maxLines?: number;
  semanticsLabel?: string;
  textWidthBasis?: TextWidthBasis;
  textHeightBehavior?: TextHeightBehavior;
}

function toTextAlign(textAlign: TextAlign) {
  switch (textAlign) {
    case TextAlign.left:
      return "left";
    case TextAlign.right:
      return "right";
    case TextAlign.center:
      return "center";
    case TextAlign.justify:
      return "justify";
    case TextAlign.start:
      return "start";
    case TextAlign.end:
      return "end";
  }
}

class _Text extends React.Component<TextProps, {}> {
  render() {
    let style: any = {};
    if (this.props.style) {
      style = this.props.style.toCss();
    }
    if (this.props.textAlign) {
      style.textAlign = toTextAlign(this.props.textAlign);
    }
    if (this.props.textDirection) {
      style.direction = this.props.textDirection;
    }
    if (this.props.locale) {
      style.locale = this.props.locale;
    }
    if (this.props.softWrap) {
      style.whiteSpace = "normal";
    }
    if (this.props.overflow) {
      style.overflow = this.props.overflow;
    }
    if (this.props.textScaleFactor) {
      style.textScaleFactor = this.props.textScaleFactor;
    }
    if (this.props.maxLines) {
      style.maxLines = this.props.maxLines;
    }
    if (this.props.semanticsLabel) {
      style.semanticsLabel = this.props.semanticsLabel;
    }
    if (this.props.textWidthBasis) {
      style.textWidthBasis = this.props.textWidthBasis;
    }
    if (this.props.textHeightBehavior) {
      style.textHeightBehavior = this.props.textHeightBehavior;
    }

    return React.createElement(
      "label",
      {
        class: this.props.className ? this.props.className : "",
        style: style,
        ...processBaseUIProps(this.props),
      },
      this.props.data
    );
  }
}

export default function Text(props: TextProps) {
  return React.createElement(_Text, props);
}
