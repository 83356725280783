import BaseUser from "./BaseUser";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";
import AdminRole from "../classes/AdminRole";

export default class Admin extends BaseUser {
  private static readonly _FULLNAME: number = 0;
  private static readonly _ROLE: number = 1;
  private _fullName: string = "";
  private _role: AdminRole = AdminRole.School;
  public constructor(
    d3eParams?: Partial<{ fullName: string; role: AdminRole }>
  ) {
    super();

    this.setFullName(d3eParams?.fullName ?? "");

    this.setRole(d3eParams?.role ?? AdminRole.School);
  }
  public get d3eType(): string {
    return "Admin";
  }
  public get fullName(): string {
    return this._fullName;
  }
  public setFullName(val: string): void {
    let isValChanged: boolean = this._fullName !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Admin._FULLNAME, this._fullName);

    this._fullName = val;

    this.fire("fullName", this);
  }
  public get role(): AdminRole {
    return this._role;
  }
  public setRole(val: AdminRole): void {
    let isValChanged: boolean = this._role !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Admin._ROLE, this._role.index);

    this._role = val;

    this.fire("role", this);
  }
  public get(field: number): any {
    switch (field) {
      case Admin._FULLNAME: {
        return this._fullName;
      }

      case Admin._ROLE: {
        return this._role.index;
      }
      default: {
        return super.get(field);
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): Admin {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {
    super.collectChildValues(ctx);
  }
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    super.deepCloneIntoObj(dbObj, ctx);

    let obj: Admin = dbObj as Admin;

    obj.setFullName(this._fullName);

    obj.setRole(this._role);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case Admin._FULLNAME: {
        this.setFullName(value as string);
        break;
      }

      case Admin._ROLE: {
        this.setRole(AdminRole.values[value as number]);
        break;
      }
      default: {
        super.set(field, value);
      }
    }
  }
}
