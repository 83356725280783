import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import TextView from "./TextView";
import Duration from "../core/Duration";
import DurationUtils from "../classes/DurationUtils";

export interface DurationViewProps extends BaseUIProps {
  key?: string;
  value: Duration;
  format?: string;
  showZero?: boolean;
  color?: ui.Color;
}

class _DurationViewState extends ObservableComponent<DurationViewProps> {
  static defaultProps = {
    value: null,
    format: "",
    showZero: false,
    color: null,
  };
  formattedDuration: string = "";
  public constructor(props: DurationViewProps) {
    super(props);

    this.initState();
  }
  public get value(): Duration {
    return this.props.value;
  }
  public get format(): string {
    return this.props.format;
  }
  public get showZero(): boolean {
    return this.props.showZero;
  }
  public get color(): ui.Color {
    return this.props.color;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["format", "showZero", "value"], this.computeFormattedDuration);

    this.computeFormattedDuration();

    this.on(["color", "formattedDuration"], this.rebuild);
  }
  public componentDidUpdate(prevProps: DurationViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.format !== this.props.format) {
      this.fire("format", this);
    }

    if (prevProps.showZero !== this.props.showZero) {
      this.fire("showZero", this);
    }

    if (prevProps.color !== this.props.color) {
      this.fire("color", this);
    }
  }
  public setFormattedDuration(val: string): void {
    let isValChanged: boolean = this.formattedDuration !== val;

    if (!isValChanged) {
      return;
    }

    this.formattedDuration = val;

    this.fire("formattedDuration", this);
  }
  public computeFormattedDuration = (): void => {
    try {
      this.setFormattedDuration(
        DurationUtils.formattedDuration(this.value, this.format, this.showZero)
      );
    } catch (exception) {
      console.log(
        " exception in computeFormattedDuration : " + exception.toString()
      );

      this.setFormattedDuration("");
    }
  };
  public render(): ReactNode {
    return TextView({
      data: this.formattedDuration,
      style: new ui.TextStyle({
        color: this.color !== null ? this.color : null,
      }),
      className: ui.join(this.props.className, "DurationView"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function DurationView(props: DurationViewProps) {
  return React.createElement(_DurationViewState, {
    ..._DurationViewState.defaultProps,
    ...props,
  });
}
