import CalendarEvent from "./CalendarEvent";
import Duration from "../core/Duration";
import DateTime from "../core/DateTime";
import D3EDate from "./D3EDate";

export default class CalendarHelper {
  public events: Array<CalendarEvent> = null;
  public constructor(events: Array<CalendarEvent>) {
    this.events = events;
  }
  public getEventsForDate(
    date: DateTime,
    categories: Array<string>
  ): Array<CalendarEvent> {
    if (categories.isEmpty) {
      return this.events
        .where(
          (event) =>
            event.date.year === date.year &&
            event.date.month === date.month &&
            event.date.day === date.day
        )
        .toList();
    } else {
      return this.events
        .where(
          (event) =>
            event.date.year === date.year &&
            event.date.month === date.month &&
            event.date.day === date.day &&
            categories.contains(event.category)
        )
        .toList();
    }
  }
  public getEventsForMonth(
    date: DateTime,
    categories: Array<string>
  ): Array<CalendarEvent> {
    if (categories.isEmpty) {
      return this.events
        .where(
          (event) =>
            event.date.year === date.year && event.date.month === date.month
        )
        .toList();
    } else {
      return this.events
        .where(
          (event) =>
            event.date.year === date.year &&
            event.date.month === date.month &&
            categories.contains(event.category)
        )
        .toList();
    }
  }
  public static to(n: number): Array<number> {
    let res: Array<number> = [];

    for (let x: number = 1; x <= n; x++) {
      res.add(x);
    }

    return res;
  }
  public getAllDayEventsForDate(
    date: DateTime,
    categories: Array<string>
  ): Array<CalendarEvent> {
    if (categories.isEmpty) {
      return this.events
        .where(
          (event) =>
            event.date.year === date.year &&
            event.date.month === date.month &&
            event.date.day === date.day &&
            event.allDay
        )
        .toList();
    } else {
      return this.events
        .where(
          (event) =>
            event.date.year === date.year &&
            event.date.month === date.month &&
            event.date.day === date.day &&
            event.allDay &&
            categories.contains(event.category)
        )
        .toList();
    }
  }
  public static formatHour(hour: number): string {
    if (hour === 0) {
      return "12AM";
    } else if (hour < 12) {
      return hour.toString() + "AM";
    } else if (hour === 12) {
      return "12PM";
    } else {
      return (hour - 12).toString() + "PM";
    }
  }
  public sortEvents(events: Array<CalendarEvent>): Array<CalendarEvent> {
    let allDayEvents: Array<CalendarEvent> = events
      .where((event) => !event.allDay)
      .toList();

    allDayEvents.sort((a, b) => a.startTime.compareTo(b.startTime));

    return allDayEvents;
  }
  public getEventHeight(event: CalendarEvent): number {
    let height: number = event.endTime
      .difference(event.startTime)
      .inMinutes.toDouble();

    //  if 60 minutes equals 100 px height, then find out height of above

    height = (height * 100) / 60;

    if (height < 25.0) {
      height = 25.0;
    }

    return height;
  }
  public getEventTop(event: CalendarEvent): number {
    let top: number = event.startTime.minute.toDouble();

    top = (top * 100) / 60;

    return top;
  }
  public static getWeekDay(date: D3EDate): string {
    let dayOfWeek: number = date.dayOfWeek;

    let weekday: string;

    switch (dayOfWeek) {
      case 0: {
        weekday = "Sunday";
        break;
      }

      case 1: {
        weekday = "Monday";
        break;
      }

      case 2: {
        weekday = "Tuesday";
        break;
      }

      case 3: {
        weekday = "Wednesday";
        break;
      }

      case 4: {
        weekday = "Thursday";
        break;
      }

      case 5: {
        weekday = "Friday";
        break;
      }

      case 6: {
        weekday = "Saturday";
        break;
      }
      default: {
      }
    }

    return weekday;
  }
  public static getWeekDayDates(dateTime: DateTime): Array<DateTime> {
    let weekDayDates: Array<DateTime> = [];

    //  Calculate the start of the current week (Monday)

    //  dateTime.weekday gives 1 for Monday, 2 for Tuesday, ... 7 for Sunday

    //  To find the previous Monday, we subtract (dateTime.weekday - 1) days

    let monday: DateTime = dateTime.subtract(
      new Duration({ days: dateTime.weekday - 1 })
    );

    //  Loop to add the dates of the week

    for (let i: number = 0; i < 7; i++) {
      weekDayDates.add(monday.add(new Duration({ days: i })));
    }

    return weekDayDates;
  }
}
