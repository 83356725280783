import React, { ReactNode } from "react";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";

interface GoogleMapMarkerProps extends BaseUIProps {
  lat: number;
  lng: number;
  child: ReactNode;
}

class _GoogleMapMarkerState extends React.Component<GoogleMapMarkerProps, {}> {
  constructor(props: GoogleMapMarkerProps) {
    super(props);
  }

  public render(): ReactNode {
    return React.createElement(
      "div",
      {
        lat: this.props.lat,
        lng: this.props.lng,
      },
      this.props.child
    );
  }
}
export default function GoogleMapMarker(props: GoogleMapMarkerProps) {
  return React.createElement(_GoogleMapMarkerState, props);
}
