import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";
import RentalRequest from "./RentalRequest";

export default class RentalRequestChanges extends DBObject {
  private static readonly _CHANGES: number = 0;
  private static readonly _REQUEST: number = 1;
  public id: number = 0;
  public otherMaster: DBObject;
  private _request: RentalRequest = null;
  private _changes: string = "";
  public constructor(
    d3eParams?: Partial<{ changes: string; request: RentalRequest }>
  ) {
    super();

    this.setChanges(d3eParams?.changes ?? "");

    this.setRequest(d3eParams?.request ?? null);
  }
  public get d3eType(): string {
    return "RentalRequestChanges";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isTransientModel(): boolean {
    return;
  }
  public get request(): RentalRequest {
    return this._request;
  }
  public setRequest(val: RentalRequest): void {
    let isValChanged: boolean = this._request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequestChanges._REQUEST, this._request);

    this.updateObservable("request", this._request, val);

    this._request = val;

    this.fire("request", this);
  }
  public get changes(): string {
    return this._changes;
  }
  public setChanges(val: string): void {
    let isValChanged: boolean = this._changes !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequestChanges._CHANGES, this._changes);

    this._changes = val;

    this.fire("changes", this);
  }
  public get(field: number): any {
    switch (field) {
      case RentalRequestChanges._REQUEST: {
        return this._request;
      }

      case RentalRequestChanges._CHANGES: {
        return this._changes;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): RentalRequestChanges {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: RentalRequestChanges = dbObj as RentalRequestChanges;

    obj.id = this.id;

    obj.setRequest(this._request);

    obj.setChanges(this._changes);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case RentalRequestChanges._REQUEST: {
        this.setRequest(value as RentalRequest);
        break;
      }

      case RentalRequestChanges._CHANGES: {
        this.setChanges(value as string);
        break;
      }
    }
  }
}
