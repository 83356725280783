import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import DateTimeView from "./DateTimeView";
import PendingPaymentsRequest from "../models/PendingPaymentsRequest";
import RentalRequest from "../models/RentalRequest";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import DateTime from "../core/DateTime";
import Renter from "../models/Renter";
import PendingPayments from "../classes/PendingPayments";
import IconView from "./IconView";
import MessageDispatch from "../rocket/MessageDispatch";
import Query from "../classes/Query";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

export interface RentalCurrentInvoicePageProps extends BaseUIProps {
  key?: string;
  renter: Renter;
}

class _RentalCurrentInvoicePageState extends ObservableComponent<RentalCurrentInvoicePageProps> {
  static defaultProps = { renter: null };
  query: PendingPayments = null;
  paidAmount: number = 0.0;
  totalAmount: number = 0.0;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RentalCurrentInvoicePageProps) {
    super(props);

    this.initState();
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("renter", this.props.renter);

    this.on(["renter"], this.computeQuery);

    this.computeQuery();

    this.on(
      ["query", "query.items", "query.items.paid"],
      this.computePaidAmount
    );

    this.computePaidAmount();

    this.on(
      ["query", "query.items", "query.items.total"],
      this.computeTotalAmount
    );

    this.computeTotalAmount();

    this.on(
      [
        "paidAmount",
        "query",
        "query.items",
        "query.items.eventName",
        "query.items.facility",
        "query.items.facility.name",
        "query.items.school",
        "query.items.school.name",
        "query.items.total",
        "renter",
        "renter.fullName",
        "totalAmount",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RentalCurrentInvoicePageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public setQuery(val: PendingPayments): void {
    let isValChanged: boolean = this.query !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("query", this.query, val);

    MessageDispatch.get().dispose(this.query);

    this.query = val;

    this.fire("query", this);
  }
  public computeQuery = async (): Promise<void> => {
    try {
      this.setQuery(
        await Query.get().getPendingPayments(
          UsageConstants.QUERY_GETPENDINGPAYMENTS_RENTALCURRENTINVOICEPAGE_PROPERTIES_QUERY_COMPUTATION,
          new PendingPaymentsRequest({
            renter: this.renter,
            pageSize: 100,
            offset: 0,
          }),
          { "synchronize": true }
        )
      );
    } catch (exception) {
      console.log(" exception in computeQuery : " + exception.toString());

      this.setQuery(null);
    }
  };
  public setPaidAmount(val: number): void {
    let isValChanged: boolean = this.paidAmount !== val;

    if (!isValChanged) {
      return;
    }

    this.paidAmount = val;

    this.fire("paidAmount", this);
  }
  public computePaidAmount = (): void => {
    try {
      this.setPaidAmount(this.query?.items.fold(0.0, (p, e) => p + e.paid));
    } catch (exception) {
      console.log(" exception in computePaidAmount : " + exception.toString());

      this.setPaidAmount(0.0);
    }
  };
  public setTotalAmount(val: number): void {
    let isValChanged: boolean = this.totalAmount !== val;

    if (!isValChanged) {
      return;
    }

    this.totalAmount = val;

    this.fire("totalAmount", this);
  }
  public computeTotalAmount = (): void => {
    try {
      this.setTotalAmount(this.query?.items.fold(0.0, (p, e) => p + e.total));
    } catch (exception) {
      console.log(" exception in computeTotalAmount : " + exception.toString());

      this.setTotalAmount(0.0);
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.start,
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            TextView({
              data: "Current Invoice",
              style: new ui.TextStyle({
                fontSize: 20.0,
                fontWeight: ui.FontWeight.bold,
                color: cStyle.c6,
              }),
              className: "xaa1",
              key: "0",
            }),
          ],
          className: "xbab hc h",
          key: "0",
        }),
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          crossAxisAlignment: ui.CrossAxisAlignment.end,
          children: [
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                TextView({
                  data: "Invoice",
                  style: new ui.TextStyle({
                    fontSize: 20.0,
                    fontWeight: ui.FontWeight.bold,
                  }),
                  className: "x14b hc",
                  key: "0",
                }),
                TextView({
                  data: this.renter.fullName,
                  style: new ui.TextStyle({ fontSize: 18 }),
                  className: "xbfc hc",
                  key: "1",
                }),
              ],
              className: "x555 hc h",
              key: "0",
            }),
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                TextView({
                  data: "Utah",
                  style: new ui.TextStyle({ fontWeight: ui.FontWeight.bold }),
                  className: "xbd4a",
                  key: "0",
                }),
                TextView({ data: "392 E Winchester St, Suite 201,", key: "1" }),
                TextView({ data: "Murray, Utah 84107", key: "2" }),
              ],
              key: "1",
            }),
          ],
          className: "x587 hc h",
          key: "1",
        }),
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.end,
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            DateTimeView({
              value: DateTime.now(),
              format: "MMM dd, yyyy hh:mm a",
              key: "0",
            }),
          ],
          className: "x0d9 hc h",
          key: "2",
        }),
        this.query?.items.isNotEmpty
          ? ui.Column({
              mainAxisAlignment: ui.MainAxisAlignment.start,
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.Table({
                  defaultColumnWidth: new ui.FlexColumnWidth(1),
                  columnWidths: { 0: new ui.FlexColumnWidth(1) },
                  children: [
                    ui.TableRow({
                      children: [
                        ui.TableCell({
                          child: TextView({
                            data: "S.No",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "x41 hc",
                          }),
                          className: "hc",
                          key: "0",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: "Event Name",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "x610 hc",
                          }),
                          className: "hc",
                          key: "1",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: "Facility",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "x63b hc",
                          }),
                          className: "hc",
                          key: "2",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: "School",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "x454 hc",
                          }),
                          className: "hc",
                          key: "3",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: "Amount",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "xd6c hc",
                          }),
                          className: "hc",
                          key: "4",
                        }),
                      ],
                      className: "x62f hc",
                      key: "0",
                    }),
                    (this.query?.items ?? []).expand((item) => [
                      ui.TableRow({
                        children: [
                          ui.TableCell({
                            child: TextView({
                              data: (
                                this.query.items.indexOf(item) + 1
                              ).toString(),
                              style: new ui.TextStyle({
                                fontSize: 16,
                                color: cStyle.c20,
                              }),
                              className: "x997a hc",
                            }),
                            className: "hc",
                            key: "0",
                          }),
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: TextView({
                              data: item.eventName,
                              style: new ui.TextStyle({
                                fontFamily:
                                  cStyle.tTextViewSubHeaderFontFamilyOn,
                                color: cStyle.tTextViewSubHeaderColorOn,
                              }),
                              className: "x458 hc",
                            }),
                            className: "hc",
                            key: "1",
                          }),
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: TextView({
                              data: item.facility.name,
                              style: new ui.TextStyle({
                                fontFamily:
                                  cStyle.tTextViewSubHeaderFontFamilyOn,
                                color: cStyle.tTextViewSubHeaderColorOn,
                              }),
                              className: "xdc5 hc",
                            }),
                            className: "hc",
                            key: "2",
                          }),
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: TextView({
                              data: item.school.name,
                              style: new ui.TextStyle({
                                fontFamily:
                                  cStyle.tTextViewSubHeaderFontFamilyOn,
                                color: cStyle.tTextViewSubHeaderColorOn,
                              }),
                              className: "x9b4 hc",
                            }),
                            className: "hc",
                            key: "3",
                          }),
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: TextView({
                              data: "$" + item.total.toString(),
                              style: new ui.TextStyle({
                                fontFamily:
                                  cStyle.tTextViewSubHeaderFontFamilyOn,
                                color: cStyle.tTextViewSubHeaderColorOn,
                              }),
                              className: "x4e77 hc",
                            }),
                            className: "hc",
                            key: "4",
                          }),
                        ],
                        className: "x069 hc",
                        key: item?.ident,
                      }),
                    ]),
                  ],
                  className: "x6be hc h",
                  key: "0",
                }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.end,
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    ui.Column({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        ui.Wrap({
                          spacing: 20,
                          children: [
                            TextView({
                              data: "Total: ",
                              style: new ui.TextStyle({
                                fontWeight: ui.FontWeight.bold,
                                color: cStyle.c19,
                              }),
                              className: "x1dc hc",
                              key: "0",
                            }),
                            TextView({
                              data: "$" + this.totalAmount.toString(),
                              style: new ui.TextStyle({
                                fontWeight: ui.FontWeight.bold,
                                color: cStyle.c19,
                              }),
                              className: "xa44a hc",
                              key: "1",
                            }),
                          ],
                          className: "hc",
                          key: "0",
                        }),
                        ui.Wrap({
                          spacing: 20,
                          children: [
                            TextView({
                              data: "Paid: ",
                              states: ui.joinStates(
                                {
                                  "data-c0":
                                    this.paidAmount === this.totalAmount,
                                },
                                {}
                              ),
                              style: new ui.TextStyle({
                                fontWeight: ui.FontWeight.bold,
                                color:
                                  this.paidAmount === this.totalAmount
                                    ? cStyle.c19
                                    : null,
                              }),
                              className: "xa7b hc",
                              key: "0",
                            }),
                            TextView({
                              data: "$" + this.paidAmount.toString(),
                              states: ui.joinStates(
                                {
                                  "data-c0":
                                    this.paidAmount === this.totalAmount,
                                },
                                {}
                              ),
                              style: new ui.TextStyle({
                                fontWeight: ui.FontWeight.bold,
                                color:
                                  this.paidAmount === this.totalAmount
                                    ? cStyle.c19
                                    : null,
                              }),
                              className: "x513 hc",
                              key: "1",
                            }),
                          ],
                          className: "hc",
                          key: "1",
                        }),
                        ui.Wrap({
                          spacing: 20,
                          children: [
                            TextView({
                              data: "Due: ",
                              states: ui.joinStates(
                                {
                                  "data-c0":
                                    this.paidAmount !== this.totalAmount,
                                },
                                {}
                              ),
                              style: new ui.TextStyle({
                                fontWeight: ui.FontWeight.bold,
                                color:
                                  this.paidAmount !== this.totalAmount
                                    ? new ui.Color(0xffe71306)
                                    : null,
                              }),
                              className: "x3a1 hc",
                              key: "0",
                            }),
                            TextView({
                              data:
                                "$" +
                                (this.totalAmount - this.paidAmount).toString(),
                              states: ui.joinStates(
                                {
                                  "data-c0":
                                    this.paidAmount !== this.totalAmount,
                                },
                                {}
                              ),
                              style: new ui.TextStyle({
                                fontWeight: ui.FontWeight.bold,
                                color:
                                  this.paidAmount !== this.totalAmount
                                    ? new ui.Color(0xffe71306)
                                    : null,
                              }),
                              className: "xc9b hc",
                              key: "1",
                            }),
                          ],
                          className: "hc",
                          key: "2",
                        }),
                      ],
                      className: "x742 hc",
                      key: "0",
                    }),
                  ],
                  className: "xab4 hc h",
                  key: "1",
                }),
              ],
              className: "xb41 hc h",
            })
          : ui.Column({
              children: [
                IconView({
                  icon: MaterialIcons.check_circle,
                  size: 150,
                  color: cStyle.c6,
                  className: "x9fc hc",
                  key: "0",
                }),
                TextView({
                  data: "No Payment Required",
                  className: "hc",
                  key: "1",
                }),
              ],
              className: "hc",
            }),
      ],
      className: ui.join(
        this.props.className,
        "RentalCurrentInvoicePage xf41 hc vc"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public dispose(): void {
    MessageDispatch.get().dispose(this.query);

    super.dispose();
  }
}
export default function RentalCurrentInvoicePage(
  props: RentalCurrentInvoicePageProps
) {
  return React.createElement(_RentalCurrentInvoicePageState, {
    ..._RentalCurrentInvoicePageState.defaultProps,
    ...props,
  });
}
