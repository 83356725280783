export default class Range {
  public constructor() {}
  public static to(n: number): Array<number> {
    let res: Array<number> = [];

    for (let x: number = 0; x < n; x++) {
      res.add(x);
    }

    return res;
  }
}
