import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CalendarViewType from "../classes/CalendarViewType";
import CalendarEvent from "../classes/CalendarEvent";

type _EventDetailsPopupViewBuilder<CalendarEvent, CalendarViewType> = (
  context: any,
  event: CalendarEvent,
  viewType: CalendarViewType
) => ReactNode;

export interface EventDetailsPopupViewProps extends BaseUIProps {
  key?: string;
  event?: CalendarEvent;
  type?: CalendarViewType;
  builder?: _EventDetailsPopupViewBuilder<CalendarEvent, CalendarViewType>;
}

class _EventDetailsPopupViewState extends ObservableComponent<EventDetailsPopupViewProps> {
  static defaultProps = { event: null, type: CalendarViewType.Month };
  public constructor(props: EventDetailsPopupViewProps) {
    super(props);

    this.initState();
  }
  public get event(): CalendarEvent {
    return this.props.event;
  }
  public get type(): CalendarViewType {
    return this.props.type;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("event", this.props.event);

    this.on(["event", "type"], this.rebuild);
  }
  public componentDidUpdate(prevProps: EventDetailsPopupViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.event !== this.props.event) {
      this.updateObservable("event", prevProps.event, this.props.event);

      this.fire("event", this);
    }

    if (prevProps.type !== this.props.type) {
      this.fire("type", this);
    }
  }
  public render(): ReactNode {
    return this.props.builder(this.context, this.event, this.type);
  }
}
export default function EventDetailsPopupView(
  props: EventDetailsPopupViewProps
) {
  return React.createElement(_EventDetailsPopupViewState, {
    ..._EventDetailsPopupViewState.defaultProps,
    ...props,
  });
}
