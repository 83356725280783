import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import InvoiceView from "./InvoiceView";
import DateTimeView from "./DateTimeView";
import D3EDisposable from "../rocket/D3EDisposable";
import RentalRequest from "../models/RentalRequest";
import TextView from "./TextView";
import TimeView from "./TimeView";
import Admin from "../models/Admin";
import MessageDispatch from "../rocket/MessageDispatch";
import Query from "../classes/Query";
import { UsageConstants } from "../rocket/D3ETemplate";

export interface ReservationViewProps extends BaseUIProps {
  key?: string;
  user: Admin;
  request: RentalRequest;
}

class _ReservationViewState extends ObservableComponent<ReservationViewProps> {
  static defaultProps = { user: null, request: null };
  public requestDisposable: D3EDisposable;
  public constructor(props: ReservationViewProps) {
    super(props);

    this.initState();
  }
  public get user(): Admin {
    return this.props.user;
  }
  public get request(): RentalRequest {
    return this.props.request;
  }
  public initState() {
    super.initState();

    this.runFetchDataQueryForRequest();

    this.requestDisposable = MessageDispatch.get().syncObject(
      this.props.request,
      UsageConstants.QUERY_GETRENTALREQUESTBYID_RESERVATIONVIEW_REQUEST_FETCHDATA
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.updateSyncProperty("request", this.props.request);

    this.on(
      [
        "request",
        "request.createdBy",
        "request.createdBy.fullName",
        "request.endTime",
        "request.eventDate",
        "request.eventName",
        "request.facility",
        "request.facility.name",
        "request.school",
        "request.school.address",
        "request.school.name",
        "request.school.schoolType",
        "request.school.schoolType.name",
        "request.startTime",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ReservationViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.request !== this.props.request) {
      this.updateObservable("request", prevProps.request, this.props.request);

      this.runFetchDataQueryForRequest();

      this.fire("request", this);
    }
  }
  public runFetchDataQueryForRequest = (): void => {
    if (this.request == null) {
      return;
    }

    Query.get().getRentalRequestById(
      UsageConstants.QUERY_GETRENTALREQUESTBYID_RESERVATIONVIEW_REQUEST_FETCHDATA,
      this.request.id
    );
  };
  public render(): ReactNode {
    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                TextView({
                  data: "Event Details: ",
                  style: new ui.TextStyle({ fontWeight: ui.FontWeight.bold }),
                  className: "x34b hc",
                  key: "0",
                }),
                TextView({
                  data: this.request.eventName,
                  className: "hc",
                  key: "1",
                }),
                DateTimeView({
                  value: this.request.eventDate.toDateTime(),
                  format: "MMM dd, yyyy",
                  className: "hc",
                  key: "2",
                }),
                ui.Wrap({
                  spacing: 5,
                  children: [
                    TimeView({
                      value: this.request.startTime,
                      format: "hh:mm a",
                      className: "hc",
                      key: "0",
                    }),
                    TextView({ data: "-", className: "hc", key: "1" }),
                    TimeView({
                      value: this.request.endTime,
                      format: "hh:mm a",
                      className: "hc",
                      key: "2",
                    }),
                  ],
                  className: "hc",
                  key: "3",
                }),
                TextView({
                  data: "Facility Details: ",
                  style: new ui.TextStyle({ fontWeight: ui.FontWeight.bold }),
                  className: "x0de3 hc",
                  key: "4",
                }),
                TextView({
                  data: this.request.facility.name,
                  className: "hc",
                  key: "5",
                }),
                TextView({
                  data:
                    this.request.school.name +
                    " - " +
                    this.request.school.schoolType.name,
                  softWrap: true,
                  className: "hc",
                  key: "6",
                }),
                TextView({
                  data: "Address: ",
                  style: new ui.TextStyle({ fontWeight: ui.FontWeight.bold }),
                  className: "x9266 hc",
                  key: "7",
                }),
                TextView({
                  data: this.request.school.address,
                  className: "hc",
                  key: "8",
                }),
                TextView({
                  data: "Renter: " + this.request.createdBy.fullName,
                  style: new ui.TextStyle({ fontWeight: ui.FontWeight.bold }),
                  className: "x63da hc",
                  key: "9",
                }),
              ],
              className: "x7e9 hc",
              key: "0",
            }),
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                InvoiceView({
                  request: this.request,
                  className: "xaee hc h",
                  key: "0",
                }),
              ],
              className: "x053 hc",
              key: "1",
            }),
          ],
          className: "x755 hc h",
          key: "0",
        }),
      ],
      className: ui.join(
        this.props.className,
        "ReservationView xbce hc vc h v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public dispose(): void {
    this.requestDisposable?.dispose();

    super.dispose();
  }
}
export default function ReservationView(props: ReservationViewProps) {
  return React.createElement(_ReservationViewState, {
    ..._ReservationViewState.defaultProps,
    ...props,
  });
}
