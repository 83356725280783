import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import D3EDate from "../classes/D3EDate";
import TextView from "./TextView";

type _DateCellOnTapDate = (date: D3EDate) => void;

type _Container1OnTap = (d3eState: DateCellRefs) => void;

export interface DateCellProps extends BaseUIProps {
  key?: string;
  date: D3EDate;
  selected: boolean;
  month: number;
  selectedColor?: ui.Color;
  hoverColor?: ui.Color;
  selectedTextColor?: ui.Color;
  hoverTextColor?: ui.Color;
  disable?: boolean;
  onTapDate?: _DateCellOnTapDate;
}
/// To store state data for DateCell
class DateCellRefs {
  public container1: Container1State = new Container1State();
}

interface Container1WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: DateCellRefs;
  _onTapDateHandler?: _Container1OnTap;
  date: D3EDate;
  disable: boolean;
  hoverColor: ui.Color;
  hoverTextColor: ui.Color;
  month: number;
  selected: boolean;
  selectedColor: ui.Color;
  selectedTextColor: ui.Color;
}

class Container1State extends ObjectObservable {
  private _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _Container1WithState extends ObservableComponent<Container1WithStateProps> {
  public constructor(props: Container1WithStateProps) {
    super(props);

    this.initState();
  }
  public get container1(): Container1State {
    return this.props.d3eState.container1;
  }
  public get date(): D3EDate {
    return this.props.date;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public get hoverColor(): ui.Color {
    return this.props.hoverColor;
  }
  public get hoverTextColor(): ui.Color {
    return this.props.hoverTextColor;
  }
  public get month(): number {
    return this.props.month;
  }
  public get selected(): boolean {
    return this.props.selected;
  }
  public get selectedColor(): ui.Color {
    return this.props.selectedColor;
  }
  public get selectedTextColor(): ui.Color {
    return this.props.selectedTextColor;
  }
  public get d3eState(): DateCellRefs {
    return this.props.d3eState;
  }
  public get _onTapDateHandler(): _Container1OnTap {
    return this.props._onTapDateHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("container1", null, this.container1);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "container1",
        "container1.hover",
        "date",
        "disable",
        "hoverColor",
        "hoverTextColor",
        "month",
        "selected",
        "selectedColor",
        "selectedTextColor",
      ],
      this.rebuild
    );
  }
  public container1OnEnter(event): void {
    return this.container1.setHover(true);
  }
  public container1OnExit(event): void {
    return this.container1.setHover(false);
  }
  public dispose(): void {
    this.container1.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    return ui.Container({
      alignment: ui.Alignment.center,
      states: ui.joinStates(
        {
          "data-c1": this.container1.hover && this.hoverColor === null,
          "data-c4": this.selected && this.selectedColor === null,
          "data-c5": this.selected,
        },
        {}
      ),
      foregroundDecoration: this.disable
        ? new ui.BoxDecoration({ color: new ui.Color(0x61dddddd) })
        : null,
      decoration:
        this.container1.hover && this.hoverColor !== null
          ? new ui.BoxDecoration({
              color: this.hoverColor,
              borderRadius: ui.BorderRadius.circular(5.0),
            })
          : this.selected && this.selectedColor !== null
          ? new ui.BoxDecoration({
              borderRadius: ui.BorderRadius.circular(5.0),
              color: this.selectedColor,
            })
          : new ui.BoxDecoration({}),
      child:
        this.selected && this.selectedTextColor === null
          ? TextView({
              data: this.date.day.toString(),
              style: new ui.TextStyle({
                fontSize: 13,
                color: new ui.Color(0xffffffff),
              }),
              className: "x0a hc vc",
            })
          : this.selected && this.selectedTextColor !== null
          ? TextView({
              data: this.date.day.toString(),
              style: new ui.TextStyle({
                fontSize: 13,
                color: this.selectedTextColor,
              }),
              className: "x32 hc vc",
            })
          : this.container1.hover && this.hoverTextColor !== null
          ? TextView({
              data: this.date.day.toString(),
              style: new ui.TextStyle({
                fontSize: 13,
                color: this.hoverTextColor,
              }),
              className: "x7b9 hc vc",
            })
          : this.container1.hover && this.hoverTextColor === null
          ? TextView({
              data: this.date.day.toString(),
              style: new ui.TextStyle({
                fontSize: 13,
                color: new ui.Color(0xffffffff),
              }),
              className: "x25f hc vc",
            })
          : this.month !== this.date.month
          ? TextView({
              data: this.date.day.toString(),
              style: new ui.TextStyle({
                fontSize: 13,
                color: new ui.Color(0xffd3d3d3),
              }),
              className: "x40d hc vc",
            })
          : TextView({
              data: this.date.day.toString(),
              style: new ui.TextStyle({ fontSize: 13 }),
              className: "x9ba hc vc",
            }),
      onEnter: (event) => {
        this.container1OnEnter(event);
      },
      onExit: (event) => {
        this.container1OnExit(event);
      },
      onTap: (e) => {
        e.stopPropagation();

        this._onTapDateHandler(this.d3eState);
      },
      className: "xbf8 hc vc",
    });
  }
}
function Container1WithState(props: Container1WithStateProps) {
  return React.createElement(_Container1WithState, props);
}

class _DateCellState extends ObservableComponent<DateCellProps> {
  static defaultProps = {
    date: null,
    selected: false,
    month: 0,
    selectedColor: null,
    hoverColor: null,
    selectedTextColor: null,
    hoverTextColor: null,
    disable: false,
    onTapDate: null,
  };
  d3eState: DateCellRefs = new DateCellRefs();
  public constructor(props: DateCellProps) {
    super(props);

    this.initState();
  }
  public get date(): D3EDate {
    return this.props.date;
  }
  public get selected(): boolean {
    return this.props.selected;
  }
  public get month(): number {
    return this.props.month;
  }
  public get selectedColor(): ui.Color {
    return this.props.selectedColor;
  }
  public get hoverColor(): ui.Color {
    return this.props.hoverColor;
  }
  public get selectedTextColor(): ui.Color {
    return this.props.selectedTextColor;
  }
  public get hoverTextColor(): ui.Color {
    return this.props.hoverTextColor;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "date",
        "disable",
        "hoverColor",
        "hoverTextColor",
        "month",
        "selected",
        "selectedColor",
        "selectedTextColor",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: DateCellProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.date !== this.props.date) {
      this.fire("date", this);
    }

    if (prevProps.selected !== this.props.selected) {
      this.fire("selected", this);
    }

    if (prevProps.month !== this.props.month) {
      this.fire("month", this);
    }

    if (prevProps.selectedColor !== this.props.selectedColor) {
      this.fire("selectedColor", this);
    }

    if (prevProps.hoverColor !== this.props.hoverColor) {
      this.fire("hoverColor", this);
    }

    if (prevProps.selectedTextColor !== this.props.selectedTextColor) {
      this.fire("selectedTextColor", this);
    }

    if (prevProps.hoverTextColor !== this.props.hoverTextColor) {
      this.fire("hoverTextColor", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }
  }
  public render(): ReactNode {
    return ui.Column({
      children: [
        Container1WithState({
          d3eState: this.d3eState,
          _onTapDateHandler: this.onTapDateHandler,
          date: this.date,
          disable: this.disable,
          hoverColor: this.hoverColor,
          hoverTextColor: this.hoverTextColor,
          month: this.month,
          selected: this.selected,
          selectedColor: this.selectedColor,
          selectedTextColor: this.selectedTextColor,
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "DateCell"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onTapDateHandler = (d3eState: DateCellRefs): void => {
    if (!this.disable) {
      this.onTapDate(this.date);
    }
  };
  public get onTapDate(): _DateCellOnTapDate {
    return this.props.onTapDate;
  }
  public get container1() {
    return this.d3eState.container1;
  }
}
export default function DateCell(props: DateCellProps) {
  return React.createElement(_DateCellState, {
    ..._DateCellState.defaultProps,
    ...props,
  });
}
