import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ListWrapper from "../utils/ListWrapper";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

type _GSDRMSDoubleInputFieldOnChanged = (value: number) => void;

export interface GSDRMSDoubleInputFieldProps extends BaseUIProps {
  key?: string;
  name?: string;
  placeHolder?: string;
  value: number;
  isRequired?: boolean;
  decimalCount?: number;
  errors?: Array<string>;
  disable?: boolean;
  width?: number;
  dense?: boolean;
  _errorsHash?: number;
  onChanged?: _GSDRMSDoubleInputFieldOnChanged;
}

class _GSDRMSDoubleInputFieldState extends ObservableComponent<GSDRMSDoubleInputFieldProps> {
  static defaultProps = {
    name: "",
    placeHolder: "",
    value: 0.0,
    isRequired: false,
    decimalCount: 2,
    disable: false,
    width: 400.0,
    dense: false,
    errors: [],
    onChanged: null,
  };
  fieldController: ui.TextEditingController = new ui.TextEditingController();
  focusNode: ui.FocusNode = null;
  active: boolean = false;
  nullValue: number = 0.0;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: GSDRMSDoubleInputFieldProps) {
    super(props);

    this.initState();
  }
  public get name(): string {
    return this.props.name;
  }
  public get placeHolder(): string {
    return this.props.placeHolder;
  }
  public get value(): number {
    return this.props.value;
  }
  public get isRequired(): boolean {
    return this.props.isRequired;
  }
  public get decimalCount(): number {
    return this.props.decimalCount;
  }
  public get errors(): Array<string> {
    return this.props.errors;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public get width(): number {
    return this.props.width;
  }
  public get dense(): boolean {
    return this.props.dense;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.subscribeToList(this.errors, "errors");

    this.on(
      [
        "dense",
        "disable",
        "errors",
        "focusNode",
        "isRequired",
        "name",
        "nullValue",
        "placeHolder",
        "value",
        "width",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: GSDRMSDoubleInputFieldProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.name !== this.props.name) {
      this.fire("name", this);
    }

    if (prevProps.placeHolder !== this.props.placeHolder) {
      this.fire("placeHolder", this);
    }

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.isRequired !== this.props.isRequired) {
      this.fire("isRequired", this);
    }

    if (prevProps.decimalCount !== this.props.decimalCount) {
      this.fire("decimalCount", this);
    }

    if (prevProps.errors !== this.props.errors) {
      this.fire("errors", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }

    if (prevProps.width !== this.props.width) {
      this.fire("width", this);
    }

    if (prevProps.dense !== this.props.dense) {
      this.fire("dense", this);
    }
  }
  public setFocusNode(val: ui.FocusNode): void {
    let isValChanged: boolean = this.focusNode !== val;

    if (!isValChanged) {
      return;
    }

    this.focusNode = val;

    this.fire("focusNode", this);
  }
  public setActive(val: boolean): void {
    let isValChanged: boolean = this.active !== val;

    if (!isValChanged) {
      return;
    }

    this.active = val;

    this.fire("active", this);
  }
  public setNullValue(val: number): void {
    let isValChanged: boolean = this.nullValue !== val;

    if (!isValChanged) {
      return;
    }

    this.nullValue = val;

    this.fire("nullValue", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          children: [
            this.name !== null && this.name.isNotEmpty
              ? TextView({
                  data: this.name,
                  style: new ui.TextStyle({
                    fontFamily: cStyle.tTextViewNameTextFontFamilyOn,
                    color: cStyle.tTextViewNameTextColorOn,
                    fontSize: cStyle.tTextViewNameTextFontSizeOn,
                    fontWeight: cStyle.tTextViewNameTextFontWeightOn,
                  }),
                  className: "x1d3",
                })
              : [],
            this.isRequired
              ? TextView({
                  data: "*",
                  style: new ui.TextStyle({
                    fontFamily: cStyle.tTextViewErrorLabelFontFamilyOn,
                    color: cStyle.tTextViewErrorLabelColorOn,
                    fontSize: cStyle.tTextViewErrorLabelFontSizeOn,
                  }),
                })
              : [],
          ],
          className: "x3b8 hc h",
          key: "0",
        }),
        ui.Container({
          width: this.width,
          child: ui.InputField({
            value: this.value !== this.nullValue ? this.value.toString() : "",
            inActiveColor: new ui.Color(0xffdcdcdc),
            activeColor: new ui.Color(0xff6c10ec),
            focusNode: this.focusNode,
            placeHolder: this.placeHolder,
            inputFormatter: new RegExp("^[\\d+\\-\\.](\\d+)?\\.?\\d{0,9}"),
            disable: this.disable,
            backgroundColor: cStyle.c2,
            style: new ui.TextStyle({
              fontSize: 14,
              fontWeight: ui.FontWeight.w500,
              color: new ui.Color(0xff000000),
              decorationStyle: ui.TextDecorationStyle.solid,
            }),
            cornerRadius: 3,
            dense: this.dense,
            controller: this.fieldController,
            onChanged: (text) => {
              this.onChangeText(text);
            },
            onFocusChange: (val) => {},
          }),
          key: "1",
          className: "x1e2 hc vc",
        }),
        this.errors.isNotEmpty
          ? ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                this.errors.expand((item) => [
                  TextView({
                    data: item,
                    style: new ui.TextStyle({
                      fontFamily: cStyle.tTextViewErrorLabelFontFamilyOn,
                      color: cStyle.tTextViewErrorLabelColorOn,
                      fontSize: cStyle.tTextViewErrorLabelFontSizeOn,
                    }),
                    className: "hc",
                    key: item?.toString(),
                  }),
                ]),
              ],
              className: "hc",
            })
          : [],
      ],
      className: ui.join(
        this.props.className,
        "GSDRMSDoubleInputField x84d hc h"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setNullValue(null);

    this.setFocusNode(new ui.FocusNode());

    this.focusNode.addListener(this.onChangeFocus);

    this.fieldController.text =
      this.value !== this.nullValue
        ? this.value.toStringAsFixed(this.decimalCount)
        : "";
  };
  public onChangeFocus = (): void => {
    this.setActive(this.focusNode.hasFocus);
  };
  public onChangeText = (text: string): void => {
    if (text.isEmpty) {
      this.onChanged(0.0);

      return;
    }

    if (this.onChanged !== null) {
      this.onChanged(Number.parseFloat(text));
    }
  };
  public get onChanged(): _GSDRMSDoubleInputFieldOnChanged {
    return this.props.onChanged;
  }
}
export default function GSDRMSDoubleInputField(
  props: GSDRMSDoubleInputFieldProps
) {
  return React.createElement(
    _GSDRMSDoubleInputFieldState,
    { ..._GSDRMSDoubleInputFieldState.defaultProps, ...props },
    ListWrapper.fromInput<string>(props.errors, "errors")
  );
}
