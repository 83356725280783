export default class CalendarViewType {
  public static readonly Month = new CalendarViewType("Month", "Month", 0);
  public static readonly Week = new CalendarViewType("Week", "Week", 1);
  public static readonly Year = new CalendarViewType("Year", "Year", 2);
  public static readonly Day = new CalendarViewType("Day", "Day", 3);
  private _ident: string;
  private _name: string;
  private _index: number;
  public constructor(_ident: string, _name: string, _index: number) {
    this._ident = _ident;

    this._name = _name;

    this._index = _index;
  }
  public static get values(): CalendarViewType[] {
    return [
      CalendarViewType.Month,
      CalendarViewType.Week,
      CalendarViewType.Year,
      CalendarViewType.Day,
    ];
  }
  public get ident(): string {
    return this._ident;
  }
  public get name(): string {
    return this._name;
  }
  public get index(): number {
    return this._index;
  }
  public toString(): string {
    return this._name;
  }
}
