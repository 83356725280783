import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import TextView from "./TextView";
import Time from "../classes/Time";
import DateFormat from "../classes/DateFormat";

export interface TimeViewProps extends BaseUIProps {
  key?: string;
  value?: Time;
  format?: string;
}

class _TimeViewState extends ObservableComponent<TimeViewProps> {
  static defaultProps = { value: null, format: "HH:mm:ss" };
  formatter: DateFormat = null;
  displayValue: string = "";
  public constructor(props: TimeViewProps) {
    super(props);

    this.initState();
  }
  public get value(): Time {
    return this.props.value;
  }
  public get format(): string {
    return this.props.format;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.on(["formatter", "value"], this.computeDisplayValue);

    this.computeDisplayValue();

    this.on(["displayValue"], this.rebuild);
  }
  public componentDidUpdate(prevProps: TimeViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.format !== this.props.format) {
      this.fire("format", this);
    }
  }
  public setFormatter(val: DateFormat): void {
    let isValChanged: boolean = this.formatter !== val;

    if (!isValChanged) {
      return;
    }

    this.formatter = val;

    this.fire("formatter", this);
  }
  public setDisplayValue(val: string): void {
    let isValChanged: boolean = this.displayValue !== val;

    if (!isValChanged) {
      return;
    }

    this.displayValue = val;

    this.fire("displayValue", this);
  }
  public computeDisplayValue = (): void => {
    try {
      this.setDisplayValue(
        this.formatter === null
          ? ""
          : this.formatter.format(this.value.toDateTime())
      );
    } catch (exception) {
      console.log(
        " exception in computeDisplayValue : " + exception.toString()
      );

      this.setDisplayValue("");
    }
  };
  public render(): ReactNode {
    return TextView({
      data: this.displayValue,
      className: ui.join(this.props.className, "TimeView hc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setFormatter(new DateFormat(this.format));
  };
}
export default function TimeView(props: TimeViewProps) {
  return React.createElement(_TimeViewState, {
    ..._TimeViewState.defaultProps,
    ...props,
  });
}
