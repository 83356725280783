import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import SignUpRequest from "../models/SignUpRequest";
import Loader from "./Loader";
import ResultStatus from "../classes/ResultStatus";
import LoginResult from "../classes/LoginResult";
import TextView from "./TextView";
import PageNavigator from "../classes/PageNavigator";
import LabelwithInputField from "./LabelwithInputField";
import Renter from "../models/Renter";
import Result from "../classes/Result";
import Query from "../classes/Query";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _SignUpButtonOnPressed = (d3eState: RentelSignupPageRefs) => void;

type _LoginButtonOnPressed = (d3eState: RentelSignupPageRefs) => void;

type _InputField3OnChanged = (
  text: string,
  d3eState: RentelSignupPageRefs
) => void;

type _InputFieldOnChanged = (
  text: string,
  d3eState: RentelSignupPageRefs
) => void;

type _InputField2OnChanged = (
  text: string,
  d3eState: RentelSignupPageRefs
) => void;

export interface RentelSignupPageProps extends BaseUIProps {
  key?: string;
  signUpRequest: SignUpRequest;
}
/// To store state data for RentelSignupPage
class RentelSignupPageRefs {
  public loginButton: LoginButtonState = new LoginButtonState();
  public signUpButton: SignUpButtonState = new SignUpButtonState();
}

interface LoginButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentelSignupPageRefs;
  _onPressedButtonHandler?: _LoginButtonOnPressed;
}

class LoginButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _LoginButtonWithState extends ObservableComponent<LoginButtonWithStateProps> {
  loginButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LoginButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get loginButton(): LoginButtonState {
    return this.props.d3eState.loginButton;
  }
  public get d3eState(): RentelSignupPageRefs {
    return this.props.d3eState;
  }
  public get _onPressedButtonHandler(): _LoginButtonOnPressed {
    return this.props._onPressedButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("loginButton", null, this.loginButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["loginButton"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.loginButton.disable,
      onPressed: () => {
        this._onPressedButtonHandler(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({
        data: "Login here.",
        style: new ui.TextStyle({
          color: cStyle.c20,
          decoration: ui.TextDecoration.underline,
          decorationColor: cStyle.c20,
          fontSize: 15,
          fontWeight: ui.FontWeight.w600,
        }),
        className: "xcc4",
      }),
    });
  }
}
function LoginButtonWithState(props: LoginButtonWithStateProps) {
  return React.createElement(_LoginButtonWithState, props);
}

interface SignUpButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentelSignupPageRefs;
  _onPressedButton2Handler?: _SignUpButtonOnPressed;
}

class SignUpButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _SignUpButtonWithState extends ObservableComponent<SignUpButtonWithStateProps> {
  signUpButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SignUpButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get signUpButton(): SignUpButtonState {
    return this.props.d3eState.signUpButton;
  }
  public get d3eState(): RentelSignupPageRefs {
    return this.props.d3eState;
  }
  public get _onPressedButton2Handler(): _SignUpButtonOnPressed {
    return this.props._onPressedButton2Handler;
  }
  public initState() {
    super.initState();

    this.updateObservable("signUpButton", null, this.signUpButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["signUpButton", "signUpButton.", "signUpButton.hover"],
      this.rebuild
    );
  }
  public signUpButtonOnEnter(event): void {
    return this.signUpButton.setHover(true);
  }
  public signUpButtonOnExit(event): void {
    return this.signUpButton.setHover(false);
  }
  public dispose(): void {
    this.signUpButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      expand: true,
      margin: ui.EdgeInsets.fromLTRB(0.0, 20.0, 0.0, 10.0, new Map()),
      child: Button({
        padding: this.signUpButton.hover
          ? cStyle.tButtonPrimaryNewPaddingOnHover
          : cStyle.tButtonPrimaryNewPaddingOn,
        decoration: this.signUpButton.hover
          ? cStyle.tButtonPrimaryNewDecorationOnHover
          : cStyle.tButtonPrimaryNewDecorationOn,
        disable: this.signUpButton.disable,
        onPressed: () => {
          this._onPressedButton2Handler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Create Account ", className: "hc" }),
        onEnter: (event) => {
          this.signUpButtonOnEnter(event);
        },
        onExit: (event) => {
          this.signUpButtonOnExit(event);
        },
      }),
      className: "xe13 hc h",
    });
  }
}
function SignUpButtonWithState(props: SignUpButtonWithStateProps) {
  return React.createElement(_SignUpButtonWithState, props);
}

class _RentelSignupPageState extends ObservableComponent<RentelSignupPageProps> {
  static defaultProps = { signUpRequest: null };
  d3eState: RentelSignupPageRefs = new RentelSignupPageRefs();
  emailInfo: boolean = false;
  fullNameInfo: boolean = false;
  passwordInfo: boolean = false;
  errorMessages: string = "";
  showError: boolean = false;
  showLoading: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RentelSignupPageProps) {
    super(props);

    this.initState();
  }
  public get signUpRequest(): SignUpRequest {
    return this.props.signUpRequest;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("signUpRequest", this.props.signUpRequest);

    this.on(
      [
        "emailInfo",
        "fullNameInfo",
        "passwordInfo",
        "showLoading",
        "signUpRequest",
        "signUpRequest.email",
        "signUpRequest.fullName",
        "signUpRequest.password",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RentelSignupPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.signUpRequest !== this.props.signUpRequest) {
      this.updateObservable(
        "signUpRequest",
        prevProps.signUpRequest,
        this.props.signUpRequest
      );

      this.fire("signUpRequest", this);
    }
  }
  public setEmailInfo(val: boolean): void {
    let isValChanged: boolean = this.emailInfo !== val;

    if (!isValChanged) {
      return;
    }

    this.emailInfo = val;

    this.fire("emailInfo", this);
  }
  public setFullNameInfo(val: boolean): void {
    let isValChanged: boolean = this.fullNameInfo !== val;

    if (!isValChanged) {
      return;
    }

    this.fullNameInfo = val;

    this.fire("fullNameInfo", this);
  }
  public setPasswordInfo(val: boolean): void {
    let isValChanged: boolean = this.passwordInfo !== val;

    if (!isValChanged) {
      return;
    }

    this.passwordInfo = val;

    this.fire("passwordInfo", this);
  }
  public setErrorMessages(val: string): void {
    let isValChanged: boolean = this.errorMessages !== val;

    if (!isValChanged) {
      return;
    }

    this.errorMessages = val;

    this.fire("errorMessages", this);
  }
  public setShowError(val: boolean): void {
    let isValChanged: boolean = this.showError !== val;

    if (!isValChanged) {
      return;
    }

    this.showError = val;

    this.fire("showError", this);
  }
  public setShowLoading(val: boolean): void {
    let isValChanged: boolean = this.showLoading !== val;

    if (!isValChanged) {
      return;
    }

    this.showLoading = val;

    this.fire("showLoading", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return this.showLoading
      ? ui.Column({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                Loader({ key: "0" }),
                TextView({
                  data: "Loading App......",
                  className: "x383",
                  key: "1",
                }),
              ],
              className: "xd24 hc h",
              key: "0",
            }),
          ],
          className: ui.join(this.props.className, "RentelSignupPage hc vc"),
          ...copyBaseUIProps(this.props),
        })
      : ui.Row({
          children: [
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.AssetImage({
                  path: "images/Logo-new.png",
                  width: 183,
                  height: 55,
                  className: "x926 hc vc",
                  key: "0",
                }),
                TextView({
                  data: "Welcome to FeciliRes",
                  style: new ui.TextStyle({
                    color: cStyle.c2,
                    fontSize: 38,
                    fontWeight: cStyle.tTextViewHeadlineOneFontWeightOn,
                  }),
                  className: "x3a2a hc",
                  key: "1",
                }),
                TextView({
                  data: "Lorem ipsum is placeholder text commonly used in the\ngraphic, print, and publishing industries for previewing layout\nand visual mockups.",
                  softWrap: true,
                  maxLines: 5,
                  textAlign: cStyle.tTextViewLabelTextAlignOn,
                  style: new ui.TextStyle({
                    color: cStyle.c2,
                    fontSize: 18,
                    height: 1.8,
                  }),
                  className: "x8087 hc",
                  key: "2",
                }),
              ],
              className: "xbaa7 hc vc v",
              key: "0",
            }),
            ui.Column({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.center,
                  children: [
                    ui.AssetImage({
                      path: "images/Logo.png",
                      width: 235,
                      height: 55,
                      fit: ui.BoxFit.cover,
                      className: "x22c hc vc",
                      key: "0",
                    }),
                  ],
                  className: "x65e hc h",
                  key: "0",
                }),
                ui.Column({
                  mainAxisAlignment: ui.MainAxisAlignment.center,
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    TextView({
                      data: "Sign Up",
                      style: new ui.TextStyle({
                        fontSize: cStyle.tTextViewHeadlineOneFontSizeOn,
                        fontWeight: cStyle.tTextViewHeadlineOneFontWeightOn,
                      }),
                      className: "hc",
                      key: "0",
                    }),
                    TextView({
                      data: "We are glad to see you again!",
                      softWrap: true,
                      style: new ui.TextStyle({
                        fontSize: 15,
                        fontFamily: "Open Sans",
                      }),
                      className: "xef6 hc",
                      key: "1",
                    }),
                    LabelwithInputField({
                      placeHolder: "Enter Full Name",
                      value: this.signUpRequest.fullName,
                      errors: this.fullNameInfo
                        ? ["Please enter your full name"]
                        : [],
                      name: "Full Name",
                      onChanged: (text) => {
                        this.inputField3onChanged(text, this.d3eState);
                      },
                      className: "x4a9 hc h",
                      key: "2",
                    }),
                    ui.Container({
                      margin: ui.EdgeInsets.symmetric({
                        horizontal: 0.0,
                        vertical: 8.0,
                        transitions: new Map(),
                      }),
                      child: LabelwithInputField({
                        placeHolder: "Email address",
                        value: this.signUpRequest.email,
                        errors: this.emailInfo
                          ? ["Please enter your email"]
                          : [],
                        name: "Email",
                        onChanged: (text) => {
                          this.inputFieldonChanged(text, this.d3eState);
                        },
                      }),
                      key: "3",
                      className: "x8c1 hc h",
                    }),
                    LabelwithInputField({
                      placeHolder: "Enter Password",
                      value: this.signUpRequest.password,
                      obscureText: true,
                      errors: this.passwordInfo
                        ? ["Please enter your password"]
                        : [],
                      name: "Password",
                      onChanged: (text) => {
                        this.inputField2onChanged(text, this.d3eState);
                      },
                      className: "xaf3 hc h",
                      key: "4",
                    }),
                    ui.Container({ className: "x4e9 hc vc", key: "5" }),
                    ui.Row({
                      mainAxisAlignment: ui.MainAxisAlignment.center,
                      children: [
                        SignUpButtonWithState({
                          d3eState: this.d3eState,
                          _onPressedButton2Handler:
                            this.onPressedButton2Handler,
                          key: "0",
                        }),
                      ],
                      className: "xa18 hc h",
                      key: "6",
                    }),
                    ui.Row({
                      mainAxisAlignment: ui.MainAxisAlignment.center,
                      children: [
                        TextView({
                          data: "Already have an account?",
                          style: new ui.TextStyle({ fontSize: 15 }),
                          className: "x303",
                          key: "0",
                        }),
                        LoginButtonWithState({
                          d3eState: this.d3eState,
                          _onPressedButtonHandler: this.onPressedButtonHandler,
                          key: "1",
                        }),
                      ],
                      className: "xaab hc h",
                      key: "7",
                    }),
                  ],
                  className: "x0d2 hc h",
                  key: "1",
                }),
              ],
              className: "x422 hc vc h v",
              key: "1",
            }),
          ],
          className: ui.join(this.props.className, "RentelSignupPage hc vc"),
          ...copyBaseUIProps(this.props),
        });
  }
  public onPressedButton2Handler = async (
    d3eState: RentelSignupPageRefs
  ): Promise<void> => {
    let foundError: boolean = false;

    if (this.signUpRequest.email.isEmpty) {
      // POS: 250.00, Y = 30.00

      this.setEmailInfo(true);

      foundError = true;
    }

    // POS: 350.00, Y = 80.00

    if (this.signUpRequest.password.isEmpty) {
      // POS: 450.00, Y = 70.00

      this.setPasswordInfo(true);

      foundError = true;
    }

    // POS: 550.00, Y = 80.00

    if (this.signUpRequest.fullName.isEmpty) {
      // POS: 650.00, Y = 70.00

      this.setFullNameInfo(true);

      foundError = true;
    }

    if (foundError) {
      return;
    }

    // POS: 550.00, Y = 80.00

    this.setShowLoading(true);

    let result: Result<SignUpRequest> = await this.signUpRequest.save();

    // POS: 800.00, Y = 80.00

    if (result.status === ResultStatus.Success) {
      this.doAuthenticate(
        this.signUpRequest.email,
        this.signUpRequest.password
      );
    } else {
      this.setErrorMessages(result.errors.join(", "));

      this.setShowError(true);
    }

    this.setShowLoading(false);
  };
  public doAuthenticate = async (
    email: string,
    password: string
  ): Promise<void> => {
    let result: LoginResult =
      await Query.get().loginRenterUserWithEmailAndPassword(
        UsageConstants.QUERY_LOGINRENTERUSERWITHEMAILANDPASSWORD_RENTELSIGNUPPAGE_EVENTHANDLERS_DOAUTHENTICATE_BLOCK,
        { email: email, password: password }
      );

    if (result.success) {
      this.navigator.pushRentalHomePage({
        renter: result.userObject as Renter,
      });
    }
  };
  public onPressedButtonHandler = (d3eState: RentelSignupPageRefs): void => {
    this.navigator.pushRentalLoginPage();
  };
  public inputField3onChanged = (
    val: string,
    d3eState: RentelSignupPageRefs
  ): void => {
    this.signUpRequest.setFullName(val);
  };
  public inputFieldonChanged = (
    val: string,
    d3eState: RentelSignupPageRefs
  ): void => {
    this.signUpRequest.setEmail(val);
  };
  public inputField2onChanged = (
    val: string,
    d3eState: RentelSignupPageRefs
  ): void => {
    this.signUpRequest.setPassword(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get loginButton() {
    return this.d3eState.loginButton;
  }
  public get signUpButton() {
    return this.d3eState.signUpButton;
  }
}
export default function RentelSignupPage(props: RentelSignupPageProps) {
  return React.createElement(_RentelSignupPageState, {
    ..._RentelSignupPageState.defaultProps,
    ...props,
  });
}
