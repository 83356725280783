import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import { BuildContext } from "../classes/BuildContext";

export interface HorizontalLineProps extends BaseUIProps {
  key?: string;
  margin?: ui.EdgeInsets;
}

class _HorizontalLineState extends ObservableComponent<HorizontalLineProps> {
  static defaultProps = { margin: null };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: HorizontalLineProps) {
    super(props);

    this.initState();
  }
  public get margin(): ui.EdgeInsets {
    return this.props.margin;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["margin"], this.rebuild);
  }
  public componentDidUpdate(prevProps: HorizontalLineProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.margin !== this.props.margin) {
      this.fire("margin", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Row({
      children: [
        ui.Container({
          alignment: ui.Alignment.center,
          margin: this.margin,
          states: ui.joinStates({ "data-c0": this.margin === null }, {}),
          className: "x4d2 hc vc h",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "HorizontalLine x1cf hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function HorizontalLine(props: HorizontalLineProps) {
  return React.createElement(_HorizontalLineState, {
    ..._HorizontalLineState.defaultProps,
    ...props,
  });
}
