import React, { ReactNode, ReactNodeArray } from "react";
import { CrossAxisAlignment } from "../classes/CrossAxisAlignment";
import CSSHelper from "../classes/CSSHelper";
import { MainAxisAlignment } from "../classes/MainAxisAlignment";
import { MainAxisSize } from "../classes/MainAxisSize";
import { TextBaseline } from "../classes/TextBaseline";
import { TextDirection } from "../classes/TextDirection";
import { VerticalDirection } from "../classes/VerticalDirection";
import BaseUIProps, { processBaseUIProps } from "./BaseUIProps";

interface RowProps extends BaseUIProps {
  key?: string;
  mainAxisAlignment?: MainAxisAlignment;
  mainAxisSize?: MainAxisSize;
  crossAxisAlignment?: CrossAxisAlignment;
  textDirection?: TextDirection;
  verticalDirection?: VerticalDirection; // TODO
  textBaseline?: TextBaseline; // TODO
  children?: ReactNode[];
}

class _Row extends React.Component<RowProps, {}> {
  static defaultProps = {
    mainAxisAlignment: MainAxisAlignment.start,
    mainAxisSize: MainAxisSize.max,
    crossAxisAlignment: CrossAxisAlignment.center,
    verticalDirection: VerticalDirection.down,
  };

  constructor(props: RowProps) {
    super(props);
    this.populateStyle();
  }

  populateStyle() {
    //BaseComponentUtil.applyBaseComponentProps(this.rowStyle, this.props);
  }

  render() {
    let classNames = [];
    classNames.addAll(
      CSSHelper.handleMainAxisAlignment(this.props.mainAxisAlignment)
    );
    classNames.addAll(
      CSSHelper.handleCrossAxisAlignment(this.props.crossAxisAlignment)
    );
    if (this.props.mainAxisSize == MainAxisSize.max) {
      classNames.push("max");
    }

    if (this.props.textDirection == TextDirection.rtl) {
      classNames.push("rtl");
    }
    return React.createElement(
      "ui-row",
      {
        class:
          classNames.join(" ") +
          (this.props.className ? " " + this.props.className : ""),
        ...processBaseUIProps(this.props),
      },
      this.props.children
    );
  }
}

export default function Row(props: RowProps) {
  return React.createElement(_Row, {
    ..._Row.defaultProps,
    ...props,
  });
}
