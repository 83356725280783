import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import TextView from "./TextView";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

export interface FeaturesCardViewProps extends BaseUIProps {
  key?: string;
  icon: ui.IconData;
  headerText: string;
  subText?: string;
}

class _FeaturesCardViewState extends ObservableComponent<FeaturesCardViewProps> {
  static defaultProps = { icon: null, headerText: "", subText: "" };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: FeaturesCardViewProps) {
    super(props);

    this.initState();
  }
  public get icon(): ui.IconData {
    return this.props.icon;
  }
  public get headerText(): string {
    return this.props.headerText;
  }
  public get subText(): string {
    return this.props.subText;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["headerText", "icon", "subText"], this.rebuild);
  }
  public componentDidUpdate(prevProps: FeaturesCardViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.icon !== this.props.icon) {
      this.fire("icon", this);
    }

    if (prevProps.headerText !== this.props.headerText) {
      this.fire("headerText", this);
    }

    if (prevProps.subText !== this.props.subText) {
      this.fire("subText", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      mainAxisAlignment: ui.MainAxisAlignment.center,
      crossAxisAlignment: ui.CrossAxisAlignment.center,
      children: [
        ui.Column({
          children: [
            IconView({
              color: cStyle.c20,
              icon: this.icon,
              size: 45,
              className: "x399",
              key: "0",
            }),
            TextView({
              data: this.headerText,
              softWrap: true,
              textAlign: ui.TextAlign.center,
              style: new ui.TextStyle({
                fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
              }),
              className: "xeb",
              key: "1",
            }),
            TextView({
              data: this.subText,
              softWrap: true,
              textAlign: ui.TextAlign.center,
              key: "2",
            }),
          ],
          className: "x58",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "FeaturesCardView"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function FeaturesCardView(props: FeaturesCardViewProps) {
  return React.createElement(_FeaturesCardViewState, {
    ..._FeaturesCardViewState.defaultProps,
    ...props,
  });
}
