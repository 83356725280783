import { SystemMouseCursor } from "./SystemMouseCursor";

export class SystemMouseCursors {
  static click: SystemMouseCursor;
  static basic: SystemMouseCursor;
  static none: SystemMouseCursor;
  static text: SystemMouseCursor;
  static forbidden: SystemMouseCursor;
  static grab: SystemMouseCursor;
  static cell: SystemMouseCursor;
  static allScroll: SystemMouseCursor;
  static contextMenu: SystemMouseCursor;
  static copy: SystemMouseCursor;
  static help: SystemMouseCursor;
  static move: SystemMouseCursor;
  static noDrop: SystemMouseCursor;
  static precise: SystemMouseCursor;
  static progress: SystemMouseCursor;
  static resizeColumn: SystemMouseCursor;
  static resizeDown: SystemMouseCursor;
  static resizeDownLeft: SystemMouseCursor;
  static resizeDownRight: SystemMouseCursor;
  static resizeLeft: SystemMouseCursor;
  static resizeRow: SystemMouseCursor;
  static resizeLeftRight: SystemMouseCursor;
  static resizeRight: SystemMouseCursor;
  static resizeUp: SystemMouseCursor;
  static resizeUpDown: SystemMouseCursor;
  static resizeUpLeft: SystemMouseCursor;
  static resizeUpLeftDownRight: SystemMouseCursor;
  static resizeUpRight: SystemMouseCursor;
  static verticalText: SystemMouseCursor;
  static wait: SystemMouseCursor;
  static zoomIn: SystemMouseCursor;
  static zoomOut: SystemMouseCursor;
}
