import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";
import SchoolType from "./SchoolType";

export default class School extends DBObject {
  private static readonly _ADDRESS: number = 0;
  private static readonly _CITY: number = 1;
  private static readonly _LATITUDE: number = 2;
  private static readonly _LONGITUDE: number = 3;
  private static readonly _NAME: number = 4;
  private static readonly _PINCODE: number = 5;
  private static readonly _SCHOOLTYPE: number = 6;
  private static readonly _STATE: number = 7;
  public id: number = 0;
  public otherMaster: DBObject;
  private _name: string = "";
  private _schoolType: SchoolType = null;
  private _address: string = "";
  private _latitude: string = "";
  private _longitude: string = "";
  private _city: string = "";
  private _pincode: string = "";
  private _state: string = "";
  public constructor(
    d3eParams?: Partial<{
      address: string;
      city: string;
      latitude: string;
      longitude: string;
      name: string;
      pincode: string;
      schoolType: SchoolType;
      state: string;
    }>
  ) {
    super();

    this.setAddress(d3eParams?.address ?? "");

    this.setCity(d3eParams?.city ?? "");

    this.setLatitude(d3eParams?.latitude ?? "");

    this.setLongitude(d3eParams?.longitude ?? "");

    this.setName(d3eParams?.name ?? "");

    this.setPincode(d3eParams?.pincode ?? "");

    this.setSchoolType(d3eParams?.schoolType ?? null);

    this.setState(d3eParams?.state ?? "");
  }
  public get d3eType(): string {
    return "School";
  }
  public toString(): string {
    return this.name;
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get name(): string {
    return this._name;
  }
  public setName(val: string): void {
    let isValChanged: boolean = this._name !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(School._NAME, this._name);

    this._name = val;

    this.fire("name", this);
  }
  public get schoolType(): SchoolType {
    return this._schoolType;
  }
  public setSchoolType(val: SchoolType): void {
    let isValChanged: boolean = this._schoolType !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(School._SCHOOLTYPE, this._schoolType);

    this.updateObservable("schoolType", this._schoolType, val);

    this._schoolType = val;

    this.fire("schoolType", this);
  }
  public get address(): string {
    return this._address;
  }
  public setAddress(val: string): void {
    let isValChanged: boolean = this._address !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(School._ADDRESS, this._address);

    this._address = val;

    this.fire("address", this);
  }
  public get latitude(): string {
    return this._latitude;
  }
  public setLatitude(val: string): void {
    let isValChanged: boolean = this._latitude !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(School._LATITUDE, this._latitude);

    this._latitude = val;

    this.fire("latitude", this);
  }
  public get longitude(): string {
    return this._longitude;
  }
  public setLongitude(val: string): void {
    let isValChanged: boolean = this._longitude !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(School._LONGITUDE, this._longitude);

    this._longitude = val;

    this.fire("longitude", this);
  }
  public get city(): string {
    return this._city;
  }
  public setCity(val: string): void {
    let isValChanged: boolean = this._city !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(School._CITY, this._city);

    this._city = val;

    this.fire("city", this);
  }
  public get pincode(): string {
    return this._pincode;
  }
  public setPincode(val: string): void {
    let isValChanged: boolean = this._pincode !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(School._PINCODE, this._pincode);

    this._pincode = val;

    this.fire("pincode", this);
  }
  public get state(): string {
    return this._state;
  }
  public setState(val: string): void {
    let isValChanged: boolean = this._state !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(School._STATE, this._state);

    this._state = val;

    this.fire("state", this);
  }
  public get(field: number): any {
    switch (field) {
      case School._NAME: {
        return this._name;
      }

      case School._SCHOOLTYPE: {
        return this._schoolType;
      }

      case School._ADDRESS: {
        return this._address;
      }

      case School._LATITUDE: {
        return this._latitude;
      }

      case School._LONGITUDE: {
        return this._longitude;
      }

      case School._CITY: {
        return this._city;
      }

      case School._PINCODE: {
        return this._pincode;
      }

      case School._STATE: {
        return this._state;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): School {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: School = dbObj as School;

    obj.id = this.id;

    obj.setName(this._name);

    obj.setSchoolType(this._schoolType);

    obj.setAddress(this._address);

    obj.setLatitude(this._latitude);

    obj.setLongitude(this._longitude);

    obj.setCity(this._city);

    obj.setPincode(this._pincode);

    obj.setState(this._state);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case School._NAME: {
        this.setName(value as string);
        break;
      }

      case School._SCHOOLTYPE: {
        this.setSchoolType(value as SchoolType);
        break;
      }

      case School._ADDRESS: {
        this.setAddress(value as string);
        break;
      }

      case School._LATITUDE: {
        this.setLatitude(value as string);
        break;
      }

      case School._LONGITUDE: {
        this.setLongitude(value as string);
        break;
      }

      case School._CITY: {
        this.setCity(value as string);
        break;
      }

      case School._PINCODE: {
        this.setPincode(value as string);
        break;
      }

      case School._STATE: {
        this.setState(value as string);
        break;
      }
    }
  }
}
