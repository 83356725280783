import Equipment from "./Equipment";
import D3EDate from "../classes/D3EDate";
import FacilityUsageCategory from "./FacilityUsageCategory";
import Time from "../classes/Time";
import Service from "./Service";
import RequestStatus from "../classes/RequestStatus";
import Renter from "./Renter";
import CloneContext from "../utils/CloneContext";
import ListWrapper from "../utils/ListWrapper";
import School from "./School";
import DateTime from "../core/DateTime";
import DBObject from "../utils/DBObject";
import CollectionUtils from "../utils/CollectionUtils";
import Facility from "./Facility";

export default class RentalRequest extends DBObject {
  private static readonly _CATEGORY: number = 0;
  private static readonly _CREATEDBY: number = 1;
  private static readonly _CREATEDDATE: number = 2;
  private static readonly _ENDTIME: number = 3;
  private static readonly _EQUIPMENTS: number = 4;
  private static readonly _EVENTDATE: number = 5;
  private static readonly _EVENTNAME: number = 6;
  private static readonly _FACILITY: number = 7;
  private static readonly _PAID: number = 8;
  private static readonly _PRICE: number = 9;
  private static readonly _REQUESTCHANGES: number = 10;
  private static readonly _REQUESTID: number = 11;
  private static readonly _SCHOOL: number = 12;
  private static readonly _SERVICES: number = 13;
  private static readonly _STARTTIME: number = 14;
  private static readonly _STATUS: number = 15;
  private static readonly _TOTAL: number = 16;
  public id: number = 0;
  public otherMaster: DBObject;
  private _requestId: string = "";
  private _createdBy: Renter = null;
  private _facility: Facility = null;
  private _school: School = null;
  private _category: FacilityUsageCategory = null;
  private _eventName: string = "";
  private _eventDate: D3EDate = null;
  private _startTime: Time = null;
  private _endTime: Time = null;
  private _services: Array<Service> = ListWrapper.reference(
    this,
    "services",
    RentalRequest._SERVICES
  );
  private _equipments: Array<Equipment> = ListWrapper.reference(
    this,
    "equipments",
    RentalRequest._EQUIPMENTS
  );
  private _price: number = 0.0;
  private _total: number = 0.0;
  private _paid: number = 0.0;
  private _requestChanges: boolean = false;
  private _status: RequestStatus = RequestStatus.Submitted;
  private _createdDate: DateTime = null;
  public constructor(
    d3eParams?: Partial<{
      category: FacilityUsageCategory;
      createdBy: Renter;
      createdDate: DateTime;
      endTime: Time;
      equipments: Array<Equipment>;
      eventDate: D3EDate;
      eventName: string;
      facility: Facility;
      paid: number;
      price: number;
      requestChanges: boolean;
      requestId: string;
      services: Array<Service>;
      startTime: Time;
      status: RequestStatus;
      total: number;
    }>
  ) {
    super();

    this.setCategory(d3eParams?.category ?? null);

    this.setCreatedBy(d3eParams?.createdBy ?? null);

    this.setCreatedDate(d3eParams?.createdDate ?? null);

    this.setEndTime(d3eParams?.endTime ?? null);

    this.setEquipments(
      d3eParams?.equipments ??
        ListWrapper.reference(this, "equipments", RentalRequest._EQUIPMENTS)
    );

    this.setEventDate(d3eParams?.eventDate ?? null);

    this.setEventName(d3eParams?.eventName ?? "");

    this.setFacility(d3eParams?.facility ?? null);

    this.setPaid(d3eParams?.paid ?? 0.0);

    this.setPrice(d3eParams?.price ?? 0.0);

    this.setRequestChanges(d3eParams?.requestChanges ?? false);

    this.setRequestId(d3eParams?.requestId ?? "");

    this.setServices(
      d3eParams?.services ??
        ListWrapper.reference(this, "services", RentalRequest._SERVICES)
    );

    this.setStartTime(d3eParams?.startTime ?? null);

    this.setStatus(d3eParams?.status ?? RequestStatus.Submitted);

    this.setTotal(d3eParams?.total ?? 0.0);
  }
  public get d3eType(): string {
    return "RentalRequest";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get requestId(): string {
    return this._requestId;
  }
  public setRequestId(val: string): void {
    let isValChanged: boolean = this._requestId !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequest._REQUESTID, this._requestId);

    this._requestId = val;

    this.fire("requestId", this);
  }
  public get createdBy(): Renter {
    return this._createdBy;
  }
  public setCreatedBy(val: Renter): void {
    let isValChanged: boolean = this._createdBy !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequest._CREATEDBY, this._createdBy);

    this.updateObservable("createdBy", this._createdBy, val);

    this._createdBy = val;

    this.fire("createdBy", this);
  }
  public get facility(): Facility {
    return this._facility;
  }
  public setFacility(val: Facility): void {
    let isValChanged: boolean = this._facility !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequest._FACILITY, this._facility);

    this.updateObservable("facility", this._facility, val);

    this._facility = val;

    this.fire("facility", this);
  }
  public get school(): School {
    return this._school;
  }
  public setSchool(val: School): void {
    let isValChanged: boolean = this._school !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequest._SCHOOL, this._school);

    this.updateObservable("school", this._school, val);

    this._school = val;

    this.fire("school", this);
  }
  public get category(): FacilityUsageCategory {
    return this._category;
  }
  public setCategory(val: FacilityUsageCategory): void {
    let isValChanged: boolean = this._category !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequest._CATEGORY, this._category);

    this.updateObservable("category", this._category, val);

    this._category = val;

    this.fire("category", this);
  }
  public get eventName(): string {
    return this._eventName;
  }
  public setEventName(val: string): void {
    let isValChanged: boolean = this._eventName !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequest._EVENTNAME, this._eventName);

    this._eventName = val;

    this.fire("eventName", this);
  }
  public get eventDate(): D3EDate {
    return this._eventDate;
  }
  public setEventDate(val: D3EDate): void {
    let isValChanged: boolean = this._eventDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequest._EVENTDATE, this._eventDate);

    this._eventDate = val;

    this.fire("eventDate", this);
  }
  public get startTime(): Time {
    return this._startTime;
  }
  public setStartTime(val: Time): void {
    let isValChanged: boolean = this._startTime !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequest._STARTTIME, this._startTime);

    this._startTime = val;

    this.fire("startTime", this);
  }
  public get endTime(): Time {
    return this._endTime;
  }
  public setEndTime(val: Time): void {
    let isValChanged: boolean = this._endTime !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequest._ENDTIME, this._endTime);

    this._endTime = val;

    this.fire("endTime", this);
  }
  public get services(): Array<Service> {
    return this._services;
  }
  public setServices(val: Array<Service>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._services,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(RentalRequest._SERVICES)) {
      let _old: Array<Service> = Array.from(this._services);

      this.updateD3EChanges(RentalRequest._SERVICES, _old);
    }

    this.updateObservableColl("services", this._services, val);

    this._services.clear();

    this._services.addAll(val);

    this.fire("services", this);
  }
  public addToServices(val: Service, index: number = -1): void {
    let _old: Array<Service> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      RentalRequest._SERVICES
    );

    if (_isNewChange) {
      _old = Array.from(this._services);
    }

    if (index === -1) {
      if (!this._services.contains(val)) this._services.add(val);
    } else {
      this._services.remove(this._services.elementAt(index));

      this._services.add(val);
    }

    this.fire("services", this, val, true);

    this.updateObservable("services", null, val);

    if (_isNewChange) {
      this.updateD3EChanges(RentalRequest._SERVICES, _old);
    }
  }
  public removeFromServices(val: Service): void {
    let _old: Array<Service> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      RentalRequest._SERVICES
    );

    if (_isNewChange) {
      _old = Array.from(this._services);
    }

    this._services.remove(val);

    this.fire("services", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(RentalRequest._SERVICES, _old);
    }
  }
  public get equipments(): Array<Equipment> {
    return this._equipments;
  }
  public setEquipments(val: Array<Equipment>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._equipments,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(RentalRequest._EQUIPMENTS)) {
      let _old: Array<Equipment> = Array.from(this._equipments);

      this.updateD3EChanges(RentalRequest._EQUIPMENTS, _old);
    }

    this.updateObservableColl("equipments", this._equipments, val);

    this._equipments.clear();

    this._equipments.addAll(val);

    this.fire("equipments", this);
  }
  public addToEquipments(val: Equipment, index: number = -1): void {
    let _old: Array<Equipment> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      RentalRequest._EQUIPMENTS
    );

    if (_isNewChange) {
      _old = Array.from(this._equipments);
    }

    if (index === -1) {
      if (!this._equipments.contains(val)) this._equipments.add(val);
    } else {
      this._equipments.remove(this._equipments.elementAt(index));

      this._equipments.add(val);
    }

    this.fire("equipments", this, val, true);

    this.updateObservable("equipments", null, val);

    if (_isNewChange) {
      this.updateD3EChanges(RentalRequest._EQUIPMENTS, _old);
    }
  }
  public removeFromEquipments(val: Equipment): void {
    let _old: Array<Equipment> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      RentalRequest._EQUIPMENTS
    );

    if (_isNewChange) {
      _old = Array.from(this._equipments);
    }

    this._equipments.remove(val);

    this.fire("equipments", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(RentalRequest._EQUIPMENTS, _old);
    }
  }
  public get price(): number {
    return this._price;
  }
  public setPrice(val: number): void {
    let isValChanged: boolean = this._price !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequest._PRICE, this._price);

    this._price = val;

    this.fire("price", this);
  }
  public get total(): number {
    return this._total;
  }
  public setTotal(val: number): void {
    let isValChanged: boolean = this._total !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequest._TOTAL, this._total);

    this._total = val;

    this.fire("total", this);
  }
  public get paid(): number {
    return this._paid;
  }
  public setPaid(val: number): void {
    let isValChanged: boolean = this._paid !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequest._PAID, this._paid);

    this._paid = val;

    this.fire("paid", this);
  }
  public get requestChanges(): boolean {
    return this._requestChanges;
  }
  public setRequestChanges(val: boolean): void {
    let isValChanged: boolean = this._requestChanges !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequest._REQUESTCHANGES, this._requestChanges);

    this._requestChanges = val;

    this.fire("requestChanges", this);
  }
  public get status(): RequestStatus {
    return this._status;
  }
  public setStatus(val: RequestStatus): void {
    let isValChanged: boolean = this._status !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequest._STATUS, this._status.index);

    this._status = val;

    this.fire("status", this);
  }
  public get createdDate(): DateTime {
    return this._createdDate;
  }
  public setCreatedDate(val: DateTime): void {
    let isValChanged: boolean = this._createdDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequest._CREATEDDATE, this._createdDate);

    this._createdDate = val;

    this.fire("createdDate", this);
  }
  public get(field: number): any {
    switch (field) {
      case RentalRequest._REQUESTID: {
        return this._requestId;
      }

      case RentalRequest._CREATEDBY: {
        return this._createdBy;
      }

      case RentalRequest._FACILITY: {
        return this._facility;
      }

      case RentalRequest._SCHOOL: {
        return this._school;
      }

      case RentalRequest._CATEGORY: {
        return this._category;
      }

      case RentalRequest._EVENTNAME: {
        return this._eventName;
      }

      case RentalRequest._EVENTDATE: {
        return this._eventDate;
      }

      case RentalRequest._STARTTIME: {
        return this._startTime;
      }

      case RentalRequest._ENDTIME: {
        return this._endTime;
      }

      case RentalRequest._SERVICES: {
        return this._services;
      }

      case RentalRequest._EQUIPMENTS: {
        return this._equipments;
      }

      case RentalRequest._PRICE: {
        return this._price;
      }

      case RentalRequest._TOTAL: {
        return this._total;
      }

      case RentalRequest._PAID: {
        return this._paid;
      }

      case RentalRequest._REQUESTCHANGES: {
        return this._requestChanges;
      }

      case RentalRequest._STATUS: {
        return this._status.index;
      }

      case RentalRequest._CREATEDDATE: {
        return this._createdDate;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): RentalRequest {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: RentalRequest = dbObj as RentalRequest;

    obj.id = this.id;

    obj.setRequestId(this._requestId);

    obj.setCreatedBy(this._createdBy);

    obj.setFacility(this._facility);

    obj.setSchool(this._school);

    obj.setCategory(this._category);

    obj.setEventName(this._eventName);

    obj.setEventDate(this._eventDate);

    obj.setStartTime(this._startTime);

    obj.setEndTime(this._endTime);

    obj.setServices(this._services);

    obj.setEquipments(this._equipments);

    obj.setPrice(this._price);

    obj.setTotal(this._total);

    obj.setPaid(this._paid);

    obj.setRequestChanges(this._requestChanges);

    obj.setStatus(this._status);

    obj.setCreatedDate(this._createdDate);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case RentalRequest._REQUESTID: {
        this.setRequestId(value as string);
        break;
      }

      case RentalRequest._CREATEDBY: {
        this.setCreatedBy(value as Renter);
        break;
      }

      case RentalRequest._FACILITY: {
        this.setFacility(value as Facility);
        break;
      }

      case RentalRequest._SCHOOL: {
        this.setSchool(value as School);
        break;
      }

      case RentalRequest._CATEGORY: {
        this.setCategory(value as FacilityUsageCategory);
        break;
      }

      case RentalRequest._EVENTNAME: {
        this.setEventName(value as string);
        break;
      }

      case RentalRequest._EVENTDATE: {
        this.setEventDate(value as D3EDate);
        break;
      }

      case RentalRequest._STARTTIME: {
        this.setStartTime(value as Time);
        break;
      }

      case RentalRequest._ENDTIME: {
        this.setEndTime(value as Time);
        break;
      }

      case RentalRequest._SERVICES: {
        this.setServices((value as Array<any>).cast<Service>().toList());
        break;
      }

      case RentalRequest._EQUIPMENTS: {
        this.setEquipments((value as Array<any>).cast<Equipment>().toList());
        break;
      }

      case RentalRequest._PRICE: {
        this.setPrice(value as number);
        break;
      }

      case RentalRequest._TOTAL: {
        this.setTotal(value as number);
        break;
      }

      case RentalRequest._PAID: {
        this.setPaid(value as number);
        break;
      }

      case RentalRequest._REQUESTCHANGES: {
        this.setRequestChanges(value as boolean);
        break;
      }

      case RentalRequest._STATUS: {
        this.setStatus(RequestStatus.values[value as number]);
        break;
      }

      case RentalRequest._CREATEDDATE: {
        this.setCreatedDate(value as DateTime);
        break;
      }
    }
  }
}
