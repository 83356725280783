import React, { ReactElement, ReactNode } from "react";
import * as ui from "../native";
import { ContextData } from "./BuildContext";
import FacilityType from "../models/FacilityType";
import SignUpRequest from "../models/SignUpRequest";
import RentalRequest from "../models/RentalRequest";
import School from "../models/School";
import ThemeWrapper from "../components/ThemeWrapper";
import GlobalFunctions from "../utils/GlobalFunctions";
import Renter from "../models/Renter";
import Facility from "../models/Facility";
import Admin from "../models/Admin";
import { StyleThemeData } from "../components/ThemeWrapper";

export type RouterController = (node: ReactNode) => void;

const pageRouters: Map<string, RouterController> = new Map<
  string,
  RouterController
>();

export default class PageNavigator {
  public static defaultOverlay: ui.OverlayController;
  public static themeUpdateListener = () => {};
  public kIsWeb = true;
  public ctx: ContextData;
  public constructor(ctx: ContextData) {
    this.ctx = ctx;
  }
  public static addListener(target: string, listener: RouterController) {
    pageRouters.set(target, listener);
  }
  public static removeListener(target: string) {
    pageRouters.remove(target);
  }
  public static of(ctx: ContextData): PageNavigator {
    return new PageNavigator(ctx);
  }
  public wrapTheme(widget: ReactNode): ReactNode {
    let mediaQuery = ui.MediaQuery.of(this.ctx);

    return ThemeWrapper({ child: widget });
  }
  public push(
    replace: boolean,
    widget: ReactNode,
    title: string,
    path: string,
    target: string
  ): void {
    if (this.kIsWeb) {
      if (replace) {
        window.history.replaceState(null, title, path);
      } else {
        window.history.pushState(null, title, path);
      }
    }

    widget = this.wrapTheme(widget);

    if (!target) {
      target = "";
    }

    let listener = pageRouters.get(target);

    if (listener) {
      listener(widget);
    }
  }
  public pop(): void {
    this.close();
  }
  public close(): boolean {
    if (this.ctx.popup) {
      this.ctx.popup.hidePopup({});

      return true;
    } else {
      return false;
    }
  }
  public pushBookingPage(
    d3eParams?: Partial<{
      replace: boolean;
      target: string;
      user: Renter;
      facility: Facility;
    }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let user = d3eParams?.user;

    let facility = d3eParams?.facility;

    this.push(
      replace,
      GlobalFunctions.BookingPage({ user: user, facility: facility }),
      "",
      "#booking page",
      target
    );
  }
  public pushEventCalendarPage(
    d3eParams?: Partial<{
      replace: boolean;
      target: string;
      school: School;
      renter: Renter;
    }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let school = d3eParams?.school;

    let renter = d3eParams?.renter;

    this.push(
      replace,
      GlobalFunctions.EventCalendarPage({ school: school, renter: renter }),
      "",
      "#event-calendar-page",
      target
    );
  }
  public pushMainHomePage(
    d3eParams?: Partial<{ replace: boolean; target: string }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    this.push(
      replace,
      GlobalFunctions.MainHomePage({}),
      "",
      "#main-home-page",
      target
    );
  }
  public pushMainPage(
    d3eParams?: Partial<{ replace: boolean; target: string }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    this.push(replace, GlobalFunctions.MainPage({}), "", "#main-page", target);
  }
  public pushPaymentMethodsPage(
    d3eParams?: Partial<{ replace: boolean; target: string; renter: Renter }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let renter = d3eParams?.renter;

    this.push(
      replace,
      GlobalFunctions.PaymentMethodsPage({ renter: renter }),
      "",
      "#payment-methods-page",
      target
    );
  }
  public pushRentalChangePasswordPage(
    d3eParams?: Partial<{ replace: boolean; target: string; user: Renter }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let user = d3eParams?.user;

    this.push(
      replace,
      GlobalFunctions.RentalChangePasswordPage({ user: user }),
      "RentalChangePassWord",
      "#rental-change-password-page",
      target
    );
  }
  public pushRentalCurrentInvoicePage(
    d3eParams?: Partial<{ replace: boolean; target: string; renter: Renter }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let renter = d3eParams?.renter;

    this.push(
      replace,
      GlobalFunctions.RentalCurrentInvoicePage({ renter: renter }),
      "",
      "#rental-current-invoice-page",
      target
    );
  }
  public pushRentalDashboardPage(
    d3eParams?: Partial<{ replace: boolean; target: string; renter: Renter }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let renter = d3eParams?.renter;

    this.push(
      replace,
      GlobalFunctions.RentalDashboardPage({ renter: renter }),
      "",
      "#rental-dashboard-page",
      target
    );
  }
  public pushRentalDocumentPage(
    d3eParams?: Partial<{ replace: boolean; target: string; renter: Renter }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let renter = d3eParams?.renter;

    this.push(
      replace,
      GlobalFunctions.RentalDocumentPage({ renter: renter }),
      "",
      "#rental-document-page",
      target
    );
  }
  public pushRentalForgotPasswordPage(
    d3eParams?: Partial<{ replace: boolean; target: string }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    this.push(
      replace,
      GlobalFunctions.RentalForgotPasswordPage({}),
      "",
      "#rental-forgot-password-page",
      target
    );
  }
  public pushRentalHomePage(
    d3eParams?: Partial<{ replace: boolean; target: string; renter: Renter }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let renter = d3eParams?.renter;

    this.push(
      replace,
      GlobalFunctions.RentalHomePage({ renter: renter }),
      "",
      "#rental-home-page",
      target
    );
  }
  public pushRentalInsurancePage(
    d3eParams?: Partial<{ replace: boolean; target: string; renter: Renter }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let renter = d3eParams?.renter;

    this.push(
      replace,
      GlobalFunctions.RentalInsurancePage({ renter: renter }),
      "",
      "#rental-insurance-page",
      target
    );
  }
  public pushRentalLoginPage(
    d3eParams?: Partial<{ replace: boolean; target: string }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    this.push(
      replace,
      GlobalFunctions.RentalLoginPage({}),
      "",
      "#rental-login-page",
      target
    );
  }
  public pushRentalPaymentHistoryPage(
    d3eParams?: Partial<{ replace: boolean; target: string; renter: Renter }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let renter = d3eParams?.renter;

    this.push(
      replace,
      GlobalFunctions.RentalPaymentHistoryPage({ renter: renter }),
      "",
      "#rental-payment-history-page",
      target
    );
  }
  public pushRentalReservationPage(
    d3eParams?: Partial<{ replace: boolean; target: string; renter: Renter }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let renter = d3eParams?.renter;

    this.push(
      replace,
      GlobalFunctions.RentalReservationPage({ renter: renter }),
      "",
      "#rental-reservation-page",
      target
    );
  }
  public pushRentelSignupPage(
    d3eParams?: Partial<{
      replace: boolean;
      target: string;
      signUpRequest: SignUpRequest;
    }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let signUpRequest = d3eParams?.signUpRequest;

    this.push(
      replace,
      GlobalFunctions.RentelSignupPage({ signUpRequest: signUpRequest }),
      "",
      "#rentel-signup-page",
      target
    );
  }
  public pushRequestOverViewPage(
    d3eParams?: Partial<{
      replace: boolean;
      target: string;
      requestObj: RentalRequest;
      admin: Admin;
      renter: Renter;
    }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let requestObj = d3eParams?.requestObj;

    let admin = d3eParams?.admin;

    let renter = d3eParams?.renter;

    this.push(
      replace,
      GlobalFunctions.RequestOverViewPage({
        requestObj: requestObj,
        admin: admin,
        renter: renter,
      }),
      "",
      "#request-over-view-page",
      target
    );
  }
  public pushSearchPage(
    d3eParams?: Partial<{
      replace: boolean;
      target: string;
      facilityType: FacilityType;
      location: string;
      facilityTypes: Array<FacilityType>;
      locations: Array<string>;
      renter: Renter;
    }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let facilityType = d3eParams?.facilityType;

    let location = d3eParams?.location;

    let facilityTypes = d3eParams?.facilityTypes;

    let locations = d3eParams?.locations;

    let renter = d3eParams?.renter;

    this.push(
      replace,
      GlobalFunctions.SearchPage({
        facilityType: facilityType,
        location: location,
        facilityTypes: facilityTypes,
        locations: locations,
        renter: renter,
      }),
      "",
      "#search-page",
      target
    );
  }
  public applyTheme(d3eParams?: Partial<{ theme: string }>): void {
    let theme = d3eParams?.theme;

    /*
TODO
*/

    switch (theme) {
      case "D3ELight": {
        StyleThemeData.current = StyleThemeData.createD3ELight();

        PageNavigator.themeUpdateListener();

        /*
TODO ThemeBuilder.of(this.ctx).rebuild();
*/
        break;
      }
      default: {
        StyleThemeData.current = StyleThemeData.createD3ELight();

        PageNavigator.themeUpdateListener();

        /*
TODO ThemeBuilder.of(this.ctx).rebuild();
*/
      }
    }
  }
  public getProjectThemeNames(): string[] {
    return ["D3ELight"];
  }
  public getCurrentThemeName(): string {
    return StyleThemeData.current.themeName;
  }
}
