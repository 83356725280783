import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import MaterialIcons from "../icons/MaterialIcons";
import Duration from "../core/Duration";
import Timer from "../utils/Timer";
import IconButton from "./IconButton";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

type _SearchComponentOnChanged = (value: string) => void;

type _SearchComponentOnEditingComplete = (value: string) => void;

type _SearchComponentOnTap = () => void;

export interface SearchComponentProps extends BaseUIProps {
  key?: string;
  value?: string;
  placeholder?: string;
  autoFocus?: boolean;
  onChanged?: _SearchComponentOnChanged;
  onEditingComplete?: _SearchComponentOnEditingComplete;
  onTap?: _SearchComponentOnTap;
}

class _SearchComponentState extends ObservableComponent<SearchComponentProps> {
  static defaultProps = {
    value: "",
    placeholder: "",
    autoFocus: false,
    onChanged: null,
    onEditingComplete: null,
    onTap: null,
  };
  searchFieldController: ui.TextEditingController =
    new ui.TextEditingController();
  focusNode: ui.FocusNode = null;
  active: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SearchComponentProps) {
    super(props);

    this.initState();
  }
  public get value(): string {
    return this.props.value;
  }
  public get placeholder(): string {
    return this.props.placeholder;
  }
  public get autoFocus(): boolean {
    return this.props.autoFocus;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.on(
      ["active", "autoFocus", "focusNode", "placeholder", "value"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: SearchComponentProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.placeholder !== this.props.placeholder) {
      this.fire("placeholder", this);
    }

    if (prevProps.autoFocus !== this.props.autoFocus) {
      this.fire("autoFocus", this);
    }
  }
  public setFocusNode(val: ui.FocusNode): void {
    let isValChanged: boolean = this.focusNode !== val;

    if (!isValChanged) {
      return;
    }

    this.focusNode = val;

    this.fire("focusNode", this);
  }
  public setActive(val: boolean): void {
    let isValChanged: boolean = this.active !== val;

    if (!isValChanged) {
      return;
    }

    this.active = val;

    this.fire("active", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      states: ui.joinStates({ "data-c0": this.active }, this.props.states),
      decoration: this.active
        ? new ui.BoxDecoration({
            color: cStyle.c2,
            border: ui.Border.all({ color: cStyle.c20, width: 1 }),
            borderRadius: ui.BorderRadius.circular(3.0),
          })
        : new ui.BoxDecoration({}),
      child: ui.Row({
        mainAxisAlignment: ui.MainAxisAlignment.spaceEvenly,
        children: [
          IconView({
            icon: MaterialIcons.search,
            size: 23,
            className: "x5fa",
            key: "0",
          }),
          ui.InputField({
            placeHolder: this.placeholder,
            padding: ui.EdgeInsets.symmetric({
              horizontal: 0.0,
              vertical: 4.0,
              transitions: new Map(),
            }),
            focusNode: this.focusNode,
            autofocus: this.autoFocus,
            value: this.value,
            activeColor: cStyle.tInputFieldSearchInputfieldActiveColorOn,
            inActiveColor: cStyle.tInputFieldSearchInputfieldInActiveColorOn,
            placeHolderColor:
              cStyle.tInputFieldSearchInputfieldPlaceHolderColorOn,
            controller: this.searchFieldController,
            onChanged: (text) => {
              this.onSearchHandler(text);
            },
            onEditingComplete: () => {
              this.onSearchEditingHandler();
            },
            onTap: () => {
              this.onSearchTapHandler();
            },
            onFocusChange: (val) => {},
            className: "x8f28 hc h",
            key: "1",
          }),
          ui.Container({
            padding: ui.EdgeInsets.fromLTRB(5.0, 0.0, 10.0, 0.0, new Map()),
            child: IconButton({
              icon: MaterialIcons.close,
              size: 18,
              onPressed: () => {
                this.onCloseHandler();
              },
              states: ui.joinStates(
                {
                  "data-visibility":
                    this.searchFieldController.text !== null &&
                    this.searchFieldController.text !== "",
                },
                {}
              ),
            }),
            key: "2",
            className: "x983",
            states: ui.joinStates(
              {
                "data-visibility":
                  this.searchFieldController.text !== null &&
                  this.searchFieldController.text !== "",
              },
              {}
            ),
          }),
        ],
        className: "xb3d",
      }),
      className: ui.join(this.props.className, "SearchComponent x213"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setFocusNode(new ui.FocusNode());

    this.focusNode.addListener(this.onChangeFocus);

    if (this.autoFocus) {
      this.focusNode.requestFocus();

      new Timer(new Duration({ milliseconds: 100 }), () => {
        this.searchFieldController.selection = new ui.TextSelection({
          baseOffset: 0,
          extentOffset: this.value?.length ?? 0,
        });
      });
    }
  };
  public onChangeFocus = (): void => {
    this.setActive(this.focusNode.hasFocus);

    if (!this.active) {
      this.searchFieldController.selection = new ui.TextSelection({
        baseOffset: 0,
        extentOffset: 0,
      });
    }

    if (this.active && this.onTap !== null) {
      this.onTap();
    }
  };
  public onSearchHandler = (text: string): void => {
    if (this.onChanged !== null) {
      this.onChanged(text);
    }
  };
  public onSearchEditingHandler = (): void => {
    if (this.onEditingComplete !== null) {
      this.onEditingComplete(this.searchFieldController.text);
    }
  };
  public onSearchTapHandler = (): void => {
    if (this.onTap !== null) {
      this.onTap();
    }
  };
  public onCloseHandler = (): void => {
    this.searchFieldController.text = "";

    if (this.onChanged !== null) {
      this.onChanged(this.searchFieldController.text);
    }
  };
  public get onChanged(): _SearchComponentOnChanged {
    return this.props.onChanged;
  }
  public get onEditingComplete(): _SearchComponentOnEditingComplete {
    return this.props.onEditingComplete;
  }
  public get onTap(): _SearchComponentOnTap {
    return this.props.onTap;
  }
}
export default function SearchComponent(props: SearchComponentProps) {
  return React.createElement(_SearchComponentState, {
    ..._SearchComponentState.defaultProps,
    ...props,
  });
}
