import ListWrapper from "../utils/ListWrapper";
import DFile from "../classes/DFile";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";
import CollectionUtils from "../utils/CollectionUtils";

export default class FacilityType extends DBObject {
  private static readonly _IMAGE: number = 0;
  private static readonly _NAME: number = 1;
  private static readonly _POLICIES: number = 2;
  public id: number = 0;
  public otherMaster: DBObject;
  private _name: string = "";
  private _policies: Array<string> = ListWrapper.primitive(
    this,
    "policies",
    FacilityType._POLICIES
  );
  private _image: DFile = null;
  public constructor(
    d3eParams?: Partial<{ image: DFile; name: string; policies: Array<string> }>
  ) {
    super();

    this.setImage(d3eParams?.image ?? null);

    this.setName(d3eParams?.name ?? "");

    this.setPolicies(
      d3eParams?.policies ??
        ListWrapper.primitive(this, "policies", FacilityType._POLICIES)
    );
  }
  public get d3eType(): string {
    return "FacilityType";
  }
  public toString(): string {
    return this.name;
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get name(): string {
    return this._name;
  }
  public setName(val: string): void {
    let isValChanged: boolean = this._name !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(FacilityType._NAME, this._name);

    this._name = val;

    this.fire("name", this);
  }
  public get policies(): Array<string> {
    return this._policies;
  }
  public setPolicies(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._policies,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(FacilityType._POLICIES)) {
      let _old: Array<string> = Array.from(this._policies);

      this.updateD3EChanges(FacilityType._POLICIES, _old);
    }

    this._policies.clear();

    this._policies.addAll(val);

    this.fire("policies", this);
  }
  public addToPolicies(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      FacilityType._POLICIES
    );

    if (_isNewChange) {
      _old = Array.from(this._policies);
    }

    if (index === -1) {
      if (!this._policies.contains(val)) this._policies.add(val);
    } else {
      this._policies.remove(this._policies.elementAt(index));

      this._policies.add(val);
    }

    this.fire("policies", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(FacilityType._POLICIES, _old);
    }
  }
  public removeFromPolicies(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      FacilityType._POLICIES
    );

    if (_isNewChange) {
      _old = Array.from(this._policies);
    }

    this._policies.remove(val);

    this.fire("policies", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(FacilityType._POLICIES, _old);
    }
  }
  public get image(): DFile {
    return this._image;
  }
  public setImage(val: DFile): void {
    let isValChanged: boolean = this._image !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(FacilityType._IMAGE, this._image);

    this._image = val;

    this.fire("image", this);
  }
  public get(field: number): any {
    switch (field) {
      case FacilityType._NAME: {
        return this._name;
      }

      case FacilityType._POLICIES: {
        return this._policies;
      }

      case FacilityType._IMAGE: {
        return this._image;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): FacilityType {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: FacilityType = dbObj as FacilityType;

    obj.id = this.id;

    obj.setName(this._name);

    obj.setPolicies(this._policies);

    obj.setImage(this._image);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case FacilityType._NAME: {
        this.setName(value as string);
        break;
      }

      case FacilityType._POLICIES: {
        this.setPolicies((value as Array<any>).cast<string>().toList());
        break;
      }

      case FacilityType._IMAGE: {
        this.setImage(value as DFile);
        break;
      }
    }
  }
}
