import CalendarEvent from "./CalendarEvent";
import RentalRequest from "../models/RentalRequest";

export default class CalendarEventUtils {
  public constructor() {}
  public static getEventList(
    rentals: Array<RentalRequest>
  ): Array<CalendarEvent> {
    let eventList: Array<CalendarEvent> = [];

    for (let rental of rentals) {
      let event: CalendarEvent = new CalendarEvent({
        date: rental.eventDate,
        title: rental.eventName,
        startTime: rental.startTime.toDateTime(),
        endTime: rental.endTime.toDateTime(),
      });

      eventList.add(event);
    }

    return eventList;
  }
}
