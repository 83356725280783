import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import PopupTargetController from "./PopupTargetController";
import ListWrapper from "../utils/ListWrapper";
import SearchFilterView from "./SearchFilterView";
import Popup from "./Popup";
import TextView from "./TextView";
import CollectionUtils from "../utils/CollectionUtils";
import IconView from "./IconView";
import { StyleThemeData } from "./ThemeWrapper";
import { BuildContext } from "../classes/BuildContext";

type _RentalSearchableViewOnChanged<T> = (item: T) => void;

export interface RentalSearchableViewProps<T> extends BaseUIProps {
  key?: string;
  value?: T;
  isRequired?: boolean;
  name?: string;
  items?: Array<T>;
  placeHolder?: string;
  errors?: Array<string>;
  disable?: boolean;
  activeColor?: ui.Color;
  inActiveColor?: ui.Color;
  icon?: ui.IconData;
  _itemsHash?: number;
  _errorsHash?: number;
  onChanged?: _RentalSearchableViewOnChanged<T>;
}

class _RentalSearchableViewState<T> extends ObservableComponent<
  RentalSearchableViewProps<T>
> {
  static defaultProps = {
    value: null,
    isRequired: false,
    name: "",
    placeHolder: "Search",
    disable: false,
    activeColor: null,
    inActiveColor: null,
    icon: null,
    items: [],
    errors: [],
    onChanged: null,
  };
  searchableEmpltyController: ui.TextEditingController =
    new ui.TextEditingController();
  itemsInternal: Array<T> = ListWrapper.widget(this, "itemsInternal");
  filterdData: Array<T> = ListWrapper.widget(this, "filterdData");
  searchValue: string = "";
  popUpWidth: number = 165.0;
  focusNode: ui.FocusNode = null;
  active: boolean = false;
  resultsPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public emptyPopupTargetController: PopupTargetController =
    new PopupTargetController();
  public constructor(props: RentalSearchableViewProps<T>) {
    super(props);

    this.initState();
  }
  public get value(): T {
    return this.props.value;
  }
  public get isRequired(): boolean {
    return this.props.isRequired;
  }
  public get name(): string {
    return this.props.name;
  }
  public get items(): Array<T> {
    return this.props.items;
  }
  public get placeHolder(): string {
    return this.props.placeHolder;
  }
  public get errors(): Array<string> {
    return this.props.errors;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public get activeColor(): ui.Color {
    return this.props.activeColor;
  }
  public get inActiveColor(): ui.Color {
    return this.props.inActiveColor;
  }
  public get icon(): ui.IconData {
    return this.props.icon;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.subscribeToList(this.items, "items");

    this.on(["items"], this.computeItemsInternal);

    this.computeItemsInternal();

    this.subscribeToList(this.errors, "errors");

    this.on(
      [
        "active",
        "activeColor",
        "disable",
        "errors",
        "focusNode",
        "icon",
        "inActiveColor",
        "isRequired",
        "name",
        "placeHolder",
        "searchValue",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RentalSearchableViewProps<T>): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.isRequired !== this.props.isRequired) {
      this.fire("isRequired", this);
    }

    if (prevProps.name !== this.props.name) {
      this.fire("name", this);
    }

    if (prevProps.items !== this.props.items) {
      this.fire("items", this);
    }

    if (prevProps.placeHolder !== this.props.placeHolder) {
      this.fire("placeHolder", this);
    }

    if (prevProps.errors !== this.props.errors) {
      this.fire("errors", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }

    if (prevProps.activeColor !== this.props.activeColor) {
      this.fire("activeColor", this);
    }

    if (prevProps.inActiveColor !== this.props.inActiveColor) {
      this.fire("inActiveColor", this);
    }

    if (prevProps.icon !== this.props.icon) {
      this.fire("icon", this);
    }
  }
  public setItemsInternal(val: Array<T>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.itemsInternal,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.itemsInternal.clear();

    this.itemsInternal.addAll(val);

    this.fire("itemsInternal", this);
  }
  public addToItemsInternal(val: T, index: number = -1): void {
    if (index === -1) {
      if (!this.itemsInternal.contains(val)) this.itemsInternal.add(val);
    } else {
      this.itemsInternal.remove(this.itemsInternal.elementAt(index));

      this.itemsInternal.add(val);
    }

    this.fire("itemsInternal", this, val, true);
  }
  public removeFromItemsInternal(val: T): void {
    this.itemsInternal.remove(val);

    this.fire("itemsInternal", this, val, false);
  }
  public computeItemsInternal = (): void => {
    try {
      this.setItemsInternal(Array.from([...this.items]));
    } catch (exception) {
      console.log(
        " exception in computeItemsInternal : " + exception.toString()
      );

      this.setItemsInternal([]);
    }
  };
  public setFilterdData(val: Array<T>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.filterdData,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.filterdData.clear();

    this.filterdData.addAll(val);

    this.fire("filterdData", this);
  }
  public addToFilterdData(val: T, index: number = -1): void {
    if (index === -1) {
      if (!this.filterdData.contains(val)) this.filterdData.add(val);
    } else {
      this.filterdData.remove(this.filterdData.elementAt(index));

      this.filterdData.add(val);
    }

    this.fire("filterdData", this, val, true);
  }
  public removeFromFilterdData(val: T): void {
    this.filterdData.remove(val);

    this.fire("filterdData", this, val, false);
  }
  public setSearchValue(val: string): void {
    let isValChanged: boolean = this.searchValue !== val;

    if (!isValChanged) {
      return;
    }

    this.searchValue = val;

    this.fire("searchValue", this);
  }
  public setPopUpWidth(val: number): void {
    let isValChanged: boolean = this.popUpWidth !== val;

    if (!isValChanged) {
      return;
    }

    this.popUpWidth = val;

    this.fire("popUpWidth", this);
  }
  public setFocusNode(val: ui.FocusNode): void {
    let isValChanged: boolean = this.focusNode !== val;

    if (!isValChanged) {
      return;
    }

    this.focusNode = val;

    this.fire("focusNode", this);
  }
  public setActive(val: boolean): void {
    let isValChanged: boolean = this.active !== val;

    if (!isValChanged) {
      return;
    }

    this.active = val;

    this.fire("active", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        this.name !== null && this.name.isNotEmpty
          ? ui.Row({
              children: [
                TextView({
                  data: this.name,
                  style: new ui.TextStyle({
                    fontFamily: cStyle.tTextViewNameTextFontFamilyOn,
                    color: cStyle.tTextViewNameTextColorOn,
                    fontSize: cStyle.tTextViewNameTextFontSizeOn,
                    fontWeight: cStyle.tTextViewNameTextFontWeightOn,
                  }),
                  className: "x05dc",
                  key: "0",
                }),
                this.isRequired
                  ? TextView({
                      data: "*",
                      style: new ui.TextStyle({
                        fontFamily: cStyle.tTextViewNameTextFontFamilyOn,
                        color: cStyle.tTextViewNameTextColorOn,
                        fontSize: cStyle.tTextViewNameTextFontSizeOn,
                        fontWeight: cStyle.tTextViewNameTextFontWeightOn,
                      }),
                    })
                  : [],
              ],
              className: "xaf2 hc h",
            })
          : [],
        ui.Container({
          decoration: new ui.BoxDecoration({
            color: this.active ? this.activeColor : this.inActiveColor,
            borderRadius: ui.BorderRadius.circular(5.0),
          }),
          child: ui.Row({
            mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
            children: [
              ui.InputField({
                activeColor: cStyle.c20,
                inActiveColor: cStyle.c36,
                focusNode: this.focusNode,
                padding: ui.EdgeInsets.symmetric({
                  horizontal: 5.0,
                  vertical: 6.0,
                  transitions: new Map(),
                }),
                disable: this.disable,
                placeHolder:
                  this.searchableEmpltyController.text === null ||
                  this.searchableEmpltyController.text.isEmpty
                    ? this.placeHolder
                    : null,
                value: this.searchValue,
                controller: this.searchableEmpltyController,
                onChanged: (text) => {
                  this.onChangeText(text);
                },
                onTap: () => {
                  this.onEmptyClick();
                },
                onFocusChange: (val) => {},
                className: "xf44 hc h",
                key: "0",
              }),
              this.icon !== null
                ? IconView({ icon: this.icon, size: 24.0 })
                : [],
            ],
            d3eRef: ui.LayoutAware((bounds, globalPos) => {
              this.onDropDownBoundsChanges(bounds, globalPos);
            }, this.emptyPopupTargetController.handleRef),
          }),
          key: "1",
          className: "x95ae hc h",
        }),
        this.errors.isNotEmpty
          ? ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                this.errors.expand((item) => [
                  TextView({
                    data: item,
                    style: new ui.TextStyle({
                      color: cStyle.tTextViewErrorTextColorOn,
                    }),
                    className: "hc",
                    key: item?.toString(),
                  }),
                ]),
              ],
              className: "hc",
            })
          : [],
      ],
      className: ui.join(this.props.className, "RentalSearchableView xd157"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onEmptyClick = (): void => {
    if (!this.disable) {
      this.setFilterdData(this.items);

      this.showResults({ autoClose: true, takeFocus: false });

      this.setSearchValue("");

      this.setActive(true);
    }
  };
  public onSelectListTile = (item: any): void => {
    this.onChanged(item);

    if (item !== null) {
      this.searchableEmpltyController.text = item.toString();

      this.setSearchValue(item.toString());

      this.setFilterdData(this.items);
    } else {
      this.searchableEmpltyController.text = "";

      this.setSearchValue("");
    }

    this.hideResults();

    this.setActive(false);
  };
  public onDropDownBoundsChanges = (
    bounds: ui.Rect,
    globalPos: ui.Offset
  ): void => {
    this.setPopUpWidth(bounds.width);
  };
  public onInit = (): void => {
    this.setFocusNode(new ui.FocusNode());

    this.focusNode.addListener(this.onChangeFocus);

    this.setSearchValue(this.value !== null ? this.value.toString() : "");

    this.setFilterdData(this.items);
  };
  public onChangeFocus = (): void => {
    this.setActive(this.focusNode.hasFocus);
  };
  public onClosePopUp = (): void => {
    if (this.searchValue.isEmpty) {
      this.setSearchValue(this.value !== null ? this.value.toString() : "");
    }
  };
  public onChangeText = (text: string): void => {
    let val: string = text !== null ? text : "";

    this.setSearchValue(val);

    let l: Array<T> = this.items
      .where((i) => i.toString().toLowerCase().startsWith(val.toLowerCase()))
      .toList();

    this.setItemsInternal(
      this.itemsInternal.where((x) => !l.contains(x)).toList()
    );

    l.addAll(
      this.itemsInternal
        .where((i) => i.toString().toLowerCase().contains(val.toLowerCase()))
        .toList()
    );

    if (val.isEmpty) {
      this.setFilterdData(this.items);
    } else {
      this.setFilterdData(l);
    }

    this.showResults({ autoClose: true, takeFocus: false });
  };
  public get onChanged(): _RentalSearchableViewOnChanged<T> {
    return this.props.onChanged;
  }
  public dispose(): void {
    this.resultsPopup?.dispose();

    super.dispose();
  }
  public showResults(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.resultsPopup?.dispose();

    /*
TODO ThemeWrapper.of(this.context);
*/

    let cStyle = StyleThemeData.current;

    let target: ui.Rect = this.emptyPopupTargetController.getTarget(
      this.context
    );

    this.resultsPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Bottom,
      child: ui.Container({
        constraints: new ui.BoxConstraints({ maxHeight: 250 }),
        decoration: new ui.BoxDecoration({
          color: cStyle.c4,
          borderRadius: ui.BorderRadius.zero,
          boxShadow: [
            new ui.BoxShadow({
              color: new ui.Color(0xff0000),
              blurRadius: 1,
              spreadRadius: 2,
            }),
          ],
        }),
        margin: ui.EdgeInsets.fromLTRB(1.0, 2.0, 1.0, 0.0, new Map()),
        width: this.popUpWidth,
        child: SearchFilterView({
          value: this.value,
          items: this.filterdData,
          onSelectItem: (item) => {
            this.onSelectListTile(item);
          },
        }),
        className: "x8c6 hc vc",
      }),
      target: target,
      onClose: this.onClosePopUp,
    });

    this.resultsPopup.showPopup(this.context);
  }
  public hideResults(): void {
    this.resultsPopup?.dispose();
  }
}
export default function RentalSearchableView<T>(
  props: RentalSearchableViewProps<T>
) {
  return React.createElement(
    _RentalSearchableViewState<T>,
    { ..._RentalSearchableViewState.defaultProps, ...props },
    ListWrapper.fromInput<T>(props.items, "items"),
    ListWrapper.fromInput<string>(props.errors, "errors")
  );
}
