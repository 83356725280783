export default class PaginationUtils {
  public constructor() {}
  public static pageNumberPath(
    pageSize: number,
    offset: number,
    total: number
  ): string {
    if (total === 0) {
      return "0-0 of 0";
    } else {
      return (
        (offset + 1).toString() +
        " - " +
        (offset + pageSize > total
          ? total.toString()
          : (offset + pageSize).toString()) +
        " of " +
        total.toString()
      );
    }
  }
  public static hideBackwardArrow(
    pageSize: number,
    offset: number,
    total: number
  ): boolean {
    return pageSize > total || offset <= 1;
  }
  public static hideForwardArrow(
    pageSize: number,
    offset: number,
    total: number
  ): boolean {
    return (
      pageSize > total ||
      offset + pageSize >= total ||
      total - offset < pageSize
    );
  }
}
