import DateTime from "../core/DateTime";
import Admin from "./Admin";
import Renter from "./Renter";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";
import RentalRequest from "./RentalRequest";
import MessageType from "../classes/MessageType";

export default class Message extends DBObject {
  private static readonly _ADMIN: number = 0;
  private static readonly _CREATEDDATE: number = 1;
  private static readonly _MESSAGE: number = 2;
  private static readonly _RENTER: number = 3;
  private static readonly _REQUEST: number = 4;
  private static readonly _TYPE: number = 5;
  public id: number = 0;
  public otherMaster: DBObject;
  private _type: MessageType = MessageType.History;
  private _message: string = "";
  private _request: RentalRequest = null;
  private _admin: Admin = null;
  private _renter: Renter = null;
  private _createdDate: DateTime = null;
  public constructor(
    d3eParams?: Partial<{
      admin: Admin;
      createdDate: DateTime;
      message: string;
      renter: Renter;
      request: RentalRequest;
      type: MessageType;
    }>
  ) {
    super();

    this.setAdmin(d3eParams?.admin ?? null);

    this.setCreatedDate(d3eParams?.createdDate ?? null);

    this.setMessage(d3eParams?.message ?? "");

    this.setRenter(d3eParams?.renter ?? null);

    this.setRequest(d3eParams?.request ?? null);

    this.setType(d3eParams?.type ?? MessageType.History);
  }
  public get d3eType(): string {
    return "Message";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get type(): MessageType {
    return this._type;
  }
  public setType(val: MessageType): void {
    let isValChanged: boolean = this._type !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Message._TYPE, this._type.index);

    this._type = val;

    this.fire("type", this);
  }
  public get message(): string {
    return this._message;
  }
  public setMessage(val: string): void {
    let isValChanged: boolean = this._message !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Message._MESSAGE, this._message);

    this._message = val;

    this.fire("message", this);
  }
  public get request(): RentalRequest {
    return this._request;
  }
  public setRequest(val: RentalRequest): void {
    let isValChanged: boolean = this._request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Message._REQUEST, this._request);

    this.updateObservable("request", this._request, val);

    this._request = val;

    this.fire("request", this);
  }
  public get admin(): Admin {
    return this._admin;
  }
  public setAdmin(val: Admin): void {
    let isValChanged: boolean = this._admin !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Message._ADMIN, this._admin);

    this.updateObservable("admin", this._admin, val);

    this._admin = val;

    this.fire("admin", this);
  }
  public get renter(): Renter {
    return this._renter;
  }
  public setRenter(val: Renter): void {
    let isValChanged: boolean = this._renter !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Message._RENTER, this._renter);

    this.updateObservable("renter", this._renter, val);

    this._renter = val;

    this.fire("renter", this);
  }
  public get createdDate(): DateTime {
    return this._createdDate;
  }
  public setCreatedDate(val: DateTime): void {
    let isValChanged: boolean = this._createdDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Message._CREATEDDATE, this._createdDate);

    this._createdDate = val;

    this.fire("createdDate", this);
  }
  public get(field: number): any {
    switch (field) {
      case Message._TYPE: {
        return this._type.index;
      }

      case Message._MESSAGE: {
        return this._message;
      }

      case Message._REQUEST: {
        return this._request;
      }

      case Message._ADMIN: {
        return this._admin;
      }

      case Message._RENTER: {
        return this._renter;
      }

      case Message._CREATEDDATE: {
        return this._createdDate;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): Message {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: Message = dbObj as Message;

    obj.id = this.id;

    obj.setType(this._type);

    obj.setMessage(this._message);

    obj.setRequest(this._request);

    obj.setAdmin(this._admin);

    obj.setRenter(this._renter);

    obj.setCreatedDate(this._createdDate);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case Message._TYPE: {
        this.setType(MessageType.values[value as number]);
        break;
      }

      case Message._MESSAGE: {
        this.setMessage(value as string);
        break;
      }

      case Message._REQUEST: {
        this.setRequest(value as RentalRequest);
        break;
      }

      case Message._ADMIN: {
        this.setAdmin(value as Admin);
        break;
      }

      case Message._RENTER: {
        this.setRenter(value as Renter);
        break;
      }

      case Message._CREATEDDATE: {
        this.setCreatedDate(value as DateTime);
        break;
      }
    }
  }
}
