import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class SignUpRequest extends DBObject {
  private static readonly _EMAIL: number = 0;
  private static readonly _FULLNAME: number = 1;
  private static readonly _PASSWORD: number = 2;
  public id: number = 0;
  public otherMaster: DBObject;
  private _fullName: string = "";
  private _email: string = "";
  private _password: string = "";
  public constructor(
    d3eParams?: Partial<{ email: string; fullName: string; password: string }>
  ) {
    super();

    this.setEmail(d3eParams?.email ?? "");

    this.setFullName(d3eParams?.fullName ?? "");

    this.setPassword(d3eParams?.password ?? "");
  }
  public get d3eType(): string {
    return "SignUpRequest";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get fullName(): string {
    return this._fullName;
  }
  public setFullName(val: string): void {
    let isValChanged: boolean = this._fullName !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SignUpRequest._FULLNAME, this._fullName);

    this._fullName = val;

    this.fire("fullName", this);
  }
  public get email(): string {
    return this._email;
  }
  public setEmail(val: string): void {
    let isValChanged: boolean = this._email !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SignUpRequest._EMAIL, this._email);

    this._email = val;

    this.fire("email", this);
  }
  public get password(): string {
    return this._password;
  }
  public setPassword(val: string): void {
    let isValChanged: boolean = this._password !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SignUpRequest._PASSWORD, this._password);

    this._password = val;

    this.fire("password", this);
  }
  public get(field: number): any {
    switch (field) {
      case SignUpRequest._FULLNAME: {
        return this._fullName;
      }

      case SignUpRequest._EMAIL: {
        return this._email;
      }

      case SignUpRequest._PASSWORD: {
        return this._password;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): SignUpRequest {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: SignUpRequest = dbObj as SignUpRequest;

    obj.id = this.id;

    obj.setFullName(this._fullName);

    obj.setEmail(this._email);

    obj.setPassword(this._password);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case SignUpRequest._FULLNAME: {
        this.setFullName(value as string);
        break;
      }

      case SignUpRequest._EMAIL: {
        this.setEmail(value as string);
        break;
      }

      case SignUpRequest._PASSWORD: {
        this.setPassword(value as string);
        break;
      }
    }
  }
}
