import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import RenterProfileView from "./RenterProfileView";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import Renter from "../models/Renter";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

type _RentalSideMenuViewOnSelectedMenu = (value: string) => void;

type _DashboardButtonOnPressed = (d3eState: RentalSideMenuViewRefs) => void;

type _CalendarButtonOnPressed = (d3eState: RentalSideMenuViewRefs) => void;

type _PaymentsButtonOnPressed = (d3eState: RentalSideMenuViewRefs) => void;

type _CurrentInvoiceButtonOnPressed = (
  d3eState: RentalSideMenuViewRefs
) => void;

type _PaymentHistoryButtonOnPressed = (
  d3eState: RentalSideMenuViewRefs
) => void;

type _PaymentMethodButtonOnPressed = (d3eState: RentalSideMenuViewRefs) => void;

type _DocumentsButtonOnPressed = (d3eState: RentalSideMenuViewRefs) => void;

type _InsuranceButtonOnPressed = (d3eState: RentalSideMenuViewRefs) => void;

type _ReservationsButtonOnPressed = (d3eState: RentalSideMenuViewRefs) => void;

export interface RentalSideMenuViewProps extends BaseUIProps {
  key?: string;
  renter: Renter;
  onSelectedMenu?: _RentalSideMenuViewOnSelectedMenu;
}
/// To store state data for RentalSideMenuView
class RentalSideMenuViewRefs {
  public calendarButton: CalendarButtonState = new CalendarButtonState();
  public currentInvoiceButton: CurrentInvoiceButtonState =
    new CurrentInvoiceButtonState();
  public dashboardButton: DashboardButtonState = new DashboardButtonState();
  public documentsButton: DocumentsButtonState = new DocumentsButtonState();
  public insuranceButton: InsuranceButtonState = new InsuranceButtonState();
  public paymentHistoryButton: PaymentHistoryButtonState =
    new PaymentHistoryButtonState();
  public paymentMethodButton: PaymentMethodButtonState =
    new PaymentMethodButtonState();
  public paymentsButton: PaymentsButtonState = new PaymentsButtonState();
  public reservationsButton: ReservationsButtonState =
    new ReservationsButtonState();
}

interface ReservationsButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalSideMenuViewRefs;
  _onPressedReservationsButtonHandler?: _ReservationsButtonOnPressed;
  selectedMenu: string;
}

class ReservationsButtonState extends ObjectObservable {
  private _hover: boolean = false;
  private _disable: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _ReservationsButtonWithState extends ObservableComponent<ReservationsButtonWithStateProps> {
  reservationsButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ReservationsButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get reservationsButton(): ReservationsButtonState {
    return this.props.d3eState.reservationsButton;
  }
  public get selectedMenu(): string {
    return this.props.selectedMenu;
  }
  public get d3eState(): RentalSideMenuViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedReservationsButtonHandler(): _ReservationsButtonOnPressed {
    return this.props._onPressedReservationsButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("reservationsButton", null, this.reservationsButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["reservationsButton", "reservationsButton.hover", "selectedMenu"],
      this.rebuild
    );
  }
  public reservationsButtonOnEnter(event): void {
    return this.reservationsButton.setHover(true);
  }
  public reservationsButtonOnExit(event): void {
    return this.reservationsButton.setHover(false);
  }
  public dispose(): void {
    this.reservationsButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 0.0, 10.0, new Map()),
      child: Button({
        padding: ui.EdgeInsets.zero,
        decoration:
          this.selectedMenu == "Reservations"
            ? new ui.BoxDecoration({
                color: cStyle.c37,
                border: ui.Border.all({ color: cStyle.c37, width: 0.0 }),
              })
            : this.reservationsButton.hover
            ? new ui.BoxDecoration({ color: cStyle.c37 })
            : new ui.BoxDecoration({
                color: new ui.Color(0x0),
                border: ui.Border.all({ color: new ui.Color(0x0), width: 0.0 }),
                borderRadius: ui.BorderRadius.circular(3.0),
              }),
        disable: this.reservationsButton.disable,
        onPressed: () => {
          this._onPressedReservationsButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          children: [
            IconView({
              icon: MaterialIcons.domain_verification,
              size: 24,
              color:
                this.selectedMenu == "Reservations"
                  ? cStyle.c20
                  : this.reservationsButton.hover
                  ? cStyle.c20
                  : cStyle.c20,
              states: ui.joinStates(
                {
                  "data-c0": this.selectedMenu == "Reservations",
                  "data-c1": this.reservationsButton.hover,
                },
                {}
              ),
              className: "x209",
              key: "0",
            }),
            TextView({
              data: "Reservations",
              softWrap: true,
              states: ui.joinStates(
                {
                  "data-c0": this.selectedMenu == "Reservations",
                  "data-c1": this.reservationsButton.hover,
                },
                {}
              ),
              style: new ui.TextStyle({
                color:
                  this.selectedMenu == "Reservations"
                    ? cStyle.c20
                    : this.reservationsButton.hover
                    ? cStyle.c20
                    : cStyle.c20,
              }),
              className: "x37d",
              key: "1",
            }),
          ],
          className: "x189 hc h",
        }),
        onEnter: (event) => {
          this.reservationsButtonOnEnter(event);
        },
        onExit: (event) => {
          this.reservationsButtonOnExit(event);
        },
      }),
      className: "x6eb hc h",
    });
  }
}
function ReservationsButtonWithState(props: ReservationsButtonWithStateProps) {
  return React.createElement(_ReservationsButtonWithState, props);
}

interface InsuranceButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalSideMenuViewRefs;
  _onPressedInsuranceButtonHandler?: _InsuranceButtonOnPressed;
  selectedMenu: string;
}

class InsuranceButtonState extends ObjectObservable {
  private _hover: boolean = false;
  private _disable: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _InsuranceButtonWithState extends ObservableComponent<InsuranceButtonWithStateProps> {
  insuranceButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: InsuranceButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get insuranceButton(): InsuranceButtonState {
    return this.props.d3eState.insuranceButton;
  }
  public get selectedMenu(): string {
    return this.props.selectedMenu;
  }
  public get d3eState(): RentalSideMenuViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedInsuranceButtonHandler(): _InsuranceButtonOnPressed {
    return this.props._onPressedInsuranceButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("insuranceButton", null, this.insuranceButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["insuranceButton", "insuranceButton.hover", "selectedMenu"],
      this.rebuild
    );
  }
  public insuranceButtonOnEnter(event): void {
    return this.insuranceButton.setHover(true);
  }
  public insuranceButtonOnExit(event): void {
    return this.insuranceButton.setHover(false);
  }
  public dispose(): void {
    this.insuranceButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 0.0, 10.0, new Map()),
      child: Button({
        padding: ui.EdgeInsets.zero,
        decoration:
          this.selectedMenu == "Insurance"
            ? new ui.BoxDecoration({
                color: cStyle.c37,
                border: ui.Border.all({ color: cStyle.c37, width: 0.0 }),
              })
            : this.insuranceButton.hover
            ? new ui.BoxDecoration({ color: cStyle.c37 })
            : new ui.BoxDecoration({
                color: new ui.Color(0x0),
                border: ui.Border.all({ color: new ui.Color(0x0), width: 0.0 }),
                borderRadius: ui.BorderRadius.circular(3.0),
              }),
        disable: this.insuranceButton.disable,
        onPressed: () => {
          this._onPressedInsuranceButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          children: [
            IconView({
              icon: MaterialIcons.add_moderator,
              size: 24,
              color:
                this.selectedMenu == "Insurance"
                  ? cStyle.c20
                  : this.insuranceButton.hover
                  ? cStyle.c20
                  : cStyle.c20,
              states: ui.joinStates(
                {
                  "data-c0": this.selectedMenu == "Insurance",
                  "data-c1": this.insuranceButton.hover,
                },
                {}
              ),
              className: "x746",
              key: "0",
            }),
            TextView({
              data: "Insurance",
              softWrap: true,
              states: ui.joinStates(
                {
                  "data-c0": this.selectedMenu == "Insurance",
                  "data-c1": this.insuranceButton.hover,
                },
                {}
              ),
              style: new ui.TextStyle({
                color:
                  this.selectedMenu == "Insurance"
                    ? cStyle.c20
                    : this.insuranceButton.hover
                    ? cStyle.c20
                    : cStyle.c20,
              }),
              className: "x2c4",
              key: "1",
            }),
          ],
          className: "x5986 hc h",
        }),
        onEnter: (event) => {
          this.insuranceButtonOnEnter(event);
        },
        onExit: (event) => {
          this.insuranceButtonOnExit(event);
        },
      }),
      className: "x15e hc h",
    });
  }
}
function InsuranceButtonWithState(props: InsuranceButtonWithStateProps) {
  return React.createElement(_InsuranceButtonWithState, props);
}

interface DocumentsButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalSideMenuViewRefs;
  _onPressedDocumentsButtonHandler?: _DocumentsButtonOnPressed;
  selectedMenu: string;
}

class DocumentsButtonState extends ObjectObservable {
  private _hover: boolean = false;
  private _disable: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _DocumentsButtonWithState extends ObservableComponent<DocumentsButtonWithStateProps> {
  documentsButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: DocumentsButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get documentsButton(): DocumentsButtonState {
    return this.props.d3eState.documentsButton;
  }
  public get selectedMenu(): string {
    return this.props.selectedMenu;
  }
  public get d3eState(): RentalSideMenuViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedDocumentsButtonHandler(): _DocumentsButtonOnPressed {
    return this.props._onPressedDocumentsButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("documentsButton", null, this.documentsButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["documentsButton", "documentsButton.hover", "selectedMenu"],
      this.rebuild
    );
  }
  public documentsButtonOnEnter(event): void {
    return this.documentsButton.setHover(true);
  }
  public documentsButtonOnExit(event): void {
    return this.documentsButton.setHover(false);
  }
  public dispose(): void {
    this.documentsButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 0.0, 10.0, new Map()),
      child: Button({
        padding: ui.EdgeInsets.zero,
        decoration:
          this.selectedMenu == "Document"
            ? new ui.BoxDecoration({
                color: cStyle.c37,
                border: ui.Border.all({ color: cStyle.c37, width: 0.0 }),
              })
            : this.documentsButton.hover
            ? new ui.BoxDecoration({ color: cStyle.c37 })
            : new ui.BoxDecoration({
                color: new ui.Color(0x0),
                border: ui.Border.all({ color: new ui.Color(0x0), width: 0.0 }),
                borderRadius: ui.BorderRadius.circular(3.0),
              }),
        disable: this.documentsButton.disable,
        onPressed: () => {
          this._onPressedDocumentsButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          children: [
            IconView({
              icon: MaterialIcons.description,
              size: 24,
              color:
                this.selectedMenu == "Document"
                  ? cStyle.c20
                  : this.documentsButton.hover
                  ? cStyle.c20
                  : cStyle.c20,
              states: ui.joinStates(
                {
                  "data-c0": this.selectedMenu == "Document",
                  "data-c1": this.documentsButton.hover,
                },
                {}
              ),
              className: "x84e",
              key: "0",
            }),
            TextView({
              data: "Documents",
              softWrap: true,
              states: ui.joinStates(
                {
                  "data-c0": this.selectedMenu == "Document",
                  "data-c1": this.documentsButton.hover,
                },
                {}
              ),
              style: new ui.TextStyle({
                color:
                  this.selectedMenu == "Document"
                    ? cStyle.c20
                    : this.documentsButton.hover
                    ? cStyle.c20
                    : cStyle.c20,
              }),
              className: "x920",
              key: "1",
            }),
          ],
          className: "x488 hc h",
        }),
        onEnter: (event) => {
          this.documentsButtonOnEnter(event);
        },
        onExit: (event) => {
          this.documentsButtonOnExit(event);
        },
      }),
      className: "xc07 hc h",
    });
  }
}
function DocumentsButtonWithState(props: DocumentsButtonWithStateProps) {
  return React.createElement(_DocumentsButtonWithState, props);
}

interface PaymentMethodButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalSideMenuViewRefs;
  _onPressedPaymentMethodButtonHandler?: _PaymentMethodButtonOnPressed;
  selectedMenu: string;
}

class PaymentMethodButtonState extends ObjectObservable {
  private _hover: boolean = false;
  private _disable: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _PaymentMethodButtonWithState extends ObservableComponent<PaymentMethodButtonWithStateProps> {
  paymentMethodButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: PaymentMethodButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get paymentMethodButton(): PaymentMethodButtonState {
    return this.props.d3eState.paymentMethodButton;
  }
  public get selectedMenu(): string {
    return this.props.selectedMenu;
  }
  public get d3eState(): RentalSideMenuViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedPaymentMethodButtonHandler(): _PaymentMethodButtonOnPressed {
    return this.props._onPressedPaymentMethodButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable(
      "paymentMethodButton",
      null,
      this.paymentMethodButton
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["paymentMethodButton", "paymentMethodButton.hover", "selectedMenu"],
      this.rebuild
    );
  }
  public paymentMethodButtonOnEnter(event): void {
    return this.paymentMethodButton.setHover(true);
  }
  public paymentMethodButtonOnExit(event): void {
    return this.paymentMethodButton.setHover(false);
  }
  public dispose(): void {
    this.paymentMethodButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 0.0, 10.0, new Map()),
      child: Button({
        padding: ui.EdgeInsets.zero,
        decoration:
          this.selectedMenu == "Payment Method"
            ? new ui.BoxDecoration({
                color: cStyle.c37,
                border: ui.Border.all({ color: cStyle.c37, width: 0.0 }),
              })
            : this.paymentMethodButton.hover
            ? new ui.BoxDecoration({ color: cStyle.c37 })
            : new ui.BoxDecoration({
                color: new ui.Color(0x0),
                border: ui.Border.all({ color: new ui.Color(0x0), width: 0.0 }),
                borderRadius: ui.BorderRadius.circular(3.0),
              }),
        disable: this.paymentMethodButton.disable,
        onPressed: () => {
          this._onPressedPaymentMethodButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          children: [
            TextView({
              data: "Payment Method",
              softWrap: true,
              states: ui.joinStates(
                {
                  "data-c0": this.selectedMenu == "Payment Method",
                  "data-c1": this.paymentMethodButton.hover,
                },
                {}
              ),
              style: new ui.TextStyle({
                color:
                  this.selectedMenu == "Payment Method"
                    ? cStyle.c20
                    : this.paymentMethodButton.hover
                    ? cStyle.c20
                    : cStyle.c20,
              }),
              className: "x5941",
              key: "0",
            }),
          ],
          className: "x4a5 hc h",
        }),
        onEnter: (event) => {
          this.paymentMethodButtonOnEnter(event);
        },
        onExit: (event) => {
          this.paymentMethodButtonOnExit(event);
        },
      }),
      className: "x05c hc h",
    });
  }
}
function PaymentMethodButtonWithState(
  props: PaymentMethodButtonWithStateProps
) {
  return React.createElement(_PaymentMethodButtonWithState, props);
}

interface PaymentHistoryButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalSideMenuViewRefs;
  _onPressedPaymentHistoryButtonHandler?: _PaymentHistoryButtonOnPressed;
  selectedMenu: string;
}

class PaymentHistoryButtonState extends ObjectObservable {
  private _hover: boolean = false;
  private _disable: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _PaymentHistoryButtonWithState extends ObservableComponent<PaymentHistoryButtonWithStateProps> {
  paymentHistoryButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: PaymentHistoryButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get paymentHistoryButton(): PaymentHistoryButtonState {
    return this.props.d3eState.paymentHistoryButton;
  }
  public get selectedMenu(): string {
    return this.props.selectedMenu;
  }
  public get d3eState(): RentalSideMenuViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedPaymentHistoryButtonHandler(): _PaymentHistoryButtonOnPressed {
    return this.props._onPressedPaymentHistoryButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable(
      "paymentHistoryButton",
      null,
      this.paymentHistoryButton
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["paymentHistoryButton", "paymentHistoryButton.hover", "selectedMenu"],
      this.rebuild
    );
  }
  public paymentHistoryButtonOnEnter(event): void {
    return this.paymentHistoryButton.setHover(true);
  }
  public paymentHistoryButtonOnExit(event): void {
    return this.paymentHistoryButton.setHover(false);
  }
  public dispose(): void {
    this.paymentHistoryButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 0.0, 10.0, new Map()),
      child: Button({
        padding: ui.EdgeInsets.zero,
        decoration:
          this.selectedMenu == "Payment History"
            ? new ui.BoxDecoration({
                color: cStyle.c37,
                border: ui.Border.all({ color: cStyle.c37, width: 0.0 }),
              })
            : this.paymentHistoryButton.hover
            ? new ui.BoxDecoration({ color: cStyle.c37 })
            : new ui.BoxDecoration({
                color: new ui.Color(0x0),
                border: ui.Border.all({ color: new ui.Color(0x0), width: 0.0 }),
                borderRadius: ui.BorderRadius.circular(3.0),
              }),
        disable: this.paymentHistoryButton.disable,
        onPressed: () => {
          this._onPressedPaymentHistoryButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          children: [
            TextView({
              data: "Payment History",
              softWrap: true,
              states: ui.joinStates(
                {
                  "data-c0": this.selectedMenu == "Payment History",
                  "data-c1": this.paymentHistoryButton.hover,
                },
                {}
              ),
              style: new ui.TextStyle({
                color:
                  this.selectedMenu == "Payment History"
                    ? cStyle.c20
                    : this.paymentHistoryButton.hover
                    ? cStyle.c20
                    : cStyle.c20,
              }),
              className: "x041",
              key: "0",
            }),
          ],
          className: "x7bd hc h",
        }),
        onEnter: (event) => {
          this.paymentHistoryButtonOnEnter(event);
        },
        onExit: (event) => {
          this.paymentHistoryButtonOnExit(event);
        },
      }),
      className: "x784 hc h",
    });
  }
}
function PaymentHistoryButtonWithState(
  props: PaymentHistoryButtonWithStateProps
) {
  return React.createElement(_PaymentHistoryButtonWithState, props);
}

interface CurrentInvoiceButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalSideMenuViewRefs;
  _onPressedCurrentInvoiceButtonHandler?: _CurrentInvoiceButtonOnPressed;
  selectedMenu: string;
}

class CurrentInvoiceButtonState extends ObjectObservable {
  private _hover: boolean = false;
  private _disable: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _CurrentInvoiceButtonWithState extends ObservableComponent<CurrentInvoiceButtonWithStateProps> {
  currentInvoiceButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CurrentInvoiceButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get currentInvoiceButton(): CurrentInvoiceButtonState {
    return this.props.d3eState.currentInvoiceButton;
  }
  public get selectedMenu(): string {
    return this.props.selectedMenu;
  }
  public get d3eState(): RentalSideMenuViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedCurrentInvoiceButtonHandler(): _CurrentInvoiceButtonOnPressed {
    return this.props._onPressedCurrentInvoiceButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable(
      "currentInvoiceButton",
      null,
      this.currentInvoiceButton
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["currentInvoiceButton", "currentInvoiceButton.hover", "selectedMenu"],
      this.rebuild
    );
  }
  public currentInvoiceButtonOnEnter(event): void {
    return this.currentInvoiceButton.setHover(true);
  }
  public currentInvoiceButtonOnExit(event): void {
    return this.currentInvoiceButton.setHover(false);
  }
  public dispose(): void {
    this.currentInvoiceButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 0.0, 10.0, new Map()),
      child: Button({
        padding: ui.EdgeInsets.zero,
        decoration:
          this.selectedMenu == "CurrentInvoice"
            ? new ui.BoxDecoration({
                color: cStyle.c37,
                border: ui.Border.all({ color: cStyle.c37, width: 0.0 }),
              })
            : this.currentInvoiceButton.hover
            ? new ui.BoxDecoration({ color: cStyle.c37 })
            : new ui.BoxDecoration({
                color: new ui.Color(0x0),
                border: ui.Border.all({ color: new ui.Color(0x0), width: 0.0 }),
                borderRadius: ui.BorderRadius.circular(3.0),
              }),
        disable: this.currentInvoiceButton.disable,
        onPressed: () => {
          this._onPressedCurrentInvoiceButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          children: [
            TextView({
              data: "Current Invoice",
              softWrap: true,
              states: ui.joinStates(
                {
                  "data-c0": this.selectedMenu == "Payments",
                  "data-c1": this.currentInvoiceButton.hover,
                },
                {}
              ),
              style: new ui.TextStyle({
                color:
                  this.selectedMenu == "Payments"
                    ? cStyle.c20
                    : this.currentInvoiceButton.hover
                    ? cStyle.c20
                    : cStyle.c20,
              }),
              className: "x490",
              key: "0",
            }),
          ],
          className: "x48d hc h",
        }),
        onEnter: (event) => {
          this.currentInvoiceButtonOnEnter(event);
        },
        onExit: (event) => {
          this.currentInvoiceButtonOnExit(event);
        },
      }),
      className: "xef hc h",
    });
  }
}
function CurrentInvoiceButtonWithState(
  props: CurrentInvoiceButtonWithStateProps
) {
  return React.createElement(_CurrentInvoiceButtonWithState, props);
}

interface PaymentsButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalSideMenuViewRefs;
  _onPressedPaymentsButtonHandler?: _PaymentsButtonOnPressed;
  selectedMenu: string;
}

class PaymentsButtonState extends ObjectObservable {
  private _hover: boolean = false;
  private _disable: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _PaymentsButtonWithState extends ObservableComponent<PaymentsButtonWithStateProps> {
  paymentsButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: PaymentsButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get paymentsButton(): PaymentsButtonState {
    return this.props.d3eState.paymentsButton;
  }
  public get selectedMenu(): string {
    return this.props.selectedMenu;
  }
  public get d3eState(): RentalSideMenuViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedPaymentsButtonHandler(): _PaymentsButtonOnPressed {
    return this.props._onPressedPaymentsButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("paymentsButton", null, this.paymentsButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["paymentsButton", "paymentsButton.hover", "selectedMenu"],
      this.rebuild
    );
  }
  public paymentsButtonOnEnter(event): void {
    return this.paymentsButton.setHover(true);
  }
  public paymentsButtonOnExit(event): void {
    return this.paymentsButton.setHover(false);
  }
  public dispose(): void {
    this.paymentsButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 0.0, 10.0, new Map()),
      child: Button({
        padding: ui.EdgeInsets.zero,
        decoration:
          this.selectedMenu == "Payment"
            ? new ui.BoxDecoration({
                color: cStyle.c37,
                border: ui.Border.all({ color: cStyle.c37, width: 0.0 }),
              })
            : this.paymentsButton.hover
            ? new ui.BoxDecoration({ color: cStyle.c37 })
            : new ui.BoxDecoration({
                color: new ui.Color(0x0),
                border: ui.Border.all({ color: new ui.Color(0x0), width: 0.0 }),
                borderRadius: ui.BorderRadius.circular(3.0),
              }),
        disable: this.paymentsButton.disable,
        onPressed: () => {
          this._onPressedPaymentsButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          children: [
            IconView({
              icon: MaterialIcons.monetization_on,
              size: 24,
              color:
                this.selectedMenu == "Payments"
                  ? cStyle.c20
                  : this.paymentsButton.hover
                  ? cStyle.c20
                  : cStyle.c20,
              states: ui.joinStates(
                {
                  "data-c0": this.selectedMenu == "Payments",
                  "data-c1": this.paymentsButton.hover,
                },
                {}
              ),
              className: "xfd0",
              key: "0",
            }),
            TextView({
              data: "Payments",
              softWrap: true,
              states: ui.joinStates(
                {
                  "data-c0": this.selectedMenu == "Payments",
                  "data-c1": this.paymentsButton.hover,
                },
                {}
              ),
              style: new ui.TextStyle({
                color:
                  this.selectedMenu == "Payments"
                    ? cStyle.c20
                    : this.paymentsButton.hover
                    ? cStyle.c20
                    : cStyle.c20,
              }),
              className: "x332",
              key: "1",
            }),
          ],
          className: "x43aa hc h",
        }),
        onEnter: (event) => {
          this.paymentsButtonOnEnter(event);
        },
        onExit: (event) => {
          this.paymentsButtonOnExit(event);
        },
      }),
      className: "xe79 hc h",
    });
  }
}
function PaymentsButtonWithState(props: PaymentsButtonWithStateProps) {
  return React.createElement(_PaymentsButtonWithState, props);
}

interface CalendarButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalSideMenuViewRefs;
  _onPressedCalendarButtonHandler?: _CalendarButtonOnPressed;
  selectedMenu: string;
}

class CalendarButtonState extends ObjectObservable {
  private _hover: boolean = false;
  private _disable: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _CalendarButtonWithState extends ObservableComponent<CalendarButtonWithStateProps> {
  calendarButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CalendarButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get calendarButton(): CalendarButtonState {
    return this.props.d3eState.calendarButton;
  }
  public get selectedMenu(): string {
    return this.props.selectedMenu;
  }
  public get d3eState(): RentalSideMenuViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedCalendarButtonHandler(): _CalendarButtonOnPressed {
    return this.props._onPressedCalendarButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("calendarButton", null, this.calendarButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["calendarButton", "calendarButton.hover", "selectedMenu"],
      this.rebuild
    );
  }
  public calendarButtonOnEnter(event): void {
    return this.calendarButton.setHover(true);
  }
  public calendarButtonOnExit(event): void {
    return this.calendarButton.setHover(false);
  }
  public dispose(): void {
    this.calendarButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 0.0, 10.0, new Map()),
      child: Button({
        padding: ui.EdgeInsets.zero,
        decoration:
          this.selectedMenu == "Calendar"
            ? new ui.BoxDecoration({
                color: cStyle.c37,
                border: ui.Border.all({ color: cStyle.c37, width: 0.0 }),
              })
            : this.calendarButton.hover
            ? new ui.BoxDecoration({ color: cStyle.c37 })
            : new ui.BoxDecoration({
                color: new ui.Color(0x0),
                border: ui.Border.all({ color: new ui.Color(0x0), width: 0.0 }),
                borderRadius: ui.BorderRadius.circular(3.0),
              }),
        disable: this.calendarButton.disable,
        onPressed: () => {
          this._onPressedCalendarButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          children: [
            IconView({
              icon: MaterialIcons.calendar_month,
              size: 24,
              color:
                this.selectedMenu == "Calendar"
                  ? cStyle.c20
                  : this.calendarButton.hover
                  ? cStyle.c20
                  : cStyle.c20,
              states: ui.joinStates(
                {
                  "data-c0": this.selectedMenu == "Calendar",
                  "data-c1": this.calendarButton.hover,
                },
                {}
              ),
              className: "xd82",
              key: "0",
            }),
            TextView({
              data: "Calendar",
              softWrap: true,
              states: ui.joinStates(
                {
                  "data-c0": this.selectedMenu == "Calendar",
                  "data-c1": this.calendarButton.hover,
                },
                {}
              ),
              style: new ui.TextStyle({
                color:
                  this.selectedMenu == "Calendar"
                    ? cStyle.c20
                    : this.calendarButton.hover
                    ? cStyle.c20
                    : cStyle.c20,
              }),
              className: "x68e",
              key: "1",
            }),
          ],
          className: "x526 hc h",
        }),
        onEnter: (event) => {
          this.calendarButtonOnEnter(event);
        },
        onExit: (event) => {
          this.calendarButtonOnExit(event);
        },
      }),
      className: "x576 hc h",
    });
  }
}
function CalendarButtonWithState(props: CalendarButtonWithStateProps) {
  return React.createElement(_CalendarButtonWithState, props);
}

interface DashboardButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalSideMenuViewRefs;
  _onPressedDashboardButtonHandler?: _DashboardButtonOnPressed;
  selectedMenu: string;
}

class DashboardButtonState extends ObjectObservable {
  private _hover: boolean = false;
  private _disable: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _DashboardButtonWithState extends ObservableComponent<DashboardButtonWithStateProps> {
  dashboardButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: DashboardButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get dashboardButton(): DashboardButtonState {
    return this.props.d3eState.dashboardButton;
  }
  public get selectedMenu(): string {
    return this.props.selectedMenu;
  }
  public get d3eState(): RentalSideMenuViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedDashboardButtonHandler(): _DashboardButtonOnPressed {
    return this.props._onPressedDashboardButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("dashboardButton", null, this.dashboardButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["dashboardButton", "dashboardButton.hover", "selectedMenu"],
      this.rebuild
    );
  }
  public dashboardButtonOnEnter(event): void {
    return this.dashboardButton.setHover(true);
  }
  public dashboardButtonOnExit(event): void {
    return this.dashboardButton.setHover(false);
  }
  public dispose(): void {
    this.dashboardButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 0.0, 10.0, new Map()),
      child: Button({
        padding: ui.EdgeInsets.zero,
        decoration:
          this.selectedMenu == "DashBoard"
            ? new ui.BoxDecoration({
                color: cStyle.c37,
                border: ui.Border.all({ color: cStyle.c37, width: 0.0 }),
              })
            : this.dashboardButton.hover
            ? new ui.BoxDecoration({ color: cStyle.c37 })
            : new ui.BoxDecoration({
                color: new ui.Color(0x0),
                border: ui.Border.all({ color: new ui.Color(0x0), width: 0.0 }),
                borderRadius: ui.BorderRadius.circular(3.0),
              }),
        disable: this.dashboardButton.disable,
        onPressed: () => {
          this._onPressedDashboardButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          children: [
            IconView({
              icon: MaterialIcons.dashboard,
              size: 24,
              color:
                this.selectedMenu == "DashBoard"
                  ? cStyle.c20
                  : this.dashboardButton.hover
                  ? cStyle.c20
                  : cStyle.c20,
              states: ui.joinStates(
                {
                  "data-c0": this.selectedMenu == "DashBoard",
                  "data-c1": this.dashboardButton.hover,
                },
                {}
              ),
              className: "x7879",
              key: "0",
            }),
            TextView({
              data: "Dashboard",
              softWrap: true,
              states: ui.joinStates(
                {
                  "data-c0": this.selectedMenu == "DashBoard",
                  "data-c1": this.dashboardButton.hover,
                },
                {}
              ),
              style: new ui.TextStyle({
                color:
                  this.selectedMenu == "DashBoard"
                    ? cStyle.c20
                    : this.dashboardButton.hover
                    ? cStyle.c20
                    : cStyle.c20,
              }),
              className: "x021",
              key: "1",
            }),
          ],
          className: "xd27 hc h",
        }),
        onEnter: (event) => {
          this.dashboardButtonOnEnter(event);
        },
        onExit: (event) => {
          this.dashboardButtonOnExit(event);
        },
      }),
      className: "x855 hc h",
    });
  }
}
function DashboardButtonWithState(props: DashboardButtonWithStateProps) {
  return React.createElement(_DashboardButtonWithState, props);
}

class _RentalSideMenuViewState extends ObservableComponent<RentalSideMenuViewProps> {
  static defaultProps = { renter: null, onSelectedMenu: null };
  d3eState: RentalSideMenuViewRefs = new RentalSideMenuViewRefs();
  selectedMenu: string = "DashBoard";
  showPayments: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RentalSideMenuViewProps) {
    super(props);

    this.initState();
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("renter", this.props.renter);

    this.on(["renter", "selectedMenu", "showPayments"], this.rebuild);
  }
  public componentDidUpdate(prevProps: RentalSideMenuViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public setSelectedMenu(val: string): void {
    let isValChanged: boolean = this.selectedMenu !== val;

    if (!isValChanged) {
      return;
    }

    this.selectedMenu = val;

    this.fire("selectedMenu", this);
  }
  public setShowPayments(val: boolean): void {
    let isValChanged: boolean = this.showPayments !== val;

    if (!isValChanged) {
      return;
    }

    this.showPayments = val;

    this.fire("showPayments", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
              children: [
                ui.Column({
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    DashboardButtonWithState({
                      d3eState: this.d3eState,
                      _onPressedDashboardButtonHandler:
                        this.onPressedDashboardButtonHandler,
                      selectedMenu: this.selectedMenu,
                      key: "0",
                    }),
                    CalendarButtonWithState({
                      d3eState: this.d3eState,
                      _onPressedCalendarButtonHandler:
                        this.onPressedCalendarButtonHandler,
                      selectedMenu: this.selectedMenu,
                      key: "1",
                    }),
                    PaymentsButtonWithState({
                      d3eState: this.d3eState,
                      _onPressedPaymentsButtonHandler:
                        this.onPressedPaymentsButtonHandler,
                      selectedMenu: this.selectedMenu,
                      key: "2",
                    }),
                    this.showPayments
                      ? ui.Column({
                          crossAxisAlignment: ui.CrossAxisAlignment.start,
                          children: [
                            CurrentInvoiceButtonWithState({
                              d3eState: this.d3eState,
                              _onPressedCurrentInvoiceButtonHandler:
                                this.onPressedCurrentInvoiceButtonHandler,
                              selectedMenu: this.selectedMenu,
                              key: "0",
                            }),
                            PaymentHistoryButtonWithState({
                              d3eState: this.d3eState,
                              _onPressedPaymentHistoryButtonHandler:
                                this.onPressedPaymentHistoryButtonHandler,
                              selectedMenu: this.selectedMenu,
                              key: "1",
                            }),
                            PaymentMethodButtonWithState({
                              d3eState: this.d3eState,
                              _onPressedPaymentMethodButtonHandler:
                                this.onPressedPaymentMethodButtonHandler,
                              selectedMenu: this.selectedMenu,
                              key: "2",
                            }),
                          ],
                          className: "xc0f1 hc h",
                        })
                      : [],
                    DocumentsButtonWithState({
                      d3eState: this.d3eState,
                      _onPressedDocumentsButtonHandler:
                        this.onPressedDocumentsButtonHandler,
                      selectedMenu: this.selectedMenu,
                      key: "4",
                    }),
                    InsuranceButtonWithState({
                      d3eState: this.d3eState,
                      _onPressedInsuranceButtonHandler:
                        this.onPressedInsuranceButtonHandler,
                      selectedMenu: this.selectedMenu,
                      key: "5",
                    }),
                    ReservationsButtonWithState({
                      d3eState: this.d3eState,
                      _onPressedReservationsButtonHandler:
                        this.onPressedReservationsButtonHandler,
                      selectedMenu: this.selectedMenu,
                      key: "6",
                    }),
                  ],
                  className: "x352 hc h",
                  key: "0",
                }),
                ui.Column({
                  mainAxisAlignment: ui.MainAxisAlignment.center,
                  children: [
                    RenterProfileView({
                      renter: this.renter,
                      textColor: new ui.Color(0xff000000),
                      iconColor: new ui.Color(0xff000000),
                      cardBgColor: cStyle.c37,
                      className: "xe93 hc h",
                      key: "0",
                    }),
                  ],
                  className: "xd7e hc h",
                  key: "1",
                }),
              ],
              className: "x88d hc vc v",
              key: "0",
            }),
          ],
          className: "xf81 vc v",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "RentalSideMenuView xfc1 vc v"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressedDashboardButtonHandler = (
    d3eState: RentalSideMenuViewRefs
  ): void => {
    this.setSelectedMenu("Dashboard");

    this.onSelectedMenu(this.selectedMenu);
  };
  public onPressedReservationsButtonHandler = (
    d3eState: RentalSideMenuViewRefs
  ): void => {
    /*
Your code here. 
*/

    this.setSelectedMenu("Reservations");

    this.onSelectedMenu(this.selectedMenu);
  };
  public onPressedInsuranceButtonHandler = (
    d3eState: RentalSideMenuViewRefs
  ): void => {
    /*
Your code here. 
*/

    this.setSelectedMenu("Insurance");

    this.onSelectedMenu(this.selectedMenu);
  };
  public onPressedDocumentsButtonHandler = (
    d3eState: RentalSideMenuViewRefs
  ): void => {
    /*
Your code here. 
*/

    this.setSelectedMenu("Document");

    this.onSelectedMenu(this.selectedMenu);
  };
  public onPressedPaymentsButtonHandler = (
    d3eState: RentalSideMenuViewRefs
  ): void => {
    /*
Your code here. 
*/

    this.setSelectedMenu("Payments");

    this.setShowPayments(!this.showPayments);
  };
  public onPressedCalendarButtonHandler = (
    d3eState: RentalSideMenuViewRefs
  ): void => {
    /*
Your code here. 
*/

    this.setSelectedMenu("Calendar");

    this.onSelectedMenu(this.selectedMenu);
  };
  public onPressedCurrentInvoiceButtonHandler = (
    d3eState: RentalSideMenuViewRefs
  ): void => {
    /*
Your code here. 
*/

    this.setSelectedMenu("CurrentInvoice");

    this.onSelectedMenu(this.selectedMenu);
  };
  public onPressedPaymentHistoryButtonHandler = (
    d3eState: RentalSideMenuViewRefs
  ): void => {
    /*
Your code here. 
*/

    this.setSelectedMenu("Payment History");

    this.onSelectedMenu(this.selectedMenu);
  };
  public onPressedPaymentMethodButtonHandler = (
    d3eState: RentalSideMenuViewRefs
  ): void => {
    /*
Your code here. 
*/

    this.setSelectedMenu("Payment Method");

    this.onSelectedMenu(this.selectedMenu);
  };
  public get onSelectedMenu(): _RentalSideMenuViewOnSelectedMenu {
    return this.props.onSelectedMenu;
  }
  public get calendarButton() {
    return this.d3eState.calendarButton;
  }
  public get currentInvoiceButton() {
    return this.d3eState.currentInvoiceButton;
  }
  public get dashboardButton() {
    return this.d3eState.dashboardButton;
  }
  public get documentsButton() {
    return this.d3eState.documentsButton;
  }
  public get insuranceButton() {
    return this.d3eState.insuranceButton;
  }
  public get paymentHistoryButton() {
    return this.d3eState.paymentHistoryButton;
  }
  public get paymentMethodButton() {
    return this.d3eState.paymentMethodButton;
  }
  public get paymentsButton() {
    return this.d3eState.paymentsButton;
  }
  public get reservationsButton() {
    return this.d3eState.reservationsButton;
  }
}
export default function RentalSideMenuView(props: RentalSideMenuViewProps) {
  return React.createElement(_RentalSideMenuViewState, {
    ..._RentalSideMenuViewState.defaultProps,
    ...props,
  });
}
