import WSReader from "../rocket/WSReader";
import MessageDispatch from "../rocket/MessageDispatch";
import { RPCConstants } from "../rocket/D3ETemplate";

export default class PaymentServiceClient {
  public async createSessionLink(
    requestId: string,
    eventName: string,
    amount: number
  ): Promise<string> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.PaymentService,
      RPCConstants.PaymentServiceCreateSessionLink,
      {
        "args": (w) => {
          w.writeString(requestId);

          w.writeString(eventName);

          w.writeNumber(amount);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readString() as string;
  }
}
