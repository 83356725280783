import React, { ReactNode } from "react";
import { BorderRadius } from "../classes/BorderRadius";
import BaseUIProps from "./BaseUIProps";

interface ClipRRectProps extends BaseUIProps {
  borderRadius: BorderRadius;
  child: ReactNode;
}

class _ClipRRect extends React.Component<ClipRRectProps, {}> {
  render() {
    let style: any = {};
    Object.assign(style, {
      clipPath: "circle(50% at 50% 50%)",
    });
    return React.createElement(
      "ui-clip-r-rect",
      {
        style,
        ...this.props,
      },
      this.props.child
    );
  }
}

export default function ClipRRect(props: ClipRRectProps) {
  return React.createElement(_ClipRRect, props);
}
