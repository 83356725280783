import RequestStatus from "../classes/RequestStatus";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";
import RentalRequest from "./RentalRequest";

export default class RequestStatusUpdate extends DBObject {
  private static readonly _RENTALREQUEST: number = 0;
  private static readonly _STATUS: number = 1;
  public id: number = 0;
  public otherMaster: DBObject;
  private _rentalRequest: RentalRequest = null;
  private _status: RequestStatus = RequestStatus.Submitted;
  public constructor(
    d3eParams?: Partial<{ rentalRequest: RentalRequest; status: RequestStatus }>
  ) {
    super();

    this.setRentalRequest(d3eParams?.rentalRequest ?? null);

    this.setStatus(d3eParams?.status ?? RequestStatus.Submitted);
  }
  public get d3eType(): string {
    return "RequestStatusUpdate";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isTransientModel(): boolean {
    return;
  }
  public get rentalRequest(): RentalRequest {
    return this._rentalRequest;
  }
  public setRentalRequest(val: RentalRequest): void {
    let isValChanged: boolean = this._rentalRequest !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      RequestStatusUpdate._RENTALREQUEST,
      this._rentalRequest
    );

    this.updateObservable("rentalRequest", this._rentalRequest, val);

    this._rentalRequest = val;

    this.fire("rentalRequest", this);
  }
  public get status(): RequestStatus {
    return this._status;
  }
  public setStatus(val: RequestStatus): void {
    let isValChanged: boolean = this._status !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RequestStatusUpdate._STATUS, this._status.index);

    this._status = val;

    this.fire("status", this);
  }
  public get(field: number): any {
    switch (field) {
      case RequestStatusUpdate._RENTALREQUEST: {
        return this._rentalRequest;
      }

      case RequestStatusUpdate._STATUS: {
        return this._status.index;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): RequestStatusUpdate {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: RequestStatusUpdate = dbObj as RequestStatusUpdate;

    obj.id = this.id;

    obj.setRentalRequest(this._rentalRequest);

    obj.setStatus(this._status);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case RequestStatusUpdate._RENTALREQUEST: {
        this.setRentalRequest(value as RentalRequest);
        break;
      }

      case RequestStatusUpdate._STATUS: {
        this.setStatus(RequestStatus.values[value as number]);
        break;
      }
    }
  }
}
