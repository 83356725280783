import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import IconView from "./IconView";

export interface PolicesViewProps extends BaseUIProps {
  key?: string;
  policy?: string;
}

class _PolicesViewState extends ObservableComponent<PolicesViewProps> {
  static defaultProps = { policy: "" };
  public constructor(props: PolicesViewProps) {
    super(props);

    this.initState();
  }
  public get policy(): string {
    return this.props.policy;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["policy"], this.rebuild);
  }
  public componentDidUpdate(prevProps: PolicesViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.policy !== this.props.policy) {
      this.fire("policy", this);
    }
  }
  public render(): ReactNode {
    return ui.Row({
      mainAxisAlignment: ui.MainAxisAlignment.start,
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        IconView({
          icon: MaterialIcons.fiber_manual_record,
          size: 8,
          className: "x525",
          key: "0",
        }),
        TextView({
          data: this.policy,
          softWrap: true,
          className: "x541a",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "PolicesView x1935 hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function PolicesView(props: PolicesViewProps) {
  return React.createElement(_PolicesViewState, {
    ..._PolicesViewState.defaultProps,
    ...props,
  });
}
