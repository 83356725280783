import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import Range from "../classes/Range";
import CalendarHelper from "../classes/CalendarHelper";
import PopupTargetController from "./PopupTargetController";
import CalendarViewType from "../classes/CalendarViewType";
import D3EDate from "../classes/D3EDate";
import EventDetailsPopupView from "./EventDetailsPopupView";
import Popup from "./Popup";
import CalenderUtils from "../classes/CalenderUtils";
import ListWrapper from "../utils/ListWrapper";
import CalendarEvent from "../classes/CalendarEvent";
import TextView from "./TextView";
import DateBoxView from "./DateBoxView";
import CollectionUtils from "../utils/CollectionUtils";

type _MonthViewOnDateSelected = (date: D3EDate) => void;

type _MonthViewBuilder<CalendarEvent, CalendarViewType> = (
  context: any,
  event: CalendarEvent,
  viewType: CalendarViewType
) => ReactNode;

export interface MonthViewProps extends BaseUIProps {
  key?: string;
  helper: CalendarHelper;
  date: D3EDate;
  categories: Array<string>;
  _categoriesHash?: number;
  onDateSelected?: _MonthViewOnDateSelected;
  builder?: _MonthViewBuilder<CalendarEvent, CalendarViewType>;
}

class _MonthViewState extends ObservableComponent<MonthViewProps> {
  static defaultProps = {
    helper: null,
    date: null,
    categories: [],
    onDateSelected: null,
  };
  listOfDates: Array<D3EDate> = ListWrapper.widget(this, "listOfDates");
  weekNames: Array<string> = ListWrapper.widget(this, "weekNames");
  monthName: string = "";
  events: Array<CalendarEvent> = ListWrapper.widget(this, "events");
  selectedEvent: CalendarEvent = null;
  eventDetailsPopupPopup: Popup;
  public dateCell11PopupTargetController: PopupTargetController =
    new PopupTargetController();
  public constructor(props: MonthViewProps) {
    super(props);

    this.initState();
  }
  public get helper(): CalendarHelper {
    return this.props.helper;
  }
  public get date(): D3EDate {
    return this.props.date;
  }
  public get categories(): Array<string> {
    return this.props.categories;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.categories, "categories");

    this.on(["date"], this.computeListOfDates);

    this.computeListOfDates();

    this.computeWeekNames();

    this.on(["date"], this.computeMonthName);

    this.computeMonthName();

    this.on(["categories", "date", "helper"], this.computeEvents);

    this.computeEvents();

    this.on(
      ["categories", "date", "helper", "listOfDates", "monthName", "weekNames"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: MonthViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.helper !== this.props.helper) {
      this.fire("helper", this);
    }

    if (prevProps.date !== this.props.date) {
      this.fire("date", this);
    }

    if (prevProps.categories !== this.props.categories) {
      this.fire("categories", this);
    }
  }
  public setListOfDates(val: Array<D3EDate>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.listOfDates,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.listOfDates.clear();

    this.listOfDates.addAll(val);

    this.fire("listOfDates", this);
  }
  public addToListOfDates(val: D3EDate, index: number = -1): void {
    if (index === -1) {
      if (!this.listOfDates.contains(val)) this.listOfDates.add(val);
    } else {
      this.listOfDates.remove(this.listOfDates.elementAt(index));

      this.listOfDates.add(val);
    }

    this.fire("listOfDates", this, val, true);
  }
  public removeFromListOfDates(val: D3EDate): void {
    this.listOfDates.remove(val);

    this.fire("listOfDates", this, val, false);
  }
  public computeListOfDates = (): void => {
    try {
      this.setListOfDates(
        Array.from(CalenderUtils.prepareCalenderData(this.date))
      );
    } catch (exception) {
      console.log(" exception in computeListOfDates : " + exception.toString());

      this.setListOfDates([]);
    }
  };
  public setWeekNames(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.weekNames,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.weekNames.clear();

    this.weekNames.addAll(val);

    this.fire("weekNames", this);
  }
  public addToWeekNames(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.weekNames.contains(val)) this.weekNames.add(val);
    } else {
      this.weekNames.remove(this.weekNames.elementAt(index));

      this.weekNames.add(val);
    }

    this.fire("weekNames", this, val, true);
  }
  public removeFromWeekNames(val: string): void {
    this.weekNames.remove(val);

    this.fire("weekNames", this, val, false);
  }
  public computeWeekNames = (): void => {
    try {
      this.setWeekNames(
        Array.from(["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"])
      );
    } catch (exception) {
      console.log(" exception in computeWeekNames : " + exception.toString());

      this.setWeekNames([]);
    }
  };
  public setMonthName(val: string): void {
    let isValChanged: boolean = this.monthName !== val;

    if (!isValChanged) {
      return;
    }

    this.monthName = val;

    this.fire("monthName", this);
  }
  public computeMonthName = (): void => {
    try {
      this.setMonthName(CalenderUtils.getMonthName(this.date.month));
    } catch (exception) {
      console.log(" exception in computeMonthName : " + exception.toString());

      this.setMonthName("");
    }
  };
  public setEvents(val: Array<CalendarEvent>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.events, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("events", this.events, val);

    this.events.clear();

    this.events.addAll(val);

    this.fire("events", this);
  }
  public addToEvents(val: CalendarEvent, index: number = -1): void {
    if (index === -1) {
      if (!this.events.contains(val)) this.events.add(val);
    } else {
      this.events.remove(this.events.elementAt(index));

      this.events.add(val);
    }

    this.fire("events", this, val, true);

    this.updateObservable("events", null, val);
  }
  public removeFromEvents(val: CalendarEvent): void {
    this.events.remove(val);

    this.fire("events", this, val, false);

    this.removeObservable("events", val);
  }
  public computeEvents = (): void => {
    try {
      this.setEvents(
        Array.from(
          this.helper.getEventsForMonth(this.date.toDateTime(), this.categories)
        )
      );
    } catch (exception) {
      console.log(" exception in computeEvents : " + exception.toString());

      this.setEvents([]);
    }
  };
  public setSelectedEvent(val: CalendarEvent): void {
    let isValChanged: boolean = this.selectedEvent !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("selectedEvent", this.selectedEvent, val);

    this.selectedEvent = val;

    this.fire("selectedEvent", this);
  }
  public render(): ReactNode {
    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          children: [
            TextView({
              data: this.monthName,
              style: new ui.TextStyle({
                fontSize: 13,
                fontWeight: ui.FontWeight.bold,
              }),
              className: "x1a2",
              key: "0",
            }),
          ],
          className: "xd79 hc h",
          key: "0",
        }),
        ui.Table({
          border: new ui.TableBorder({
            top: new ui.BorderSide({
              color: new ui.Color(0xffced4da),
              width: 1,
              style: ui.BorderStyle.solid,
            }),
            bottom: new ui.BorderSide({
              color: new ui.Color(0xffced4da),
              width: 1,
              style: ui.BorderStyle.solid,
            }),
            left: new ui.BorderSide({
              color: new ui.Color(0xffced4da),
              width: 1,
              style: ui.BorderStyle.solid,
            }),
            right: new ui.BorderSide({
              color: new ui.Color(0xffced4da),
              width: 1,
              style: ui.BorderStyle.solid,
            }),
            verticalInside: new ui.BorderSide({
              color: new ui.Color(0xffced4da),
              width: 1,
              style: ui.BorderStyle.solid,
            }),
            horizontalInside: new ui.BorderSide({
              color: new ui.Color(0xffced4da),
              width: 1,
              style: ui.BorderStyle.solid,
            }),
          }),
          children: [
            ui.TableRow({
              children: [
                this.weekNames.expand((forItem) => [
                  ui.TableCell({
                    child: ui.Container({
                      child: ui.Center({
                        child: TextView({
                          data: forItem,
                          style: new ui.TextStyle({
                            fontSize: 14,
                            color: new ui.Color(0xffffffff),
                            fontWeight: ui.FontWeight.w500,
                          }),
                          className: "xb12 hc",
                        }),
                        className: "hc",
                      }),
                      className: "x708 hc",
                    }),
                    className: "x01e hc",
                    key: forItem?.toString(),
                  }),
                ]),
              ],
              className: "hc",
              key: "0",
            }),
            Range.to(6).expand((eachWeek) => [
              ui.TableRow({
                children: [
                  this.listOfDates
                    .sublist(eachWeek * 7, eachWeek * 7 + 7)
                    .expand((forItem2) => [
                      ui.TableCell({
                        child: DateBoxView({
                          date: forItem2,
                          month: this.date.month,
                          items: this.helper.getEventsForDate(
                            forItem2.toDateTime(),
                            this.categories
                          ),
                          pressedOnEvent: (event) => {
                            this.onPressedEvent(event, forItem2, eachWeek);
                          },
                          builder: (context, event, viewType) => {
                            return this.props.builder(
                              this.context,
                              event,
                              viewType
                            );
                          },
                          d3eRef:
                            this.dateCell11PopupTargetController.handleRef,
                          className: "hc",
                        }),
                        className: "hc",
                        key: forItem2?.toString(),
                      }),
                    ]),
                ],
                className: "hc",
                key: eachWeek?.toString(),
              }),
            ]),
          ],
          className: "x081 hc h",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "MonthView xa76 hc vc h v"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressedEvent = (
    event: CalendarEvent,
    forItem2: D3EDate,
    eachWeek: number
  ): void => {
    this.setSelectedEvent(event);

    this.showEventDetailsPopup({ autoClose: true });
  };
  public get onDateSelected(): _MonthViewOnDateSelected {
    return this.props.onDateSelected;
  }
  public dispose(): void {
    this.eventDetailsPopupPopup?.dispose();

    super.dispose();
  }
  public showEventDetailsPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.eventDetailsPopupPopup?.dispose();

    let target: ui.Rect = this.dateCell11PopupTargetController.getTarget(
      this.context
    );

    this.eventDetailsPopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Left,
      child: EventDetailsPopupView({
        event: this.selectedEvent,
        type: CalendarViewType.Month,
        builder: this.props.builder,
        className: "hc vc",
      }),
      target: target,
    });

    this.eventDetailsPopupPopup.showPopup(this.context);
  }
  public hideEventDetailsPopup(): void {
    this.eventDetailsPopupPopup?.dispose();
  }
}
export default function MonthView(props: MonthViewProps) {
  return React.createElement(
    _MonthViewState,
    { ..._MonthViewState.defaultProps, ...props },
    ListWrapper.fromInput<string>(props.categories, "categories")
  );
}
