import DateTime from "../core/DateTime";
import Renter from "./Renter";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";
import RentalRequest from "./RentalRequest";

export default class PaymentReceipt extends DBObject {
  private static readonly _AMOUNT: number = 0;
  private static readonly _CREATEDDATE: number = 1;
  private static readonly _RENTER: number = 2;
  private static readonly _REQUEST: number = 3;
  public id: number = 0;
  public otherMaster: DBObject;
  private _request: RentalRequest = null;
  private _renter: Renter = null;
  private _amount: number = 0.0;
  private _createdDate: DateTime = null;
  public constructor(
    d3eParams?: Partial<{
      amount: number;
      createdDate: DateTime;
      renter: Renter;
      request: RentalRequest;
    }>
  ) {
    super();

    this.setAmount(d3eParams?.amount ?? 0.0);

    this.setCreatedDate(d3eParams?.createdDate ?? null);

    this.setRenter(d3eParams?.renter ?? null);

    this.setRequest(d3eParams?.request ?? null);
  }
  public get d3eType(): string {
    return "PaymentReceipt";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get request(): RentalRequest {
    return this._request;
  }
  public setRequest(val: RentalRequest): void {
    let isValChanged: boolean = this._request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(PaymentReceipt._REQUEST, this._request);

    this.updateObservable("request", this._request, val);

    this._request = val;

    this.fire("request", this);
  }
  public get renter(): Renter {
    return this._renter;
  }
  public setRenter(val: Renter): void {
    let isValChanged: boolean = this._renter !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(PaymentReceipt._RENTER, this._renter);

    this.updateObservable("renter", this._renter, val);

    this._renter = val;

    this.fire("renter", this);
  }
  public get amount(): number {
    return this._amount;
  }
  public setAmount(val: number): void {
    let isValChanged: boolean = this._amount !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(PaymentReceipt._AMOUNT, this._amount);

    this._amount = val;

    this.fire("amount", this);
  }
  public get createdDate(): DateTime {
    return this._createdDate;
  }
  public setCreatedDate(val: DateTime): void {
    let isValChanged: boolean = this._createdDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(PaymentReceipt._CREATEDDATE, this._createdDate);

    this._createdDate = val;

    this.fire("createdDate", this);
  }
  public get(field: number): any {
    switch (field) {
      case PaymentReceipt._REQUEST: {
        return this._request;
      }

      case PaymentReceipt._RENTER: {
        return this._renter;
      }

      case PaymentReceipt._AMOUNT: {
        return this._amount;
      }

      case PaymentReceipt._CREATEDDATE: {
        return this._createdDate;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): PaymentReceipt {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: PaymentReceipt = dbObj as PaymentReceipt;

    obj.id = this.id;

    obj.setRequest(this._request);

    obj.setRenter(this._renter);

    obj.setAmount(this._amount);

    obj.setCreatedDate(this._createdDate);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case PaymentReceipt._REQUEST: {
        this.setRequest(value as RentalRequest);
        break;
      }

      case PaymentReceipt._RENTER: {
        this.setRenter(value as Renter);
        break;
      }

      case PaymentReceipt._AMOUNT: {
        this.setAmount(value as number);
        break;
      }

      case PaymentReceipt._CREATEDDATE: {
        this.setCreatedDate(value as DateTime);
        break;
      }
    }
  }
}
