import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ListWrapper from "../utils/ListWrapper";
import MouseHoverView from "./MouseHoverView";

type _DropDownPopupOnChanged<T> = (value: T) => void;

type _DropDownPopupBuilder<T> = (context: any, item: T) => ReactNode;

export interface DropDownPopupProps<T> extends BaseUIProps {
  key?: string;
  items: Array<T>;
  hoverColor?: ui.Color;
  currentItem: T;
  _itemsHash?: number;
  onChanged?: _DropDownPopupOnChanged<T>;
  builder?: _DropDownPopupBuilder<T>;
}

class _DropDownPopupState<T> extends ObservableComponent<
  DropDownPopupProps<T>
> {
  static defaultProps = {
    hoverColor: null,
    currentItem: null,
    items: [],
    onChanged: null,
  };
  id1Controller: ui.ScrollController = new ui.ScrollController();
  public constructor(props: DropDownPopupProps<T>) {
    super(props);

    this.initState();
  }
  public get items(): Array<T> {
    return this.props.items;
  }
  public get hoverColor(): ui.Color {
    return this.props.hoverColor;
  }
  public get currentItem(): T {
    return this.props.currentItem;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.items, "items");

    this.on(["currentItem", "hoverColor", "items"], this.rebuild);
  }
  public componentDidUpdate(prevProps: DropDownPopupProps<T>): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.items !== this.props.items) {
      this.fire("items", this);
    }

    if (prevProps.hoverColor !== this.props.hoverColor) {
      this.fire("hoverColor", this);
    }

    if (prevProps.currentItem !== this.props.currentItem) {
      this.fire("currentItem", this);
    }
  }
  public render(): ReactNode {
    return ui.ListView({
      controller: this.id1Controller,
      scrollDirection: ui.Axis.vertical,
      shrinkWrap: true,
      itemCount: this.items.length,
      itemBuilder: (context, index) => {
        return MouseHoverView({
          hoverColor: this.hoverColor,
          child: ui.Container({
            decoration: new ui.BoxDecoration({
              color:
                this.items[index] === this.currentItem ? this.hoverColor : null,
            }),
            child: this.props.builder(this.context, this.items[index]),
            className: "xbd2 hc",
          }),
          onTap: (e) => {
            e.stopPropagation();

            this.onSelectItem(index);
          },
          className: "hc",
          key: index.toString(),
        });
      },
      className: ui.join(this.props.className, "DropDownPopup hc vc"),
    });
  }
  public onSelectItem = (index: number): void => {
    this.onChanged(this.items[index]);
  };
  public get onChanged(): _DropDownPopupOnChanged<T> {
    return this.props.onChanged;
  }
}
export default function DropDownPopup<T>(props: DropDownPopupProps<T>) {
  return React.createElement(
    _DropDownPopupState<T>,
    { ..._DropDownPopupState.defaultProps, ...props },
    ListWrapper.fromInput<T>(props.items, "items")
  );
}
