import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import TextView from "./TextView";

export interface ToolTipMessageProps extends BaseUIProps {
  key?: string;
  message?: string;
  textColor?: ui.Color;
}

class _ToolTipMessageState extends ObservableComponent<ToolTipMessageProps> {
  static defaultProps = { message: "", textColor: null };
  public constructor(props: ToolTipMessageProps) {
    super(props);

    this.initState();
  }
  public get message(): string {
    return this.props.message;
  }
  public get textColor(): ui.Color {
    return this.props.textColor;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["message", "textColor"], this.rebuild);
  }
  public componentDidUpdate(prevProps: ToolTipMessageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.message !== this.props.message) {
      this.fire("message", this);
    }

    if (prevProps.textColor !== this.props.textColor) {
      this.fire("textColor", this);
    }
  }
  public render(): ReactNode {
    return TextView({
      data: this.message,
      style: new ui.TextStyle({ color: this.textColor }),
      className: ui.join(this.props.className, "ToolTipMessage xcd60 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function ToolTipMessage(props: ToolTipMessageProps) {
  return React.createElement(_ToolTipMessageState, {
    ..._ToolTipMessageState.defaultProps,
    ...props,
  });
}
