import School from "./School";
import D3EDate from "../classes/D3EDate";
import RequestStatus from "../classes/RequestStatus";
import Renter from "./Renter";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class RentalRequestsRequest extends DBObject {
  private static readonly _APPLYSTATUS: number = 0;
  private static readonly _FROMDATE: number = 1;
  private static readonly _OFFSET: number = 2;
  private static readonly _PAGESIZE: number = 3;
  private static readonly _RENTER: number = 4;
  private static readonly _SCHOOL: number = 5;
  private static readonly _STATUS: number = 6;
  private static readonly _TODATE: number = 7;
  public id: number = 0;
  public otherMaster: DBObject;
  private _applyStatus: boolean = false;
  private _status: RequestStatus = RequestStatus.Submitted;
  private _school: School = null;
  private _renter: Renter = null;
  private _fromDate: D3EDate = null;
  private _toDate: D3EDate = null;
  private _pageSize: number = 0;
  private _offset: number = 0;
  public constructor(
    d3eParams?: Partial<{
      applyStatus: boolean;
      fromDate: D3EDate;
      offset: number;
      pageSize: number;
      renter: Renter;
      school: School;
      status: RequestStatus;
      toDate: D3EDate;
    }>
  ) {
    super();

    this.setApplyStatus(d3eParams?.applyStatus ?? false);

    this.setFromDate(d3eParams?.fromDate ?? null);

    this.setOffset(d3eParams?.offset ?? 0);

    this.setPageSize(d3eParams?.pageSize ?? 0);

    this.setRenter(d3eParams?.renter ?? null);

    this.setSchool(d3eParams?.school ?? null);

    this.setStatus(d3eParams?.status ?? RequestStatus.Submitted);

    this.setToDate(d3eParams?.toDate ?? null);
  }
  public get d3eType(): string {
    return "RentalRequestsRequest";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isTransientModel(): boolean {
    return;
  }
  public get applyStatus(): boolean {
    return this._applyStatus;
  }
  public setApplyStatus(val: boolean): void {
    let isValChanged: boolean = this._applyStatus !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      RentalRequestsRequest._APPLYSTATUS,
      this._applyStatus
    );

    this._applyStatus = val;

    this.fire("applyStatus", this);
  }
  public get status(): RequestStatus {
    return this._status;
  }
  public setStatus(val: RequestStatus): void {
    let isValChanged: boolean = this._status !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequestsRequest._STATUS, this._status.index);

    this._status = val;

    this.fire("status", this);
  }
  public get school(): School {
    return this._school;
  }
  public setSchool(val: School): void {
    let isValChanged: boolean = this._school !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequestsRequest._SCHOOL, this._school);

    this.updateObservable("school", this._school, val);

    this._school = val;

    this.fire("school", this);
  }
  public get renter(): Renter {
    return this._renter;
  }
  public setRenter(val: Renter): void {
    let isValChanged: boolean = this._renter !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequestsRequest._RENTER, this._renter);

    this.updateObservable("renter", this._renter, val);

    this._renter = val;

    this.fire("renter", this);
  }
  public get fromDate(): D3EDate {
    return this._fromDate;
  }
  public setFromDate(val: D3EDate): void {
    let isValChanged: boolean = this._fromDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequestsRequest._FROMDATE, this._fromDate);

    this._fromDate = val;

    this.fire("fromDate", this);
  }
  public get toDate(): D3EDate {
    return this._toDate;
  }
  public setToDate(val: D3EDate): void {
    let isValChanged: boolean = this._toDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequestsRequest._TODATE, this._toDate);

    this._toDate = val;

    this.fire("toDate", this);
  }
  public get pageSize(): number {
    return this._pageSize;
  }
  public setPageSize(val: number): void {
    let isValChanged: boolean = this._pageSize !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequestsRequest._PAGESIZE, this._pageSize);

    this._pageSize = val;

    this.fire("pageSize", this);
  }
  public get offset(): number {
    return this._offset;
  }
  public setOffset(val: number): void {
    let isValChanged: boolean = this._offset !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RentalRequestsRequest._OFFSET, this._offset);

    this._offset = val;

    this.fire("offset", this);
  }
  public get(field: number): any {
    switch (field) {
      case RentalRequestsRequest._APPLYSTATUS: {
        return this._applyStatus;
      }

      case RentalRequestsRequest._STATUS: {
        return this._status.index;
      }

      case RentalRequestsRequest._SCHOOL: {
        return this._school;
      }

      case RentalRequestsRequest._RENTER: {
        return this._renter;
      }

      case RentalRequestsRequest._FROMDATE: {
        return this._fromDate;
      }

      case RentalRequestsRequest._TODATE: {
        return this._toDate;
      }

      case RentalRequestsRequest._PAGESIZE: {
        return this._pageSize;
      }

      case RentalRequestsRequest._OFFSET: {
        return this._offset;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): RentalRequestsRequest {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: RentalRequestsRequest = dbObj as RentalRequestsRequest;

    obj.id = this.id;

    obj.setApplyStatus(this._applyStatus);

    obj.setStatus(this._status);

    obj.setSchool(this._school);

    obj.setRenter(this._renter);

    obj.setFromDate(this._fromDate);

    obj.setToDate(this._toDate);

    obj.setPageSize(this._pageSize);

    obj.setOffset(this._offset);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case RentalRequestsRequest._APPLYSTATUS: {
        this.setApplyStatus(value as boolean);
        break;
      }

      case RentalRequestsRequest._STATUS: {
        this.setStatus(RequestStatus.values[value as number]);
        break;
      }

      case RentalRequestsRequest._SCHOOL: {
        this.setSchool(value as School);
        break;
      }

      case RentalRequestsRequest._RENTER: {
        this.setRenter(value as Renter);
        break;
      }

      case RentalRequestsRequest._FROMDATE: {
        this.setFromDate(value as D3EDate);
        break;
      }

      case RentalRequestsRequest._TODATE: {
        this.setToDate(value as D3EDate);
        break;
      }

      case RentalRequestsRequest._PAGESIZE: {
        this.setPageSize(value as number);
        break;
      }

      case RentalRequestsRequest._OFFSET: {
        this.setOffset(value as number);
        break;
      }
    }
  }
}
