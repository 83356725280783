export default class ImageFrom {
  public static readonly Network = new ImageFrom("Network", "Network", 0);
  public static readonly Asset = new ImageFrom("Asset", "Asset", 1);
  public static readonly Project = new ImageFrom("Project", "Project", 2);
  private _ident: string;
  private _name: string;
  private _index: number;
  public constructor(_ident: string, _name: string, _index: number) {
    this._ident = _ident;

    this._name = _name;

    this._index = _index;
  }
  public static get values(): ImageFrom[] {
    return [ImageFrom.Network, ImageFrom.Asset, ImageFrom.Project];
  }
  public get ident(): string {
    return this._ident;
  }
  public get name(): string {
    return this._name;
  }
  public get index(): number {
    return this._index;
  }
  public toString(): string {
    return this._name;
  }
}
