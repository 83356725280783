import LoginResult from "./LoginResult";
import Env from "./Env";
import { ApolloClient } from "@apollo/client";
import * as __d3ett from "../rocket/D3ETemplate";
import { D3ETemplate } from "../rocket/D3ETemplate";
import SchoolsList from "./SchoolsList";
import PendingRentalRequests from "./PendingRentalRequests";
import FacilityType from "../models/FacilityType";
import RequestInsurancesRequest from "../models/RequestInsurancesRequest";
import RentalRequest from "../models/RentalRequest";
import School from "../models/School";
import GraphQLClientInit from "../utils/GraphQLClientInit";
import LocalDataStore from "../utils/LocalDataStore";
import MessageList from "./MessageList";
import FacilityTypeList from "./FacilityTypeList";
import SchoolType from "../models/SchoolType";
import Renter from "../models/Renter";
import RequestDocuments from "./RequestDocuments";
import Admin from "../models/Admin";
import EventListRequest from "../models/EventListRequest";
import MessageDispatch from "../rocket/MessageDispatch";
import RentalFacilities from "./RentalFacilities";
import RentalBookingsRequest from "../models/RentalBookingsRequest";
import RequestDocument from "../models/RequestDocument";
import FacilitiesListRequest from "../models/FacilitiesListRequest";
import PendingPayments from "./PendingPayments";
import Facility from "../models/Facility";
import RentalBookings from "./RentalBookings";
import FacilityUsageCategory from "../models/FacilityUsageCategory";
import Equipment from "../models/Equipment";
import PaymentHistory from "./PaymentHistory";
import RentalRequests from "./RentalRequests";
import Message from "../models/Message";
import PendingRentalRequestsRequest from "../models/PendingRentalRequestsRequest";
import RequestInsurance from "../models/RequestInsurance";
import ReferenceCatch from "../utils/ReferenceCatch";
import FacilityTypeListRequest from "../models/FacilityTypeListRequest";
import Service from "../models/Service";
import MessageListRequest from "../models/MessageListRequest";
import RequestDocumentsRequest from "../models/RequestDocumentsRequest";
import SchoolsListRequest from "../models/SchoolsListRequest";
import PendingPaymentsRequest from "../models/PendingPaymentsRequest";
import RentalRequestsRequest from "../models/RentalRequestsRequest";
import EventList from "./EventList";
import PaymentReceipt from "../models/PaymentReceipt";
import PaymentHistoryRequest from "../models/PaymentHistoryRequest";
import RentalFacilitiesRequest from "../models/RentalFacilitiesRequest";
import RequestInsurances from "./RequestInsurances";
import BaseUser from "../models/BaseUser";
import FacilitiesList from "./FacilitiesList";

export default class Query {
  private _client: ApolloClient<any>;
  private static _queryObject: Query;
  private _referenceCatch: ReferenceCatch;
  private constructor() {}
  private static _init() {
    let q = new Query();

    q._client = GraphQLClientInit.get();

    q._referenceCatch = ReferenceCatch.get();

    return q;
  }
  public static get() {
    if (Query._queryObject == null) {
      Query._queryObject = Query._init();
    }

    return Query._queryObject;
  }
  public async currentUser(): Promise<BaseUser> {
    GraphQLClientInit.token = await LocalDataStore.get().getToken();

    return await LocalDataStore.get().currentUser();
  }
  public async logout(): Promise<boolean> {
    LocalDataStore.get().setUser(null, null);

    await MessageDispatch.get().logout();

    return true;
  }
  public async getAdminById(usage: number, id: number): Promise<Admin> {
    return MessageDispatch.get().query(__d3ett.ADMIN, id, usage);
  }
  public async getEquipmentById(usage: number, id: number): Promise<Equipment> {
    return MessageDispatch.get().query(__d3ett.EQUIPMENT, id, usage);
  }
  public async getFacilityById(usage: number, id: number): Promise<Facility> {
    return MessageDispatch.get().query(__d3ett.FACILITY, id, usage);
  }
  public async getFacilityTypeById(
    usage: number,
    id: number
  ): Promise<FacilityType> {
    return MessageDispatch.get().query(__d3ett.FACILITYTYPE, id, usage);
  }
  public async getFacilityUsageCategoryById(
    usage: number,
    id: number
  ): Promise<FacilityUsageCategory> {
    return MessageDispatch.get().query(
      __d3ett.FACILITYUSAGECATEGORY,
      id,
      usage
    );
  }
  public async getPaymentReceiptById(
    usage: number,
    id: number
  ): Promise<PaymentReceipt> {
    return MessageDispatch.get().query(__d3ett.PAYMENTRECEIPT, id, usage);
  }
  public async getRentalRequestById(
    usage: number,
    id: number
  ): Promise<RentalRequest> {
    return MessageDispatch.get().query(__d3ett.RENTALREQUEST, id, usage);
  }
  public async getRenterById(usage: number, id: number): Promise<Renter> {
    return MessageDispatch.get().query(__d3ett.RENTER, id, usage);
  }
  public async getSchoolById(usage: number, id: number): Promise<School> {
    return MessageDispatch.get().query(__d3ett.SCHOOL, id, usage);
  }
  public async getSchoolTypeById(
    usage: number,
    id: number
  ): Promise<SchoolType> {
    return MessageDispatch.get().query(__d3ett.SCHOOLTYPE, id, usage);
  }
  public async getServiceById(usage: number, id: number): Promise<Service> {
    return MessageDispatch.get().query(__d3ett.SERVICE, id, usage);
  }
  public async loginRenterUserWithEmailAndPassword(
    usage: number,
    d3eParams?: Partial<{
      email: string;
      password: string;
      deviceToken: string;
    }>
  ): Promise<LoginResult> {
    let email = d3eParams?.email;

    let password = d3eParams?.password;

    let deviceToken = d3eParams?.deviceToken;

    return MessageDispatch.get().login(
      "loginRenterUserWithEmailAndPassword",
      usage,
      { email: email, password: password, deviceToken: deviceToken }
    );
  }
  public async loginWithOTP(
    usage: number,
    d3eParams?: Partial<{ token: string; code: string; deviceToken: string }>
  ): Promise<LoginResult> {
    let token = d3eParams?.token;

    let code = d3eParams?.code;

    let deviceToken = d3eParams?.deviceToken;

    return MessageDispatch.get().login("loginWithOTP", usage, {
      token: token,
      code: code,
      deviceToken: deviceToken,
    });
  }
  public async getEventList(
    usage: number,
    request: EventListRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<EventList> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery("EventList", usage, true, request);
  }
  public async getFacilitiesList(
    usage: number,
    request: FacilitiesListRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<FacilitiesList> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "FacilitiesList",
      usage,
      true,
      request,
      { synchronize: synchronize }
    );
  }
  public async getFacilityTypeList(
    usage: number,
    request: FacilityTypeListRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<FacilityTypeList> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "FacilityTypeList",
      usage,
      true,
      request,
      { synchronize: synchronize }
    );
  }
  public async getMessageList(
    usage: number,
    request: MessageListRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<MessageList> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "MessageList",
      usage,
      true,
      request,
      { synchronize: synchronize }
    );
  }
  public async getPaymentHistory(
    usage: number,
    request: PaymentHistoryRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<PaymentHistory> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "PaymentHistory",
      usage,
      true,
      request,
      { synchronize: synchronize }
    );
  }
  public async getPendingPayments(
    usage: number,
    request: PendingPaymentsRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<PendingPayments> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "PendingPayments",
      usage,
      true,
      request,
      { synchronize: synchronize }
    );
  }
  public async getPendingRentalRequests(
    usage: number,
    request: PendingRentalRequestsRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<PendingRentalRequests> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "PendingRentalRequests",
      usage,
      true,
      request,
      { synchronize: synchronize }
    );
  }
  public async getRentalBookings(
    usage: number,
    request: RentalBookingsRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<RentalBookings> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "RentalBookings",
      usage,
      true,
      request
    );
  }
  public async getRentalFacilities(
    usage: number,
    request: RentalFacilitiesRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<RentalFacilities> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "RentalFacilities",
      usage,
      true,
      request
    );
  }
  public async getRentalRequests(
    usage: number,
    request: RentalRequestsRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<RentalRequests> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "RentalRequests",
      usage,
      true,
      request,
      { synchronize: synchronize }
    );
  }
  public async getRequestDocuments(
    usage: number,
    request: RequestDocumentsRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<RequestDocuments> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "RequestDocuments",
      usage,
      true,
      request,
      { synchronize: synchronize }
    );
  }
  public async getRequestInsurances(
    usage: number,
    request: RequestInsurancesRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<RequestInsurances> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "RequestInsurances",
      usage,
      true,
      request,
      { synchronize: synchronize }
    );
  }
  public async getSchoolsList(
    usage: number,
    request: SchoolsListRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<SchoolsList> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "SchoolsList",
      usage,
      true,
      request,
      { synchronize: synchronize }
    );
  }
}
