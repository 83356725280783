import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import IconView from "./IconView";

export interface RentalFooterViewProps extends BaseUIProps {
  key?: string;
}

class _RentalFooterViewState extends ObservableComponent<RentalFooterViewProps> {
  public constructor(props: RentalFooterViewProps) {
    super(props);

    this.initState();
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {}
  public render(): ReactNode {
    return ui.Row({
      children: [
        IconView({
          size: 24,
          icon: MaterialIcons.copyright,
          className: "xc49",
          key: "0",
        }),
        TextView({ data: "2023 Rentals, Inc.", key: "1" }),
      ],
      className: ui.join(this.props.className, "RentalFooterView xb04 hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function RentalFooterView(props: RentalFooterViewProps) {
  return React.createElement(_RentalFooterViewState, props);
}
