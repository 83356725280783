import DBObject from "../utils/DBObject";

export default class SuccessMessage extends DBObject {
  private _id: number = DBObject.nextStructId();
  private _message: string = "";
  public constructor(d3eParams?: Partial<{ message: string }>) {
    super();

    if (d3eParams?.message) {
      this.setMessage(d3eParams?.message);
    }
  }
  public get id(): number {
    return this._id;
  }
  public set id(id: number) {
    this._id = id;
  }
  public get d3eType(): string {
    return "SuccessMessage";
  }
  public clear(): void {}
  public initListeners(): void {
    super.initListeners();
  }
  public get message(): string {
    return this._message;
  }
  public setMessage(val: string): void {
    let isValChanged: boolean = this._message !== val;

    if (!isValChanged) {
      return;
    }

    this._message = val;

    this.fire("message", this);
  }
  public equals(other: any): boolean {
    return (
      this === other ||
      (other instanceof SuccessMessage && this._message === other._message)
    );
  }
  public get hashCode(): number {
    return this._message?.hashCode ?? 0;
  }
}
