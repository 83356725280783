import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class SchoolsListRequest extends DBObject {
  private static readonly _ASCENDING: number = 0;
  private static readonly _OFFSET: number = 1;
  private static readonly _ORDERBY: number = 2;
  private static readonly _PAGESIZE: number = 3;
  public id: number = 0;
  public otherMaster: DBObject;
  private _pageSize: number = 0;
  private _offset: number = 0;
  private _orderBy: string = "";
  private _ascending: boolean = false;
  public constructor(
    d3eParams?: Partial<{
      ascending: boolean;
      offset: number;
      orderBy: string;
      pageSize: number;
    }>
  ) {
    super();

    this.setAscending(d3eParams?.ascending ?? false);

    this.setOffset(d3eParams?.offset ?? 0);

    this.setOrderBy(d3eParams?.orderBy ?? "");

    this.setPageSize(d3eParams?.pageSize ?? 0);
  }
  public get d3eType(): string {
    return "SchoolsListRequest";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isTransientModel(): boolean {
    return;
  }
  public get pageSize(): number {
    return this._pageSize;
  }
  public setPageSize(val: number): void {
    let isValChanged: boolean = this._pageSize !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SchoolsListRequest._PAGESIZE, this._pageSize);

    this._pageSize = val;

    this.fire("pageSize", this);
  }
  public get offset(): number {
    return this._offset;
  }
  public setOffset(val: number): void {
    let isValChanged: boolean = this._offset !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SchoolsListRequest._OFFSET, this._offset);

    this._offset = val;

    this.fire("offset", this);
  }
  public get orderBy(): string {
    return this._orderBy;
  }
  public setOrderBy(val: string): void {
    let isValChanged: boolean = this._orderBy !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SchoolsListRequest._ORDERBY, this._orderBy);

    this._orderBy = val;

    this.fire("orderBy", this);
  }
  public get ascending(): boolean {
    return this._ascending;
  }
  public setAscending(val: boolean): void {
    let isValChanged: boolean = this._ascending !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SchoolsListRequest._ASCENDING, this._ascending);

    this._ascending = val;

    this.fire("ascending", this);
  }
  public get(field: number): any {
    switch (field) {
      case SchoolsListRequest._PAGESIZE: {
        return this._pageSize;
      }

      case SchoolsListRequest._OFFSET: {
        return this._offset;
      }

      case SchoolsListRequest._ORDERBY: {
        return this._orderBy;
      }

      case SchoolsListRequest._ASCENDING: {
        return this._ascending;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): SchoolsListRequest {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: SchoolsListRequest = dbObj as SchoolsListRequest;

    obj.id = this.id;

    obj.setPageSize(this._pageSize);

    obj.setOffset(this._offset);

    obj.setOrderBy(this._orderBy);

    obj.setAscending(this._ascending);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case SchoolsListRequest._PAGESIZE: {
        this.setPageSize(value as number);
        break;
      }

      case SchoolsListRequest._OFFSET: {
        this.setOffset(value as number);
        break;
      }

      case SchoolsListRequest._ORDERBY: {
        this.setOrderBy(value as string);
        break;
      }

      case SchoolsListRequest._ASCENDING: {
        this.setAscending(value as boolean);
        break;
      }
    }
  }
}
