import Duration from "../core/Duration";

export default class DurationUtils {
  public constructor() {}
  public static makeTwoDigit(num: number): string {
    if (num < 10) {
      return "0" + num.toString();
    } else {
      return num.toString();
    }
  }
  public static formattedDuration(
    value: Duration,
    format: string,
    showZero: boolean
  ): string {
    if (value === null || value === Duration.zero) {
      return showZero ? "0h 00m" : "";
    }

    if (format === null || format === "") {
      return value.toString();
    }

    let seconds: number = value.inSeconds;

    if (seconds < 0) {
      seconds *= -1;
    }

    let hours: number = (seconds / 3600).truncate();

    let minutes: number = ((seconds % 3600) / 60).truncate();

    switch (format) {
      case "HHh MMm": {
        return (
          DurationUtils.makeTwoDigit(hours) +
          "h " +
          DurationUtils.makeTwoDigit(minutes) +
          "m"
        );
      }

      case "HH:mm": {
        return (
          DurationUtils.makeTwoDigit(hours) +
          "." +
          DurationUtils.makeTwoDigit(minutes)
        );
      }

      case "HH.HHh": {
        return hours.toString() + "." + minutes.toString() + "h";
      }

      case "HH.HH": {
        return hours.toString() + "." + minutes.toString();
      }
      default: {
      }
    }

    return value.toString();
  }
}
