import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class OneTimePassword extends DBObject {
  private static readonly _INPUT: number = 0;
  private static readonly _INPUTTYPE: number = 1;
  private static readonly _TOKEN: number = 2;
  private static readonly _USERTYPE: number = 3;
  public id: number = 0;
  public otherMaster: DBObject;
  private _input: string = "";
  private _inputType: string = "";
  private _userType: string = "";
  private _token: string = "";
  public constructor(
    d3eParams?: Partial<{
      input: string;
      inputType: string;
      token: string;
      userType: string;
    }>
  ) {
    super();

    this.setInput(d3eParams?.input ?? "");

    this.setInputType(d3eParams?.inputType ?? "");

    this.setToken(d3eParams?.token ?? "");

    this.setUserType(d3eParams?.userType ?? "");
  }
  public get d3eType(): string {
    return "OneTimePassword";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get input(): string {
    return this._input;
  }
  public setInput(val: string): void {
    let isValChanged: boolean = this._input !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(OneTimePassword._INPUT, this._input);

    this._input = val;

    this.fire("input", this);
  }
  public get inputType(): string {
    return this._inputType;
  }
  public setInputType(val: string): void {
    let isValChanged: boolean = this._inputType !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(OneTimePassword._INPUTTYPE, this._inputType);

    this._inputType = val;

    this.fire("inputType", this);
  }
  public get userType(): string {
    return this._userType;
  }
  public setUserType(val: string): void {
    let isValChanged: boolean = this._userType !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(OneTimePassword._USERTYPE, this._userType);

    this._userType = val;

    this.fire("userType", this);
  }
  public get token(): string {
    return this._token;
  }
  public setToken(val: string): void {
    let isValChanged: boolean = this._token !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(OneTimePassword._TOKEN, this._token);

    this._token = val;

    this.fire("token", this);
  }
  public get(field: number): any {
    switch (field) {
      case OneTimePassword._INPUT: {
        return this._input;
      }

      case OneTimePassword._INPUTTYPE: {
        return this._inputType;
      }

      case OneTimePassword._USERTYPE: {
        return this._userType;
      }

      case OneTimePassword._TOKEN: {
        return this._token;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): OneTimePassword {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: OneTimePassword = dbObj as OneTimePassword;

    obj.id = this.id;

    obj.setInput(this._input);

    obj.setInputType(this._inputType);

    obj.setUserType(this._userType);

    obj.setToken(this._token);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case OneTimePassword._INPUT: {
        this.setInput(value as string);
        break;
      }

      case OneTimePassword._INPUTTYPE: {
        this.setInputType(value as string);
        break;
      }

      case OneTimePassword._USERTYPE: {
        this.setUserType(value as string);
        break;
      }

      case OneTimePassword._TOKEN: {
        this.setToken(value as string);
        break;
      }
    }
  }
}
