import RentalCurrentInvoicePage from "../components/RentalCurrentInvoicePage";
import SearchPage from "../components/SearchPage";
import RentalLoginPage from "../components/RentalLoginPage";
import MainHomePage from "../components/MainHomePage";
import GlobalFunctions from "./GlobalFunctions";
import RentalPaymentHistoryPage from "../components/RentalPaymentHistoryPage";
import UndoManager from "./UndoManager";
import RentalDashboardPage from "../components/RentalDashboardPage";
import RequestOverViewPage from "../components/RequestOverViewPage";
import RentalChangePasswordPage from "../components/RentalChangePasswordPage";
import EventCalendarPage from "../components/EventCalendarPage";
import MainPage from "../components/MainPage";
import RentelSignupPage from "../components/RentelSignupPage";
import RentalHomePage from "../components/RentalHomePage";
import PaymentMethodsPage from "../components/PaymentMethodsPage";
import RentalForgotPasswordPage from "../components/RentalForgotPasswordPage";
import BookingPage from "../components/BookingPage";
import RentalDocumentPage from "../components/RentalDocumentPage";
import RentalReservationPage from "../components/RentalReservationPage";
import RentalInsurancePage from "../components/RentalInsurancePage";

export default class AllPagesUtil {
  public static load(): void {
    GlobalFunctions.BookingPage = BookingPage;

    GlobalFunctions.EventCalendarPage = EventCalendarPage;

    GlobalFunctions.MainHomePage = MainHomePage;

    GlobalFunctions.MainPage = MainPage;

    GlobalFunctions.PaymentMethodsPage = PaymentMethodsPage;

    GlobalFunctions.RentalChangePasswordPage = RentalChangePasswordPage;

    GlobalFunctions.RentalCurrentInvoicePage = RentalCurrentInvoicePage;

    GlobalFunctions.RentalDashboardPage = RentalDashboardPage;

    GlobalFunctions.RentalDocumentPage = RentalDocumentPage;

    GlobalFunctions.RentalForgotPasswordPage = RentalForgotPasswordPage;

    GlobalFunctions.RentalHomePage = RentalHomePage;

    GlobalFunctions.RentalInsurancePage = RentalInsurancePage;

    GlobalFunctions.RentalLoginPage = RentalLoginPage;

    GlobalFunctions.RentalPaymentHistoryPage = RentalPaymentHistoryPage;

    GlobalFunctions.RentalReservationPage = RentalReservationPage;

    GlobalFunctions.RentelSignupPage = RentelSignupPage;

    GlobalFunctions.RequestOverViewPage = RequestOverViewPage;

    GlobalFunctions.SearchPage = SearchPage;

    UndoManager.load();
  }
}
