import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import MaterialIcons from "../icons/MaterialIcons";
import Facility from "../models/Facility";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

export interface FacilityMapPinProps extends BaseUIProps {
  key?: string;
  facility: Facility;
}

class _FacilityMapPinState extends ObservableComponent<FacilityMapPinProps> {
  static defaultProps = { facility: null };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: FacilityMapPinProps) {
    super(props);

    this.initState();
  }
  public get facility(): Facility {
    return this.props.facility;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("facility", this.props.facility);
  }
  public componentDidUpdate(prevProps: FacilityMapPinProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.facility !== this.props.facility) {
      this.updateObservable(
        "facility",
        prevProps.facility,
        this.props.facility
      );

      this.fire("facility", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return IconView({
      icon: MaterialIcons.home,
      color: cStyle.c16,
      className: ui.join(this.props.className, "FacilityMapPin xac hc"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function FacilityMapPin(props: FacilityMapPinProps) {
  return React.createElement(_FacilityMapPinState, {
    ..._FacilityMapPinState.defaultProps,
    ...props,
  });
}
