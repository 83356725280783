import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import CheckServerErrors from "./CheckServerErrors";
import Browser from "../classes/Browser";
import RentalRequest from "../models/RentalRequest";
import PaymentReceipt from "../models/PaymentReceipt";
import TextView from "./TextView";
import PageNavigator from "../classes/PageNavigator";
import RPCServices from "../rpc/RPCServices";
import CollectionUtils from "../utils/CollectionUtils";
import GSDRMSDoubleInputField from "./GSDRMSDoubleInputField";
import PopupWrapperView from "./PopupWrapperView";
import { Runnable } from "../classes/core";
import { BuildContext } from "../classes/BuildContext";

type _CancelButtonOnPressed = (d3eState: MakePaymentViewRefs) => void;

type _PayButtonOnPressed = (d3eState: MakePaymentViewRefs) => void;

type _GSDRMSDoubleInputFieldOnChanged = (
  text: number,
  d3eState: MakePaymentViewRefs
) => void;

export interface MakePaymentViewProps extends BaseUIProps {
  key?: string;
  request: RentalRequest;
}
/// To store state data for MakePaymentView
class MakePaymentViewRefs {
  public cancelButton: CancelButtonState = new CancelButtonState();
  public payButton: PayButtonState = new PayButtonState();
}

interface PayButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: MakePaymentViewRefs;
  _saveButtonHandler?: _PayButtonOnPressed;
}

class PayButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _PayButtonWithState extends ObservableComponent<PayButtonWithStateProps> {
  payButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: PayButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get payButton(): PayButtonState {
    return this.props.d3eState.payButton;
  }
  public get d3eState(): MakePaymentViewRefs {
    return this.props.d3eState;
  }
  public get _saveButtonHandler(): _PayButtonOnPressed {
    return this.props._saveButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("payButton", null, this.payButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["payButton", "payButton.", "payButton.hover"], this.rebuild);
  }
  public payButtonOnEnter(event): void {
    return this.payButton.setHover(true);
  }
  public payButtonOnExit(event): void {
    return this.payButton.setHover(false);
  }
  public dispose(): void {
    this.payButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: 100,
      child: Button({
        padding: this.payButton.hover
          ? cStyle.tButtonPrimaryNewPaddingOnHover
          : cStyle.tButtonPrimaryNewPaddingOn,
        decoration: this.payButton.hover
          ? cStyle.tButtonPrimaryNewDecorationOnHover
          : cStyle.tButtonPrimaryNewDecorationOn,
        disable: this.payButton.disable,
        onPressed: () => {
          this._saveButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Make Payment" }),
        onEnter: (event) => {
          this.payButtonOnEnter(event);
        },
        onExit: (event) => {
          this.payButtonOnExit(event);
        },
      }),
      className: "x431 hc",
    });
  }
}
function PayButtonWithState(props: PayButtonWithStateProps) {
  return React.createElement(_PayButtonWithState, props);
}

interface CancelButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: MakePaymentViewRefs;
  _cancelButtonHandler?: _CancelButtonOnPressed;
}

class CancelButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _CancelButtonWithState extends ObservableComponent<CancelButtonWithStateProps> {
  cancelButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CancelButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get cancelButton(): CancelButtonState {
    return this.props.d3eState.cancelButton;
  }
  public get d3eState(): MakePaymentViewRefs {
    return this.props.d3eState;
  }
  public get _cancelButtonHandler(): _CancelButtonOnPressed {
    return this.props._cancelButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("cancelButton", null, this.cancelButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["cancelButton", "cancelButton.", "cancelButton.hover"],
      this.rebuild
    );
  }
  public cancelButtonOnEnter(event): void {
    return this.cancelButton.setHover(true);
  }
  public cancelButtonOnExit(event): void {
    return this.cancelButton.setHover(false);
  }
  public dispose(): void {
    this.cancelButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 10.0, 0.0, new Map()),
      width: 100,
      child: Button({
        padding: this.cancelButton.hover
          ? cStyle.tButtonSecondaryNewPaddingOnHover
          : cStyle.tButtonSecondaryNewPaddingOn,
        decoration: this.cancelButton.hover
          ? cStyle.tButtonSecondaryNewDecorationOnHover
          : cStyle.tButtonSecondaryNewDecorationOn,
        disable: this.cancelButton.disable,
        onPressed: () => {
          this._cancelButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Cancel" }),
        onEnter: (event) => {
          this.cancelButtonOnEnter(event);
        },
        onExit: (event) => {
          this.cancelButtonOnExit(event);
        },
      }),
      className: "x5d5b hc",
    });
  }
}
function CancelButtonWithState(props: CancelButtonWithStateProps) {
  return React.createElement(_CancelButtonWithState, props);
}

class _MakePaymentViewState extends ObservableComponent<MakePaymentViewProps> {
  static defaultProps = { request: null };
  d3eState: MakePaymentViewRefs = new MakePaymentViewRefs();
  receipt: PaymentReceipt = null;
  errors: Array<string> = ListWrapper.widget(this, "errors");
  rateErrors: boolean = false;
  private _editInitListeners: Array<Runnable> = [];
  private _d3eModelErrors: Array<string> = [];
  private _requestErrors: Array<string> = [];
  private _amountErrors: Array<string> = [];
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: MakePaymentViewProps) {
    super(props);

    this.initState();
  }
  public get request(): RentalRequest {
    return this.props.request;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("request", this.props.request);

    this.on(["request", "request.paid", "request.total"], this.computeReceipt);

    this.computeReceipt();

    this.on(
      ["errors", "rateErrors", "receipt", "receipt.amount"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: MakePaymentViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.request !== this.props.request) {
      this.updateObservable("request", prevProps.request, this.props.request);

      this.fire("request", this);
    }
  }
  public setReceipt(val: PaymentReceipt): void {
    let isValChanged: boolean = this.receipt !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("receipt", this.receipt, val);

    this.receipt = val;

    this.fire("receipt", this);
  }
  public computeReceipt = (): void => {
    try {
      this.setReceipt(
        new PaymentReceipt({
          request: this.request,
          amount: this.request.total - this.request.paid,
        })
      );
    } catch (exception) {
      console.log(" exception in computeReceipt : " + exception.toString());

      this.setReceipt(null);
    }
  };
  public setErrors(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.errors, val);

    if (!isValChanged) {
      return;
    }

    this.errors.clear();

    this.errors.addAll(val);

    this.fire("errors", this);
  }
  public addToErrors(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.errors.contains(val)) this.errors.add(val);
    } else {
      this.errors.remove(this.errors.elementAt(index));

      this.errors.add(val);
    }

    this.fire("errors", this, val, true);
  }
  public removeFromErrors(val: string): void {
    this.errors.remove(val);

    this.fire("errors", this, val, false);
  }
  public setRateErrors(val: boolean): void {
    let isValChanged: boolean = this.rateErrors !== val;

    if (!isValChanged) {
      return;
    }

    this.rateErrors = val;

    this.fire("rateErrors", this);
  }
  public get d3eModelErrors(): Array<string> {
    return this._d3eModelErrors ?? [];
  }
  public set d3eModelErrors(val: Array<string>) {
    this._d3eModelErrors.clear();

    this._d3eModelErrors.addAll(val);
  }
  public get requestErrors(): Array<string> {
    return this._requestErrors ?? [];
  }
  public set requestErrors(val: Array<string>) {
    this._requestErrors.clear();

    this._requestErrors.addAll(val);
  }
  public async computeRequestErrors(): Promise<void> {
    this.requestErrors = [];

    let it: RentalRequest = this.receipt.request;

    if (it == null) {
      this.requestErrors.add("Request cannot be null.");
    }

    this.fire("requestErrors", this);
  }
  public get amountErrors(): Array<string> {
    return this._amountErrors ?? [];
  }
  public set amountErrors(val: Array<string>) {
    this._amountErrors.clear();

    this._amountErrors.addAll(val);
  }
  public async computeAmountErrors(): Promise<void> {
    this.amountErrors = [];

    let it: number = this.receipt.amount;

    this.fire("amountErrors", this);
  }
  public setEditDefaults(): void {}
  public get isEditValid(): boolean {
    if (this.requestErrors.isNotEmpty) {
      return false;
    }

    if (this.amountErrors.isNotEmpty) {
      return false;
    }

    return true;
  }
  public validate(): Array<string> {
    let errors: Array<string> = [];

    this.computeRequestErrors();

    errors.addAll(this.requestErrors);

    this.computeAmountErrors();

    errors.addAll(this.amountErrors);

    return errors;
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return PopupWrapperView({
      title: "Make Payment",
      content: ui.Column({
        crossAxisAlignment: ui.CrossAxisAlignment.start,
        children: [
          GSDRMSDoubleInputField({
            name: "Rate Per Hour",
            value: this.receipt.amount,
            isRequired: true,
            width: 400,
            onChanged: (text) => {
              this.gSDRMSDoubleInputFieldonChanged(text, this.d3eState);
            },
            errors:
              this.rateErrors === true
                ? this.receipt.amount === 0.0
                  ? [" Payment not accepting"]
                  : []
                : [],
            className: "xe1a hc h",
            key: "0",
          }),
        ],
        className: "x7b3 hc h",
      }),
      buttons: [
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          mainAxisAlignment: ui.MainAxisAlignment.start,
          children: [
            this.errors.isNotEmpty
              ? CheckServerErrors({ errors: this.errors })
              : [],
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.start,
              children: [
                CancelButtonWithState({
                  d3eState: this.d3eState,
                  _cancelButtonHandler: this.cancelButtonHandler,
                  key: "0",
                }),
                PayButtonWithState({
                  d3eState: this.d3eState,
                  _saveButtonHandler: this.saveButtonHandler,
                  key: "1",
                }),
              ],
              key: "1",
            }),
          ],
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "MakePaymentView hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public cancelButtonHandler = (d3eState: MakePaymentViewRefs): void => {
    this.navigator.close();
  };
  public saveButtonHandler = async (
    d3eState: MakePaymentViewRefs
  ): Promise<void> => {
    // POS: 150.00, Y = 80.00

    if (this.validate().isEmpty) {
      if (this.receipt.amount <= this.request.total - this.request.paid) {
        let link: string =
          await RPCServices.getPaymentService().createSessionLink(
            this.request.requestId,
            this.request.eventName,
            this.receipt.amount
          );

        Browser.open(link);

        this.navigator.close();
      } else {
        this.setErrors([
          "Amount should not be grater than the remaining amount to be paid",
        ]);
      }
    }
  };
  public gSDRMSDoubleInputFieldonChanged = (
    val: number,
    d3eState: MakePaymentViewRefs
  ): void => {
    this.receipt.setAmount(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get cancelButton() {
    return this.d3eState.cancelButton;
  }
  public get payButton() {
    return this.d3eState.payButton;
  }
}
export default function MakePaymentView(props: MakePaymentViewProps) {
  return React.createElement(_MakePaymentViewState, {
    ..._MakePaymentViewState.defaultProps,
    ...props,
  });
}
