import React from "react";
import { BoxFit } from "../classes/BoxFit";
import { ImageFrom } from "../classes/ImageFrom";
import BaseUIProps, { copyBaseUIProps } from "./BaseUIProps";
import ImageView from "./ImageView";

interface NetworkImageProps extends BaseUIProps {
  url: string;
  fit?: BoxFit;
  width?: number;
  height?: number;
}

class _NetworkImage extends React.Component<NetworkImageProps, {}> {
  static defaultProps = {
    fit: BoxFit.contain,
    with: 0,
    height: 0,
  };
  render() {
    return ImageView({
      imageUrl: this.props.url,
      imageType: ImageFrom.Network,
      fit: this.props.fit,
      className: this.props.className,
      ...copyBaseUIProps(this.props),
    });
  }
}

export default function NetworkImage(props: NetworkImageProps) {
  return React.createElement(_NetworkImage, {
    ..._NetworkImage.defaultProps,
    ...props,
  });
}
