import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import FacilityType from "../models/FacilityType";
import D3EDisposable from "../rocket/D3EDisposable";
import School from "../models/School";
import MaterialIcons from "../icons/MaterialIcons";
import PageNavigator from "../classes/PageNavigator";
import Renter from "../models/Renter";
import Query from "../classes/Query";
import MessageDispatch from "../rocket/MessageDispatch";
import Button from "./Button";
import SignUpRequest from "../models/SignUpRequest";
import ListWrapper from "../utils/ListWrapper";
import TextView from "./TextView";
import RentalSearchableView from "./RentalSearchableView";
import CollectionUtils from "../utils/CollectionUtils";
import IconView from "./IconView";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _OnProfilePressedOnPressed = (d3eState: BannerRefs) => void;

type _ButtonOnPressed = (d3eState: BannerRefs) => void;

type _Button2OnPressed = (d3eState: BannerRefs) => void;

type _SearchButtonOnPressed = (d3eState: BannerRefs) => void;

type _RentalSearchableViewOnChanged = (
  text: FacilityType,
  d3eState: BannerRefs
) => void;

type _RentalSearchableView2OnChanged = (
  text: string,
  d3eState: BannerRefs
) => void;

export interface BannerProps extends BaseUIProps {
  key?: string;
  facilityTypes: Array<FacilityType>;
  schools: Array<School>;
  renter: Renter;
  _facilityTypesHash?: number;
  _schoolsHash?: number;
}
/// To store state data for Banner
class BannerRefs {
  public button: ButtonState = new ButtonState();
  public button2: Button2State = new Button2State();
  public button3: Button3State = new Button3State();
  public button4: Button4State = new Button4State();
  public onProfilePressed: OnProfilePressedState = new OnProfilePressedState();
  public searchButton: SearchButtonState = new SearchButtonState();
}

interface SearchButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: BannerRefs;
  _onPressedSearchButtonHandler?: _SearchButtonOnPressed;
}

class SearchButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _SearchButtonWithState extends ObservableComponent<SearchButtonWithStateProps> {
  searchButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SearchButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get searchButton(): SearchButtonState {
    return this.props.d3eState.searchButton;
  }
  public get d3eState(): BannerRefs {
    return this.props.d3eState;
  }
  public get _onPressedSearchButtonHandler(): _SearchButtonOnPressed {
    return this.props._onPressedSearchButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("searchButton", null, this.searchButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["searchButton"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 20.0, 0.0, 0.0, new Map()),
      width: 415,
      child: Button({
        padding: cStyle.tButtonPrimaryButtonPaddingOn,
        decoration: cStyle.tButtonPrimaryButtonDecorationOn,
        disable: this.searchButton.disable,
        onPressed: () => {
          this._onPressedSearchButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Search", className: "hc" }),
      }),
      className: "x18 hc",
    });
  }
}
function SearchButtonWithState(props: SearchButtonWithStateProps) {
  return React.createElement(_SearchButtonWithState, props);
}

interface Button4WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: BannerRefs;
}

class Button4State extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _Button4WithState extends ObservableComponent<Button4WithStateProps> {
  button4FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Button4WithStateProps) {
    super(props);

    this.initState();
  }
  public get button4(): Button4State {
    return this.props.d3eState.button4;
  }
  public get d3eState(): BannerRefs {
    return this.props.d3eState;
  }
  public initState() {
    super.initState();

    this.updateObservable("button4", null, this.button4);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button4"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      decoration: new ui.BoxDecoration({
        color: new ui.Color(0xff009845),
        border: ui.Border.all({ color: new ui.Color(0xff0000), width: 1.5 }),
        borderRadius: ui.BorderRadius.circular(3.0),
      }),
      padding: ui.EdgeInsets.symmetric({
        horizontal: 25.0,
        vertical: 15.0,
        transitions: new Map(),
      }),
      disable: this.button4.disable,
      onFocusChange: (val) => {},
      child: TextView({
        data: "Attend a Demo",
        style: new ui.TextStyle({
          color: cStyle.c2,
          fontSize: 18,
          fontWeight: ui.FontWeight.w600,
        }),
        className: "x45",
      }),
    });
  }
}
function Button4WithState(props: Button4WithStateProps) {
  return React.createElement(_Button4WithState, props);
}

interface Button3WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: BannerRefs;
}

class Button3State extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _Button3WithState extends ObservableComponent<Button3WithStateProps> {
  button3FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Button3WithStateProps) {
    super(props);

    this.initState();
  }
  public get button3(): Button3State {
    return this.props.d3eState.button3;
  }
  public get d3eState(): BannerRefs {
    return this.props.d3eState;
  }
  public initState() {
    super.initState();

    this.updateObservable("button3", null, this.button3);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button3"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 15.0, 0.0, new Map()),
      child: Button({
        decoration: new ui.BoxDecoration({
          color: cStyle.c2,
          border: ui.Border.all({
            color: new ui.Color(0xff42389d),
            width: 1.5,
          }),
          borderRadius: ui.BorderRadius.circular(3.0),
        }),
        padding: ui.EdgeInsets.symmetric({
          horizontal: 25.0,
          vertical: 15.0,
          transitions: new Map(),
        }),
        disable: this.button3.disable,
        onFocusChange: (val) => {},
        child: TextView({
          data: "Schedule a Demo",
          style: new ui.TextStyle({
            color: cStyle.c12,
            fontSize: 18,
            fontWeight: ui.FontWeight.w600,
          }),
          className: "xc1",
        }),
      }),
      className: "x07",
    });
  }
}
function Button3WithState(props: Button3WithStateProps) {
  return React.createElement(_Button3WithState, props);
}

interface Button2WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: BannerRefs;
  _onPressedButtonHandler?: _Button2OnPressed;
}

class Button2State extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _Button2WithState extends ObservableComponent<Button2WithStateProps> {
  button2FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Button2WithStateProps) {
    super(props);

    this.initState();
  }
  public get button2(): Button2State {
    return this.props.d3eState.button2;
  }
  public get d3eState(): BannerRefs {
    return this.props.d3eState;
  }
  public get _onPressedButtonHandler(): _Button2OnPressed {
    return this.props._onPressedButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("button2", null, this.button2);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button2"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      decoration: new ui.BoxDecoration({
        color: cStyle.c20,
        border: ui.Border.all({ color: new ui.Color(0xff0000), width: 0.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
      }),
      padding: ui.EdgeInsets.symmetric({
        horizontal: 25.0,
        vertical: 15.0,
        transitions: new Map(),
      }),
      disable: this.button2.disable,
      onPressed: () => {
        this._onPressedButtonHandler(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({
        data: "Sign Up",
        style: new ui.TextStyle({
          color: cStyle.c2,
          fontSize: 18,
          fontWeight: ui.FontWeight.w600,
        }),
        className: "xa6",
      }),
    });
  }
}
function Button2WithState(props: Button2WithStateProps) {
  return React.createElement(_Button2WithState, props);
}

interface ButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: BannerRefs;
  _onPressedButtonHandler1?: _ButtonOnPressed;
}

class ButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _ButtonWithState extends ObservableComponent<ButtonWithStateProps> {
  buttonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get button(): ButtonState {
    return this.props.d3eState.button;
  }
  public get d3eState(): BannerRefs {
    return this.props.d3eState;
  }
  public get _onPressedButtonHandler1(): _ButtonOnPressed {
    return this.props._onPressedButtonHandler1;
  }
  public initState() {
    super.initState();

    this.updateObservable("button", null, this.button);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 15.0, 0.0, new Map()),
      child: Button({
        decoration: new ui.BoxDecoration({
          color: cStyle.c2,
          border: ui.Border.all({
            color: new ui.Color(0xff42389d),
            width: 1.5,
          }),
          borderRadius: ui.BorderRadius.circular(3.0),
        }),
        padding: ui.EdgeInsets.symmetric({
          horizontal: 25.0,
          vertical: 15.0,
          transitions: new Map(),
        }),
        disable: this.button.disable,
        onPressed: () => {
          this._onPressedButtonHandler1(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({
          data: "Log In",
          style: new ui.TextStyle({
            color: cStyle.c20,
            fontSize: 18,
            fontWeight: ui.FontWeight.w600,
          }),
          className: "xda",
        }),
      }),
      className: "x24",
    });
  }
}
function ButtonWithState(props: ButtonWithStateProps) {
  return React.createElement(_ButtonWithState, props);
}

interface OnProfilePressedWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: BannerRefs;
  _onProfilePressedHandler?: _OnProfilePressedOnPressed;
  renter: Renter;
}

class OnProfilePressedState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _OnProfilePressedWithState extends ObservableComponent<OnProfilePressedWithStateProps> {
  onProfilePressedFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: OnProfilePressedWithStateProps) {
    super(props);

    this.initState();
  }
  public get onProfilePressed(): OnProfilePressedState {
    return this.props.d3eState.onProfilePressed;
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public get d3eState(): BannerRefs {
    return this.props.d3eState;
  }
  public get _onProfilePressedHandler(): _OnProfilePressedOnPressed {
    return this.props._onProfilePressedHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("onProfilePressed", null, this.onProfilePressed);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("renter", this.props.renter);

    this.on(["onProfilePressed", "renter", "renter.fullName"], this.rebuild);
  }
  public componentDidUpdate(prevProps: OnProfilePressedWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.onProfilePressed.disable,
      onPressed: () => {
        this._onProfilePressedHandler(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({
        data: "Hi " + this.renter.fullName,
        style: new ui.TextStyle({ color: cStyle.c2, fontSize: 18 }),
        className: "xd41",
      }),
    });
  }
}
function OnProfilePressedWithState(props: OnProfilePressedWithStateProps) {
  return React.createElement(_OnProfilePressedWithState, props);
}

class _BannerState extends ObservableComponent<BannerProps> {
  static defaultProps = { renter: null, facilityTypes: [], schools: [] };
  d3eState: BannerRefs = new BannerRefs();
  facilityType: FacilityType = null;
  location: string = "";
  locations: Array<string> = ListWrapper.widget(this, "locations");
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public renterDisposable: D3EDisposable;
  public constructor(props: BannerProps) {
    super(props);

    this.initState();
  }
  public get facilityTypes(): Array<FacilityType> {
    return this.props.facilityTypes;
  }
  public get schools(): Array<School> {
    return this.props.schools;
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.runFetchDataQueryForRenter();

    this.renterDisposable = MessageDispatch.get().syncObject(
      this.props.renter,
      UsageConstants.QUERY_GETRENTERBYID_BANNER_RENTER_FETCHDATA
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.facilityTypes, "facilityTypes");

    this.updateSyncCollProperty("facilityTypes", this.props.facilityTypes);

    this.subscribeToList(this.schools, "schools");

    this.updateSyncCollProperty("schools", this.props.schools);

    this.on(
      ["schools", "schools.city", "schools.state"],
      this.computeLocations
    );

    this.computeLocations();

    this.updateSyncProperty("renter", this.props.renter);

    this.on(
      ["facilityType", "facilityTypes", "location", "locations", "renter"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: BannerProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.facilityTypes !== this.props.facilityTypes) {
      this.updateObservableColl(
        "facilityTypes",
        prevProps.facilityTypes,
        this.props.facilityTypes
      );

      this.fire("facilityTypes", this);
    }

    if (prevProps.schools !== this.props.schools) {
      this.updateObservableColl(
        "schools",
        prevProps.schools,
        this.props.schools
      );

      this.fire("schools", this);
    }

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.runFetchDataQueryForRenter();

      this.fire("renter", this);
    }
  }
  public setFacilityType(val: FacilityType): void {
    let isValChanged: boolean = this.facilityType !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("facilityType", this.facilityType, val);

    this.facilityType = val;

    this.fire("facilityType", this);
  }
  public setLocation(val: string): void {
    let isValChanged: boolean = this.location !== val;

    if (!isValChanged) {
      return;
    }

    this.location = val;

    this.fire("location", this);
  }
  public setLocations(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.locations,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.locations.clear();

    this.locations.addAll(val);

    this.fire("locations", this);
  }
  public addToLocations(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.locations.contains(val)) this.locations.add(val);
    } else {
      this.locations.remove(this.locations.elementAt(index));

      this.locations.add(val);
    }

    this.fire("locations", this, val, true);
  }
  public removeFromLocations(val: string): void {
    this.locations.remove(val);

    this.fire("locations", this, val, false);
  }
  public computeLocations = (): void => {
    try {
      this.setLocations(
        Array.from(
          this.schools
            .map((school) => school.city + ", " + school.state)
            .toSet()
            .toList()
        )
      );
    } catch (exception) {
      console.log(" exception in computeLocations : " + exception.toString());

      this.setLocations([]);
    }
  };
  public runFetchDataQueryForRenter = (): void => {
    if (this.renter == null) {
      return;
    }

    Query.get().getRenterById(
      UsageConstants.QUERY_GETRENTERBYID_BANNER_RENTER_FETCHDATA,
      this.renter.id
    );
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            ui.AssetImage({
              path: "images/faciliRestm.png",
              width: 249,
              height: 38,
              className: "x56 hc vc",
              key: "0",
            }),
            ui.Row({
              children: [
                ui.Wrap({
                  crossAxisAlignment: ui.WrapCrossAlignment.center,
                  children: [
                    IconView({
                      icon: MaterialIcons.local_phone,
                      color: cStyle.c20,
                      size: 25,
                      className: "x28",
                      key: "0",
                    }),
                    ui.Column({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        TextView({
                          data: "Call anytime",
                          style: new ui.TextStyle({
                            fontFamily: "Open Sans",
                            fontSize: 16,
                          }),
                          className: "xf9",
                          key: "0",
                        }),
                        TextView({
                          data: "510-378-8077",
                          style: new ui.TextStyle({
                            fontSize: 18,
                            fontWeight: ui.FontWeight.w700,
                            fontFamily: "Open Sans",
                          }),
                          className: "x17",
                          key: "1",
                        }),
                      ],
                      key: "1",
                    }),
                  ],
                  className: "xcd",
                  key: "0",
                }),
                ui.Wrap({
                  crossAxisAlignment: ui.WrapCrossAlignment.center,
                  children: [
                    IconView({
                      icon: MaterialIcons.mail_outline,
                      color: cStyle.c20,
                      size: 25,
                      className: "x89a",
                      key: "0",
                    }),
                    ui.Column({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        TextView({
                          data: "Write email",
                          style: new ui.TextStyle({
                            fontFamily: "Open Sans",
                            fontSize: 16,
                          }),
                          className: "x43a",
                          key: "0",
                        }),
                        TextView({
                          data: "contact@facilires.com",
                          style: new ui.TextStyle({
                            fontSize: 18,
                            fontWeight: ui.FontWeight.w700,
                            fontFamily: "Open Sans",
                          }),
                          className: "x4c",
                          key: "1",
                        }),
                      ],
                      key: "1",
                    }),
                  ],
                  className: "x4b",
                  key: "1",
                }),
                this.renter !== null
                  ? OnProfilePressedWithState({
                      d3eState: this.d3eState,
                      _onProfilePressedHandler: this.onProfilePressedHandler,
                      renter: this.renter,
                    })
                  : ui.Row({
                      children: [
                        ButtonWithState({
                          d3eState: this.d3eState,
                          _onPressedButtonHandler1:
                            this.onPressedButtonHandler1,
                          key: "0",
                        }),
                        Button2WithState({
                          d3eState: this.d3eState,
                          _onPressedButtonHandler: this.onPressedButtonHandler,
                          key: "1",
                        }),
                      ],
                    }),
              ],
              key: "1",
            }),
          ],
          className: "x95 hc h",
          key: "0",
        }),
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                TextView({
                  data: "We bring communities &\n spaces together.",
                  softWrap: true,
                  style: new ui.TextStyle({
                    color: cStyle.c2,
                    fontSize: 45,
                    fontWeight: cStyle.tTextViewHeadlineThreeFontWeightOn,
                  }),
                  className: "x7d",
                  key: "0",
                }),
                TextView({
                  data: "Facilitron helps community members search and \n request public event spaces in their local area.",
                  softWrap: true,
                  style: new ui.TextStyle({
                    color: cStyle.c2,
                    height: 2,
                    fontSize: 22,
                    fontWeight: ui.FontWeight.w600,
                  }),
                  className: "x8d",
                  key: "1",
                }),
                ui.Row({
                  children: [
                    Button3WithState({ d3eState: this.d3eState, key: "0" }),
                    Button4WithState({ d3eState: this.d3eState, key: "1" }),
                  ],
                  className: "xc48",
                  key: "2",
                }),
              ],
              key: "0",
            }),
            ui.Column({
              children: [
                TextView({
                  data: "Search for a Auditoriums, gymnasiums, rooms, playing Fields, pools and GTI-Hospital Room",
                  softWrap: true,
                  style: new ui.TextStyle({
                    height: 1.6,
                    fontSize: cStyle.tTextViewHeadlineThreeFontSizeOn,
                    fontWeight: cStyle.tTextViewHeadlineThreeFontWeightOn,
                  }),
                  className: "xec hc",
                  key: "0",
                }),
                ui.Container({
                  padding: ui.EdgeInsets.all(5.0, new Map()),
                  width: 415,
                  child: RentalSearchableView<FacilityType>({
                    name: "Facility Name",
                    placeHolder: "E.g Auditoriums",
                    activeColor: cStyle.c20,
                    inActiveColor: cStyle.c36,
                    value: this.facilityType,
                    items: this.facilityTypes,
                    onChanged: (text) => {
                      this.rentalSearchableViewonChanged(text, this.d3eState);
                    },
                  }),
                  key: "1",
                  className: "x1f hc",
                }),
                ui.Container({
                  padding: ui.EdgeInsets.all(5.0, new Map()),
                  width: 415,
                  child: RentalSearchableView<string>({
                    name: "Facility Location",
                    placeHolder: "Enter Location",
                    inActiveColor: cStyle.c36,
                    activeColor: cStyle.c20,
                    value: this.location,
                    items: this.locations,
                    onChanged: (text) => {
                      this.rentalSearchableView2onChanged(text, this.d3eState);
                    },
                  }),
                  key: "2",
                  className: "xc2 hc",
                }),
                SearchButtonWithState({
                  d3eState: this.d3eState,
                  _onPressedSearchButtonHandler:
                    this.onPressedSearchButtonHandler,
                  key: "3",
                }),
              ],
              className: "xa2 hc",
              key: "1",
            }),
          ],
          className: "xfe hc h",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "Banner x74 hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressedSearchButtonHandler = (d3eState: BannerRefs): void => {
    this.navigator.pushSearchPage({
      facilityType: this.facilityType,
      location: this.location,
      facilityTypes: this.facilityTypes,
      locations: this.locations,
      renter: this.renter,
    });
  };
  public onPressedButtonHandler = (d3eState: BannerRefs): void => {
    this.navigator.pushRentelSignupPage({ signUpRequest: new SignUpRequest() });
  };
  public onPressedButtonHandler1 = (d3eState: BannerRefs): void => {
    this.navigator.pushRentalLoginPage();
  };
  public onProfilePressedHandler = (d3eState: BannerRefs): void => {
    this.navigator.pushRentalHomePage({ renter: this.renter, replace: true });
  };
  public dispose(): void {
    this.renterDisposable?.dispose();

    super.dispose();
  }
  public rentalSearchableViewonChanged = (
    val: FacilityType,
    d3eState: BannerRefs
  ): void => {
    this.setFacilityType(val);
  };
  public rentalSearchableView2onChanged = (
    val: string,
    d3eState: BannerRefs
  ): void => {
    this.setLocation(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get button() {
    return this.d3eState.button;
  }
  public get button2() {
    return this.d3eState.button2;
  }
  public get button3() {
    return this.d3eState.button3;
  }
  public get button4() {
    return this.d3eState.button4;
  }
  public get onProfilePressed() {
    return this.d3eState.onProfilePressed;
  }
  public get searchButton() {
    return this.d3eState.searchButton;
  }
}
export default function Banner(props: BannerProps) {
  return React.createElement(
    _BannerState,
    { ..._BannerState.defaultProps, ...props },
    ListWrapper.fromInput<FacilityType>(props.facilityTypes, "facilityTypes"),
    ListWrapper.fromInput<School>(props.schools, "schools")
  );
}
