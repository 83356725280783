import Renter from "./Renter";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";
import RentalRequest from "./RentalRequest";

export default class PaymentHistoryRequest extends DBObject {
  private static readonly _RENTER: number = 0;
  private static readonly _REQUEST: number = 1;
  public id: number = 0;
  public otherMaster: DBObject;
  private _request: RentalRequest = null;
  private _renter: Renter = null;
  public constructor(
    d3eParams?: Partial<{ renter: Renter; request: RentalRequest }>
  ) {
    super();

    this.setRenter(d3eParams?.renter ?? null);

    this.setRequest(d3eParams?.request ?? null);
  }
  public get d3eType(): string {
    return "PaymentHistoryRequest";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isTransientModel(): boolean {
    return;
  }
  public get request(): RentalRequest {
    return this._request;
  }
  public setRequest(val: RentalRequest): void {
    let isValChanged: boolean = this._request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(PaymentHistoryRequest._REQUEST, this._request);

    this.updateObservable("request", this._request, val);

    this._request = val;

    this.fire("request", this);
  }
  public get renter(): Renter {
    return this._renter;
  }
  public setRenter(val: Renter): void {
    let isValChanged: boolean = this._renter !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(PaymentHistoryRequest._RENTER, this._renter);

    this.updateObservable("renter", this._renter, val);

    this._renter = val;

    this.fire("renter", this);
  }
  public get(field: number): any {
    switch (field) {
      case PaymentHistoryRequest._REQUEST: {
        return this._request;
      }

      case PaymentHistoryRequest._RENTER: {
        return this._renter;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): PaymentHistoryRequest {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: PaymentHistoryRequest = dbObj as PaymentHistoryRequest;

    obj.id = this.id;

    obj.setRequest(this._request);

    obj.setRenter(this._renter);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case PaymentHistoryRequest._REQUEST: {
        this.setRequest(value as RentalRequest);
        break;
      }

      case PaymentHistoryRequest._RENTER: {
        this.setRenter(value as Renter);
        break;
      }
    }
  }
}
