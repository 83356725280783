import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import CalendarViewType from "../classes/CalendarViewType";
import ListWrapper from "../utils/ListWrapper";
import D3EDate from "../classes/D3EDate";
import RoundedDateView from "./RoundedDateView";
import CalendarEvent from "../classes/CalendarEvent";
import TextView from "./TextView";

type _DateBoxViewPressedOnEvent = (event: CalendarEvent) => void;

type _DateBoxViewBuilder<CalendarEvent, CalendarViewType> = (
  context: any,
  event: CalendarEvent,
  viewType: CalendarViewType
) => ReactNode;

type _ButtonOnPressed = (d3eState: _EventState) => void;

export interface DateBoxViewProps extends BaseUIProps {
  key?: string;
  date: D3EDate;
  month: number;
  items?: Array<CalendarEvent>;
  dateBgColor?: ui.Color;
  _itemsHash?: number;
  pressedOnEvent: _DateBoxViewPressedOnEvent;
  builder?: _DateBoxViewBuilder<CalendarEvent, CalendarViewType>;
}
/// To store state data for DateBoxView
class DateBoxViewRefs {
  public dateColumn: DateColumnState = new DateColumnState();
  public eventState: Map<CalendarEvent, _EventState> = new Map();
  public forEvent(event: CalendarEvent): _EventState {
    let res = this.eventState.get(event);

    if (res == null) {
      res = new _EventState(this, event);

      this.eventState.set(event, res);
    }

    return res;
  }
}

interface ButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _EventState;
  _onPressed?: _ButtonOnPressed;
  event: CalendarEvent;
}

class ButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _ButtonWithState extends ObservableComponent<ButtonWithStateProps> {
  buttonFocusNode: ui.FocusNode = new ui.FocusNode();
  public constructor(props: ButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get button(): ButtonState {
    return this.props.d3eState.button;
  }
  public get event(): CalendarEvent {
    return this.props.event;
  }
  public get d3eState(): _EventState {
    return this.props.d3eState;
  }
  public get _onPressed(): _ButtonOnPressed {
    return this.props._onPressed;
  }
  public initState() {
    super.initState();

    this.updateObservable("button", null, this.button);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("event", this.props.event);

    this.on(
      ["button", "event", "event.backgroundColor", "event.title"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ButtonWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.event !== this.props.event) {
      this.updateObservable("event", prevProps.event, this.props.event);

      this.fire("event", this);
    }
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    return ui.Container({
      constraints: new ui.BoxConstraints({ minWidth: 200, minHeight: 20 }),
      margin: ui.EdgeInsets.all(5.0, new Map()),
      child: Button({
        decoration: new ui.BoxDecoration({
          color: ui.HexColor.fromHexStr(this.event.backgroundColor),
          borderRadius: ui.BorderRadius.circular(3.0),
        }),
        padding: ui.EdgeInsets.all(7.0, new Map()),
        disable: this.button.disable,
        onPressed: () => {
          this._onPressed(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({
          data: this.event.title,
          softWrap: true,
          style: new ui.TextStyle({
            fontWeight: ui.FontWeight.w400,
            color: new ui.Color(0xffffffff),
          }),
          className: "xf8 hc",
        }),
      }),
      className: "xb9 hc",
    });
  }
}
function ButtonWithState(props: ButtonWithStateProps) {
  return React.createElement(_ButtonWithState, props);
}

interface DateColumnWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: DateBoxViewRefs;
  _onPressed?: _ButtonOnPressed;
  currentDate: D3EDate;
  date: D3EDate;
  dateBgColor: ui.Color;
  items: Array<CalendarEvent>;
  month: number;
}

class DateColumnState extends ObjectObservable {
  private _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _DateColumnWithState extends ObservableComponent<DateColumnWithStateProps> {
  buttonFocusNode: ui.FocusNode = new ui.FocusNode();
  public constructor(props: DateColumnWithStateProps) {
    super(props);

    this.initState();
  }
  public get currentDate(): D3EDate {
    return this.props.currentDate;
  }
  public get date(): D3EDate {
    return this.props.date;
  }
  public get dateBgColor(): ui.Color {
    return this.props.dateBgColor;
  }
  public get dateColumn(): DateColumnState {
    return this.props.d3eState.dateColumn;
  }
  public get items(): Array<CalendarEvent> {
    return this.props.items;
  }
  public get month(): number {
    return this.props.month;
  }
  public get d3eState(): DateBoxViewRefs {
    return this.props.d3eState;
  }
  public get _onPressed(): _ButtonOnPressed {
    return this.props._onPressed;
  }
  public initState() {
    super.initState();

    this.updateObservable("dateColumn", null, this.dateColumn);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "currentDate",
        "date",
        "dateBgColor",
        "dateColumn",
        "dateColumn.hover",
        "items",
        "month",
      ],
      this.rebuild
    );
  }
  public dateColumnOnEnter(event): void {
    return this.dateColumn.setHover(true);
  }
  public dateColumnOnExit(event): void {
    return this.dateColumn.setHover(false);
  }
  public dispose(): void {
    this.dateColumn.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    return ui.Container({
      decoration: D3EDate.equals(this.date, this.currentDate)
        ? new ui.BoxDecoration({
            color:
              this.dateBgColor !== null
                ? this.dateBgColor
                : ui.HexColor.fromHexStr("fffaf2cc"),
          })
        : this.dateColumn.hover
        ? new ui.BoxDecoration({
            color:
              this.dateBgColor !== null
                ? this.dateBgColor
                : ui.HexColor.fromHexStr("fffaf2cc"),
          })
        : null,
      child: ui.Column({
        crossAxisAlignment: ui.CrossAxisAlignment.end,
        mainAxisSize: ui.MainAxisSize.min,
        children: [
          ui.Container({
            margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 0.0, 5.0, new Map()),
            child: RoundedDateView({
              date: this.date,
              month: this.month,
              items: this.items,
            }),
            key: "0",
            className: "x96 hc",
          }),
          this.items.expand((event) => [
            ButtonWithState({
              d3eState: this.d3eState.forEvent(event),
              _onPressed: this._onPressed,
              event: event,
              key: event?.toString(),
            }),
          ]),
        ],
        onEnter: (event) => {
          this.dateColumnOnEnter(event);
        },
        onExit: (event) => {
          this.dateColumnOnExit(event);
        },
      }),
      className: ui.join(this.props.className, "DateBoxView x8c8 hc"),
      ...copyBaseUIProps(this.props),
    });
  }
}
function DateColumnWithState(props: DateColumnWithStateProps) {
  return React.createElement(_DateColumnWithState, props);
}

class _EventState {
  parent: DateBoxViewRefs;
  event: CalendarEvent;
  button: ButtonState = new ButtonState();
  public constructor(parent, event) {
    this.parent = parent;

    this.event = event;
  }
  public get dateColumn(): DateColumnState {
    return this.parent.dateColumn;
  }
}

class _DateBoxViewState extends ObservableComponent<DateBoxViewProps> {
  static defaultProps = {
    date: null,
    month: 0,
    dateBgColor: null,
    items: [],
    pressedOnEvent: null,
  };
  d3eState: DateBoxViewRefs = new DateBoxViewRefs();
  currentDate: D3EDate = null;
  public constructor(props: DateBoxViewProps) {
    super(props);

    this.initState();
  }
  public get date(): D3EDate {
    return this.props.date;
  }
  public get month(): number {
    return this.props.month;
  }
  public get items(): Array<CalendarEvent> {
    return this.props.items;
  }
  public get dateBgColor(): ui.Color {
    return this.props.dateBgColor;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.items, "items");

    this.updateSyncCollProperty("items", this.props.items);

    this.computeCurrentDate();

    this.on(
      ["currentDate", "date", "dateBgColor", "items", "month"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: DateBoxViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.date !== this.props.date) {
      this.fire("date", this);
    }

    if (prevProps.month !== this.props.month) {
      this.fire("month", this);
    }

    if (prevProps.items !== this.props.items) {
      this.updateObservableColl("items", prevProps.items, this.props.items);

      this.fire("items", this);
    }

    if (prevProps.dateBgColor !== this.props.dateBgColor) {
      this.fire("dateBgColor", this);
    }
  }
  public setCurrentDate(val: D3EDate): void {
    let isValChanged: boolean = this.currentDate !== val;

    if (!isValChanged) {
      return;
    }

    this.currentDate = val;

    this.fire("currentDate", this);
  }
  public computeCurrentDate = (): void => {
    try {
      this.setCurrentDate(D3EDate.now());
    } catch (exception) {
      console.log(" exception in computeCurrentDate : " + exception.toString());

      this.setCurrentDate(null);
    }
  };
  public render(): ReactNode {
    return DateColumnWithState({
      d3eState: this.d3eState,
      _onPressed: this.onPressed,
      currentDate: this.currentDate,
      date: this.date,
      dateBgColor: this.dateBgColor,
      items: this.items,
      month: this.month,
      className: this.props.className,
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressed = (d3eState: _EventState): void => {
    if (this.pressedOnEvent !== null) {
      this.pressedOnEvent(d3eState.event);
    }
  };
  public get pressedOnEvent(): _DateBoxViewPressedOnEvent {
    return this.props.pressedOnEvent;
  }
  public get dateColumn() {
    return this.d3eState.dateColumn;
  }
}
export default function DateBoxView(props: DateBoxViewProps) {
  return React.createElement(
    _DateBoxViewState,
    { ..._DateBoxViewState.defaultProps, ...props },
    ListWrapper.fromInput<CalendarEvent>(props.items, "items")
  );
}
