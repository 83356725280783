import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import MonthYearView from "./MonthYearView";
import CalendarHelper from "../classes/CalendarHelper";
import ListWrapper from "../utils/ListWrapper";
import D3EDate from "../classes/D3EDate";

type _YearViewOnDateSelected = (date: D3EDate) => void;

export interface YearViewProps extends BaseUIProps {
  key?: string;
  input: D3EDate;
  categories: Array<string>;
  helper: CalendarHelper;
  yearWeekBgColor?: ui.Color;
  currentDateColor?: ui.Color;
  _categoriesHash?: number;
  onDateSelected: _YearViewOnDateSelected;
}

class _YearViewState extends ObservableComponent<YearViewProps> {
  static defaultProps = {
    input: null,
    helper: null,
    yearWeekBgColor: null,
    currentDateColor: null,
    categories: [],
    onDateSelected: null,
  };
  public constructor(props: YearViewProps) {
    super(props);

    this.initState();
  }
  public get input(): D3EDate {
    return this.props.input;
  }
  public get categories(): Array<string> {
    return this.props.categories;
  }
  public get helper(): CalendarHelper {
    return this.props.helper;
  }
  public get yearWeekBgColor(): ui.Color {
    return this.props.yearWeekBgColor;
  }
  public get currentDateColor(): ui.Color {
    return this.props.currentDateColor;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.categories, "categories");

    this.on(
      ["categories", "currentDateColor", "helper", "input", "yearWeekBgColor"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: YearViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.input !== this.props.input) {
      this.fire("input", this);
    }

    if (prevProps.categories !== this.props.categories) {
      this.fire("categories", this);
    }

    if (prevProps.helper !== this.props.helper) {
      this.fire("helper", this);
    }

    if (prevProps.yearWeekBgColor !== this.props.yearWeekBgColor) {
      this.fire("yearWeekBgColor", this);
    }

    if (prevProps.currentDateColor !== this.props.currentDateColor) {
      this.fire("currentDateColor", this);
    }
  }
  public render(): ReactNode {
    return ui.Column({
      children: [
        CalendarHelper.to(4).expand((i) => [
          ui.Row({
            children: [
              CalendarHelper.to(3).expand((j) => [
                ui.Container({
                  expand: true,
                  margin: ui.EdgeInsets.all(10.0, new Map()),
                  child: MonthYearView({
                    date: D3EDate.of(this.input.year, (i - 1) * 3 + j, 1),
                    helper: this.helper,
                    weekNameBgColor: this.yearWeekBgColor,
                    currentDateColor: this.currentDateColor,
                    categories: this.categories,
                    onDateSelected: (date) => {
                      this.onDatePressed(date, j, i);
                    },
                  }),
                  key: j?.toString(),
                  className: "x55f hc h",
                }),
              ]),
            ],
            className: "xe14 hc h",
            key: i?.toString(),
          }),
        ]),
      ],
      className: ui.join(this.props.className, "YearView x8087f hc vc h v"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onDatePressed = (date: D3EDate, j: number, i: number): void => {
    this.onDateSelected(date);
  };
  public get onDateSelected(): _YearViewOnDateSelected {
    return this.props.onDateSelected;
  }
}
export default function YearView(props: YearViewProps) {
  return React.createElement(
    _YearViewState,
    { ..._YearViewState.defaultProps, ...props },
    ListWrapper.fromInput<string>(props.categories, "categories")
  );
}
