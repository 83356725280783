import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import TextView from "./TextView";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

export interface FeaturesViewProps extends BaseUIProps {
  key?: string;
  icon: ui.IconData;
  title: string;
  description: string;
}

class _FeaturesViewState extends ObservableComponent<FeaturesViewProps> {
  static defaultProps = { icon: null, title: "", description: "" };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: FeaturesViewProps) {
    super(props);

    this.initState();
  }
  public get icon(): ui.IconData {
    return this.props.icon;
  }
  public get title(): string {
    return this.props.title;
  }
  public get description(): string {
    return this.props.description;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["description", "icon", "title"], this.rebuild);
  }
  public componentDidUpdate(prevProps: FeaturesViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.icon !== this.props.icon) {
      this.fire("icon", this);
    }

    if (prevProps.title !== this.props.title) {
      this.fire("title", this);
    }

    if (prevProps.description !== this.props.description) {
      this.fire("description", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          crossAxisAlignment: ui.CrossAxisAlignment.center,
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [
            IconView({
              icon: this.icon,
              size: 45,
              color: new ui.Color(0xff3a73ed),
              className: "xbd6 vc",
              key: "0",
            }),
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                TextView({
                  data: this.title,
                  softWrap: true,
                  style: new ui.TextStyle({
                    fontWeight: ui.FontWeight.w700,
                    fontSize: 22,
                  }),
                  className: "xd0",
                  key: "0",
                }),
                TextView({
                  data: this.description,
                  softWrap: true,
                  style: new ui.TextStyle({ fontSize: 17, height: 1.5 }),
                  className: "x774",
                  key: "1",
                }),
              ],
              className: "xe1 vc v",
              key: "1",
            }),
          ],
          className: "x0a4 hc vc h",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "FeaturesView xe46 hc"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function FeaturesView(props: FeaturesViewProps) {
  return React.createElement(_FeaturesViewState, {
    ..._FeaturesViewState.defaultProps,
    ...props,
  });
}
