import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

export interface FooterProps extends BaseUIProps {
  key?: string;
}
/// To store state data for Footer
class FooterRefs {
  public button: ButtonState = new ButtonState();
  public button2: Button2State = new Button2State();
  public button3: Button3State = new Button3State();
  public button4: Button4State = new Button4State();
  public button5: Button5State = new Button5State();
  public button6: Button6State = new Button6State();
  public button7: Button7State = new Button7State();
}

interface Button7WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: FooterRefs;
}

class Button7State extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _Button7WithState extends ObservableComponent<Button7WithStateProps> {
  button7FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Button7WithStateProps) {
    super(props);

    this.initState();
  }
  public get button7(): Button7State {
    return this.props.d3eState.button7;
  }
  public get d3eState(): FooterRefs {
    return this.props.d3eState;
  }
  public initState() {
    super.initState();

    this.updateObservable("button7", null, this.button7);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button7"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.button7.disable,
      onFocusChange: (val) => {},
      child: TextView({
        data: "Terms and Conditions",
        style: new ui.TextStyle({
          fontSize: 16,
          decoration: ui.TextDecoration.underline,
          color: cStyle.c2,
        }),
        className: "x5d6",
      }),
    });
  }
}
function Button7WithState(props: Button7WithStateProps) {
  return React.createElement(_Button7WithState, props);
}

interface Button6WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: FooterRefs;
}

class Button6State extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _Button6WithState extends ObservableComponent<Button6WithStateProps> {
  button6FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Button6WithStateProps) {
    super(props);

    this.initState();
  }
  public get button6(): Button6State {
    return this.props.d3eState.button6;
  }
  public get d3eState(): FooterRefs {
    return this.props.d3eState;
  }
  public initState() {
    super.initState();

    this.updateObservable("button6", null, this.button6);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button6"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 20.0, 0.0, new Map()),
      child: Button({
        disable: this.button6.disable,
        onFocusChange: (val) => {},
        child: TextView({
          data: "Privacy Policy",
          style: new ui.TextStyle({
            decoration: ui.TextDecoration.underline,
            color: cStyle.c2,
            fontSize: 16,
          }),
          className: "x82c",
        }),
      }),
      className: "xa56",
    });
  }
}
function Button6WithState(props: Button6WithStateProps) {
  return React.createElement(_Button6WithState, props);
}

interface Button5WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: FooterRefs;
}

class Button5State extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _Button5WithState extends ObservableComponent<Button5WithStateProps> {
  button5FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Button5WithStateProps) {
    super(props);

    this.initState();
  }
  public get button5(): Button5State {
    return this.props.d3eState.button5;
  }
  public get d3eState(): FooterRefs {
    return this.props.d3eState;
  }
  public initState() {
    super.initState();

    this.updateObservable("button5", null, this.button5);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button5"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.button5.disable,
      onFocusChange: (val) => {},
      child: TextView({
        data: "Portfolio",
        style: new ui.TextStyle({ color: cStyle.c2, fontSize: 16 }),
        className: "xcf",
      }),
    });
  }
}
function Button5WithState(props: Button5WithStateProps) {
  return React.createElement(_Button5WithState, props);
}

interface Button4WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: FooterRefs;
}

class Button4State extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _Button4WithState extends ObservableComponent<Button4WithStateProps> {
  button4FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Button4WithStateProps) {
    super(props);

    this.initState();
  }
  public get button4(): Button4State {
    return this.props.d3eState.button4;
  }
  public get d3eState(): FooterRefs {
    return this.props.d3eState;
  }
  public initState() {
    super.initState();

    this.updateObservable("button4", null, this.button4);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button4"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 10.0,
        transitions: new Map(),
      }),
      child: Button({
        disable: this.button4.disable,
        onFocusChange: (val) => {},
        child: TextView({
          data: "Careers",
          style: new ui.TextStyle({ color: cStyle.c2, fontSize: 16 }),
          className: "x1f1",
        }),
      }),
      className: "xcee",
    });
  }
}
function Button4WithState(props: Button4WithStateProps) {
  return React.createElement(_Button4WithState, props);
}

interface Button3WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: FooterRefs;
}

class Button3State extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _Button3WithState extends ObservableComponent<Button3WithStateProps> {
  button3FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Button3WithStateProps) {
    super(props);

    this.initState();
  }
  public get button3(): Button3State {
    return this.props.d3eState.button3;
  }
  public get d3eState(): FooterRefs {
    return this.props.d3eState;
  }
  public initState() {
    super.initState();

    this.updateObservable("button3", null, this.button3);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button3"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.button3.disable,
      onFocusChange: (val) => {},
      child: TextView({
        data: "News",
        style: new ui.TextStyle({ fontSize: 16, color: cStyle.c2 }),
        className: "x803",
      }),
    });
  }
}
function Button3WithState(props: Button3WithStateProps) {
  return React.createElement(_Button3WithState, props);
}

interface Button2WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: FooterRefs;
}

class Button2State extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _Button2WithState extends ObservableComponent<Button2WithStateProps> {
  button2FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Button2WithStateProps) {
    super(props);

    this.initState();
  }
  public get button2(): Button2State {
    return this.props.d3eState.button2;
  }
  public get d3eState(): FooterRefs {
    return this.props.d3eState;
  }
  public initState() {
    super.initState();

    this.updateObservable("button2", null, this.button2);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button2"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 10.0, 0.0, 0.0, new Map()),
      child: Button({
        disable: this.button2.disable,
        onFocusChange: (val) => {},
        child: TextView({
          data: "Management",
          style: new ui.TextStyle({ color: cStyle.c2, fontSize: 16 }),
          className: "x73",
        }),
      }),
      className: "x30d",
    });
  }
}
function Button2WithState(props: Button2WithStateProps) {
  return React.createElement(_Button2WithState, props);
}

interface ButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: FooterRefs;
}

class ButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _ButtonWithState extends ObservableComponent<ButtonWithStateProps> {
  buttonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get button(): ButtonState {
    return this.props.d3eState.button;
  }
  public get d3eState(): FooterRefs {
    return this.props.d3eState;
  }
  public initState() {
    super.initState();

    this.updateObservable("button", null, this.button);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.button.disable,
      onFocusChange: (val) => {},
      child: TextView({
        data: "Company Profile",
        style: new ui.TextStyle({ color: cStyle.c2 }),
        className: "xd58",
      }),
    });
  }
}
function ButtonWithState(props: ButtonWithStateProps) {
  return React.createElement(_ButtonWithState, props);
}

class _FooterState extends ObservableComponent<FooterProps> {
  d3eState: FooterRefs = new FooterRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: FooterProps) {
    super(props);

    this.initState();
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {}
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.AssetImage({
                  path: "images/faciliRestm1.png",
                  width: 150,
                  className: "xb4 hc",
                  key: "0",
                }),
                TextView({
                  data: "Facilitron helps community members search and request \n public event spaces in their local area.",
                  softWrap: true,
                  style: new ui.TextStyle({ color: cStyle.c2, fontSize: 16 }),
                  className: "x81 hc",
                  key: "1",
                }),
              ],
              key: "0",
            }),
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                TextView({
                  data: "About Us",
                  style: new ui.TextStyle({
                    color: cStyle.c2,
                    fontSize: cStyle.tTextViewHeadlineThreeFontSizeOn,
                    fontWeight: cStyle.tTextViewHeadlineThreeFontWeightOn,
                  }),
                  className: "xbbc",
                  key: "0",
                }),
                ButtonWithState({ d3eState: this.d3eState, key: "1" }),
                Button2WithState({ d3eState: this.d3eState, key: "2" }),
              ],
              key: "1",
            }),
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                TextView({
                  data: "CONTACT US",
                  style: new ui.TextStyle({
                    color: cStyle.c2,
                    fontSize: cStyle.tTextViewHeadlineThreeFontSizeOn,
                    fontWeight: cStyle.tTextViewHeadlineThreeFontWeightOn,
                  }),
                  className: "x232",
                  key: "0",
                }),
                ui.Wrap({
                  crossAxisAlignment: ui.WrapCrossAlignment.center,
                  children: [
                    IconView({
                      size: 24,
                      icon: MaterialIcons.local_phone,
                      color: cStyle.c2,
                      className: "xb71",
                      key: "0",
                    }),
                    TextView({
                      data: "510-378-8077",
                      softWrap: true,
                      style: new ui.TextStyle({
                        color: cStyle.c2,
                        fontSize: 16,
                      }),
                      className: "xfd4",
                      key: "1",
                    }),
                  ],
                  className: "xd165",
                  key: "1",
                }),
                ui.Row({
                  crossAxisAlignment: ui.CrossAxisAlignment.center,
                  children: [
                    IconView({
                      size: 24,
                      icon: MaterialIcons.location_on,
                      color: cStyle.c2,
                      className: "xa60",
                      key: "0",
                    }),
                    TextView({
                      data: "392 E Winchester St, Suite 201,. Murray, Utah 84107",
                      softWrap: true,
                      style: new ui.TextStyle({
                        color: cStyle.c2,
                        fontSize: 16,
                      }),
                      className: "xb0d",
                      key: "1",
                    }),
                  ],
                  className: "xe9",
                  key: "2",
                }),
                ui.Row({
                  crossAxisAlignment: ui.CrossAxisAlignment.center,
                  children: [
                    IconView({
                      size: 24,
                      icon: MaterialIcons.email,
                      color: cStyle.c2,
                      className: "x847",
                      key: "0",
                    }),
                    TextView({
                      data: "contact@facilires.com",
                      softWrap: true,
                      style: new ui.TextStyle({
                        color: cStyle.c2,
                        fontSize: 16,
                      }),
                      className: "xa5e",
                      key: "1",
                    }),
                  ],
                  className: "xc18d",
                  key: "3",
                }),
              ],
              key: "2",
            }),
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                TextView({
                  data: "QUICK LINKS",
                  style: new ui.TextStyle({
                    color: cStyle.c2,
                    fontSize: cStyle.tTextViewHeadlineThreeFontSizeOn,
                    fontWeight: cStyle.tTextViewHeadlineThreeFontWeightOn,
                  }),
                  className: "x548",
                  key: "0",
                }),
                Button3WithState({ d3eState: this.d3eState, key: "1" }),
                Button4WithState({ d3eState: this.d3eState, key: "2" }),
                Button5WithState({ d3eState: this.d3eState, key: "3" }),
              ],
              key: "3",
            }),
          ],
          className: "x7ce hc h",
          key: "0",
        }),
        ui.Row({ className: "xe2c hc h", key: "1" }),
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            TextView({
              data: "\u00A92024 Facilires. All Rights Reserved",
              softWrap: true,
              style: new ui.TextStyle({ color: cStyle.c2, fontSize: 16 }),
              className: "xbbf",
              key: "0",
            }),
            ui.Wrap({
              children: [
                Button6WithState({ d3eState: this.d3eState, key: "0" }),
                Button7WithState({ d3eState: this.d3eState, key: "1" }),
              ],
              key: "1",
            }),
          ],
          className: "xa99 hc h",
          key: "2",
        }),
      ],
      className: ui.join(this.props.className, "Footer x441 hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public get button() {
    return this.d3eState.button;
  }
  public get button2() {
    return this.d3eState.button2;
  }
  public get button3() {
    return this.d3eState.button3;
  }
  public get button4() {
    return this.d3eState.button4;
  }
  public get button5() {
    return this.d3eState.button5;
  }
  public get button6() {
    return this.d3eState.button6;
  }
  public get button7() {
    return this.d3eState.button7;
  }
}
export default function Footer(props: FooterProps) {
  return React.createElement(_FooterState, props);
}
