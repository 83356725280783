import Equipment from "./Equipment";
import FacilityType from "./FacilityType";
import ListWrapper from "../utils/ListWrapper";
import School from "./School";
import DFile from "../classes/DFile";
import Service from "./Service";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";
import CollectionUtils from "../utils/CollectionUtils";
import FacilityRate from "./FacilityRate";

export default class Facility extends DBObject {
  private static readonly _EQUIPMENTS: number = 0;
  private static readonly _IMAGES: number = 1;
  private static readonly _NAME: number = 2;
  private static readonly _PRICES: number = 3;
  private static readonly _SCHOOL: number = 4;
  private static readonly _SERVICES: number = 5;
  private static readonly _TYPE: number = 6;
  public id: number = 0;
  public otherMaster: DBObject;
  private _name: string = "";
  private _type: FacilityType = null;
  private _services: Array<Service> = ListWrapper.reference(
    this,
    "services",
    Facility._SERVICES
  );
  private _equipments: Array<Equipment> = ListWrapper.reference(
    this,
    "equipments",
    Facility._EQUIPMENTS
  );
  private _prices: Array<FacilityRate> = ListWrapper.child(
    this,
    "prices",
    Facility._PRICES
  );
  private _images: Array<DFile> = ListWrapper.primitive(
    this,
    "images",
    Facility._IMAGES
  );
  private _school: School = null;
  public constructor(
    d3eParams?: Partial<{
      equipments: Array<Equipment>;
      images: Array<DFile>;
      name: string;
      prices: Array<FacilityRate>;
      school: School;
      services: Array<Service>;
      type: FacilityType;
    }>
  ) {
    super();

    this.setEquipments(
      d3eParams?.equipments ??
        ListWrapper.reference(this, "equipments", Facility._EQUIPMENTS)
    );

    this.setImages(
      d3eParams?.images ??
        ListWrapper.primitive(this, "images", Facility._IMAGES)
    );

    this.setName(d3eParams?.name ?? "");

    this.setPrices(
      d3eParams?.prices ?? ListWrapper.child(this, "prices", Facility._PRICES)
    );

    this.setSchool(d3eParams?.school ?? null);

    this.setServices(
      d3eParams?.services ??
        ListWrapper.reference(this, "services", Facility._SERVICES)
    );

    this.setType(d3eParams?.type ?? null);
  }
  public get d3eType(): string {
    return "Facility";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get name(): string {
    return this._name;
  }
  public setName(val: string): void {
    let isValChanged: boolean = this._name !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Facility._NAME, this._name);

    this._name = val;

    this.fire("name", this);
  }
  public get type(): FacilityType {
    return this._type;
  }
  public setType(val: FacilityType): void {
    let isValChanged: boolean = this._type !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Facility._TYPE, this._type);

    this.updateObservable("type", this._type, val);

    this._type = val;

    this.fire("type", this);
  }
  public get services(): Array<Service> {
    return this._services;
  }
  public setServices(val: Array<Service>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._services,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(Facility._SERVICES)) {
      let _old: Array<Service> = Array.from(this._services);

      this.updateD3EChanges(Facility._SERVICES, _old);
    }

    this.updateObservableColl("services", this._services, val);

    this._services.clear();

    this._services.addAll(val);

    this.fire("services", this);
  }
  public addToServices(val: Service, index: number = -1): void {
    let _old: Array<Service> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Facility._SERVICES);

    if (_isNewChange) {
      _old = Array.from(this._services);
    }

    if (index === -1) {
      if (!this._services.contains(val)) this._services.add(val);
    } else {
      this._services.remove(this._services.elementAt(index));

      this._services.add(val);
    }

    this.fire("services", this, val, true);

    this.updateObservable("services", null, val);

    if (_isNewChange) {
      this.updateD3EChanges(Facility._SERVICES, _old);
    }
  }
  public removeFromServices(val: Service): void {
    let _old: Array<Service> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Facility._SERVICES);

    if (_isNewChange) {
      _old = Array.from(this._services);
    }

    this._services.remove(val);

    this.fire("services", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(Facility._SERVICES, _old);
    }
  }
  public get equipments(): Array<Equipment> {
    return this._equipments;
  }
  public setEquipments(val: Array<Equipment>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._equipments,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(Facility._EQUIPMENTS)) {
      let _old: Array<Equipment> = Array.from(this._equipments);

      this.updateD3EChanges(Facility._EQUIPMENTS, _old);
    }

    this.updateObservableColl("equipments", this._equipments, val);

    this._equipments.clear();

    this._equipments.addAll(val);

    this.fire("equipments", this);
  }
  public addToEquipments(val: Equipment, index: number = -1): void {
    let _old: Array<Equipment> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Facility._EQUIPMENTS);

    if (_isNewChange) {
      _old = Array.from(this._equipments);
    }

    if (index === -1) {
      if (!this._equipments.contains(val)) this._equipments.add(val);
    } else {
      this._equipments.remove(this._equipments.elementAt(index));

      this._equipments.add(val);
    }

    this.fire("equipments", this, val, true);

    this.updateObservable("equipments", null, val);

    if (_isNewChange) {
      this.updateD3EChanges(Facility._EQUIPMENTS, _old);
    }
  }
  public removeFromEquipments(val: Equipment): void {
    let _old: Array<Equipment> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Facility._EQUIPMENTS);

    if (_isNewChange) {
      _old = Array.from(this._equipments);
    }

    this._equipments.remove(val);

    this.fire("equipments", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(Facility._EQUIPMENTS, _old);
    }
  }
  public get prices(): Array<FacilityRate> {
    return this._prices;
  }
  public setPrices(val: Array<FacilityRate>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this._prices, val);

    if (!isValChanged) {
      return;
    }

    if (this._prices != null) {
      this._prices.forEach((one) => (one.otherMaster = null));
    }

    if (val != null) {
      for (let o of val) {
        o.updateMaster(this, Facility._PRICES);
      }
    }

    if (!this.d3eChanges.contains(Facility._PRICES)) {
      let _old: Array<FacilityRate> = Array.from(this._prices);

      this.updateD3EChanges(Facility._PRICES, _old);
    }

    this.updateObservableColl("prices", this._prices, val);

    this._prices.clear();

    this._prices.addAll(val);

    this.fire("prices", this);
  }
  public addToPrices(val: FacilityRate, index: number = -1): void {
    let _old: Array<FacilityRate> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Facility._PRICES);

    if (_isNewChange) {
      _old = Array.from(this._prices);
    }

    val.otherMaster = this;

    val.childPropertyInMaster = Facility._PRICES;

    if (index === -1) {
      if (!this._prices.contains(val)) this._prices.add(val);
    } else {
      this._prices.remove(this._prices.elementAt(index));

      this._prices.add(val);
    }

    this.fire("prices", this, val, true);

    this.updateObservable("prices", null, val);

    if (_isNewChange) {
      this.updateD3EChanges(Facility._PRICES, _old);
    }
  }
  public removeFromPrices(val: FacilityRate): void {
    let _old: Array<FacilityRate> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Facility._PRICES);

    if (_isNewChange) {
      _old = Array.from(this._prices);
    }

    this._prices.remove(val);

    val.otherMaster = null;

    this.fire("prices", this, val, false);

    this.removeObservable("prices", val);

    if (_isNewChange) {
      this.updateD3EChanges(Facility._PRICES, _old);
    }
  }
  public get images(): Array<DFile> {
    return this._images;
  }
  public setImages(val: Array<DFile>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this._images, val);

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(Facility._IMAGES)) {
      let _old: Array<DFile> = Array.from(this._images);

      this.updateD3EChanges(Facility._IMAGES, _old);
    }

    this._images.clear();

    this._images.addAll(val);

    this.fire("images", this);
  }
  public addToImages(val: DFile, index: number = -1): void {
    let _old: Array<DFile> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Facility._IMAGES);

    if (_isNewChange) {
      _old = Array.from(this._images);
    }

    if (index === -1) {
      if (!this._images.contains(val)) this._images.add(val);
    } else {
      this._images.remove(this._images.elementAt(index));

      this._images.add(val);
    }

    this.fire("images", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(Facility._IMAGES, _old);
    }
  }
  public removeFromImages(val: DFile): void {
    let _old: Array<DFile> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Facility._IMAGES);

    if (_isNewChange) {
      _old = Array.from(this._images);
    }

    this._images.remove(val);

    this.fire("images", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(Facility._IMAGES, _old);
    }
  }
  public get school(): School {
    return this._school;
  }
  public setSchool(val: School): void {
    let isValChanged: boolean = this._school !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Facility._SCHOOL, this._school);

    this.updateObservable("school", this._school, val);

    this._school = val;

    this.fire("school", this);
  }
  public get(field: number): any {
    switch (field) {
      case Facility._NAME: {
        return this._name;
      }

      case Facility._TYPE: {
        return this._type;
      }

      case Facility._SERVICES: {
        return this._services;
      }

      case Facility._EQUIPMENTS: {
        return this._equipments;
      }

      case Facility._PRICES: {
        return this._prices;
      }

      case Facility._IMAGES: {
        return this._images;
      }

      case Facility._SCHOOL: {
        return this._school;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): Facility {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {
    ctx.collectChilds(this._prices);
  }
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: Facility = dbObj as Facility;

    obj.id = this.id;

    obj.setName(this._name);

    obj.setType(this._type);

    obj.setServices(this._services);

    obj.setEquipments(this._equipments);

    ctx.cloneChildList(this._prices, (v) => obj.setPrices(v));

    obj.setImages(this._images);

    obj.setSchool(this._school);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case Facility._NAME: {
        this.setName(value as string);
        break;
      }

      case Facility._TYPE: {
        this.setType(value as FacilityType);
        break;
      }

      case Facility._SERVICES: {
        this.setServices((value as Array<any>).cast<Service>().toList());
        break;
      }

      case Facility._EQUIPMENTS: {
        this.setEquipments((value as Array<any>).cast<Equipment>().toList());
        break;
      }

      case Facility._PRICES: {
        this.setPrices((value as Array<any>).cast<FacilityRate>().toList());
        break;
      }

      case Facility._IMAGES: {
        this.setImages((value as Array<any>).cast<DFile>().toList());
        break;
      }

      case Facility._SCHOOL: {
        this.setSchool(value as School);
        break;
      }
    }
  }
}
