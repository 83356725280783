import PaymentServiceClient from "./PaymentServiceClient";
import FileServiceClient from "./FileServiceClient";
import UniqueCheckerClient from "./UniqueCheckerClient";

export default class RPCServices {
  private static _uniqueChecker: UniqueCheckerClient;
  private static _fileService: FileServiceClient;
  private static _paymentService: PaymentServiceClient;
  public static getUniqueChecker(): UniqueCheckerClient {
    if (RPCServices._uniqueChecker == null) {
      RPCServices._uniqueChecker = new UniqueCheckerClient();
    }

    return RPCServices._uniqueChecker;
  }
  public static getFileService(): FileServiceClient {
    if (RPCServices._fileService == null) {
      RPCServices._fileService = new FileServiceClient();
    }

    return RPCServices._fileService;
  }
  public static getPaymentService(): PaymentServiceClient {
    if (RPCServices._paymentService == null) {
      RPCServices._paymentService = new PaymentServiceClient();
    }

    return RPCServices._paymentService;
  }
}
