import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import ResultStatus from "../classes/ResultStatus";
import D3EDate from "../classes/D3EDate";
import RentalRequest from "../models/RentalRequest";
import Popup from "./Popup";
import MaterialIcons from "../icons/MaterialIcons";
import PageNavigator from "../classes/PageNavigator";
import Renter from "../models/Renter";
import PolicesView from "./PolicesView";
import FacilitiesMapView from "./FacilitiesMapView";
import Result from "../classes/Result";
import Query from "../classes/Query";
import MessageDispatch from "../rocket/MessageDispatch";
import Button from "./Button";
import RentalBookingsRequest from "../models/RentalBookingsRequest";
import ListWrapper from "../utils/ListWrapper";
import CheckServerErrors from "./CheckServerErrors";
import TextView from "./TextView";
import DFile from "../classes/DFile";
import Facility from "../models/Facility";
import LightDivider from "./LightDivider";
import CollectionUtils from "../utils/CollectionUtils";
import Equipment from "../models/Equipment";
import PopupTargetController from "./PopupTargetController";
import CalenderView from "./CalenderView";
import D3EDisposable from "../rocket/D3EDisposable";
import SuccessMessage from "../classes/SuccessMessage";
import FooterView from "./FooterView";
import EventBus from "../utils/EventBus";
import Time from "../classes/Time";
import FacilityRate from "../models/FacilityRate";
import LabelTimeSlotField from "./LabelTimeSlotField";
import HeaderView from "./HeaderView";
import RentalRequestUtils from "../classes/RentalRequestUtils";
import Service from "../models/Service";
import CheckboxWithText from "./CheckboxWithText";
import CategoiresList from "./CategoiresList";
import IconView from "./IconView";
import LabelWithStringField from "./LabelWithStringField";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _MakeChangesButtonOnPressed = (d3eState: BookingPageRefs) => void;

type _LabelTimeSlotFieldRefOnChanged = (
  text: Time,
  d3eState: BookingPageRefs
) => void;

type _LabelTimeSlotFieldRef1OnChanged = (
  text: Time,
  d3eState: BookingPageRefs
) => void;

type _LabelWithStringFieldRefOnChanged = (
  text: string,
  d3eState: BookingPageRefs
) => void;

export interface BookingPageProps extends BaseUIProps {
  key?: string;
  user: Renter;
  facility: Facility;
}
/// To store state data for BookingPage
class BookingPageRefs {
  public makeChangesButton: MakeChangesButtonState =
    new MakeChangesButtonState();
}

interface MakeChangesButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: BookingPageRefs;
  _onChangesButtonHandler?: _MakeChangesButtonOnPressed;
}

class MakeChangesButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _MakeChangesButtonWithState extends ObservableComponent<MakeChangesButtonWithStateProps> {
  makeChangesButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: MakeChangesButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get makeChangesButton(): MakeChangesButtonState {
    return this.props.d3eState.makeChangesButton;
  }
  public get d3eState(): BookingPageRefs {
    return this.props.d3eState;
  }
  public get _onChangesButtonHandler(): _MakeChangesButtonOnPressed {
    return this.props._onChangesButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("makeChangesButton", null, this.makeChangesButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["makeChangesButton"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.symmetric({
        horizontal: 10.0,
        vertical: 0.0,
        transitions: new Map(),
      }),
      child: Button({
        padding: ui.EdgeInsets.all(10.0, new Map()),
        decoration: cStyle.tButtonPrimaryButtonDecorationOn,
        disable: this.makeChangesButton.disable,
        onPressed: () => {
          this._onChangesButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({
          data: "Book Now",
          style: new ui.TextStyle({ color: cStyle.c2 }),
          className: "x61 hc",
        }),
      }),
      className: "x28a hc",
    });
  }
}
function MakeChangesButtonWithState(props: MakeChangesButtonWithStateProps) {
  return React.createElement(_MakeChangesButtonWithState, props);
}

class _BookingPageState extends ObservableComponent<BookingPageProps> {
  static defaultProps = { user: null, facility: null };
  d3eState: BookingPageRefs = new BookingPageRefs();
  request: RentalRequest = null;
  selectedRate: FacilityRate = null;
  totalHours: number = 0;
  total: number = 0.0;
  totalServices: number = 0.0;
  totalEquipements: number = 0.0;
  allBookings: Array<RentalRequest> = ListWrapper.widget(this, "allBookings");
  errors: Array<string> = ListWrapper.widget(this, "errors");
  categorySelectionViewPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public categoryTextViewPopupTargetController: PopupTargetController =
    new PopupTargetController();
  public facilityDisposable: D3EDisposable;
  public constructor(props: BookingPageProps) {
    super(props);

    this.initState();
  }
  public get user(): Renter {
    return this.props.user;
  }
  public get facility(): Facility {
    return this.props.facility;
  }
  public initState() {
    super.initState();

    this.runFetchDataQueryForFacility();

    this.facilityDisposable = MessageDispatch.get().syncObject(
      this.props.facility,
      UsageConstants.QUERY_GETFACILITYBYID_BOOKINGPAGE_FACILITY_FETCHDATA
    );

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.updateSyncProperty("facility", this.props.facility);

    this.on(["facility"], this.computeRequest);

    this.computeRequest();

    this.on(
      ["request", "request.endTime", "request.startTime"],
      this.computeTotalHours
    );

    this.computeTotalHours();

    this.on(
      [
        "request",
        "request.price",
        "totalEquipements",
        "totalHours",
        "totalServices",
      ],
      this.computeTotal
    );

    this.computeTotal();

    this.on(
      [
        "request",
        "request.services",
        "request.services.ratePerHour",
        "totalHours",
      ],
      this.computeTotalServices
    );

    this.computeTotalServices();

    this.on(
      [
        "request",
        "request.equipments",
        "request.equipments.ratePerHour",
        "totalHours",
      ],
      this.computeTotalEquipements
    );

    this.computeTotalEquipements();

    this.on(
      ["facility", "request", "request.eventDate"],
      this.computeAllBookings
    );

    this.computeAllBookings();

    this.on(
      [
        "allBookings",
        "errors",
        "facility",
        "facility.equipments",
        "facility.equipments.name",
        "facility.equipments.ratePerHour",
        "facility.images",
        "facility.name",
        "facility.school",
        "facility.school.address",
        "facility.school.city",
        "facility.school.pincode",
        "facility.school.state",
        "facility.services",
        "facility.services.name",
        "facility.services.ratePerHour",
        "facility.type",
        "facility.type.policies",
        "request",
        "request.endTime",
        "request.equipments",
        "request.eventDate",
        "request.eventName",
        "request.price",
        "request.services",
        "request.startTime",
        "selectedRate",
        "selectedRate.category",
        "selectedRate.category.description",
        "selectedRate.category.name",
        "total",
        "totalEquipements",
        "totalServices",
        "user",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: BookingPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.facility !== this.props.facility) {
      this.updateObservable(
        "facility",
        prevProps.facility,
        this.props.facility
      );

      this.runFetchDataQueryForFacility();

      this.fire("facility", this);
    }
  }
  public runFetchDataQueryForFacility = (): void => {
    if (this.facility == null) {
      return;
    }

    Query.get().getFacilityById(
      UsageConstants.QUERY_GETFACILITYBYID_BOOKINGPAGE_FACILITY_FETCHDATA,
      this.facility.id
    );
  };
  public setRequest(val: RentalRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public computeRequest = (): void => {
    try {
      this.setRequest(
        new RentalRequest({ facility: this.facility, eventDate: D3EDate.now() })
      );
    } catch (exception) {
      console.log(" exception in computeRequest : " + exception.toString());

      this.setRequest(null);
    }
  };
  public setSelectedRate(val: FacilityRate): void {
    let isValChanged: boolean = this.selectedRate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("selectedRate", this.selectedRate, val);

    this.selectedRate = val;

    this.changedValue();

    this.fire("selectedRate", this);
  }
  public setTotalHours(val: number): void {
    let isValChanged: boolean = this.totalHours !== val;

    if (!isValChanged) {
      return;
    }

    this.totalHours = val;

    this.fire("totalHours", this);
  }
  public computeTotalHours = (): void => {
    try {
      this.setTotalHours(
        this.request.startTime === null || this.request.endTime === null
          ? 1
          : this.request.endTime.minus(this.request.startTime).inHours
      );
    } catch (exception) {
      console.log(" exception in computeTotalHours : " + exception.toString());

      this.setTotalHours(0);
    }
  };
  public setTotal(val: number): void {
    let isValChanged: boolean = this.total !== val;

    if (!isValChanged) {
      return;
    }

    this.total = val;

    this.fire("total", this);
  }
  public computeTotal = (): void => {
    try {
      this.setTotal(
        this.request.price * this.totalHours +
          this.totalServices +
          this.totalEquipements
      );
    } catch (exception) {
      console.log(" exception in computeTotal : " + exception.toString());

      this.setTotal(0.0);
    }
  };
  public setTotalServices(val: number): void {
    let isValChanged: boolean = this.totalServices !== val;

    if (!isValChanged) {
      return;
    }

    this.totalServices = val;

    this.fire("totalServices", this);
  }
  public computeTotalServices = (): void => {
    try {
      this.setTotalServices(
        this.request.services.fold(
          0.0,
          (p, s) => p + s.ratePerHour * this.totalHours
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeTotalServices : " + exception.toString()
      );

      this.setTotalServices(0.0);
    }
  };
  public setTotalEquipements(val: number): void {
    let isValChanged: boolean = this.totalEquipements !== val;

    if (!isValChanged) {
      return;
    }

    this.totalEquipements = val;

    this.fire("totalEquipements", this);
  }
  public computeTotalEquipements = (): void => {
    try {
      this.setTotalEquipements(
        this.request.equipments.fold(
          0.0,
          (p, e) => p + e.ratePerHour * this.totalHours
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeTotalEquipements : " + exception.toString()
      );

      this.setTotalEquipements(0.0);
    }
  };
  public setAllBookings(val: Array<RentalRequest>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.allBookings,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("allBookings", this.allBookings, val);

    this.allBookings.clear();

    this.allBookings.addAll(val);

    this.fire("allBookings", this);
  }
  public addToAllBookings(val: RentalRequest, index: number = -1): void {
    if (index === -1) {
      if (!this.allBookings.contains(val)) this.allBookings.add(val);
    } else {
      this.allBookings.remove(this.allBookings.elementAt(index));

      this.allBookings.add(val);
    }

    this.fire("allBookings", this, val, true);

    this.updateObservable("allBookings", null, val);
  }
  public removeFromAllBookings(val: RentalRequest): void {
    this.allBookings.remove(val);

    this.fire("allBookings", this, val, false);

    this.removeObservable("allBookings", val);
  }
  public computeAllBookings = async (): Promise<void> => {
    try {
      this.setAllBookings(
        Array.from(
          (
            await Query.get().getRentalBookings(
              UsageConstants.QUERY_GETRENTALBOOKINGS_BOOKINGPAGE_PROPERTIES_ALLBOOKINGS_COMPUTATION,
              new RentalBookingsRequest({
                date: this.request.eventDate,
                facility: this.facility,
              })
            )
          ).items
        )
      );
    } catch (exception) {
      console.log(" exception in computeAllBookings : " + exception.toString());

      this.setAllBookings([]);
    }
  };
  public setErrors(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.errors, val);

    if (!isValChanged) {
      return;
    }

    this.errors.clear();

    this.errors.addAll(val);

    this.fire("errors", this);
  }
  public addToErrors(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.errors.contains(val)) this.errors.add(val);
    } else {
      this.errors.remove(this.errors.elementAt(index));

      this.errors.add(val);
    }

    this.fire("errors", this, val, true);
  }
  public removeFromErrors(val: string): void {
    this.errors.remove(val);

    this.fire("errors", this, val, false);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        HeaderView({ renter: this.user, className: "x6c hc h", key: "0" }),
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
              children: [
                ui.Column({
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    TextView({
                      data: this.facility.name,
                      style: new ui.TextStyle({
                        fontSize: cStyle.tTextViewHeadlineOneFontSizeOn,
                        fontWeight: cStyle.tTextViewHeadlineOneFontWeightOn,
                      }),
                      className: "x2fe hc",
                      key: "0",
                    }),
                    ui.Wrap({
                      children: [
                        IconView({
                          size: 22,
                          icon: MaterialIcons.location_on,
                          color: cStyle.c20,
                          className: "xb3 hc",
                          key: "0",
                        }),
                        TextView({
                          data:
                            this.facility.school.address +
                            ", " +
                            this.facility.school.city +
                            ", " +
                            this.facility.school.state +
                            ", " +
                            this.facility.school.pincode,
                          style: new ui.TextStyle({
                            fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                            fontWeight:
                              cStyle.tTextViewHeadlineFourFontWeightOn,
                          }),
                          className: "hc",
                          key: "1",
                        }),
                      ],
                      className: "hc",
                      key: "1",
                    }),
                  ],
                  className: "xab hc h",
                  key: "0",
                }),
                ui.Column({
                  crossAxisAlignment: ui.CrossAxisAlignment.end,
                  children: [
                    ui.Row({
                      mainAxisAlignment: ui.MainAxisAlignment.end,
                      children: [
                        TextView({
                          data: "$" + this.request.price.toString() + " /hr",
                          style: new ui.TextStyle({
                            fontSize: cStyle.tTextViewHeadlineThreeFontSizeOn,
                            fontWeight:
                              cStyle.tTextViewHeadlineThreeFontWeightOn,
                          }),
                          className: "x87d",
                          key: "0",
                        }),
                      ],
                      className: "x31 hc h",
                      key: "0",
                    }),
                    ui.Row({
                      mainAxisAlignment: ui.MainAxisAlignment.start,
                      children: [
                        ui.Column({
                          crossAxisAlignment: ui.CrossAxisAlignment.start,
                          children: [
                            TextView({
                              data: this.selectedRate.category.name,
                              style: new ui.TextStyle({
                                fontSize:
                                  cStyle.tTextViewHeadlineFiveFontSizeOn,
                                fontWeight:
                                  cStyle.tTextViewHeadlineFiveFontWeightOn,
                              }),
                              key: "0",
                            }),
                            TextView({
                              data: this.selectedRate.category.description,
                              softWrap: true,
                              style: new ui.TextStyle({ fontSize: 10 }),
                              className: "x03 hc",
                              key: "1",
                            }),
                          ],
                          key: "0",
                        }),
                        IconView({
                          size: 22,
                          icon: MaterialIcons.keyboard_arrow_down,
                          color: cStyle.c20,
                          className: "xe0",
                          key: "1",
                        }),
                      ],
                      d3eRef:
                        this.categoryTextViewPopupTargetController.handleRef,
                      onTap: (e) => {
                        e.stopPropagation();

                        this.onTapCategoryTextViewHandler(this.d3eState);
                      },
                      className: "xa5f hc",
                      key: "1",
                    }),
                  ],
                  className: "xa6a hc h",
                  key: "1",
                }),
              ],
              className: "x68 hc h",
              key: "0",
            }),
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.start,
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    ui.NetworkImage({
                      url: this.facility.images.first.downloadUrl,
                      fit: ui.BoxFit.fill,
                      className: "xe06 hc h",
                      key: "0",
                    }),
                    ui.Row({
                      mainAxisAlignment: ui.MainAxisAlignment.start,
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        ui.Wrap({
                          spacing: 10,
                          runSpacing: 10,
                          children: [
                            this.facility.images
                              .sublist(1, this.facility.images.length)
                              .expand((item) => [
                                ui.NetworkImage({
                                  url: item.downloadUrl,
                                  fit: ui.BoxFit.cover,
                                  width: 300,
                                  height: 200,
                                  className: "xa3 hc vc",
                                  key: item?.toString(),
                                }),
                              ]),
                          ],
                          className: "x16 hc h",
                          key: "0",
                        }),
                      ],
                      className: "x29 hc h",
                      key: "1",
                    }),
                  ],
                  className: "x5f hc h",
                  key: "0",
                }),
              ],
              className: "xaa hc h",
              key: "1",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.Column({
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    ui.Column({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        TextView({
                          data: "Listing Description",
                          style: new ui.TextStyle({
                            fontSize: cStyle.tTextViewHeadlineTwoFontSizeOn,
                            fontWeight: cStyle.tTextViewHeadlineTwoFontWeightOn,
                          }),
                          className: "x64 hc",
                          key: "0",
                        }),
                        TextView({
                          data: "The guest apartment has its own entrance located on the ground level of our home. The private apartment is 720 sq and has 8 ft ceilings. The living room and kitchen are new and the bath and the two bedrooms are remodeled. The apartment gets tons of light and comes with modern amenities including centralized heat/AC(vital in both the summer and winter months), 50 inch HD-TV, high-speed wireless, dishwasher, and washer and dryer in the basement.",
                          softWrap: true,
                          className: "hc",
                          key: "1",
                        }),
                      ],
                      className: "x52 hc",
                      key: "0",
                    }),
                    ui.Column({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        TextView({
                          data: "Policies",
                          style: new ui.TextStyle({
                            fontSize: cStyle.tTextViewHeadlineTwoFontSizeOn,
                            fontWeight: cStyle.tTextViewHeadlineTwoFontWeightOn,
                          }),
                          className: "x5d hc",
                          key: "0",
                        }),
                        this.facility.type.policies.expand((item) => [
                          ui.Container({
                            margin: ui.EdgeInsets.all(5.0, new Map()),
                            child: PolicesView({ policy: item }),
                            key: item?.toString(),
                            className: "x5f1 hc h",
                          }),
                        ]),
                      ],
                      className: "x165 hc h",
                      key: "1",
                    }),
                    this.facility.services.isNotEmpty ||
                    this.facility.equipments.isNotEmpty
                      ? ui.Column({
                          crossAxisAlignment: ui.CrossAxisAlignment.start,
                          children: [
                            TextView({
                              data: "Amenities",
                              style: new ui.TextStyle({
                                fontSize: cStyle.tTextViewHeadlineTwoFontSizeOn,
                                fontWeight:
                                  cStyle.tTextViewHeadlineTwoFontWeightOn,
                              }),
                              className: "x0b3 hc",
                              key: "0",
                            }),
                            ui.Row({
                              mainAxisAlignment:
                                ui.MainAxisAlignment.spaceBetween,
                              crossAxisAlignment: ui.CrossAxisAlignment.start,
                              children: [
                                this.facility.services.isNotEmpty
                                  ? ui.Column({
                                      crossAxisAlignment:
                                        ui.CrossAxisAlignment.start,
                                      children: [
                                        TextView({
                                          data: "Services",
                                          style: new ui.TextStyle({
                                            fontSize:
                                              cStyle.tTextViewHeadlineFourFontSizeOn,
                                            fontWeight:
                                              cStyle.tTextViewHeadlineFourFontWeightOn,
                                          }),
                                          key: "0",
                                        }),
                                        this.facility.services.expand(
                                          (serviceItem) => [
                                            CheckboxWithText({
                                              value:
                                                this.request.services.contains(
                                                  serviceItem
                                                ),
                                              title:
                                                serviceItem.name +
                                                " $" +
                                                serviceItem.ratePerHour.toString() +
                                                "/hr",
                                              activeColor: cStyle.c19,
                                              onChanged: (value) => {
                                                this.servicesCheckboxHandler(
                                                  value,
                                                  serviceItem,
                                                  this.d3eState
                                                );
                                              },
                                              key: serviceItem?.ident,
                                            }),
                                          ]
                                        ),
                                      ],
                                    })
                                  : [],
                                this.facility.equipments.isNotEmpty
                                  ? ui.Column({
                                      crossAxisAlignment:
                                        ui.CrossAxisAlignment.start,
                                      children: [
                                        TextView({
                                          data: "Equipments",
                                          style: new ui.TextStyle({
                                            fontSize:
                                              cStyle.tTextViewHeadlineFourFontSizeOn,
                                            fontWeight:
                                              cStyle.tTextViewHeadlineFourFontWeightOn,
                                          }),
                                          key: "0",
                                        }),
                                        this.facility.equipments.expand(
                                          (equipmentItem) => [
                                            CheckboxWithText({
                                              value:
                                                this.request.equipments.contains(
                                                  equipmentItem
                                                ),
                                              title:
                                                equipmentItem.name +
                                                " $" +
                                                equipmentItem.ratePerHour.toString() +
                                                "/hr",
                                              activeColor: cStyle.c19,
                                              onChanged: (value) => {
                                                this.servicesCheckboxHandler2(
                                                  value,
                                                  equipmentItem,
                                                  this.d3eState
                                                );
                                              },
                                              key: equipmentItem?.ident,
                                            }),
                                          ]
                                        ),
                                      ],
                                      className: "x59",
                                    })
                                  : [],
                                ui.Column({
                                  crossAxisAlignment:
                                    ui.CrossAxisAlignment.start,
                                  children: [
                                    TextView({
                                      data:
                                        "Services Cost : $" +
                                        this.totalServices.toString(),
                                      states: ui.joinStates(
                                        {
                                          "data-visibility":
                                            this.facility.services.isNotEmpty,
                                        },
                                        {}
                                      ),
                                      key: "0",
                                    }),
                                    TextView({
                                      data:
                                        "Equipments Cost : $" +
                                        this.totalEquipements.toString(),
                                      states: ui.joinStates(
                                        {
                                          "data-visibility":
                                            this.facility.equipments.isNotEmpty,
                                        },
                                        {}
                                      ),
                                      key: "1",
                                    }),
                                    TextView({
                                      data:
                                        "Grand Total : $" +
                                        this.total.toString(),
                                      key: "2",
                                    }),
                                  ],
                                  key: "2",
                                }),
                              ],
                              className: "x84 hc h",
                              key: "1",
                            }),
                          ],
                          className: "x21 hc h",
                        })
                      : TextView({
                          data: "No Amenities found.",
                          style: new ui.TextStyle({
                            fontSize: cStyle.tTextViewHeadlineThreeFontSizeOn,
                            fontWeight:
                              cStyle.tTextViewHeadlineThreeFontWeightOn,
                          }),
                          className: "hc",
                        }),
                    LightDivider({
                      margin: ui.EdgeInsets.fromLTRB(
                        0.0,
                        10.0,
                        0.0,
                        0.0,
                        new Map()
                      ),
                      className: "x94 hc h",
                      key: "3",
                    }),
                    ui.Container({
                      height: 512,
                      margin: ui.EdgeInsets.fromLTRB(
                        0.0,
                        0.0,
                        10.0,
                        0.0,
                        new Map()
                      ),
                      padding: ui.EdgeInsets.fromLTRB(
                        0.0,
                        20.0,
                        0.0,
                        0.0,
                        new Map()
                      ),
                      width: Number.POSITIVE_INFINITY,
                      child: FacilitiesMapView({ facilities: [this.facility] }),
                      key: "4",
                      className: "x11c hc vc",
                    }),
                  ],
                  className: "x97e hc h",
                  key: "0",
                }),
                ui.Column({
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    CalenderView({
                      input: this.request.eventDate,
                      selectedTextColor: cStyle.c12,
                      selectedColor: cStyle.c20,
                      hoverColor: cStyle.c20,
                      onDateSelected: (date) => {
                        this.onChangedCalenderHandler(date, this.d3eState);
                      },
                      className: "x6e hc h",
                      key: "0",
                    }),
                    ui.Row({
                      mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        ui.Container({
                          expand: true,
                          margin: ui.EdgeInsets.fromLTRB(
                            0.0,
                            0.0,
                            10.0,
                            0.0,
                            new Map()
                          ),
                          child: LabelTimeSlotField({
                            name: "Start Time",
                            value: this.request.startTime,
                            blockedSlots:
                              RentalRequestUtils.getBlockedStartSlots(
                                this.allBookings
                              ),
                            hoverColor: cStyle.c20,
                            cornerRadius: 3,
                            date: this.request.eventDate,
                            onChanged: (text) => {
                              this.labelTimeSlotFieldRefonChanged(
                                text,
                                this.d3eState
                              );
                            },
                          }),
                          key: "0",
                          className: "x186 hc h",
                        }),
                        ui.Container({
                          expand: true,
                          child: LabelTimeSlotField({
                            name: "End Time",
                            value: this.request.endTime,
                            blockedSlots: RentalRequestUtils.getBlockedEndSlots(
                              this.allBookings,
                              this.request.startTime
                            ),
                            hoverColor: cStyle.c20,
                            cornerRadius: 3,
                            date: this.request.eventDate,
                            onChanged: (text) => {
                              this.labelTimeSlotFieldRef1onChanged(
                                text,
                                this.d3eState
                              );
                            },
                          }),
                          key: "1",
                          className: "x1c3 hc h",
                        }),
                      ],
                      className: "xee hc h",
                      key: "1",
                    }),
                    ui.Container({
                      margin: ui.EdgeInsets.all(10.0, new Map()),
                      child: LabelWithStringField({
                        name: "Event Name",
                        value: this.request.eventName,
                        onChanged: (text) => {
                          this.labelWithStringFieldRefonChanged(
                            text,
                            this.d3eState
                          );
                        },
                      }),
                      key: "2",
                      className: "xbc hc h",
                    }),
                    this.errors.isNotEmpty
                      ? ui.Container({
                          margin: ui.EdgeInsets.fromLTRB(
                            5.0,
                            0.0,
                            0.0,
                            0.0,
                            new Map()
                          ),
                          child: CheckServerErrors({ errors: this.errors }),
                          className: "xf1 hc",
                        })
                      : [],
                    MakeChangesButtonWithState({
                      d3eState: this.d3eState,
                      _onChangesButtonHandler: this.onChangesButtonHandler,
                      key: "4",
                    }),
                  ],
                  className: "xc2e hc",
                  key: "1",
                }),
              ],
              className: "x11 hc h",
              key: "2",
            }),
          ],
          className: "x0d hc h",
          key: "1",
        }),
        ui.Container({
          padding: ui.EdgeInsets.fromLTRB(0.0, 50.0, 0.0, 0.0, new Map()),
          child: FooterView({}),
          key: "2",
          className: "xe8 hc h",
        }),
      ],
      className: ui.join(this.props.className, "BookingPage xfe3 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setSelectedRate(RentalRequestUtils.getTopPriceRate(this.facility));
  };
  public servicesCheckboxHandler = (
    value: boolean,
    serviceItem: Service,
    d3eState: BookingPageRefs
  ): void => {
    if (value) {
      this.request.services.add(serviceItem);
    } else {
      this.request.services.remove(serviceItem);
    }
  };
  public servicesCheckboxHandler2 = (
    value: boolean,
    equipmentItem: Equipment,
    d3eState: BookingPageRefs
  ): void => {
    if (value) {
      this.request.equipments.add(equipmentItem);
    } else {
      this.request.equipments.remove(equipmentItem);
    }
  };
  public onChangesButtonHandler = async (
    d3eState: BookingPageRefs
  ): Promise<void> => {
    this.request.setTotal(this.total);

    let res: Result<RentalRequest> = await this.request.save();

    if (res.status === ResultStatus.Success) {
      EventBus.get().fire(
        new SuccessMessage({ message: "Rental Request Created Successfully" })
      );

      this.navigator.pushRentalHomePage({ renter: this.user, replace: true });
    } else {
      this.setErrors(res.errors);
    }
  };
  public onChangedCalenderHandler = (
    date: D3EDate,
    d3eState: BookingPageRefs
  ): void => {
    this.request.setEventDate(date);
  };
  public changedValue = (): void => {
    this.request.setCategory(this.selectedRate.category);

    this.request.setPrice(this.selectedRate.price);
  };
  public onTapCategoryTextViewHandler = (d3eState: BookingPageRefs): void => {
    this.showCategorySelectionView({ autoClose: true });
  };
  public onTapCategorySelectionViewHandler = (value: FacilityRate): void => {
    this.setSelectedRate(value);

    this.hideCategorySelectionView();
  };
  public dispose(): void {
    this.facilityDisposable?.dispose();

    this.categorySelectionViewPopup?.dispose();

    super.dispose();
  }
  public showCategorySelectionView(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.categorySelectionViewPopup?.dispose();

    let target: ui.Rect = this.categoryTextViewPopupTargetController.getTarget(
      this.context
    );

    this.categorySelectionViewPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Bottom,
      child: ui.Container({
        width: 200,
        child: CategoiresList({
          facility: this.facility,
          onSelectItem: (value) => {
            this.onTapCategorySelectionViewHandler(value);
          },
        }),
        className: "x18f hc vc",
      }),
      target: target,
    });

    this.categorySelectionViewPopup.showPopup(this.context);
  }
  public hideCategorySelectionView(): void {
    this.categorySelectionViewPopup?.dispose();
  }
  public labelTimeSlotFieldRefonChanged = (
    val: Time,
    d3eState: BookingPageRefs
  ): void => {
    this.request.setStartTime(val);
  };
  public labelTimeSlotFieldRef1onChanged = (
    val: Time,
    d3eState: BookingPageRefs
  ): void => {
    this.request.setEndTime(val);
  };
  public labelWithStringFieldRefonChanged = (
    val: string,
    d3eState: BookingPageRefs
  ): void => {
    this.request.setEventName(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get makeChangesButton() {
    return this.d3eState.makeChangesButton;
  }
}
export default function BookingPage(props: BookingPageProps) {
  return React.createElement(_BookingPageState, {
    ..._BookingPageState.defaultProps,
    ...props,
  });
}
