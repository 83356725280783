import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";

export interface LightDividerProps extends BaseUIProps {
  key?: string;
  margin?: ui.EdgeInsets;
  dividerColor?: ui.Color;
}

class _LightDividerState extends ObservableComponent<LightDividerProps> {
  static defaultProps = { margin: null, dividerColor: null };
  public constructor(props: LightDividerProps) {
    super(props);

    this.initState();
  }
  public get margin(): ui.EdgeInsets {
    return this.props.margin;
  }
  public get dividerColor(): ui.Color {
    return this.props.dividerColor;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["dividerColor", "margin"], this.rebuild);
  }
  public componentDidUpdate(prevProps: LightDividerProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.margin !== this.props.margin) {
      this.fire("margin", this);
    }

    if (prevProps.dividerColor !== this.props.dividerColor) {
      this.fire("dividerColor", this);
    }
  }
  public render(): ReactNode {
    return ui.Row({
      children: [
        ui.Container({
          decoration: new ui.BoxDecoration({
            color:
              this.dividerColor !== null
                ? this.dividerColor
                : ui.HexColor.fromHexInt(0xee000000),
          }),
          alignment: ui.Alignment.center,
          margin: this.margin,
          states: ui.joinStates({ "data-c0": this.margin === null }, {}),
          className: "xbd4 hc vc h",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "LightDivider x4b7 hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function LightDivider(props: LightDividerProps) {
  return React.createElement(_LightDividerState, {
    ..._LightDividerState.defaultProps,
    ...props,
  });
}
