import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import PopupTargetController from "./PopupTargetController";
import ListWrapper from "../utils/ListWrapper";
import D3EDate from "../classes/D3EDate";
import Popup from "./Popup";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import Time from "../classes/Time";
import TimeSelectionView from "./TimeSelectionView";
import IconView from "./IconView";
import DateFormat from "../classes/DateFormat";
import { BuildContext } from "../classes/BuildContext";

type _LabelTimeSlotFieldOnChanged = (time: Time) => void;

type _TimeButtonOnPressed = (d3eState: LabelTimeSlotFieldRefs) => void;

export interface LabelTimeSlotFieldProps extends BaseUIProps {
  key?: string;
  name: string;
  date?: D3EDate;
  isRequired?: boolean;
  blockedSlots?: Array<Time>;
  value: Time;
  placeHolder?: string;
  timeFormat?: string;
  activeColor?: ui.Color;
  inActiveColor?: ui.Color;
  hoverColor?: ui.Color;
  cornerRadius?: number;
  _blockedSlotsHash?: number;
  onChanged?: _LabelTimeSlotFieldOnChanged;
}
/// To store state data for LabelTimeSlotField
class LabelTimeSlotFieldRefs {
  public timeButton: TimeButtonState = new TimeButtonState();
  public timeIcon: TimeIconState = new TimeIconState();
  timeInputController: ui.TextEditingController =
    new ui.TextEditingController();
}

interface TimeIconWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: LabelTimeSlotFieldRefs;
  hoverColor: ui.Color;
}

class TimeIconState extends ObjectObservable {
  private _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _TimeIconWithState extends ObservableComponent<TimeIconWithStateProps> {
  public constructor(props: TimeIconWithStateProps) {
    super(props);

    this.initState();
  }
  public get hoverColor(): ui.Color {
    return this.props.hoverColor;
  }
  public get timeIcon(): TimeIconState {
    return this.props.d3eState.timeIcon;
  }
  public get d3eState(): LabelTimeSlotFieldRefs {
    return this.props.d3eState;
  }
  public initState() {
    super.initState();

    this.updateObservable("timeIcon", null, this.timeIcon);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["hoverColor", "timeIcon", "timeIcon.hover"], this.rebuild);
  }
  public timeIconOnEnter(event): void {
    return this.timeIcon.setHover(true);
  }
  public timeIconOnExit(event): void {
    return this.timeIcon.setHover(false);
  }
  public dispose(): void {
    this.timeIcon.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    return IconView({
      icon: MaterialIcons.access_time,
      size: 18,
      color: this.timeIcon.hover ? this.hoverColor : null,
      onEnter: (event) => {
        this.timeIconOnEnter(event);
      },
      onExit: (event) => {
        this.timeIconOnExit(event);
      },
      className: "x8a6",
    });
  }
}
function TimeIconWithState(props: TimeIconWithStateProps) {
  return React.createElement(_TimeIconWithState, props);
}

interface TimeButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: LabelTimeSlotFieldRefs;
  _onIconPressed?: _TimeButtonOnPressed;
  timeButtonPopupTargetController: PopupTargetController;
  hoverColor: ui.Color;
}

class TimeButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _TimeButtonWithState extends ObservableComponent<TimeButtonWithStateProps> {
  timeButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  public constructor(props: TimeButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get hoverColor(): ui.Color {
    return this.props.hoverColor;
  }
  public get timeButton(): TimeButtonState {
    return this.props.d3eState.timeButton;
  }
  public get timeIcon(): TimeIconState {
    return this.props.d3eState.timeIcon;
  }
  public get d3eState(): LabelTimeSlotFieldRefs {
    return this.props.d3eState;
  }
  public get _onIconPressed(): _TimeButtonOnPressed {
    return this.props._onIconPressed;
  }
  public get timeButtonPopupTargetController(): PopupTargetController {
    return this.props.timeButtonPopupTargetController;
  }
  public initState() {
    super.initState();

    this.updateObservable("timeButton", null, this.timeButton);

    this.updateObservable("timeIcon", null, this.timeIcon);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["hoverColor", "timeButton", "timeIcon"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    return Button({
      disable: this.timeButton.disable,
      onPressed: () => {
        this._onIconPressed(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TimeIconWithState({
        d3eState: this.d3eState,
        hoverColor: this.hoverColor,
      }),
      d3eRef: this.timeButtonPopupTargetController.handleRef,
    });
  }
}
function TimeButtonWithState(props: TimeButtonWithStateProps) {
  return React.createElement(_TimeButtonWithState, props);
}

class _LabelTimeSlotFieldState extends ObservableComponent<LabelTimeSlotFieldProps> {
  static defaultProps = {
    name: "",
    date: null,
    isRequired: false,
    value: null,
    placeHolder: "",
    timeFormat: "hh:mm a",
    activeColor: null,
    inActiveColor: null,
    hoverColor: null,
    cornerRadius: 0.0,
    blockedSlots: [],
    onChanged: null,
  };
  d3eState: LabelTimeSlotFieldRefs = new LabelTimeSlotFieldRefs();
  formatter: DateFormat = null;
  focusNode: ui.FocusNode = null;
  focused: boolean = false;
  error: string = "";
  timeSelectionViewPopupPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public timeButtonPopupTargetController: PopupTargetController =
    new PopupTargetController();
  public constructor(props: LabelTimeSlotFieldProps) {
    super(props);

    this.initState();
  }
  public get name(): string {
    return this.props.name;
  }
  public get date(): D3EDate {
    return this.props.date;
  }
  public get isRequired(): boolean {
    return this.props.isRequired;
  }
  public get blockedSlots(): Array<Time> {
    return this.props.blockedSlots;
  }
  public get value(): Time {
    return this.props.value;
  }
  public get placeHolder(): string {
    return this.props.placeHolder;
  }
  public get timeFormat(): string {
    return this.props.timeFormat;
  }
  public get activeColor(): ui.Color {
    return this.props.activeColor;
  }
  public get inActiveColor(): ui.Color {
    return this.props.inActiveColor;
  }
  public get hoverColor(): ui.Color {
    return this.props.hoverColor;
  }
  public get cornerRadius(): number {
    return this.props.cornerRadius;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.subscribeToList(this.blockedSlots, "blockedSlots");

    this.on(["timeFormat"], this.computeFormatter);

    this.computeFormatter();

    this.on(
      [
        "activeColor",
        "cornerRadius",
        "error",
        "focusNode",
        "focused",
        "formatter",
        "hoverColor",
        "inActiveColor",
        "isRequired",
        "name",
        "placeHolder",
        "value",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: LabelTimeSlotFieldProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.name !== this.props.name) {
      this.fire("name", this);
    }

    if (prevProps.date !== this.props.date) {
      this.fire("date", this);
    }

    if (prevProps.isRequired !== this.props.isRequired) {
      this.fire("isRequired", this);
    }

    if (prevProps.blockedSlots !== this.props.blockedSlots) {
      this.fire("blockedSlots", this);
    }

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.placeHolder !== this.props.placeHolder) {
      this.fire("placeHolder", this);
    }

    if (prevProps.timeFormat !== this.props.timeFormat) {
      this.fire("timeFormat", this);
    }

    if (prevProps.activeColor !== this.props.activeColor) {
      this.fire("activeColor", this);
    }

    if (prevProps.inActiveColor !== this.props.inActiveColor) {
      this.fire("inActiveColor", this);
    }

    if (prevProps.hoverColor !== this.props.hoverColor) {
      this.fire("hoverColor", this);
    }

    if (prevProps.cornerRadius !== this.props.cornerRadius) {
      this.fire("cornerRadius", this);
    }
  }
  public setFormatter(val: DateFormat): void {
    let isValChanged: boolean = this.formatter !== val;

    if (!isValChanged) {
      return;
    }

    this.formatter = val;

    this.fire("formatter", this);
  }
  public computeFormatter = (): void => {
    try {
      this.setFormatter(new DateFormat(this.timeFormat));
    } catch (exception) {
      console.log(" exception in computeFormatter : " + exception.toString());

      this.setFormatter(null);
    }
  };
  public setFocusNode(val: ui.FocusNode): void {
    let isValChanged: boolean = this.focusNode !== val;

    if (!isValChanged) {
      return;
    }

    this.focusNode = val;

    this.fire("focusNode", this);
  }
  public setFocused(val: boolean): void {
    let isValChanged: boolean = this.focused !== val;

    if (!isValChanged) {
      return;
    }

    this.focused = val;

    this.fire("focused", this);
  }
  public setError(val: string): void {
    let isValChanged: boolean = this.error !== val;

    if (!isValChanged) {
      return;
    }

    this.error = val;

    this.fire("error", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          children: [
            TextView({
              data: this.name,
              style: new ui.TextStyle({
                fontFamily: cStyle.tTextViewNameTextFontFamilyOn,
                color: cStyle.tTextViewNameTextColorOn,
                fontSize: cStyle.tTextViewNameTextFontSizeOn,
                fontWeight: cStyle.tTextViewNameTextFontWeightOn,
              }),
              className: "x3f2",
              key: "0",
            }),
            this.isRequired
              ? TextView({
                  data: "*",
                  style: new ui.TextStyle({
                    fontFamily: cStyle.tTextViewNameTextFontFamilyOn,
                    color: cStyle.tTextViewNameTextColorOn,
                    fontSize: cStyle.tTextViewNameTextFontSizeOn,
                    fontWeight: cStyle.tTextViewNameTextFontWeightOn,
                  }),
                })
              : [],
          ],
          className: "xbaf hc h",
          key: "0",
        }),
        ui.Container({
          decoration: new ui.BoxDecoration({
            border: ui.Border.all({
              color:
                this.activeColor !== null && this.focused
                  ? this.activeColor
                  : this.inActiveColor !== null && !this.focused
                  ? this.inActiveColor
                  : this.focused
                  ? ui.HexColor.fromHexInt(0xff14acff)
                  : ui.HexColor.fromHexInt(0xffbfbfbf),
              width: 1.0,
            }),
            borderRadius: ui.BorderRadiusExt.only({
              topLeft: ui.RadiusExt.elliptical({
                x: this.cornerRadius,
                y: this.cornerRadius,
              }),
              topRight: ui.RadiusExt.elliptical({
                x: this.cornerRadius,
                y: this.cornerRadius,
              }),
              bottomLeft: ui.RadiusExt.elliptical({
                x: this.cornerRadius,
                y: this.cornerRadius,
              }),
              bottomRight: ui.RadiusExt.elliptical({
                x: this.cornerRadius,
                y: this.cornerRadius,
              }),
            }),
          }),
          child: ui.Row({
            children: [
              ui.InputField({
                value:
                  this.value === null
                    ? ""
                    : this.formatter.format(this.value.toDateTime()),
                activeColor: cStyle.c2,
                inActiveColor: cStyle.c2,
                placeHolder: this.placeHolder,
                focusNode: this.focusNode,
                disable: true,
                padding: ui.EdgeInsets.symmetric({
                  horizontal: 5.0,
                  vertical: 6.0,
                  transitions: new Map(),
                }),
                controller: this.d3eState.timeInputController,
                onFocusChange: (val) => {},
                className: "xaef hc h",
                key: "0",
              }),
              TimeButtonWithState({
                d3eState: this.d3eState,
                _onIconPressed: this.onIconPressed,
                hoverColor: this.hoverColor,
                timeButtonPopupTargetController:
                  this.timeButtonPopupTargetController,
                key: "1",
              }),
            ],
            className: "xcb2 hc h",
          }),
          className: "xb54 hc h",
          key: "1",
        }),
        this.error !== null && this.error.isNotEmpty
          ? TextView({
              data: this.error,
              style: new ui.TextStyle({ color: new ui.Color(0xffe30000) }),
              className: "xb30 hc",
            })
          : [],
      ],
      className: ui.join(this.props.className, "LabelTimeSlotField xc05 hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setFocusNode(new ui.FocusNode());

    this.focusNode.addListener(this.focusHandler);
  };
  public focusHandler = (): void => {
    this.setFocused(this.focusNode.hasFocus);
  };
  public onIconPressed = (d3eState: LabelTimeSlotFieldRefs): void => {
    this.showTimeSelectionViewPopup({ autoClose: true });
  };
  public onTimeSelected = (time: Time): void => {
    this.hideTimeSelectionViewPopup();

    this.onChanged(time);
  };
  public get onChanged(): _LabelTimeSlotFieldOnChanged {
    return this.props.onChanged;
  }
  public dispose(): void {
    this.timeSelectionViewPopupPopup?.dispose();

    super.dispose();
  }
  public showTimeSelectionViewPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.timeSelectionViewPopupPopup?.dispose();

    let target: ui.Rect = this.timeButtonPopupTargetController.getTarget(
      this.context
    );

    this.timeSelectionViewPopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Top,
      child: ui.Container({
        decoration: new ui.BoxDecoration({ color: new ui.Color(0xffffffff) }),
        height: 400,
        width: 400,
        child: TimeSelectionView({
          date: this.date,
          blockedSlots: this.blockedSlots,
          onSelectedTime: (time) => {
            this.onTimeSelected(time);
          },
        }),
        className: "x73d hc vc",
      }),
      target: target,
    });

    this.timeSelectionViewPopupPopup.showPopup(this.context);
  }
  public hideTimeSelectionViewPopup(): void {
    this.timeSelectionViewPopupPopup?.dispose();
  }
  public get timeButton() {
    return this.d3eState.timeButton;
  }
  public get timeIcon() {
    return this.d3eState.timeIcon;
  }
}
export default function LabelTimeSlotField(props: LabelTimeSlotFieldProps) {
  return React.createElement(
    _LabelTimeSlotFieldState,
    { ..._LabelTimeSlotFieldState.defaultProps, ...props },
    ListWrapper.fromInput<Time>(props.blockedSlots, "blockedSlots")
  );
}
