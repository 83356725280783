import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import ResultStatus from "../classes/ResultStatus";
import RentalRequestChanges from "../models/RentalRequestChanges";
import SuccessMessage from "../classes/SuccessMessage";
import RentalRequest from "../models/RentalRequest";
import EventBus from "../utils/EventBus";
import PageNavigator from "../classes/PageNavigator";
import Result from "../classes/Result";
import PopupWrapperView from "./PopupWrapperView";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import CheckServerErrors from "./CheckServerErrors";
import TextView from "./TextView";
import FailureMessage from "../classes/FailureMessage";
import CollectionUtils from "../utils/CollectionUtils";
import { BuildContext } from "../classes/BuildContext";

type _CancelButtonOnPressed = (d3eState: RequestChangesPopupRefs) => void;

type _ChangesButtonOnPressed = (d3eState: RequestChangesPopupRefs) => void;

type _DocRefOnChanged = (
  text: string,
  d3eState: RequestChangesPopupRefs
) => void;

export interface RequestChangesPopupProps extends BaseUIProps {
  key?: string;
  request: RentalRequest;
}
/// To store state data for RequestChangesPopup
class RequestChangesPopupRefs {
  public cancelButton: CancelButtonState = new CancelButtonState();
  public changesButton: ChangesButtonState = new ChangesButtonState();
  docRefController: ui.TextEditingController = new ui.TextEditingController();
  docRefFocusNode: ui.FocusNode = new ui.FocusNode();
}

interface ChangesButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RequestChangesPopupRefs;
  _saveButtonHandler?: _ChangesButtonOnPressed;
}

class ChangesButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ChangesButtonWithState extends ObservableComponent<ChangesButtonWithStateProps> {
  changesButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ChangesButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get changesButton(): ChangesButtonState {
    return this.props.d3eState.changesButton;
  }
  public get d3eState(): RequestChangesPopupRefs {
    return this.props.d3eState;
  }
  public get _saveButtonHandler(): _ChangesButtonOnPressed {
    return this.props._saveButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("changesButton", null, this.changesButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["changesButton", "changesButton.", "changesButton.hover"],
      this.rebuild
    );
  }
  public changesButtonOnEnter(event): void {
    return this.changesButton.setHover(true);
  }
  public changesButtonOnExit(event): void {
    return this.changesButton.setHover(false);
  }
  public dispose(): void {
    this.changesButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: 100,
      child: Button({
        padding: this.changesButton.hover
          ? cStyle.tButtonPrimaryNewPaddingOnHover
          : cStyle.tButtonPrimaryNewPaddingOn,
        decoration: this.changesButton.hover
          ? cStyle.tButtonPrimaryNewDecorationOnHover
          : cStyle.tButtonPrimaryNewDecorationOn,
        disable: this.changesButton.disable,
        onPressed: () => {
          this._saveButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Request" }),
        onEnter: (event) => {
          this.changesButtonOnEnter(event);
        },
        onExit: (event) => {
          this.changesButtonOnExit(event);
        },
      }),
      className: "xa3c hc",
    });
  }
}
function ChangesButtonWithState(props: ChangesButtonWithStateProps) {
  return React.createElement(_ChangesButtonWithState, props);
}

interface CancelButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RequestChangesPopupRefs;
  _cancelButtonHandler?: _CancelButtonOnPressed;
}

class CancelButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _CancelButtonWithState extends ObservableComponent<CancelButtonWithStateProps> {
  cancelButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CancelButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get cancelButton(): CancelButtonState {
    return this.props.d3eState.cancelButton;
  }
  public get d3eState(): RequestChangesPopupRefs {
    return this.props.d3eState;
  }
  public get _cancelButtonHandler(): _CancelButtonOnPressed {
    return this.props._cancelButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("cancelButton", null, this.cancelButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["cancelButton", "cancelButton.", "cancelButton.hover"],
      this.rebuild
    );
  }
  public cancelButtonOnEnter(event): void {
    return this.cancelButton.setHover(true);
  }
  public cancelButtonOnExit(event): void {
    return this.cancelButton.setHover(false);
  }
  public dispose(): void {
    this.cancelButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 10.0, 0.0, new Map()),
      width: 100,
      child: Button({
        padding: this.cancelButton.hover
          ? cStyle.tButtonSecondaryNewPaddingOnHover
          : cStyle.tButtonSecondaryNewPaddingOn,
        decoration: this.cancelButton.hover
          ? cStyle.tButtonSecondaryNewDecorationOnHover
          : cStyle.tButtonSecondaryNewDecorationOn,
        disable: this.cancelButton.disable,
        onPressed: () => {
          this._cancelButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Cancel" }),
        onEnter: (event) => {
          this.cancelButtonOnEnter(event);
        },
        onExit: (event) => {
          this.cancelButtonOnExit(event);
        },
      }),
      className: "x18a hc",
    });
  }
}
function CancelButtonWithState(props: CancelButtonWithStateProps) {
  return React.createElement(_CancelButtonWithState, props);
}

class _RequestChangesPopupState extends ObservableComponent<RequestChangesPopupProps> {
  static defaultProps = { request: null };
  d3eState: RequestChangesPopupRefs = new RequestChangesPopupRefs();
  requestChanges: RentalRequestChanges = null;
  changes: string = "";
  errors: Array<string> = ListWrapper.widget(this, "errors");
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RequestChangesPopupProps) {
    super(props);

    this.initState();
  }
  public get request(): RentalRequest {
    return this.props.request;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("request", this.props.request);

    this.on(["request"], this.computeRequestChanges);

    this.computeRequestChanges();

    this.on(["changes", "errors"], this.rebuild);
  }
  public componentDidUpdate(prevProps: RequestChangesPopupProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.request !== this.props.request) {
      this.updateObservable("request", prevProps.request, this.props.request);

      this.fire("request", this);
    }
  }
  public setRequestChanges(val: RentalRequestChanges): void {
    let isValChanged: boolean = this.requestChanges !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("requestChanges", this.requestChanges, val);

    this.requestChanges = val;

    this.fire("requestChanges", this);
  }
  public computeRequestChanges = (): void => {
    try {
      this.setRequestChanges(
        new RentalRequestChanges({ request: this.request })
      );
    } catch (exception) {
      console.log(
        " exception in computeRequestChanges : " + exception.toString()
      );

      this.setRequestChanges(null);
    }
  };
  public setChanges(val: string): void {
    let isValChanged: boolean = this.changes !== val;

    if (!isValChanged) {
      return;
    }

    this.changes = val;

    this.fire("changes", this);
  }
  public setErrors(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.errors, val);

    if (!isValChanged) {
      return;
    }

    this.errors.clear();

    this.errors.addAll(val);

    this.fire("errors", this);
  }
  public addToErrors(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.errors.contains(val)) this.errors.add(val);
    } else {
      this.errors.remove(this.errors.elementAt(index));

      this.errors.add(val);
    }

    this.fire("errors", this, val, true);
  }
  public removeFromErrors(val: string): void {
    this.errors.remove(val);

    this.fire("errors", this, val, false);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return PopupWrapperView({
      title: "Request Changes",
      content: ui.Column({
        crossAxisAlignment: ui.CrossAxisAlignment.start,
        children: [
          ui.InputField({
            dense: true,
            value: this.changes,
            placeHolder: "Enter Changes",
            maxLines: 5,
            keyboardType: ui.TextInputType.multiline,
            inActiveColor: new ui.Color(0xffdcdcdc),
            activeColor: new ui.Color(0xff6c10ec),
            controller: this.d3eState.docRefController,
            onChanged: (text) => {
              this.docRefonChanged(text, this.d3eState);
            },
            onFocusChange: (val) => {},
            focusNode: this.d3eState.docRefFocusNode,
            className: "xd40 hc h",
            key: "0",
          }),
        ],
        className: "x650 hc h",
      }),
      buttons: [
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          mainAxisAlignment: ui.MainAxisAlignment.start,
          children: [
            this.errors.isNotEmpty
              ? CheckServerErrors({ errors: this.errors })
              : [],
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.start,
              children: [
                CancelButtonWithState({
                  d3eState: this.d3eState,
                  _cancelButtonHandler: this.cancelButtonHandler,
                  key: "0",
                }),
                ChangesButtonWithState({
                  d3eState: this.d3eState,
                  _saveButtonHandler: this.saveButtonHandler,
                  key: "1",
                }),
              ],
              key: "1",
            }),
          ],
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "RequestChangesPopup hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public cancelButtonHandler = (d3eState: RequestChangesPopupRefs): void => {
    this.navigator.close();
  };
  public saveButtonHandler = async (
    d3eState: RequestChangesPopupRefs
  ): Promise<void> => {
    // POS: 150.00, Y = 80.00

    if (this.changes.isNotEmpty) {
      this.requestChanges.setChanges(this.changes);

      let result: Result<RentalRequestChanges> =
        await this.requestChanges.save();

      if (result.status === ResultStatus.Success) {
        EventBus.get().fire(
          new SuccessMessage({
            message: "Request Changes Submitted Successfully",
          })
        );

        this.setErrors([]);

        this.navigator.close();
      }

      this.setChanges("");
    } else {
      EventBus.get().fire(
        new FailureMessage({ message: "Please enter changes" })
      );

      this.setErrors(["Please enter changes"]);
    }
  };
  public docRefonChanged = (
    val: string,
    d3eState: RequestChangesPopupRefs
  ): void => {
    this.setChanges(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get cancelButton() {
    return this.d3eState.cancelButton;
  }
  public get changesButton() {
    return this.d3eState.changesButton;
  }
}
export default function RequestChangesPopup(props: RequestChangesPopupProps) {
  return React.createElement(_RequestChangesPopupState, {
    ..._RequestChangesPopupState.defaultProps,
    ...props,
  });
}
