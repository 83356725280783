import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";

type _CellButtonOnPressed = () => void;

type _CellButtonOnLongPressed = () => void;

type _ChildWrapperOnKey = (
  focusNode: ui.FocusNode,
  event: ui.RawKeyEvent,
  d3eState: CellButtonRefs
) => ui.KeyEventResult;

type _ChildWrapperOnTap = (d3eState: CellButtonRefs) => void;

type _ChildWrapperOnLongPress = (d3eState: CellButtonRefs) => void;

type _CellButtonFocusChange = (val: boolean) => void;

export interface CellButtonProps extends BaseUIProps {
  key?: string;
  disable?: boolean;
  decoration?: ui.BoxDecoration;
  padding?: ui.EdgeInsets;
  onPressed?: _CellButtonOnPressed;
  onLongPressed?: _CellButtonOnLongPressed;
  child?: ReactNode;
  _onFocusChange?: _CellButtonFocusChange;
}
/// To store state data for CellButton
class CellButtonRefs {
  public childWrapper: ChildWrapperState = new ChildWrapperState();
  focusNode: ui.FocusNode = new ui.FocusNode();
}

interface ChildWrapperWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: CellButtonRefs;
  child: ReactNode;
  _onHandleLongPress?: _ChildWrapperOnLongPress;
  _onHandleTap?: _ChildWrapperOnTap;
  _onKey?: _ChildWrapperOnKey;
  _onFocusChange: _CellButtonFocusChange;
  childPresent: boolean;
  decoration: ui.BoxDecoration;
  disable: boolean;
  padding: ui.EdgeInsets;
}

class ChildWrapperState extends ObjectObservable {
  private _focus: boolean = false;
  public get focus(): boolean {
    return this._focus;
  }
  public setFocus(val: boolean) {
    let isValChanged: boolean = this._focus !== val;

    if (!isValChanged) {
      return;
    }

    this._focus = val;

    this.fire("focus", this);
  }
}

class _ChildWrapperWithState extends ObservableComponent<ChildWrapperWithStateProps> {
  public constructor(props: ChildWrapperWithStateProps) {
    super(props);

    this.initState();
  }
  public get childPresent(): boolean {
    return this.props.childPresent;
  }
  public get childWrapper(): ChildWrapperState {
    return this.props.d3eState.childWrapper;
  }
  public get decoration(): ui.BoxDecoration {
    return this.props.decoration;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public get padding(): ui.EdgeInsets {
    return this.props.padding;
  }
  public get d3eState(): CellButtonRefs {
    return this.props.d3eState;
  }
  public get child(): ReactNode {
    return this.props.child;
  }
  public get _onHandleLongPress(): _ChildWrapperOnLongPress {
    return this.props._onHandleLongPress;
  }
  public get _onHandleTap(): _ChildWrapperOnTap {
    return this.props._onHandleTap;
  }
  public get _onKey(): _ChildWrapperOnKey {
    return this.props._onKey;
  }
  public get _onFocusChange(): _CellButtonFocusChange {
    return this.props._onFocusChange;
  }
  public initState() {
    super.initState();

    this.updateObservable("childWrapper", null, this.childWrapper);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "childPresent",
        "childWrapper",
        "childWrapper.focus",
        "decoration",
        "decoration.backgroundBlendMode",
        "decoration.border",
        "decoration.borderRadius",
        "decoration.boxShadow",
        "decoration.gradient",
        "decoration.image",
        "decoration.shape",
        "disable",
        "padding",
      ],
      this.rebuild
    );
  }
  public childWrapperOnFocusChange(val): void {
    return this.childWrapper.setFocus(val);
  }
  public dispose(): void {
    this.childWrapper.setFocus(false);

    super.dispose();
  }
  public render(): ReactNode {
    return ui.Focus({
      focusNode: this.props.d3eState.focusNode ?? new ui.FocusNode(),
      child: ui.Container({
        padding: this.padding,
        decoration:
          this.disable && this.decoration !== null
            ? new ui.BoxDecoration({
                color: ui.HexColor.fromHexInt(0x61dddddd),
                image: this.decoration.image,
                border: this.decoration.border,
                borderRadius: this.decoration.borderRadius,
                boxShadow: this.decoration.boxShadow,
                gradient: this.decoration.gradient,
                backgroundBlendMode: this.decoration.backgroundBlendMode,
                shape: this.decoration.shape,
              })
            : this.decoration !== null
            ? this.decoration
            : new ui.BoxDecoration(),
        states: ui.joinStates(
          { "data-c1": this.disable, "data-c2": this.padding === null },
          this.props.states
        ),
        child: ui.Container({
          child: this.childPresent
            ? this.props.child
            : ui.Container({ className: "hc" }),
          className: "hc",
        }),
        onTap: (e) => {
          e.stopPropagation();

          this._onHandleTap(this.d3eState);
        },
        onLongPress: () => {
          this._onHandleLongPress(this.d3eState);
        },
        onFocusKey: (focusNode, event) => {
          return this._onKey(focusNode, event, this.d3eState);
        },
        className: ui.join(this.props.className, "CellButton x02 hc"),
        ...copyBaseUIProps(this.props),
      }),
      onFocusKey: (focusNode, event) => {
        return this._onKey(focusNode, event, this.d3eState);
      },
      onFocusChange: (val) => {
        this.childWrapperOnFocusChange(val);

        this.props.onFocusChange(val);
      },
    });
  }
}
function ChildWrapperWithState(props: ChildWrapperWithStateProps) {
  return React.createElement(_ChildWrapperWithState, props);
}

class _CellButtonState extends ObservableComponent<CellButtonProps> {
  static defaultProps = {
    disable: false,
    decoration: null,
    padding: null,
    onPressed: null,
    onLongPressed: null,
  };
  d3eState: CellButtonRefs = new CellButtonRefs();
  public constructor(props: CellButtonProps) {
    super(props);

    this.initState();
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public get decoration(): ui.BoxDecoration {
    return this.props.decoration;
  }
  public get padding(): ui.EdgeInsets {
    return this.props.padding;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["childPresent", "decoration", "disable", "padding"], this.rebuild);
  }
  public componentDidUpdate(prevProps: CellButtonProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }

    if (prevProps.decoration !== this.props.decoration) {
      this.fire("decoration", this);
    }

    if (prevProps.padding !== this.props.padding) {
      this.fire("padding", this);
    }
  }
  public render(): ReactNode {
    return ChildWrapperWithState({
      d3eState: this.d3eState,
      child: this.child,
      _onHandleLongPress: this.onHandleLongPress,
      _onHandleTap: this.onHandleTap,
      _onKey: this.onKey,
      childPresent: this.childPresent,
      decoration: this.decoration,
      disable: this.disable,
      padding: this.padding,
      _onFocusChange: this.onFocusChange,
      className: this.props.className,
      ...copyBaseUIProps(this.props),
    });
  }
  public onHandleTap = (d3eState: CellButtonRefs): void => {
    if (this.onPressed !== null && !this.disable) {
      this.onPressed();
    }
  };
  public onHandleLongPress = (d3eState: CellButtonRefs): void => {
    if (this.onLongPressed !== null && !this.disable) {
      this.onLongPressed();
    }
  };
  public onKey = (
    focusNode: ui.FocusNode,
    event: ui.RawKeyEvent,
    d3eState: CellButtonRefs
  ): ui.KeyEventResult => {
    if (event instanceof ui.RawKeyDownEvent && !this.disable) {
      if (
        event.logicalKey === ui.LogicalKeyboardKey.enter ||
        event.logicalKey === ui.LogicalKeyboardKey.space ||
        event.logicalKey === ui.LogicalKeyboardKey.numpadEnter
      ) {
        if (this.onPressed !== null) {
          this.onPressed();
        }

        return ui.KeyEventResult.handled;
      }
    }

    return ui.KeyEventResult.ignored;
  };
  public get onPressed(): _CellButtonOnPressed {
    return this.props.onPressed;
  }
  public get onLongPressed(): _CellButtonOnLongPressed {
    return this.props.onLongPressed;
  }
  public get childPresent(): boolean {
    return this.props.child != null;
  }
  public get child(): ReactNode {
    return this.props.child;
  }
  public get onFocusChange(): _CellButtonFocusChange {
    return this.props.onFocusChange;
  }
  public get childWrapper() {
    return this.d3eState.childWrapper;
  }
}
export default function CellButton(props: CellButtonProps) {
  return React.createElement(_CellButtonState, {
    ..._CellButtonState.defaultProps,
    ...props,
  });
}
