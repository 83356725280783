import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import FacilityType from "../models/FacilityType";
import School from "../models/School";
import MaterialIcons from "../icons/MaterialIcons";
import PageNavigator from "../classes/PageNavigator";
import Renter from "../models/Renter";
import FacilityTypeListRequest from "../models/FacilityTypeListRequest";
import Query from "../classes/Query";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import SchoolsListRequest from "../models/SchoolsListRequest";
import TextView from "./TextView";
import RentalSearchableView from "./RentalSearchableView";
import CollectionUtils from "../utils/CollectionUtils";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _SearchButtonOnPressed = (d3eState: RentalSearchRequestViewRefs) => void;

type _RentalSearchableViewOnChanged = (
  text: FacilityType,
  d3eState: RentalSearchRequestViewRefs
) => void;

type _RentalSearchableView2OnChanged = (
  text: string,
  d3eState: RentalSearchRequestViewRefs
) => void;

export interface RentalSearchRequestViewProps extends BaseUIProps {
  key?: string;
  renter: Renter;
}
/// To store state data for RentalSearchRequestView
class RentalSearchRequestViewRefs {
  public searchButton: SearchButtonState = new SearchButtonState();
}

interface SearchButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalSearchRequestViewRefs;
  _onPressedSearchButtonHandler?: _SearchButtonOnPressed;
}

class SearchButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _SearchButtonWithState extends ObservableComponent<SearchButtonWithStateProps> {
  searchButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SearchButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get searchButton(): SearchButtonState {
    return this.props.d3eState.searchButton;
  }
  public get d3eState(): RentalSearchRequestViewRefs {
    return this.props.d3eState;
  }
  public get _onPressedSearchButtonHandler(): _SearchButtonOnPressed {
    return this.props._onPressedSearchButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("searchButton", null, this.searchButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["searchButton"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      expand: true,
      child: Button({
        padding: cStyle.tButtonPrimaryButtonPaddingOn,
        decoration: cStyle.tButtonPrimaryButtonDecorationOn,
        disable: this.searchButton.disable,
        onPressed: () => {
          this._onPressedSearchButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Search", className: "hc" }),
      }),
      className: "x6d5 hc h",
    });
  }
}
function SearchButtonWithState(props: SearchButtonWithStateProps) {
  return React.createElement(_SearchButtonWithState, props);
}

class _RentalSearchRequestViewState extends ObservableComponent<RentalSearchRequestViewProps> {
  static defaultProps = { renter: null };
  d3eState: RentalSearchRequestViewRefs = new RentalSearchRequestViewRefs();
  facilityType: FacilityType = null;
  searchLocation: string = "";
  facilityTypes: Array<FacilityType> = ListWrapper.widget(
    this,
    "facilityTypes"
  );
  schools: Array<School> = ListWrapper.widget(this, "schools");
  locations: Array<string> = ListWrapper.widget(this, "locations");
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RentalSearchRequestViewProps) {
    super(props);

    this.initState();
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.computeFacilityTypes();

    this.computeSchools();

    this.on(
      ["schools", "schools.city", "schools.state"],
      this.computeLocations
    );

    this.computeLocations();

    this.updateSyncProperty("renter", this.props.renter);

    this.on(
      ["facilityType", "facilityTypes", "locations", "searchLocation"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RentalSearchRequestViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public setFacilityType(val: FacilityType): void {
    let isValChanged: boolean = this.facilityType !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("facilityType", this.facilityType, val);

    this.facilityType = val;

    this.fire("facilityType", this);
  }
  public setSearchLocation(val: string): void {
    let isValChanged: boolean = this.searchLocation !== val;

    if (!isValChanged) {
      return;
    }

    this.searchLocation = val;

    this.fire("searchLocation", this);
  }
  public setFacilityTypes(val: Array<FacilityType>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.facilityTypes,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("facilityTypes", this.facilityTypes, val);

    this.facilityTypes.clear();

    this.facilityTypes.addAll(val);

    this.fire("facilityTypes", this);
  }
  public addToFacilityTypes(val: FacilityType, index: number = -1): void {
    if (index === -1) {
      if (!this.facilityTypes.contains(val)) this.facilityTypes.add(val);
    } else {
      this.facilityTypes.remove(this.facilityTypes.elementAt(index));

      this.facilityTypes.add(val);
    }

    this.fire("facilityTypes", this, val, true);

    this.updateObservable("facilityTypes", null, val);
  }
  public removeFromFacilityTypes(val: FacilityType): void {
    this.facilityTypes.remove(val);

    this.fire("facilityTypes", this, val, false);

    this.removeObservable("facilityTypes", val);
  }
  public computeFacilityTypes = async (): Promise<void> => {
    try {
      this.setFacilityTypes(
        Array.from(
          (
            await Query.get().getFacilityTypeList(
              UsageConstants.QUERY_GETFACILITYTYPELIST_RENTALSEARCHREQUESTVIEW_PROPERTIES_FACILITYTYPES_COMPUTATION,
              new FacilityTypeListRequest({ pageSize: 100, offset: 0 })
            )
          )?.items ?? []
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeFacilityTypes : " + exception.toString()
      );

      this.setFacilityTypes([]);
    }
  };
  public setSchools(val: Array<School>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.schools, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("schools", this.schools, val);

    this.schools.clear();

    this.schools.addAll(val);

    this.fire("schools", this);
  }
  public addToSchools(val: School, index: number = -1): void {
    if (index === -1) {
      if (!this.schools.contains(val)) this.schools.add(val);
    } else {
      this.schools.remove(this.schools.elementAt(index));

      this.schools.add(val);
    }

    this.fire("schools", this, val, true);

    this.updateObservable("schools", null, val);
  }
  public removeFromSchools(val: School): void {
    this.schools.remove(val);

    this.fire("schools", this, val, false);

    this.removeObservable("schools", val);
  }
  public computeSchools = async (): Promise<void> => {
    try {
      this.setSchools(
        Array.from(
          (
            await Query.get().getSchoolsList(
              UsageConstants.QUERY_GETSCHOOLSLIST_RENTALSEARCHREQUESTVIEW_PROPERTIES_SCHOOLS_COMPUTATION,
              new SchoolsListRequest({ pageSize: 1000, offset: 0 })
            )
          ).items
        )
      );
    } catch (exception) {
      console.log(" exception in computeSchools : " + exception.toString());

      this.setSchools([]);
    }
  };
  public setLocations(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.locations,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.locations.clear();

    this.locations.addAll(val);

    this.fire("locations", this);
  }
  public addToLocations(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.locations.contains(val)) this.locations.add(val);
    } else {
      this.locations.remove(this.locations.elementAt(index));

      this.locations.add(val);
    }

    this.fire("locations", this, val, true);
  }
  public removeFromLocations(val: string): void {
    this.locations.remove(val);

    this.fire("locations", this, val, false);
  }
  public computeLocations = (): void => {
    try {
      this.setLocations(
        Array.from(
          this.schools
            .map((school) => school.city + ", " + school.state)
            .toSet()
            .toList()
        )
      );
    } catch (exception) {
      console.log(" exception in computeLocations : " + exception.toString());

      this.setLocations([]);
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            TextView({
              data: "New Request",
              style: new ui.TextStyle({ fontWeight: ui.FontWeight.w600 }),
              className: "xc98 hc",
              key: "0",
            }),
            ui.Container({
              expand: true,
              padding: ui.EdgeInsets.all(5.0, new Map()),
              child: RentalSearchableView<FacilityType>({
                value: this.facilityType,
                items: this.facilityTypes,
                activeColor: cStyle.c36,
                inActiveColor: cStyle.c36,
                placeHolder: "Enter facility or activity",
                onChanged: (text) => {
                  this.rentalSearchableViewonChanged(text, this.d3eState);
                },
              }),
              key: "1",
              className: "xc37 hc vc h v",
            }),
            ui.Container({
              expand: true,
              padding: ui.EdgeInsets.all(5.0, new Map()),
              child: RentalSearchableView<string>({
                value: this.searchLocation,
                items: this.locations,
                activeColor: cStyle.c36,
                inActiveColor: cStyle.c36,
                placeHolder: "Search City or State",
                icon: MaterialIcons.location_pin,
                onChanged: (text) => {
                  this.rentalSearchableView2onChanged(text, this.d3eState);
                },
              }),
              key: "2",
              className: "x60c hc vc h v",
            }),
          ],
          className: "x661 hc vc h v",
          key: "0",
        }),
        ui.Column({
          mainAxisAlignment: ui.MainAxisAlignment.end,
          crossAxisAlignment: ui.CrossAxisAlignment.end,
          children: [
            ui.Row({
              children: [
                SearchButtonWithState({
                  d3eState: this.d3eState,
                  _onPressedSearchButtonHandler:
                    this.onPressedSearchButtonHandler,
                  key: "0",
                }),
              ],
              className: "x2e17 hc h",
              key: "0",
            }),
          ],
          className: "x77ea hc h",
          key: "1",
        }),
      ],
      className: ui.join(
        this.props.className,
        "RentalSearchRequestView xed9 hc h v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressedSearchButtonHandler = (
    d3eState: RentalSearchRequestViewRefs
  ): void => {
    this.navigator.pushSearchPage({
      facilityType: this.facilityType,
      location: this.searchLocation,
      facilityTypes: this.facilityTypes,
      locations: this.locations,
      renter: this.renter,
    });
  };
  public rentalSearchableViewonChanged = (
    val: FacilityType,
    d3eState: RentalSearchRequestViewRefs
  ): void => {
    this.setFacilityType(val);
  };
  public rentalSearchableView2onChanged = (
    val: string,
    d3eState: RentalSearchRequestViewRefs
  ): void => {
    this.setSearchLocation(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get searchButton() {
    return this.d3eState.searchButton;
  }
}
export default function RentalSearchRequestView(
  props: RentalSearchRequestViewProps
) {
  return React.createElement(_RentalSearchRequestViewState, {
    ..._RentalSearchRequestViewState.defaultProps,
    ...props,
  });
}
