import React, { ReactNode } from "react";
import { AlignmentDirectional } from "../classes/AlignmentDirectional";
import { Overflow } from "../classes/Overflow";
import { StackFit } from "../classes/StackFit";
import { TextDirection } from "../classes/TextDirection";
import BaseUIProps, { processBaseUIProps } from "./BaseUIProps";
import { Clip } from "../classes/Clip";

interface StackProps extends BaseUIProps {
  alignment?: AlignmentDirectional;
  textDirection?: TextDirection;
  fit?: StackFit;
  overflow?: Overflow;
  clipBehavior?: Clip;
  children?: Array<ReactNode>;
}

class _Stack extends React.Component<StackProps, {}> {
  static defaultProps = {
    alignment: AlignmentDirectional.topStart,
    fit: StackFit.loose,
    overflow: Overflow.clip,
    clipBehavior: Clip.hardEdge,
  };

  render() {
    return React.createElement(
      "ui-stack",
      {
        class: this.props.className ? this.props.className : "",
        ...processBaseUIProps(this.props),
      },

      this.props.children
    );
  }
}

export default function Stack(props: StackProps) {
  return React.createElement(_Stack, { ..._Stack.defaultProps, ...props });
}
