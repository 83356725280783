import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import RentalRequest from "../models/RentalRequest";
import Popup from "./Popup";
import MaterialIcons from "../icons/MaterialIcons";
import PageNavigator from "../classes/PageNavigator";
import Renter from "../models/Renter";
import PendingPayments from "../classes/PendingPayments";
import Query from "../classes/Query";
import MessageDispatch from "../rocket/MessageDispatch";
import Button from "./Button";
import CellButton from "./CellButton";
import PendingPaymentsRequest from "../models/PendingPaymentsRequest";
import MakePaymentView from "./MakePaymentView";
import TextView from "./TextView";
import IconView from "./IconView";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _ViewButtonOnPressed = (d3eState: _ItemState) => void;

type _PayButtonOnPressed = (d3eState: _ItemState) => void;

export interface RentalPaymentsViewProps extends BaseUIProps {
  key?: string;
  renter: Renter;
}
/// To store state data for RentalPaymentsView
class RentalPaymentsViewRefs {
  public itemState: Map<RentalRequest, _ItemState> = new Map();
  public forItem(item: RentalRequest): _ItemState {
    let res = this.itemState.get(item);

    if (res == null) {
      res = new _ItemState(this, item);

      this.itemState.set(item, res);
    }

    return res;
  }
}

interface PayButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _ItemState;
  _payButtonClicked?: _PayButtonOnPressed;
}

class PayButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _PayButtonWithState extends ObservableComponent<PayButtonWithStateProps> {
  payButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: PayButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get payButton(): PayButtonState {
    return this.props.d3eState.payButton;
  }
  public get d3eState(): _ItemState {
    return this.props.d3eState;
  }
  public get _payButtonClicked(): _PayButtonOnPressed {
    return this.props._payButtonClicked;
  }
  public initState() {
    super.initState();

    this.updateObservable("payButton", null, this.payButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["payButton"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.payButton.disable,
      onPressed: () => {
        this._payButtonClicked(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({
        data: "Pay Now",
        style: new ui.TextStyle({
          fontFamily: cStyle.tTextViewSubHeader1FontFamilyOn,
          color: cStyle.tTextViewSubHeader1ColorOn,
          fontWeight: cStyle.tTextViewSubHeader1FontWeightOn,
        }),
        className: "x802 hc",
      }),
      className: "hc",
    });
  }
}
function PayButtonWithState(props: PayButtonWithStateProps) {
  return React.createElement(_PayButtonWithState, props);
}

interface ViewButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _ItemState;
  _onViewHandler?: _ViewButtonOnPressed;
  item: RentalRequest;
}

class ViewButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ViewButtonWithState extends ObservableComponent<ViewButtonWithStateProps> {
  viewButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ViewButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get item(): RentalRequest {
    return this.props.item;
  }
  public get viewButton(): ViewButtonState {
    return this.props.d3eState.viewButton;
  }
  public get d3eState(): _ItemState {
    return this.props.d3eState;
  }
  public get _onViewHandler(): _ViewButtonOnPressed {
    return this.props._onViewHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("viewButton", null, this.viewButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("item", this.props.item);

    this.on(
      [
        "item",
        "item.requestId",
        "viewButton",
        "viewButton.",
        "viewButton.hover",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ViewButtonWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.item !== this.props.item) {
      this.updateObservable("item", prevProps.item, this.props.item);

      this.fire("item", this);
    }
  }
  public viewButtonOnEnter(event): void {
    return this.viewButton.setHover(true);
  }
  public viewButtonOnExit(event): void {
    return this.viewButton.setHover(false);
  }
  public dispose(): void {
    this.viewButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return CellButton({
      padding: this.viewButton.hover
        ? cStyle.tCellButtonLinkCellButtonPaddingOnHover
        : cStyle.tCellButtonLinkCellButtonPaddingOn,
      disable: this.viewButton.disable,
      onPressed: () => {
        this._onViewHandler(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({
        data: this.item.requestId,
        style: new ui.TextStyle({ fontSize: 16, color: cStyle.c20 }),
        className: "x602 hc",
      }),
      onEnter: (event) => {
        this.viewButtonOnEnter(event);
      },
      onExit: (event) => {
        this.viewButtonOnExit(event);
      },
      className: "x6be3 hc",
    });
  }
}
function ViewButtonWithState(props: ViewButtonWithStateProps) {
  return React.createElement(_ViewButtonWithState, props);
}

class _ItemState {
  parent: RentalPaymentsViewRefs;
  item: RentalRequest;
  payButton: PayButtonState = new PayButtonState();
  viewButton: ViewButtonState = new ViewButtonState();
  public constructor(parent, item) {
    this.parent = parent;

    this.item = item;
  }
}

class _RentalPaymentsViewState extends ObservableComponent<RentalPaymentsViewProps> {
  static defaultProps = { renter: null };
  d3eState: RentalPaymentsViewRefs = new RentalPaymentsViewRefs();
  query: PendingPayments = null;
  request: RentalRequest = null;
  paymentPopupPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RentalPaymentsViewProps) {
    super(props);

    this.initState();
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["renter"], this.computeQuery);

    this.computeQuery();

    this.updateSyncProperty("renter", this.props.renter);

    this.on(
      [
        "query",
        "query.items",
        "query.items.eventName",
        "query.items.facility",
        "query.items.facility.name",
        "query.items.paid",
        "query.items.total",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RentalPaymentsViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public setQuery(val: PendingPayments): void {
    let isValChanged: boolean = this.query !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("query", this.query, val);

    MessageDispatch.get().dispose(this.query);

    this.query = val;

    this.fire("query", this);
  }
  public computeQuery = async (): Promise<void> => {
    try {
      this.setQuery(
        await Query.get().getPendingPayments(
          UsageConstants.QUERY_GETPENDINGPAYMENTS_RENTALPAYMENTSVIEW_PROPERTIES_QUERY_COMPUTATION,
          new PendingPaymentsRequest({
            renter: this.renter,
            pageSize: 100,
            offset: 0,
          }),
          { "synchronize": true }
        )
      );
    } catch (exception) {
      console.log(" exception in computeQuery : " + exception.toString());

      this.setQuery(null);
    }
  };
  public setRequest(val: RentalRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          children: [
            TextView({
              data: "Payments",
              style: new ui.TextStyle({ fontWeight: ui.FontWeight.w600 }),
              className: "xbc7",
              key: "0",
            }),
          ],
          className: "x0e hc h",
          key: "0",
        }),
        this.query?.items.isNotEmpty
          ? ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.Table({
                  defaultColumnWidth: new ui.FlexColumnWidth(1),
                  columnWidths: { 0: new ui.FlexColumnWidth(1) },
                  children: [
                    ui.TableRow({
                      children: [
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: "Res. ID",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "xa6b hc",
                          }),
                          className: "hc",
                          key: "0",
                        }),
                        ui.TableCell({
                          child: TextView({
                            data: "Event Name",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "x21a hc",
                          }),
                          className: "hc",
                          key: "1",
                        }),
                        ui.TableCell({
                          child: TextView({
                            data: "Facility Name",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "x3842 hc",
                          }),
                          className: "hc",
                          key: "2",
                        }),
                        ui.TableCell({
                          child: TextView({
                            data: "Total",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "x539a hc",
                          }),
                          className: "hc",
                          key: "3",
                        }),
                        ui.TableCell({
                          child: TextView({
                            data: "Due",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "xa24 hc",
                          }),
                          className: "hc",
                          key: "4",
                        }),
                        ui.TableCell({
                          child: ui.Container({ className: "hc" }),
                          className: "hc",
                          key: "5",
                        }),
                      ],
                      className: "x9b9 hc",
                      key: "0",
                    }),
                    (this.query?.items).expand((item) => [
                      ui.TableRow({
                        children: [
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: ViewButtonWithState({
                              d3eState: this.d3eState.forItem(item),
                              _onViewHandler: this.onViewHandler,
                              item: item,
                            }),
                            className: "hc",
                            key: "0",
                          }),
                          ui.TableCell({
                            child: TextView({
                              data: item.eventName,
                              softWrap: true,
                              style: new ui.TextStyle({
                                fontFamily:
                                  cStyle.tTextViewSubHeaderFontFamilyOn,
                                color: cStyle.tTextViewSubHeaderColorOn,
                              }),
                              className: "xea5 hc",
                            }),
                            className: "hc",
                            key: "1",
                          }),
                          ui.TableCell({
                            child: TextView({
                              data: item.facility.name,
                              style: new ui.TextStyle({
                                fontFamily:
                                  cStyle.tTextViewSubHeaderFontFamilyOn,
                                color: cStyle.tTextViewSubHeaderColorOn,
                              }),
                              className: "x1dc8 hc",
                            }),
                            className: "hc",
                            key: "2",
                          }),
                          ui.TableCell({
                            child: TextView({
                              data: "$" + item.total.toString(),
                              style: new ui.TextStyle({
                                fontFamily:
                                  cStyle.tTextViewTableCellTextFontFamilyOn,
                                color: cStyle.tTextViewTableCellTextColorOn,
                                fontSize:
                                  cStyle.tTextViewTableCellTextFontSizeOn,
                              }),
                              className: "hc",
                            }),
                            className: "hc",
                            key: "3",
                          }),
                          ui.TableCell({
                            child: TextView({
                              data: "$" + (item.total - item.paid).toString(),
                              style: new ui.TextStyle({
                                fontFamily:
                                  cStyle.tTextViewSubHeaderFontFamilyOn,
                                color: cStyle.tTextViewSubHeaderColorOn,
                              }),
                              className: "x0cb hc",
                            }),
                            className: "hc",
                            key: "4",
                          }),
                          ui.TableCell({
                            child: PayButtonWithState({
                              d3eState: this.d3eState.forItem(item),
                              _payButtonClicked: this.payButtonClicked,
                            }),
                            className: "hc",
                            key: "5",
                          }),
                        ],
                        className: "x461 hc",
                        key: item?.ident,
                      }),
                    ]),
                  ],
                  className: "x29b hc h",
                  key: "0",
                }),
              ],
              className: "x337 hc h",
            })
          : ui.Column({
              children: [
                IconView({
                  icon: MaterialIcons.check_circle,
                  size: 150,
                  color: cStyle.c6,
                  className: "x268 hc",
                  key: "0",
                }),
                TextView({
                  data: "No Payment Required",
                  className: "hc",
                  key: "1",
                }),
              ],
              className: "hc",
            }),
      ],
      className: ui.join(this.props.className, "RentalPaymentsView x017 hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public payButtonClicked = (d3eState: _ItemState): void => {
    this.setRequest(d3eState.item);

    this.showPaymentPopup();
  };
  public onViewHandler = (d3eState: _ItemState): void => {
    // TODO: add logic to navigate to the Request overview page

    this.setRequest(d3eState.item);

    this.navigator.pushRequestOverViewPage({
      admin: null,
      renter: this.renter,
      requestObj: this.request,
      target: "rRouter",
      replace: false,
    });
  };
  public dispose(): void {
    MessageDispatch.get().dispose(this.query);

    this.paymentPopupPopup?.dispose();

    super.dispose();
  }
  public showPaymentPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.paymentPopupPopup?.dispose();

    this.paymentPopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        width: 460,
        child: MakePaymentView({ request: this.request }),
        className: "x777 hc vc",
      }),
    });

    this.paymentPopupPopup.showPopup(this.context);
  }
  public hidePaymentPopup(): void {
    this.paymentPopupPopup?.dispose();
  }
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function RentalPaymentsView(props: RentalPaymentsViewProps) {
  return React.createElement(_RentalPaymentsViewState, {
    ..._RentalPaymentsViewState.defaultProps,
    ...props,
  });
}
