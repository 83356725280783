import {
  FLOAT64_MAX,
  FLOAT64_MIN,
  TWO_TO_20,
  TWO_TO_32,
  TWO_TO_52,
} from "../classes/core";

class WriterHelper {
  low: number;
  high: number;

  constructor() {}

  splitInt64(value: number) {
    // Convert to sign-magnitude representation.
    var sign = value < 0;
    value = Math.abs(value);

    // Extract low 32 bits and high 32 bits as unsigned integers.
    var lowBits = value >>> 0;
    var highBits = Math.floor((value - lowBits) / TWO_TO_32);
    highBits = highBits >>> 0;

    // Perform two's complement conversion if the sign bit was set.
    if (sign) {
      highBits = ~highBits >>> 0;
      lowBits = ~lowBits >>> 0;
      lowBits += 1;
      if (lowBits > 0xffffffff) {
        lowBits = 0;
        highBits++;
        if (highBits > 0xffffffff) highBits = 0;
      }
    }

    this.low = lowBits;
    this.high = highBits;
  }

  splitFloat64(value: number) {
    var sign = value < 0 ? 1 : 0;
    value = sign ? -value : value;

    // Handle zeros.
    if (value === 0) {
      if (1 / value > 0) {
        // Positive zero.
        this.high = 0x00000000;
        this.low = 0x00000000;
      } else {
        // Negative zero.
        this.high = 0x80000000;
        this.low = 0x00000000;
      }
      return;
    }

    // Handle nans.
    if (isNaN(value)) {
      this.high = 0x7fffffff;
      this.low = 0xffffffff;
      return;
    }

    // Handle infinities.
    if (value > FLOAT64_MAX) {
      this.high = ((sign << 31) | 0x7ff00000) >>> 0;
      this.low = 0;
      return;
    }

    // Handle denormals.
    if (value < FLOAT64_MIN) {
      // Number is a denormal.
      var mant = value / Math.pow(2, -1074);
      var mantHigh = mant / TWO_TO_32;
      this.high = ((sign << 31) | mantHigh) >>> 0;
      this.low = mant >>> 0;
      return;
    }

    // Compute the least significant exponent needed to represent the magnitude of
    // the value by repeadly dividing/multiplying by 2 until the magnitude
    // crosses 2. While tempting to use log math to find the exponent, at the
    // boundaries of precision, the result can be off by one.
    var maxDoubleExponent = 1023;
    var minDoubleExponent = -1022;
    var x = value;
    var exp = 0;
    if (x >= 2) {
      while (x >= 2 && exp < maxDoubleExponent) {
        exp++;
        x = x / 2;
      }
    } else {
      while (x < 1 && exp > minDoubleExponent) {
        x = x * 2;
        exp--;
      }
    }
    var mant = value * Math.pow(2, -exp);

    var mantHigh = (mant * TWO_TO_20) & 0xfffff;
    var mantLow = (mant * TWO_TO_52) >>> 0;

    this.high = ((sign << 31) | ((exp + 1023) << 20) | mantHigh) >>> 0;
    this.low = mantLow;
  }
}

export default class BufferWriter {
  static encoder = new TextEncoder();
  private _buffer: number[] = [];

  public writeBool(value: boolean) {
    this._buffer.push(value ? 1 : 0);
  }

  public writeInt(value: number) {
    let helper = new WriterHelper();
    let zigzagged = this._encodeZigZag64(value);
    helper.splitInt64(zigzagged);

    // Break the binary representation into chunks of 7 bits, set the 8th bit
    // in each chunk if it's not the final chunk, and append to the result.
    let lowBits = helper.low;
    let highBits = helper.high;
    while (highBits > 0 || lowBits > 127) {
      this._buffer.push((lowBits & 0x7f) | 0x80);
      lowBits = ((lowBits >>> 7) | (highBits << 25)) >>> 0;
      highBits = highBits >>> 7;
    }
    this._buffer.push(lowBits);
  }

  public writeByte(value: number) {
    this._buffer.push(value);
  }

  public writeDouble(value: number) {
    let helper = new WriterHelper();
    helper.splitFloat64(value);
    // Since it is BigEndian, we write the high bits first
    this.writeUint32BigEndian(helper.high);
    this.writeUint32BigEndian(helper.low);
  }

  public writeString(value: string) {
    if (value == null) {
      this.writeInt(-1);
    } else if (value.isEmpty) {
      this.writeInt(0);
    } else {
      this._writeString(value);
    }
  }

  public takeBytes(): Uint8Array {
    var array = new Uint8Array(this._buffer);
    return array;
  }

  private _encodeZigZag64(value: number): number {
    return (value << 1) ^ (value >> 63);
  }

  private writeUint32(value: number) {
    this._buffer.push((value >>> 0) & 0xff);
    this._buffer.push((value >>> 8) & 0xff);
    this._buffer.push((value >>> 16) & 0xff);
    this._buffer.push((value >>> 24) & 0xff);
  }

  private writeUint32BigEndian(value: number) {
    this._buffer.push((value >>> 24) & 0xff);
    this._buffer.push((value >>> 16) & 0xff);
    this._buffer.push((value >>> 8) & 0xff);
    this._buffer.push((value >>> 0) & 0xff);
  }

  public _writeString(value: string) {
    let encode = BufferWriter.encoder.encode(value);
    this.writeInt(encode.length);
    this._buffer.addAll(Array.from(encode));
    return encode.length;
  }
}
