import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import TextView from "./TextView";
import Renter from "../models/Renter";
import { BuildContext } from "../classes/BuildContext";

export interface PaymentMethodsPageProps extends BaseUIProps {
  key?: string;
  renter: Renter;
}

class _PaymentMethodsPageState extends ObservableComponent<PaymentMethodsPageProps> {
  static defaultProps = { renter: null };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: PaymentMethodsPageProps) {
    super(props);

    this.initState();
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("renter", this.props.renter);
  }
  public componentDidUpdate(prevProps: PaymentMethodsPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        TextView({
          data: "Payment Methods",
          style: new ui.TextStyle({
            fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
            fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
          }),
          className: "hc",
          key: "0",
        }),
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [
            TextView({
              data: "No Saved Payment Methods found.",
              style: new ui.TextStyle({
                fontSize: cStyle.tTextViewHeadlineOneFontSizeOn,
                fontWeight: cStyle.tTextViewHeadlineOneFontWeightOn,
              }),
              key: "0",
            }),
          ],
          className: "x7ec hc h",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "PaymentMethodsPage x63a hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function PaymentMethodsPage(props: PaymentMethodsPageProps) {
  return React.createElement(_PaymentMethodsPageState, {
    ..._PaymentMethodsPageState.defaultProps,
    ...props,
  });
}
