import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import SignUpRequest from "../models/SignUpRequest";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import PageNavigator from "../classes/PageNavigator";
import Renter from "../models/Renter";
import IconButton from "./IconButton";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

type _OnProfilePressedOnPressed = (d3eState: HeaderViewRefs) => void;

type _LoginBtnOnPressed = (d3eState: HeaderViewRefs) => void;

type _Button8OnPressed = (d3eState: HeaderViewRefs) => void;

export interface HeaderViewProps extends BaseUIProps {
  key?: string;
  renter?: Renter;
}
/// To store state data for HeaderView
class HeaderViewRefs {
  public button8: Button8State = new Button8State();
  public loginBtn: LoginBtnState = new LoginBtnState();
  public onProfilePressed: OnProfilePressedState = new OnProfilePressedState();
}

interface Button8WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: HeaderViewRefs;
  _onSignUpHandler?: _Button8OnPressed;
}

class Button8State extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _Button8WithState extends ObservableComponent<Button8WithStateProps> {
  button8FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Button8WithStateProps) {
    super(props);

    this.initState();
  }
  public get button8(): Button8State {
    return this.props.d3eState.button8;
  }
  public get d3eState(): HeaderViewRefs {
    return this.props.d3eState;
  }
  public get _onSignUpHandler(): _Button8OnPressed {
    return this.props._onSignUpHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("button8", null, this.button8);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button8"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: 110,
      child: Button({
        padding: cStyle.tButtonPrimaryButtonPaddingOn,
        decoration: cStyle.tButtonPrimaryButtonDecorationOn,
        disable: this.button8.disable,
        onPressed: () => {
          this._onSignUpHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Sign Up" }),
      }),
      className: "x01 hc",
    });
  }
}
function Button8WithState(props: Button8WithStateProps) {
  return React.createElement(_Button8WithState, props);
}

interface LoginBtnWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: HeaderViewRefs;
  _onLoginHandler?: _LoginBtnOnPressed;
}

class LoginBtnState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _LoginBtnWithState extends ObservableComponent<LoginBtnWithStateProps> {
  loginBtnFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LoginBtnWithStateProps) {
    super(props);

    this.initState();
  }
  public get loginBtn(): LoginBtnState {
    return this.props.d3eState.loginBtn;
  }
  public get d3eState(): HeaderViewRefs {
    return this.props.d3eState;
  }
  public get _onLoginHandler(): _LoginBtnOnPressed {
    return this.props._onLoginHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("loginBtn", null, this.loginBtn);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["loginBtn"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 20.0, 0.0, new Map()),
      child: Button({
        disable: this.loginBtn.disable,
        onPressed: () => {
          this._onLoginHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({
          data: "Log In",
          style: new ui.TextStyle({
            color: cStyle.c20,
            fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
            fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
          }),
          className: "x14",
        }),
      }),
      className: "xe50",
    });
  }
}
function LoginBtnWithState(props: LoginBtnWithStateProps) {
  return React.createElement(_LoginBtnWithState, props);
}

interface OnProfilePressedWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: HeaderViewRefs;
  _onProfilePressedHandler?: _OnProfilePressedOnPressed;
  renter: Renter;
}

class OnProfilePressedState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _OnProfilePressedWithState extends ObservableComponent<OnProfilePressedWithStateProps> {
  onProfilePressedFocusNode: ui.FocusNode = new ui.FocusNode();
  public constructor(props: OnProfilePressedWithStateProps) {
    super(props);

    this.initState();
  }
  public get onProfilePressed(): OnProfilePressedState {
    return this.props.d3eState.onProfilePressed;
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public get d3eState(): HeaderViewRefs {
    return this.props.d3eState;
  }
  public get _onProfilePressedHandler(): _OnProfilePressedOnPressed {
    return this.props._onProfilePressedHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("onProfilePressed", null, this.onProfilePressed);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("renter", this.props.renter);

    this.on(["onProfilePressed", "renter", "renter.fullName"], this.rebuild);
  }
  public componentDidUpdate(prevProps: OnProfilePressedWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    return Button({
      disable: this.onProfilePressed.disable,
      onPressed: () => {
        this._onProfilePressedHandler(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({ data: "Hi " + this.renter.fullName }),
    });
  }
}
function OnProfilePressedWithState(props: OnProfilePressedWithStateProps) {
  return React.createElement(_OnProfilePressedWithState, props);
}

class _HeaderViewState extends ObservableComponent<HeaderViewProps> {
  static defaultProps = { renter: null };
  d3eState: HeaderViewRefs = new HeaderViewRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: HeaderViewProps) {
    super(props);

    this.initState();
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("renter", this.props.renter);

    this.on(["renter"], this.rebuild);
  }
  public componentDidUpdate(prevProps: HeaderViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            ui.Wrap({
              crossAxisAlignment: ui.WrapCrossAlignment.center,
              children: [
                ui.Wrap({
                  crossAxisAlignment: ui.WrapCrossAlignment.center,
                  children: [
                    IconView({
                      size: 24,
                      icon: MaterialIcons.email,
                      color: cStyle.c2,
                      className: "xf9d",
                      key: "0",
                    }),
                    TextView({
                      data: "contact@facilires.com",
                      style: new ui.TextStyle({ color: cStyle.c2 }),
                      className: "xf47",
                      key: "1",
                    }),
                  ],
                  className: "x226",
                  key: "0",
                }),
                ui.Wrap({
                  crossAxisAlignment: ui.WrapCrossAlignment.center,
                  children: [
                    IconView({
                      size: 24,
                      icon: MaterialIcons.local_phone,
                      color: cStyle.c2,
                      className: "x539",
                      key: "0",
                    }),
                    TextView({
                      data: "510-378-8077",
                      style: new ui.TextStyle({ color: cStyle.c2 }),
                      className: "xca",
                      key: "1",
                    }),
                  ],
                  className: "xee5",
                  key: "1",
                }),
                ui.Wrap({
                  crossAxisAlignment: ui.WrapCrossAlignment.center,
                  children: [
                    IconView({
                      size: 24,
                      icon: MaterialIcons.location_pin,
                      color: cStyle.c2,
                      className: "xded",
                      key: "0",
                    }),
                    TextView({
                      data: "392 E Winchester St, Suite 201,. Murray, Utah 84107",
                      style: new ui.TextStyle({ color: cStyle.c2 }),
                      className: "x47f",
                      key: "1",
                    }),
                  ],
                  className: "xfa7",
                  key: "2",
                }),
              ],
              key: "0",
            }),
            ui.Wrap({
              children: [
                IconButton({
                  icon: MaterialIcons.shop,
                  color: cStyle.c2,
                  onPressed: () => {
                    this.onPressedHomeButtonHandler(this.d3eState);
                  },
                  key: "0",
                }),
                IconButton({
                  icon: MaterialIcons.add_to_drive,
                  color: cStyle.c2,
                  onPressed: () => {
                    this.iconButton2Handler(this.d3eState);
                  },
                  key: "1",
                }),
                IconButton({
                  icon: MaterialIcons.description,
                  color: cStyle.c2,
                  onPressed: () => {
                    this.iconButton3Handler(this.d3eState);
                  },
                  key: "2",
                }),
                IconButton({
                  icon: MaterialIcons.airplay,
                  color: cStyle.c2,
                  onPressed: () => {
                    this.iconButton4Handler(this.d3eState);
                  },
                  key: "3",
                }),
              ],
              key: "1",
            }),
          ],
          className: "xf04 hc h",
          key: "0",
        }),
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            ui.Container({
              child: ui.AssetImage({
                path: "images/Logo.png",
                height: 50,
                width: 130,
                className: "x3ab hc vc",
              }),
              onTap: (e) => {
                e.stopPropagation();

                this.imageViewButtonHandler(this.d3eState);
              },
              key: "0",
            }),
            this.renter !== null
              ? OnProfilePressedWithState({
                  d3eState: this.d3eState,
                  _onProfilePressedHandler: this.onProfilePressedHandler,
                  renter: this.renter,
                })
              : ui.Wrap({
                  crossAxisAlignment: ui.WrapCrossAlignment.center,
                  children: [
                    LoginBtnWithState({
                      d3eState: this.d3eState,
                      _onLoginHandler: this.onLoginHandler,
                      key: "0",
                    }),
                    Button8WithState({
                      d3eState: this.d3eState,
                      _onSignUpHandler: this.onSignUpHandler,
                      key: "1",
                    }),
                  ],
                }),
          ],
          className: "x27 hc h",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "HeaderView x187 hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressedHomeButtonHandler = (d3eState: HeaderViewRefs): void => {
    this.navigator.pushMainHomePage();
  };
  public imageViewButtonHandler = (d3eState: HeaderViewRefs): void => {
    this.navigator.pushMainHomePage();
  };
  public onProfilePressedHandler = (d3eState: HeaderViewRefs): void => {
    this.navigator.pushRentalHomePage({ renter: this.renter, replace: true });
  };
  public iconButton4Handler = (d3eState: HeaderViewRefs): void => {};
  public iconButton2Handler = (d3eState: HeaderViewRefs): void => {};
  public iconButton3Handler = (d3eState: HeaderViewRefs): void => {};
  public onLoginHandler = (d3eState: HeaderViewRefs): void => {
    this.navigator.pushRentalLoginPage();
  };
  public onSignUpHandler = (d3eState: HeaderViewRefs): void => {
    this.navigator.pushRentelSignupPage({ signUpRequest: new SignUpRequest() });
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get button8() {
    return this.d3eState.button8;
  }
  public get loginBtn() {
    return this.d3eState.loginBtn;
  }
  public get onProfilePressed() {
    return this.d3eState.onProfilePressed;
  }
}
export default function HeaderView(props: HeaderViewProps) {
  return React.createElement(_HeaderViewState, {
    ..._HeaderViewState.defaultProps,
    ...props,
  });
}
