import FacilityUsageCategory from "./FacilityUsageCategory";
import DBObject from "../utils/DBObject";
import Result from "../classes/Result";
import CloneContext from "../utils/CloneContext";

export default class FacilityRate extends DBObject {
  private static readonly _CATEGORY: number = 0;
  private static readonly _PRICE: number = 1;
  public id: number = 0;
  public otherMaster: DBObject;
  private _category: FacilityUsageCategory = null;
  private _price: number = 0.0;
  public childPropertyInMaster: number = 0;
  public constructor(
    d3eParams?: Partial<{ category: FacilityUsageCategory; price: number }>
  ) {
    super();

    this.setCategory(d3eParams?.category ?? null);

    this.setPrice(d3eParams?.price ?? 0.0);
  }
  public get d3eType(): string {
    return "FacilityRate";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get category(): FacilityUsageCategory {
    return this._category;
  }
  public setCategory(val: FacilityUsageCategory): void {
    let isValChanged: boolean = this._category !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(FacilityRate._CATEGORY, this._category);

    this.updateObservable("category", this._category, val);

    this._category = val;

    this.fire("category", this);
  }
  public get price(): number {
    return this._price;
  }
  public setPrice(val: number): void {
    let isValChanged: boolean = this._price !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(FacilityRate._PRICE, this._price);

    this._price = val;

    this.fire("price", this);
  }
  public get(field: number): any {
    switch (field) {
      case FacilityRate._CATEGORY: {
        return this._category;
      }

      case FacilityRate._PRICE: {
        return this._price;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);

    this.otherMaster?.updateChildChanges(this.childPropertyInMaster);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): FacilityRate {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: FacilityRate = dbObj as FacilityRate;

    obj.id = this.id;

    obj.setCategory(this._category);

    obj.setPrice(this._price);
  }
  public async save(): Promise<Result<FacilityRate>> {
    if (this.otherMaster != null) {
      return this.otherMaster.save();
    }

    return new Result();
  }
  public async delete(): Promise<Result<FacilityRate>> {
    if (this.otherMaster != null) {
      return this.otherMaster.delete();
    }

    return new Result();
  }
  public set(field: number, value: any): void {
    switch (field) {
      case FacilityRate._CATEGORY: {
        this.setCategory(value as FacilityUsageCategory);
        break;
      }

      case FacilityRate._PRICE: {
        this.setPrice(value as number);
        break;
      }
    }
  }
}
