import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class Service extends DBObject {
  private static readonly _NAME: number = 0;
  private static readonly _RATEPERHOUR: number = 1;
  public id: number = 0;
  public otherMaster: DBObject;
  private _name: string = "";
  private _ratePerHour: number = 0.0;
  public constructor(
    d3eParams?: Partial<{ name: string; ratePerHour: number }>
  ) {
    super();

    this.setName(d3eParams?.name ?? "");

    this.setRatePerHour(d3eParams?.ratePerHour ?? 0.0);
  }
  public get d3eType(): string {
    return "Service";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get name(): string {
    return this._name;
  }
  public setName(val: string): void {
    let isValChanged: boolean = this._name !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Service._NAME, this._name);

    this._name = val;

    this.fire("name", this);
  }
  public get ratePerHour(): number {
    return this._ratePerHour;
  }
  public setRatePerHour(val: number): void {
    let isValChanged: boolean = this._ratePerHour !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Service._RATEPERHOUR, this._ratePerHour);

    this._ratePerHour = val;

    this.fire("ratePerHour", this);
  }
  public get(field: number): any {
    switch (field) {
      case Service._NAME: {
        return this._name;
      }

      case Service._RATEPERHOUR: {
        return this._ratePerHour;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): Service {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: Service = dbObj as Service;

    obj.id = this.id;

    obj.setName(this._name);

    obj.setRatePerHour(this._ratePerHour);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case Service._NAME: {
        this.setName(value as string);
        break;
      }

      case Service._RATEPERHOUR: {
        this.setRatePerHour(value as number);
        break;
      }
    }
  }
}
