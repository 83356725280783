import React, { ReactNode, ReactNodeArray } from "react";
import { CrossAxisAlignment } from "../classes/CrossAxisAlignment";
import CSSHelper from "../classes/CSSHelper";
import { MainAxisAlignment } from "../classes/MainAxisAlignment";
import { MainAxisSize } from "../classes/MainAxisSize";
import { TextBaseline } from "../classes/TextBaseline";
import { TextDirection } from "../classes/TextDirection";
import { VerticalDirection } from "../classes/VerticalDirection";
import BaseUIProps, { processBaseUIProps } from "./BaseUIProps";

interface ColumnProps extends BaseUIProps {
  key?: string;
  mainAxisAlignment?: MainAxisAlignment;
  mainAxisSize?: MainAxisSize;
  crossAxisAlignment?: CrossAxisAlignment;
  textDirection?: TextDirection;
  verticalDirection?: VerticalDirection;
  textBaseline?: TextBaseline;
  children?: ReactNode[];
}

class _Column extends React.Component<ColumnProps, {}> {
  static defaultProps = {
    mainAxisAlignment: MainAxisAlignment.start,
    mainAxisSize: MainAxisSize.max,
    crossAxisAlignment: CrossAxisAlignment.center,
    verticalDirection: VerticalDirection.down,
  };
  render() {
    let classNames = ["fd-col"];
    classNames.addAll(
      CSSHelper.handleMainAxisAlignment(this.props.mainAxisAlignment)
    );
    classNames.addAll(
      CSSHelper.handleCrossAxisAlignment(this.props.crossAxisAlignment)
    );
    if (this.props.mainAxisSize === MainAxisSize.max) {
      classNames.push("max");
    }

    if (this.props.textDirection === TextDirection.rtl) {
      classNames.push("rtl");
    }
    return React.createElement(
      "ui-column",
      {
        class:
          classNames.join(" ") +
          (this.props.className ? " " + this.props.className : ""),
        ...processBaseUIProps(this.props),
      },
      this.props.children
    );
  }
}

export default function Column(props: ColumnProps) {
  return React.createElement(_Column, {
    ..._Column.defaultProps,
    ...props,
  });
}
