import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

export interface StatusCardViewProps extends BaseUIProps {
  key?: string;
  title?: string;
  bgColor?: ui.Color;
  headerBg?: ui.Color;
  child?: ReactNode;
  trailing?: ReactNode;
  leading?: ReactNode;
}

class _StatusCardViewState extends ObservableComponent<StatusCardViewProps> {
  static defaultProps = { title: "", bgColor: null, headerBg: null };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: StatusCardViewProps) {
    super(props);

    this.initState();
  }
  public get title(): string {
    return this.props.title;
  }
  public get bgColor(): ui.Color {
    return this.props.bgColor;
  }
  public get headerBg(): ui.Color {
    return this.props.headerBg;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "bgColor",
        "childPresent",
        "headerBg",
        "leadingPresent",
        "title",
        "trailingPresent",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: StatusCardViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.title !== this.props.title) {
      this.fire("title", this);
    }

    if (prevProps.bgColor !== this.props.bgColor) {
      this.fire("bgColor", this);
    }

    if (prevProps.headerBg !== this.props.headerBg) {
      this.fire("headerBg", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      decoration: new ui.BoxDecoration({
        color:
          this.bgColor !== null
            ? this.bgColor
            : ui.HexColor.fromHexInt(0xee362d8a),
        borderRadius: ui.BorderRadius.circular(5.0),
      }),
      child: ui.Column({
        crossAxisAlignment: ui.CrossAxisAlignment.start,
        children: [
          (this.title !== null && this.title.isNotEmpty) ||
          this.leadingPresent ||
          this.trailingPresent
            ? ui.Container({
                decoration: new ui.BoxDecoration({
                  color:
                    this.headerBg !== null
                      ? this.headerBg
                      : ui.HexColor.fromHexInt(0xee362d8a),
                }),
                child: ui.Row({
                  children: [
                    this.title !== null && this.title.isNotEmpty
                      ? TextView({
                          data: this.title,
                          style: new ui.TextStyle({
                            fontWeight: ui.FontWeight.w600,
                            fontFamily: "Open Sans",
                            color: cStyle.c2,
                          }),
                          className: "xddd1",
                        })
                      : [],
                    this.leadingPresent ? this.props.leading : [],
                    ui.Container({ className: "x68d5 hc h", key: "2" }),
                    this.trailingPresent ? this.props.trailing : [],
                  ],
                }),
                className: "x0055 hc h",
              })
            : [],
          this.childPresent
            ? ui.Container({
                child: this.props.child,
                className: "x015 hc vc h v",
              })
            : [],
        ],
      }),
      className: ui.join(this.props.className, "StatusCardView x061 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public get childPresent(): boolean {
    return this.props.child != null;
  }
  public get child(): ReactNode {
    return this.props.child;
  }
  public get trailingPresent(): boolean {
    return this.props.trailing != null;
  }
  public get trailing(): ReactNode {
    return this.props.trailing;
  }
  public get leadingPresent(): boolean {
    return this.props.leading != null;
  }
  public get leading(): ReactNode {
    return this.props.leading;
  }
}
export default function StatusCardView(props: StatusCardViewProps) {
  return React.createElement(_StatusCardViewState, {
    ..._StatusCardViewState.defaultProps,
    ...props,
  });
}
