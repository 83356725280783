import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import FacilityType from "../models/FacilityType";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

type _FacilityRentCardViewOnTapFacilityType = (value: FacilityType) => void;

export interface FacilityRentCardViewProps extends BaseUIProps {
  key?: string;
  facilityType: FacilityType;
  onTapFacilityType?: _FacilityRentCardViewOnTapFacilityType;
}

class _FacilityRentCardViewState extends ObservableComponent<FacilityRentCardViewProps> {
  static defaultProps = { facilityType: null, onTapFacilityType: null };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: FacilityRentCardViewProps) {
    super(props);

    this.initState();
  }
  public get facilityType(): FacilityType {
    return this.props.facilityType;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("facilityType", this.props.facilityType);

    this.on(
      ["facilityType", "facilityType.image", "facilityType.name"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: FacilityRentCardViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.facilityType !== this.props.facilityType) {
      this.updateObservable(
        "facilityType",
        prevProps.facilityType,
        this.props.facilityType
      );

      this.fire("facilityType", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      decoration: new ui.BoxDecoration({
        image: new ui.DecorationImage({
          imageUrl: this.facilityType.image?.downloadUrl ?? "",
          fit: ui.BoxFit.fill,
        }),
      }),
      child: ui.Column({
        crossAxisAlignment: ui.CrossAxisAlignment.start,
        mainAxisAlignment: ui.MainAxisAlignment.end,
        children: [
          ui.Row({
            mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
            children: [
              TextView({
                data: this.facilityType.name,
                style: new ui.TextStyle({
                  color: cStyle.c2,
                  fontSize: cStyle.tTextViewHeadlineOneFontSizeOn,
                  fontWeight: cStyle.tTextViewHeadlineOneFontWeightOn,
                }),
                className: "x57",
                key: "0",
              }),
            ],
            className: "x9a5 hc h",
            key: "0",
          }),
        ],
        className: "x5d5 hc vc h v",
      }),
      onTap: (e) => {
        e.stopPropagation();

        this.onTapFacilityTypeHandler();
      },
      className: ui.join(
        this.props.className,
        "FacilityRentCardView xba hc vc"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onTapFacilityTypeHandler = (): void => {
    this.onTapFacilityType(this.facilityType);
  };
  public get onTapFacilityType(): _FacilityRentCardViewOnTapFacilityType {
    return this.props.onTapFacilityType;
  }
}
export default function FacilityRentCardView(props: FacilityRentCardViewProps) {
  return React.createElement(_FacilityRentCardViewState, {
    ..._FacilityRentCardViewState.defaultProps,
    ...props,
  });
}
