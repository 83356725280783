import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import Service from "../models/Service";
import Equipment from "../models/Equipment";
import D3EDisposable from "../rocket/D3EDisposable";
import RentalRequest from "../models/RentalRequest";
import TextView from "./TextView";
import DurationView from "./DurationView";
import LightDivider from "./LightDivider";
import MessageDispatch from "../rocket/MessageDispatch";
import Query from "../classes/Query";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

export interface InvoiceViewProps extends BaseUIProps {
  key?: string;
  request: RentalRequest;
}

class _InvoiceViewState extends ObservableComponent<InvoiceViewProps> {
  static defaultProps = { request: null };
  totalFacility: number = 0.0;
  totalServices: number = 0.0;
  totalEquipements: number = 0.0;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public requestDisposable: D3EDisposable;
  public constructor(props: InvoiceViewProps) {
    super(props);

    this.initState();
  }
  public get request(): RentalRequest {
    return this.props.request;
  }
  public initState() {
    super.initState();

    this.runFetchDataQueryForRequest();

    this.requestDisposable = MessageDispatch.get().syncObject(
      this.props.request,
      UsageConstants.QUERY_GETRENTALREQUESTBYID_INVOICEVIEW_REQUEST_FETCHDATA
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("request", this.props.request);

    this.on(
      [
        "request",
        "request.endTime",
        "request.facility",
        "request.facility.prices",
        "request.facility.prices.price",
        "request.startTime",
      ],
      this.computeTotalFacility
    );

    this.computeTotalFacility();

    this.on(
      [
        "request",
        "request.endTime",
        "request.services",
        "request.services.ratePerHour",
        "request.startTime",
      ],
      this.computeTotalServices
    );

    this.computeTotalServices();

    this.on(
      [
        "request",
        "request.endTime",
        "request.equipments",
        "request.equipments.ratePerHour",
        "request.startTime",
      ],
      this.computeTotalEquipements
    );

    this.computeTotalEquipements();

    this.on(
      [
        "request",
        "request.endTime",
        "request.equipments",
        "request.equipments.name",
        "request.equipments.ratePerHour",
        "request.paid",
        "request.price",
        "request.services",
        "request.services.name",
        "request.services.ratePerHour",
        "request.startTime",
        "request.total",
        "totalEquipements",
        "totalFacility",
        "totalServices",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: InvoiceViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.request !== this.props.request) {
      this.updateObservable("request", prevProps.request, this.props.request);

      this.runFetchDataQueryForRequest();

      this.fire("request", this);
    }
  }
  public runFetchDataQueryForRequest = (): void => {
    if (this.request == null) {
      return;
    }

    Query.get().getRentalRequestById(
      UsageConstants.QUERY_GETRENTALREQUESTBYID_INVOICEVIEW_REQUEST_FETCHDATA,
      this.request.id
    );
  };
  public setTotalFacility(val: number): void {
    let isValChanged: boolean = this.totalFacility !== val;

    if (!isValChanged) {
      return;
    }

    this.totalFacility = val;

    this.fire("totalFacility", this);
  }
  public computeTotalFacility = (): void => {
    try {
      this.setTotalFacility(
        this.request.facility.prices
          .map(
            (r) =>
              r.price *
              this.request.endTime.difference(this.request.startTime).inHours
          )
          .reduce((a, b) => a + b)
      );
    } catch (exception) {
      console.log(
        " exception in computeTotalFacility : " + exception.toString()
      );

      this.setTotalFacility(0.0);
    }
  };
  public setTotalServices(val: number): void {
    let isValChanged: boolean = this.totalServices !== val;

    if (!isValChanged) {
      return;
    }

    this.totalServices = val;

    this.fire("totalServices", this);
  }
  public computeTotalServices = (): void => {
    try {
      this.setTotalServices(
        this.request.services
          .map(
            (s) =>
              s.ratePerHour *
              this.request.endTime.difference(this.request.startTime).inHours
          )
          .reduce((a, b) => a + b)
      );
    } catch (exception) {
      console.log(
        " exception in computeTotalServices : " + exception.toString()
      );

      this.setTotalServices(0.0);
    }
  };
  public setTotalEquipements(val: number): void {
    let isValChanged: boolean = this.totalEquipements !== val;

    if (!isValChanged) {
      return;
    }

    this.totalEquipements = val;

    this.fire("totalEquipements", this);
  }
  public computeTotalEquipements = (): void => {
    try {
      this.setTotalEquipements(
        this.request.equipments
          .map(
            (e) =>
              e.ratePerHour *
              this.request.endTime.difference(this.request.startTime).inHours
          )
          .reduce((a, b) => a + b)
      );
    } catch (exception) {
      console.log(
        " exception in computeTotalEquipements : " + exception.toString()
      );

      this.setTotalEquipements(0.0);
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                TextView({ data: "Facility Rate", className: "hc", key: "0" }),
                TextView({ data: "Duration", className: "hc", key: "1" }),
              ],
              className: "x37c hc h",
              key: "0",
            }),
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              mainAxisAlignment: ui.MainAxisAlignment.start,
              children: [
                TextView({
                  data: "$" + this.request.price.toString() + "/hr",
                  className: "hc",
                  key: "0",
                }),
                DurationView({
                  value: this.request.endTime.difference(
                    this.request.startTime
                  ),
                  format: "HH.HHh",
                  className: "hc",
                  key: "1",
                }),
                this.request.services.isNotEmpty &&
                this.request.equipments.isNotEmpty
                  ? ui.Column({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        LightDivider({
                          dividerColor: cStyle.c14,
                          className: "xc9 hc h",
                          key: "0",
                        }),
                        ui.Row({
                          mainAxisAlignment: ui.MainAxisAlignment.end,
                          children: [
                            TextView({
                              data: "$" + this.totalFacility.toString(),
                              style: new ui.TextStyle({
                                fontWeight: ui.FontWeight.bold,
                              }),
                              className: "x233",
                              key: "0",
                            }),
                          ],
                          className: "x63d hc h",
                          key: "1",
                        }),
                        LightDivider({
                          dividerColor: cStyle.c14,
                          className: "xbeb hc h",
                          key: "2",
                        }),
                      ],
                      className: "x520b hc h",
                    })
                  : [],
              ],
              className: "x7c8 hc h",
              key: "1",
            }),
          ],
          className: "xcd1 hc h",
          key: "0",
        }),
        this.request.services.isNotEmpty
          ? ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                TextView({ data: "Services", className: "hc", key: "0" }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    ui.Column({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      mainAxisAlignment: ui.MainAxisAlignment.start,
                      children: [
                        this.request.services.expand((item) => [
                          TextView({
                            data: item.name,
                            className: "x88 hc",
                            key: item?.ident,
                          }),
                        ]),
                      ],
                      className: "xb6 hc h",
                      key: "0",
                    }),
                    ui.Column({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      mainAxisAlignment: ui.MainAxisAlignment.start,
                      children: [
                        this.request.services.expand((item) => [
                          ui.Row({
                            mainAxisAlignment: ui.MainAxisAlignment.start,
                            children: [
                              DurationView({
                                value: this.request.endTime.difference(
                                  this.request.startTime
                                ),
                                format: "HH.HHh",
                                key: "0",
                              }),
                              TextView({
                                data: "$" + item.ratePerHour.toString() + "/hr",
                                key: "1",
                              }),
                            ],
                            className: "xf6e hc h",
                            key: item?.ident,
                          }),
                        ]),
                        LightDivider({
                          dividerColor: cStyle.c14,
                          className: "x17b hc h",
                          key: "1",
                        }),
                        ui.Row({
                          mainAxisAlignment: ui.MainAxisAlignment.end,
                          children: [
                            TextView({
                              data: "$" + this.totalServices.toString(),
                              style: new ui.TextStyle({
                                fontWeight: ui.FontWeight.bold,
                              }),
                              className: "x0625",
                              key: "0",
                            }),
                          ],
                          className: "x2d hc h",
                          key: "2",
                        }),
                        LightDivider({
                          dividerColor: cStyle.c14,
                          className: "x266 hc h",
                          key: "3",
                        }),
                      ],
                      className: "x67 hc h",
                      key: "1",
                    }),
                  ],
                  className: "xf5 hc h",
                  key: "1",
                }),
              ],
              className: "x7dc hc h",
            })
          : [],
        this.request.equipments.isNotEmpty
          ? ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                TextView({ data: "Equipments", className: "hc", key: "0" }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    ui.Column({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      mainAxisAlignment: ui.MainAxisAlignment.start,
                      children: [
                        this.request.equipments.expand((item) => [
                          TextView({
                            data: item.name,
                            className: "x49 hc",
                            key: item?.ident,
                          }),
                        ]),
                      ],
                      className: "x846 hc h",
                      key: "0",
                    }),
                    ui.Column({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      mainAxisAlignment: ui.MainAxisAlignment.start,
                      children: [
                        this.request.equipments.expand((item) => [
                          ui.Row({
                            mainAxisAlignment: ui.MainAxisAlignment.start,
                            children: [
                              DurationView({
                                value: this.request.endTime.difference(
                                  this.request.startTime
                                ),
                                format: "HH.HHh",
                                key: "0",
                              }),
                              TextView({
                                data: "$" + item.ratePerHour.toString() + "/hr",
                                key: "1",
                              }),
                            ],
                            className: "x194 hc h",
                            key: item?.ident,
                          }),
                        ]),
                        LightDivider({
                          dividerColor: cStyle.c14,
                          className: "x49d hc h",
                          key: "1",
                        }),
                        ui.Row({
                          mainAxisAlignment: ui.MainAxisAlignment.end,
                          children: [
                            TextView({
                              data: "$" + this.totalEquipements.toString(),
                              style: new ui.TextStyle({
                                fontWeight: ui.FontWeight.bold,
                              }),
                              className: "x32e",
                              key: "0",
                            }),
                          ],
                          className: "xff8 hc h",
                          key: "2",
                        }),
                        LightDivider({
                          dividerColor: cStyle.c14,
                          className: "xe533 hc h",
                          key: "3",
                        }),
                      ],
                      className: "x8440 hc h",
                      key: "1",
                    }),
                  ],
                  className: "xcfe hc h",
                  key: "1",
                }),
              ],
              className: "x489 hc h",
            })
          : [],
        LightDivider({
          dividerColor: cStyle.c14,
          className: "x817 hc h",
          key: "3",
        }),
        ui.Wrap({
          spacing: 20,
          children: [
            TextView({
              data: "Total: ",
              style: new ui.TextStyle({
                fontWeight: ui.FontWeight.bold,
                color: cStyle.c19,
              }),
              className: "xe18 hc",
              key: "0",
            }),
            TextView({
              data: "$" + this.request.total.toString(),
              style: new ui.TextStyle({
                fontWeight: ui.FontWeight.bold,
                color: cStyle.c19,
              }),
              className: "x15a hc",
              key: "1",
            }),
          ],
          className: "hc",
          key: "4",
        }),
        ui.Wrap({
          spacing: 20,
          children: [
            TextView({
              data: "Paid: ",
              states: ui.joinStates(
                { "data-c0": this.request.paid === this.request.total },
                {}
              ),
              style: new ui.TextStyle({
                fontWeight: ui.FontWeight.bold,
                color:
                  this.request.paid === this.request.total ? cStyle.c19 : null,
              }),
              className: "x916 hc",
              key: "0",
            }),
            TextView({
              data: "$" + this.request.paid.toString(),
              states: ui.joinStates(
                { "data-c0": this.request.paid === this.request.total },
                {}
              ),
              style: new ui.TextStyle({
                fontWeight: ui.FontWeight.bold,
                color:
                  this.request.paid === this.request.total ? cStyle.c19 : null,
              }),
              className: "x638 hc",
              key: "1",
            }),
          ],
          className: "hc",
          key: "5",
        }),
        ui.Wrap({
          spacing: 20,
          children: [
            TextView({
              data: "Due: ",
              states: ui.joinStates(
                { "data-c0": this.request.paid !== this.request.total },
                {}
              ),
              style: new ui.TextStyle({
                fontWeight: ui.FontWeight.bold,
                color:
                  this.request.paid !== this.request.total
                    ? new ui.Color(0xffe71306)
                    : null,
              }),
              className: "x5f3 hc",
              key: "0",
            }),
            TextView({
              data: "$" + (this.request.total - this.request.paid).toString(),
              states: ui.joinStates(
                { "data-c0": this.request.paid !== this.request.total },
                {}
              ),
              style: new ui.TextStyle({
                fontWeight: ui.FontWeight.bold,
                color:
                  this.request.paid !== this.request.total
                    ? new ui.Color(0xffe71306)
                    : null,
              }),
              className: "x336 hc",
              key: "1",
            }),
          ],
          className: "hc",
          key: "6",
        }),
      ],
      className: ui.join(this.props.className, "InvoiceView x7d7 hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public dispose(): void {
    this.requestDisposable?.dispose();

    super.dispose();
  }
}
export default function InvoiceView(props: InvoiceViewProps) {
  return React.createElement(_InvoiceViewState, {
    ..._InvoiceViewState.defaultProps,
    ...props,
  });
}
