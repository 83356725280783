import ChangeNotifier from "./ChangeNotifier";
import { ScrollPosition } from "./ScrollPosition";

export class ScrollController extends ChangeNotifier {
  debugLabel: string;
  hasClients: boolean;
  initialScrollOffset: number;
  keepScrollOffset: boolean;
  offset: number;
  position: ScrollPosition;
  positions: Array<ScrollPosition>;

  /*
    Not doing this for now.
    private scrollable: SingleChildScrollView | ListView;

    connect(scrollable: SingleChildScrollView | ListView | undefined) {
        if (scrollable) {
            this.scrollable = scrollable;
        }
    }
    */

  setScrollPosition(offset: number) {
    this.offset = offset;
    if (this.position) {
      this.position.pixels = offset;
    }
    // Maybe fire here.
  }

  constructor(param?: Partial<ScrollController>) {
    super();
    Object.assign(this, param);
  }
}
