import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import DateTimeView from "./DateTimeView";
import CellButton from "./CellButton";
import ListWrapper from "../utils/ListWrapper";
import RentalRequest from "../models/RentalRequest";
import PendingRentalRequestsRequest from "../models/PendingRentalRequestsRequest";
import TextView from "./TextView";
import PageNavigator from "../classes/PageNavigator";
import Renter from "../models/Renter";
import RequestStatus from "../classes/RequestStatus";
import CollectionUtils from "../utils/CollectionUtils";
import Query from "../classes/Query";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _ViewButtonOnPressed = (d3eState: _ItemState) => void;

export interface RentalPendingRequestsViewProps extends BaseUIProps {
  key?: string;
  renter: Renter;
}
/// To store state data for RentalPendingRequestsView
class RentalPendingRequestsViewRefs {
  public itemState: Map<RentalRequest, _ItemState> = new Map();
  public forItem(item: RentalRequest): _ItemState {
    let res = this.itemState.get(item);

    if (res == null) {
      res = new _ItemState(this, item);

      this.itemState.set(item, res);
    }

    return res;
  }
}

interface ViewButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _ItemState;
  _onViewHandler?: _ViewButtonOnPressed;
  item: RentalRequest;
}

class ViewButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ViewButtonWithState extends ObservableComponent<ViewButtonWithStateProps> {
  viewButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ViewButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get item(): RentalRequest {
    return this.props.item;
  }
  public get viewButton(): ViewButtonState {
    return this.props.d3eState.viewButton;
  }
  public get d3eState(): _ItemState {
    return this.props.d3eState;
  }
  public get _onViewHandler(): _ViewButtonOnPressed {
    return this.props._onViewHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("viewButton", null, this.viewButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("item", this.props.item);

    this.on(
      [
        "item",
        "item.requestId",
        "viewButton",
        "viewButton.",
        "viewButton.hover",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ViewButtonWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.item !== this.props.item) {
      this.updateObservable("item", prevProps.item, this.props.item);

      this.fire("item", this);
    }
  }
  public viewButtonOnEnter(event): void {
    return this.viewButton.setHover(true);
  }
  public viewButtonOnExit(event): void {
    return this.viewButton.setHover(false);
  }
  public dispose(): void {
    this.viewButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return CellButton({
      padding: this.viewButton.hover
        ? cStyle.tCellButtonLinkCellButtonPaddingOnHover
        : cStyle.tCellButtonLinkCellButtonPaddingOn,
      disable: this.viewButton.disable,
      onPressed: () => {
        this._onViewHandler(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({
        data: this.item.requestId,
        style: new ui.TextStyle({ fontSize: 16, color: cStyle.c20 }),
        className: "xad12 hc",
      }),
      onEnter: (event) => {
        this.viewButtonOnEnter(event);
      },
      onExit: (event) => {
        this.viewButtonOnExit(event);
      },
      className: "xb5e hc",
    });
  }
}
function ViewButtonWithState(props: ViewButtonWithStateProps) {
  return React.createElement(_ViewButtonWithState, props);
}

class _ItemState {
  parent: RentalPendingRequestsViewRefs;
  item: RentalRequest;
  viewButton: ViewButtonState = new ViewButtonState();
  public constructor(parent, item) {
    this.parent = parent;

    this.item = item;
  }
}

class _RentalPendingRequestsViewState extends ObservableComponent<RentalPendingRequestsViewProps> {
  static defaultProps = { renter: null };
  d3eState: RentalPendingRequestsViewRefs = new RentalPendingRequestsViewRefs();
  submititedList: Array<RentalRequest> = ListWrapper.widget(
    this,
    "submititedList"
  );
  request: RentalRequest = null;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RentalPendingRequestsViewProps) {
    super(props);

    this.initState();
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["renter"], this.computeSubmititedList);

    this.computeSubmititedList();

    this.updateSyncProperty("renter", this.props.renter);

    this.on(
      [
        "submititedList",
        "submititedList.eventDate",
        "submititedList.eventName",
        "submititedList.facility",
        "submititedList.facility.name",
        "submititedList.status",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RentalPendingRequestsViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public setSubmititedList(val: Array<RentalRequest>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.submititedList,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("submititedList", this.submititedList, val);

    this.submititedList.clear();

    this.submititedList.addAll(val);

    this.fire("submititedList", this);
  }
  public addToSubmititedList(val: RentalRequest, index: number = -1): void {
    if (index === -1) {
      if (!this.submititedList.contains(val)) this.submititedList.add(val);
    } else {
      this.submititedList.remove(this.submititedList.elementAt(index));

      this.submititedList.add(val);
    }

    this.fire("submititedList", this, val, true);

    this.updateObservable("submititedList", null, val);
  }
  public removeFromSubmititedList(val: RentalRequest): void {
    this.submititedList.remove(val);

    this.fire("submititedList", this, val, false);

    this.removeObservable("submititedList", val);
  }
  public computeSubmititedList = async (): Promise<void> => {
    try {
      this.setSubmititedList(
        Array.from(
          (
            await Query.get().getPendingRentalRequests(
              UsageConstants.QUERY_GETPENDINGRENTALREQUESTS_RENTALPENDINGREQUESTSVIEW_PROPERTIES_SUBMITITEDLIST_COMPUTATION,
              new PendingRentalRequestsRequest({ renter: this.renter })
            )
          )?.items ?? []
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeSubmititedList : " + exception.toString()
      );

      this.setSubmititedList([]);
    }
  };
  public setRequest(val: RentalRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.center,
      children: [
        ui.Row({
          children: [
            TextView({
              data: "Pending Requests",
              style: new ui.TextStyle({ fontWeight: ui.FontWeight.w600 }),
              className: "x787",
              key: "0",
            }),
          ],
          className: "xc45 hc h",
          key: "0",
        }),
        this.submititedList.isNotEmpty
          ? ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.Table({
                  defaultColumnWidth: new ui.FlexColumnWidth(1),
                  columnWidths: { 0: new ui.FlexColumnWidth(1) },
                  children: [
                    ui.TableRow({
                      children: [
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: "Res. ID",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "xa78 hc",
                          }),
                          className: "hc",
                          key: "0",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: "Event Name",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "x754 hc",
                          }),
                          className: "hc",
                          key: "1",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: "Facility Name",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "x80e hc",
                          }),
                          className: "hc",
                          key: "2",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: "Event Date",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "xed5 hc",
                          }),
                          className: "hc",
                          key: "3",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: "Status",
                            style: new ui.TextStyle({
                              fontFamily:
                                cStyle.tTextViewSubHeader1FontFamilyOn,
                              color: cStyle.tTextViewSubHeader1ColorOn,
                              fontWeight:
                                cStyle.tTextViewSubHeader1FontWeightOn,
                            }),
                            className: "x647 hc",
                          }),
                          className: "hc",
                          key: "4",
                        }),
                      ],
                      className: "xb76 hc",
                      key: "0",
                    }),
                    this.submititedList.expand((item) => [
                      ui.TableRow({
                        children: [
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: ViewButtonWithState({
                              d3eState: this.d3eState.forItem(item),
                              _onViewHandler: this.onViewHandler,
                              item: item,
                            }),
                            className: "hc",
                            key: "0",
                          }),
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: TextView({
                              data: item.eventName,
                              softWrap: true,
                              style: new ui.TextStyle({
                                fontFamily:
                                  cStyle.tTextViewTableHeaderFontFamilyOn,
                                color: cStyle.tTextViewTableHeaderColorOn,
                                fontWeight:
                                  cStyle.tTextViewTableHeaderFontWeightOn,
                              }),
                              className: "hc",
                            }),
                            className: "hc",
                            key: "1",
                          }),
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: TextView({
                              data: item.facility.name,
                              style: new ui.TextStyle({
                                fontFamily:
                                  cStyle.tTextViewTableHeaderFontFamilyOn,
                                color: cStyle.tTextViewTableHeaderColorOn,
                                fontWeight:
                                  cStyle.tTextViewTableHeaderFontWeightOn,
                              }),
                              className: "hc",
                            }),
                            className: "hc",
                            key: "2",
                          }),
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: DateTimeView({
                              value: item.eventDate.toDateTime(),
                              format: "MMM dd, yyyy",
                              className: "hc",
                            }),
                            className: "hc",
                            key: "3",
                          }),
                          ui.TableCell({
                            verticalAlignment:
                              ui.TableCellVerticalAlignment.middle,
                            child: TextView({
                              data: item.status.name,
                              states: ui.joinStates(
                                {
                                  "data-c0":
                                    item.status === RequestStatus.Submitted,
                                  "data-c1":
                                    item.status === RequestStatus.Approved ||
                                    item.status ===
                                      RequestStatus.SchoolApproved,
                                  "data-c2":
                                    item.status === RequestStatus.Rejected ||
                                    item.status ===
                                      RequestStatus.SchoolRejected,
                                },
                                {}
                              ),
                              style: new ui.TextStyle({
                                fontFamily:
                                  cStyle.tTextViewTableHeaderFontFamilyOn,
                                color:
                                  item.status === RequestStatus.Submitted
                                    ? new ui.Color(0xffdec150)
                                    : item.status === RequestStatus.Approved ||
                                      item.status ===
                                        RequestStatus.SchoolApproved
                                    ? new ui.Color(0xff008000)
                                    : item.status === RequestStatus.Rejected ||
                                      item.status ===
                                        RequestStatus.SchoolRejected
                                    ? new ui.Color(0xfff71306)
                                    : cStyle.tTextViewTableHeaderColorOn,
                                fontWeight:
                                  cStyle.tTextViewTableHeaderFontWeightOn,
                              }),
                              className: "x4dc hc",
                            }),
                            className: "hc",
                            key: "4",
                          }),
                        ],
                        className: "xd4fe hc",
                        key: item?.ident,
                      }),
                    ]),
                  ],
                  className: "xd11 hc h",
                  key: "0",
                }),
              ],
              className: "xbac hc h",
            })
          : ui.Column({
              children: [
                TextView({
                  data: "No Pending Requests",
                  className: "hc",
                  key: "0",
                }),
              ],
              className: "hc",
            }),
      ],
      className: ui.join(
        this.props.className,
        "RentalPendingRequestsView xc94 hc h"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onViewHandler = (d3eState: _ItemState): void => {
    // TODO: add logic to navigate to the request overview page

    this.setRequest(d3eState.item);

    this.navigator.pushRequestOverViewPage({
      admin: null,
      renter: this.renter,
      requestObj: this.request,
      target: "rRouter",
      replace: false,
    });
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function RentalPendingRequestsView(
  props: RentalPendingRequestsViewProps
) {
  return React.createElement(_RentalPendingRequestsViewState, {
    ..._RentalPendingRequestsViewState.defaultProps,
    ...props,
  });
}
