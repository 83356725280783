import React, { ReactNode } from "react";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import GoogleMapController from "../classes/GoogleMapController";
import GoogleMapReact from "google-map-react";
import Env from "../classes/Env";

interface GoogleMapProps extends BaseUIProps {
  zoom?: number;
  centerLat?: number;
  centerLng?: number;
  controller?: GoogleMapController;
  children?: ReactNode[];
}
class _GoogleMapState extends React.Component<GoogleMapProps, {}> {
  static defaultProps = {
    controller: new GoogleMapController(),
    centerLat: 40.652846,
    centerLng: -112.014536,
    zoom: 13,
  };

  private ref: HTMLInputElement;
  private _controller: GoogleMapController;

  constructor(props: GoogleMapProps) {
    super(props);

    this.initState();
  }

  public initState() {
    this.initListeners();
  }

  public initListeners(): void {}
  get controller() {
    return this._controller;
  }

  public render(): ReactNode {
    return React.createElement(
      GoogleMapReact,
      {
        bootstrapURLKeys: { key: Env.get().googleMapApiKey },
        defaultCenter: {
          lat: this.props.centerLat,
          lng: this.props.centerLng,
        },
        defaultZoom: this.props.zoom,
      },
      this.props.children
    );
  }
}

export default function GoogleMap(props: GoogleMapProps) {
  let style = {};
  style["width"] = "inherit";
  style["height"] = "inherit";
  // wrap in div element to avoid error
  return React.createElement(
    "div",
    {
      class: props.className ? " " + props.className : "",
      style: style,
      ...copyBaseUIProps(props),
    },
    React.createElement(_GoogleMapState, {
      ..._GoogleMapState.defaultProps,
    })
  );
}
