import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import Device from "../utils/Device";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import PageNavigator from "../classes/PageNavigator";
import DFile from "../classes/DFile";
import Renter from "../models/Renter";
import IconButton from "./IconButton";
import IconView from "./IconView";
import Query from "../classes/Query";

type _RenterProfileViewOnCancel = () => void;

export interface RenterProfileViewProps extends BaseUIProps {
  key?: string;
  renter: Renter;
  profileSize?: number;
  iconColor?: ui.Color;
  textColor?: ui.Color;
  cardBgColor?: ui.Color;
  onCancel?: _RenterProfileViewOnCancel;
}

class _RenterProfileViewState extends ObservableComponent<RenterProfileViewProps> {
  static defaultProps = {
    renter: null,
    profileSize: 0.0,
    iconColor: null,
    textColor: null,
    cardBgColor: null,
    onCancel: null,
  };
  profile: DFile = null;
  public constructor(props: RenterProfileViewProps) {
    super(props);

    this.initState();
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public get profileSize(): number {
    return this.props.profileSize;
  }
  public get iconColor(): ui.Color {
    return this.props.iconColor;
  }
  public get textColor(): ui.Color {
    return this.props.textColor;
  }
  public get cardBgColor(): ui.Color {
    return this.props.cardBgColor;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("renter", this.props.renter);

    this.on(
      [
        "cardBgColor",
        "iconColor",
        "profile",
        "profileSize",
        "renter",
        "renter.email",
        "renter.fullName",
        "textColor",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RenterProfileViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }

    if (prevProps.profileSize !== this.props.profileSize) {
      this.fire("profileSize", this);
    }

    if (prevProps.iconColor !== this.props.iconColor) {
      this.fire("iconColor", this);
    }

    if (prevProps.textColor !== this.props.textColor) {
      this.fire("textColor", this);
    }

    if (prevProps.cardBgColor !== this.props.cardBgColor) {
      this.fire("cardBgColor", this);
    }
  }
  public setProfile(val: DFile): void {
    let isValChanged: boolean = this.profile !== val;

    if (!isValChanged) {
      return;
    }

    this.profile = val;

    this.fire("profile", this);
  }
  public render(): ReactNode {
    return ui.Container({
      decoration: new ui.BoxDecoration({ color: this.cardBgColor }),
      child: ui.Row({
        mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
        crossAxisAlignment: ui.CrossAxisAlignment.center,
        children: [
          ui.Row({
            mainAxisAlignment: ui.MainAxisAlignment.center,
            crossAxisAlignment: ui.CrossAxisAlignment.center,
            children: [
              ui.Container({
                alignment: ui.Alignment.center,
                decoration: new ui.BoxDecoration({
                  borderRadius: ui.BorderRadius.circular(30.0),
                  border: ui.Border.all({
                    color:
                      this.textColor !== null
                        ? this.textColor
                        : ui.HexColor.fromHexInt(0xff000000),
                    width: 2,
                  }),
                }),
                child: IconView({
                  icon: MaterialIcons.person,
                  size: 20,
                  color:
                    this.iconColor !== null
                      ? this.iconColor
                      : ui.HexColor.fromHexInt(0xff000000),
                  className: "x925 hc vc",
                }),
                className: "x52b hc vc",
                key: "0",
              }),
              ui.Column({
                mainAxisAlignment: ui.MainAxisAlignment.start,
                crossAxisAlignment: ui.CrossAxisAlignment.start,
                children: [
                  TextView({
                    data: this.renter?.fullName ?? "",
                    softWrap: true,
                    style: new ui.TextStyle({
                      fontWeight: ui.FontWeight.w600,
                      color:
                        this.textColor !== null
                          ? this.textColor
                          : ui.HexColor.fromHexInt(0xff000000),
                      fontFamily: "Open Sans",
                      fontSize: 16,
                    }),
                    className: "xcf1",
                    key: "0",
                  }),
                  TextView({
                    data: this.renter?.email ?? "",
                    softWrap: true,
                    style: new ui.TextStyle({
                      fontSize:
                        this.profile !== null && this.profileSize > 0.0
                          ? this.profileSize / 4
                          : 10.0,
                      color:
                        this.textColor !== null
                          ? this.textColor
                          : ui.HexColor.fromHexInt(0xff000000),
                      fontFamily: "Open Sans",
                    }),
                    key: "1",
                  }),
                ],
                className: "x92c",
                key: "1",
              }),
            ],
            key: "0",
          }),
          ui.Container({
            margin: ui.EdgeInsets.fromLTRB(5.0, 0.0, 0.0, 0.0, new Map()),
            child: IconButton({
              icon: MaterialIcons.power_settings_new,
              tooltip: "Log out",
              color:
                this.iconColor !== null
                  ? this.iconColor
                  : ui.HexColor.fromHexInt(0xffffffff),
              size: 25,
              onPressed: () => {
                this.logoutButtonHandler();
              },
            }),
            key: "1",
            className: "x535",
          }),
        ],
      }),
      className: ui.join(this.props.className, "RenterProfileView x135 hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public logoutButtonHandler = async (): Promise<void> => {
    Device.put("email", null);

    Device.put("password", null);

    let result: boolean = await Query.get().logout();

    this.navigator.pushMainHomePage();
  };
  public get onCancel(): _RenterProfileViewOnCancel {
    return this.props.onCancel;
  }
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function RenterProfileView(props: RenterProfileViewProps) {
  return React.createElement(_RenterProfileViewState, {
    ..._RenterProfileViewState.defaultProps,
    ...props,
  });
}
