import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import ResultStatus from "../classes/ResultStatus";
import RentalRequest from "../models/RentalRequest";
import Popup from "./Popup";
import Renter from "../models/Renter";
import Admin from "../models/Admin";
import Result from "../classes/Result";
import Button from "./Button";
import RequestDocument from "../models/RequestDocument";
import TextView from "./TextView";
import FailureMessage from "../classes/FailureMessage";
import RequestStatusUpdate from "../models/RequestStatusUpdate";
import AddOrEditDocumentView from "./AddOrEditDocumentView";
import PaymentDetailsCardView from "./PaymentDetailsCardView";
import RequestChangesPopup from "./RequestChangesPopup";
import SuccessMessage from "../classes/SuccessMessage";
import EventBus from "../utils/EventBus";
import Message from "../models/Message";
import RequestInsurance from "../models/RequestInsurance";
import StatusCardView from "./StatusCardView";
import RequestStatus from "../classes/RequestStatus";
import DateFormat from "../classes/DateFormat";
import AdminRole from "../classes/AdminRole";
import ReservationView from "./ReservationView";
import CommentHistoryView from "./CommentHistoryView";
import AddorEditInsuranceView from "./AddorEditInsuranceView";
import MessageType from "../classes/MessageType";
import { BuildContext } from "../classes/BuildContext";

type _ApproveButtonOnPressed = (d3eState: RequestOverViewPageRefs) => void;

type _RejectButtonOnPressed = (d3eState: RequestOverViewPageRefs) => void;

type _RequestChangesButtonOnPressed = (
  d3eState: RequestOverViewPageRefs
) => void;

type _DocumentUploadButtonOnPressed = (
  d3eState: RequestOverViewPageRefs
) => void;

type _InsuranceUploadButtonOnPressed = (
  d3eState: RequestOverViewPageRefs
) => void;

type _CommentButtonOnPressed = (d3eState: RequestOverViewPageRefs) => void;

type _DocRefOnChanged = (
  text: string,
  d3eState: RequestOverViewPageRefs
) => void;

export interface RequestOverViewPageProps extends BaseUIProps {
  key?: string;
  requestObj: RentalRequest;
  admin: Admin;
  renter: Renter;
}
/// To store state data for RequestOverViewPage
class RequestOverViewPageRefs {
  public approveButton: ApproveButtonState = new ApproveButtonState();
  public commentButton: CommentButtonState = new CommentButtonState();
  docRefController: ui.TextEditingController = new ui.TextEditingController();
  docRefFocusNode: ui.FocusNode = new ui.FocusNode();
  public documentUploadButton: DocumentUploadButtonState =
    new DocumentUploadButtonState();
  public insuranceUploadButton: InsuranceUploadButtonState =
    new InsuranceUploadButtonState();
  public rejectButton: RejectButtonState = new RejectButtonState();
  public requestChangesButton: RequestChangesButtonState =
    new RequestChangesButtonState();
}

interface CommentButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RequestOverViewPageRefs;
  _onCommentHandler?: _CommentButtonOnPressed;
}

class CommentButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _CommentButtonWithState extends ObservableComponent<CommentButtonWithStateProps> {
  commentButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CommentButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get commentButton(): CommentButtonState {
    return this.props.d3eState.commentButton;
  }
  public get d3eState(): RequestOverViewPageRefs {
    return this.props.d3eState;
  }
  public get _onCommentHandler(): _CommentButtonOnPressed {
    return this.props._onCommentHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("commentButton", null, this.commentButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["commentButton", "commentButton.", "commentButton.hover"],
      this.rebuild
    );
  }
  public commentButtonOnEnter(event): void {
    return this.commentButton.setHover(true);
  }
  public commentButtonOnExit(event): void {
    return this.commentButton.setHover(false);
  }
  public dispose(): void {
    this.commentButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: 100,
      child: Button({
        padding: this.commentButton.hover
          ? cStyle.tButtonPrimaryNewPaddingOnHover
          : cStyle.tButtonPrimaryNewPaddingOn,
        decoration: this.commentButton.hover
          ? cStyle.tButtonPrimaryNewDecorationOnHover
          : cStyle.tButtonPrimaryNewDecorationOn,
        disable: this.commentButton.disable,
        onPressed: () => {
          this._onCommentHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Comment" }),
        onEnter: (event) => {
          this.commentButtonOnEnter(event);
        },
        onExit: (event) => {
          this.commentButtonOnExit(event);
        },
      }),
      className: "xe68 hc",
    });
  }
}
function CommentButtonWithState(props: CommentButtonWithStateProps) {
  return React.createElement(_CommentButtonWithState, props);
}

interface InsuranceUploadButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RequestOverViewPageRefs;
  _onPressedInsuranceUploadButtonHandler?: _InsuranceUploadButtonOnPressed;
}

class InsuranceUploadButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _InsuranceUploadButtonWithState extends ObservableComponent<InsuranceUploadButtonWithStateProps> {
  insuranceUploadButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: InsuranceUploadButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get insuranceUploadButton(): InsuranceUploadButtonState {
    return this.props.d3eState.insuranceUploadButton;
  }
  public get d3eState(): RequestOverViewPageRefs {
    return this.props.d3eState;
  }
  public get _onPressedInsuranceUploadButtonHandler(): _InsuranceUploadButtonOnPressed {
    return this.props._onPressedInsuranceUploadButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable(
      "insuranceUploadButton",
      null,
      this.insuranceUploadButton
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "insuranceUploadButton",
        "insuranceUploadButton.",
        "insuranceUploadButton.hover",
      ],
      this.rebuild
    );
  }
  public insuranceUploadButtonOnEnter(event): void {
    return this.insuranceUploadButton.setHover(true);
  }
  public insuranceUploadButtonOnExit(event): void {
    return this.insuranceUploadButton.setHover(false);
  }
  public dispose(): void {
    this.insuranceUploadButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 10.0, 0.0, 0.0, new Map()),
      width: 200,
      child: Button({
        padding: this.insuranceUploadButton.hover
          ? cStyle.tButtonPrimaryNewPaddingOnHover
          : cStyle.tButtonPrimaryNewPaddingOn,
        decoration: this.insuranceUploadButton.hover
          ? cStyle.tButtonPrimaryNewDecorationOnHover
          : cStyle.tButtonPrimaryNewDecorationOn,
        disable: this.insuranceUploadButton.disable,
        onPressed: () => {
          this._onPressedInsuranceUploadButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "+ Add Insurance" }),
        onEnter: (event) => {
          this.insuranceUploadButtonOnEnter(event);
        },
        onExit: (event) => {
          this.insuranceUploadButtonOnExit(event);
        },
      }),
      className: "x6228 hc",
    });
  }
}
function InsuranceUploadButtonWithState(
  props: InsuranceUploadButtonWithStateProps
) {
  return React.createElement(_InsuranceUploadButtonWithState, props);
}

interface DocumentUploadButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RequestOverViewPageRefs;
  _onPressedDocumentUploadButtonHandler?: _DocumentUploadButtonOnPressed;
}

class DocumentUploadButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _DocumentUploadButtonWithState extends ObservableComponent<DocumentUploadButtonWithStateProps> {
  documentUploadButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: DocumentUploadButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get documentUploadButton(): DocumentUploadButtonState {
    return this.props.d3eState.documentUploadButton;
  }
  public get d3eState(): RequestOverViewPageRefs {
    return this.props.d3eState;
  }
  public get _onPressedDocumentUploadButtonHandler(): _DocumentUploadButtonOnPressed {
    return this.props._onPressedDocumentUploadButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable(
      "documentUploadButton",
      null,
      this.documentUploadButton
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "documentUploadButton",
        "documentUploadButton.",
        "documentUploadButton.hover",
      ],
      this.rebuild
    );
  }
  public documentUploadButtonOnEnter(event): void {
    return this.documentUploadButton.setHover(true);
  }
  public documentUploadButtonOnExit(event): void {
    return this.documentUploadButton.setHover(false);
  }
  public dispose(): void {
    this.documentUploadButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 10.0, 0.0, 0.0, new Map()),
      width: 200,
      child: Button({
        padding: this.documentUploadButton.hover
          ? cStyle.tButtonPrimaryNewPaddingOnHover
          : cStyle.tButtonPrimaryNewPaddingOn,
        decoration: this.documentUploadButton.hover
          ? cStyle.tButtonPrimaryNewDecorationOnHover
          : cStyle.tButtonPrimaryNewDecorationOn,
        disable: this.documentUploadButton.disable,
        onPressed: () => {
          this._onPressedDocumentUploadButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "+ Add Document" }),
        onEnter: (event) => {
          this.documentUploadButtonOnEnter(event);
        },
        onExit: (event) => {
          this.documentUploadButtonOnExit(event);
        },
      }),
      className: "xc460 hc",
    });
  }
}
function DocumentUploadButtonWithState(
  props: DocumentUploadButtonWithStateProps
) {
  return React.createElement(_DocumentUploadButtonWithState, props);
}

interface RequestChangesButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RequestOverViewPageRefs;
  _onRequestChangesHandler?: _RequestChangesButtonOnPressed;
  requestObj: RentalRequest;
}

class RequestChangesButtonState extends ObjectObservable {
  public _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _RequestChangesButtonWithState extends ObservableComponent<RequestChangesButtonWithStateProps> {
  requestChangesButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RequestChangesButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get requestChangesButton(): RequestChangesButtonState {
    return this.props.d3eState.requestChangesButton;
  }
  public get requestObj(): RentalRequest {
    return this.props.requestObj;
  }
  public get d3eState(): RequestOverViewPageRefs {
    return this.props.d3eState;
  }
  public get _onRequestChangesHandler(): _RequestChangesButtonOnPressed {
    return this.props._onRequestChangesHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable(
      "requestChangesButton",
      null,
      this.requestChangesButton
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("requestObj", this.props.requestObj);

    this.on(
      [
        "requestChangesButton",
        "requestChangesButton.",
        "requestChangesButton.hover",
        "requestObj",
        "requestObj.requestChanges",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(
    prevProps: RequestChangesButtonWithStateProps
  ): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.requestObj !== this.props.requestObj) {
      this.updateObservable(
        "requestObj",
        prevProps.requestObj,
        this.props.requestObj
      );

      this.fire("requestObj", this);
    }
  }
  public requestChangesButtonOnEnter(event): void {
    return this.requestChangesButton.setHover(true);
  }
  public requestChangesButtonOnExit(event): void {
    return this.requestChangesButton.setHover(false);
  }
  public dispose(): void {
    this.requestChangesButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 10.0, 0.0, 0.0, new Map()),
      width: 200,
      child: Button({
        disable: this.requestObj.requestChanges,
        padding: this.requestChangesButton.hover
          ? cStyle.tButtonPrimaryNewPaddingOnHover
          : cStyle.tButtonPrimaryNewPaddingOn,
        decoration: this.requestChangesButton.hover
          ? cStyle.tButtonPrimaryNewDecorationOnHover
          : cStyle.tButtonPrimaryNewDecorationOn,
        onPressed: () => {
          this._onRequestChangesHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({
          data: "Request Changes",
          states: ui.joinStates(
            { "data-c0": this.requestObj.requestChanges },
            {}
          ),
          style: new ui.TextStyle({
            color: this.requestObj.requestChanges
              ? new ui.Color(0xff000000)
              : null,
          }),
          className: "x223",
        }),
        onEnter: (event) => {
          this.requestChangesButtonOnEnter(event);
        },
        onExit: (event) => {
          this.requestChangesButtonOnExit(event);
        },
      }),
      className: "xcde hc",
    });
  }
}
function RequestChangesButtonWithState(
  props: RequestChangesButtonWithStateProps
) {
  return React.createElement(_RequestChangesButtonWithState, props);
}

interface RejectButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RequestOverViewPageRefs;
  _onActionUpdateHandler1?: _RejectButtonOnPressed;
  isActionEnabled: boolean;
}

class RejectButtonState extends ObjectObservable {
  public _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _RejectButtonWithState extends ObservableComponent<RejectButtonWithStateProps> {
  rejectButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RejectButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get isActionEnabled(): boolean {
    return this.props.isActionEnabled;
  }
  public get rejectButton(): RejectButtonState {
    return this.props.d3eState.rejectButton;
  }
  public get d3eState(): RequestOverViewPageRefs {
    return this.props.d3eState;
  }
  public get _onActionUpdateHandler1(): _RejectButtonOnPressed {
    return this.props._onActionUpdateHandler1;
  }
  public initState() {
    super.initState();

    this.updateObservable("rejectButton", null, this.rejectButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "isActionEnabled",
        "rejectButton",
        "rejectButton.",
        "rejectButton.hover",
      ],
      this.rebuild
    );
  }
  public rejectButtonOnEnter(event): void {
    return this.rejectButton.setHover(true);
  }
  public rejectButtonOnExit(event): void {
    return this.rejectButton.setHover(false);
  }
  public dispose(): void {
    this.rejectButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 10.0, 0.0, 0.0, new Map()),
      width: 100,
      child: Button({
        disable: !this.isActionEnabled,
        padding: this.rejectButton.hover
          ? cStyle.tButtonPrimaryNewPaddingOnHover
          : cStyle.tButtonPrimaryNewPaddingOn,
        decoration: this.rejectButton.hover
          ? cStyle.tButtonPrimaryNewDecorationOnHover
          : cStyle.tButtonPrimaryNewDecorationOn,
        onPressed: () => {
          this._onActionUpdateHandler1(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({
          data: "Reject",
          states: ui.joinStates({ "data-c0": !this.isActionEnabled }, {}),
          style: new ui.TextStyle({
            color: !this.isActionEnabled
              ? new ui.Color(0xff000000)
              : new ui.Color(0xffffffff),
          }),
          className: "x7d8",
        }),
        onEnter: (event) => {
          this.rejectButtonOnEnter(event);
        },
        onExit: (event) => {
          this.rejectButtonOnExit(event);
        },
      }),
      className: "xec44 hc",
    });
  }
}
function RejectButtonWithState(props: RejectButtonWithStateProps) {
  return React.createElement(_RejectButtonWithState, props);
}

interface ApproveButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RequestOverViewPageRefs;
  _onActionUpdateHandler?: _ApproveButtonOnPressed;
  isActionEnabled: boolean;
}

class ApproveButtonState extends ObjectObservable {
  public _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ApproveButtonWithState extends ObservableComponent<ApproveButtonWithStateProps> {
  approveButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ApproveButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get approveButton(): ApproveButtonState {
    return this.props.d3eState.approveButton;
  }
  public get isActionEnabled(): boolean {
    return this.props.isActionEnabled;
  }
  public get d3eState(): RequestOverViewPageRefs {
    return this.props.d3eState;
  }
  public get _onActionUpdateHandler(): _ApproveButtonOnPressed {
    return this.props._onActionUpdateHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("approveButton", null, this.approveButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "approveButton",
        "approveButton.",
        "approveButton.hover",
        "isActionEnabled",
      ],
      this.rebuild
    );
  }
  public approveButtonOnEnter(event): void {
    return this.approveButton.setHover(true);
  }
  public approveButtonOnExit(event): void {
    return this.approveButton.setHover(false);
  }
  public dispose(): void {
    this.approveButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 10.0, 10.0, 0.0, new Map()),
      width: 100,
      child: Button({
        disable: !this.isActionEnabled,
        padding: this.approveButton.hover
          ? cStyle.tButtonPrimaryNewPaddingOnHover
          : cStyle.tButtonPrimaryNewPaddingOn,
        decoration: this.approveButton.hover
          ? cStyle.tButtonPrimaryNewDecorationOnHover
          : cStyle.tButtonPrimaryNewDecorationOn,
        onPressed: () => {
          this._onActionUpdateHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({
          data: "Approve",
          states: ui.joinStates({ "data-c0": !this.isActionEnabled }, {}),
          style: new ui.TextStyle({
            color: !this.isActionEnabled ? new ui.Color(0xff000000) : null,
          }),
          className: "x0e7",
        }),
        onEnter: (event) => {
          this.approveButtonOnEnter(event);
        },
        onExit: (event) => {
          this.approveButtonOnExit(event);
        },
      }),
      className: "x024 hc",
    });
  }
}
function ApproveButtonWithState(props: ApproveButtonWithStateProps) {
  return React.createElement(_ApproveButtonWithState, props);
}

class _RequestOverViewPageState extends ObservableComponent<RequestOverViewPageProps> {
  static defaultProps = { requestObj: null, admin: null, renter: null };
  d3eState: RequestOverViewPageRefs = new RequestOverViewPageRefs();
  comment: string = "";
  recentDocument: RequestDocument = null;
  recentInsurance: RequestInsurance = null;
  isActionEnabled: boolean = false;
  addRequestPopupPopup: Popup;
  documentPopupPopup: Popup;
  insurancePopupPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RequestOverViewPageProps) {
    super(props);

    this.initState();
  }
  public get requestObj(): RentalRequest {
    return this.props.requestObj;
  }
  public get admin(): Admin {
    return this.props.admin;
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("requestObj", this.props.requestObj);

    this.updateSyncProperty("admin", this.props.admin);

    this.updateSyncProperty("renter", this.props.renter);

    this.on(
      ["admin", "admin.role", "requestObj", "requestObj.status"],
      this.computeIsActionEnabled
    );

    this.computeIsActionEnabled();

    this.on(
      [
        "admin",
        "comment",
        "isActionEnabled",
        "renter",
        "requestObj",
        "requestObj.createdDate",
        "requestObj.requestChanges",
        "requestObj.requestId",
        "requestObj.status",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RequestOverViewPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.requestObj !== this.props.requestObj) {
      this.updateObservable(
        "requestObj",
        prevProps.requestObj,
        this.props.requestObj
      );

      this.fire("requestObj", this);
    }

    if (prevProps.admin !== this.props.admin) {
      this.updateObservable("admin", prevProps.admin, this.props.admin);

      this.fire("admin", this);
    }

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public setComment(val: string): void {
    let isValChanged: boolean = this.comment !== val;

    if (!isValChanged) {
      return;
    }

    this.comment = val;

    this.fire("comment", this);
  }
  public setRecentDocument(val: RequestDocument): void {
    let isValChanged: boolean = this.recentDocument !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("recentDocument", this.recentDocument, val);

    this.recentDocument = val;

    this.fire("recentDocument", this);
  }
  public setRecentInsurance(val: RequestInsurance): void {
    let isValChanged: boolean = this.recentInsurance !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("recentInsurance", this.recentInsurance, val);

    this.recentInsurance = val;

    this.fire("recentInsurance", this);
  }
  public setIsActionEnabled(val: boolean): void {
    let isValChanged: boolean = this.isActionEnabled !== val;

    if (!isValChanged) {
      return;
    }

    this.isActionEnabled = val;

    this.fire("isActionEnabled", this);
  }
  public computeIsActionEnabled = (): void => {
    try {
      this.setIsActionEnabled(
        this.admin === null
          ? false
          : this.admin.role === AdminRole.Admin
          ? this.requestObj.status === RequestStatus.SchoolApproved
          : this.requestObj.status === RequestStatus.Submitted
      );
    } catch (exception) {
      console.log(
        " exception in computeIsActionEnabled : " + exception.toString()
      );

      this.setIsActionEnabled(false);
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Wrap({
          runSpacing: 10,
          spacing: 10,
          children: [
            StatusCardView({
              title:
                new DateFormat("MMM dd, yyyy").format(
                  this.requestObj.createdDate
                ) +
                " - " +
                this.requestObj.requestId,
              bgColor: new ui.Color(0xffffffff),
              headerBg:
                this.requestObj.status === RequestStatus.Rejected ||
                this.requestObj.status === RequestStatus.SchoolRejected
                  ? new ui.Color(0xfff71306)
                  : this.requestObj.status === RequestStatus.Approved ||
                    this.requestObj.status === RequestStatus.SchoolApproved
                  ? new ui.Color(0xff008000)
                  : new ui.Color(0xffdec150),
              child: ReservationView({
                request: this.requestObj,
                user: this.admin,
                className: "xadae hc vc h v",
              }),
              trailing: TextView({
                data: this.requestObj.status.name,
                style: new ui.TextStyle({ color: new ui.Color(0xffffffff) }),
                className: "xd39",
              }),
              className: "hc",
              key: "0",
            }),
            StatusCardView({
              title: "Actions",
              bgColor: new ui.Color(0xffffffff),
              headerBg: new ui.Color(0xff4076b9),
              child: ui.Column({
                crossAxisAlignment: ui.CrossAxisAlignment.start,
                children: [
                  this.admin !== null
                    ? ui.Row({
                        mainAxisAlignment: ui.MainAxisAlignment.start,
                        children: [
                          ApproveButtonWithState({
                            d3eState: this.d3eState,
                            _onActionUpdateHandler: this.onActionUpdateHandler,
                            isActionEnabled: this.isActionEnabled,
                            key: "0",
                          }),
                          RejectButtonWithState({
                            d3eState: this.d3eState,
                            _onActionUpdateHandler1:
                              this.onActionUpdateHandler1,
                            isActionEnabled: this.isActionEnabled,
                            key: "1",
                          }),
                        ],
                        className: "x16c0 hc h",
                      })
                    : [],
                  this.renter !== null
                    ? ui.Row({
                        mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                        children: [
                          ui.Column({
                            crossAxisAlignment: ui.CrossAxisAlignment.start,
                            mainAxisAlignment: ui.MainAxisAlignment.start,
                            children: [
                              RequestChangesButtonWithState({
                                d3eState: this.d3eState,
                                _onRequestChangesHandler:
                                  this.onRequestChangesHandler,
                                requestObj: this.requestObj,
                                key: "0",
                              }),
                              this.requestObj.requestChanges
                                ? TextView({ data: "Changes Requested" })
                                : [],
                            ],
                            key: "0",
                          }),
                          DocumentUploadButtonWithState({
                            d3eState: this.d3eState,
                            _onPressedDocumentUploadButtonHandler:
                              this.onPressedDocumentUploadButtonHandler,
                            key: "1",
                          }),
                          InsuranceUploadButtonWithState({
                            d3eState: this.d3eState,
                            _onPressedInsuranceUploadButtonHandler:
                              this.onPressedInsuranceUploadButtonHandler,
                            key: "2",
                          }),
                        ],
                        className: "x49e hc h",
                      })
                    : [],
                  ui.InputField({
                    dense: true,
                    value: this.comment,
                    placeHolder: "Enter Comment",
                    maxLines: 5,
                    keyboardType: ui.TextInputType.multiline,
                    inActiveColor: new ui.Color(0xffdcdcdc),
                    activeColor: new ui.Color(0xff6c10ec),
                    controller: this.d3eState.docRefController,
                    onChanged: (text) => {
                      this.docRefonChanged(text, this.d3eState);
                    },
                    onFocusChange: (val) => {},
                    focusNode: this.d3eState.docRefFocusNode,
                    className: "xb6c hc h",
                    key: "2",
                  }),
                  ui.Row({
                    crossAxisAlignment: ui.CrossAxisAlignment.start,
                    mainAxisAlignment: ui.MainAxisAlignment.end,
                    children: [
                      CommentButtonWithState({
                        d3eState: this.d3eState,
                        _onCommentHandler: this.onCommentHandler,
                        key: "0",
                      }),
                    ],
                    className: "x55d hc h",
                    key: "3",
                  }),
                ],
                className: "x5bb hc vc h v",
              }),
              className: "hc",
              key: "1",
            }),
            StatusCardView({
              title: "Payment Details",
              bgColor: new ui.Color(0xffffffff),
              headerBg: new ui.Color(0xff4076b9),
              child: PaymentDetailsCardView({
                requestObj: this.requestObj,
                className: "xdca hc vc h v",
              }),
              className: "hc",
              key: "2",
            }),
            StatusCardView({
              title: "Comments/History",
              bgColor: new ui.Color(0xffffffff),
              headerBg: new ui.Color(0xff4076b9),
              child: CommentHistoryView({
                rentalRequest: this.requestObj,
                className: "x384c hc vc h v",
              }),
              className: "hc",
              key: "3",
            }),
          ],
          className: "hc",
          key: "0",
        }),
      ],
      className: ui.join(
        this.props.className,
        "RequestOverViewPage x7bb hc vc"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onActionUpdateHandler = async (
    d3eState: RequestOverViewPageRefs
  ): Promise<void> => {
    let request: RequestStatusUpdate = new RequestStatusUpdate({
      rentalRequest: this.requestObj,
      status:
        this.admin.role === AdminRole.Admin
          ? RequestStatus.Approved
          : RequestStatus.SchoolApproved,
    });

    let result: Result<RequestStatusUpdate> = await request.save();

    if (result.status === ResultStatus.Success) {
      EventBus.get().fire(
        new SuccessMessage({ message: " Request Updated Successfully" })
      );
    } else {
      EventBus.get().fire(
        new FailureMessage({ message: "Request Updatation Failed" })
      );
    }
  };
  public onActionUpdateHandler1 = async (
    d3eState: RequestOverViewPageRefs
  ): Promise<void> => {
    let request: RequestStatusUpdate = new RequestStatusUpdate({
      rentalRequest: this.requestObj,
      status:
        this.admin.role === AdminRole.Admin
          ? RequestStatus.Rejected
          : RequestStatus.SchoolRejected,
    });

    let result: Result<RequestStatusUpdate> = await request.save();

    if (result.status === ResultStatus.Success) {
      EventBus.get().fire(
        new SuccessMessage({ message: " Request Updated Successfully" })
      );
    } else {
      EventBus.get().fire(
        new FailureMessage({ message: "Request Updatation Failed" })
      );
    }
  };
  public onCommentHandler = async (
    d3eState: RequestOverViewPageRefs
  ): Promise<void> => {
    if (this.comment.isNotEmpty) {
      let message: Message = new Message({
        message: this.comment,
        request: this.requestObj,
        type: MessageType.Comment,
      });

      let result: Result<Message> = await message.save();

      if (result.status === ResultStatus.Success) {
        EventBus.get().fire(
          new SuccessMessage({ message: " Comment Added Successfully" })
        );
      }

      this.setComment("");
    } else {
      EventBus.get().fire(
        new FailureMessage({ message: "Comment cannot be empty" })
      );
    }
  };
  public onRequestChangesHandler = (
    d3eState: RequestOverViewPageRefs
  ): void => {
    this.showAddRequestPopup();
  };
  public onPressedDocumentUploadButtonHandler = (
    d3eState: RequestOverViewPageRefs
  ): void => {
    this.setRecentDocument(new RequestDocument({ request: this.requestObj }));

    this.showDocumentPopup({ autoClose: true });
  };
  public onPressedInsuranceUploadButtonHandler = (
    d3eState: RequestOverViewPageRefs
  ): void => {
    this.setRecentInsurance(new RequestInsurance({ request: this.requestObj }));

    this.showInsurancePopup({ autoClose: true });
  };
  public dispose(): void {
    this.addRequestPopupPopup?.dispose();

    this.documentPopupPopup?.dispose();

    this.insurancePopupPopup?.dispose();

    super.dispose();
  }
  public showAddRequestPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.addRequestPopupPopup?.dispose();

    this.addRequestPopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        width: 460,
        child: RequestChangesPopup({ request: this.requestObj }),
        className: "x88f hc vc",
      }),
    });

    this.addRequestPopupPopup.showPopup(this.context);
  }
  public showDocumentPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.documentPopupPopup?.dispose();

    this.documentPopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        width: 460,
        child: AddOrEditDocumentView({ obj: this.recentDocument }),
        className: "xa916 hc vc",
      }),
    });

    this.documentPopupPopup.showPopup(this.context);
  }
  public showInsurancePopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.insurancePopupPopup?.dispose();

    this.insurancePopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        width: 460,
        child: AddorEditInsuranceView({ obj: this.recentInsurance }),
        className: "x94a hc vc",
      }),
    });

    this.insurancePopupPopup.showPopup(this.context);
  }
  public hideAddRequestPopup(): void {
    this.addRequestPopupPopup?.dispose();
  }
  public hideDocumentPopup(): void {
    this.documentPopupPopup?.dispose();
  }
  public hideInsurancePopup(): void {
    this.insurancePopupPopup?.dispose();
  }
  public docRefonChanged = (
    val: string,
    d3eState: RequestOverViewPageRefs
  ): void => {
    this.setComment(val);
  };
  public get approveButton() {
    return this.d3eState.approveButton;
  }
  public get commentButton() {
    return this.d3eState.commentButton;
  }
  public get documentUploadButton() {
    return this.d3eState.documentUploadButton;
  }
  public get insuranceUploadButton() {
    return this.d3eState.insuranceUploadButton;
  }
  public get rejectButton() {
    return this.d3eState.rejectButton;
  }
  public get requestChangesButton() {
    return this.d3eState.requestChangesButton;
  }
}
export default function RequestOverViewPage(props: RequestOverViewPageProps) {
  return React.createElement(_RequestOverViewPageState, {
    ..._RequestOverViewPageState.defaultProps,
    ...props,
  });
}
