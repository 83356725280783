export enum CursorType {
  none = 1,
  click,
  basic,
  text,
  forbidden,
  cell,
  precise,
  grab,
  move,
  noDrop,
  resizeUpRightDownLeft,
  alias,
  copy,
  allScroll,
  resizeUpLeftDownRight,
  resizeUpDown,
  resizeLeftRight,
  resizeRow,
  zoomIn,
  zoomOut,
  resizeRight,
  resizeColumn,
  resizeDownRight,
  resizeDownLeft,
  resizeUpRight,
  resizeUpLeft,
  resizeUp,
  resizeLeft,
  resizeDown,
  grabbing,
  wait,
  progress,
  contextMenu,
  help,
  verticalText,
}

export default CursorType;
