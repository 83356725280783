import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import SignUpRequest from "../models/SignUpRequest";
import ListWrapper from "../utils/ListWrapper";
import CheckServerErrors from "./CheckServerErrors";
import LoginResult from "../classes/LoginResult";
import TextView from "./TextView";
import PageNavigator from "../classes/PageNavigator";
import LabelwithInputField from "./LabelwithInputField";
import Renter from "../models/Renter";
import CollectionUtils from "../utils/CollectionUtils";
import Query from "../classes/Query";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _ForgotPasswordButtonOnPressed = (d3eState: RentalLoginPageRefs) => void;

type _LoginButtonOnPressed = (d3eState: RentalLoginPageRefs) => void;

type _Button2OnPressed = (d3eState: RentalLoginPageRefs) => void;

type _InputFieldOnChanged = (
  text: string,
  d3eState: RentalLoginPageRefs
) => void;

type _InputField2OnChanged = (
  text: string,
  d3eState: RentalLoginPageRefs
) => void;

export interface RentalLoginPageProps extends BaseUIProps {
  key?: string;
}
/// To store state data for RentalLoginPage
class RentalLoginPageRefs {
  public button2: Button2State = new Button2State();
  public forgotPasswordButton: ForgotPasswordButtonState =
    new ForgotPasswordButtonState();
  public loginButton: LoginButtonState = new LoginButtonState();
}

interface Button2WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalLoginPageRefs;
  _onPressedButton2Handler?: _Button2OnPressed;
}

class Button2State extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _Button2WithState extends ObservableComponent<Button2WithStateProps> {
  button2FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Button2WithStateProps) {
    super(props);

    this.initState();
  }
  public get button2(): Button2State {
    return this.props.d3eState.button2;
  }
  public get d3eState(): RentalLoginPageRefs {
    return this.props.d3eState;
  }
  public get _onPressedButton2Handler(): _Button2OnPressed {
    return this.props._onPressedButton2Handler;
  }
  public initState() {
    super.initState();

    this.updateObservable("button2", null, this.button2);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button2"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.button2.disable,
      onPressed: () => {
        this._onPressedButton2Handler(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({
        data: "Create Account",
        style: new ui.TextStyle({
          color: cStyle.c20,
          decoration: ui.TextDecoration.underline,
          decorationColor: cStyle.c20,
          fontSize: 15,
          fontWeight: ui.FontWeight.w600,
        }),
        className: "x68a",
      }),
    });
  }
}
function Button2WithState(props: Button2WithStateProps) {
  return React.createElement(_Button2WithState, props);
}

interface LoginButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalLoginPageRefs;
  _onPressedButtonHandler?: _LoginButtonOnPressed;
}

class LoginButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _LoginButtonWithState extends ObservableComponent<LoginButtonWithStateProps> {
  loginButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LoginButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get loginButton(): LoginButtonState {
    return this.props.d3eState.loginButton;
  }
  public get d3eState(): RentalLoginPageRefs {
    return this.props.d3eState;
  }
  public get _onPressedButtonHandler(): _LoginButtonOnPressed {
    return this.props._onPressedButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("loginButton", null, this.loginButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["loginButton", "loginButton.", "loginButton.hover"], this.rebuild);
  }
  public loginButtonOnEnter(event): void {
    return this.loginButton.setHover(true);
  }
  public loginButtonOnExit(event): void {
    return this.loginButton.setHover(false);
  }
  public dispose(): void {
    this.loginButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      expand: true,
      margin: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 20.0,
        transitions: new Map(),
      }),
      child: Button({
        padding: this.loginButton.hover
          ? cStyle.tButtonPrimaryNewPaddingOnHover
          : cStyle.tButtonPrimaryNewPaddingOn,
        decoration: this.loginButton.hover
          ? cStyle.tButtonPrimaryNewDecorationOnHover
          : cStyle.tButtonPrimaryNewDecorationOn,
        disable: this.loginButton.disable,
        onPressed: () => {
          this._onPressedButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Log in", className: "hc" }),
        onEnter: (event) => {
          this.loginButtonOnEnter(event);
        },
        onExit: (event) => {
          this.loginButtonOnExit(event);
        },
      }),
      className: "xedb hc h",
    });
  }
}
function LoginButtonWithState(props: LoginButtonWithStateProps) {
  return React.createElement(_LoginButtonWithState, props);
}

interface ForgotPasswordButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalLoginPageRefs;
  _forgotPasswordHandler?: _ForgotPasswordButtonOnPressed;
}

class ForgotPasswordButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ForgotPasswordButtonWithState extends ObservableComponent<ForgotPasswordButtonWithStateProps> {
  forgotPasswordButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ForgotPasswordButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get forgotPasswordButton(): ForgotPasswordButtonState {
    return this.props.d3eState.forgotPasswordButton;
  }
  public get d3eState(): RentalLoginPageRefs {
    return this.props.d3eState;
  }
  public get _forgotPasswordHandler(): _ForgotPasswordButtonOnPressed {
    return this.props._forgotPasswordHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable(
      "forgotPasswordButton",
      null,
      this.forgotPasswordButton
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "forgotPasswordButton",
        "forgotPasswordButton.",
        "forgotPasswordButton.hover",
      ],
      this.rebuild
    );
  }
  public forgotPasswordButtonOnEnter(event): void {
    return this.forgotPasswordButton.setHover(true);
  }
  public forgotPasswordButtonOnExit(event): void {
    return this.forgotPasswordButton.setHover(false);
  }
  public dispose(): void {
    this.forgotPasswordButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.forgotPasswordButton.disable,
      onPressed: () => {
        this._forgotPasswordHandler(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({
        data: "Forgot Password",
        style: new ui.TextStyle({
          fontFamily: "Open Sans",
          fontSize: 16,
          color: cStyle.c20,
        }),
        className: "x0c4",
      }),
      onEnter: (event) => {
        this.forgotPasswordButtonOnEnter(event);
      },
      onExit: (event) => {
        this.forgotPasswordButtonOnExit(event);
      },
      className: "x03a",
    });
  }
}
function ForgotPasswordButtonWithState(
  props: ForgotPasswordButtonWithStateProps
) {
  return React.createElement(_ForgotPasswordButtonWithState, props);
}

class _RentalLoginPageState extends ObservableComponent<RentalLoginPageProps> {
  d3eState: RentalLoginPageRefs = new RentalLoginPageRefs();
  email: string = "";
  password: string = "";
  emailInfo: boolean = false;
  passwordInfo: boolean = false;
  errors: Array<string> = ListWrapper.widget(this, "errors");
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RentalLoginPageProps) {
    super(props);

    this.initState();
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["email", "emailInfo", "errors", "password", "passwordInfo"],
      this.rebuild
    );
  }
  public setEmail(val: string): void {
    let isValChanged: boolean = this.email !== val;

    if (!isValChanged) {
      return;
    }

    this.email = val;

    this.fire("email", this);
  }
  public setPassword(val: string): void {
    let isValChanged: boolean = this.password !== val;

    if (!isValChanged) {
      return;
    }

    this.password = val;

    this.fire("password", this);
  }
  public setEmailInfo(val: boolean): void {
    let isValChanged: boolean = this.emailInfo !== val;

    if (!isValChanged) {
      return;
    }

    this.emailInfo = val;

    this.fire("emailInfo", this);
  }
  public setPasswordInfo(val: boolean): void {
    let isValChanged: boolean = this.passwordInfo !== val;

    if (!isValChanged) {
      return;
    }

    this.passwordInfo = val;

    this.fire("passwordInfo", this);
  }
  public setErrors(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.errors, val);

    if (!isValChanged) {
      return;
    }

    this.errors.clear();

    this.errors.addAll(val);

    this.fire("errors", this);
  }
  public addToErrors(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.errors.contains(val)) this.errors.add(val);
    } else {
      this.errors.remove(this.errors.elementAt(index));

      this.errors.add(val);
    }

    this.fire("errors", this, val, true);
  }
  public removeFromErrors(val: string): void {
    this.errors.remove(val);

    this.fire("errors", this, val, false);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Row({
      mainAxisAlignment: ui.MainAxisAlignment.center,
      children: [
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          mainAxisAlignment: ui.MainAxisAlignment.start,
          children: [
            ui.AssetImage({
              path: "images/Logo-new.png",
              width: 183,
              height: 55,
              className: "x9df hc vc",
              key: "0",
            }),
            TextView({
              data: "Welcome back to FeciliRes",
              style: new ui.TextStyle({
                color: cStyle.c2,
                fontSize: 38,
                fontWeight: cStyle.tTextViewHeadlineOneFontWeightOn,
              }),
              className: "x6f7 hc",
              key: "1",
            }),
            TextView({
              data: "Lorem ipsum is placeholder text commonly used in the\ngraphic, print, and publishing industries for previewing layout\nand visual mockups.",
              softWrap: true,
              maxLines: 5,
              textAlign: cStyle.tTextViewLabelTextAlignOn,
              style: new ui.TextStyle({
                color: cStyle.c2,
                fontSize: 18,
                height: 1.8,
              }),
              className: "x704 hc",
              key: "2",
            }),
          ],
          className: "xffe hc vc v",
          key: "0",
        }),
        ui.Column({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                ui.AssetImage({
                  path: "images/Logo.png",
                  width: 235,
                  height: 55,
                  fit: ui.BoxFit.cover,
                  className: "x250 hc vc",
                  key: "0",
                }),
              ],
              className: "xb6b hc h",
              key: "0",
            }),
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                TextView({
                  data: "Log In",
                  style: new ui.TextStyle({
                    fontSize: cStyle.tTextViewHeadlineOneFontSizeOn,
                    fontWeight: cStyle.tTextViewHeadlineOneFontWeightOn,
                  }),
                  className: "hc",
                  key: "0",
                }),
                TextView({
                  data: "We are glad to see you again!",
                  softWrap: true,
                  style: new ui.TextStyle({
                    fontSize: 15,
                    fontFamily: "Open Sans",
                  }),
                  className: "xf3e hc",
                  key: "1",
                }),
                LabelwithInputField({
                  placeHolder: "Email address",
                  value: this.email,
                  errors: this.emailInfo ? ["Please enter your email"] : [],
                  name: "Email",
                  onChanged: (text) => {
                    this.inputFieldonChanged(text, this.d3eState);
                  },
                  className: "xb27 hc h",
                  key: "2",
                }),
                ui.Container({ className: "x33c hc vc", key: "3" }),
                LabelwithInputField({
                  placeHolder: "Enter Password",
                  value: this.password,
                  obscureText: true,
                  errors: this.passwordInfo
                    ? ["Please enter your password"]
                    : [],
                  name: "Password",
                  onSubmitted: (value) => {
                    this.onSubmittedPasswordField(value, this.d3eState);
                  },
                  onChanged: (text) => {
                    this.inputField2onChanged(text, this.d3eState);
                  },
                  className: "xdda hc h",
                  key: "4",
                }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.end,
                  children: [
                    ForgotPasswordButtonWithState({
                      d3eState: this.d3eState,
                      _forgotPasswordHandler: this.forgotPasswordHandler,
                      key: "0",
                    }),
                  ],
                  className: "xa3f hc h",
                  key: "5",
                }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.start,
                  children: [
                    ui.Column({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        this.errors.isNotEmpty
                          ? CheckServerErrors({ errors: this.errors })
                          : [],
                      ],
                      key: "0",
                    }),
                  ],
                  className: "x649 hc h",
                  key: "6",
                }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.center,
                  children: [
                    LoginButtonWithState({
                      d3eState: this.d3eState,
                      _onPressedButtonHandler: this.onPressedButtonHandler,
                      key: "0",
                    }),
                  ],
                  className: "x6ee hc h",
                  key: "7",
                }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.center,
                  children: [
                    TextView({
                      data: "Don't have an account?",
                      style: new ui.TextStyle({ fontSize: 15 }),
                      className: "x1c36",
                      key: "0",
                    }),
                    Button2WithState({
                      d3eState: this.d3eState,
                      _onPressedButton2Handler: this.onPressedButton2Handler,
                      key: "1",
                    }),
                  ],
                  className: "x69a hc h",
                  key: "8",
                }),
              ],
              className: "x8b5 hc h",
              key: "1",
            }),
          ],
          className: "x28c hc vc h v",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "RentalLoginPage x6dc hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onSubmittedPasswordField = (
    value: string,
    d3eState: RentalLoginPageRefs
  ): void => {
    this.authenticatingUser();
  };
  public onPressedButtonHandler = (d3eState: RentalLoginPageRefs): void => {
    this.authenticatingUser();
  };
  public onPressedButton2Handler = (d3eState: RentalLoginPageRefs): void => {
    this.navigator.pushRentelSignupPage({ signUpRequest: new SignUpRequest() });
  };
  public forgotPasswordHandler = (d3eState: RentalLoginPageRefs): void => {
    this.navigator.pushRentalForgotPasswordPage();
  };
  public authenticatingUser = async (): Promise<void> => {
    if (this.email.isEmpty) {
      // POS: 250.00, Y = 30.00

      this.setEmailInfo(true);
    }

    // POS: 350.00, Y = 80.00

    if (this.password.isEmpty) {
      // POS: 450.00, Y = 70.00

      this.setPasswordInfo(true);
    }

    if (this.emailInfo || this.passwordInfo) {
      return;
    }

    // POS: 550.00, Y = 80.00

    let result: LoginResult =
      await Query.get().loginRenterUserWithEmailAndPassword(
        UsageConstants.QUERY_LOGINRENTERUSERWITHEMAILANDPASSWORD_RENTALLOGINPAGE_EVENTHANDLERS_AUTHENTICATINGUSER_BLOCK,
        { email: this.email, password: this.password }
      );

    // POS: 800.00, Y = 80.00

    if (result.success) {
      this.navigator.pushRentalHomePage({
        renter: result.userObject as Renter,
      });
    } else {
      this.setErrors(["Invalid login credentials"]);
    }
  };
  public inputFieldonChanged = (
    val: string,
    d3eState: RentalLoginPageRefs
  ): void => {
    this.setEmail(val);
  };
  public inputField2onChanged = (
    val: string,
    d3eState: RentalLoginPageRefs
  ): void => {
    this.setPassword(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get button2() {
    return this.d3eState.button2;
  }
  public get forgotPasswordButton() {
    return this.d3eState.forgotPasswordButton;
  }
  public get loginButton() {
    return this.d3eState.loginButton;
  }
}
export default function RentalLoginPage(props: RentalLoginPageProps) {
  return React.createElement(_RentalLoginPageState, props);
}
