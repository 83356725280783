import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";

export interface MouseHoverViewProps extends BaseUIProps {
  key?: string;
  hoverColor?: ui.Color;
  child?: ReactNode;
}

class _MouseHoverViewState extends ObservableComponent<MouseHoverViewProps> {
  static defaultProps = { hoverColor: null };
  mousePointerOn: boolean = false;
  color: ui.Color = null;
  public constructor(props: MouseHoverViewProps) {
    super(props);

    this.initState();
  }
  public get hoverColor(): ui.Color {
    return this.props.hoverColor;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["hoverColor", "mousePointerOn"], this.computeColor);

    this.computeColor();

    this.on(["childPresent", "color"], this.rebuild);
  }
  public componentDidUpdate(prevProps: MouseHoverViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.hoverColor !== this.props.hoverColor) {
      this.fire("hoverColor", this);
    }
  }
  public setMousePointerOn(val: boolean): void {
    let isValChanged: boolean = this.mousePointerOn !== val;

    if (!isValChanged) {
      return;
    }

    this.mousePointerOn = val;

    this.fire("mousePointerOn", this);
  }
  public setColor(val: ui.Color): void {
    let isValChanged: boolean = this.color !== val;

    if (!isValChanged) {
      return;
    }

    this.color = val;

    this.fire("color", this);
  }
  public computeColor = (): void => {
    try {
      this.setColor(
        this.mousePointerOn
          ? this.hoverColor !== null
            ? this.hoverColor
            : ui.HexColor.fromHexInt(0xff9bb7d4)
          : null
      );
    } catch (exception) {
      console.log(" exception in computeColor : " + exception.toString());

      this.setColor(null);
    }
  };
  public render(): ReactNode {
    return ui.Container({
      decoration: new ui.BoxDecoration({ color: this.color }),
      child: this.childPresent ? this.props.child : [],
      onEnter: (event) => {
        this.onEnterHandler(event);
      },
      onExit: (event) => {
        this.onExitHandler(event);
      },
      className: ui.join(this.props.className, "MouseHoverView hc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onEnterHandler = (event: ui.PointerEnterEvent): void => {
    this.setMousePointerOn(true);
  };
  public onExitHandler = (event: ui.PointerExitEvent): void => {
    this.setMousePointerOn(false);
  };
  public get childPresent(): boolean {
    return this.props.child != null;
  }
  public get child(): ReactNode {
    return this.props.child;
  }
}
export default function MouseHoverView(props: MouseHoverViewProps) {
  return React.createElement(_MouseHoverViewState, {
    ..._MouseHoverViewState.defaultProps,
    ...props,
  });
}
