export enum TextInputType {
  text = 1,
  multiline,
  numberValue,
  phone,
  datetime,
  emailAddress,
  url,
  number,
  visiblePassword,
  name,
  streetAddress,
  none,
}
