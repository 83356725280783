import FacilityType from "./FacilityType";
import School from "./School";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class FacilitiesListRequest extends DBObject {
  private static readonly _ASCENDING: number = 0;
  private static readonly _OFFSET: number = 1;
  private static readonly _ORDERBY: number = 2;
  private static readonly _PAGESIZE: number = 3;
  private static readonly _SCHOOL: number = 4;
  private static readonly _TYPE: number = 5;
  public id: number = 0;
  public otherMaster: DBObject;
  private _pageSize: number = 0;
  private _offset: number = 0;
  private _orderBy: string = "";
  private _ascending: boolean = false;
  private _school: School = null;
  private _type: FacilityType = null;
  public constructor(
    d3eParams?: Partial<{
      ascending: boolean;
      offset: number;
      orderBy: string;
      pageSize: number;
      school: School;
      type: FacilityType;
    }>
  ) {
    super();

    this.setAscending(d3eParams?.ascending ?? false);

    this.setOffset(d3eParams?.offset ?? 0);

    this.setOrderBy(d3eParams?.orderBy ?? "");

    this.setPageSize(d3eParams?.pageSize ?? 0);

    this.setSchool(d3eParams?.school ?? null);

    this.setType(d3eParams?.type ?? null);
  }
  public get d3eType(): string {
    return "FacilitiesListRequest";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isTransientModel(): boolean {
    return;
  }
  public get pageSize(): number {
    return this._pageSize;
  }
  public setPageSize(val: number): void {
    let isValChanged: boolean = this._pageSize !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(FacilitiesListRequest._PAGESIZE, this._pageSize);

    this._pageSize = val;

    this.fire("pageSize", this);
  }
  public get offset(): number {
    return this._offset;
  }
  public setOffset(val: number): void {
    let isValChanged: boolean = this._offset !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(FacilitiesListRequest._OFFSET, this._offset);

    this._offset = val;

    this.fire("offset", this);
  }
  public get orderBy(): string {
    return this._orderBy;
  }
  public setOrderBy(val: string): void {
    let isValChanged: boolean = this._orderBy !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(FacilitiesListRequest._ORDERBY, this._orderBy);

    this._orderBy = val;

    this.fire("orderBy", this);
  }
  public get ascending(): boolean {
    return this._ascending;
  }
  public setAscending(val: boolean): void {
    let isValChanged: boolean = this._ascending !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(FacilitiesListRequest._ASCENDING, this._ascending);

    this._ascending = val;

    this.fire("ascending", this);
  }
  public get school(): School {
    return this._school;
  }
  public setSchool(val: School): void {
    let isValChanged: boolean = this._school !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(FacilitiesListRequest._SCHOOL, this._school);

    this.updateObservable("school", this._school, val);

    this._school = val;

    this.fire("school", this);
  }
  public get type(): FacilityType {
    return this._type;
  }
  public setType(val: FacilityType): void {
    let isValChanged: boolean = this._type !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(FacilitiesListRequest._TYPE, this._type);

    this.updateObservable("type", this._type, val);

    this._type = val;

    this.fire("type", this);
  }
  public get(field: number): any {
    switch (field) {
      case FacilitiesListRequest._PAGESIZE: {
        return this._pageSize;
      }

      case FacilitiesListRequest._OFFSET: {
        return this._offset;
      }

      case FacilitiesListRequest._ORDERBY: {
        return this._orderBy;
      }

      case FacilitiesListRequest._ASCENDING: {
        return this._ascending;
      }

      case FacilitiesListRequest._SCHOOL: {
        return this._school;
      }

      case FacilitiesListRequest._TYPE: {
        return this._type;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): FacilitiesListRequest {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: FacilitiesListRequest = dbObj as FacilitiesListRequest;

    obj.id = this.id;

    obj.setPageSize(this._pageSize);

    obj.setOffset(this._offset);

    obj.setOrderBy(this._orderBy);

    obj.setAscending(this._ascending);

    obj.setSchool(this._school);

    obj.setType(this._type);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case FacilitiesListRequest._PAGESIZE: {
        this.setPageSize(value as number);
        break;
      }

      case FacilitiesListRequest._OFFSET: {
        this.setOffset(value as number);
        break;
      }

      case FacilitiesListRequest._ORDERBY: {
        this.setOrderBy(value as string);
        break;
      }

      case FacilitiesListRequest._ASCENDING: {
        this.setAscending(value as boolean);
        break;
      }

      case FacilitiesListRequest._SCHOOL: {
        this.setSchool(value as School);
        break;
      }

      case FacilitiesListRequest._TYPE: {
        this.setType(value as FacilityType);
        break;
      }
    }
  }
}
