import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class ChangePasswordRequest extends DBObject {
  private static readonly _NEWPASSWORD: number = 0;
  public id: number = 0;
  public otherMaster: DBObject;
  private _newPassword: string = "";
  public constructor(d3eParams?: Partial<{ newPassword: string }>) {
    super();

    this.setNewPassword(d3eParams?.newPassword ?? "");
  }
  public get d3eType(): string {
    return "ChangePasswordRequest";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isTransientModel(): boolean {
    return;
  }
  public get newPassword(): string {
    return this._newPassword;
  }
  public setNewPassword(val: string): void {
    let isValChanged: boolean = this._newPassword !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      ChangePasswordRequest._NEWPASSWORD,
      this._newPassword
    );

    this._newPassword = val;

    this.fire("newPassword", this);
  }
  public get(field: number): any {
    switch (field) {
      case ChangePasswordRequest._NEWPASSWORD: {
        return this._newPassword;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): ChangePasswordRequest {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: ChangePasswordRequest = dbObj as ChangePasswordRequest;

    obj.id = this.id;

    obj.setNewPassword(this._newPassword);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case ChangePasswordRequest._NEWPASSWORD: {
        this.setNewPassword(value as string);
        break;
      }
    }
  }
}
