import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import D3EDate from "../classes/D3EDate";
import CalendarEvent from "../classes/CalendarEvent";
import TextView from "./TextView";

type _ViewOnPressed = (d3eState: RoundedDateViewRefs) => void;

export interface RoundedDateViewProps extends BaseUIProps {
  key?: string;
  date: D3EDate;
  month: number;
  items?: Array<CalendarEvent>;
  _itemsHash?: number;
}
/// To store state data for RoundedDateView
class RoundedDateViewRefs {
  public view: ViewState = new ViewState();
}

interface ViewWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RoundedDateViewRefs;
  _viewThisDayEvents?: _ViewOnPressed;
  date: D3EDate;
  month: number;
}

class ViewState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _ViewWithState extends ObservableComponent<ViewWithStateProps> {
  viewFocusNode: ui.FocusNode = new ui.FocusNode();
  public constructor(props: ViewWithStateProps) {
    super(props);

    this.initState();
  }
  public get date(): D3EDate {
    return this.props.date;
  }
  public get month(): number {
    return this.props.month;
  }
  public get view(): ViewState {
    return this.props.d3eState.view;
  }
  public get d3eState(): RoundedDateViewRefs {
    return this.props.d3eState;
  }
  public get _viewThisDayEvents(): _ViewOnPressed {
    return this.props._viewThisDayEvents;
  }
  public initState() {
    super.initState();

    this.updateObservable("view", null, this.view);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["date", "month", "view"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    return Button({
      padding: ui.EdgeInsets.all(10.0, new Map()),
      disable: this.view.disable,
      onPressed: () => {
        this._viewThisDayEvents(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({
        data: this.date.day.toString(),
        states: ui.joinStates(
          { "data-c0": this.date.month !== this.month },
          {}
        ),
        style: new ui.TextStyle({
          fontSize: 14.0,
          color:
            this.date.month !== this.month ? new ui.Color(0xffd3d3d3) : null,
        }),
        className: "x38e hc",
      }),
      className: ui.join(this.props.className, "RoundedDateView hc"),
      ...copyBaseUIProps(this.props),
    });
  }
}
function ViewWithState(props: ViewWithStateProps) {
  return React.createElement(_ViewWithState, props);
}

class _RoundedDateViewState extends ObservableComponent<RoundedDateViewProps> {
  static defaultProps = { date: null, month: 0, items: [] };
  d3eState: RoundedDateViewRefs = new RoundedDateViewRefs();
  public constructor(props: RoundedDateViewProps) {
    super(props);

    this.initState();
  }
  public get date(): D3EDate {
    return this.props.date;
  }
  public get month(): number {
    return this.props.month;
  }
  public get items(): Array<CalendarEvent> {
    return this.props.items;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.items, "items");

    this.updateSyncCollProperty("items", this.props.items);

    this.on(["date", "month"], this.rebuild);
  }
  public componentDidUpdate(prevProps: RoundedDateViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.date !== this.props.date) {
      this.fire("date", this);
    }

    if (prevProps.month !== this.props.month) {
      this.fire("month", this);
    }

    if (prevProps.items !== this.props.items) {
      this.updateObservableColl("items", prevProps.items, this.props.items);

      this.fire("items", this);
    }
  }
  public render(): ReactNode {
    return ViewWithState({
      d3eState: this.d3eState,
      _viewThisDayEvents: this.viewThisDayEvents,
      date: this.date,
      month: this.month,
    });
  }
  public viewThisDayEvents = (d3eState: RoundedDateViewRefs): void => {
    // Todo: view events of this day
  };
  public get view() {
    return this.d3eState.view;
  }
}
export default function RoundedDateView(props: RoundedDateViewProps) {
  return React.createElement(
    _RoundedDateViewState,
    { ..._RoundedDateViewState.defaultProps, ...props },
    ListWrapper.fromInput<CalendarEvent>(props.items, "items")
  );
}
