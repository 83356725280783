import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import FacilityType from "../models/FacilityType";
import RentalFacilities from "../classes/RentalFacilities";
import FooterView from "./FooterView";
import HeaderView from "./HeaderView";
import Renter from "../models/Renter";
import FacilitiesMapView from "./FacilitiesMapView";
import PaginationView from "./PaginationView";
import Query from "../classes/Query";
import Button from "./Button";
import FacilityCardView from "./FacilityCardView";
import ListWrapper from "../utils/ListWrapper";
import TextView from "./TextView";
import RentalFacilitiesRequest from "../models/RentalFacilitiesRequest";
import RentalSearchableView from "./RentalSearchableView";
import Facility from "../models/Facility";
import CollectionUtils from "../utils/CollectionUtils";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _SearchButtonOnPressed = (d3eState: SearchPageRefs) => void;

type _RentalSearchableViewOnChanged = (
  text: FacilityType,
  d3eState: SearchPageRefs
) => void;

type _RentalSearchableView2OnChanged = (
  text: string,
  d3eState: SearchPageRefs
) => void;

export interface SearchPageProps extends BaseUIProps {
  key?: string;
  facilityType: FacilityType;
  location: string;
  facilityTypes: Array<FacilityType>;
  locations: Array<string>;
  renter: Renter;
  _facilityTypesHash?: number;
  _locationsHash?: number;
}
/// To store state data for SearchPage
class SearchPageRefs {
  public searchButton: SearchButtonState = new SearchButtonState();
}

interface SearchButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: SearchPageRefs;
  _onSearchButtonClicked?: _SearchButtonOnPressed;
}

class SearchButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _SearchButtonWithState extends ObservableComponent<SearchButtonWithStateProps> {
  searchButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SearchButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get searchButton(): SearchButtonState {
    return this.props.d3eState.searchButton;
  }
  public get d3eState(): SearchPageRefs {
    return this.props.d3eState;
  }
  public get _onSearchButtonClicked(): _SearchButtonOnPressed {
    return this.props._onSearchButtonClicked;
  }
  public initState() {
    super.initState();

    this.updateObservable("searchButton", null, this.searchButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["searchButton"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: 428,
      child: Button({
        padding: cStyle.tButtonPrimaryButtonPaddingOn,
        decoration: cStyle.tButtonPrimaryButtonDecorationOn,
        disable: this.searchButton.disable,
        onPressed: () => {
          this._onSearchButtonClicked(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Search", className: "hc" }),
      }),
      className: "x330 hc",
    });
  }
}
function SearchButtonWithState(props: SearchButtonWithStateProps) {
  return React.createElement(_SearchButtonWithState, props);
}

class _SearchPageState extends ObservableComponent<SearchPageProps> {
  static defaultProps = {
    facilityType: null,
    location: "",
    renter: null,
    facilityTypes: [],
    locations: [],
  };
  d3eState: SearchPageRefs = new SearchPageRefs();
  facilitiesQuery: RentalFacilities = null;
  facilities: Array<Facility> = ListWrapper.widget(this, "facilities");
  request: RentalFacilitiesRequest = null;
  selectedLocation: string = "";
  private _selectedLocationDefaultValue: string = "";
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SearchPageProps) {
    super(props);

    this.initState();
  }
  public get facilityType(): FacilityType {
    return this.props.facilityType;
  }
  public get location(): string {
    return this.props.location;
  }
  public get facilityTypes(): Array<FacilityType> {
    return this.props.facilityTypes;
  }
  public get locations(): Array<string> {
    return this.props.locations;
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.setDefaults();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("facilityType", this.props.facilityType);

    this.subscribeToList(this.facilityTypes, "facilityTypes");

    this.updateSyncCollProperty("facilityTypes", this.props.facilityTypes);

    this.subscribeToList(this.locations, "locations");

    this.updateSyncProperty("renter", this.props.renter);

    this.on(
      [
        "request",
        "request.city",
        "request.facilityType",
        "request.offset",
        "request.pageSize",
        "request.state",
      ],
      this.computeFacilitiesQuery
    );

    this.computeFacilitiesQuery();

    this.on(
      ["facilitiesQuery", "facilitiesQuery.items"],
      this.computeFacilities
    );

    this.computeFacilities();

    this.on(["facilityType", "location"], this.computeRequest);

    this.computeRequest();

    this.on(["location"], this.setSelectedLocationDefaultValue);

    this.on(
      [
        "facilities",
        "facilitiesQuery",
        "facilitiesQuery.count",
        "facilityTypes",
        "locations",
        "renter",
        "request",
        "request.facilityType",
        "request.offset",
        "request.pageSize",
        "selectedLocation",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: SearchPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.facilityType !== this.props.facilityType) {
      this.updateObservable(
        "facilityType",
        prevProps.facilityType,
        this.props.facilityType
      );

      this.fire("facilityType", this);
    }

    if (prevProps.location !== this.props.location) {
      this.fire("location", this);
    }

    if (prevProps.facilityTypes !== this.props.facilityTypes) {
      this.updateObservableColl(
        "facilityTypes",
        prevProps.facilityTypes,
        this.props.facilityTypes
      );

      this.fire("facilityTypes", this);
    }

    if (prevProps.locations !== this.props.locations) {
      this.fire("locations", this);
    }

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public setFacilitiesQuery(val: RentalFacilities): void {
    let isValChanged: boolean = this.facilitiesQuery !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("facilitiesQuery", this.facilitiesQuery, val);

    this.facilitiesQuery = val;

    this.fire("facilitiesQuery", this);
  }
  public computeFacilitiesQuery = async (): Promise<void> => {
    try {
      this.setFacilitiesQuery(
        await Query.get().getRentalFacilities(
          UsageConstants.QUERY_GETRENTALFACILITIES_SEARCHPAGE_PROPERTIES_FACILITIESQUERY_COMPUTATION,
          this.request
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeFacilitiesQuery : " + exception.toString()
      );

      this.setFacilitiesQuery(null);
    }
  };
  public setFacilities(val: Array<Facility>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.facilities,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("facilities", this.facilities, val);

    this.facilities.clear();

    this.facilities.addAll(val);

    this.fire("facilities", this);
  }
  public addToFacilities(val: Facility, index: number = -1): void {
    if (index === -1) {
      if (!this.facilities.contains(val)) this.facilities.add(val);
    } else {
      this.facilities.remove(this.facilities.elementAt(index));

      this.facilities.add(val);
    }

    this.fire("facilities", this, val, true);

    this.updateObservable("facilities", null, val);
  }
  public removeFromFacilities(val: Facility): void {
    this.facilities.remove(val);

    this.fire("facilities", this, val, false);

    this.removeObservable("facilities", val);
  }
  public computeFacilities = (): void => {
    try {
      this.setFacilities(Array.from(this.facilitiesQuery?.items ?? []));
    } catch (exception) {
      console.log(" exception in computeFacilities : " + exception.toString());

      this.setFacilities([]);
    }
  };
  public setRequest(val: RentalFacilitiesRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public computeRequest = (): void => {
    try {
      this.setRequest(
        new RentalFacilitiesRequest({
          facilityType: this.facilityType,
          city: this.location === null ? null : this.location.split(", ").first,
          state: this.location === null ? null : this.location.split(", ").last,
          pageSize: 50,
          offset: 0,
        })
      );
    } catch (exception) {
      console.log(" exception in computeRequest : " + exception.toString());

      this.setRequest(null);
    }
  };
  public setSelectedLocation(val: string): void {
    let isValChanged: boolean = this.selectedLocation !== val;

    if (!isValChanged) {
      return;
    }

    this.selectedLocation = val;

    this.fire("selectedLocation", this);
  }
  public setSelectedLocationDefaultValue = (force?: boolean): void => {
    try {
      let oldDefaultValue: string = this._selectedLocationDefaultValue;

      this._selectedLocationDefaultValue = this.location;

      if (force || oldDefaultValue === this.selectedLocation)
        this.setSelectedLocation(this._selectedLocationDefaultValue);
    } catch (e) {
      this.setSelectedLocation("");
    }
  };
  public setDefaults(): void {
    this.setSelectedLocationDefaultValue();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        HeaderView({ renter: this.renter, className: "x243 hc h", key: "0" }),
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                ui.Column({
                  children: [
                    RentalSearchableView<FacilityType>({
                      name: "Facility Type",
                      value: this.request.facilityType,
                      items: this.facilityTypes,
                      activeColor: cStyle.c20,
                      inActiveColor: cStyle.c36,
                      placeHolder: "Search for a facility type",
                      onChanged: (text) => {
                        this.rentalSearchableViewonChanged(text, this.d3eState);
                      },
                      className: "x931 hc h",
                      key: "0",
                    }),
                    ui.Container({
                      margin: ui.EdgeInsets.symmetric({
                        horizontal: 0.0,
                        vertical: 10.0,
                        transitions: new Map(),
                      }),
                      child: RentalSearchableView<string>({
                        name: "Location",
                        value: this.selectedLocation,
                        items: this.locations,
                        activeColor: cStyle.c20,
                        inActiveColor: cStyle.c36,
                        placeHolder: "Enter a city or facility state/city",
                        onChanged: (text) => {
                          this.rentalSearchableView2onChanged(
                            text,
                            this.d3eState
                          );
                        },
                      }),
                      key: "1",
                      className: "x82e1 hc h",
                    }),
                    SearchButtonWithState({
                      d3eState: this.d3eState,
                      _onSearchButtonClicked: this.onSearchButtonClicked,
                      key: "2",
                    }),
                  ],
                  className: "x365 hc",
                  key: "0",
                }),
                ui.Container({
                  expand: true,
                  height: 350,
                  margin: ui.EdgeInsets.fromLTRB(
                    15.0,
                    0.0,
                    0.0,
                    0.0,
                    new Map()
                  ),
                  child: FacilitiesMapView({ facilities: this.facilities }),
                  key: "1",
                  className: "xa7c hc vc h",
                }),
              ],
              className: "x0814 hc h",
              key: "0",
            }),
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                TextView({
                  data:
                    (this.facilitiesQuery?.count ?? 0).toString() +
                    " results found",
                  style: new ui.TextStyle({
                    fontSize: cStyle.tTextViewHeadlineOneFontSizeOn,
                    fontWeight: cStyle.tTextViewHeadlineOneFontWeightOn,
                  }),
                  className: "x918 hc",
                  key: "0",
                }),
                ui.Row({
                  children: [
                    ui.Wrap({
                      children: [
                        this.facilities.expand((facilityItem) => [
                          FacilityCardView({
                            facility: facilityItem,
                            user: this.renter,
                            className: "hc",
                            key: facilityItem?.ident,
                          }),
                        ]),
                      ],
                      className: "x64a hc h",
                      key: "0",
                    }),
                  ],
                  className: "x7ba hc h",
                  key: "1",
                }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.end,
                  children: [
                    ui.Container({
                      padding: ui.EdgeInsets.fromLTRB(
                        0.0,
                        0.0,
                        0.0,
                        20.0,
                        new Map()
                      ),
                      child: PaginationView({
                        pageSize: this.request.pageSize,
                        offset: this.request.offset,
                        total: this.facilitiesQuery?.count ?? 0,
                        onChange: (pageSize, offset) => {
                          this.onPaginationViewChanged(
                            pageSize,
                            offset,
                            this.d3eState
                          );
                        },
                      }),
                      key: "0",
                      className: "xb05",
                    }),
                  ],
                  className: "xd73 hc h",
                  key: "2",
                }),
              ],
              className: "x1e10 hc h",
              key: "1",
            }),
            FooterView({ className: "x317 hc h", key: "2" }),
          ],
          className: "x821 hc vc h v",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "SearchPage hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPaginationViewChanged = (
    pageSize: number,
    offset: number,
    d3eState: SearchPageRefs
  ): void => {
    this.request.setPageSize(pageSize);

    this.request.setOffset(offset);

    this.setRequest(this.request.deepClone());
  };
  public onSearchButtonClicked = (d3eState: SearchPageRefs): void => {
    let cityState: Array<string> = this.selectedLocation.split(", ");

    this.request.setCity(cityState[0]);

    this.request.setState(cityState[1]);

    this.setRequest(this.request.deepClone());
  };
  public rentalSearchableViewonChanged = (
    val: FacilityType,
    d3eState: SearchPageRefs
  ): void => {
    this.request.setFacilityType(val);
  };
  public rentalSearchableView2onChanged = (
    val: string,
    d3eState: SearchPageRefs
  ): void => {
    this.setSelectedLocation(val);
  };
  public get searchButton() {
    return this.d3eState.searchButton;
  }
}
export default function SearchPage(props: SearchPageProps) {
  return React.createElement(
    _SearchPageState,
    { ..._SearchPageState.defaultProps, ...props },
    ListWrapper.fromInput<FacilityType>(props.facilityTypes, "facilityTypes"),
    ListWrapper.fromInput<string>(props.locations, "locations")
  );
}
