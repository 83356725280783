import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import OneTimePassword from "../models/OneTimePassword";
import ListWrapper from "../utils/ListWrapper";
import ResultStatus from "../classes/ResultStatus";
import LoginResult from "../classes/LoginResult";
import TextView from "./TextView";
import PageNavigator from "../classes/PageNavigator";
import LabelwithInputField from "./LabelwithInputField";
import Renter from "../models/Renter";
import Result from "../classes/Result";
import CollectionUtils from "../utils/CollectionUtils";
import Query from "../classes/Query";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _Enable2OnPressed = (d3eState: RentalForgotPasswordPageRefs) => void;

type _ROTP2OnPressed = (d3eState: RentalForgotPasswordPageRefs) => void;

type _VerifyOtp2OnPressed = (d3eState: RentalForgotPasswordPageRefs) => void;

type _SignInButtonOnPressed = (d3eState: RentalForgotPasswordPageRefs) => void;

export interface RentalForgotPasswordPageProps extends BaseUIProps {
  key?: string;
}
/// To store state data for RentalForgotPasswordPage
class RentalForgotPasswordPageRefs {
  public disable2: Disable2State = new Disable2State();
  public enable2: Enable2State = new Enable2State();
  public rOTP2: ROTP2State = new ROTP2State();
  public signInButton: SignInButtonState = new SignInButtonState();
  public verifyOtp2: VerifyOtp2State = new VerifyOtp2State();
}

interface SignInButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalForgotPasswordPageRefs;
  _onPressedSignInButton?: _SignInButtonOnPressed;
}

class SignInButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _SignInButtonWithState extends ObservableComponent<SignInButtonWithStateProps> {
  signInButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SignInButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get signInButton(): SignInButtonState {
    return this.props.d3eState.signInButton;
  }
  public get d3eState(): RentalForgotPasswordPageRefs {
    return this.props.d3eState;
  }
  public get _onPressedSignInButton(): _SignInButtonOnPressed {
    return this.props._onPressedSignInButton;
  }
  public initState() {
    super.initState();

    this.updateObservable("signInButton", null, this.signInButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["signInButton", "signInButton.", "signInButton.hover"],
      this.rebuild
    );
  }
  public signInButtonOnEnter(event): void {
    return this.signInButton.setHover(true);
  }
  public signInButtonOnExit(event): void {
    return this.signInButton.setHover(false);
  }
  public dispose(): void {
    this.signInButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.signInButton.disable,
      onPressed: () => {
        this._onPressedSignInButton(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({ data: "Sign In" }),
      onEnter: (event) => {
        this.signInButtonOnEnter(event);
      },
      onExit: (event) => {
        this.signInButtonOnExit(event);
      },
      className: "x2a4",
    });
  }
}
function SignInButtonWithState(props: SignInButtonWithStateProps) {
  return React.createElement(_SignInButtonWithState, props);
}

interface VerifyOtp2WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalForgotPasswordPageRefs;
  _verifyOTP?: _VerifyOtp2OnPressed;
}

class VerifyOtp2State extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _VerifyOtp2WithState extends ObservableComponent<VerifyOtp2WithStateProps> {
  verifyOtp2FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: VerifyOtp2WithStateProps) {
    super(props);

    this.initState();
  }
  public get verifyOtp2(): VerifyOtp2State {
    return this.props.d3eState.verifyOtp2;
  }
  public get d3eState(): RentalForgotPasswordPageRefs {
    return this.props.d3eState;
  }
  public get _verifyOTP(): _VerifyOtp2OnPressed {
    return this.props._verifyOTP;
  }
  public initState() {
    super.initState();

    this.updateObservable("verifyOtp2", null, this.verifyOtp2);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["verifyOtp2", "verifyOtp2.", "verifyOtp2.hover"], this.rebuild);
  }
  public verifyOtp2OnEnter(event): void {
    return this.verifyOtp2.setHover(true);
  }
  public verifyOtp2OnExit(event): void {
    return this.verifyOtp2.setHover(false);
  }
  public dispose(): void {
    this.verifyOtp2.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      padding: this.verifyOtp2.hover
        ? cStyle.tButtonPrimaryNewPaddingOnHover
        : cStyle.tButtonPrimaryNewPaddingOn,
      decoration: this.verifyOtp2.hover
        ? cStyle.tButtonPrimaryNewDecorationOnHover
        : cStyle.tButtonPrimaryNewDecorationOn,
      disable: this.verifyOtp2.disable,
      onPressed: () => {
        this._verifyOTP(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({ data: "Verify Code" }),
      onEnter: (event) => {
        this.verifyOtp2OnEnter(event);
      },
      onExit: (event) => {
        this.verifyOtp2OnExit(event);
      },
      className: "x8cd2",
    });
  }
}
function VerifyOtp2WithState(props: VerifyOtp2WithStateProps) {
  return React.createElement(_VerifyOtp2WithState, props);
}

interface ROTP2WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalForgotPasswordPageRefs;
  _resendOTP?: _ROTP2OnPressed;
}

class ROTP2State extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ROTP2WithState extends ObservableComponent<ROTP2WithStateProps> {
  rOTP2FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ROTP2WithStateProps) {
    super(props);

    this.initState();
  }
  public get rOTP2(): ROTP2State {
    return this.props.d3eState.rOTP2;
  }
  public get d3eState(): RentalForgotPasswordPageRefs {
    return this.props.d3eState;
  }
  public get _resendOTP(): _ROTP2OnPressed {
    return this.props._resendOTP;
  }
  public initState() {
    super.initState();

    this.updateObservable("rOTP2", null, this.rOTP2);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["rOTP2", "rOTP2.", "rOTP2.hover"], this.rebuild);
  }
  public rOTP2OnEnter(event): void {
    return this.rOTP2.setHover(true);
  }
  public rOTP2OnExit(event): void {
    return this.rOTP2.setHover(false);
  }
  public dispose(): void {
    this.rOTP2.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 10.0, 0.0, new Map()),
      child: Button({
        padding: this.rOTP2.hover
          ? cStyle.tButtonSecondaryNewPaddingOnHover
          : cStyle.tButtonSecondaryNewPaddingOn,
        decoration: this.rOTP2.hover
          ? cStyle.tButtonSecondaryNewDecorationOnHover
          : cStyle.tButtonSecondaryNewDecorationOn,
        disable: this.rOTP2.disable,
        onPressed: () => {
          this._resendOTP(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Resend Code" }),
        onEnter: (event) => {
          this.rOTP2OnEnter(event);
        },
        onExit: (event) => {
          this.rOTP2OnExit(event);
        },
      }),
      className: "xb48",
    });
  }
}
function ROTP2WithState(props: ROTP2WithStateProps) {
  return React.createElement(_ROTP2WithState, props);
}

interface Disable2WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalForgotPasswordPageRefs;
}

class Disable2State extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _Disable2WithState extends ObservableComponent<Disable2WithStateProps> {
  disable2FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Disable2WithStateProps) {
    super(props);

    this.initState();
  }
  public get disable2(): Disable2State {
    return this.props.d3eState.disable2;
  }
  public get d3eState(): RentalForgotPasswordPageRefs {
    return this.props.d3eState;
  }
  public initState() {
    super.initState();

    this.updateObservable("disable2", null, this.disable2);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["disable2", "disable2.", "disable2.hover"], this.rebuild);
  }
  public disable2OnEnter(event): void {
    return this.disable2.setHover(true);
  }
  public disable2OnExit(event): void {
    return this.disable2.setHover(false);
  }
  public dispose(): void {
    this.disable2.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      expand: true,
      child: Button({
        padding: this.disable2.hover
          ? cStyle.tButtonPrimaryNewPaddingOnHover
          : cStyle.tButtonPrimaryNewPaddingOn,
        decoration: this.disable2.hover
          ? cStyle.tButtonPrimaryNewDecorationOnHover
          : cStyle.tButtonPrimaryNewDecorationOn,
        disable: this.disable2.disable,
        onFocusChange: (val) => {},
        child: TextView({ data: "Resend Password", className: "hc" }),
        onEnter: (event) => {
          this.disable2OnEnter(event);
        },
        onExit: (event) => {
          this.disable2OnExit(event);
        },
      }),
      className: "x607 hc h",
    });
  }
}
function Disable2WithState(props: Disable2WithStateProps) {
  return React.createElement(_Disable2WithState, props);
}

interface Enable2WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RentalForgotPasswordPageRefs;
  _forgotPassword?: _Enable2OnPressed;
}

class Enable2State extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _Enable2WithState extends ObservableComponent<Enable2WithStateProps> {
  enable2FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Enable2WithStateProps) {
    super(props);

    this.initState();
  }
  public get enable2(): Enable2State {
    return this.props.d3eState.enable2;
  }
  public get d3eState(): RentalForgotPasswordPageRefs {
    return this.props.d3eState;
  }
  public get _forgotPassword(): _Enable2OnPressed {
    return this.props._forgotPassword;
  }
  public initState() {
    super.initState();

    this.updateObservable("enable2", null, this.enable2);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["enable2", "enable2.", "enable2.hover"], this.rebuild);
  }
  public enable2OnEnter(event): void {
    return this.enable2.setHover(true);
  }
  public enable2OnExit(event): void {
    return this.enable2.setHover(false);
  }
  public dispose(): void {
    this.enable2.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      expand: true,
      child: Button({
        padding: this.enable2.hover
          ? cStyle.tButtonPrimaryNewPaddingOnHover
          : cStyle.tButtonPrimaryNewPaddingOn,
        decoration: this.enable2.hover
          ? cStyle.tButtonPrimaryNewDecorationOnHover
          : cStyle.tButtonPrimaryNewDecorationOn,
        disable: this.enable2.disable,
        onPressed: () => {
          this._forgotPassword(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Resend Password", className: "hc" }),
        onEnter: (event) => {
          this.enable2OnEnter(event);
        },
        onExit: (event) => {
          this.enable2OnExit(event);
        },
      }),
      className: "xd9f hc h",
    });
  }
}
function Enable2WithState(props: Enable2WithStateProps) {
  return React.createElement(_Enable2WithState, props);
}

class _RentalForgotPasswordPageState extends ObservableComponent<RentalForgotPasswordPageProps> {
  d3eState: RentalForgotPasswordPageRefs = new RentalForgotPasswordPageRefs();
  email: string = "";
  endUser: Renter = null;
  showOtpPage: boolean = false;
  otpErrorMessages: Array<string> = ListWrapper.widget(
    this,
    "otpErrorMessages"
  );
  showOtpError: boolean = false;
  showError: boolean = false;
  showOtpValidationError: boolean = false;
  userEnteredOTP: string = "";
  token: string = "";
  hitRequest: boolean = false;
  emailInfo: boolean = false;
  userEmailError: Array<string> = ListWrapper.widget(this, "userEmailError");
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RentalForgotPasswordPageProps) {
    super(props);

    this.initState();
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "email",
        "emailInfo",
        "hitRequest",
        "otpErrorMessages",
        "showError",
        "showOtpError",
        "showOtpPage",
        "showOtpValidationError",
        "userEmailError",
      ],
      this.rebuild
    );
  }
  public setEmail(val: string): void {
    let isValChanged: boolean = this.email !== val;

    if (!isValChanged) {
      return;
    }

    this.email = val;

    this.fire("email", this);
  }
  public setEndUser(val: Renter): void {
    let isValChanged: boolean = this.endUser !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("endUser", this.endUser, val);

    this.endUser = val;

    this.fire("endUser", this);
  }
  public setShowOtpPage(val: boolean): void {
    let isValChanged: boolean = this.showOtpPage !== val;

    if (!isValChanged) {
      return;
    }

    this.showOtpPage = val;

    this.fire("showOtpPage", this);
  }
  public setOtpErrorMessages(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.otpErrorMessages,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.otpErrorMessages.clear();

    this.otpErrorMessages.addAll(val);

    this.fire("otpErrorMessages", this);
  }
  public addToOtpErrorMessages(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.otpErrorMessages.contains(val)) this.otpErrorMessages.add(val);
    } else {
      this.otpErrorMessages.remove(this.otpErrorMessages.elementAt(index));

      this.otpErrorMessages.add(val);
    }

    this.fire("otpErrorMessages", this, val, true);
  }
  public removeFromOtpErrorMessages(val: string): void {
    this.otpErrorMessages.remove(val);

    this.fire("otpErrorMessages", this, val, false);
  }
  public setShowOtpError(val: boolean): void {
    let isValChanged: boolean = this.showOtpError !== val;

    if (!isValChanged) {
      return;
    }

    this.showOtpError = val;

    this.fire("showOtpError", this);
  }
  public setShowError(val: boolean): void {
    let isValChanged: boolean = this.showError !== val;

    if (!isValChanged) {
      return;
    }

    this.showError = val;

    this.fire("showError", this);
  }
  public setShowOtpValidationError(val: boolean): void {
    let isValChanged: boolean = this.showOtpValidationError !== val;

    if (!isValChanged) {
      return;
    }

    this.showOtpValidationError = val;

    this.fire("showOtpValidationError", this);
  }
  public setUserEnteredOTP(val: string): void {
    let isValChanged: boolean = this.userEnteredOTP !== val;

    if (!isValChanged) {
      return;
    }

    this.userEnteredOTP = val;

    this.fire("userEnteredOTP", this);
  }
  public setToken(val: string): void {
    let isValChanged: boolean = this.token !== val;

    if (!isValChanged) {
      return;
    }

    this.token = val;

    this.fire("token", this);
  }
  public setHitRequest(val: boolean): void {
    let isValChanged: boolean = this.hitRequest !== val;

    if (!isValChanged) {
      return;
    }

    this.hitRequest = val;

    this.fire("hitRequest", this);
  }
  public setEmailInfo(val: boolean): void {
    let isValChanged: boolean = this.emailInfo !== val;

    if (!isValChanged) {
      return;
    }

    this.emailInfo = val;

    this.fire("emailInfo", this);
  }
  public setUserEmailError(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.userEmailError,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.userEmailError.clear();

    this.userEmailError.addAll(val);

    this.fire("userEmailError", this);
  }
  public addToUserEmailError(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.userEmailError.contains(val)) this.userEmailError.add(val);
    } else {
      this.userEmailError.remove(this.userEmailError.elementAt(index));

      this.userEmailError.add(val);
    }

    this.fire("userEmailError", this, val, true);
  }
  public removeFromUserEmailError(val: string): void {
    this.userEmailError.remove(val);

    this.fire("userEmailError", this, val, false);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      mainAxisAlignment: ui.MainAxisAlignment.center,
      children: [
        ui.Column({
          children: [
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                ui.AssetImage({
                  path: "images/Resize logo.png",
                  width: 235,
                  height: 55,
                  fit: ui.BoxFit.cover,
                  className: "xf1d hc vc",
                  key: "0",
                }),
              ],
              className: "x61f hc h",
              key: "0",
            }),
            !this.showOtpPage
              ? ui.Column({
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    TextView({
                      data: "Forgot Password",
                      style: new ui.TextStyle({
                        fontWeight: ui.FontWeight.w600,
                        fontSize: 25,
                        fontFamily: "Open Sans",
                      }),
                      className: "xc50 hc",
                      key: "0",
                    }),
                    TextView({
                      data: "If you have forgotten your password you can reset it here.",
                      softWrap: true,
                      style: new ui.TextStyle({
                        fontSize: 15,
                        fontFamily: "Open Sans",
                      }),
                      className: "x9ad1 hc",
                      key: "1",
                    }),
                    ui.Container({ className: "xad3 hc vc", key: "2" }),
                    LabelwithInputField({
                      name: "Email",
                      placeHolder: "Enter Email Address",
                      value: this.email,
                      isRequired: true,
                      errors: this.userEmailError,
                      onChanged: (value) => {
                        this.setStateEmail(value, this.d3eState);
                      },
                      onTap: () => {
                        this.setStateEmailError(this.d3eState);
                      },
                      className: "xfe4 hc h",
                      key: "3",
                    }),
                    this.emailInfo
                      ? TextView({
                          data: "Please enter an email address",
                          style: new ui.TextStyle({
                            fontSize: 14,
                            fontFamily: cStyle.tTextViewErrorLabelFontFamilyOn,
                            color: cStyle.tTextViewErrorLabelColorOn,
                          }),
                          className: "xcc hc",
                        })
                      : [],
                    this.showError
                      ? TextView({
                          data: "You have entered Invalid Email id",
                          style: new ui.TextStyle({
                            fontFamily: cStyle.tTextViewErrorLabelFontFamilyOn,
                            color: cStyle.tTextViewErrorLabelColorOn,
                            fontSize: cStyle.tTextViewErrorLabelFontSizeOn,
                          }),
                          className: "hc",
                        })
                      : [],
                    this.showOtpError
                      ? ui.Column({
                          crossAxisAlignment: ui.CrossAxisAlignment.start,
                          children: [
                            this.otpErrorMessages.expand((error) => [
                              TextView({
                                data: error,
                                style: new ui.TextStyle({
                                  fontFamily:
                                    cStyle.tTextViewErrorLabelFontFamilyOn,
                                  color: cStyle.tTextViewErrorLabelColorOn,
                                  fontSize:
                                    cStyle.tTextViewErrorLabelFontSizeOn,
                                }),
                                className: "hc",
                                key: error?.toString(),
                              }),
                            ]),
                          ],
                          className: "hc",
                        })
                      : [],
                    ui.Container({ className: "x863 hc vc", key: "7" }),
                    ui.Row({
                      mainAxisAlignment: ui.MainAxisAlignment.start,
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        !this.hitRequest
                          ? Enable2WithState({
                              d3eState: this.d3eState,
                              _forgotPassword: this.forgotPassword,
                            })
                          : Disable2WithState({ d3eState: this.d3eState }),
                      ],
                      className: "x101 hc h",
                      key: "8",
                    }),
                  ],
                  className: "xcf4 hc",
                })
              : ui.Column({
                  mainAxisAlignment: ui.MainAxisAlignment.center,
                  children: [
                    ui.Container({ className: "x45b hc vc", key: "0" }),
                    TextView({
                      data:
                        "We have sent an email to " +
                        this.email +
                        " with a One-Time code. Please provide the code below to complete the process.",
                      textAlign: ui.TextAlign.center,
                      style: new ui.TextStyle({
                        fontFamily: "Open Sans",
                        fontSize: 14,
                      }),
                      className: "x937 hc",
                      key: "1",
                    }),
                    ui.Container({
                      width: 400,
                      child: LabelwithInputField({
                        name: "Enter One Time Code",
                        placeHolder: "Enter The Code",
                        onChanged: (value) => {
                          this.setOTPCode(value, this.d3eState);
                        },
                        onTap: () => {
                          this.setOTPCodeError(this.d3eState);
                        },
                      }),
                      key: "2",
                      className: "x04d hc",
                    }),
                    this.showOtpValidationError
                      ? TextView({
                          data: "Please enter valid otp !",
                          textAlign: ui.TextAlign.center,
                          style: new ui.TextStyle({
                            fontSize: 14,
                            fontFamily: cStyle.tTextViewErrorLabelFontFamilyOn,
                            color: cStyle.tTextViewErrorLabelColorOn,
                          }),
                          className: "xeb7 hc",
                        })
                      : [],
                    ui.Row({
                      mainAxisAlignment: ui.MainAxisAlignment.start,
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        ROTP2WithState({
                          d3eState: this.d3eState,
                          _resendOTP: this.resendOTP,
                          key: "0",
                        }),
                        VerifyOtp2WithState({
                          d3eState: this.d3eState,
                          _verifyOTP: this.verifyOTP,
                          key: "1",
                        }),
                      ],
                      className: "x0bd hc",
                      key: "4",
                    }),
                  ],
                  className: "x1d4 hc",
                }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                TextView({
                  data: "Back to ?",
                  softWrap: true,
                  style: new ui.TextStyle({
                    fontFamily: "Open Sans",
                    fontSize: 16,
                  }),
                  className: "xc1c",
                  key: "0",
                }),
                SignInButtonWithState({
                  d3eState: this.d3eState,
                  _onPressedSignInButton: this.onPressedSignInButton,
                  key: "1",
                }),
              ],
              className: "xead hc h",
              key: "2",
            }),
          ],
          className: "x94d hc",
          key: "0",
        }),
      ],
      className: ui.join(
        this.props.className,
        "RentalForgotPasswordPage x92f hc vc"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public setStateEmail = (
    value: string,
    d3eState: RentalForgotPasswordPageRefs
  ): void => {
    this.setEmail(value);
  };
  public setStateEmailError = (
    d3eState: RentalForgotPasswordPageRefs
  ): void => {
    this.setEmailInfo(false);

    this.setShowError(false);
  };
  public setOTPCode = (
    value: string,
    d3eState: RentalForgotPasswordPageRefs
  ): void => {
    this.setUserEnteredOTP(value);
  };
  public setOTPCodeError = (d3eState: RentalForgotPasswordPageRefs): void => {
    this.setShowOtpValidationError(false);
  };
  public forgotPassword = (d3eState: RentalForgotPasswordPageRefs): void => {
    this.doForgotPassword();
  };
  public doForgotPassword = async (): Promise<void> => {
    this.setHitRequest(true);

    this.setShowOtpError(false);

    this.setShowError(false);

    if (this.email === "") {
      this.setEmailInfo(true);
    } else {
      let otp: OneTimePassword = new OneTimePassword({
        input: this.email.toLowerCase(),
        inputType: "email",
        userType: "Admin",
      });

      let result: Result<OneTimePassword> = await otp.save();

      if (result.status === ResultStatus.Success) {
        this.setToken(otp.token);

        this.setShowOtpPage(true);
      } else {
        this.setOtpErrorMessages(result.errors);

        this.setHitRequest(false);

        this.setShowOtpError(true);
      }
    }
  };
  public verifyOTP = async (
    d3eState: RentalForgotPasswordPageRefs
  ): Promise<void> => {
    this.setShowOtpValidationError(false);

    let result: LoginResult = await Query.get().loginWithOTP(
      UsageConstants.QUERY_LOGINWITHOTP_RENTALFORGOTPASSWORDPAGE_EVENTHANDLERS_VERIFYOTP_BLOCK,
      { token: this.token, code: this.userEnteredOTP }
    );

    if (result.success) {
      this.setEndUser(result.userObject as Renter);

      this.endUser.setEmail(this.email);

      this.navigator.pushRentalChangePasswordPage({ user: this.endUser });
    } else {
      this.setShowOtpValidationError(true);
    }
  };
  public resendOTP = (d3eState: RentalForgotPasswordPageRefs): void => {
    this.doForgotPassword();
  };
  public onPressedSignInButton = (
    d3eState: RentalForgotPasswordPageRefs
  ): void => {
    this.navigator.pushRentalLoginPage();
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get disable2() {
    return this.d3eState.disable2;
  }
  public get enable2() {
    return this.d3eState.enable2;
  }
  public get rOTP2() {
    return this.d3eState.rOTP2;
  }
  public get signInButton() {
    return this.d3eState.signInButton;
  }
  public get verifyOtp2() {
    return this.d3eState.verifyOtp2;
  }
}
export default function RentalForgotPasswordPage(
  props: RentalForgotPasswordPageProps
) {
  return React.createElement(_RentalForgotPasswordPageState, props);
}
