import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import Range from "../classes/Range";
import CalendarHelper from "../classes/CalendarHelper";
import PopupTargetController from "./PopupTargetController";
import CalendarViewType from "../classes/CalendarViewType";
import D3EDate from "../classes/D3EDate";
import EventDetailsPopupView from "./EventDetailsPopupView";
import Popup from "./Popup";
import ListWrapper from "../utils/ListWrapper";
import DateTime from "../core/DateTime";
import CalendarEvent from "../classes/CalendarEvent";
import TextView from "./TextView";
import DateBoxView from "./DateBoxView";
import CollectionUtils from "../utils/CollectionUtils";
import { BuildContext } from "../classes/BuildContext";

type _WeekViewOnDateSelected = (date: D3EDate) => void;

type _WeekViewBuilder<CalendarEvent, CalendarViewType> = (
  context: any,
  event: CalendarEvent,
  viewType: CalendarViewType
) => ReactNode;

export interface WeekViewProps extends BaseUIProps {
  key?: string;
  helper: CalendarHelper;
  date: D3EDate;
  categories: Array<string>;
  _categoriesHash?: number;
  onDateSelected?: _WeekViewOnDateSelected;
  builder?: _WeekViewBuilder<CalendarEvent, CalendarViewType>;
}

class _WeekViewState extends ObservableComponent<WeekViewProps> {
  static defaultProps = {
    helper: null,
    date: null,
    categories: [],
    onDateSelected: null,
  };
  listOfDates: Array<DateTime> = ListWrapper.widget(this, "listOfDates");
  weekNames: Array<string> = ListWrapper.widget(this, "weekNames");
  events: Array<CalendarEvent> = ListWrapper.widget(this, "events");
  selectedEvent: CalendarEvent = null;
  eventDetailsPopupPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public dateCell11PopupTargetController: PopupTargetController =
    new PopupTargetController();
  public constructor(props: WeekViewProps) {
    super(props);

    this.initState();
  }
  public get helper(): CalendarHelper {
    return this.props.helper;
  }
  public get date(): D3EDate {
    return this.props.date;
  }
  public get categories(): Array<string> {
    return this.props.categories;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.categories, "categories");

    this.on(["date"], this.computeListOfDates);

    this.computeListOfDates();

    this.computeWeekNames();

    this.on(["categories", "date", "helper"], this.computeEvents);

    this.computeEvents();

    this.on(
      ["categories", "date", "helper", "listOfDates", "weekNames"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: WeekViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.helper !== this.props.helper) {
      this.fire("helper", this);
    }

    if (prevProps.date !== this.props.date) {
      this.fire("date", this);
    }

    if (prevProps.categories !== this.props.categories) {
      this.fire("categories", this);
    }
  }
  public setListOfDates(val: Array<DateTime>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.listOfDates,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.listOfDates.clear();

    this.listOfDates.addAll(val);

    this.fire("listOfDates", this);
  }
  public addToListOfDates(val: DateTime, index: number = -1): void {
    if (index === -1) {
      if (!this.listOfDates.contains(val)) this.listOfDates.add(val);
    } else {
      this.listOfDates.remove(this.listOfDates.elementAt(index));

      this.listOfDates.add(val);
    }

    this.fire("listOfDates", this, val, true);
  }
  public removeFromListOfDates(val: DateTime): void {
    this.listOfDates.remove(val);

    this.fire("listOfDates", this, val, false);
  }
  public computeListOfDates = (): void => {
    try {
      this.setListOfDates(
        Array.from(CalendarHelper.getWeekDayDates(this.date.toDateTime()))
      );
    } catch (exception) {
      console.log(" exception in computeListOfDates : " + exception.toString());

      this.setListOfDates([]);
    }
  };
  public setWeekNames(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.weekNames,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.weekNames.clear();

    this.weekNames.addAll(val);

    this.fire("weekNames", this);
  }
  public addToWeekNames(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.weekNames.contains(val)) this.weekNames.add(val);
    } else {
      this.weekNames.remove(this.weekNames.elementAt(index));

      this.weekNames.add(val);
    }

    this.fire("weekNames", this, val, true);
  }
  public removeFromWeekNames(val: string): void {
    this.weekNames.remove(val);

    this.fire("weekNames", this, val, false);
  }
  public computeWeekNames = (): void => {
    try {
      this.setWeekNames(
        Array.from(["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"])
      );
    } catch (exception) {
      console.log(" exception in computeWeekNames : " + exception.toString());

      this.setWeekNames([]);
    }
  };
  public setEvents(val: Array<CalendarEvent>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.events, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("events", this.events, val);

    this.events.clear();

    this.events.addAll(val);

    this.fire("events", this);
  }
  public addToEvents(val: CalendarEvent, index: number = -1): void {
    if (index === -1) {
      if (!this.events.contains(val)) this.events.add(val);
    } else {
      this.events.remove(this.events.elementAt(index));

      this.events.add(val);
    }

    this.fire("events", this, val, true);

    this.updateObservable("events", null, val);
  }
  public removeFromEvents(val: CalendarEvent): void {
    this.events.remove(val);

    this.fire("events", this, val, false);

    this.removeObservable("events", val);
  }
  public computeEvents = (): void => {
    try {
      this.setEvents(
        Array.from(
          this.helper.getEventsForMonth(this.date.toDateTime(), this.categories)
        )
      );
    } catch (exception) {
      console.log(" exception in computeEvents : " + exception.toString());

      this.setEvents([]);
    }
  };
  public setSelectedEvent(val: CalendarEvent): void {
    let isValChanged: boolean = this.selectedEvent !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("selectedEvent", this.selectedEvent, val);

    this.selectedEvent = val;

    this.fire("selectedEvent", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Table({
          border: cStyle.tTableCalendarTableBorderOn,
          children: [
            ui.TableRow({
              children: [
                Range.to(7).expand((index) => [
                  ui.Container({
                    child: ui.Center({
                      child: TextView({
                        data:
                          this.weekNames[index] +
                          " " +
                          this.listOfDates[index].month.toString() +
                          "/" +
                          this.listOfDates[index].day.toString(),
                        style: new ui.TextStyle({
                          fontSize: 14,
                          color: new ui.Color(0xffffffff),
                        }),
                        className: "x18c hc",
                      }),
                      className: "hc",
                    }),
                    className: "x5ec hc",
                    key: index?.toString(),
                  }),
                ]),
              ],
              className: "hc",
              key: "0",
            }),
            ui.TableRow({
              children: [
                this.listOfDates.expand((weekIndex) => [
                  ui.TableCell({
                    child: DateBoxView({
                      date: weekIndex.date,
                      month: this.date.month,
                      items: this.helper.getEventsForDate(
                        weekIndex,
                        this.categories
                      ),
                      pressedOnEvent: (event) => {
                        this.onPressedEvent(event, weekIndex);
                      },
                      builder: (context, event, viewType) => {
                        return this.props.builder(
                          this.context,
                          event,
                          viewType
                        );
                      },
                      d3eRef: this.dateCell11PopupTargetController.handleRef,
                      className: "hc",
                    }),
                    className: "hc",
                    key: weekIndex?.toString(),
                  }),
                ]),
              ],
              className: "hc",
              key: "1",
            }),
          ],
          className: "xea9 hc h",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "WeekView xa1e hc vc h v"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressedEvent = (event: CalendarEvent, weekIndex: DateTime): void => {
    this.setSelectedEvent(event);

    this.showEventDetailsPopup({ autoClose: true });
  };
  public get onDateSelected(): _WeekViewOnDateSelected {
    return this.props.onDateSelected;
  }
  public dispose(): void {
    this.eventDetailsPopupPopup?.dispose();

    super.dispose();
  }
  public showEventDetailsPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.eventDetailsPopupPopup?.dispose();

    let target: ui.Rect = this.dateCell11PopupTargetController.getTarget(
      this.context
    );

    this.eventDetailsPopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Left,
      child: EventDetailsPopupView({
        event: this.selectedEvent,
        type: CalendarViewType.Month,
        builder: this.props.builder,
        className: "hc vc",
      }),
      target: target,
    });

    this.eventDetailsPopupPopup.showPopup(this.context);
  }
  public hideEventDetailsPopup(): void {
    this.eventDetailsPopupPopup?.dispose();
  }
}
export default function WeekView(props: WeekViewProps) {
  return React.createElement(
    _WeekViewState,
    { ..._WeekViewState.defaultProps, ...props },
    ListWrapper.fromInput<string>(props.categories, "categories")
  );
}
