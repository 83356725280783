import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import TextView from "./TextView";

type _MonthOrYearCellOnTapMonthOrYear = (monthOrYear: string) => void;

type _Container1OnTap = (d3eState: MonthOrYearCellRefs) => void;

export interface MonthOrYearCellProps extends BaseUIProps {
  key?: string;
  monthOrYear: string;
  selectedColor?: ui.Color;
  hoverColor?: ui.Color;
  selectedTextColor?: ui.Color;
  hoverTextColor?: ui.Color;
  disable: boolean;
  onTapMonthOrYear: _MonthOrYearCellOnTapMonthOrYear;
}
/// To store state data for MonthOrYearCell
class MonthOrYearCellRefs {
  public container1: Container1State = new Container1State();
}

interface Container1WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: MonthOrYearCellRefs;
  _onTapcontainer1BehaviourHandler?: _Container1OnTap;
  disable: boolean;
  hoverColor: ui.Color;
  hoverTextColor: ui.Color;
  isSelected: boolean;
  monthOrYear: string;
  selectedColor: ui.Color;
  selectedTextColor: ui.Color;
}

class Container1State extends ObjectObservable {
  private _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _Container1WithState extends ObservableComponent<Container1WithStateProps> {
  public constructor(props: Container1WithStateProps) {
    super(props);

    this.initState();
  }
  public get container1(): Container1State {
    return this.props.d3eState.container1;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public get hoverColor(): ui.Color {
    return this.props.hoverColor;
  }
  public get hoverTextColor(): ui.Color {
    return this.props.hoverTextColor;
  }
  public get isSelected(): boolean {
    return this.props.isSelected;
  }
  public get monthOrYear(): string {
    return this.props.monthOrYear;
  }
  public get selectedColor(): ui.Color {
    return this.props.selectedColor;
  }
  public get selectedTextColor(): ui.Color {
    return this.props.selectedTextColor;
  }
  public get d3eState(): MonthOrYearCellRefs {
    return this.props.d3eState;
  }
  public get _onTapcontainer1BehaviourHandler(): _Container1OnTap {
    return this.props._onTapcontainer1BehaviourHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("container1", null, this.container1);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "container1",
        "container1.hover",
        "disable",
        "hoverColor",
        "hoverTextColor",
        "isSelected",
        "monthOrYear",
        "selectedColor",
        "selectedTextColor",
      ],
      this.rebuild
    );
  }
  public container1OnEnter(event): void {
    return this.container1.setHover(true);
  }
  public container1OnExit(event): void {
    return this.container1.setHover(false);
  }
  public dispose(): void {
    this.container1.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    return ui.Container({
      alignment: ui.Alignment.center,
      states: ui.joinStates(
        {
          "data-c1": this.container1.hover && this.hoverColor === null,
          "data-c3": !this.isSelected,
        },
        {}
      ),
      foregroundDecoration: this.disable
        ? new ui.BoxDecoration({ color: new ui.Color(0x61dddddd) })
        : null,
      decoration:
        this.container1.hover && this.hoverColor !== null
          ? new ui.BoxDecoration({
              color: this.hoverColor,
              borderRadius: ui.BorderRadius.circular(5.0),
            })
          : this.isSelected && this.selectedColor !== null
          ? new ui.BoxDecoration({
              color: this.selectedColor,
              borderRadius: ui.BorderRadius.circular(5.0),
            })
          : new ui.BoxDecoration({}),
      child: TextView({
        data: this.monthOrYear,
        states: ui.joinStates(
          {
            "data-c0": this.isSelected && this.selectedTextColor === null,
            "data-c2": this.container1.hover && this.hoverTextColor === null,
          },
          {}
        ),
        style: new ui.TextStyle({
          fontSize: 13,
          fontFamily: "Roboto",
          color:
            this.isSelected && this.selectedTextColor !== null
              ? this.selectedTextColor
              : this.container1.hover && this.hoverTextColor !== null
              ? this.hoverTextColor
              : new ui.Color(0xff000000),
          fontWeight: ui.FontWeight.bold,
        }),
        className: "x20 hc vc",
      }),
      onEnter: (event) => {
        this.container1OnEnter(event);
      },
      onExit: (event) => {
        this.container1OnExit(event);
      },
      onTap: (e) => {
        e.stopPropagation();

        this._onTapcontainer1BehaviourHandler(this.d3eState);
      },
      className: "x4b2 hc vc",
    });
  }
}
function Container1WithState(props: Container1WithStateProps) {
  return React.createElement(_Container1WithState, props);
}

class _MonthOrYearCellState extends ObservableComponent<MonthOrYearCellProps> {
  static defaultProps = {
    monthOrYear: "",
    selectedColor: null,
    hoverColor: null,
    selectedTextColor: null,
    hoverTextColor: null,
    disable: false,
    onTapMonthOrYear: null,
  };
  d3eState: MonthOrYearCellRefs = new MonthOrYearCellRefs();
  isSelected: boolean = false;
  public constructor(props: MonthOrYearCellProps) {
    super(props);

    this.initState();
  }
  public get monthOrYear(): string {
    return this.props.monthOrYear;
  }
  public get selectedColor(): ui.Color {
    return this.props.selectedColor;
  }
  public get hoverColor(): ui.Color {
    return this.props.hoverColor;
  }
  public get selectedTextColor(): ui.Color {
    return this.props.selectedTextColor;
  }
  public get hoverTextColor(): ui.Color {
    return this.props.hoverTextColor;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "disable",
        "hoverColor",
        "hoverTextColor",
        "isSelected",
        "monthOrYear",
        "selectedColor",
        "selectedTextColor",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: MonthOrYearCellProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.monthOrYear !== this.props.monthOrYear) {
      this.fire("monthOrYear", this);
    }

    if (prevProps.selectedColor !== this.props.selectedColor) {
      this.fire("selectedColor", this);
    }

    if (prevProps.hoverColor !== this.props.hoverColor) {
      this.fire("hoverColor", this);
    }

    if (prevProps.selectedTextColor !== this.props.selectedTextColor) {
      this.fire("selectedTextColor", this);
    }

    if (prevProps.hoverTextColor !== this.props.hoverTextColor) {
      this.fire("hoverTextColor", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }
  }
  public setIsSelected(val: boolean): void {
    let isValChanged: boolean = this.isSelected !== val;

    if (!isValChanged) {
      return;
    }

    this.isSelected = val;

    this.fire("isSelected", this);
  }
  public render(): ReactNode {
    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        Container1WithState({
          d3eState: this.d3eState,
          _onTapcontainer1BehaviourHandler:
            this.onTapcontainer1BehaviourHandler,
          disable: this.disable,
          hoverColor: this.hoverColor,
          hoverTextColor: this.hoverTextColor,
          isSelected: this.isSelected,
          monthOrYear: this.monthOrYear,
          selectedColor: this.selectedColor,
          selectedTextColor: this.selectedTextColor,
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "MonthOrYearCell"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onTapcontainer1BehaviourHandler = (
    d3eState: MonthOrYearCellRefs
  ): void => {
    this.setIsSelected(true);

    this.onTapMonthOrYear(this.monthOrYear);
  };
  public get onTapMonthOrYear(): _MonthOrYearCellOnTapMonthOrYear {
    return this.props.onTapMonthOrYear;
  }
  public get container1() {
    return this.d3eState.container1;
  }
}
export default function MonthOrYearCell(props: MonthOrYearCellProps) {
  return React.createElement(_MonthOrYearCellState, {
    ..._MonthOrYearCellState.defaultProps,
    ...props,
  });
}
