import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import FacilityType from "../models/FacilityType";
import Loader from "./Loader";
import Footer from "./Footer";
import GSDRMSCardView from "./GSDRMSCardView";
import School from "../models/School";
import Banner from "./Banner";
import Renter from "../models/Renter";
import FacilityTypeListRequest from "../models/FacilityTypeListRequest";
import Query from "../classes/Query";
import ListWrapper from "../utils/ListWrapper";
import SchoolsListRequest from "../models/SchoolsListRequest";
import Features from "./Features";
import FacilitiesListRequest from "../models/FacilitiesListRequest";
import TextView from "./TextView";
import BaseUser from "../models/BaseUser";
import Facility from "../models/Facility";
import CollectionUtils from "../utils/CollectionUtils";
import { UsageConstants } from "../rocket/D3ETemplate";

export interface MainPageProps extends BaseUIProps {
  key?: string;
}

class _MainPageState extends ObservableComponent<MainPageProps> {
  facilityTypes: Array<FacilityType> = ListWrapper.widget(
    this,
    "facilityTypes"
  );
  schools: Array<School> = ListWrapper.widget(this, "schools");
  facilities: Array<Facility> = ListWrapper.widget(this, "facilities");
  userLoaded: boolean = false;
  renter: Renter = null;
  public constructor(props: MainPageProps) {
    super(props);

    this.initState();
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.computeFacilityTypes();

    this.computeSchools();

    this.computeFacilities();

    this.on(
      ["facilities", "facilityTypes", "renter", "schools", "userLoaded"],
      this.rebuild
    );
  }
  public setFacilityTypes(val: Array<FacilityType>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.facilityTypes,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("facilityTypes", this.facilityTypes, val);

    this.facilityTypes.clear();

    this.facilityTypes.addAll(val);

    this.fire("facilityTypes", this);
  }
  public addToFacilityTypes(val: FacilityType, index: number = -1): void {
    if (index === -1) {
      if (!this.facilityTypes.contains(val)) this.facilityTypes.add(val);
    } else {
      this.facilityTypes.remove(this.facilityTypes.elementAt(index));

      this.facilityTypes.add(val);
    }

    this.fire("facilityTypes", this, val, true);

    this.updateObservable("facilityTypes", null, val);
  }
  public removeFromFacilityTypes(val: FacilityType): void {
    this.facilityTypes.remove(val);

    this.fire("facilityTypes", this, val, false);

    this.removeObservable("facilityTypes", val);
  }
  public computeFacilityTypes = async (): Promise<void> => {
    try {
      this.setFacilityTypes(
        Array.from(
          (
            await Query.get().getFacilityTypeList(
              UsageConstants.QUERY_GETFACILITYTYPELIST_MAINPAGE_PROPERTIES_FACILITYTYPES_COMPUTATION,
              new FacilityTypeListRequest({ pageSize: 100, offset: 0 })
            )
          )?.items ?? []
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeFacilityTypes : " + exception.toString()
      );

      this.setFacilityTypes([]);
    }
  };
  public setSchools(val: Array<School>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.schools, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("schools", this.schools, val);

    this.schools.clear();

    this.schools.addAll(val);

    this.fire("schools", this);
  }
  public addToSchools(val: School, index: number = -1): void {
    if (index === -1) {
      if (!this.schools.contains(val)) this.schools.add(val);
    } else {
      this.schools.remove(this.schools.elementAt(index));

      this.schools.add(val);
    }

    this.fire("schools", this, val, true);

    this.updateObservable("schools", null, val);
  }
  public removeFromSchools(val: School): void {
    this.schools.remove(val);

    this.fire("schools", this, val, false);

    this.removeObservable("schools", val);
  }
  public computeSchools = async (): Promise<void> => {
    try {
      this.setSchools(
        Array.from(
          (
            await Query.get().getSchoolsList(
              UsageConstants.QUERY_GETSCHOOLSLIST_MAINPAGE_PROPERTIES_SCHOOLS_COMPUTATION,
              new SchoolsListRequest({ pageSize: 1000, offset: 0 })
            )
          )?.items ?? []
        )
      );
    } catch (exception) {
      console.log(" exception in computeSchools : " + exception.toString());

      this.setSchools([]);
    }
  };
  public setFacilities(val: Array<Facility>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.facilities,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("facilities", this.facilities, val);

    this.facilities.clear();

    this.facilities.addAll(val);

    this.fire("facilities", this);
  }
  public addToFacilities(val: Facility, index: number = -1): void {
    if (index === -1) {
      if (!this.facilities.contains(val)) this.facilities.add(val);
    } else {
      this.facilities.remove(this.facilities.elementAt(index));

      this.facilities.add(val);
    }

    this.fire("facilities", this, val, true);

    this.updateObservable("facilities", null, val);
  }
  public removeFromFacilities(val: Facility): void {
    this.facilities.remove(val);

    this.fire("facilities", this, val, false);

    this.removeObservable("facilities", val);
  }
  public computeFacilities = async (): Promise<void> => {
    try {
      this.setFacilities(
        Array.from(
          (
            await Query.get().getFacilitiesList(
              UsageConstants.QUERY_GETFACILITIESLIST_MAINPAGE_PROPERTIES_FACILITIES_COMPUTATION,
              new FacilitiesListRequest({ pageSize: 1000, offset: 0 })
            )
          )?.items ?? []
        )
      );
    } catch (exception) {
      console.log(" exception in computeFacilities : " + exception.toString());

      this.setFacilities([]);
    }
  };
  public setUserLoaded(val: boolean): void {
    let isValChanged: boolean = this.userLoaded !== val;

    if (!isValChanged) {
      return;
    }

    this.userLoaded = val;

    this.fire("userLoaded", this);
  }
  public setRenter(val: Renter): void {
    let isValChanged: boolean = this.renter !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("renter", this.renter, val);

    this.renter = val;

    this.fire("renter", this);
  }
  public render(): ReactNode {
    return this.userLoaded
      ? ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            Banner({
              facilityTypes: this.facilityTypes,
              schools: this.schools,
              renter: this.renter,
              className: "x2f7 hc h",
              key: "0",
            }),
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.Column({
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    TextView({
                      data: "Facilities for rent",
                      style: new ui.TextStyle({
                        fontSize: 35,
                        fontWeight: ui.FontWeight.w600,
                      }),
                      className: "x0b4 hc",
                      key: "0",
                    }),
                    TextView({
                      data: "We feature a variety of auditoriums, gyms, classrooms, and other venue options available for community use.",
                      softWrap: true,
                      style: new ui.TextStyle({ fontSize: 16 }),
                      className: "x4c0 hc",
                      key: "1",
                    }),
                  ],
                  className: "hc",
                  key: "0",
                }),
                ui.Column({
                  children: [
                    ui.Wrap({
                      spacing: 10,
                      runSpacing: 10,
                      alignment: ui.WrapAlignment.center,
                      children: [
                        this.facilityTypes.expand((item) => [
                          GSDRMSCardView({
                            facilityType: item,
                            facility: this.facilities,
                            className: "hc",
                            key: item?.ident,
                          }),
                        ]),
                      ],
                      className: "hc",
                      key: "0",
                    }),
                  ],
                  className: "xe66 hc",
                  key: "1",
                }),
              ],
              className: "xd10 hc",
              key: "1",
            }),
            Features({ className: "x810 hc h", key: "2" }),
            Footer({ className: "x174 hc h", key: "3" }),
          ],
          className: ui.join(this.props.className, "MainPage x46b hc vc"),
          ...copyBaseUIProps(this.props),
        })
      : ui.Column({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                Loader({ key: "0" }),
                TextView({
                  data: "Loading App......",
                  className: "x9dc",
                  key: "1",
                }),
              ],
              className: "x011 hc h",
              key: "0",
            }),
          ],
          className: ui.join(this.props.className, "MainPage hc vc"),
          ...copyBaseUIProps(this.props),
        });
  }
  public onInit = async (): Promise<void> => {
    let baseUser: BaseUser = await Query.get().currentUser();

    if (baseUser !== null) {
      //  It must be EndUser as we dont have other user types now

      let user: Renter = await Query.get().getRenterById(
        UsageConstants.QUERY_LOADRENTER_MAINPAGE_EVENTHANDLERS_ONINIT_BLOCK,
        (baseUser as Renter).id
      );

      if (user !== null && user.active) {
        this.setRenter(user);
      }
    }

    this.setUserLoaded(true);
  };
}
export default function MainPage(props: MainPageProps) {
  return React.createElement(_MainPageState, props);
}
