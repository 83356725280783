import DateTime from "../core/DateTime";
import DFile from "../classes/DFile";
import Renter from "./Renter";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";
import RentalRequest from "./RentalRequest";

export default class RequestInsurance extends DBObject {
  private static readonly _ATTACHMENT: number = 0;
  private static readonly _CREATEDBY: number = 1;
  private static readonly _CREATEDDATE: number = 2;
  private static readonly _REQUEST: number = 3;
  public id: number = 0;
  public otherMaster: DBObject;
  private _request: RentalRequest = null;
  private _createdBy: Renter = null;
  private _attachment: DFile = null;
  private _createdDate: DateTime = null;
  public constructor(
    d3eParams?: Partial<{
      attachment: DFile;
      createdBy: Renter;
      createdDate: DateTime;
      request: RentalRequest;
    }>
  ) {
    super();

    this.setAttachment(d3eParams?.attachment ?? null);

    this.setCreatedBy(d3eParams?.createdBy ?? null);

    this.setCreatedDate(d3eParams?.createdDate ?? null);

    this.setRequest(d3eParams?.request ?? null);
  }
  public get d3eType(): string {
    return "RequestInsurance";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get request(): RentalRequest {
    return this._request;
  }
  public setRequest(val: RentalRequest): void {
    let isValChanged: boolean = this._request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RequestInsurance._REQUEST, this._request);

    this.updateObservable("request", this._request, val);

    this._request = val;

    this.fire("request", this);
  }
  public get createdBy(): Renter {
    return this._createdBy;
  }
  public setCreatedBy(val: Renter): void {
    let isValChanged: boolean = this._createdBy !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RequestInsurance._CREATEDBY, this._createdBy);

    this.updateObservable("createdBy", this._createdBy, val);

    this._createdBy = val;

    this.fire("createdBy", this);
  }
  public get attachment(): DFile {
    return this._attachment;
  }
  public setAttachment(val: DFile): void {
    let isValChanged: boolean = this._attachment !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RequestInsurance._ATTACHMENT, this._attachment);

    this._attachment = val;

    this.fire("attachment", this);
  }
  public get createdDate(): DateTime {
    return this._createdDate;
  }
  public setCreatedDate(val: DateTime): void {
    let isValChanged: boolean = this._createdDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(RequestInsurance._CREATEDDATE, this._createdDate);

    this._createdDate = val;

    this.fire("createdDate", this);
  }
  public get(field: number): any {
    switch (field) {
      case RequestInsurance._REQUEST: {
        return this._request;
      }

      case RequestInsurance._CREATEDBY: {
        return this._createdBy;
      }

      case RequestInsurance._ATTACHMENT: {
        return this._attachment;
      }

      case RequestInsurance._CREATEDDATE: {
        return this._createdDate;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): RequestInsurance {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: RequestInsurance = dbObj as RequestInsurance;

    obj.id = this.id;

    obj.setRequest(this._request);

    obj.setCreatedBy(this._createdBy);

    obj.setAttachment(this._attachment);

    obj.setCreatedDate(this._createdDate);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case RequestInsurance._REQUEST: {
        this.setRequest(value as RentalRequest);
        break;
      }

      case RequestInsurance._CREATEDBY: {
        this.setCreatedBy(value as Renter);
        break;
      }

      case RequestInsurance._ATTACHMENT: {
        this.setAttachment(value as DFile);
        break;
      }

      case RequestInsurance._CREATEDDATE: {
        this.setCreatedDate(value as DateTime);
        break;
      }
    }
  }
}
