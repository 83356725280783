import * as ui from "../native";
import React, { ReactNode } from "react";
import { DomUtils } from "../utils/DomUtils";
import { BuildContext } from "../classes/BuildContext";

export class StyleThemeData {
  public readonly themeName: string;
  public readonly color: ui.Color;
  public readonly textStyle: ui.TextStyle;
  public readonly tooltipBackgroundColor: ui.Color;
  public readonly tooltipTextColor: ui.Color;
  public static D3ELight: StyleThemeData;
  public readonly c1: ui.Color;
  public readonly c2: ui.Color;
  public readonly c3: ui.Color;
  public readonly c4: ui.Color;
  public readonly c5: ui.Color;
  public readonly c6: ui.Color;
  public readonly c7: ui.Color;
  public readonly c8: ui.Color;
  public readonly c9: ui.Color;
  public readonly c10: ui.Color;
  public readonly c11: ui.Color;
  public readonly c12: ui.Color;
  public readonly c13: ui.Color;
  public readonly c14: ui.Color;
  public readonly c15: ui.Color;
  public readonly c16: ui.Color;
  public readonly c17: ui.Color;
  public readonly c18: ui.Color;
  public readonly c19: ui.Color;
  public readonly c20: ui.Color;
  public readonly c21: ui.Color;
  public readonly c22: ui.Color;
  public readonly c23: ui.Color;
  public readonly c24: ui.Color;
  public readonly c25: ui.Color;
  public readonly c26: ui.Color;
  public readonly c27: ui.Color;
  public readonly c28: ui.Color;
  public readonly c29: ui.Color;
  public readonly c30: ui.Color;
  public readonly c31: ui.Color;
  public readonly c32: ui.Color;
  public readonly c33: ui.Color;
  public readonly c34: ui.Color;
  public readonly c35: ui.Color;
  public readonly c36: ui.Color;
  public readonly c37: ui.Color;
  public readonly tTextViewBlue11ColorOn: ui.Color;
  public readonly tTextViewBlue11FontSizeOn: number;
  public readonly tButtonCalendarButtonDecorationOn: ui.BoxDecoration;
  public readonly tButtonCalendarButtonPaddingOn: ui.EdgeInsets;
  public readonly tButtonCalendarButtonTextStyleOn: ui.TextStyle;
  public readonly tButtonCalendarButtonDecorationOnHover: ui.BoxDecoration;
  public readonly tTableCalendarTableBorderOn: ui.TableBorder;
  public readonly tColumnBannerFormBGPaddingOn: ui.EdgeInsets;
  public readonly tColumnBannerFormBGDecorationOn: ui.BoxDecoration;
  public readonly tTextViewBlueColorOn: ui.Color;
  public readonly tTextViewBlueFontSizeOn: number;
  public readonly tButtonDangerDecorationOn: ui.BoxDecoration;
  public readonly tButtonDangerPaddingOn: ui.EdgeInsets;
  public readonly tButtonDangerConstraintsOn: ui.BoxConstraints;
  public readonly tButtonDangerTextStyleOn: ui.TextStyle;
  public readonly tButtonDangerDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonDangerPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonDangerConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonDangerTextStyleOnHover: ui.TextStyle;
  public readonly tButtonDangerOutlineDecorationOn: ui.BoxDecoration;
  public readonly tButtonDangerOutlinePaddingOn: ui.EdgeInsets;
  public readonly tButtonDangerOutlineTextStyleOn: ui.TextStyle;
  public readonly tButtonDangerOutlineConstraintsOn: ui.BoxConstraints;
  public readonly tButtonDangerOutlinePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonDangerOutlineDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonDangerOutlineConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonDangerOutlineTextStyleOnHover: ui.TextStyle;
  public readonly tButtonDarkDecorationOn: ui.BoxDecoration;
  public readonly tButtonDarkPaddingOn: ui.EdgeInsets;
  public readonly tButtonDarkConstraintsOn: ui.BoxConstraints;
  public readonly tButtonDarkTextStyleOn: ui.TextStyle;
  public readonly tButtonDarkDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonDarkPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonDarkConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonDarkTextStyleOnHover: ui.TextStyle;
  public readonly tButtonDarkOutlineDecorationOn: ui.BoxDecoration;
  public readonly tButtonDarkOutlinePaddingOn: ui.EdgeInsets;
  public readonly tButtonDarkOutlineTextStyleOn: ui.TextStyle;
  public readonly tButtonDarkOutlineConstraintsOn: ui.BoxConstraints;
  public readonly tButtonDarkOutlinePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonDarkOutlineDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonDarkOutlineConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonDarkOutlineTextStyleOnHover: ui.TextStyle;
  public readonly tInputFieldDefaultHeightOn: number;
  public readonly tInputFieldDefaultBackgroundColorOn: ui.Color;
  public readonly tInputFieldDefaultCornerRadiusOn: number;
  public readonly tInputFieldDefaultStyleOn: ui.TextStyle;
  public readonly tListTileDefaultDecorationOnHover: ui.BoxDecoration;
  public readonly tListTileDefaultDecorationOnFocused: ui.BoxDecoration;
  public readonly tListTileDefaultDecorationOnSelected: ui.BoxDecoration;
  public readonly tDropDownDefaultDropDownDecorationOn: ui.BoxDecoration;
  public readonly tDropDownDefaultPlaceHolderColorOn: ui.Color;
  public readonly tCardDefaultColorOn: ui.Color;
  public readonly tCardDefaultDecorationOn: ui.BoxDecoration;
  public readonly tTabBarDefaultBackgroundColorOn: ui.Color;
  public readonly tTabBarDefaultActiveColorOn: ui.Color;
  public readonly tDateFieldDefaultHeightOn: number;
  public readonly tDateFieldDefaultCornerRadiusOn: number;
  public readonly tTimeFieldDefaultHeightOn: number;
  public readonly tTimeFieldDefaultCornerRadiusOn: number;
  public readonly tDateTimeFieldDefaultHeightOn: number;
  public readonly tDateTimeFieldDefaultCornerRadiusOn: number;
  public readonly tIntegerInputFieldDefaultHeightOn: number;
  public readonly tIntegerInputFieldDefaultBackgroundColorOn: ui.Color;
  public readonly tIntegerInputFieldDefaultCornerRadiusOn: number;
  public readonly tIntegerInputFieldDefaultPaddingOn: ui.EdgeInsets;
  public readonly tDoubleInputFieldDefaultHeightOn: number;
  public readonly tDoubleInputFieldDefaultBackgroundColorOn: ui.Color;
  public readonly tDoubleInputFieldDefaultCornerRadiusOn: number;
  public readonly tDoubleInputFieldDefaultPaddingOn: ui.EdgeInsets;
  public readonly tBaseDefaultPopUpDecorationOn: ui.BoxDecoration;
  public readonly tButtonDemoButtonDecorationOn: ui.BoxDecoration;
  public readonly tButtonDemoButtonTextStyleOn: ui.TextStyle;
  public readonly tButtonDemoButtonPaddingOn: ui.EdgeInsets;
  public readonly tInputFieldDisabledHeightOn: number;
  public readonly tInputFieldDisabledBackgroundColorOn: ui.Color;
  public readonly tInputFieldDisabledCornerRadiusOn: number;
  public readonly tInputFieldDisabledStyleOn: ui.TextStyle;
  public readonly tInputFieldDisabledDisableOn: boolean;
  public readonly tTextViewDisplayHeadingFourFontSizeOn: number;
  public readonly tTextViewDisplayHeadingFourMarginOn: ui.EdgeInsets;
  public readonly tTextViewDisplayHeadingOneFontSizeOn: number;
  public readonly tTextViewDisplayHeadingOneMarginOn: ui.EdgeInsets;
  public readonly tTextViewDisplayHeadingThreeFontSizeOn: number;
  public readonly tTextViewDisplayHeadingThreeMarginOn: ui.EdgeInsets;
  public readonly tTextViewDisplayHeadingTwoFontSizeOn: number;
  public readonly tTextViewDisplayHeadingTwoMarginOn: ui.EdgeInsets;
  public readonly tTextViewDropDownBuilderTextColorOn: ui.Color;
  public readonly tTextViewDropDownBuilderTextPaddingOn: ui.EdgeInsets;
  public readonly tDropDownDropdownWithAllBordersHeightOn: number;
  public readonly tDropDownDropdownWithAllBordersViewDecorationOn: ui.BoxDecoration;
  public readonly tDropDownDropdownWithAllBordersDropDownDecorationOn: ui.BoxDecoration;
  public readonly tDropDownDropdownWithAllBordersPlaceHolderColorOn: ui.Color;
  public readonly tTextViewErrorColorOn: ui.Color;
  public readonly tTextViewErrorTextAlignOn: ui.TextAlign;
  public readonly tTextViewErrorMarginOn: ui.EdgeInsets;
  public readonly tTextViewErrorLabelColorOn: ui.Color;
  public readonly tTextViewErrorLabelFontFamilyOn: string;
  public readonly tTextViewErrorLabelFontSizeOn: number;
  public readonly tTextViewErrorLabelMarginOn: ui.EdgeInsets;
  public readonly tTextViewErrorTextColorOn: ui.Color;
  public readonly tBaseFieldViewMarginOn: ui.EdgeInsets;
  public readonly tTextViewHeadingFontSizeOn: number;
  public readonly tTextViewHeadingFontWeightOn: ui.FontWeight;
  public readonly tTextViewHeadingTextAlignOn: ui.TextAlign;
  public readonly tTextViewHeadingColorOn: ui.Color;
  public readonly tTextViewHeadingFontFamilyOn: string;
  public readonly tTextViewHeadlineFiveFontSizeOn: number;
  public readonly tTextViewHeadlineFiveFontWeightOn: ui.FontWeight;
  public readonly tTextViewHeadlineFourFontSizeOn: number;
  public readonly tTextViewHeadlineFourFontWeightOn: ui.FontWeight;
  public readonly tTextViewHeadlineOneFontSizeOn: number;
  public readonly tTextViewHeadlineOneFontWeightOn: ui.FontWeight;
  public readonly tTextViewHeadlineSixFontSizeOn: number;
  public readonly tTextViewHeadlineSixFontWeightOn: ui.FontWeight;
  public readonly tTextViewHeadlineThreeFontSizeOn: number;
  public readonly tTextViewHeadlineThreeFontWeightOn: ui.FontWeight;
  public readonly tTextViewHeadlineTwoFontSizeOn: number;
  public readonly tTextViewHeadlineTwoFontWeightOn: ui.FontWeight;
  public readonly tTextViewLabelColorOn: ui.Color;
  public readonly tTextViewLabelTextAlignOn: ui.TextAlign;
  public readonly tTextViewLabelMarginOn: ui.EdgeInsets;
  public readonly tTextViewLabelTextColorOn: ui.Color;
  public readonly tTextViewLabelTextTextAlignOn: ui.TextAlign;
  public readonly tTextViewLabelTextFontSizeOn: number;
  public readonly tTextViewLabelTextFontWeightOn: ui.FontWeight;
  public readonly tInputFieldLargeHeightOn: number;
  public readonly tInputFieldLargeBackgroundColorOn: ui.Color;
  public readonly tInputFieldLargeCornerRadiusOn: number;
  public readonly tInputFieldLargeStyleOn: ui.TextStyle;
  public readonly tButtonLightDecorationOn: ui.BoxDecoration;
  public readonly tButtonLightPaddingOn: ui.EdgeInsets;
  public readonly tButtonLightConstraintsOn: ui.BoxConstraints;
  public readonly tButtonLightTextStyleOn: ui.TextStyle;
  public readonly tButtonLightDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonLightPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonLightConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonLightTextStyleOnHover: ui.TextStyle;
  public readonly tBaseLightColorWithMarginDecorationOn: ui.BoxDecoration;
  public readonly tBaseLightColorWithMarginMarginOn: ui.EdgeInsets;
  public readonly tButtonLightOutlineDecorationOn: ui.BoxDecoration;
  public readonly tButtonLightOutlinePaddingOn: ui.EdgeInsets;
  public readonly tButtonLightOutlineTextStyleOn: ui.TextStyle;
  public readonly tButtonLightOutlineConstraintsOn: ui.BoxConstraints;
  public readonly tButtonLightOutlinePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonLightOutlineDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonLightOutlineConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonLightOutlineTextStyleOnHover: ui.TextStyle;
  public readonly tButtonLinkTextStyleOn: ui.TextStyle;
  public readonly tButtonLinkPaddingOn: ui.EdgeInsets;
  public readonly tButtonLinkConstraintsOn: ui.BoxConstraints;
  public readonly tButtonLinkDecorationOn: ui.BoxDecoration;
  public readonly tButtonLinkPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonLinkTextStyleOnHover: ui.TextStyle;
  public readonly tButtonLinkDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonLinkConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonLinkButtonTextStyleOn: ui.TextStyle;
  public readonly tButtonLinkButtonTextStyleOnHover: ui.TextStyle;
  public readonly tCellButtonLinkCellButtonTextStyleOn: ui.TextStyle;
  public readonly tCellButtonLinkCellButtonPaddingOn: ui.EdgeInsets;
  public readonly tCellButtonLinkCellButtonTextStyleOnHover: ui.TextStyle;
  public readonly tCellButtonLinkCellButtonPaddingOnHover: ui.EdgeInsets;
  public readonly tListTileListTileDecorationOnHover: ui.BoxDecoration;
  public readonly tListTileListTileDecorationOnFocused: ui.BoxDecoration;
  public readonly tListTileListTileDecorationOnSelected: ui.BoxDecoration;
  public readonly tListTileListTileDecorationOn: ui.BoxDecoration;
  public readonly tBaseListViewBgColorDecorationOn: ui.BoxDecoration;
  public readonly tBaseListViewBgColorMarginOn: ui.EdgeInsets;
  public readonly tTextViewNameTextColorOn: ui.Color;
  public readonly tTextViewNameTextFontWeightOn: ui.FontWeight;
  public readonly tTextViewNameTextFontFamilyOn: string;
  public readonly tTextViewNameTextFontSizeOn: number;
  public readonly tBaseP1PaddingOn: ui.EdgeInsets;
  public readonly tBaseP15PaddingOn: ui.EdgeInsets;
  public readonly tButtonPrimaryDecorationOn: ui.BoxDecoration;
  public readonly tButtonPrimaryPaddingOn: ui.EdgeInsets;
  public readonly tButtonPrimaryTextStyleOn: ui.TextStyle;
  public readonly tButtonPrimaryConstraintsOn: ui.BoxConstraints;
  public readonly tButtonPrimaryDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonPrimaryPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonPrimaryConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonPrimaryTextStyleOnHover: ui.TextStyle;
  public readonly tButtonPrimaryButtonDecorationOn: ui.BoxDecoration;
  public readonly tButtonPrimaryButtonTextStyleOn: ui.TextStyle;
  public readonly tButtonPrimaryButtonPaddingOn: ui.EdgeInsets;
  public readonly tButtonPrimaryNewDecorationOn: ui.BoxDecoration;
  public readonly tButtonPrimaryNewPaddingOn: ui.EdgeInsets;
  public readonly tButtonPrimaryNewTextStyleOn: ui.TextStyle;
  public readonly tButtonPrimaryNewDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonPrimaryNewPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonPrimaryNewTextStyleOnHover: ui.TextStyle;
  public readonly tButtonPrimaryOrangeDecorationOn: ui.BoxDecoration;
  public readonly tButtonPrimaryOrangePaddingOn: ui.EdgeInsets;
  public readonly tButtonPrimaryOrangeConstraintsOn: ui.BoxConstraints;
  public readonly tButtonPrimaryOrangeTextStyleOn: ui.TextStyle;
  public readonly tButtonPrimaryOrangePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonPrimaryOrangeDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonPrimaryOrangeTextStyleOnHover: ui.TextStyle;
  public readonly tButtonPrimaryOrangeConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonPrimaryOrangeOutlineDecorationOn: ui.BoxDecoration;
  public readonly tButtonPrimaryOrangeOutlinePaddingOn: ui.EdgeInsets;
  public readonly tButtonPrimaryOrangeOutlineTextStyleOn: ui.TextStyle;
  public readonly tButtonPrimaryOrangeOutlineConstraintsOn: ui.BoxConstraints;
  public readonly tButtonPrimaryOrangeOutlinePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonPrimaryOrangeOutlineDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonPrimaryOrangeOutlineConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonPrimaryOrangeOutlineTextStyleOnHover: ui.TextStyle;
  public readonly tButtonPrimaryOutlineDecorationOn: ui.BoxDecoration;
  public readonly tButtonPrimaryOutlinePaddingOn: ui.EdgeInsets;
  public readonly tButtonPrimaryOutlineTextStyleOn: ui.TextStyle;
  public readonly tButtonPrimaryOutlineConstraintsOn: ui.BoxConstraints;
  public readonly tButtonPrimaryOutlinePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonPrimaryOutlineDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonPrimaryOutlineConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonPrimaryOutlineTextStyleOnHover: ui.TextStyle;
  public readonly tButtonRentalPrimaryButtonDecorationOn: ui.BoxDecoration;
  public readonly tButtonRentalPrimaryButtonPaddingOn: ui.EdgeInsets;
  public readonly tButtonRentalPrimaryButtonTextStyleOn: ui.TextStyle;
  public readonly tButtonRentalPrimaryButtonDecorationOnSelected: ui.BoxDecoration;
  public readonly tButtonRentalSecondaryButtonDecorationOn: ui.BoxDecoration;
  public readonly tButtonRentalSecondaryButtonTextStyleOn: ui.TextStyle;
  public readonly tButtonRentalSecondaryButtonPaddingOn: ui.EdgeInsets;
  public readonly tDropDownRoundRadiusDropDownHeightOn: number;
  public readonly tDropDownRoundRadiusDropDownOnHoverColorOn: ui.Color;
  public readonly tDropDownRoundRadiusDropDownPlaceHolderColorOn: ui.Color;
  public readonly tDropDownRoundRadiusDropDownDropDownDecorationOn: ui.BoxDecoration;
  public readonly tDropDownRoundRadiusDropDownViewDecorationOn: ui.BoxDecoration;
  public readonly tBaseScreenBgColorMarginOn: ui.EdgeInsets;
  public readonly tInputFieldSearchInputfieldInActiveColorOn: ui.Color;
  public readonly tInputFieldSearchInputfieldPlaceHolderColorOn: ui.Color;
  public readonly tInputFieldSearchInputfieldActiveColorOn: ui.Color;
  public readonly tInputFieldSearchInputfieldPaddingOn: ui.EdgeInsets;
  public readonly tButtonSecondaryDecorationOn: ui.BoxDecoration;
  public readonly tButtonSecondaryPaddingOn: ui.EdgeInsets;
  public readonly tButtonSecondaryConstraintsOn: ui.BoxConstraints;
  public readonly tButtonSecondaryTextStyleOn: ui.TextStyle;
  public readonly tButtonSecondaryDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonSecondaryPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonSecondaryConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonSecondaryTextStyleOnHover: ui.TextStyle;
  public readonly tButtonSecondaryNewPaddingOn: ui.EdgeInsets;
  public readonly tButtonSecondaryNewDecorationOn: ui.BoxDecoration;
  public readonly tButtonSecondaryNewTextStyleOn: ui.TextStyle;
  public readonly tButtonSecondaryNewDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonSecondaryNewPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonSecondaryNewTextStyleOnHover: ui.TextStyle;
  public readonly tButtonSecondaryOutlineDecorationOn: ui.BoxDecoration;
  public readonly tButtonSecondaryOutlinePaddingOn: ui.EdgeInsets;
  public readonly tButtonSecondaryOutlineTextStyleOn: ui.TextStyle;
  public readonly tButtonSecondaryOutlineConstraintsOn: ui.BoxConstraints;
  public readonly tButtonSecondaryOutlinePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonSecondaryOutlineDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonSecondaryOutlineConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonSecondaryOutlineTextStyleOnHover: ui.TextStyle;
  public readonly tInputFieldSmallHeightOn: number;
  public readonly tInputFieldSmallBackgroundColorOn: ui.Color;
  public readonly tInputFieldSmallCornerRadiusOn: number;
  public readonly tInputFieldSmallStyleOn: ui.TextStyle;
  public readonly tTextViewSubHeaderColorOn: ui.Color;
  public readonly tTextViewSubHeaderFontFamilyOn: string;
  public readonly tTextViewSubHeaderPaddingOn: ui.EdgeInsets;
  public readonly tTextViewSubHeader1ColorOn: ui.Color;
  public readonly tTextViewSubHeader1FontWeightOn: ui.FontWeight;
  public readonly tTextViewSubHeader1FontFamilyOn: string;
  public readonly tTextViewSubHeader1PaddingOn: ui.EdgeInsets;
  public readonly tButtonSuccessDecorationOn: ui.BoxDecoration;
  public readonly tButtonSuccessPaddingOn: ui.EdgeInsets;
  public readonly tButtonSuccessConstraintsOn: ui.BoxConstraints;
  public readonly tButtonSuccessTextStyleOn: ui.TextStyle;
  public readonly tButtonSuccessDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonSuccessPaddingOnHover: ui.EdgeInsets;
  public readonly tButtonSuccessConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonSuccessTextStyleOnHover: ui.TextStyle;
  public readonly tButtonSuccessOutlineDecorationOn: ui.BoxDecoration;
  public readonly tButtonSuccessOutlinePaddingOn: ui.EdgeInsets;
  public readonly tButtonSuccessOutlineTextStyleOn: ui.TextStyle;
  public readonly tButtonSuccessOutlineConstraintsOn: ui.BoxConstraints;
  public readonly tButtonSuccessOutlinePaddingOnHover: ui.EdgeInsets;
  public readonly tButtonSuccessOutlineDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonSuccessOutlineConstraintsOnHover: ui.BoxConstraints;
  public readonly tButtonSuccessOutlineTextStyleOnHover: ui.TextStyle;
  public readonly tTextViewTableCellTextFontSizeOn: number;
  public readonly tTextViewTableCellTextColorOn: ui.Color;
  public readonly tTextViewTableCellTextFontFamilyOn: string;
  public readonly tTextViewTableHeaderColorOn: ui.Color;
  public readonly tTextViewTableHeaderFontFamilyOn: string;
  public readonly tTextViewTableHeaderFontWeightOn: ui.FontWeight;
  public readonly tTableRowTableRowOnebgDecorationOn: ui.BoxDecoration;
  public readonly tTableRowTableRowOnebgPaddingOn: ui.EdgeInsets;
  public readonly tTableRowTableRowTwobgDecorationOn: ui.BoxDecoration;
  public readonly tTableRowTableRowTwobgPaddingOn: ui.EdgeInsets;
  public readonly tButtonTextSideBarTextStyleOn: ui.TextStyle;
  public readonly tButtonWarningDecorationOn: ui.BoxDecoration;
  public readonly tButtonWarningConstraintsOn: ui.BoxConstraints;
  public readonly tButtonWarningTextStyleOn: ui.TextStyle;
  public readonly tButtonWarningDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonWarningTextStyleOnHover: ui.TextStyle;
  public readonly tButtonWarningOutlineDecorationOn: ui.BoxDecoration;
  public readonly tButtonWarningOutlineTextStyleOn: ui.TextStyle;
  public readonly tButtonWarningOutlineConstraintsOn: ui.BoxConstraints;
  public readonly tButtonWarningOutlineDecorationOnHover: ui.BoxDecoration;
  public readonly tButtonWarningOutlineTextStyleOnHover: ui.TextStyle;
  public readonly tButtonWhitePrimaryNewDecorationOn: ui.BoxDecoration;
  public readonly tButtonWhitePrimaryNewPaddingOn: ui.EdgeInsets;
  public readonly tButtonWhitePrimaryNewTextStyleOn: ui.TextStyle;
  public static current: StyleThemeData;
  public constructor(
    d3eParams?: Partial<{
      themeName: string;
      color: ui.Color;
      textStyle: ui.TextStyle;
      tooltipBackgroundColor: ui.Color;
      tooltipTextColor: ui.Color;
      tTextViewBlue11ColorOn: ui.Color;
      tTextViewBlue11FontSizeOn: number;
      tButtonCalendarButtonDecorationOn: ui.BoxDecoration;
      tButtonCalendarButtonPaddingOn: ui.EdgeInsets;
      tButtonCalendarButtonTextStyleOn: ui.TextStyle;
      tButtonCalendarButtonDecorationOnHover: ui.BoxDecoration;
      tTableCalendarTableBorderOn: ui.TableBorder;
      tColumnBannerFormBGPaddingOn: ui.EdgeInsets;
      tColumnBannerFormBGDecorationOn: ui.BoxDecoration;
      tTextViewBlueColorOn: ui.Color;
      tTextViewBlueFontSizeOn: number;
      tButtonDangerDecorationOn: ui.BoxDecoration;
      tButtonDangerPaddingOn: ui.EdgeInsets;
      tButtonDangerConstraintsOn: ui.BoxConstraints;
      tButtonDangerTextStyleOn: ui.TextStyle;
      tButtonDangerDecorationOnHover: ui.BoxDecoration;
      tButtonDangerPaddingOnHover: ui.EdgeInsets;
      tButtonDangerConstraintsOnHover: ui.BoxConstraints;
      tButtonDangerTextStyleOnHover: ui.TextStyle;
      tButtonDangerOutlineDecorationOn: ui.BoxDecoration;
      tButtonDangerOutlinePaddingOn: ui.EdgeInsets;
      tButtonDangerOutlineTextStyleOn: ui.TextStyle;
      tButtonDangerOutlineConstraintsOn: ui.BoxConstraints;
      tButtonDangerOutlinePaddingOnHover: ui.EdgeInsets;
      tButtonDangerOutlineDecorationOnHover: ui.BoxDecoration;
      tButtonDangerOutlineConstraintsOnHover: ui.BoxConstraints;
      tButtonDangerOutlineTextStyleOnHover: ui.TextStyle;
      tButtonDarkDecorationOn: ui.BoxDecoration;
      tButtonDarkPaddingOn: ui.EdgeInsets;
      tButtonDarkConstraintsOn: ui.BoxConstraints;
      tButtonDarkTextStyleOn: ui.TextStyle;
      tButtonDarkDecorationOnHover: ui.BoxDecoration;
      tButtonDarkPaddingOnHover: ui.EdgeInsets;
      tButtonDarkConstraintsOnHover: ui.BoxConstraints;
      tButtonDarkTextStyleOnHover: ui.TextStyle;
      tButtonDarkOutlineDecorationOn: ui.BoxDecoration;
      tButtonDarkOutlinePaddingOn: ui.EdgeInsets;
      tButtonDarkOutlineTextStyleOn: ui.TextStyle;
      tButtonDarkOutlineConstraintsOn: ui.BoxConstraints;
      tButtonDarkOutlinePaddingOnHover: ui.EdgeInsets;
      tButtonDarkOutlineDecorationOnHover: ui.BoxDecoration;
      tButtonDarkOutlineConstraintsOnHover: ui.BoxConstraints;
      tButtonDarkOutlineTextStyleOnHover: ui.TextStyle;
      tInputFieldDefaultHeightOn: number;
      tInputFieldDefaultBackgroundColorOn: ui.Color;
      tInputFieldDefaultCornerRadiusOn: number;
      tInputFieldDefaultStyleOn: ui.TextStyle;
      tListTileDefaultDecorationOnHover: ui.BoxDecoration;
      tListTileDefaultDecorationOnFocused: ui.BoxDecoration;
      tListTileDefaultDecorationOnSelected: ui.BoxDecoration;
      tDropDownDefaultDropDownDecorationOn: ui.BoxDecoration;
      tDropDownDefaultPlaceHolderColorOn: ui.Color;
      tCardDefaultColorOn: ui.Color;
      tCardDefaultDecorationOn: ui.BoxDecoration;
      tTabBarDefaultBackgroundColorOn: ui.Color;
      tTabBarDefaultActiveColorOn: ui.Color;
      tDateFieldDefaultHeightOn: number;
      tDateFieldDefaultCornerRadiusOn: number;
      tTimeFieldDefaultHeightOn: number;
      tTimeFieldDefaultCornerRadiusOn: number;
      tDateTimeFieldDefaultHeightOn: number;
      tDateTimeFieldDefaultCornerRadiusOn: number;
      tIntegerInputFieldDefaultHeightOn: number;
      tIntegerInputFieldDefaultBackgroundColorOn: ui.Color;
      tIntegerInputFieldDefaultCornerRadiusOn: number;
      tIntegerInputFieldDefaultPaddingOn: ui.EdgeInsets;
      tDoubleInputFieldDefaultHeightOn: number;
      tDoubleInputFieldDefaultBackgroundColorOn: ui.Color;
      tDoubleInputFieldDefaultCornerRadiusOn: number;
      tDoubleInputFieldDefaultPaddingOn: ui.EdgeInsets;
      tBaseDefaultPopUpDecorationOn: ui.BoxDecoration;
      tButtonDemoButtonDecorationOn: ui.BoxDecoration;
      tButtonDemoButtonTextStyleOn: ui.TextStyle;
      tButtonDemoButtonPaddingOn: ui.EdgeInsets;
      tInputFieldDisabledHeightOn: number;
      tInputFieldDisabledBackgroundColorOn: ui.Color;
      tInputFieldDisabledCornerRadiusOn: number;
      tInputFieldDisabledStyleOn: ui.TextStyle;
      tInputFieldDisabledDisableOn: boolean;
      tTextViewDisplayHeadingFourFontSizeOn: number;
      tTextViewDisplayHeadingFourMarginOn: ui.EdgeInsets;
      tTextViewDisplayHeadingOneFontSizeOn: number;
      tTextViewDisplayHeadingOneMarginOn: ui.EdgeInsets;
      tTextViewDisplayHeadingThreeFontSizeOn: number;
      tTextViewDisplayHeadingThreeMarginOn: ui.EdgeInsets;
      tTextViewDisplayHeadingTwoFontSizeOn: number;
      tTextViewDisplayHeadingTwoMarginOn: ui.EdgeInsets;
      tTextViewDropDownBuilderTextColorOn: ui.Color;
      tTextViewDropDownBuilderTextPaddingOn: ui.EdgeInsets;
      tDropDownDropdownWithAllBordersHeightOn: number;
      tDropDownDropdownWithAllBordersViewDecorationOn: ui.BoxDecoration;
      tDropDownDropdownWithAllBordersDropDownDecorationOn: ui.BoxDecoration;
      tDropDownDropdownWithAllBordersPlaceHolderColorOn: ui.Color;
      tTextViewErrorColorOn: ui.Color;
      tTextViewErrorTextAlignOn: ui.TextAlign;
      tTextViewErrorMarginOn: ui.EdgeInsets;
      tTextViewErrorLabelColorOn: ui.Color;
      tTextViewErrorLabelFontFamilyOn: string;
      tTextViewErrorLabelFontSizeOn: number;
      tTextViewErrorLabelMarginOn: ui.EdgeInsets;
      tTextViewErrorTextColorOn: ui.Color;
      tBaseFieldViewMarginOn: ui.EdgeInsets;
      tTextViewHeadingFontSizeOn: number;
      tTextViewHeadingFontWeightOn: ui.FontWeight;
      tTextViewHeadingTextAlignOn: ui.TextAlign;
      tTextViewHeadingColorOn: ui.Color;
      tTextViewHeadingFontFamilyOn: string;
      tTextViewHeadlineFiveFontSizeOn: number;
      tTextViewHeadlineFiveFontWeightOn: ui.FontWeight;
      tTextViewHeadlineFourFontSizeOn: number;
      tTextViewHeadlineFourFontWeightOn: ui.FontWeight;
      tTextViewHeadlineOneFontSizeOn: number;
      tTextViewHeadlineOneFontWeightOn: ui.FontWeight;
      tTextViewHeadlineSixFontSizeOn: number;
      tTextViewHeadlineSixFontWeightOn: ui.FontWeight;
      tTextViewHeadlineThreeFontSizeOn: number;
      tTextViewHeadlineThreeFontWeightOn: ui.FontWeight;
      tTextViewHeadlineTwoFontSizeOn: number;
      tTextViewHeadlineTwoFontWeightOn: ui.FontWeight;
      tTextViewLabelColorOn: ui.Color;
      tTextViewLabelTextAlignOn: ui.TextAlign;
      tTextViewLabelMarginOn: ui.EdgeInsets;
      tTextViewLabelTextColorOn: ui.Color;
      tTextViewLabelTextTextAlignOn: ui.TextAlign;
      tTextViewLabelTextFontSizeOn: number;
      tTextViewLabelTextFontWeightOn: ui.FontWeight;
      tInputFieldLargeHeightOn: number;
      tInputFieldLargeBackgroundColorOn: ui.Color;
      tInputFieldLargeCornerRadiusOn: number;
      tInputFieldLargeStyleOn: ui.TextStyle;
      tButtonLightDecorationOn: ui.BoxDecoration;
      tButtonLightPaddingOn: ui.EdgeInsets;
      tButtonLightConstraintsOn: ui.BoxConstraints;
      tButtonLightTextStyleOn: ui.TextStyle;
      tButtonLightDecorationOnHover: ui.BoxDecoration;
      tButtonLightPaddingOnHover: ui.EdgeInsets;
      tButtonLightConstraintsOnHover: ui.BoxConstraints;
      tButtonLightTextStyleOnHover: ui.TextStyle;
      tBaseLightColorWithMarginDecorationOn: ui.BoxDecoration;
      tBaseLightColorWithMarginMarginOn: ui.EdgeInsets;
      tButtonLightOutlineDecorationOn: ui.BoxDecoration;
      tButtonLightOutlinePaddingOn: ui.EdgeInsets;
      tButtonLightOutlineTextStyleOn: ui.TextStyle;
      tButtonLightOutlineConstraintsOn: ui.BoxConstraints;
      tButtonLightOutlinePaddingOnHover: ui.EdgeInsets;
      tButtonLightOutlineDecorationOnHover: ui.BoxDecoration;
      tButtonLightOutlineConstraintsOnHover: ui.BoxConstraints;
      tButtonLightOutlineTextStyleOnHover: ui.TextStyle;
      tButtonLinkTextStyleOn: ui.TextStyle;
      tButtonLinkPaddingOn: ui.EdgeInsets;
      tButtonLinkConstraintsOn: ui.BoxConstraints;
      tButtonLinkDecorationOn: ui.BoxDecoration;
      tButtonLinkPaddingOnHover: ui.EdgeInsets;
      tButtonLinkTextStyleOnHover: ui.TextStyle;
      tButtonLinkDecorationOnHover: ui.BoxDecoration;
      tButtonLinkConstraintsOnHover: ui.BoxConstraints;
      tButtonLinkButtonTextStyleOn: ui.TextStyle;
      tButtonLinkButtonTextStyleOnHover: ui.TextStyle;
      tCellButtonLinkCellButtonTextStyleOn: ui.TextStyle;
      tCellButtonLinkCellButtonPaddingOn: ui.EdgeInsets;
      tCellButtonLinkCellButtonTextStyleOnHover: ui.TextStyle;
      tCellButtonLinkCellButtonPaddingOnHover: ui.EdgeInsets;
      tListTileListTileDecorationOnHover: ui.BoxDecoration;
      tListTileListTileDecorationOnFocused: ui.BoxDecoration;
      tListTileListTileDecorationOnSelected: ui.BoxDecoration;
      tListTileListTileDecorationOn: ui.BoxDecoration;
      tBaseListViewBgColorDecorationOn: ui.BoxDecoration;
      tBaseListViewBgColorMarginOn: ui.EdgeInsets;
      tTextViewNameTextColorOn: ui.Color;
      tTextViewNameTextFontWeightOn: ui.FontWeight;
      tTextViewNameTextFontFamilyOn: string;
      tTextViewNameTextFontSizeOn: number;
      tBaseP1PaddingOn: ui.EdgeInsets;
      tBaseP15PaddingOn: ui.EdgeInsets;
      tButtonPrimaryDecorationOn: ui.BoxDecoration;
      tButtonPrimaryPaddingOn: ui.EdgeInsets;
      tButtonPrimaryTextStyleOn: ui.TextStyle;
      tButtonPrimaryConstraintsOn: ui.BoxConstraints;
      tButtonPrimaryDecorationOnHover: ui.BoxDecoration;
      tButtonPrimaryPaddingOnHover: ui.EdgeInsets;
      tButtonPrimaryConstraintsOnHover: ui.BoxConstraints;
      tButtonPrimaryTextStyleOnHover: ui.TextStyle;
      tButtonPrimaryButtonDecorationOn: ui.BoxDecoration;
      tButtonPrimaryButtonTextStyleOn: ui.TextStyle;
      tButtonPrimaryButtonPaddingOn: ui.EdgeInsets;
      tButtonPrimaryNewDecorationOn: ui.BoxDecoration;
      tButtonPrimaryNewPaddingOn: ui.EdgeInsets;
      tButtonPrimaryNewTextStyleOn: ui.TextStyle;
      tButtonPrimaryNewDecorationOnHover: ui.BoxDecoration;
      tButtonPrimaryNewPaddingOnHover: ui.EdgeInsets;
      tButtonPrimaryNewTextStyleOnHover: ui.TextStyle;
      tButtonPrimaryOrangeDecorationOn: ui.BoxDecoration;
      tButtonPrimaryOrangePaddingOn: ui.EdgeInsets;
      tButtonPrimaryOrangeConstraintsOn: ui.BoxConstraints;
      tButtonPrimaryOrangeTextStyleOn: ui.TextStyle;
      tButtonPrimaryOrangePaddingOnHover: ui.EdgeInsets;
      tButtonPrimaryOrangeDecorationOnHover: ui.BoxDecoration;
      tButtonPrimaryOrangeTextStyleOnHover: ui.TextStyle;
      tButtonPrimaryOrangeConstraintsOnHover: ui.BoxConstraints;
      tButtonPrimaryOrangeOutlineDecorationOn: ui.BoxDecoration;
      tButtonPrimaryOrangeOutlinePaddingOn: ui.EdgeInsets;
      tButtonPrimaryOrangeOutlineTextStyleOn: ui.TextStyle;
      tButtonPrimaryOrangeOutlineConstraintsOn: ui.BoxConstraints;
      tButtonPrimaryOrangeOutlinePaddingOnHover: ui.EdgeInsets;
      tButtonPrimaryOrangeOutlineDecorationOnHover: ui.BoxDecoration;
      tButtonPrimaryOrangeOutlineConstraintsOnHover: ui.BoxConstraints;
      tButtonPrimaryOrangeOutlineTextStyleOnHover: ui.TextStyle;
      tButtonPrimaryOutlineDecorationOn: ui.BoxDecoration;
      tButtonPrimaryOutlinePaddingOn: ui.EdgeInsets;
      tButtonPrimaryOutlineTextStyleOn: ui.TextStyle;
      tButtonPrimaryOutlineConstraintsOn: ui.BoxConstraints;
      tButtonPrimaryOutlinePaddingOnHover: ui.EdgeInsets;
      tButtonPrimaryOutlineDecorationOnHover: ui.BoxDecoration;
      tButtonPrimaryOutlineConstraintsOnHover: ui.BoxConstraints;
      tButtonPrimaryOutlineTextStyleOnHover: ui.TextStyle;
      tButtonRentalPrimaryButtonDecorationOn: ui.BoxDecoration;
      tButtonRentalPrimaryButtonPaddingOn: ui.EdgeInsets;
      tButtonRentalPrimaryButtonTextStyleOn: ui.TextStyle;
      tButtonRentalPrimaryButtonDecorationOnSelected: ui.BoxDecoration;
      tButtonRentalSecondaryButtonDecorationOn: ui.BoxDecoration;
      tButtonRentalSecondaryButtonTextStyleOn: ui.TextStyle;
      tButtonRentalSecondaryButtonPaddingOn: ui.EdgeInsets;
      tDropDownRoundRadiusDropDownHeightOn: number;
      tDropDownRoundRadiusDropDownOnHoverColorOn: ui.Color;
      tDropDownRoundRadiusDropDownPlaceHolderColorOn: ui.Color;
      tDropDownRoundRadiusDropDownDropDownDecorationOn: ui.BoxDecoration;
      tDropDownRoundRadiusDropDownViewDecorationOn: ui.BoxDecoration;
      tBaseScreenBgColorMarginOn: ui.EdgeInsets;
      tInputFieldSearchInputfieldInActiveColorOn: ui.Color;
      tInputFieldSearchInputfieldPlaceHolderColorOn: ui.Color;
      tInputFieldSearchInputfieldActiveColorOn: ui.Color;
      tInputFieldSearchInputfieldPaddingOn: ui.EdgeInsets;
      tButtonSecondaryDecorationOn: ui.BoxDecoration;
      tButtonSecondaryPaddingOn: ui.EdgeInsets;
      tButtonSecondaryConstraintsOn: ui.BoxConstraints;
      tButtonSecondaryTextStyleOn: ui.TextStyle;
      tButtonSecondaryDecorationOnHover: ui.BoxDecoration;
      tButtonSecondaryPaddingOnHover: ui.EdgeInsets;
      tButtonSecondaryConstraintsOnHover: ui.BoxConstraints;
      tButtonSecondaryTextStyleOnHover: ui.TextStyle;
      tButtonSecondaryNewPaddingOn: ui.EdgeInsets;
      tButtonSecondaryNewDecorationOn: ui.BoxDecoration;
      tButtonSecondaryNewTextStyleOn: ui.TextStyle;
      tButtonSecondaryNewDecorationOnHover: ui.BoxDecoration;
      tButtonSecondaryNewPaddingOnHover: ui.EdgeInsets;
      tButtonSecondaryNewTextStyleOnHover: ui.TextStyle;
      tButtonSecondaryOutlineDecorationOn: ui.BoxDecoration;
      tButtonSecondaryOutlinePaddingOn: ui.EdgeInsets;
      tButtonSecondaryOutlineTextStyleOn: ui.TextStyle;
      tButtonSecondaryOutlineConstraintsOn: ui.BoxConstraints;
      tButtonSecondaryOutlinePaddingOnHover: ui.EdgeInsets;
      tButtonSecondaryOutlineDecorationOnHover: ui.BoxDecoration;
      tButtonSecondaryOutlineConstraintsOnHover: ui.BoxConstraints;
      tButtonSecondaryOutlineTextStyleOnHover: ui.TextStyle;
      tInputFieldSmallHeightOn: number;
      tInputFieldSmallBackgroundColorOn: ui.Color;
      tInputFieldSmallCornerRadiusOn: number;
      tInputFieldSmallStyleOn: ui.TextStyle;
      tTextViewSubHeaderColorOn: ui.Color;
      tTextViewSubHeaderFontFamilyOn: string;
      tTextViewSubHeaderPaddingOn: ui.EdgeInsets;
      tTextViewSubHeader1ColorOn: ui.Color;
      tTextViewSubHeader1FontWeightOn: ui.FontWeight;
      tTextViewSubHeader1FontFamilyOn: string;
      tTextViewSubHeader1PaddingOn: ui.EdgeInsets;
      tButtonSuccessDecorationOn: ui.BoxDecoration;
      tButtonSuccessPaddingOn: ui.EdgeInsets;
      tButtonSuccessConstraintsOn: ui.BoxConstraints;
      tButtonSuccessTextStyleOn: ui.TextStyle;
      tButtonSuccessDecorationOnHover: ui.BoxDecoration;
      tButtonSuccessPaddingOnHover: ui.EdgeInsets;
      tButtonSuccessConstraintsOnHover: ui.BoxConstraints;
      tButtonSuccessTextStyleOnHover: ui.TextStyle;
      tButtonSuccessOutlineDecorationOn: ui.BoxDecoration;
      tButtonSuccessOutlinePaddingOn: ui.EdgeInsets;
      tButtonSuccessOutlineTextStyleOn: ui.TextStyle;
      tButtonSuccessOutlineConstraintsOn: ui.BoxConstraints;
      tButtonSuccessOutlinePaddingOnHover: ui.EdgeInsets;
      tButtonSuccessOutlineDecorationOnHover: ui.BoxDecoration;
      tButtonSuccessOutlineConstraintsOnHover: ui.BoxConstraints;
      tButtonSuccessOutlineTextStyleOnHover: ui.TextStyle;
      tTextViewTableCellTextFontSizeOn: number;
      tTextViewTableCellTextColorOn: ui.Color;
      tTextViewTableCellTextFontFamilyOn: string;
      tTextViewTableHeaderColorOn: ui.Color;
      tTextViewTableHeaderFontFamilyOn: string;
      tTextViewTableHeaderFontWeightOn: ui.FontWeight;
      tTableRowTableRowOnebgDecorationOn: ui.BoxDecoration;
      tTableRowTableRowOnebgPaddingOn: ui.EdgeInsets;
      tTableRowTableRowTwobgDecorationOn: ui.BoxDecoration;
      tTableRowTableRowTwobgPaddingOn: ui.EdgeInsets;
      tButtonTextSideBarTextStyleOn: ui.TextStyle;
      tButtonWarningDecorationOn: ui.BoxDecoration;
      tButtonWarningConstraintsOn: ui.BoxConstraints;
      tButtonWarningTextStyleOn: ui.TextStyle;
      tButtonWarningDecorationOnHover: ui.BoxDecoration;
      tButtonWarningTextStyleOnHover: ui.TextStyle;
      tButtonWarningOutlineDecorationOn: ui.BoxDecoration;
      tButtonWarningOutlineTextStyleOn: ui.TextStyle;
      tButtonWarningOutlineConstraintsOn: ui.BoxConstraints;
      tButtonWarningOutlineDecorationOnHover: ui.BoxDecoration;
      tButtonWarningOutlineTextStyleOnHover: ui.TextStyle;
      tButtonWhitePrimaryNewDecorationOn: ui.BoxDecoration;
      tButtonWhitePrimaryNewPaddingOn: ui.EdgeInsets;
      tButtonWhitePrimaryNewTextStyleOn: ui.TextStyle;
      c1: ui.Color;
      c2: ui.Color;
      c3: ui.Color;
      c4: ui.Color;
      c5: ui.Color;
      c6: ui.Color;
      c7: ui.Color;
      c8: ui.Color;
      c9: ui.Color;
      c10: ui.Color;
      c11: ui.Color;
      c12: ui.Color;
      c13: ui.Color;
      c14: ui.Color;
      c15: ui.Color;
      c16: ui.Color;
      c17: ui.Color;
      c18: ui.Color;
      c19: ui.Color;
      c20: ui.Color;
      c21: ui.Color;
      c22: ui.Color;
      c23: ui.Color;
      c24: ui.Color;
      c25: ui.Color;
      c26: ui.Color;
      c27: ui.Color;
      c28: ui.Color;
      c29: ui.Color;
      c30: ui.Color;
      c31: ui.Color;
      c32: ui.Color;
      c33: ui.Color;
      c34: ui.Color;
      c35: ui.Color;
      c36: ui.Color;
      c37: ui.Color;
    }>
  ) {
    this.themeName = d3eParams?.themeName;

    this.color = d3eParams?.color;

    this.textStyle = d3eParams?.textStyle;

    this.tooltipBackgroundColor = d3eParams?.tooltipBackgroundColor;

    this.tooltipTextColor = d3eParams?.tooltipTextColor;

    this.tTextViewBlue11ColorOn = d3eParams?.tTextViewBlue11ColorOn;

    this.tTextViewBlue11FontSizeOn = d3eParams?.tTextViewBlue11FontSizeOn;

    this.tButtonCalendarButtonDecorationOn =
      d3eParams?.tButtonCalendarButtonDecorationOn;

    this.tButtonCalendarButtonPaddingOn =
      d3eParams?.tButtonCalendarButtonPaddingOn;

    this.tButtonCalendarButtonTextStyleOn =
      d3eParams?.tButtonCalendarButtonTextStyleOn;

    this.tButtonCalendarButtonDecorationOnHover =
      d3eParams?.tButtonCalendarButtonDecorationOnHover;

    this.tTableCalendarTableBorderOn = d3eParams?.tTableCalendarTableBorderOn;

    this.tColumnBannerFormBGPaddingOn = d3eParams?.tColumnBannerFormBGPaddingOn;

    this.tColumnBannerFormBGDecorationOn =
      d3eParams?.tColumnBannerFormBGDecorationOn;

    this.tTextViewBlueColorOn = d3eParams?.tTextViewBlueColorOn;

    this.tTextViewBlueFontSizeOn = d3eParams?.tTextViewBlueFontSizeOn;

    this.tButtonDangerDecorationOn = d3eParams?.tButtonDangerDecorationOn;

    this.tButtonDangerPaddingOn = d3eParams?.tButtonDangerPaddingOn;

    this.tButtonDangerConstraintsOn = d3eParams?.tButtonDangerConstraintsOn;

    this.tButtonDangerTextStyleOn = d3eParams?.tButtonDangerTextStyleOn;

    this.tButtonDangerDecorationOnHover =
      d3eParams?.tButtonDangerDecorationOnHover;

    this.tButtonDangerPaddingOnHover = d3eParams?.tButtonDangerPaddingOnHover;

    this.tButtonDangerConstraintsOnHover =
      d3eParams?.tButtonDangerConstraintsOnHover;

    this.tButtonDangerTextStyleOnHover =
      d3eParams?.tButtonDangerTextStyleOnHover;

    this.tButtonDangerOutlineDecorationOn =
      d3eParams?.tButtonDangerOutlineDecorationOn;

    this.tButtonDangerOutlinePaddingOn =
      d3eParams?.tButtonDangerOutlinePaddingOn;

    this.tButtonDangerOutlineTextStyleOn =
      d3eParams?.tButtonDangerOutlineTextStyleOn;

    this.tButtonDangerOutlineConstraintsOn =
      d3eParams?.tButtonDangerOutlineConstraintsOn;

    this.tButtonDangerOutlinePaddingOnHover =
      d3eParams?.tButtonDangerOutlinePaddingOnHover;

    this.tButtonDangerOutlineDecorationOnHover =
      d3eParams?.tButtonDangerOutlineDecorationOnHover;

    this.tButtonDangerOutlineConstraintsOnHover =
      d3eParams?.tButtonDangerOutlineConstraintsOnHover;

    this.tButtonDangerOutlineTextStyleOnHover =
      d3eParams?.tButtonDangerOutlineTextStyleOnHover;

    this.tButtonDarkDecorationOn = d3eParams?.tButtonDarkDecorationOn;

    this.tButtonDarkPaddingOn = d3eParams?.tButtonDarkPaddingOn;

    this.tButtonDarkConstraintsOn = d3eParams?.tButtonDarkConstraintsOn;

    this.tButtonDarkTextStyleOn = d3eParams?.tButtonDarkTextStyleOn;

    this.tButtonDarkDecorationOnHover = d3eParams?.tButtonDarkDecorationOnHover;

    this.tButtonDarkPaddingOnHover = d3eParams?.tButtonDarkPaddingOnHover;

    this.tButtonDarkConstraintsOnHover =
      d3eParams?.tButtonDarkConstraintsOnHover;

    this.tButtonDarkTextStyleOnHover = d3eParams?.tButtonDarkTextStyleOnHover;

    this.tButtonDarkOutlineDecorationOn =
      d3eParams?.tButtonDarkOutlineDecorationOn;

    this.tButtonDarkOutlinePaddingOn = d3eParams?.tButtonDarkOutlinePaddingOn;

    this.tButtonDarkOutlineTextStyleOn =
      d3eParams?.tButtonDarkOutlineTextStyleOn;

    this.tButtonDarkOutlineConstraintsOn =
      d3eParams?.tButtonDarkOutlineConstraintsOn;

    this.tButtonDarkOutlinePaddingOnHover =
      d3eParams?.tButtonDarkOutlinePaddingOnHover;

    this.tButtonDarkOutlineDecorationOnHover =
      d3eParams?.tButtonDarkOutlineDecorationOnHover;

    this.tButtonDarkOutlineConstraintsOnHover =
      d3eParams?.tButtonDarkOutlineConstraintsOnHover;

    this.tButtonDarkOutlineTextStyleOnHover =
      d3eParams?.tButtonDarkOutlineTextStyleOnHover;

    this.tInputFieldDefaultHeightOn = d3eParams?.tInputFieldDefaultHeightOn;

    this.tInputFieldDefaultBackgroundColorOn =
      d3eParams?.tInputFieldDefaultBackgroundColorOn;

    this.tInputFieldDefaultCornerRadiusOn =
      d3eParams?.tInputFieldDefaultCornerRadiusOn;

    this.tInputFieldDefaultStyleOn = d3eParams?.tInputFieldDefaultStyleOn;

    this.tListTileDefaultDecorationOnHover =
      d3eParams?.tListTileDefaultDecorationOnHover;

    this.tListTileDefaultDecorationOnFocused =
      d3eParams?.tListTileDefaultDecorationOnFocused;

    this.tListTileDefaultDecorationOnSelected =
      d3eParams?.tListTileDefaultDecorationOnSelected;

    this.tDropDownDefaultDropDownDecorationOn =
      d3eParams?.tDropDownDefaultDropDownDecorationOn;

    this.tDropDownDefaultPlaceHolderColorOn =
      d3eParams?.tDropDownDefaultPlaceHolderColorOn;

    this.tCardDefaultColorOn = d3eParams?.tCardDefaultColorOn;

    this.tCardDefaultDecorationOn = d3eParams?.tCardDefaultDecorationOn;

    this.tTabBarDefaultBackgroundColorOn =
      d3eParams?.tTabBarDefaultBackgroundColorOn;

    this.tTabBarDefaultActiveColorOn = d3eParams?.tTabBarDefaultActiveColorOn;

    this.tDateFieldDefaultHeightOn = d3eParams?.tDateFieldDefaultHeightOn;

    this.tDateFieldDefaultCornerRadiusOn =
      d3eParams?.tDateFieldDefaultCornerRadiusOn;

    this.tTimeFieldDefaultHeightOn = d3eParams?.tTimeFieldDefaultHeightOn;

    this.tTimeFieldDefaultCornerRadiusOn =
      d3eParams?.tTimeFieldDefaultCornerRadiusOn;

    this.tDateTimeFieldDefaultHeightOn =
      d3eParams?.tDateTimeFieldDefaultHeightOn;

    this.tDateTimeFieldDefaultCornerRadiusOn =
      d3eParams?.tDateTimeFieldDefaultCornerRadiusOn;

    this.tIntegerInputFieldDefaultHeightOn =
      d3eParams?.tIntegerInputFieldDefaultHeightOn;

    this.tIntegerInputFieldDefaultBackgroundColorOn =
      d3eParams?.tIntegerInputFieldDefaultBackgroundColorOn;

    this.tIntegerInputFieldDefaultCornerRadiusOn =
      d3eParams?.tIntegerInputFieldDefaultCornerRadiusOn;

    this.tIntegerInputFieldDefaultPaddingOn =
      d3eParams?.tIntegerInputFieldDefaultPaddingOn;

    this.tDoubleInputFieldDefaultHeightOn =
      d3eParams?.tDoubleInputFieldDefaultHeightOn;

    this.tDoubleInputFieldDefaultBackgroundColorOn =
      d3eParams?.tDoubleInputFieldDefaultBackgroundColorOn;

    this.tDoubleInputFieldDefaultCornerRadiusOn =
      d3eParams?.tDoubleInputFieldDefaultCornerRadiusOn;

    this.tDoubleInputFieldDefaultPaddingOn =
      d3eParams?.tDoubleInputFieldDefaultPaddingOn;

    this.tBaseDefaultPopUpDecorationOn =
      d3eParams?.tBaseDefaultPopUpDecorationOn;

    this.tButtonDemoButtonDecorationOn =
      d3eParams?.tButtonDemoButtonDecorationOn;

    this.tButtonDemoButtonTextStyleOn = d3eParams?.tButtonDemoButtonTextStyleOn;

    this.tButtonDemoButtonPaddingOn = d3eParams?.tButtonDemoButtonPaddingOn;

    this.tInputFieldDisabledHeightOn = d3eParams?.tInputFieldDisabledHeightOn;

    this.tInputFieldDisabledBackgroundColorOn =
      d3eParams?.tInputFieldDisabledBackgroundColorOn;

    this.tInputFieldDisabledCornerRadiusOn =
      d3eParams?.tInputFieldDisabledCornerRadiusOn;

    this.tInputFieldDisabledStyleOn = d3eParams?.tInputFieldDisabledStyleOn;

    this.tInputFieldDisabledDisableOn = d3eParams?.tInputFieldDisabledDisableOn;

    this.tTextViewDisplayHeadingFourFontSizeOn =
      d3eParams?.tTextViewDisplayHeadingFourFontSizeOn;

    this.tTextViewDisplayHeadingFourMarginOn =
      d3eParams?.tTextViewDisplayHeadingFourMarginOn;

    this.tTextViewDisplayHeadingOneFontSizeOn =
      d3eParams?.tTextViewDisplayHeadingOneFontSizeOn;

    this.tTextViewDisplayHeadingOneMarginOn =
      d3eParams?.tTextViewDisplayHeadingOneMarginOn;

    this.tTextViewDisplayHeadingThreeFontSizeOn =
      d3eParams?.tTextViewDisplayHeadingThreeFontSizeOn;

    this.tTextViewDisplayHeadingThreeMarginOn =
      d3eParams?.tTextViewDisplayHeadingThreeMarginOn;

    this.tTextViewDisplayHeadingTwoFontSizeOn =
      d3eParams?.tTextViewDisplayHeadingTwoFontSizeOn;

    this.tTextViewDisplayHeadingTwoMarginOn =
      d3eParams?.tTextViewDisplayHeadingTwoMarginOn;

    this.tTextViewDropDownBuilderTextColorOn =
      d3eParams?.tTextViewDropDownBuilderTextColorOn;

    this.tTextViewDropDownBuilderTextPaddingOn =
      d3eParams?.tTextViewDropDownBuilderTextPaddingOn;

    this.tDropDownDropdownWithAllBordersHeightOn =
      d3eParams?.tDropDownDropdownWithAllBordersHeightOn;

    this.tDropDownDropdownWithAllBordersViewDecorationOn =
      d3eParams?.tDropDownDropdownWithAllBordersViewDecorationOn;

    this.tDropDownDropdownWithAllBordersDropDownDecorationOn =
      d3eParams?.tDropDownDropdownWithAllBordersDropDownDecorationOn;

    this.tDropDownDropdownWithAllBordersPlaceHolderColorOn =
      d3eParams?.tDropDownDropdownWithAllBordersPlaceHolderColorOn;

    this.tTextViewErrorColorOn = d3eParams?.tTextViewErrorColorOn;

    this.tTextViewErrorTextAlignOn = d3eParams?.tTextViewErrorTextAlignOn;

    this.tTextViewErrorMarginOn = d3eParams?.tTextViewErrorMarginOn;

    this.tTextViewErrorLabelColorOn = d3eParams?.tTextViewErrorLabelColorOn;

    this.tTextViewErrorLabelFontFamilyOn =
      d3eParams?.tTextViewErrorLabelFontFamilyOn;

    this.tTextViewErrorLabelFontSizeOn =
      d3eParams?.tTextViewErrorLabelFontSizeOn;

    this.tTextViewErrorLabelMarginOn = d3eParams?.tTextViewErrorLabelMarginOn;

    this.tTextViewErrorTextColorOn = d3eParams?.tTextViewErrorTextColorOn;

    this.tBaseFieldViewMarginOn = d3eParams?.tBaseFieldViewMarginOn;

    this.tTextViewHeadingFontSizeOn = d3eParams?.tTextViewHeadingFontSizeOn;

    this.tTextViewHeadingFontWeightOn = d3eParams?.tTextViewHeadingFontWeightOn;

    this.tTextViewHeadingTextAlignOn = d3eParams?.tTextViewHeadingTextAlignOn;

    this.tTextViewHeadingColorOn = d3eParams?.tTextViewHeadingColorOn;

    this.tTextViewHeadingFontFamilyOn = d3eParams?.tTextViewHeadingFontFamilyOn;

    this.tTextViewHeadlineFiveFontSizeOn =
      d3eParams?.tTextViewHeadlineFiveFontSizeOn;

    this.tTextViewHeadlineFiveFontWeightOn =
      d3eParams?.tTextViewHeadlineFiveFontWeightOn;

    this.tTextViewHeadlineFourFontSizeOn =
      d3eParams?.tTextViewHeadlineFourFontSizeOn;

    this.tTextViewHeadlineFourFontWeightOn =
      d3eParams?.tTextViewHeadlineFourFontWeightOn;

    this.tTextViewHeadlineOneFontSizeOn =
      d3eParams?.tTextViewHeadlineOneFontSizeOn;

    this.tTextViewHeadlineOneFontWeightOn =
      d3eParams?.tTextViewHeadlineOneFontWeightOn;

    this.tTextViewHeadlineSixFontSizeOn =
      d3eParams?.tTextViewHeadlineSixFontSizeOn;

    this.tTextViewHeadlineSixFontWeightOn =
      d3eParams?.tTextViewHeadlineSixFontWeightOn;

    this.tTextViewHeadlineThreeFontSizeOn =
      d3eParams?.tTextViewHeadlineThreeFontSizeOn;

    this.tTextViewHeadlineThreeFontWeightOn =
      d3eParams?.tTextViewHeadlineThreeFontWeightOn;

    this.tTextViewHeadlineTwoFontSizeOn =
      d3eParams?.tTextViewHeadlineTwoFontSizeOn;

    this.tTextViewHeadlineTwoFontWeightOn =
      d3eParams?.tTextViewHeadlineTwoFontWeightOn;

    this.tTextViewLabelColorOn = d3eParams?.tTextViewLabelColorOn;

    this.tTextViewLabelTextAlignOn = d3eParams?.tTextViewLabelTextAlignOn;

    this.tTextViewLabelMarginOn = d3eParams?.tTextViewLabelMarginOn;

    this.tTextViewLabelTextColorOn = d3eParams?.tTextViewLabelTextColorOn;

    this.tTextViewLabelTextTextAlignOn =
      d3eParams?.tTextViewLabelTextTextAlignOn;

    this.tTextViewLabelTextFontSizeOn = d3eParams?.tTextViewLabelTextFontSizeOn;

    this.tTextViewLabelTextFontWeightOn =
      d3eParams?.tTextViewLabelTextFontWeightOn;

    this.tInputFieldLargeHeightOn = d3eParams?.tInputFieldLargeHeightOn;

    this.tInputFieldLargeBackgroundColorOn =
      d3eParams?.tInputFieldLargeBackgroundColorOn;

    this.tInputFieldLargeCornerRadiusOn =
      d3eParams?.tInputFieldLargeCornerRadiusOn;

    this.tInputFieldLargeStyleOn = d3eParams?.tInputFieldLargeStyleOn;

    this.tButtonLightDecorationOn = d3eParams?.tButtonLightDecorationOn;

    this.tButtonLightPaddingOn = d3eParams?.tButtonLightPaddingOn;

    this.tButtonLightConstraintsOn = d3eParams?.tButtonLightConstraintsOn;

    this.tButtonLightTextStyleOn = d3eParams?.tButtonLightTextStyleOn;

    this.tButtonLightDecorationOnHover =
      d3eParams?.tButtonLightDecorationOnHover;

    this.tButtonLightPaddingOnHover = d3eParams?.tButtonLightPaddingOnHover;

    this.tButtonLightConstraintsOnHover =
      d3eParams?.tButtonLightConstraintsOnHover;

    this.tButtonLightTextStyleOnHover = d3eParams?.tButtonLightTextStyleOnHover;

    this.tBaseLightColorWithMarginDecorationOn =
      d3eParams?.tBaseLightColorWithMarginDecorationOn;

    this.tBaseLightColorWithMarginMarginOn =
      d3eParams?.tBaseLightColorWithMarginMarginOn;

    this.tButtonLightOutlineDecorationOn =
      d3eParams?.tButtonLightOutlineDecorationOn;

    this.tButtonLightOutlinePaddingOn = d3eParams?.tButtonLightOutlinePaddingOn;

    this.tButtonLightOutlineTextStyleOn =
      d3eParams?.tButtonLightOutlineTextStyleOn;

    this.tButtonLightOutlineConstraintsOn =
      d3eParams?.tButtonLightOutlineConstraintsOn;

    this.tButtonLightOutlinePaddingOnHover =
      d3eParams?.tButtonLightOutlinePaddingOnHover;

    this.tButtonLightOutlineDecorationOnHover =
      d3eParams?.tButtonLightOutlineDecorationOnHover;

    this.tButtonLightOutlineConstraintsOnHover =
      d3eParams?.tButtonLightOutlineConstraintsOnHover;

    this.tButtonLightOutlineTextStyleOnHover =
      d3eParams?.tButtonLightOutlineTextStyleOnHover;

    this.tButtonLinkTextStyleOn = d3eParams?.tButtonLinkTextStyleOn;

    this.tButtonLinkPaddingOn = d3eParams?.tButtonLinkPaddingOn;

    this.tButtonLinkConstraintsOn = d3eParams?.tButtonLinkConstraintsOn;

    this.tButtonLinkDecorationOn = d3eParams?.tButtonLinkDecorationOn;

    this.tButtonLinkPaddingOnHover = d3eParams?.tButtonLinkPaddingOnHover;

    this.tButtonLinkTextStyleOnHover = d3eParams?.tButtonLinkTextStyleOnHover;

    this.tButtonLinkDecorationOnHover = d3eParams?.tButtonLinkDecorationOnHover;

    this.tButtonLinkConstraintsOnHover =
      d3eParams?.tButtonLinkConstraintsOnHover;

    this.tButtonLinkButtonTextStyleOn = d3eParams?.tButtonLinkButtonTextStyleOn;

    this.tButtonLinkButtonTextStyleOnHover =
      d3eParams?.tButtonLinkButtonTextStyleOnHover;

    this.tCellButtonLinkCellButtonTextStyleOn =
      d3eParams?.tCellButtonLinkCellButtonTextStyleOn;

    this.tCellButtonLinkCellButtonPaddingOn =
      d3eParams?.tCellButtonLinkCellButtonPaddingOn;

    this.tCellButtonLinkCellButtonTextStyleOnHover =
      d3eParams?.tCellButtonLinkCellButtonTextStyleOnHover;

    this.tCellButtonLinkCellButtonPaddingOnHover =
      d3eParams?.tCellButtonLinkCellButtonPaddingOnHover;

    this.tListTileListTileDecorationOnHover =
      d3eParams?.tListTileListTileDecorationOnHover;

    this.tListTileListTileDecorationOnFocused =
      d3eParams?.tListTileListTileDecorationOnFocused;

    this.tListTileListTileDecorationOnSelected =
      d3eParams?.tListTileListTileDecorationOnSelected;

    this.tListTileListTileDecorationOn =
      d3eParams?.tListTileListTileDecorationOn;

    this.tBaseListViewBgColorDecorationOn =
      d3eParams?.tBaseListViewBgColorDecorationOn;

    this.tBaseListViewBgColorMarginOn = d3eParams?.tBaseListViewBgColorMarginOn;

    this.tTextViewNameTextColorOn = d3eParams?.tTextViewNameTextColorOn;

    this.tTextViewNameTextFontWeightOn =
      d3eParams?.tTextViewNameTextFontWeightOn;

    this.tTextViewNameTextFontFamilyOn =
      d3eParams?.tTextViewNameTextFontFamilyOn;

    this.tTextViewNameTextFontSizeOn = d3eParams?.tTextViewNameTextFontSizeOn;

    this.tBaseP1PaddingOn = d3eParams?.tBaseP1PaddingOn;

    this.tBaseP15PaddingOn = d3eParams?.tBaseP15PaddingOn;

    this.tButtonPrimaryDecorationOn = d3eParams?.tButtonPrimaryDecorationOn;

    this.tButtonPrimaryPaddingOn = d3eParams?.tButtonPrimaryPaddingOn;

    this.tButtonPrimaryTextStyleOn = d3eParams?.tButtonPrimaryTextStyleOn;

    this.tButtonPrimaryConstraintsOn = d3eParams?.tButtonPrimaryConstraintsOn;

    this.tButtonPrimaryDecorationOnHover =
      d3eParams?.tButtonPrimaryDecorationOnHover;

    this.tButtonPrimaryPaddingOnHover = d3eParams?.tButtonPrimaryPaddingOnHover;

    this.tButtonPrimaryConstraintsOnHover =
      d3eParams?.tButtonPrimaryConstraintsOnHover;

    this.tButtonPrimaryTextStyleOnHover =
      d3eParams?.tButtonPrimaryTextStyleOnHover;

    this.tButtonPrimaryButtonDecorationOn =
      d3eParams?.tButtonPrimaryButtonDecorationOn;

    this.tButtonPrimaryButtonTextStyleOn =
      d3eParams?.tButtonPrimaryButtonTextStyleOn;

    this.tButtonPrimaryButtonPaddingOn =
      d3eParams?.tButtonPrimaryButtonPaddingOn;

    this.tButtonPrimaryNewDecorationOn =
      d3eParams?.tButtonPrimaryNewDecorationOn;

    this.tButtonPrimaryNewPaddingOn = d3eParams?.tButtonPrimaryNewPaddingOn;

    this.tButtonPrimaryNewTextStyleOn = d3eParams?.tButtonPrimaryNewTextStyleOn;

    this.tButtonPrimaryNewDecorationOnHover =
      d3eParams?.tButtonPrimaryNewDecorationOnHover;

    this.tButtonPrimaryNewPaddingOnHover =
      d3eParams?.tButtonPrimaryNewPaddingOnHover;

    this.tButtonPrimaryNewTextStyleOnHover =
      d3eParams?.tButtonPrimaryNewTextStyleOnHover;

    this.tButtonPrimaryOrangeDecorationOn =
      d3eParams?.tButtonPrimaryOrangeDecorationOn;

    this.tButtonPrimaryOrangePaddingOn =
      d3eParams?.tButtonPrimaryOrangePaddingOn;

    this.tButtonPrimaryOrangeConstraintsOn =
      d3eParams?.tButtonPrimaryOrangeConstraintsOn;

    this.tButtonPrimaryOrangeTextStyleOn =
      d3eParams?.tButtonPrimaryOrangeTextStyleOn;

    this.tButtonPrimaryOrangePaddingOnHover =
      d3eParams?.tButtonPrimaryOrangePaddingOnHover;

    this.tButtonPrimaryOrangeDecorationOnHover =
      d3eParams?.tButtonPrimaryOrangeDecorationOnHover;

    this.tButtonPrimaryOrangeTextStyleOnHover =
      d3eParams?.tButtonPrimaryOrangeTextStyleOnHover;

    this.tButtonPrimaryOrangeConstraintsOnHover =
      d3eParams?.tButtonPrimaryOrangeConstraintsOnHover;

    this.tButtonPrimaryOrangeOutlineDecorationOn =
      d3eParams?.tButtonPrimaryOrangeOutlineDecorationOn;

    this.tButtonPrimaryOrangeOutlinePaddingOn =
      d3eParams?.tButtonPrimaryOrangeOutlinePaddingOn;

    this.tButtonPrimaryOrangeOutlineTextStyleOn =
      d3eParams?.tButtonPrimaryOrangeOutlineTextStyleOn;

    this.tButtonPrimaryOrangeOutlineConstraintsOn =
      d3eParams?.tButtonPrimaryOrangeOutlineConstraintsOn;

    this.tButtonPrimaryOrangeOutlinePaddingOnHover =
      d3eParams?.tButtonPrimaryOrangeOutlinePaddingOnHover;

    this.tButtonPrimaryOrangeOutlineDecorationOnHover =
      d3eParams?.tButtonPrimaryOrangeOutlineDecorationOnHover;

    this.tButtonPrimaryOrangeOutlineConstraintsOnHover =
      d3eParams?.tButtonPrimaryOrangeOutlineConstraintsOnHover;

    this.tButtonPrimaryOrangeOutlineTextStyleOnHover =
      d3eParams?.tButtonPrimaryOrangeOutlineTextStyleOnHover;

    this.tButtonPrimaryOutlineDecorationOn =
      d3eParams?.tButtonPrimaryOutlineDecorationOn;

    this.tButtonPrimaryOutlinePaddingOn =
      d3eParams?.tButtonPrimaryOutlinePaddingOn;

    this.tButtonPrimaryOutlineTextStyleOn =
      d3eParams?.tButtonPrimaryOutlineTextStyleOn;

    this.tButtonPrimaryOutlineConstraintsOn =
      d3eParams?.tButtonPrimaryOutlineConstraintsOn;

    this.tButtonPrimaryOutlinePaddingOnHover =
      d3eParams?.tButtonPrimaryOutlinePaddingOnHover;

    this.tButtonPrimaryOutlineDecorationOnHover =
      d3eParams?.tButtonPrimaryOutlineDecorationOnHover;

    this.tButtonPrimaryOutlineConstraintsOnHover =
      d3eParams?.tButtonPrimaryOutlineConstraintsOnHover;

    this.tButtonPrimaryOutlineTextStyleOnHover =
      d3eParams?.tButtonPrimaryOutlineTextStyleOnHover;

    this.tButtonRentalPrimaryButtonDecorationOn =
      d3eParams?.tButtonRentalPrimaryButtonDecorationOn;

    this.tButtonRentalPrimaryButtonPaddingOn =
      d3eParams?.tButtonRentalPrimaryButtonPaddingOn;

    this.tButtonRentalPrimaryButtonTextStyleOn =
      d3eParams?.tButtonRentalPrimaryButtonTextStyleOn;

    this.tButtonRentalPrimaryButtonDecorationOnSelected =
      d3eParams?.tButtonRentalPrimaryButtonDecorationOnSelected;

    this.tButtonRentalSecondaryButtonDecorationOn =
      d3eParams?.tButtonRentalSecondaryButtonDecorationOn;

    this.tButtonRentalSecondaryButtonTextStyleOn =
      d3eParams?.tButtonRentalSecondaryButtonTextStyleOn;

    this.tButtonRentalSecondaryButtonPaddingOn =
      d3eParams?.tButtonRentalSecondaryButtonPaddingOn;

    this.tDropDownRoundRadiusDropDownHeightOn =
      d3eParams?.tDropDownRoundRadiusDropDownHeightOn;

    this.tDropDownRoundRadiusDropDownOnHoverColorOn =
      d3eParams?.tDropDownRoundRadiusDropDownOnHoverColorOn;

    this.tDropDownRoundRadiusDropDownPlaceHolderColorOn =
      d3eParams?.tDropDownRoundRadiusDropDownPlaceHolderColorOn;

    this.tDropDownRoundRadiusDropDownDropDownDecorationOn =
      d3eParams?.tDropDownRoundRadiusDropDownDropDownDecorationOn;

    this.tDropDownRoundRadiusDropDownViewDecorationOn =
      d3eParams?.tDropDownRoundRadiusDropDownViewDecorationOn;

    this.tBaseScreenBgColorMarginOn = d3eParams?.tBaseScreenBgColorMarginOn;

    this.tInputFieldSearchInputfieldInActiveColorOn =
      d3eParams?.tInputFieldSearchInputfieldInActiveColorOn;

    this.tInputFieldSearchInputfieldPlaceHolderColorOn =
      d3eParams?.tInputFieldSearchInputfieldPlaceHolderColorOn;

    this.tInputFieldSearchInputfieldActiveColorOn =
      d3eParams?.tInputFieldSearchInputfieldActiveColorOn;

    this.tInputFieldSearchInputfieldPaddingOn =
      d3eParams?.tInputFieldSearchInputfieldPaddingOn;

    this.tButtonSecondaryDecorationOn = d3eParams?.tButtonSecondaryDecorationOn;

    this.tButtonSecondaryPaddingOn = d3eParams?.tButtonSecondaryPaddingOn;

    this.tButtonSecondaryConstraintsOn =
      d3eParams?.tButtonSecondaryConstraintsOn;

    this.tButtonSecondaryTextStyleOn = d3eParams?.tButtonSecondaryTextStyleOn;

    this.tButtonSecondaryDecorationOnHover =
      d3eParams?.tButtonSecondaryDecorationOnHover;

    this.tButtonSecondaryPaddingOnHover =
      d3eParams?.tButtonSecondaryPaddingOnHover;

    this.tButtonSecondaryConstraintsOnHover =
      d3eParams?.tButtonSecondaryConstraintsOnHover;

    this.tButtonSecondaryTextStyleOnHover =
      d3eParams?.tButtonSecondaryTextStyleOnHover;

    this.tButtonSecondaryNewPaddingOn = d3eParams?.tButtonSecondaryNewPaddingOn;

    this.tButtonSecondaryNewDecorationOn =
      d3eParams?.tButtonSecondaryNewDecorationOn;

    this.tButtonSecondaryNewTextStyleOn =
      d3eParams?.tButtonSecondaryNewTextStyleOn;

    this.tButtonSecondaryNewDecorationOnHover =
      d3eParams?.tButtonSecondaryNewDecorationOnHover;

    this.tButtonSecondaryNewPaddingOnHover =
      d3eParams?.tButtonSecondaryNewPaddingOnHover;

    this.tButtonSecondaryNewTextStyleOnHover =
      d3eParams?.tButtonSecondaryNewTextStyleOnHover;

    this.tButtonSecondaryOutlineDecorationOn =
      d3eParams?.tButtonSecondaryOutlineDecorationOn;

    this.tButtonSecondaryOutlinePaddingOn =
      d3eParams?.tButtonSecondaryOutlinePaddingOn;

    this.tButtonSecondaryOutlineTextStyleOn =
      d3eParams?.tButtonSecondaryOutlineTextStyleOn;

    this.tButtonSecondaryOutlineConstraintsOn =
      d3eParams?.tButtonSecondaryOutlineConstraintsOn;

    this.tButtonSecondaryOutlinePaddingOnHover =
      d3eParams?.tButtonSecondaryOutlinePaddingOnHover;

    this.tButtonSecondaryOutlineDecorationOnHover =
      d3eParams?.tButtonSecondaryOutlineDecorationOnHover;

    this.tButtonSecondaryOutlineConstraintsOnHover =
      d3eParams?.tButtonSecondaryOutlineConstraintsOnHover;

    this.tButtonSecondaryOutlineTextStyleOnHover =
      d3eParams?.tButtonSecondaryOutlineTextStyleOnHover;

    this.tInputFieldSmallHeightOn = d3eParams?.tInputFieldSmallHeightOn;

    this.tInputFieldSmallBackgroundColorOn =
      d3eParams?.tInputFieldSmallBackgroundColorOn;

    this.tInputFieldSmallCornerRadiusOn =
      d3eParams?.tInputFieldSmallCornerRadiusOn;

    this.tInputFieldSmallStyleOn = d3eParams?.tInputFieldSmallStyleOn;

    this.tTextViewSubHeaderColorOn = d3eParams?.tTextViewSubHeaderColorOn;

    this.tTextViewSubHeaderFontFamilyOn =
      d3eParams?.tTextViewSubHeaderFontFamilyOn;

    this.tTextViewSubHeaderPaddingOn = d3eParams?.tTextViewSubHeaderPaddingOn;

    this.tTextViewSubHeader1ColorOn = d3eParams?.tTextViewSubHeader1ColorOn;

    this.tTextViewSubHeader1FontWeightOn =
      d3eParams?.tTextViewSubHeader1FontWeightOn;

    this.tTextViewSubHeader1FontFamilyOn =
      d3eParams?.tTextViewSubHeader1FontFamilyOn;

    this.tTextViewSubHeader1PaddingOn = d3eParams?.tTextViewSubHeader1PaddingOn;

    this.tButtonSuccessDecorationOn = d3eParams?.tButtonSuccessDecorationOn;

    this.tButtonSuccessPaddingOn = d3eParams?.tButtonSuccessPaddingOn;

    this.tButtonSuccessConstraintsOn = d3eParams?.tButtonSuccessConstraintsOn;

    this.tButtonSuccessTextStyleOn = d3eParams?.tButtonSuccessTextStyleOn;

    this.tButtonSuccessDecorationOnHover =
      d3eParams?.tButtonSuccessDecorationOnHover;

    this.tButtonSuccessPaddingOnHover = d3eParams?.tButtonSuccessPaddingOnHover;

    this.tButtonSuccessConstraintsOnHover =
      d3eParams?.tButtonSuccessConstraintsOnHover;

    this.tButtonSuccessTextStyleOnHover =
      d3eParams?.tButtonSuccessTextStyleOnHover;

    this.tButtonSuccessOutlineDecorationOn =
      d3eParams?.tButtonSuccessOutlineDecorationOn;

    this.tButtonSuccessOutlinePaddingOn =
      d3eParams?.tButtonSuccessOutlinePaddingOn;

    this.tButtonSuccessOutlineTextStyleOn =
      d3eParams?.tButtonSuccessOutlineTextStyleOn;

    this.tButtonSuccessOutlineConstraintsOn =
      d3eParams?.tButtonSuccessOutlineConstraintsOn;

    this.tButtonSuccessOutlinePaddingOnHover =
      d3eParams?.tButtonSuccessOutlinePaddingOnHover;

    this.tButtonSuccessOutlineDecorationOnHover =
      d3eParams?.tButtonSuccessOutlineDecorationOnHover;

    this.tButtonSuccessOutlineConstraintsOnHover =
      d3eParams?.tButtonSuccessOutlineConstraintsOnHover;

    this.tButtonSuccessOutlineTextStyleOnHover =
      d3eParams?.tButtonSuccessOutlineTextStyleOnHover;

    this.tTextViewTableCellTextFontSizeOn =
      d3eParams?.tTextViewTableCellTextFontSizeOn;

    this.tTextViewTableCellTextColorOn =
      d3eParams?.tTextViewTableCellTextColorOn;

    this.tTextViewTableCellTextFontFamilyOn =
      d3eParams?.tTextViewTableCellTextFontFamilyOn;

    this.tTextViewTableHeaderColorOn = d3eParams?.tTextViewTableHeaderColorOn;

    this.tTextViewTableHeaderFontFamilyOn =
      d3eParams?.tTextViewTableHeaderFontFamilyOn;

    this.tTextViewTableHeaderFontWeightOn =
      d3eParams?.tTextViewTableHeaderFontWeightOn;

    this.tTableRowTableRowOnebgDecorationOn =
      d3eParams?.tTableRowTableRowOnebgDecorationOn;

    this.tTableRowTableRowOnebgPaddingOn =
      d3eParams?.tTableRowTableRowOnebgPaddingOn;

    this.tTableRowTableRowTwobgDecorationOn =
      d3eParams?.tTableRowTableRowTwobgDecorationOn;

    this.tTableRowTableRowTwobgPaddingOn =
      d3eParams?.tTableRowTableRowTwobgPaddingOn;

    this.tButtonTextSideBarTextStyleOn =
      d3eParams?.tButtonTextSideBarTextStyleOn;

    this.tButtonWarningDecorationOn = d3eParams?.tButtonWarningDecorationOn;

    this.tButtonWarningConstraintsOn = d3eParams?.tButtonWarningConstraintsOn;

    this.tButtonWarningTextStyleOn = d3eParams?.tButtonWarningTextStyleOn;

    this.tButtonWarningDecorationOnHover =
      d3eParams?.tButtonWarningDecorationOnHover;

    this.tButtonWarningTextStyleOnHover =
      d3eParams?.tButtonWarningTextStyleOnHover;

    this.tButtonWarningOutlineDecorationOn =
      d3eParams?.tButtonWarningOutlineDecorationOn;

    this.tButtonWarningOutlineTextStyleOn =
      d3eParams?.tButtonWarningOutlineTextStyleOn;

    this.tButtonWarningOutlineConstraintsOn =
      d3eParams?.tButtonWarningOutlineConstraintsOn;

    this.tButtonWarningOutlineDecorationOnHover =
      d3eParams?.tButtonWarningOutlineDecorationOnHover;

    this.tButtonWarningOutlineTextStyleOnHover =
      d3eParams?.tButtonWarningOutlineTextStyleOnHover;

    this.tButtonWhitePrimaryNewDecorationOn =
      d3eParams?.tButtonWhitePrimaryNewDecorationOn;

    this.tButtonWhitePrimaryNewPaddingOn =
      d3eParams?.tButtonWhitePrimaryNewPaddingOn;

    this.tButtonWhitePrimaryNewTextStyleOn =
      d3eParams?.tButtonWhitePrimaryNewTextStyleOn;

    this.c1 = d3eParams?.c1;

    this.c2 = d3eParams?.c2;

    this.c3 = d3eParams?.c3;

    this.c4 = d3eParams?.c4;

    this.c5 = d3eParams?.c5;

    this.c6 = d3eParams?.c6;

    this.c7 = d3eParams?.c7;

    this.c8 = d3eParams?.c8;

    this.c9 = d3eParams?.c9;

    this.c10 = d3eParams?.c10;

    this.c11 = d3eParams?.c11;

    this.c12 = d3eParams?.c12;

    this.c13 = d3eParams?.c13;

    this.c14 = d3eParams?.c14;

    this.c15 = d3eParams?.c15;

    this.c16 = d3eParams?.c16;

    this.c17 = d3eParams?.c17;

    this.c18 = d3eParams?.c18;

    this.c19 = d3eParams?.c19;

    this.c20 = d3eParams?.c20;

    this.c21 = d3eParams?.c21;

    this.c22 = d3eParams?.c22;

    this.c23 = d3eParams?.c23;

    this.c24 = d3eParams?.c24;

    this.c25 = d3eParams?.c25;

    this.c26 = d3eParams?.c26;

    this.c27 = d3eParams?.c27;

    this.c28 = d3eParams?.c28;

    this.c29 = d3eParams?.c29;

    this.c30 = d3eParams?.c30;

    this.c31 = d3eParams?.c31;

    this.c32 = d3eParams?.c32;

    this.c33 = d3eParams?.c33;

    this.c34 = d3eParams?.c34;

    this.c35 = d3eParams?.c35;

    this.c36 = d3eParams?.c36;

    this.c37 = d3eParams?.c37;
  }
  public static createD3ELight(): StyleThemeData {
    StyleThemeData.D3ELight = new StyleThemeData({
      themeName: "D3ELight",
      color: new ui.Color(0xffffffff),
      textStyle: new ui.TextStyle({
        color: new ui.Color(0xff000000),
        fontFamily: "Open Sans",
        fontSize: 14.0,
      }),
      tooltipBackgroundColor: new ui.Color(0xffffffff),
      tooltipTextColor: new ui.Color(0xff000000),
      tTextViewBlue11ColorOn: new ui.Color(0xffe71306),
      tTextViewBlue11FontSizeOn: 13.5,
      tButtonCalendarButtonDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        border: ui.Border.all({ color: new ui.Color(0xffe0e0e0), width: 1.0 }),
        boxShadow: [
          new ui.BoxShadow({
            color: new ui.Color(0xffccc8c8),
            blurRadius: 3.0,
            spreadRadius: 1.0,
            offset: ui.OffsetExt.getOffset({ dx: 2.0, dy: 2.0 }),
          }),
        ],
        transitions: new Map(),
      }),
      tButtonCalendarButtonPaddingOn: ui.EdgeInsets.symmetric({
        horizontal: 10.0,
        vertical: 4.0,
        transitions: new Map(),
      }),
      tButtonCalendarButtonTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff464646),
        fontWeight: ui.FontWeight.bold,
        fontSize: 13.0,
        fontFamily: "Open Sans",
      }),
      tButtonCalendarButtonDecorationOnHover: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        border: ui.Border.all({ color: new ui.Color(0xffe0e0e0), width: 1.0 }),
        boxShadow: [
          new ui.BoxShadow({
            color: new ui.Color(0xffccc8c8),
            blurRadius: 5.0,
            spreadRadius: 3.0,
          }),
        ],
        transitions: new Map(),
      }),
      tTableCalendarTableBorderOn: new ui.TableBorder({
        horizontalInside: new ui.BorderSide({
          color: new ui.Color(0xffced4da),
          width: 0.5,
          style: ui.BorderStyle.solid,
        }),
        verticalInside: new ui.BorderSide({
          color: new ui.Color(0xffced4da),
          width: 0.5,
          style: ui.BorderStyle.solid,
        }),
        bottom: new ui.BorderSide({
          color: new ui.Color(0xffced4da),
          width: 0.5,
          style: ui.BorderStyle.solid,
        }),
        top: new ui.BorderSide({
          color: new ui.Color(0xffced4da),
          width: 0.5,
          style: ui.BorderStyle.solid,
        }),
        right: new ui.BorderSide({
          color: new ui.Color(0xffced4da),
          width: 0.5,
          style: ui.BorderStyle.solid,
        }),
        left: new ui.BorderSide({
          color: new ui.Color(0xffced4da),
          width: 0.5,
          style: ui.BorderStyle.solid,
        }),
      }),
      tColumnBannerFormBGPaddingOn: ui.EdgeInsets.all(25.0, new Map()),
      tColumnBannerFormBGDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        border: ui.Border.all({ color: new ui.Color(0xffffffff), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(5.0),
        transitions: new Map(),
      }),
      tTextViewBlueColorOn: new ui.Color(0xff0000ff),
      tTextViewBlueFontSizeOn: 13.5,
      tButtonDangerDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffe71306),
        transitions: new Map(),
      }),
      tButtonDangerPaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonDangerConstraintsOn: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonDangerTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonDangerDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xfffe766c),
        transitions: new Map(),
      }),
      tButtonDangerPaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonDangerConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonDangerTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonDangerOutlineDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xffe71306), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffffffff),
        transitions: new Map(),
      }),
      tButtonDangerOutlinePaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonDangerOutlineTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffe71306),
        fontFamily: "Open Sans",
      }),
      tButtonDangerOutlineConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonDangerOutlinePaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonDangerOutlineDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xfffe766c),
        transitions: new Map(),
      }),
      tButtonDangerOutlineConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonDangerOutlineTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonDarkDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff000000),
        transitions: new Map(),
      }),
      tButtonDarkPaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonDarkConstraintsOn: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonDarkTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonDarkDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff2d2d2d),
        transitions: new Map(),
      }),
      tButtonDarkPaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonDarkConstraintsOnHover: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonDarkTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonDarkOutlineDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff000000), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffffffff),
        transitions: new Map(),
      }),
      tButtonDarkOutlinePaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonDarkOutlineTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff000000),
        fontFamily: "Open Sans",
      }),
      tButtonDarkOutlineConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonDarkOutlinePaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonDarkOutlineDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff2d2d2d),
        transitions: new Map(),
      }),
      tButtonDarkOutlineConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonDarkOutlineTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tInputFieldDefaultHeightOn: 40.0,
      tInputFieldDefaultBackgroundColorOn: new ui.Color(0xffffffff),
      tInputFieldDefaultCornerRadiusOn: 5.0,
      tInputFieldDefaultStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff2d2d2d),
        fontFamily: "Open Sans",
      }),
      tListTileDefaultDecorationOnHover: new ui.BoxDecoration({
        color: new ui.Color(0xfff3f6f9),
        transitions: new Map(),
      }),
      tListTileDefaultDecorationOnFocused: new ui.BoxDecoration({
        color: new ui.Color(0xff6bc8fc),
        transitions: new Map(),
      }),
      tListTileDefaultDecorationOnSelected: new ui.BoxDecoration({
        color: new ui.Color(0xff46b4f1),
        transitions: new Map(),
      }),
      tDropDownDefaultDropDownDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xffdcdcdc), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(13.0),
        transitions: new Map(),
      }),
      tDropDownDefaultPlaceHolderColorOn: new ui.Color(0xff2d2d2d),
      tCardDefaultColorOn: new ui.Color(0xfff3f6f9),
      tCardDefaultDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xffdcdcdc), width: 2.0 }),
        transitions: new Map(),
      }),
      tTabBarDefaultBackgroundColorOn: new ui.Color(0xffdcdcdc),
      tTabBarDefaultActiveColorOn: new ui.Color(0xffffffff),
      tDateFieldDefaultHeightOn: 40.0,
      tDateFieldDefaultCornerRadiusOn: 5.0,
      tTimeFieldDefaultHeightOn: 40.0,
      tTimeFieldDefaultCornerRadiusOn: 5.0,
      tDateTimeFieldDefaultHeightOn: 40.0,
      tDateTimeFieldDefaultCornerRadiusOn: 5.0,
      tIntegerInputFieldDefaultHeightOn: 40.0,
      tIntegerInputFieldDefaultBackgroundColorOn: new ui.Color(0xffffffff),
      tIntegerInputFieldDefaultCornerRadiusOn: 5.0,
      tIntegerInputFieldDefaultPaddingOn: ui.EdgeInsets.fromLTRB(
        5.0,
        0.0,
        0.0,
        0.0,
        new Map()
      ),
      tDoubleInputFieldDefaultHeightOn: 40.0,
      tDoubleInputFieldDefaultBackgroundColorOn: new ui.Color(0xffffffff),
      tDoubleInputFieldDefaultCornerRadiusOn: 5.0,
      tDoubleInputFieldDefaultPaddingOn: ui.EdgeInsets.fromLTRB(
        5.0,
        0.0,
        0.0,
        0.0,
        new Map()
      ),
      tBaseDefaultPopUpDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        borderRadius: ui.BorderRadius.circular(2.0),
        boxShadow: [
          new ui.BoxShadow({
            color: new ui.Color(0x32000000),
            blurRadius: 5.0,
          }),
        ],
        transitions: new Map(),
      }),
      tButtonDemoButtonDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        border: ui.Border.all({ color: new ui.Color(0xffffffff), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(5.0),
        transitions: new Map(),
      }),
      tButtonDemoButtonTextStyleOn: new ui.TextStyle({
        fontFamily: "Open Sans",
        fontSize: 16.0,
        fontWeight: ui.FontWeight.w500,
        color: new ui.Color(0xff42389d),
      }),
      tButtonDemoButtonPaddingOn: ui.EdgeInsets.all(10.0, new Map()),
      tInputFieldDisabledHeightOn: 30.0,
      tInputFieldDisabledBackgroundColorOn: new ui.Color(0xffdcdcdc),
      tInputFieldDisabledCornerRadiusOn: 2.0,
      tInputFieldDisabledStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff2d2d2d),
        fontFamily: "Open Sans",
      }),
      tInputFieldDisabledDisableOn: true,
      tTextViewDisplayHeadingFourFontSizeOn: 30.0,
      tTextViewDisplayHeadingFourMarginOn: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 10.0,
        transitions: new Map(),
      }),
      tTextViewDisplayHeadingOneFontSizeOn: 60.0,
      tTextViewDisplayHeadingOneMarginOn: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 10.0,
        transitions: new Map(),
      }),
      tTextViewDisplayHeadingThreeFontSizeOn: 40.0,
      tTextViewDisplayHeadingThreeMarginOn: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 10.0,
        transitions: new Map(),
      }),
      tTextViewDisplayHeadingTwoFontSizeOn: 50.0,
      tTextViewDisplayHeadingTwoMarginOn: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 10.0,
        transitions: new Map(),
      }),
      tTextViewDropDownBuilderTextColorOn: new ui.Color(0xff000000),
      tTextViewDropDownBuilderTextPaddingOn: ui.EdgeInsets.fromLTRB(
        5.0,
        4.0,
        0.0,
        4.0,
        new Map()
      ),
      tDropDownDropdownWithAllBordersHeightOn: 40.0,
      tDropDownDropdownWithAllBordersViewDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        transitions: new Map(),
      }),
      tDropDownDropdownWithAllBordersDropDownDecorationOn: new ui.BoxDecoration(
        {
          border: ui.Border.all({
            color: new ui.Color(0xffdcdcdc),
            width: 1.0,
          }),
          borderRadius: ui.BorderRadius.circular(7.0),
          transitions: new Map(),
        }
      ),
      tDropDownDropdownWithAllBordersPlaceHolderColorOn: new ui.Color(
        0xff2d2d2d
      ),
      tTextViewErrorColorOn: new ui.Color(0xffe71306),
      tTextViewErrorTextAlignOn: ui.TextAlign.start,
      tTextViewErrorMarginOn: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 5.0,
        transitions: new Map(),
      }),
      tTextViewErrorLabelColorOn: new ui.Color(0xffe71306),
      tTextViewErrorLabelFontFamilyOn: "Open Sans",
      tTextViewErrorLabelFontSizeOn: 14.0,
      tTextViewErrorLabelMarginOn: ui.EdgeInsets.fromLTRB(
        0.0,
        5.0,
        0.0,
        0.0,
        new Map()
      ),
      tTextViewErrorTextColorOn: new ui.Color(0xffe71306),
      tBaseFieldViewMarginOn: ui.EdgeInsets.fromLTRB(
        4.0,
        4.0,
        4.0,
        0.0,
        new Map()
      ),
      tTextViewHeadingFontSizeOn: 18.0,
      tTextViewHeadingFontWeightOn: ui.FontWeight.w600,
      tTextViewHeadingTextAlignOn: ui.TextAlign.start,
      tTextViewHeadingColorOn: new ui.Color(0xff2d2d2d),
      tTextViewHeadingFontFamilyOn: "Open Sans",
      tTextViewHeadlineFiveFontSizeOn: 13.0,
      tTextViewHeadlineFiveFontWeightOn: ui.FontWeight.bold,
      tTextViewHeadlineFourFontSizeOn: 16.0,
      tTextViewHeadlineFourFontWeightOn: ui.FontWeight.bold,
      tTextViewHeadlineOneFontSizeOn: 32.0,
      tTextViewHeadlineOneFontWeightOn: ui.FontWeight.bold,
      tTextViewHeadlineSixFontSizeOn: 10.0,
      tTextViewHeadlineSixFontWeightOn: ui.FontWeight.bold,
      tTextViewHeadlineThreeFontSizeOn: 18.0,
      tTextViewHeadlineThreeFontWeightOn: ui.FontWeight.bold,
      tTextViewHeadlineTwoFontSizeOn: 24.0,
      tTextViewHeadlineTwoFontWeightOn: ui.FontWeight.bold,
      tTextViewLabelColorOn: new ui.Color(0xff2d2d2d),
      tTextViewLabelTextAlignOn: ui.TextAlign.start,
      tTextViewLabelMarginOn: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 5.0,
        transitions: new Map(),
      }),
      tTextViewLabelTextColorOn: new ui.Color(0xff00875a),
      tTextViewLabelTextTextAlignOn: ui.TextAlign.start,
      tTextViewLabelTextFontSizeOn: 13.0,
      tTextViewLabelTextFontWeightOn: ui.FontWeight.w600,
      tInputFieldLargeHeightOn: 60.0,
      tInputFieldLargeBackgroundColorOn: new ui.Color(0xffffffff),
      tInputFieldLargeCornerRadiusOn: 2.0,
      tInputFieldLargeStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff2d2d2d),
        fontFamily: "Open Sans",
      }),
      tButtonLightDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffdcdcdc),
        transitions: new Map(),
      }),
      tButtonLightPaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonLightConstraintsOn: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonLightTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonLightDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff0000ff),
        transitions: new Map(),
      }),
      tButtonLightPaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonLightConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonLightTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tBaseLightColorWithMarginDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xfff3f6f9),
        transitions: new Map(),
      }),
      tBaseLightColorWithMarginMarginOn: ui.EdgeInsets.fromLTRB(
        8.0,
        0.0,
        8.0,
        8.0,
        new Map()
      ),
      tButtonLightOutlineDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xffdcdcdc), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffffffff),
        transitions: new Map(),
      }),
      tButtonLightOutlinePaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonLightOutlineTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffdcdcdc),
        fontFamily: "Open Sans",
      }),
      tButtonLightOutlineConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonLightOutlinePaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonLightOutlineDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xfff5f5f5),
        transitions: new Map(),
      }),
      tButtonLightOutlineConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonLightOutlineTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff000000),
        fontFamily: "Open Sans",
      }),
      tButtonLinkTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff0000ff),
        fontFamily: "Open Sans",
      }),
      tButtonLinkPaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonLinkConstraintsOn: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonLinkDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0x0),
        transitions: new Map(),
      }),
      tButtonLinkPaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonLinkTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff0000ff),
        decoration: ui.TextDecoration.underline,
        fontFamily: "Open Sans",
      }),
      tButtonLinkDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0x0),
        transitions: new Map(),
      }),
      tButtonLinkConstraintsOnHover: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonLinkButtonTextStyleOn: new ui.TextStyle({
        fontFamily: "Open Sans",
        fontSize: 16.0,
        color: new ui.Color(0xff42389d),
      }),
      tButtonLinkButtonTextStyleOnHover: new ui.TextStyle({
        fontFamily: "Open Sans",
        decoration: ui.TextDecoration.underline,
        fontSize: 16.0,
        color: new ui.Color(0xff42389d),
      }),
      tCellButtonLinkCellButtonTextStyleOn: new ui.TextStyle({
        fontFamily: "Open Sans",
        fontSize: 16.0,
        color: new ui.Color(0xff42389d),
      }),
      tCellButtonLinkCellButtonPaddingOn: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 4.0,
        transitions: new Map(),
      }),
      tCellButtonLinkCellButtonTextStyleOnHover: new ui.TextStyle({
        fontFamily: "Open Sans",
        decoration: ui.TextDecoration.underline,
        fontSize: 16.0,
        color: new ui.Color(0xff42389d),
      }),
      tCellButtonLinkCellButtonPaddingOnHover: ui.EdgeInsets.symmetric({
        horizontal: 0.0,
        vertical: 4.0,
        transitions: new Map(),
      }),
      tListTileListTileDecorationOnHover: new ui.BoxDecoration({
        color: new ui.Color(0xfff3f6f9),
        transitions: new Map(),
      }),
      tListTileListTileDecorationOnFocused: new ui.BoxDecoration({
        color: new ui.Color(0xff6bc8fc),
        transitions: new Map(),
      }),
      tListTileListTileDecorationOnSelected: new ui.BoxDecoration({
        color: new ui.Color(0xff46b4f1),
        transitions: new Map(),
      }),
      tListTileListTileDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        transitions: new Map(),
      }),
      tBaseListViewBgColorDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xfff3f6f9),
        transitions: new Map(),
      }),
      tBaseListViewBgColorMarginOn: ui.EdgeInsets.fromLTRB(
        8.0,
        0.0,
        8.0,
        8.0,
        new Map()
      ),
      tTextViewNameTextColorOn: new ui.Color(0xff000000),
      tTextViewNameTextFontWeightOn: ui.FontWeight.w500,
      tTextViewNameTextFontFamilyOn: "Open Sans",
      tTextViewNameTextFontSizeOn: 14.0,
      tBaseP1PaddingOn: ui.EdgeInsets.all(8.0, new Map()),
      tBaseP15PaddingOn: ui.EdgeInsets.all(15.0, new Map()),
      tButtonPrimaryDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xff362d8a),
        border: ui.Border.all({ color: new ui.Color(0xff362d8a), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        transitions: new Map(),
      }),
      tButtonPrimaryPaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonPrimaryTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonPrimaryConstraintsOn: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonPrimaryDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff6bc8fc),
        transitions: new Map(),
      }),
      tButtonPrimaryPaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonPrimaryConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonPrimaryTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonPrimaryButtonDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xff42389d),
        border: ui.Border.all({ color: new ui.Color(0xff42389d), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(5.0),
        transitions: new Map(),
      }),
      tButtonPrimaryButtonTextStyleOn: new ui.TextStyle({
        fontFamily: "Open Sans",
        fontSize: 16.0,
        fontWeight: ui.FontWeight.w500,
        color: new ui.Color(0xffffffff),
      }),
      tButtonPrimaryButtonPaddingOn: ui.EdgeInsets.all(10.0, new Map()),
      tButtonPrimaryNewDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xff42389d),
        border: ui.Border.all({ color: new ui.Color(0xff42389d), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        transitions: new Map(),
      }),
      tButtonPrimaryNewPaddingOn: ui.EdgeInsets.all(10.0, new Map()),
      tButtonPrimaryNewTextStyleOn: new ui.TextStyle({
        fontFamily: "Open Sans",
        fontSize: 16.0,
        fontWeight: ui.FontWeight.w600,
        color: new ui.Color(0xffffffff),
      }),
      tButtonPrimaryNewDecorationOnHover: new ui.BoxDecoration({
        color: new ui.Color(0xff362d8a),
        border: ui.Border.all({ color: new ui.Color(0xff362d8a), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        transitions: new Map(),
      }),
      tButtonPrimaryNewPaddingOnHover: ui.EdgeInsets.all(10.0, new Map()),
      tButtonPrimaryNewTextStyleOnHover: new ui.TextStyle({
        fontFamily: "Open Sans",
        fontSize: 16.0,
        fontWeight: ui.FontWeight.w600,
        color: new ui.Color(0xffffffff),
      }),
      tButtonPrimaryOrangeDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff6bc8fc),
        transitions: new Map(),
      }),
      tButtonPrimaryOrangePaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonPrimaryOrangeConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonPrimaryOrangeTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonPrimaryOrangePaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonPrimaryOrangeDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff6bc8fc),
        transitions: new Map(),
      }),
      tButtonPrimaryOrangeTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonPrimaryOrangeConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonPrimaryOrangeOutlineDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff46b4f1), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffffffff),
        transitions: new Map(),
      }),
      tButtonPrimaryOrangeOutlinePaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonPrimaryOrangeOutlineTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff2d2d2d),
        fontFamily: "Open Sans",
      }),
      tButtonPrimaryOrangeOutlineConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonPrimaryOrangeOutlinePaddingOnHover: ui.EdgeInsets.all(
        7.0,
        new Map()
      ),
      tButtonPrimaryOrangeOutlineDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff0000ff),
        transitions: new Map(),
      }),
      tButtonPrimaryOrangeOutlineConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonPrimaryOrangeOutlineTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonPrimaryOutlineDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff46b4f1), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffffffff),
        transitions: new Map(),
      }),
      tButtonPrimaryOutlinePaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonPrimaryOutlineTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff46b4f1),
        fontFamily: "Open Sans",
      }),
      tButtonPrimaryOutlineConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonPrimaryOutlinePaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonPrimaryOutlineDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffdcdcdc),
        transitions: new Map(),
      }),
      tButtonPrimaryOutlineConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonPrimaryOutlineTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff46b4f1),
        fontFamily: "Open Sans",
      }),
      tButtonRentalPrimaryButtonDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xff766dfe),
        border: ui.Border.all({ color: new ui.Color(0xffffff), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(5.0),
        transitions: new Map(),
      }),
      tButtonRentalPrimaryButtonPaddingOn: ui.EdgeInsets.all(10.0, new Map()),
      tButtonRentalPrimaryButtonTextStyleOn: new ui.TextStyle({
        fontSize: 14.0,
        fontWeight: ui.FontWeight.w500,
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonRentalPrimaryButtonDecorationOnSelected: new ui.BoxDecoration({
        color: new ui.Color(0xff766dfe),
        transitions: new Map(),
      }),
      tButtonRentalSecondaryButtonDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        border: ui.Border.all({ color: new ui.Color(0xffffff), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(5.0),
        transitions: new Map(),
      }),
      tButtonRentalSecondaryButtonTextStyleOn: new ui.TextStyle({
        fontSize: 14.0,
        fontWeight: ui.FontWeight.w500,
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonRentalSecondaryButtonPaddingOn: ui.EdgeInsets.all(10.0, new Map()),
      tDropDownRoundRadiusDropDownHeightOn: 35.0,
      tDropDownRoundRadiusDropDownOnHoverColorOn: new ui.Color(0xfff3f6f9),
      tDropDownRoundRadiusDropDownPlaceHolderColorOn: new ui.Color(0xff2d2d2d),
      tDropDownRoundRadiusDropDownDropDownDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff000000), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffffffff),
        transitions: new Map(),
      }),
      tDropDownRoundRadiusDropDownViewDecorationOn: new ui.BoxDecoration({
        border: new ui.Border({
          right: new ui.BorderSide({
            color: new ui.Color(0xff000000),
            width: 1.0,
          }),
          left: new ui.BorderSide({
            color: new ui.Color(0xff000000),
            width: 1.0,
          }),
          bottom: new ui.BorderSide({
            color: new ui.Color(0xff000000),
            width: 1.0,
          }),
        }),
        color: new ui.Color(0xffffffff),
        transitions: new Map(),
      }),
      tBaseScreenBgColorMarginOn: ui.EdgeInsets.fromLTRB(
        8.0,
        0.0,
        8.0,
        8.0,
        new Map()
      ),
      tInputFieldSearchInputfieldInActiveColorOn: new ui.Color(0xffffffff),
      tInputFieldSearchInputfieldPlaceHolderColorOn: new ui.Color(0xff888888),
      tInputFieldSearchInputfieldActiveColorOn: new ui.Color(0xffffffff),
      tInputFieldSearchInputfieldPaddingOn: ui.EdgeInsets.fromLTRB(
        0.0,
        4.0,
        2.0,
        4.0,
        new Map()
      ),
      tButtonSecondaryDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffffffff),
        transitions: new Map(),
      }),
      tButtonSecondaryPaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonSecondaryConstraintsOn: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonSecondaryTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff46b4f1),
        fontFamily: "Open Sans",
      }),
      tButtonSecondaryDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffdcdcdc),
        transitions: new Map(),
      }),
      tButtonSecondaryPaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonSecondaryConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonSecondaryTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff46b4f1),
        fontFamily: "Open Sans",
      }),
      tButtonSecondaryNewPaddingOn: ui.EdgeInsets.all(10.0, new Map()),
      tButtonSecondaryNewDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        border: ui.Border.all({ color: new ui.Color(0xff42389d), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        transitions: new Map(),
      }),
      tButtonSecondaryNewTextStyleOn: new ui.TextStyle({
        fontFamily: "Open Sans",
        fontSize: 16.0,
        fontWeight: ui.FontWeight.w500,
        color: new ui.Color(0xff42389d),
      }),
      tButtonSecondaryNewDecorationOnHover: new ui.BoxDecoration({
        color: new ui.Color(0xfff3f6f9),
        border: ui.Border.all({ color: new ui.Color(0xff42389d), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        transitions: new Map(),
      }),
      tButtonSecondaryNewPaddingOnHover: ui.EdgeInsets.all(10.0, new Map()),
      tButtonSecondaryNewTextStyleOnHover: new ui.TextStyle({
        fontFamily: "Open Sans",
        fontSize: 16.0,
        fontWeight: ui.FontWeight.w500,
        color: new ui.Color(0xff42389d),
      }),
      tButtonSecondaryOutlineDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff46b4f1), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffffffff),
        transitions: new Map(),
      }),
      tButtonSecondaryOutlinePaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonSecondaryOutlineTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff46b4f1),
        fontFamily: "Open Sans",
      }),
      tButtonSecondaryOutlineConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonSecondaryOutlinePaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonSecondaryOutlineDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffdcdcdc),
        transitions: new Map(),
      }),
      tButtonSecondaryOutlineConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonSecondaryOutlineTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xff46b4f1),
        fontFamily: "Open Sans",
      }),
      tInputFieldSmallHeightOn: 30.0,
      tInputFieldSmallBackgroundColorOn: new ui.Color(0xffffffff),
      tInputFieldSmallCornerRadiusOn: 2.0,
      tInputFieldSmallStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff2d2d2d),
        fontFamily: "Open Sans",
      }),
      tTextViewSubHeaderColorOn: new ui.Color(0xff000000),
      tTextViewSubHeaderFontFamilyOn: "Open Sans",
      tTextViewSubHeaderPaddingOn: ui.EdgeInsets.fromLTRB(
        4.0,
        4.0,
        4.0,
        0.0,
        new Map()
      ),
      tTextViewSubHeader1ColorOn: new ui.Color(0xff000000),
      tTextViewSubHeader1FontWeightOn: ui.FontWeight.bold,
      tTextViewSubHeader1FontFamilyOn: "Open Sans",
      tTextViewSubHeader1PaddingOn: ui.EdgeInsets.fromLTRB(
        4.0,
        4.0,
        4.0,
        0.0,
        new Map()
      ),
      tButtonSuccessDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff00875a),
        transitions: new Map(),
      }),
      tButtonSuccessPaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonSuccessConstraintsOn: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonSuccessTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonSuccessDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff25bb89),
        transitions: new Map(),
      }),
      tButtonSuccessPaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonSuccessConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonSuccessTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonSuccessOutlineDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff00875a), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffffffff),
        transitions: new Map(),
      }),
      tButtonSuccessOutlinePaddingOn: ui.EdgeInsets.all(7.0, new Map()),
      tButtonSuccessOutlineTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xff00875a),
        fontFamily: "Open Sans",
      }),
      tButtonSuccessOutlineConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonSuccessOutlinePaddingOnHover: ui.EdgeInsets.all(7.0, new Map()),
      tButtonSuccessOutlineDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xff25bb89),
        transitions: new Map(),
      }),
      tButtonSuccessOutlineConstraintsOnHover: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonSuccessOutlineTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tTextViewTableCellTextFontSizeOn: 16.0,
      tTextViewTableCellTextColorOn: new ui.Color(0xff000000),
      tTextViewTableCellTextFontFamilyOn: "Open Sans",
      tTextViewTableHeaderColorOn: new ui.Color(0xff000000),
      tTextViewTableHeaderFontFamilyOn: "Open Sans",
      tTextViewTableHeaderFontWeightOn: ui.FontWeight.bold,
      tTableRowTableRowOnebgDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffe2dff3),
        border: new ui.Border({
          bottom: new ui.BorderSide({
            color: new ui.Color(0xff42389d),
            width: 0.5,
            style: ui.BorderStyle.solid,
          }),
        }),
        transitions: new Map(),
      }),
      tTableRowTableRowOnebgPaddingOn: ui.EdgeInsets.all(10.0, new Map()),
      tTableRowTableRowTwobgDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        border: new ui.Border({
          bottom: new ui.BorderSide({
            color: new ui.Color(0xff42389d),
            width: 0.5,
            style: ui.BorderStyle.solid,
          }),
        }),
        transitions: new Map(),
      }),
      tTableRowTableRowTwobgPaddingOn: ui.EdgeInsets.all(10.0, new Map()),
      tButtonTextSideBarTextStyleOn: new ui.TextStyle({
        fontFamily: "Open Sans",
        fontSize: 16.0,
        fontWeight: ui.FontWeight.w600,
        color: new ui.Color(0xffffffff),
      }),
      tButtonWarningDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffdec150),
        transitions: new Map(),
      }),
      tButtonWarningConstraintsOn: new ui.BoxConstraints({ minHeight: 30.0 }),
      tButtonWarningTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonWarningDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xfff1dd91),
        transitions: new Map(),
      }),
      tButtonWarningTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonWarningOutlineDecorationOn: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xffdec150), width: 2.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xffffffff),
        transitions: new Map(),
      }),
      tButtonWarningOutlineTextStyleOn: new ui.TextStyle({
        color: new ui.Color(0xfff1dd91),
        fontFamily: "Open Sans",
      }),
      tButtonWarningOutlineConstraintsOn: new ui.BoxConstraints({
        minHeight: 30.0,
      }),
      tButtonWarningOutlineDecorationOnHover: new ui.BoxDecoration({
        border: ui.Border.all({ color: new ui.Color(0xff13aed9), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        color: new ui.Color(0xfff1dd91),
        transitions: new Map(),
      }),
      tButtonWarningOutlineTextStyleOnHover: new ui.TextStyle({
        color: new ui.Color(0xffffffff),
        fontFamily: "Open Sans",
      }),
      tButtonWhitePrimaryNewDecorationOn: new ui.BoxDecoration({
        color: new ui.Color(0xffffffff),
        border: ui.Border.all({ color: new ui.Color(0xffffffff), width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(3.0),
        transitions: new Map(),
      }),
      tButtonWhitePrimaryNewPaddingOn: ui.EdgeInsets.all(10.0, new Map()),
      tButtonWhitePrimaryNewTextStyleOn: new ui.TextStyle({
        fontFamily: "Open Sans",
        fontSize: 16.0,
        fontWeight: ui.FontWeight.w600,
        color: new ui.Color(0xffffffff),
      }),
      c1: new ui.Color(0xff46b4f1),
      c2: new ui.Color(0xffffffff),
      c3: new ui.Color(0xff6bc8fc),
      c4: new ui.Color(0xffdcdcdc),
      c5: new ui.Color(0xff007acc),
      c6: new ui.Color(0xff00875a),
      c7: new ui.Color(0xff25bb89),
      c8: new ui.Color(0xffe71306),
      c9: new ui.Color(0xfffe766c),
      c10: new ui.Color(0xffdec150),
      c11: new ui.Color(0xfff1dd91),
      c12: new ui.Color(0xff000000),
      c13: new ui.Color(0xff2d2d2d),
      c14: new ui.Color(0xffdcdcdc),
      c15: new ui.Color(0xfff5f5f5),
      c16: new ui.Color(0xff0000ff),
      c17: new ui.Color(0xfff3f6f9),
      c18: new ui.Color(0xff13aed9),
      c19: new ui.Color(0xff80a702),
      c20: new ui.Color(0xff42389d),
      c21: new ui.Color(0xffecf2fe),
      c22: new ui.Color(0xfff8f9fb),
      c23: new ui.Color(0xffffff),
      c24: new ui.Color(0xff888888),
      c25: new ui.Color(0xff0000),
      c26: new ui.Color(0xff0071da),
      c27: new ui.Color(0xfffdfdfd),
      c28: new ui.Color(0xff766dfe),
      c29: new ui.Color(0xff00a8df),
      c30: new ui.Color(0xff888888),
      c31: new ui.Color(0xff6e797f),
      c32: new ui.Color(0xff2473b3),
      c33: new ui.Color(0xff424243),
      c34: new ui.Color(0xffffffff),
      c35: new ui.Color(0xff00c8af),
      c36: new ui.Color(0xffced4da),
      c37: new ui.Color(0xffe2dff3),
    });

    return StyleThemeData.D3ELight;
  }
}

interface ThemeWrapperProps {
  child: ReactNode;
}

class _ThemeWrapper extends React.Component<ThemeWrapperProps> {
  public render(): React.ReactNode {
    DomUtils.loadTheme(StyleThemeData.current.themeName);

    return React.createElement(
      BuildContext.Provider,
      { value: { ...this.context, theme: StyleThemeData.current } },
      this.props.child
    );
  }
}
export default function ThemeWrapper(props: ThemeWrapperProps) {
  return React.createElement(_ThemeWrapper, props);
}

export const ThemeContext = React.createContext<StyleThemeData>(null);
