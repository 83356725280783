import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import PageNavigator from "../classes/PageNavigator";
import Renter from "../models/Renter";
import Facility from "../models/Facility";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

export interface FacilityCardViewProps extends BaseUIProps {
  key?: string;
  facility: Facility;
  user: Renter;
}

class _FacilityCardViewState extends ObservableComponent<FacilityCardViewProps> {
  static defaultProps = { facility: null, user: null };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: FacilityCardViewProps) {
    super(props);

    this.initState();
  }
  public get facility(): Facility {
    return this.props.facility;
  }
  public get user(): Renter {
    return this.props.user;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("facility", this.props.facility);

    this.updateSyncProperty("user", this.props.user);

    this.on(
      [
        "facility",
        "facility.images",
        "facility.name",
        "facility.school",
        "facility.school.address",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: FacilityCardViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.facility !== this.props.facility) {
      this.updateObservable(
        "facility",
        prevProps.facility,
        this.props.facility
      );

      this.fire("facility", this);
    }

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      decoration: new ui.BoxDecoration({
        borderRadius: ui.BorderRadius.circular(5.0),
        image: new ui.DecorationImage({
          imageUrl: this.facility.images.first.downloadUrl,
          fit: ui.BoxFit.fill,
        }),
      }),
      child: ui.Column({
        crossAxisAlignment: ui.CrossAxisAlignment.start,
        children: [
          TextView({
            data: this.facility.name,
            style: new ui.TextStyle({
              color: cStyle.c2,
              fontSize: cStyle.tTextViewHeadlineTwoFontSizeOn,
              fontWeight: cStyle.tTextViewHeadlineTwoFontWeightOn,
            }),
            className: "x97ee hc",
            key: "0",
          }),
          ui.Row({
            children: [
              IconView({
                size: 24,
                icon: MaterialIcons.location_pin,
                color: cStyle.c2,
                className: "x3a",
                key: "0",
              }),
              TextView({
                data: this.facility.school.address,
                overflow: ui.TextOverflow.ellipsis,
                softWrap: true,
                style: new ui.TextStyle({
                  color: cStyle.c2,
                  fontWeight: ui.FontWeight.bold,
                  fontSize: 12,
                }),
                className: "x03d7 hc h",
                key: "1",
              }),
            ],
            className: "x251 hc h",
            key: "1",
          }),
        ],
        onTap: (e) => {
          e.stopPropagation();

          this.onPressColumn3();
        },
        className: "xe44 hc vc h v",
      }),
      className: ui.join(this.props.className, "FacilityCardView x19 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressColumn3 = (): void => {
    this.navigator.pushBookingPage({
      facility: this.facility,
      user: this.user,
    });
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function FacilityCardView(props: FacilityCardViewProps) {
  return React.createElement(_FacilityCardViewState, {
    ..._FacilityCardViewState.defaultProps,
    ...props,
  });
}
