export default class AdminRole {
  public static readonly School = new AdminRole("School", "School", 0);
  public static readonly Admin = new AdminRole("Admin", "Admin", 1);
  public static readonly Support = new AdminRole("Support", "Support", 2);
  private _ident: string;
  private _name: string;
  private _index: number;
  public constructor(_ident: string, _name: string, _index: number) {
    this._ident = _ident;

    this._name = _name;

    this._index = _index;
  }
  public static get values(): AdminRole[] {
    return [AdminRole.School, AdminRole.Admin, AdminRole.Support];
  }
  public get ident(): string {
    return this._ident;
  }
  public get name(): string {
    return this._name;
  }
  public get index(): number {
    return this._index;
  }
  public toString(): string {
    return this._name;
  }
}
