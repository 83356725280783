import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import RentalRequest from "../models/RentalRequest";
import PageNavigator from "../classes/PageNavigator";
import Renter from "../models/Renter";
import Query from "../classes/Query";
import MessageDispatch from "../rocket/MessageDispatch";
import DateTimeView from "./DateTimeView";
import CellButton from "./CellButton";
import ListWrapper from "../utils/ListWrapper";
import PaymentHistory from "../classes/PaymentHistory";
import PaymentReceipt from "../models/PaymentReceipt";
import TextView from "./TextView";
import PaymentHistoryRequest from "../models/PaymentHistoryRequest";
import CollectionUtils from "../utils/CollectionUtils";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _ViewButtonOnPressed = (d3eState: _ItemState) => void;

export interface RentalPaymentHistoryPageProps extends BaseUIProps {
  key?: string;
  renter: Renter;
}
/// To store state data for RentalPaymentHistoryPage
class RentalPaymentHistoryPageRefs {
  public itemState: Map<PaymentReceipt, _ItemState> = new Map();
  public forItem(item: PaymentReceipt): _ItemState {
    let res = this.itemState.get(item);

    if (res == null) {
      res = new _ItemState(this, item);

      this.itemState.set(item, res);
    }

    return res;
  }
}

interface ViewButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _ItemState;
  _onViewButtonPressed?: _ViewButtonOnPressed;
  item: PaymentReceipt;
}

class ViewButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ViewButtonWithState extends ObservableComponent<ViewButtonWithStateProps> {
  viewButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ViewButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get item(): PaymentReceipt {
    return this.props.item;
  }
  public get viewButton(): ViewButtonState {
    return this.props.d3eState.viewButton;
  }
  public get d3eState(): _ItemState {
    return this.props.d3eState;
  }
  public get _onViewButtonPressed(): _ViewButtonOnPressed {
    return this.props._onViewButtonPressed;
  }
  public initState() {
    super.initState();

    this.updateObservable("viewButton", null, this.viewButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("item", this.props.item);

    this.on(
      [
        "item",
        "item.request",
        "item.request.requestId",
        "viewButton",
        "viewButton.",
        "viewButton.hover",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ViewButtonWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.item !== this.props.item) {
      this.updateObservable("item", prevProps.item, this.props.item);

      this.fire("item", this);
    }
  }
  public viewButtonOnEnter(event): void {
    return this.viewButton.setHover(true);
  }
  public viewButtonOnExit(event): void {
    return this.viewButton.setHover(false);
  }
  public dispose(): void {
    this.viewButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return CellButton({
      padding: this.viewButton.hover
        ? cStyle.tCellButtonLinkCellButtonPaddingOnHover
        : cStyle.tCellButtonLinkCellButtonPaddingOn,
      disable: this.viewButton.disable,
      onPressed: () => {
        this._onViewButtonPressed(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({
        data: this.item.request.requestId,
        style: new ui.TextStyle({ fontSize: 16, color: cStyle.c20 }),
        className: "xea4 hc",
      }),
      onEnter: (event) => {
        this.viewButtonOnEnter(event);
      },
      onExit: (event) => {
        this.viewButtonOnExit(event);
      },
      className: "x0ae hc",
    });
  }
}
function ViewButtonWithState(props: ViewButtonWithStateProps) {
  return React.createElement(_ViewButtonWithState, props);
}

class _ItemState {
  parent: RentalPaymentHistoryPageRefs;
  item: PaymentReceipt;
  viewButton: ViewButtonState = new ViewButtonState();
  public constructor(parent, item) {
    this.parent = parent;

    this.item = item;
  }
}

class _RentalPaymentHistoryPageState extends ObservableComponent<RentalPaymentHistoryPageProps> {
  static defaultProps = { renter: null };
  d3eState: RentalPaymentHistoryPageRefs = new RentalPaymentHistoryPageRefs();
  query: PaymentHistory = null;
  payments: Array<PaymentReceipt> = ListWrapper.widget(this, "payments");
  request: RentalRequest = null;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RentalPaymentHistoryPageProps) {
    super(props);

    this.initState();
  }
  public get renter(): Renter {
    return this.props.renter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("renter", this.props.renter);

    this.on(["renter"], this.computeQuery);

    this.computeQuery();

    this.on(["query", "query.items"], this.computePayments);

    this.computePayments();

    this.on(
      [
        "payments",
        "payments.amount",
        "payments.createdDate",
        "payments.request",
        "payments.request.eventDate",
        "payments.request.eventName",
        "payments.request.facility",
        "payments.request.facility.name",
        "payments.request.school",
        "payments.request.school.name",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RentalPaymentHistoryPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.renter !== this.props.renter) {
      this.updateObservable("renter", prevProps.renter, this.props.renter);

      this.fire("renter", this);
    }
  }
  public setQuery(val: PaymentHistory): void {
    let isValChanged: boolean = this.query !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("query", this.query, val);

    MessageDispatch.get().dispose(this.query);

    this.query = val;

    this.fire("query", this);
  }
  public computeQuery = async (): Promise<void> => {
    try {
      this.setQuery(
        await Query.get().getPaymentHistory(
          UsageConstants.QUERY_GETPAYMENTHISTORY_RENTALPAYMENTHISTORYPAGE_PROPERTIES_QUERY_COMPUTATION,
          new PaymentHistoryRequest({ renter: this.renter }),
          { "synchronize": true }
        )
      );
    } catch (exception) {
      console.log(" exception in computeQuery : " + exception.toString());

      this.setQuery(null);
    }
  };
  public setPayments(val: Array<PaymentReceipt>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.payments, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("payments", this.payments, val);

    this.payments.clear();

    this.payments.addAll(val);

    this.fire("payments", this);
  }
  public addToPayments(val: PaymentReceipt, index: number = -1): void {
    if (index === -1) {
      if (!this.payments.contains(val)) this.payments.add(val);
    } else {
      this.payments.remove(this.payments.elementAt(index));

      this.payments.add(val);
    }

    this.fire("payments", this, val, true);

    this.updateObservable("payments", null, val);
  }
  public removeFromPayments(val: PaymentReceipt): void {
    this.payments.remove(val);

    this.fire("payments", this, val, false);

    this.removeObservable("payments", val);
  }
  public computePayments = (): void => {
    try {
      this.setPayments(Array.from(this.query !== null ? this.query.items : []));
    } catch (exception) {
      console.log(" exception in computePayments : " + exception.toString());

      this.setPayments([]);
    }
  };
  public setRequest(val: RentalRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.end,
          children: [
            ui.Row({
              children: [
                TextView({
                  data: "Payment History",
                  style: new ui.TextStyle({ fontSize: 24, color: cStyle.c20 }),
                  className: "xeb2",
                  key: "0",
                }),
              ],
              className: "x421 hc h",
              key: "0",
            }),
            ui.Table({
              defaultColumnWidth: new ui.FlexColumnWidth(1),
              columnWidths: { 0: new ui.FlexColumnWidth(1) },
              children: [
                ui.TableRow({
                  children: [
                    ui.TableCell({
                      verticalAlignment: ui.TableCellVerticalAlignment.middle,
                      child: TextView({
                        data: "Res. ID",
                        style: new ui.TextStyle({
                          fontFamily: cStyle.tTextViewTableHeaderFontFamilyOn,
                          color: cStyle.tTextViewTableHeaderColorOn,
                          fontWeight: cStyle.tTextViewTableHeaderFontWeightOn,
                        }),
                        className: "hc",
                      }),
                      className: "hc",
                      key: "0",
                    }),
                    ui.TableCell({
                      verticalAlignment: ui.TableCellVerticalAlignment.middle,
                      child: TextView({
                        data: "Event Name",
                        style: new ui.TextStyle({
                          fontFamily: cStyle.tTextViewTableHeaderFontFamilyOn,
                          color: cStyle.tTextViewTableHeaderColorOn,
                          fontWeight: cStyle.tTextViewTableHeaderFontWeightOn,
                        }),
                        className: "hc",
                      }),
                      className: "hc",
                      key: "1",
                    }),
                    ui.TableCell({
                      verticalAlignment: ui.TableCellVerticalAlignment.middle,
                      child: TextView({
                        data: "Event Date",
                        style: new ui.TextStyle({
                          fontFamily: cStyle.tTextViewTableHeaderFontFamilyOn,
                          color: cStyle.tTextViewTableHeaderColorOn,
                          fontWeight: cStyle.tTextViewTableHeaderFontWeightOn,
                        }),
                        className: "hc",
                      }),
                      className: "hc",
                      key: "2",
                    }),
                    ui.TableCell({
                      verticalAlignment: ui.TableCellVerticalAlignment.middle,
                      child: TextView({
                        data: "Facility",
                        style: new ui.TextStyle({
                          fontFamily: cStyle.tTextViewTableHeaderFontFamilyOn,
                          color: cStyle.tTextViewTableHeaderColorOn,
                          fontWeight: cStyle.tTextViewTableHeaderFontWeightOn,
                        }),
                        className: "hc",
                      }),
                      className: "hc",
                      key: "3",
                    }),
                    ui.TableCell({
                      verticalAlignment: ui.TableCellVerticalAlignment.middle,
                      child: TextView({
                        data: "School",
                        style: new ui.TextStyle({
                          fontFamily: cStyle.tTextViewTableHeaderFontFamilyOn,
                          color: cStyle.tTextViewTableHeaderColorOn,
                          fontWeight: cStyle.tTextViewTableHeaderFontWeightOn,
                        }),
                        className: "hc",
                      }),
                      className: "hc",
                      key: "4",
                    }),
                    ui.TableCell({
                      verticalAlignment: ui.TableCellVerticalAlignment.middle,
                      child: TextView({
                        data: "Payment Date",
                        style: new ui.TextStyle({
                          fontFamily: cStyle.tTextViewTableHeaderFontFamilyOn,
                          color: cStyle.tTextViewTableHeaderColorOn,
                          fontWeight: cStyle.tTextViewTableHeaderFontWeightOn,
                        }),
                        className: "hc",
                      }),
                      className: "hc",
                      key: "5",
                    }),
                    ui.TableCell({
                      verticalAlignment: ui.TableCellVerticalAlignment.middle,
                      child: TextView({
                        data: "Amount Paid",
                        style: new ui.TextStyle({
                          fontFamily: cStyle.tTextViewTableHeaderFontFamilyOn,
                          color: cStyle.tTextViewTableHeaderColorOn,
                          fontWeight: cStyle.tTextViewTableHeaderFontWeightOn,
                        }),
                        className: "hc",
                      }),
                      className: "hc",
                      key: "6",
                    }),
                  ],
                  className: "xb4c hc",
                  key: "0",
                }),
                this.payments.expand((item) => [
                  ui.TableRow({
                    children: [
                      ui.TableCell({
                        verticalAlignment: ui.TableCellVerticalAlignment.middle,
                        child: ViewButtonWithState({
                          d3eState: this.d3eState.forItem(item),
                          _onViewButtonPressed: this.onViewButtonPressed,
                          item: item,
                        }),
                        className: "hc",
                        key: "0",
                      }),
                      ui.TableCell({
                        verticalAlignment: ui.TableCellVerticalAlignment.middle,
                        child: TextView({
                          data: item.request.eventName,
                          softWrap: true,
                          style: new ui.TextStyle({
                            fontFamily:
                              cStyle.tTextViewTableCellTextFontFamilyOn,
                            color: cStyle.tTextViewTableCellTextColorOn,
                            fontSize: cStyle.tTextViewTableCellTextFontSizeOn,
                          }),
                          className: "hc",
                        }),
                        className: "hc",
                        key: "1",
                      }),
                      ui.TableCell({
                        verticalAlignment: ui.TableCellVerticalAlignment.middle,
                        child: DateTimeView({
                          value: item.request.eventDate.toDateTime(),
                          format: "MMM dd, yyyy",
                          className: "hc",
                        }),
                        className: "hc",
                        key: "2",
                      }),
                      ui.TableCell({
                        verticalAlignment: ui.TableCellVerticalAlignment.middle,
                        child: TextView({
                          data: item.request.facility.name,
                          style: new ui.TextStyle({
                            fontFamily:
                              cStyle.tTextViewTableCellTextFontFamilyOn,
                            color: cStyle.tTextViewTableCellTextColorOn,
                            fontSize: cStyle.tTextViewTableCellTextFontSizeOn,
                          }),
                          className: "hc",
                        }),
                        className: "hc",
                        key: "3",
                      }),
                      ui.TableCell({
                        verticalAlignment: ui.TableCellVerticalAlignment.middle,
                        child: TextView({
                          data: item.request.school.name,
                          style: new ui.TextStyle({
                            fontFamily:
                              cStyle.tTextViewTableCellTextFontFamilyOn,
                            color: cStyle.tTextViewTableCellTextColorOn,
                            fontSize: cStyle.tTextViewTableCellTextFontSizeOn,
                          }),
                          className: "hc",
                        }),
                        className: "hc",
                        key: "4",
                      }),
                      ui.TableCell({
                        verticalAlignment: ui.TableCellVerticalAlignment.middle,
                        child: DateTimeView({
                          value: item.createdDate,
                          format: "MMM dd, yyyy hh:mm a",
                          className: "hc",
                        }),
                        className: "hc",
                        key: "5",
                      }),
                      ui.TableCell({
                        verticalAlignment: ui.TableCellVerticalAlignment.middle,
                        child: TextView({
                          data: "$" + item.amount.toString(),
                          style: new ui.TextStyle({
                            fontFamily:
                              cStyle.tTextViewTableCellTextFontFamilyOn,
                            color: cStyle.tTextViewTableCellTextColorOn,
                            fontSize: cStyle.tTextViewTableCellTextFontSizeOn,
                          }),
                          className: "hc",
                        }),
                        className: "hc",
                        key: "6",
                      }),
                    ],
                    className: "x89f hc",
                    key: item?.ident,
                  }),
                ]),
              ],
              className: "x7c5 hc h",
              key: "1",
            }),
          ],
          className: "x2b0 hc h",
          key: "0",
        }),
      ],
      className: ui.join(
        this.props.className,
        "RentalPaymentHistoryPage x40e hc vc"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onViewButtonPressed = (d3eState: _ItemState): void => {
    this.setRequest(d3eState.item.request);

    this.navigator.pushRequestOverViewPage({
      admin: null,
      renter: this.renter,
      requestObj: this.request,
      target: "rRouter",
      replace: false,
    });
  };
  public dispose(): void {
    MessageDispatch.get().dispose(this.query);

    super.dispose();
  }
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function RentalPaymentHistoryPage(
  props: RentalPaymentHistoryPageProps
) {
  return React.createElement(_RentalPaymentHistoryPageState, {
    ..._RentalPaymentHistoryPageState.defaultProps,
    ...props,
  });
}
