import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Range from "../classes/Range";
import Button from "./Button";
import CalendarHelper from "../classes/CalendarHelper";
import CalendarViewType from "../classes/CalendarViewType";
import ListWrapper from "../utils/ListWrapper";
import D3EDate from "../classes/D3EDate";
import CalendarEvent from "../classes/CalendarEvent";
import TextView from "./TextView";
import CollectionUtils from "../utils/CollectionUtils";

type _DayViewBuilder<CalendarEvent, CalendarViewType> = (
  context: any,
  event: CalendarEvent,
  viewType: CalendarViewType
) => ReactNode;

export interface DayViewProps extends BaseUIProps {
  key?: string;
  helper?: CalendarHelper;
  day: D3EDate;
  categories: Array<string>;
  _categoriesHash?: number;
  builder?: _DayViewBuilder<CalendarEvent, CalendarViewType>;
}
/// To store state data for DayView
class DayViewRefs {
  public eventState: Map<CalendarEvent, _EventState> = new Map();
  public forEvent(event: CalendarEvent): _EventState {
    let res = this.eventState.get(event);

    if (res == null) {
      res = new _EventState(this, event);

      this.eventState.set(event, res);
    }

    return res;
  }
}

interface ButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _EventState;
  event: CalendarEvent;
}

class ButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _ButtonWithState extends ObservableComponent<ButtonWithStateProps> {
  buttonFocusNode: ui.FocusNode = new ui.FocusNode();
  public constructor(props: ButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get button(): ButtonState {
    return this.props.d3eState.button;
  }
  public get event(): CalendarEvent {
    return this.props.event;
  }
  public get d3eState(): _EventState {
    return this.props.d3eState;
  }
  public initState() {
    super.initState();

    this.updateObservable("button", null, this.button);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("event", this.props.event);

    this.on(
      ["button", "event", "event.backgroundColor", "event.title"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ButtonWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.event !== this.props.event) {
      this.updateObservable("event", prevProps.event, this.props.event);

      this.fire("event", this);
    }
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    return ui.Container({
      margin: ui.EdgeInsets.all(5.0, new Map()),
      child: Button({
        decoration: new ui.BoxDecoration({
          color: ui.HexColor.fromHexStr(this.event.backgroundColor),
          borderRadius: ui.BorderRadius.circular(3.0),
        }),
        padding: ui.EdgeInsets.all(5.0, new Map()),
        disable: this.button.disable,
        onFocusChange: (val) => {},
        child: ui.Row({
          children: [
            TextView({
              data: this.event.title,
              softWrap: true,
              style: new ui.TextStyle({
                fontWeight: ui.FontWeight.w400,
                color: new ui.Color(0xffffffff),
              }),
              className: "xc18",
              key: "0",
            }),
          ],
        }),
      }),
      className: "xd16",
    });
  }
}
function ButtonWithState(props: ButtonWithStateProps) {
  return React.createElement(_ButtonWithState, props);
}

class _EventState {
  parent: DayViewRefs;
  event: CalendarEvent;
  button: ButtonState = new ButtonState();
  public constructor(parent, event) {
    this.parent = parent;

    this.event = event;
  }
}

class _DayViewState extends ObservableComponent<DayViewProps> {
  static defaultProps = { helper: null, day: null, categories: [] };
  d3eState: DayViewRefs = new DayViewRefs();
  allDayEvents: Array<CalendarEvent> = ListWrapper.widget(this, "allDayEvents");
  events: Array<CalendarEvent> = ListWrapper.widget(this, "events");
  sortedEvents: Array<CalendarEvent> = ListWrapper.widget(this, "sortedEvents");
  public constructor(props: DayViewProps) {
    super(props);

    this.initState();
  }
  public get helper(): CalendarHelper {
    return this.props.helper;
  }
  public get day(): D3EDate {
    return this.props.day;
  }
  public get categories(): Array<string> {
    return this.props.categories;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.categories, "categories");

    this.on(["categories", "day", "helper"], this.computeAllDayEvents);

    this.computeAllDayEvents();

    this.on(["categories", "day", "helper"], this.computeEvents);

    this.computeEvents();

    this.on(
      ["events", "events.allDay", "events.startTime", "helper"],
      this.computeSortedEvents
    );

    this.computeSortedEvents();

    this.on(
      [
        "allDayEvents",
        "day",
        "helper",
        "sortedEvents",
        "sortedEvents.endTime",
        "sortedEvents.startTime",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: DayViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.helper !== this.props.helper) {
      this.fire("helper", this);
    }

    if (prevProps.day !== this.props.day) {
      this.fire("day", this);
    }

    if (prevProps.categories !== this.props.categories) {
      this.fire("categories", this);
    }
  }
  public setAllDayEvents(val: Array<CalendarEvent>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.allDayEvents,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("allDayEvents", this.allDayEvents, val);

    this.allDayEvents.clear();

    this.allDayEvents.addAll(val);

    this.fire("allDayEvents", this);
  }
  public addToAllDayEvents(val: CalendarEvent, index: number = -1): void {
    if (index === -1) {
      if (!this.allDayEvents.contains(val)) this.allDayEvents.add(val);
    } else {
      this.allDayEvents.remove(this.allDayEvents.elementAt(index));

      this.allDayEvents.add(val);
    }

    this.fire("allDayEvents", this, val, true);

    this.updateObservable("allDayEvents", null, val);
  }
  public removeFromAllDayEvents(val: CalendarEvent): void {
    this.allDayEvents.remove(val);

    this.fire("allDayEvents", this, val, false);

    this.removeObservable("allDayEvents", val);
  }
  public computeAllDayEvents = (): void => {
    try {
      this.setAllDayEvents(
        Array.from(
          this.helper.getAllDayEventsForDate(
            this.day.toDateTime(),
            this.categories
          )
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeAllDayEvents : " + exception.toString()
      );

      this.setAllDayEvents([]);
    }
  };
  public setEvents(val: Array<CalendarEvent>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.events, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("events", this.events, val);

    this.events.clear();

    this.events.addAll(val);

    this.fire("events", this);
  }
  public addToEvents(val: CalendarEvent, index: number = -1): void {
    if (index === -1) {
      if (!this.events.contains(val)) this.events.add(val);
    } else {
      this.events.remove(this.events.elementAt(index));

      this.events.add(val);
    }

    this.fire("events", this, val, true);

    this.updateObservable("events", null, val);
  }
  public removeFromEvents(val: CalendarEvent): void {
    this.events.remove(val);

    this.fire("events", this, val, false);

    this.removeObservable("events", val);
  }
  public computeEvents = (): void => {
    try {
      this.setEvents(
        Array.from(
          this.helper.getEventsForDate(this.day.toDateTime(), this.categories)
        )
      );
    } catch (exception) {
      console.log(" exception in computeEvents : " + exception.toString());

      this.setEvents([]);
    }
  };
  public setSortedEvents(val: Array<CalendarEvent>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.sortedEvents,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("sortedEvents", this.sortedEvents, val);

    this.sortedEvents.clear();

    this.sortedEvents.addAll(val);

    this.fire("sortedEvents", this);
  }
  public addToSortedEvents(val: CalendarEvent, index: number = -1): void {
    if (index === -1) {
      if (!this.sortedEvents.contains(val)) this.sortedEvents.add(val);
    } else {
      this.sortedEvents.remove(this.sortedEvents.elementAt(index));

      this.sortedEvents.add(val);
    }

    this.fire("sortedEvents", this, val, true);

    this.updateObservable("sortedEvents", null, val);
  }
  public removeFromSortedEvents(val: CalendarEvent): void {
    this.sortedEvents.remove(val);

    this.fire("sortedEvents", this, val, false);

    this.removeObservable("sortedEvents", val);
  }
  public computeSortedEvents = (): void => {
    try {
      this.setSortedEvents(Array.from(this.helper.sortEvents(this.events)));
    } catch (exception) {
      console.log(
        " exception in computeSortedEvents : " + exception.toString()
      );

      this.setSortedEvents([]);
    }
  };
  public render(): ReactNode {
    return ui.Column({
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [
            ui.Center({
              child: TextView({
                data: CalendarHelper.getWeekDay(this.day),
                style: new ui.TextStyle({
                  fontSize: 14,
                  color: new ui.Color(0xffffffff),
                  fontWeight: ui.FontWeight.w500,
                }),
                className: "xfab hc",
              }),
              className: "hc",
              key: "0",
            }),
          ],
          className: "xdc6d hc h",
          key: "0",
        }),
        ui.Row({
          children: [
            this.allDayEvents.expand((event) => [
              ButtonWithState({
                d3eState: this.d3eState.forEvent(event),
                event: event,
                key: event?.toString(),
              }),
            ]),
          ],
          className: "x9a hc h",
          key: "1",
        }),
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            Range.to(24).expand((hour) => [
              ui.Container({
                decoration:
                  hour === 0
                    ? new ui.BoxDecoration({
                        border: new ui.Border({
                          top: new ui.BorderSide({
                            width: 1.0,
                            color: ui.HexColor.fromHexStr("ffced4da"),
                          }),
                          bottom: new ui.BorderSide({
                            width: 1.0,
                            color: ui.HexColor.fromHexStr("ffced4da"),
                          }),
                        }),
                      })
                    : new ui.BoxDecoration({
                        border: new ui.Border({
                          bottom: new ui.BorderSide({
                            width: 1.0,
                            color: ui.HexColor.fromHexStr("ffced4da"),
                          }),
                        }),
                      }),
                child: ui.Stack({
                  clipBehavior: ui.Clip.none,
                  children: [
                    ui.Container({ className: "x8a hc vc", key: "0" }),
                    ui.Positioned({
                      child: TextView({
                        data: CalendarHelper.formatHour(hour),
                        className: "x08 hc vc",
                        key: "1",
                      }),
                      bottom: 80,
                      left: 20,
                    }),
                    this.sortedEvents
                      .where((element) => element.startTime.hour === hour - 1)
                      .toList()
                      .expand((sortedEvent) => [
                        ui.Positioned({
                          child: ui.Container({
                            height: this.helper.getEventHeight(sortedEvent),
                            child: this.props.builder(
                              this.context,
                              sortedEvent,
                              CalendarViewType.Day
                            ),
                            className: "hc vc",
                            key: sortedEvent?.toString(),
                          }),
                          top: this.helper.getEventTop(sortedEvent),
                          left:
                            70 * (this.sortedEvents.indexOf(sortedEvent) + 1.0),
                          height: this.helper.getEventHeight(sortedEvent),
                        }),
                      ]),
                  ],
                  className: "hc vc",
                }),
                className: "x761 hc vc",
                key: hour?.toString(),
              }),
            ]),
          ],
          className: "hc",
          key: "2",
        }),
      ],
      className: ui.join(this.props.className, "DayView x1b hc vc h v"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function DayView(props: DayViewProps) {
  return React.createElement(
    _DayViewState,
    { ..._DayViewState.defaultProps, ...props },
    ListWrapper.fromInput<string>(props.categories, "categories")
  );
}
