import React from "react";
import ReactDOM from "react-dom";
import Env from "./main/classes/Env";
import CoreExt from "./main/core/CoreExt";
import AllPagesUtil from "./main/utils/AllPagesUtil";
import MyApp from "./MyApp";

async function loadSettings() {
  let settings = await fetch("resource/settings.json").then((r) => r.json());
  Env.get().load(settings);

  const test = new CoreExt();
  AllPagesUtil.load();

  ReactDOM.render(<MyApp />, document.getElementById("root"));
}
loadSettings();
